import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import "./AuditLogsModal.Component.scss";
import CloseModal from "../../../assets/close-outline.svg";
import axios from "axios";
import LogsPagination from "../../UI/Pagination/LogsPagination";
import { userActions } from "../../../store/user";
import { rowOrderActions } from "../../../store/orders";
import { filterActions } from "../../../store/filter";
import { getWithExpiry } from "../../utils/helper";
import Loader2 from "../../UI/loader2/loader2";
import useQueryString from "../../../hooks/useQueryString";
import {
  removeEmptyParams,
  setOrderAndIcon,
} from "../../../services/helpers/sorting.hepler";
import SearchInput from "../../General/filters/SearchInput.Component";
function AuditLogs(props) {
  const { isShowModal, hideModal, type, dataType, LogTitle, refId } = props;

  const dispatch = useDispatch();
  const tableHeadings = [
    "Created At",
    "Modified At",
    "User Name",
    "Event Type",
    "Event Description",
  ];

  const auditLogsOrderMapping = {
    "Created At": "created_at",
    "Modified At": "modified_at",
    "User Name": "user",
    "Event Type": "event_type",
    "Event Description": "event_description",
  };

  // apiPath = `community/group_test_history/community_id?page=PAGE_NO`
  const [auditLogs, setAuditLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [otherLoader, setOtherLoader] = useState(false);
  const urlParams = useQueryString();

  const totalRecords = 10;

  const token = getWithExpiry("expiry_token");
  const auditLogsList = useSelector((state) => state.user.auditLogsList);
  const auditLogsCurrentPage = useSelector(
    (state) => state.user.auditLogsCurrentPage
  );
  const auditLogsTotalRecords = useSelector(
    (state) => state.user.auditLogsTotalRecords
  );

  let logType = "";

  if (type === "Corporate" && dataType === "") {
    logType = "Corporate";
  }

  if (type === "Community" && dataType === "") {
    logType = "CareCommunity";
  }

  if (type === "Patient" && dataType === "") {
    logType = "Patient";
  }
  if (type === "Patient" && dataType === "Patient History") {
    logType = "Test";
  }

  if (type === "Test" && dataType === "") {
    logType = "Test";
  }

  if (type === "Provider" && dataType === "") {
    logType = "Provider";
  }

  if (type === "RegUser" && dataType === "") {
    logType = "User";
  }
  if (type === "AdminUser" && dataType === "") {
    logType = "AdminUser";
  }
  if (type === "Billing" && dataType === "") {
    logType = "InvoiceCredit";
  }

  if (type === "Corporate" && dataType === "community") {
    logType = "Corporate_CareCommunity";
  }

  if (type === "Corporate" && dataType === "user") {
    logType = "Corporate_UserRole";
  }

  if (type === "Community" && dataType === "patient") {
    logType = "CareCommunity_Patient";
  }

  if (type === "Community" && dataType === "user") {
    logType = "CareCommunity_UserRole";
  }

  if (type === "Community" && dataType === "iphone") {
    logType = "CareCommunity_TestingIphone";
  }

  if (type === "Community" && dataType === "billee") {
    logType = "CareCommunity_Billee";
  }
  if (type === "Community" && dataType === "provider") {
    logType = "CareCommunity_Provider";
  }

  if (type === "Community" && dataType === "history") {
    logType = "BatchTest";
  }
  const [orderQuery, setOrderQuery] = useState("");
  const [headingName, setHeadingName] = useState("");
  const [search, setSearch] = useState(false);
  const handleSearchChange = (e) => {
    const { value, name } = e.target;
    dispatch(filterActions.setSearchAuditLogText(value));
  };
  const [orderState, setOrderState] = useState(null);
  const [orderState2, setOrderState2] = useState(null);
  const [apiUrl, setApiUrl] = useState("");
  const searchAuditLogText = useSelector(
    (state) => state.filter.searchAuditLogText
  );
  const rowOrderStateAuditLogs = useSelector(
    (state) => state.rowOrder.rowOrderAuditLogs
  );
  const fetchData = async () => {
    try {

      let query = apiUrl ? apiUrl + "&" : "?";

      const url = `${process.env.REACT_APP_URL_PATH}/system/get_audit_logs/${query}concerned_entity=${logType}&parent_id=${refId}&page_no=${auditLogsCurrentPage}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      const resData = response.data.data;
      setLoading(false);
      setAuditLogs(resData);
      dispatch(userActions.setAuditLogsList(resData.audit_logs));
      dispatch(userActions.setAuditLogsPageNumber(resData.audit_logs_pages));
      dispatch(
        userActions.setAuditLogsTotalRecords(resData.audit_logs_total_records)
      );

      if (auditLogsCurrentPage > resData.audit_logs_pages) {
        dispatch(userActions.setAuditLogsCurrentPage(resData.audit_logs_pages));
      }
      setOtherLoader(false);
    } catch (error) {
      setLoading(false);
      setOtherLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [orderState]);

  useEffect(() => {
    updateUrl();
  }, [orderState2]);

  const buildRowOrderQuery = () => {
    let newOrderQuery = orderQuery.slice();

    let mappingHeaderName = auditLogsOrderMapping[headingName];
    let rowOrderHeaderName =
      rowOrderStateAuditLogs[auditLogsOrderMapping[headingName]];

    if (mappingHeaderName) {
      let prevOrder = rowOrderHeaderName.order === "asc" ? "desc" : "asc";
      let prevInstance = `&${mappingHeaderName}:orderby=${prevOrder}`;
      if (newOrderQuery.includes(prevInstance)) {
        newOrderQuery = newOrderQuery.replace(prevInstance, "");
      }
      if (!search) {
        newOrderQuery = `&${mappingHeaderName}:orderby=${rowOrderHeaderName.order}`;
      }
    }
    setOrderQuery(newOrderQuery);
    return newOrderQuery;
  };

  const buildFilterQuery = () => {
    let filterQuery = "";

    return filterQuery;
  };

  const buildSearchTextQuery = () => {
    let searchQuery = "";
    searchQuery =
      searchAuditLogText !== "" ? `search_query=${searchAuditLogText}` : "";
    return searchQuery;
  };

  const updateUrl = () => {
    const searchQuery = buildSearchTextQuery();
    const newOrderQuery = buildRowOrderQuery();
    const filterQuery = buildFilterQuery();

    let customQuery = "";

    customQuery = `${searchQuery}${newOrderQuery}&${filterQuery}`;

    let modifiedString = removeEmptyParams(customQuery);
    let str =
      modifiedString && modifiedString !== "" ? "?" + modifiedString : "";

    setApiUrl(str);
    setOrderState({
      ...orderState,
    });
  };

  const onClickTableHeading = async (e) => {
    const { id } = e.target;
    setSearch(false);
    setOtherLoader(true);
    setHeadingName(id);

    let name = "";
    let obj;
    
    switch (id) {
      case "Created At":
        name = "created_at";
        obj = setOrderAndIcon(rowOrderStateAuditLogs[name]);
        dispatch(
          rowOrderActions.setRowOrderAuditLogs({
            ...rowOrderStateAuditLogs,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            modified_at: { state: 1, order: "", icon: "" },
            user: { state: 1, order: "", icon: "" },
            event_type: { state: 1, order: "", icon: "" },
            event_description: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;
      case "Modified At":
        name = "modified_at";
        obj = setOrderAndIcon(rowOrderStateAuditLogs[name]);
        dispatch(
          rowOrderActions.setRowOrderAuditLogs({
            ...rowOrderStateAuditLogs,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            created_at: { state: 1, order: "", icon: "" },
            user: { state: 1, order: "", icon: "" },
            event_type: { state: 1, order: "", icon: "" },
            event_description: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;
      case "User Name":
        name = "user";
        obj = setOrderAndIcon(rowOrderStateAuditLogs[name]);
        dispatch(
          rowOrderActions.setRowOrderAuditLogs({
            ...rowOrderStateAuditLogs,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            modified_at: { state: 1, order: "", icon: "" },
            created_at: { state: 1, order: "", icon: "" },
            event_type: { state: 1, order: "", icon: "" },
            event_description: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;
      case "Event Type":
        name = "event_type";
        obj = setOrderAndIcon(rowOrderStateAuditLogs[name]);
        dispatch(
          rowOrderActions.setRowOrderAuditLogs({
            ...rowOrderStateAuditLogs,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            modified_at: { state: 1, order: "", icon: "" },
            user: { state: 1, order: "", icon: "" },
            created_at: { state: 1, order: "", icon: "" },
            event_description: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;
      case "Event Description":
        name = "event_description";
        obj = setOrderAndIcon(rowOrderStateAuditLogs[name]);
        dispatch(
          rowOrderActions.setRowOrderAuditLogs({
            ...rowOrderStateAuditLogs,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            modified_at: { state: 1, order: "", icon: "" },
            user: { state: 1, order: "", icon: "" },
            event_type: { state: 1, order: "", icon: "" },
            created_at: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      default:
        return;
    }
  };
  const onClearSorting = () => {
    setOtherLoader(true);
    dispatch(
      rowOrderActions.setRowOrderAuditLogs({
        ...rowOrderStateAuditLogs,
        created_at: { state: 1, order: "", icon: "" },
        modified_at: { state: 1, order: "", icon: "" },
        user: { state: 1, order: "", icon: "" },
        event_type: { state: 1, order: "", icon: "" },
        event_description: { state: 1, order: "", icon: "" },
      })
    );
    setOrderQuery("");
    setHeadingName("");
    setOrderState2({
      ...orderState2,
    });
  };
  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      setSearch(true);
      setOtherLoader(true);

      updateUrl();
    }
  };

  const onClearSearch = () => {
    setOtherLoader(true);
    dispatch(filterActions.setSearchAuditLogText(""));
    setSearch(true);
    setOrderState2({
      ...orderState2,
    });
  };
  return (
    <div className="action-modal-wrapper">
      <Modal
        className="groupTest-modal audit-modal"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header className="audit-modal-header">
          <h1 className="modal-header-title"></h1>
          <span
            className="close-icon-modal "
            onClick={() => {
              hideModal();
              dispatch(userActions.setAuditLogsList([]));
              dispatch(userActions.setAuditLogsPageNumber(1));
              dispatch(userActions.setAuditLogsCurrentPage(1));
              dispatch(userActions.setAuditLogsTotalRecords(0));
            }}
          >
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>

        <Modal.Body className="filter-modal-body">
          <div className="cor-header">
            <h1 className="page-name-heading">{LogTitle}</h1>

            <div className="right-cor-header">
              <ul className="rc-header-listing">
                {/* <li className="single-rc">
                  <button onClick={onClearSorting}>Clear Sorting</button>
                </li> */}
                {/* <li className="single-rc">
                  <button onClick={onClearSearch}>Clear Search</button>
                </li> */}
                <li className="single-rc">
                  <SearchInput
                    value={searchAuditLogText}
                    handleChange={handleSearchChange}
                    onKeyEnter={onEnterPress}
                    onClearSearch={onClearSearch}
                  />
                </li>
              </ul>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table" id="en-width">
              <thead>
                <tr>
                  {tableHeadings &&
                    tableHeadings.map((tableHead) => (
                      <th
                        id={tableHead}
                        name={tableHead}
                        key={tableHead}
                        onClick={onClickTableHeading}
                      >
                        {tableHead}
                        {tableHead !== "Actions" && (
                          <>
                            {rowOrderStateAuditLogs[
                              auditLogsOrderMapping[tableHead]
                            ].icon === "" ? (
                              <span className="icons-th">
                                <span></span>
                                <span className="order-4"></span>
                              </span>
                            ) : (
                              <span className="icons-th">
                                <span
                                  className={
                                    rowOrderStateAuditLogs[
                                      auditLogsOrderMapping[tableHead]
                                    ].icon === "asc"
                                      ? "caret-4-asc-icon"
                                      : "caret-4-desc-icon"
                                  }
                                ></span>
                                <span className="order-4"></span>
                              </span>
                            )}
                          </>
                        )}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {auditLogsList.length === 0 && (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No data available in table
                    </td>
                  </tr>
                )}
                {auditLogsList &&
                  auditLogsList.map((record) => (
                    <tr>
                      <td>{record.created_at ? record.created_at : "N/A"}</td>
                      <td>{record.modified_at ? record.modified_at : "N/A"}</td>
                      <td>{record.username ? record.username : ""}</td>
                      <td>{record.event_type}</td>
                      <td>
                        {record.event_description.split("\n").map((value) => (
                          <p>{value}</p>
                        ))}
                      </td>
                    </tr>
                  ))}
                {/* {auditLogs &&
                auditLogs.audit_logs &&
                auditLogs.audit_logs.map((record) => (
                  <tr>
                    <td>{record.created_at}</td>
                    <td>{record.username ? record.username : ""}</td>
                    <td>{record.event_type}</td>
                    <td>{record.event_description}</td>
                  </tr>
                ))} */}
              </tbody>
            </table>
          </div>

          <LogsPagination
            data={auditLogs.audit_logs}
            totalRecords={auditLogsTotalRecords}
            type={type}
            dataType={dataType}
            refId={refId}
            LogType={logType}
            LogsApiUrl={apiUrl}
            setOtherLoader={setOtherLoader}
          />
        </Modal.Body>
        {/* <Modal.Footer className="addmisnis-footer-modal">
          <button
            id="close-btn"
            className="btn btn-secondary-fill btn-primary-16"
            onClick={() => {
              hideModal();
              dispatch(userActions.setAuditLogsList([]));
              dispatch(userActions.setAuditLogsPageNumber(1));
              dispatch(userActions.setAuditLogsCurrentPage(1));
              dispatch(userActions.setAuditLogsTotalRecords(0));
            }}
          >
            Close
          </button>
        </Modal.Footer> */}
        {(loading || otherLoader) && <Loader2 />}
      </Modal>
    </div>
  );
}

export default AuditLogs;
