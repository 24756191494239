import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Select, { components } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "react-phone-number-input/input";
import useInput from "../../../hooks/use-input";
import axios from "axios";
import { getWithExpiry } from "../../utils/helper";
// import images
import CloseModal from "../../../assets/close-outline.svg";
import { useSelector } from "react-redux";
import Provider from "../Provider";
import Loader2 from "../../UI/loader2/loader2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const isNotEmpty = (value) => value.trim() !== "";
const isEmailValid = (value) => value.includes("@");

const titles = [ "MD", "PA", "NP", "DO", "PhD"]

const AddProvider = (props) => {
  const {
    isShowModal,
    hideModal,
    setDataFlag,
    setShowToast,
    type,
    communityId,
  } = props;


  //Start Creating State Object for Form
  const [provider, setProvider] = useState({
    title: "MD",
    suffix: "",
    first_name: "",
    last_name: "",
    email: "",
    telephone_number: "",
    community_id: 0,
    company: "",
    billee_first_name: "",
    billee_last_name: "",
    billee_email: "",
    billee_telephone_number: "",
    billee_address_1: "",
    billee_address_2: "",
    billee_country: "",
    billee_city: "",
    billee_state: "",
    billee_zip: "",
  });
  const [phoneValue, setPhoneValue] = useState("");
  const [phoneValue2, setPhoneValue2] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "billee_zip") {
      const regex = /^\d+$/;
      if (value === "") {
        setProvider({ ...provider, [name]: value });
        return;
      }

      if (regex.test(value) === false) return;
    }
    setProvider({
      ...provider,
      [name]: value,
    });
  };

  const handleChangeCommunity = (e) => {
    setProvider({
      ...Provider,
      community_id: e[0].value,
    });
  };

  const communityData = useSelector(
    (state) => state.provider.ProviderCommunites
  );
  const options = [
    communityData.map((data) => ({
      value: data.id,
      label: data.community_name,
    })),
  ];

  /* Provider field errors */
  const [titleError, setTitleError] = useState("");
  const [suffixError, setSuffixError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [communitydropdown, setCommunitydropdown] = useState();

  /* Billee field errors */
  const [companyError, setCompanyError] = useState("");
  const [billeFirstNameError, setBilleFirstNameError] = useState("");
  const [billeLastNameError, setBilleLastNameError] = useState("");
  const [billeEmailError, setBilleeEmailError] = useState("");
  const [billePhoneError, setBilleePhoneError] = useState("");

  const [billeAddress1Error, setBilleeAddress1Error] = useState("");
  const [billeAddress2Error, setBilleeAddress2Error] = useState("");
  const [billeCountryError, setBilleeCountryError] = useState("");
  const [billeCityError, setBilleeCityError] = useState("");
  const [billeStateError, setBilleeStateError] = useState("");
  const [billeZipError, setBilleeZipError] = useState("");

  const [phoneVal, setPhoneVal] = useState("");
  const [billeePhoneVal, setBilleePhoneVal] = useState("");
  /* Add btn disable state */
  const [addBtnDisabled, setAddBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  /* set selected community in state */
  const [community, setCommunity] = useState("");

  /* set title value in state */
  const [titleVal, setTitleVal] = useState("MD");

  /* Authorization token */
  const token = "Bearer " + getWithExpiry("expiry_token");

  const isPhoneValid = (value) => {
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return isNotEmpty(value) && regex.test(value);
  };

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const addProviderSubmissionHandler = (event) => {
    event.preventDefault();
    setLoading(true);

    /* Disable button when form add button is clicked */
    setAddBtnDisabled(true);

    /* Reset Provider fields */
    setTitleError("");
    setSuffixError("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");
    setEmailError("");
    setPhoneError("");

    /* Reset Billee fields */
    setCompanyError("");
    setBilleFirstNameError("");
    setBilleLastNameError("");
    setBilleeEmailError("");
    setBilleePhoneError("");
    setBilleeAddress1Error("");
    setBilleeAddress2Error("");
    setBilleeCountryError("");
    setBilleeCityError("");
    setBilleeStateError("");
    setBilleeZipError("");

    // create form data
    const form = document.querySelector(".add-corporate-form");
    const fd = new FormData(form);

    if (type === "Community") {
      fd.append("community_id", communityId);
    }


    if (phoneValue) {
      fd.append("telephone_number", "+" + phoneValue);
    }
    if (phoneValue2) {
      fd.append("billee_telephone_number", "+" + phoneValue2);
    }


    axios({
      url: `${process.env.REACT_APP_URL_PATH}/community/add_provider/`,
      data: fd,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
       
      })
      .catch((error) => {
        setLoading(false);
        setAddBtnDisabled(true);
        if (error.response.data.message === "Field Errors") {
          if (
            error.response.data.data["provider_form"] &&
            "first_name" in error.response.data.data["provider_form"]
          ) {
            setFirstNameError(
              error.response.data.data["provider_form"]["first_name"]
            );
          }
          if (
            error.response.data.data["provider_form"] &&
            "last_name" in error.response.data.data["provider_form"]
          ) {
            setLastNameError(
              error.response.data.data["provider_form"]["last_name"]
            );
          }
          if (
            error.response.data.data["provider_form"] &&
            "email" in error.response.data.data["provider_form"]
          ) {
            setEmailError(error.response.data.data["provider_form"]["email"]);
          }
          if (
            error.response.data.data["phone_form"] &&
            "telephone_number" in error.response.data.data["phone_form"]
          ) {
            setPhoneError(
              error.response.data.data["phone_form"]["telephone_number"]
            );
          }

          // Billee Errors

          if (
            error.response.data.data["billee_form"] &&
            "company" in error.response.data.data["billee_form"]
          ) {
            setCompanyError(error.response.data.data["billee_form"]["company"]);
          }
          if (
            error.response.data.data["billee_form"] &&
            "billee_first_name" in error.response.data.data["billee_form"]
          ) {
            setBilleFirstNameError(
              error.response.data.data["billee_form"]["billee_first_name"]
            );
          }
          if (
            error.response.data.data["billee_form"] &&
            "billee_last_name" in error.response.data.data["billee_form"]
          ) {
            setBilleLastNameError(
              error.response.data.data["billee_form"]["billee_last_name"]
            );
          }
          if (
            error.response.data.data["billee_form"] &&
            "billee_email" in error.response.data.data["billee_form"]
          ) {
            setBilleeEmailError(
              error.response.data.data["billee_form"]["billee_email"]
            );
          }
          if (
            error.response.data.data["billee_form"] &&
            "billee_telephone_number" in error.response.data.data["billee_form"]
          ) {
            setBilleePhoneError(
              error.response.data.data["billee_form"]["billee_telephone_number"]
            );
          }
          if (
            error.response.data.data["billee_form"] &&
            "billee_address_1" in error.response.data.data["billee_form"]
          ) {
            setBilleeAddress1Error(
              error.response.data.data["billee_form"]["billee_address_1"]
            );
          }
          if (
            error.response.data.data["billee_form"] &&
            "billee_country" in error.response.data.data["billee_form"]
          ) {
            setBilleeCountryError(
              error.response.data.data["billee_form"]["billee_country"]
            );
          }
          if (
            error.response.data.data["billee_form"] &&
            "billee_state" in error.response.data.data["billee_form"]
          ) {
            setBilleeStateError(
              error.response.data.data["billee_form"]["billee_state"]
            );
          }
          if (
            error.response.data.data["billee_form"] &&
            "billee_city" in error.response.data.data["billee_form"]
          ) {
            setBilleeCityError(
              error.response.data.data["billee_form"]["billee_city"]
            );
          }
          if (
            error.response.data.data["billee_form"] &&
            "billee_zip" in error.response.data.data["billee_form"]
          ) {
            setBilleeZipError(
              error.response.data.data["billee_form"]["billee_zip"]
            );
          }
        } else {
          notifyError(error.response.data.message);
        }

        setAddBtnDisabled(false);
      });
  };
  const returnSelectedArray = async (object) => {
    if (!object || object.length <= 0) return [];
    var _selected = [];
    object.map((dataKey) => {
      _selected.push({
        id: dataKey.value,
        value: dataKey.value,
        label: dataKey.label,
      });
    });
    return _selected;
  };
  const [selectedCommunity, setSelectedCommuntiy] = useState([]);

  const handleSelectedCommunity = async (e) => {
    var _selected = await returnSelectedArray(e);
    setSelectedCommuntiy(_selected);
  };

  return (
    <div className="action-modal-wrapper">
      <Modal
        className="comunitie-modal modal-loader"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header className="comubitie-modal-header" id="rm-padding">
          <h1 className="modal-header-title">Add Provider</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="comunitie-modal-body">
          <form
            method="post"
            className="add-corporate-form"
            onSubmit={addProviderSubmissionHandler}
          >
            <div className="comunties-fields">
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="title" className="label">
                      Title *
                    </label>
                    <select
                      id="title"
                      name="title"
                      className="custom-select-modal"
                      onChange={handleChange}
                      value={provider.title}
                      // onChange={handleChange}
                      // value={provider.title}
                    >
                      {titles.map(title => (<option key={title} value={title}>{title}</option>))}
                    </select>
                    {titleError && <p className="error-text">{titleError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="suffix" className="label">
                      Suffix
                    </label>
                    <input
                      id="suffix"
                      name="suffix"
                      type="text"
                      placeholder="Suffix"
                      className="custom-input"
                      value={provider.suffix}
                      onChange={handleChange}
                      // onBlur={suffixBlurHandler}
                      // onChange={handleChange}
                      // value={provider.suffix}
                      required
                    />
                    {suffixError && <p className="error-text">{suffixError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="first_name" className="label">
                      First Name *
                    </label>
                    <input
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="First Name"
                      className="custom-input"
                      value={provider.first_name}
                      onChange={handleChange}
                      // onBlur={nameBlurHandler}
                      //onChange={handleChange}
                      //value={provider.first_name}
                      required
                    />
                    {firstNameError && (
                      <p className="error-text">{firstNameError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="last_name" className="label">
                      Last Name *
                    </label>
                    <input
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Last Name"
                      className="custom-input"
                      value={provider.last_name}
                      onChange={handleChange}
                      // onBlur={lastnameBlurHandler}
                      // onChange={handleChange}
                      //  value={provider.last_name}
                      required
                    />
                    {lastNameError && (
                      <p className="error-text">{lastNameError}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="email" className="label">
                      Email *
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      className="custom-input"
                      value={provider.email}
                      onChange={handleChange}
                      // onChange={handleChange}
                      // value={provider.email}
                      // onBlur={emailnameBlurHandler}
                      required
                    />
                    {emailError && <p className="error-text">{emailError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="telephone_number" className="label">
                      Telephone *
                    </label>
                    {/* <input
                      type="text"
                      id="telephone_number"
                      name="telephone_number"
                      className="custom-input"
                      placeholder="Telephone"
                      onChange={(e) => {
                        const { name, value } = e.target;

                        const regex = /^([\s+0-9])+$/i;
                        if (value === "") {
                          // case to remove whole highlighted text
                          setProvider({ ...provider, [name]: value });
                          return;
                        }
                        if (regex.test(value) === false) return;

                        if (value.length === 1 && value === "+") {
                          // case to remove + sign when last number is removed
                          setProvider({ ...provider, [name]: "" });
                        } else if (value.length >= 1 && value[0] !== "+") {
                          // case to append + sign with first number
                          setProvider({ ...provider, [name]: "+" + value });
                        } else {
                          // case to append other numbers
                          setProvider({ ...provider, [name]: value });
                        }
                      }}
                      value={provider.telephone_number}
                      required
                    /> */}
                    <PhoneInput
                      country={"us"}
                      value={phoneValue}
                      onChange={setPhoneValue}
                      inputProps={{
                        required: true,
                      }}
                    />
                    {phoneError && <p className="error-text">{phoneError}</p>}
                  </div>
                </div>
              </div>
              {type !== "Community" && (
                <div className="row">
                  <div className="col-lg-6 left-col">
                    <div className="field-wrapper">
                      <label htmlFor="community" className="label">
                        Associated Communities *
                      </label>
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isMulti
                        options={options[0]}
                        id="community_id"
                        name="community_id"
                        closeMenuOnSelect={false}
                        components={{ MultiValue }}
                        onChange={handleSelectedCommunity}
                        //value={selectedCommunity}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 left-col">
                    <div className="field-wrapper">
                      <label htmlFor="bill_community" className="label">
                        Communities to be Billed
                      </label>
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isMulti
                        options={selectedCommunity}
                        id="bill_community_id"
                        name="bill_community_id"
                        closeMenuOnSelect={false}
                        components={{ MultiValue }}
                        //   onChange={selectCommunity}
                        //  value={communitydropdown}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="addminstraion-fields">
              <div className="addminstraion-header">
                <h2 className="adsd-heading">Add Billee</h2>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="company" className="label">
                      Company
                    </label>
                    <input
                      id="company"
                      name="company"
                      type="text"
                      className="custom-input"
                      placeholder="Company"
                      onChange={handleChange}
                      value={provider.company}
                      required
                    />
                    {companyError && (
                      <p className="error-text">{companyError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="billee_first_name" className="label">
                      First Name
                    </label>
                    <input
                      id="billee_first_name"
                      name="billee_first_name"
                      type="text"
                      className="custom-input"
                      placeholder="First Name"
                      onChange={handleChange}
                      value={provider.billee_first_name}
                      required
                    />
                    {billeFirstNameError && (
                      <p className="error-text">{billeFirstNameError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="billee_last_name" className="label">
                      Last Name
                    </label>
                    <input
                      id="billee_last_name"
                      name="billee_last_name"
                      type="text"
                      className="custom-input"
                      placeholder="Last Name"
                      onChange={handleChange}
                      value={provider.billee_last_name}
                      required
                    />
                    {billeLastNameError && (
                      <p className="error-text">{billeLastNameError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="billee_email" className="label">
                      Email
                    </label>
                    <input
                      id="billee_email"
                      name="billee_email"
                      type="email"
                      className="custom-input"
                      placeholder="Email"
                      onChange={handleChange}
                      value={provider.billee_email}
                      required
                    />
                    {billeEmailError && (
                      <p className="error-text">{billeEmailError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="billee_telephone_number" className="label">
                      Telephone
                    </label>
                    {/* <input
                      type="text"
                      id="billee_telephone_number"
                      name="billee_telephone_number"
                      className="custom-input"
                      placeholder="Telephone"
                      onChange={(e) => {
                        const { name, value } = e.target;

                        const regex = /^([\s+0-9])+$/i;
                        if (value === "") {
                          // case to remove whole highlighted text
                          setProvider({ ...provider, [name]: value });
                          return;
                        }
                        if (regex.test(value) === false) return;

                        if (value.length === 1 && value === "+") {
                          // case to remove + sign when last number is removed
                          setProvider({ ...provider, [name]: "" });
                        } else if (value.length >= 1 && value[0] !== "+") {
                          // case to append + sign with first number
                          setProvider({ ...provider, [name]: "+" + value });
                        } else {
                          // case to append other numbers
                          setProvider({ ...provider, [name]: value });
                        }
                      }}
                      //value={billeePhoneVal}
                      value={provider.billee_telephone_number}
                      required
                    /> */}
                    <PhoneInput
                      country={"us"}
                      value={phoneValue2}
                      onChange={setPhoneValue2}
                      inputProps={{
                        required: true,
                      }}
                    />
                    {billePhoneError && (
                      <p className="error-text">{billePhoneError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="country" className="label">
                      Address 01
                    </label>
                    <input
                      id="billee_address_1"
                      name="billee_address_1"
                      type="text"
                      className="custom-input"
                      // value={address2}
                      // onChange={setAddress2Handler}
                      placeholder="Address 01"
                      value={provider.billee_address_1}
                      onChange={handleChange}
                    />
                    {billeAddress1Error && (
                      <p className="error-text">{billeAddress1Error}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="country" className="label">
                      Address 02
                    </label>
                    <input
                      id="billee_address_2"
                      name="billee_address_2"
                      type="text"
                      className="custom-input"
                      placeholder="Address 02"
                      value={provider.billee_address_2}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="zip" className="label">
                      City
                    </label>
                    <input
                      type="text"
                      name="billee_city"
                      id="billee_city"
                      className="custom-input"
                      placeholder="City"
                      value={provider.billee_city}
                      onChange={handleChange}
                      required
                    />
                    {billeCityError && (
                      <p className="error-text">{billeCityError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="state" className="label">
                      State
                    </label>
                    <input
                      type="text"
                      id="billee_state"
                      name="billee_state"
                      className="custom-input"
                      placeholder="State"
                      value={provider.billee_state}
                      onChange={handleChange}
                      required
                    />
                    {billeStateError && (
                      <p className="error-text">{billeStateError}</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="zip" className="label">
                      Zip
                    </label>
                    <input
                      type="text"
                      name="billee_zip"
                      id="billee_zip"
                      className="custom-input"
                      placeholder="Zip"
                      value={provider.billee_zip}
                      onChange={handleChange}
                      required
                    />
                    {billeZipError && (
                      <p className="error-text">{billeZipError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="billee_country" className="label">
                      Country
                    </label>
                    <input
                      type="text"
                      name="billee_country"
                      id="billee_country"
                      className="custom-input"
                      placeholder="Country"
                      value={provider.billee_country}
                      onChange={handleChange}
                      required
                    />
                    {billeCountryError && (
                      <p className="error-text">{billeCountryError}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            id="close-btn"
            className="btn btn-secondary-fill btn-primary-16"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            // type="submit"
            onClick={addProviderSubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={addBtnDisabled}
          >
            Add
          </button>
        </Modal.Footer>
        {/* <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
};

export default AddProvider;
const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    borderRadius: "4px",
    fontFamily: "Open Sans",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 3;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};
