import { Fragment, useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "./store/auth";
import { userActions } from "./store/user";
import { sidebarActions } from "./store/sidebar";
import axios from "axios";
import { getWithExpiry } from "./components/utils/helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/* Import common UI component */
import Homepage from "./components/UI/Homepage/Homepage";
import TestPage from "./components/testPage/TestPage";
import TestPage2 from "./components/testPage/TestPage2";
/* Import sidebar components */
import Corporate from "./components/Corporate/Corporate";
import Communities from "./components/Communities/Communities";
import Patients from "./components/Patient/Patient";
import Test from "./components/Test/Test";
import Provider from "./components/Provider/Provider";
import Users from "./components/Users/Users";
import Billing from "./components/Billing/Billing";
import NotFound from "./components/NotFound/NotFound";
import EditCorporate from "./components/EditCorporate/EditCorporate";
import CorpDetail from "./components/CorporateDetail/CorporateDetail"; // removing this causes weird behaviour

import EditComunities from "./components/EditCommunity/EditCommunity";
import TermsServiesLoggedModal from "./components/utils/TermsServices/TermsServicesLoggedModal";
import PatientsDetail from "./components/PatientDetail/PatientDetail";
import EditProfile from "./components/EditProfile/EditProfile";
import Loader from "./components/UI/loader/loader";
/* Import Authentication Components */
import Login from "./components/Authentication/Login/Login";
import ForgotPassword from "./components/Authentication/ForgotPassword/ForgotPassword";
import Signup from "./components/Authentication/Signup/Signup";
import OTPVerification from "./components/Authentication/OTPVerification/OTPVerification";
import OTPVerificationResetPsw from "./components/Authentication/OTPVerification/OTPVerificationResetPsw";
import ChangePassword from "./components/Authentication/ChangePassword/ChangePassword";
import ResetPassword from "./components/Authentication/ResetPassword/ResetPassword";
import ProtectedRoute from "./components/ProtectedRoute";
import LoginRoute from "./components/LoginRoute";
import tosRoute from "./components/tos/tos";
import routes from "./routes";
import { useInterval } from "./hooks/useInterval";
import "./App.scss";
import "./index.scss";
require("dotenv").config();

function App() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const newTabOpen = useSelector((state) => state.auth.newTabOpen);
  const pathCheck = useSelector((state) => state.auth.pathCheck);
  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);
  const appLoader = useSelector((state) => state.user.appLoader);
  const dispatch = useDispatch();

  const logoutHandler = () => {
    localStorage.removeItem("expiry_token");
    sessionStorage.clear();
    dispatch(authActions.setIsLoggedIn(false));
    //dispatch(authActions.setRememberMe(false));
    window.location.reload();
  };
  
  const notifySuccess = (successMsg) =>
  toast.success(successMsg, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  useEffect(() => {
        const handleInvalidToken = (e) => {
      if (e.key === "expiry_token" && e.oldValue && !e.newValue) {
        logoutHandler();
      }
    };
    window.addEventListener("storage", handleInvalidToken);

    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, []);

  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const showTermsModal = () => setTermsModalOpen(true);
  const hideTermsModal = () => setTermsModalOpen(false);

  const [iframeDoc, setIframeDoc] = useState("");
  const token = getWithExpiry("expiry_token");
  // const rememberToken =
  //   localStorage.getItem("rememberMe") ?? sessionStorage.getItem("rememberMe");

  useEffect(() => {
    if (token) {
      dispatch(authActions.setIsLoggedIn(true));
      dispatch(authActions.setNewTabOpen(true));

      const permissionsPromise = axios({
        url: `${process.env.REACT_APP_URL_PATH}/user/permissions/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        const permissions = response.data.data.permissions;
        const catchu_admin = response.data.data.catchu_admin;
        dispatch(userActions.addPermissions(permissions));
        dispatch(userActions.setIsCatchuAdmin(catchu_admin));
      });

      const rolesPromise = axios({
        url: `${process.env.REACT_APP_URL_PATH}/user/roles/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        if (!res.data.data.is_enabled) {
          logoutHandler();
        }
        const resData = res.data.data.roles;
        dispatch(userActions.setRole(resData));
      });

      const termsPromise = axios({
        url: `${process.env.REACT_APP_URL_PATH}/user/terms_conditions_status_logged_in/`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        if (!res.data.data.accepted) {
          setModalState(true);
          setIframeDoc(res.data.data.iframe_url);
          showTermsModal();
        }
      });

      Promise.all([permissionsPromise, rolesPromise, termsPromise])
        .then(() => {
          notifySuccess(`You are logged in as ${userRole.join(', ')}`)
        })
        .catch((error) => {
          console.error("Error occurred during API requests:", error);
        });
    } else {
      dispatch(authActions.setNewTabOpen(false));
      dispatch(authActions.setIsLoggedIn(false));
      dispatch(authActions.setRememberMe(false));
    }
  }, [token]);

  const [modalState, setModalState] = useState(false);

  // useInterval(async () => {
  //   if (isLoggedIn && !modalState) {
  //     axios({
  //       url: `${process.env.REACT_APP_URL_PATH}/user/terms_conditions_status_logged_in/`,
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //       .then((res) => {
  //         if (!res.data.data.accepted) {
  //           setModalState(true);
  //           setIframeDoc(res.data.data.iframe_url);
  //           showTermsModal();
  //         }
  //       })
  //       .catch((error) => {
  //       });
  //   }
  // }, 300000);

  // if (isLoggedIn === undefined) return <div></div>;


  let isSuperAdmin = JSON.parse(localStorage.getItem('an'))

  
  if (appLoader === true) {
    return <Loader />;
  } else {
    return (
      <Fragment>
        {isLoggedIn && termsModalOpen && (
          <TermsServiesLoggedModal
            isShowModal={termsModalOpen}
            hideModal={hideTermsModal}
            document={iframeDoc}
            setModalState={setModalState}
            logoutHandler={logoutHandler}
          />
        )}

        <Switch>
          <Route path="/" exact>
            <Redirect to="/login" />
          </Route>

          <Route path="/terms-of-services" component={tosRoute} />
          <Route exact path="/initiate_test/:token" component={TestPage} />
          <Route exact path="/privacy-policy" component={TestPage2} />

          <LoginRoute path="/login" exact={true} component={Login} />

          <Route exact={true} path="/verify-otp">
            {!isLoggedIn && pathCheck ? (
              <OTPVerification type="login" />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

           <Route exact={true} path="/catchu-admin-signup">
            {(isLoggedIn && Boolean(isSuperAdmin)) ? <Signup /> : <NotFound />}
          </Route>
      

          <Route exact={true} path="/forgot-password">
            {" "}
            {/*forgot-password screen with only email */}
            <ForgotPassword />
          </Route>
          <Route exact={true} path="/verify-forgot-password-otp">
            {!isLoggedIn && pathCheck ? (
              <OTPVerificationResetPsw type="forgot-password" />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route exact={true} path="/change-password">
            {!isLoggedIn && pathCheck ? (
              <ChangePassword />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route exact={true} path="/set-password/:id/:token">
            <ResetPassword />
          </Route>

          <Route exact={true} path="/edit/profile/">
            {!isLoggedIn && <Redirect to="/login" />}
            {isLoggedIn && (
              <Homepage>
                <EditProfile />
              </Homepage>
            )}
          </Route>

          {/* Protected Routes */}

          {
          routes.map(({ path, name, dynamic, Component, type }, key) => (

            <ProtectedRoute
              key={key}
              path={path}
              name={name}
              dynamic={dynamic}
              exact={true}
              type={type}
              isLoggedIn={isLoggedIn}
              newTabOpen={newTabOpen}
              userRole={userRole}
              permissions={permissions}
              isCatchUAdmin={isCatchUAdmin}
              component={Component}
              redirect={"/login"}
            />
          ))}

          <Route path="*">
            {isLoggedIn ? (
              <Homepage>
                <NotFound />
              </Homepage>
            ) : (
              <NotFound />
            )}
          </Route>
          {/* <Redirect from="*" to="/404" /> */}
        </Switch>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Fragment>
    );
  }
}

export default App;
