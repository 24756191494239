import { createSlice } from "@reduxjs/toolkit";

const initialCrumbState = {
  CorporateName: {
    id: "MY CORPORATE NAME",
  },
  CommunityName: {
    id: "MY COMMUNITY NAME",
  },
  PatientName: {
    id: "MY PATIENT NAME",
  },
  PrepaidPatName: {
    id: "MY PREPAID PATIENT NAME",
  }
};

const crumbSlice = createSlice({
  name: "breadcrumb",
  initialState: initialCrumbState,
  reducers: {
    setCrumbList(state, action) {
      state.crumb = action.payload;
    },

    setCorporateName(state, action) {
      state.CorporateName = action.payload;
    },
    setCommunityName(state, action) {
      state.CommunityName = action.payload;
    },
    setPatientName(state, action) {
      state.PatientName = action.payload;
    },
    setPrepaidPatientName(state, action) {
      state.PrepaidPatName = action.payload;
    },
    },
});

export const crumbActions = crumbSlice.actions;

export default crumbSlice.reducer;
