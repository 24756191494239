import React, { Fragment, useEffect, useState } from "react";

import DeleteModal from "../../Corporate/Modals/DeleteModal/DeleteModal";
import DeleteCreditModal from "../../Corporate/Modals/DeleteCredit/DeleteCreditModal";
import DisableModal from "../../Corporate/Modals/DisableModal/DisableModal";
import EnableModal from "../../Corporate/Modals/EnableModal/EnableModal";
import ShowTestsModal from "../../Corporate/Modals/ShowTestsModal/ShowTestsModal";
import { useDispatch, useSelector } from "react-redux";
import { sidebarActions } from "../../../store/sidebar";
import { communityActions } from "../../../store/community";
import PatientRowActions from "../../UI/Table/RowActions";
import BilleeRowActions from "./BilleeRowActions";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "./Table.scss";
import RowActions from "./RowActions";
import ProviderRowActions from "../../UI/Table/RowActions";
import axios from "axios";
import UpdateIphoneModel from "../Modals/UpdateIphoneModel/UpdateIphoneModel";
import EditBilleeModal from "../Modals/EditBilleeModal/EditBilleeModal";
import EditUserModal from "../Modals/EditUserModal/EditUserModal";
import EditProvider from "../../Provider/Modals/EditProvider";
import RowActionButtons from "./RowActionButtons.Component";
import GroupTestHistoryModal from "../Modals/AddGroupTestModal/GroupTestHistoryModal";
import GroupTestHistoryModalBilling from "../Modals/AddGroupTestModal/GroupTestHistoryModalBilling";
import AddPatient from "../../Patient/Modals/AddPatient";
import ResendModal from "../../Corporate/Modals/ResendModal/ResendModal";
import { providerActions } from "../../../store/provider";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../../utils/helper";
const TableBody = (props) => {
  const { type, data, dataType, communityName, setDataFlag, setShowToast } =
    props;
  const history = useHistory();
  const params = useParams();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disableModalOpen, setDisableModalOpen] = useState(false);
  const [enableModalOpen, setEnableModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editProviderModalOpen, setEditProviderModalOpen] = useState(false);
  const [editBilleeModalOpen, setEditBilleeModalOpen] = useState(false);
  const [showTests, setShowTests] = useState(false);
  const [showEditIphoneModal, setShowEditIphoneModal] = useState(false);

  //const [showGroupTestDetailModal, setShowGroupTestDetailModal] = useState(false);
  //const toggleShowEditIphoneModalgleShow = () => setShowGroupTestDetailModal(!showGroupTestDetailModal);
  // const [iphoneId, setIphoneId] = useState();

  const toggleEditIphoneModal = () =>
    setShowEditIphoneModal(!showEditIphoneModal);
  const showDeleteModal = () => setDeleteModalOpen(true);
  const hideDeleteModal = () => setDeleteModalOpen(false);
  const showDisableModal = () => setDisableModalOpen(true);
  const hideDisableModal = () => setDisableModalOpen(false);
  const showEnableModal = () => setEnableModalOpen(true);
  const hideEnableModal = () => setEnableModalOpen(false);
  const showEditModal = () => setEditModalOpen(true);
  const hideEditModal = () => setEditModalOpen(false);

  const showDeleteCreditModal = (id) => {
    setDeletedId(id);
    setDeleteModalOpen(true);
  };
  const showEditProviderModal = (record) => {
    if (record) {
      setEditProviderModalOpen(true);
      dispatch(providerActions.setEditProviderInfo(record));
    }
  };
  const hideEditProviderModal = () => setEditProviderModalOpen(false);

  const showEditBilleeModal = () => setEditBilleeModalOpen(true);
  const hideEditBilleeModal = () => setEditBilleeModalOpen(false);
  const [resendID, setResendID] = useState(null);
  const [resendModalOpen, setResendModalOpen] = useState(false);
  const showResendModal = () => setResendModalOpen(true);
  const hideResendModal = () => setResendModalOpen(false);
  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);

  // Newly Added States
  const [groupTestHistoryModalOpen, setGroupTestHistoryModalOpen] =
    useState(false);
  const [
    groupTestHistoryBillingModalOpen,
    setGroupTestHistoryBillingModalOpen,
  ] = useState(false);
  const [groupId, setGroupId] = useState(0);

  const showGroupTestHistoryModal = (group_id) => {
    setGroupId(group_id);
    setGroupTestHistoryModalOpen(true);
  };
  const showGroupTestHistoryBillingModal = (group_id) => {
    setGroupId(group_id);
    setGroupTestHistoryBillingModalOpen(true);
  };

  const hideGroupTestHistoryModal = () => setGroupTestHistoryModalOpen(false);
  const hideGroupTestHistoryBillingModal = () =>
    setGroupTestHistoryBillingModalOpen(false);
  // End newly Added States

  const showTestsModal = () => setShowTests(true);
  const hideTestsModal = () => setShowTests(false);

  const [addPatientModalOpen, setAddPatientModalOpen] = useState(false);
  const showAddPatientModalOpen = () => setAddPatientModalOpen(true);
  const hideAddPatientModalOpen = () => setAddPatientModalOpen(false);

  const dispatch = useDispatch();

  const communityList = useSelector((state) => state.corporate.communityList);
  const userList = useSelector((state) => state.community.usersList);
  const iphoneList = useSelector((state) => state.community.iphonesList);

  // Newly Added Selectors
  const groupTestHistory = useSelector(
    (state) => state.community.groupTestHistory
  );
  //End Newly add Selectors

  const [patientEditId, setPatientEditId] = useState(null);
  const [deletedId, setDeletedId] = useState(null);
  const [disabledId, setDisabledId] = useState(null);
  const [enabledId, setEnabledId] = useState(null);

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const deleteModalActions = (commId) => {
    // props.deleteCorporate(commId);
    axios
      .post({
        url: `${process.env.REACT_APP_URL_PATH}/community/delete_community/${commId}`,
      })
      .then((response) => {
        notifySuccess(response.data.message);
      })
      .catch((error) => {
        notifyError(error);
      });
    hideDeleteModal();
  };

  const disbleModalActions = (commId) => {
    axios
      .post({
        url: `${process.env.REACT_APP_URL_PATH}/corporate/disable_corporate/${commId}`,
      })
      .then((response) => {})
      .catch((error) => {});
    hideDisableModal();
  };

  const handleActionType = (corpId) => {
    // const type = props.something();
    if (actionType === "delete") {
      deleteModalActions(corpId);
    } else if (actionType === "disable") {
      disbleModalActions(corpId);
    }
  };

  const actionType = useSelector((state) => state.corporate.actionType);

  const setId = (id, action) => {
    if (dataType === "billee" || dataType === "patient") {
      if (action === "delete") {
        setDeletedId(id);
      } else if (action === "disable") {
        setDisabledId(id);
      } else if (action === "enable") {
        setEnabledId(id);
      } else if (action === "edit") {
        if (dataType === "billee") {
          dispatch(communityActions.setIdToBeEdited(id));
        } else {
          setPatientEditId(id);
        }
      } else if (action === "resend") {
        setResendID(id);
      }
    } else {
      if (action === "delete") {
        setDeletedId(id);
      } else if (action === "disable") {
        setDisabledId(id);
      } else if (action === "enable") {
        setEnabledId(id);
      } else if (action === "resend") {
        setResendID(id);
      }
    }
  };

  const setBreadcrumb = (name, path) => {
    const record = {
      name: name,
      path: path,
    };
  };
  const handleEditAction = (id, name) => {
    if (params.corporateID) {
      history.push(
        `/edit/corporates/${params.corporateID}/community/${params.communityID}/patient/${id}`
      );
    } else {
      history.push(
        `/edit/communities/${params.communityID}/edit/patient/${id}`
      );
    }
  };

  return (
    <Fragment>
      {editBilleeModalOpen && (
        <EditBilleeModal
          isShowModal={editBilleeModalOpen}
          hideModal={hideEditBilleeModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      {editProviderModalOpen && (
        <EditProvider
          isShowModal={editProviderModalOpen}
          hideModal={hideEditProviderModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      {resendModalOpen && (
        <ResendModal
          isShowModal={resendModalOpen}
          hideModal={hideResendModal}
          type={type}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          resendID={resendID}
        />
      )}

      {deleteModalOpen && (
        <DeleteModal
          data={data}
          type={dataType}
          dataType={type}
          isShowModal={deleteModalOpen}
          hideModal={hideDeleteModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          deletedId={deletedId}
        />
      )}
      {/* {deleteModalOpen && (
        <DeleteCreditModal
          data={data}
          type={dataType}
          isShowModal={deleteModalOpen}
          hideModal={hideDeleteModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          deletedId={deletedId}
        />
      )} */}
      {disableModalOpen && (
        <DisableModal
          type={dataType}
          isShowModal={disableModalOpen}
          hideModal={hideDisableModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          disabledId={disabledId}
        />
      )}
      {enableModalOpen && (
        <EnableModal
          type={dataType}
          isShowModal={enableModalOpen}
          hideModal={hideEnableModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          enabledId={enabledId}
        />
      )}

      {showTests && (
        <ShowTestsModal isShowModal={showTests} hideModal={hideTestsModal} />
      )}
      {editModalOpen && (
        <EditUserModal
          isShowModal={editModalOpen}
          hideModal={hideEditModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}

      {groupTestHistoryBillingModalOpen && (
        <GroupTestHistoryModalBilling
          isShowModal={groupTestHistoryBillingModalOpen}
          hideModal={hideGroupTestHistoryBillingModal}
          groupTestId={groupId}
          type={dataType}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      {groupTestHistoryModalOpen && (
        <GroupTestHistoryModal
          isShowModal={groupTestHistoryModalOpen}
          hideModal={hideGroupTestHistoryModal}
          showModal={showGroupTestHistoryModal}
          groupTestId={groupId}
          type={dataType}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      {addPatientModalOpen && (
        <AddPatient
          isShowModal={addPatientModalOpen}
          hideModal={hideAddPatientModalOpen}
          //record={user}
          type={"PatientDetails"}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          outerEdit={true}
          patientEditId={patientEditId}
          isEdit={true}
        />
      )}

      <tbody>
        {dataType === "community" && data?.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        {dataType === "devices" && data?.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        {dataType === "equipments" && data?.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        {dataType === "user" && data?.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        {dataType === "iphone" && data?.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        {dataType === "patient" && data?.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        {dataType === "billee" && data?.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        {dataType === "provider" && data?.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}{" "}
        {dataType === "history" && data?.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        {type === "billing" &&
          dataType === "pastInvoices" &&
          data?.length === 0 && (
            <tr>
              <td colSpan="10" className="text-center">
                No data available in table
              </td>
            </tr>
          )}
        {type === "billing" && dataType === "testToBill" && data?.length === 0 && (
            <tr>
              <td colSpan="14" className="text-center">
                No data available in table
              </td>
            </tr>
          )}
        {type === "billing" &&
          dataType === "creditHistory" &&
          data?.length === 0 && (
            <tr>
              <td colSpan="10" className="text-center">
                No data available in table
              </td>
            </tr>
          )}
        {dataType === "user" &&
          data &&
          data.map((record) => (
            <tr>
              <td>
                {record.data.first_name}
                {record.is_default && (
                  <div style={{ color: "green" }}>Default User</div>
                )}
              </td>
              <td>
                {record.data.last_name}
                {record.is_default && (
                  <div style={{ color: "green" }}>Default User</div>
                )}
              </td>
              <td>{record.data.email}</td>
              <td>{record.data.telephone_number}</td>
              <td>{record.role}</td>
              <RowActions
                setId={setId}
                recordId={record.delete_id}
                isEnable={record.is_enabled}
                isDefault={record.is_default}
                showDeleteModal={showDeleteModal}
                showDisableModal={showDisableModal}
                showEnableModal={showEnableModal}
                type={type}
                dataType={dataType}
                showEditModal={showEditModal}
                delete_id={record.delete_id}
                record={record.data}
                page="sub-page"
                showResendModal={showResendModal}
              />
            </tr>
          ))}
      </tbody>

      {type === "Corporate" && dataType === "community" && (
        <tbody>
          {communityList &&
            communityList.map((record) => (
              <tr key={Math.random()}>
                <td>{record.community_name}</td>
                <td>{record.address.address_1}</td>
                <td>
                  {record.address.address_2 ? record.address.address_2 : "N/A"}
                </td>
                <td>{record.address.state}</td>
                <td>{record.address.city}</td>
                <td>
                  {/* <span className="tag zip-tag">{record.address.zip}</span> */}
                  {record.address.zip}
                </td>
                <RowActions
                  setId={setId}
                  recordId={record.id}
                  isEnable={record.is_enabled}
                  showDeleteModal={showDeleteModal}
                  showDisableModal={showDisableModal}
                  showEnableModal={showEnableModal}
                  name={record.community_name}
                />
              </tr>
            ))}
        </tbody>
      )}

      {type === "Community" && dataType === "iphone" && (
        <tbody>
          {iphoneList &&
            iphoneList.map((record) => (
              <tr key={Math.random()}>
                {/* <td>{record.iphone_id}</td> */}
                <td>{record.number}</td>
                <td>{record.description ? record.description : "N/A"}</td>
                <RowActions
                  data={record}
                  setId={setId}
                  recordId={record.id}
                  isEnable={record.is_enabled}
                  showDeleteModal={showDeleteModal}
                  showDisableModal={showDisableModal}
                  showEnableModal={showEnableModal}
                  showEditModal={toggleEditIphoneModal}
                  dataType={dataType}
                  type={type}
                />
                {showEditIphoneModal && (
                  <UpdateIphoneModel
                    data={record}
                    hideModal={toggleEditIphoneModal}
                    setDataFlag={setDataFlag}
                    setShowToast={setShowToast}
                  />
                )}
              </tr>
            ))}
        </tbody>
      )}

      {/* // Maisam */}
      {type === "Community" && dataType === "billee" && (
        <tbody>
          {data &&
            data.map((record) => (
              <tr key={Math.random()}>
                <td>{record.company}</td>
                <td>{record.first_name}</td>
                <td>{record.last_name}</td>
                <td>{record.email}</td>
                <td>{record.telephone_number}</td>
                <BilleeRowActions
                  setId={setId}
                  recordId={record.delete_id}
                  record={record}
                  isEnable={record.is_enabled}
                  showDeleteModal={showDeleteModal}
                  showDisableModal={showDisableModal}
                  showEnableModal={showEnableModal}
                  showEditModal={showEditBilleeModal}
                  //showEditModal={toggleEditIphoneModal}
                  dataType={dataType}
                  type={type}
                  showResendModal={showResendModal}
                />
              </tr>
            ))}
        </tbody>
      )}

      {type === "Community" && dataType === "provider" && (
        <tbody>
          {data &&
            data.map((record) => (
              <tr key={Math.random()}>
                <td>{record.first_name}</td>
                <td>{record.last_name}</td>
                <td>{record.email}</td>
                <td>{record.telephone_number}</td>
                {!isCatchUAdmin &&
                (userRole.includes("Super Admin") ||
                  userRole.includes("Community Admin") ||
                  userRole.includes("Individual Practice Admin") ||
                  userRole.includes("Support Staff")) ? (
                  <>
                    <ProviderRowActions
                      type={dataType}
                      setId={setId}
                      isEnable={record.is_enabled}
                      recordId={record.id}
                      record={record}
                      showDeleteModal={showDeleteModal}
                      showDisableModal={showDisableModal}
                      showEnableModal={showEnableModal}
                      showEditModal={showEditProviderModal}
                      //showDeleteProviderModal={showDeleteProviderModal}
                      showResendModal={showResendModal}

                      // subType="Patient History"
                    />
                  </>
                ) : (
                  <>
                    {isCatchUAdmin &&
                      (userRole.includes("Super Admin") ||
                        permissions.includes("Providers") ||
                        userRole.includes("Support Staff")) && (
                        <>
                          <ProviderRowActions
                            type={dataType}
                            setId={setId}
                            isEnable={record.is_enabled}
                            recordId={record.id}
                            record={record}
                            showDeleteModal={showDeleteModal}
                            showDisableModal={showDisableModal}
                            showEnableModal={showEnableModal}
                            showEditModal={showEditProviderModal}
                            //showDeleteProviderModal={showDeleteProviderModal}
                            showResendModal={showResendModal}

                            // subType="Patient History"
                          />
                        </>
                      )}
                  </>
                )}
              </tr>
            ))}
        </tbody>
      )}

      {type === "Community" && dataType === "patient" && (
        <tbody>
          {data &&
            data.map((record) => (
              <tr key={Math.random()}>
                <td
                  className="first-td-link"
                  onClick={() =>
                    handleEditAction(
                      record.delete_id,
                      record.first_name + " " + record.last_name
                    )
                  }
                >
                  {record.first_name}
                </td>
                <td
                  className="first-td-link"
                  onClick={() =>
                    handleEditAction(
                      record.delete_id,
                      record.first_name + " " + record.last_name
                    )
                  }
                >
                  {record.last_name}
                </td>
                {/* <td>{record.first_name}</td>
                <td>{record.last_name}</td> */}
                {/* <td>{communityName}</td> */}
                <td>{record.dob}</td>
                <td>{record.email ? record.email : "N/A"}</td>
                <td>
                  {record.telephone_number ? record.telephone_number : "N/A"}
                </td>
                <td>
                  <span className="text-tag">
                    <span
                      className={`tag ${
                        (record.status === "Pending" && "complete-tag") ||
                        (record.status === "Success" && "resend-tag") ||
                        (record.status === "Failed" && "failed-tag") ||
                        (record.status === "Expired" && "failed-tag") ||
                        (record.status === "Initiated" && "pending-tag")
                      }`}
                    >
                      {record.status ? record.status : "N/A"}
                    </span>
                  </span>
                </td>
                <PatientRowActions
                  setId={setId}
                  recordId={record.delete_id}
                  isEnable={record.is_enabled}
                  showAddPatientModalOpen={showAddPatientModalOpen}
                  showDeleteModal={showDeleteModal}
                  showDisableModal={showDisableModal}
                  showEnableModal={showEnableModal}
                  name={record.first_name + " " + record.last_name}
                  //showEditModal={toggleEditIphoneModal}

                  type={dataType}
                  // type={type}
                />
              </tr>
            ))}
        </tbody>
      )}

      {type === "Community" && dataType === "history" && (
        <tbody>
          {data &&
            data.map((record) => (
              <tr key={Math.random()}>
                <td>{record.batch_name}</td>
                <td>{record.created_at}</td>
                <td>{record.expiration_date_am_pm}</td>
                <td>
                  {record.no_of_tests === 0 ? "∞" : record.total_no_of_tests}
                </td>
                <td> {record.batch_test_location}</td>
                <td>
                  <span className="text-tag">
                    <span
                      className={`tag ${
                        (record.status === "Active" && "complete-tag") ||
                        (record.status === "Completed" && "resend-tag") ||
                        (record.status === "Expired" && "failed-tag") ||
                        (record.status === "Terminated" && "pending-tag")
                      }`}
                    >
                      {record.status}
                    </span>
                  </span>
                </td>

                <RowActionButtons
                  setId={setId}
                  recordId={record.group_id}
                  isEnable={record.is_enabled}
                  showDeleteModal={showDeleteModal}
                  showDisableModal={showDisableModal}
                  showEnableModal={showEnableModal}
                  name={record.community_name}
                  showGroupTestDetailModal={() =>
                    showGroupTestHistoryModal(record.group_id)
                  }
                  dataType={dataType}
                  type={type}
                />
              </tr>
            ))}
        </tbody>
      )}

      {type === "Community" && dataType === "devices" && (
        <tbody>
          {data.length > 0 &&
            data?.map((record) => (
              <tr key={Math.random()}>
                <td>{record?.name}</td>
                <td>{record.price}</td>
                <td>{record.description ? record.description : "N/A"}</td>
                <td>{record.bllee_name ? record.bllee_name : "N/A"}</td>
                <td> {record.equipment_id}</td>
              </tr>
            ))}
        </tbody>
      )}

      {type === "billing" && dataType === "equipments" && (
        <tbody>
          {data &&
            data.map((record) => (
              <tr key={Math.random()}>
                <td>{record?.community_id}</td>
                <td>{record.community_name}</td>
                <td>{record.billee_name}</td>
                <td>{record.billee_company}</td>
                <td> {record.equipment_id}</td>
                <td> {record.equipment_price}</td>
              </tr>
            ))}
        </tbody>
      )}

      {type === "billing" && dataType === "pastInvoices" && (
        <tbody>
          {data &&
            data.map((record) => (
              <tr key={Math.random()}>
                <td>
                  <Link
                    to={{ pathname: `${record.invoice_link}` }}
                    target="_blank"
                  >
                    <u>{record.invoice_id}</u>
                  </Link>
                </td>
                {/* <td>{record.invoice_date}</td> */}
                <td>{record.due_date}</td>
                {/* <td>{record.community_name}</td> */}
                <td>
                  <Link
                    to={`/edit/communities/${
                      record.community_id && record.community_id
                    }`}
                  >
                    <u> {record.community_name}</u>
                  </Link>
                </td>
                <td>{record.billee_name}</td>
                <td>{record.billee_company}</td>
                {/* <td>{record.billee_address?record.billee_address:"N/A"}</td> */}
                {/* <td>{record.invoice_code}</td> */}
                <td>
                  <span className="text-tag">
                    <span
                      className={`tag ${
                        (record.status === "Open" && "complete-tag") ||
                        (record.status === "Paid" && "resend-tag") ||
                        (record.status === "Unpaid" && "failed-tag") ||
                        (record.status === "Partially Paid" && "complete-tag")
                      }`}
                    >
                      {record.status}
                    </span>
                  </span>
                </td>

                <td className="invoice_tab_due_amount">
                  $ {record.total ? formatCurrency(record.total) : "0"}
                </td>
                <RowActionButtons
                  setId={setId}
                  recordId={record.invoice_id}
                  isEnable={record.is_enabled}
                  showDeleteModal={showDeleteModal}
                  showDisableModal={showDisableModal}
                  showEnableModal={showEnableModal}
                  name={record.community_name}
                  showGroupTestDetailModal={() =>
                    showGroupTestHistoryBillingModal(record.id)
                  }
                  dataType={dataType}
                  type={type}
                />
              </tr>
            ))}
        </tbody>
      )}

      {type === "billing" && dataType === "testToBill" && (
        <tbody>
          {data &&
            data.map((record) => (
              <tr key={Math.random()}>
                <td>
                  {" "}
                  <Link
                    to={`/edit/patients/${
                      record.patient_user_role_id && record.patient_user_role_id
                    }`}
                  >
                    <u> {record.test_id ? record.test_id : "N/A"}</u>
                  </Link>
                </td>
                <td>
                  <Link
                    to={`/edit/communities/${
                      record.community_id && record.community_id
                    }`}
                  >
                    <u> {record.community_name}</u>
                  </Link>
                </td>
                <td>{record.billee_name}</td>
                <td>{record.billee_company}</td>
                <td>{record.test_date}</td>
                <td>{record.patient_name}</td>
                <td> {record.date_of_birth}</td>
                {/* <td> ${formatCurrency(record.initial_amount)}</td> */}
                <td>
                  {" "}
                  {record.initial_amount === "N/A" ||
                  !record.initial_amount ||
                  typeof parseInt(record.initial_amount) !== "number"
                    ? "N/A"
                    : `$${formatCurrency(record.initial_amount)}`}
                </td>
                {/* <td> {record.test_location ? record.test_location : "N/A"}</td>
                <td>
                  <span className="tag zip-tag">
                    {record.remote ? "Yes" : "No"}
                  </span>
                </td>
                
                  <td> {record.credit_amount==="N/A" || !record.credit_amount ? "N/A" :`$${formatCurrency(record.credit_amount)}` }</td>
                <td> ${record.after_discount_amount}</td>
                <td> {record.amount_reason ? record.amount_reason : "N/A"}</td> */}

                {/* <RowActionButtons
                  setId={setId}
                  recordId={record.test_id}
                  isEnable={record.is_enabled}
                  showDeleteModal={showDeleteModal}
                  showDisableModal={showDisableModal}
                  showEnableModal={showEnableModal}
                  name={record.community_name}
                  deleteCredit={() => showDeleteCreditModal(record.id)} // need test id here
                  dataType={dataType}
                  type={type}
                /> */}
              </tr>
            ))}
        </tbody>
      )}

      {type === "billing" && dataType === "creditHistory" && (
        <tbody>
          {data &&
            data.map((record) => (
              <tr key={Math.random()}>
                <td>
                  {" "}
                  <Link
                    to={`/edit/patients/${
                      record.patient_user_role_id && record.patient_user_role_id
                    }`}
                  >
                    <u>{record.test_id ? record.test_id : "N/A"} </u>{" "}
                  </Link>
                </td>
                <td>
                  <Link
                    to={`/edit/communities/${
                      record.community_id && record.community_id
                    }`}
                  >
                    <u> {record.community_name}</u>
                  </Link>
                </td>
                <td>{record.billee_name ? record.billee_name : "N/A"}</td>
                <td> {record.amount_reason ? record.amount_reason : "N/A"}</td>
                {/* <td>{record.credit_type ? record.credit_type : "N/A"}</td> */}
                <td className="credit_tab_credit_amount">
                  {" "}
                  {record.credit_amount === "N/A" ||
                  !record.credit_amount ||
                  typeof parseInt(record.credit_amount) !== "number"
                    ? "N/A"
                    : `$${formatCurrency(record.credit_amount)}`}
                </td>
                <td> {record.credit_date ? record.credit_date : "N/A"}</td>
                <td>
                  {" "}
                  {record.person_issuing_credit
                    ? record.person_issuing_credit
                    : "N/A"}
                </td>
                <td>
                  {" "}
                  <a
                    href={record.invoice_link && record.invoice_link}
                    target="__blank"
                  >
                    {record.invoice_id ? record.invoice_id : "N/A"}
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      )}
    </Fragment>
  );
};

export default TableBody;
