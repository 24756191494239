import React, { Fragment, useState } from "react";

import DeleteModal from "../../Corporate/Modals/DeleteModal/DeleteModal";
import DisableModal from "../../Corporate/Modals/DisableModal/DisableModal";
import ShowTestsModal from "../../Corporate/Modals/ShowTestsModal/ShowTestsModal";
import EditCommunityModal from "../../EditCommunity/EditCommunityModal/EditCommunityModal";
import { useDispatch, useSelector } from "react-redux";

import "./Table.scss";
import RowActions from "./RowActions";
import { sidebarActions } from "../../../store/sidebar";
import { communityActions } from "../../../store/community";
import EnableModal from "../../Corporate/Modals/EnableModal/EnableModal";
import { useHistory } from "react-router-dom";
import { textHighlighter } from "../../../utils/helper";
import { stripSrciptRegex } from "../../../constants";

const TableBody = (props) => {
  const { type, data, setDataFlag, setShowToast } = props;
  const history = useHistory();

  const [editCommunityModalShow, setEditCommunityModalShow] = useState(false);
  const showEditModalHandler = () => setEditCommunityModalShow(true);
  const hideEditModalHandler = () => setEditCommunityModalShow(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disableModalOpen, setDisableModalOpen] = useState(false);
  const [enableModalOpen, setEnableModalOpen] = useState(false);
  const [showTests, setShowTests] = useState(false);
  const [corpId, setCorpId] = useState();

  const showDeleteModal = () => setDeleteModalOpen(true);
  const hideDeleteModal = () => setDeleteModalOpen(false);
  const showDisableModal = () => setDisableModalOpen(true);
  const hideDisableModal = () => setDisableModalOpen(false);
  const showTestsModal = () => setShowTests(true);
  const hideTestsModal = () => setShowTests(false);
  const showEnableModal = () => setEnableModalOpen(true);
  const hideEnableModal = () => setEnableModalOpen(false);

  const [communityEditId, setCommunityEditId] = useState(null);
  const [deletedId, setDeletedId] = useState(null);
  const [enabledId, setEnabledId] = useState(null);
  const [disabledId, setDisabledId] = useState(null);

  const deleteModalActions = (corpId) => {
    props.deleteCorporate(corpId);
    showDeleteModal();
  };
  const dispatch = useDispatch();
  const setId = (id, type) => {
    if (type === "delete") {
      setDeletedId(id);
      // dispatch(corporateActions.setIdToBeDeleted(id));
    } else if (type === "disable") {
      setDisabledId(id);
      // dispatch(corporateActions.setIdToBeDisabled(id));
    } else if (type === "enable") {
      setEnabledId(id);
      // dispatch(corporateActions.setIdToBeEnabled(id));
    } else if (type === "edit") {
      dispatch(communityActions.setIdToBeEdited(id)); // useless
      setCommunityEditId(id);
    }
  };

  const setBreadcrumb = (name, path) => {
    const record = {
      name: name,
      path: path,
    };
    //dispatch(sidebarActions.setCrumbs(record));
  };
  const handleEditAction = (id, name) => {
    history.push(`/edit/communities/${id}`);
    //setBreadcrumb(name, `/edit/communities/${id}`);
  };

  const strArr1 = [
    "HLC0DL3Z",
    "1QS8RA5P",
    "UVSIE123",
    "OTHER123",
    "MONTE123",
    "UEAS123",
    "CATCH2022",
    "BURKE123",
  ];
  return (
    <Fragment>
      {editCommunityModalShow && (
        <EditCommunityModal
          isShowModal={showEditModalHandler}
          hideModal={hideEditModalHandler}
          type="Community"
          //data={communityInfo}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          outerEdit={true}
          communityEditId={communityEditId}
        />
      )}
      {deleteModalOpen && (
        <DeleteModal
          type={type}
          dataType={"Community"}
          isShowModal={deleteModalOpen}
          hideModal={hideDeleteModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          deletedId={deletedId}
        />
      )}
      {disableModalOpen && (
        <DisableModal
          type={type}
          isShowModal={disableModalOpen}
          hideModal={hideDisableModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          disabledId={disabledId}
        />
      )}
      {enableModalOpen && (
        <EnableModal
          type={type}
          isShowModal={enableModalOpen}
          hideModal={hideEnableModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          enabledId={enabledId}
        />
      )}
      {showTests && (
        <ShowTestsModal isShowModal={showTests} hideModal={hideTestsModal} />
      )}

      <tbody>
        {type=="Equipments"?
        <>
        {data?.length > 0 ? data?.map((record) => (
            <tr key={Math.random()}>
              <td>{record.community_name || "N/A"}</td>
              <td>{record.billee_name}</td>
              <td>{record.billee_company}</td>
              <td>{record.equipment_id}</td>
              <td>{record.equipment_price}</td>
              
              </tr>)): <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>}
        </>
        :
        <>
        {data && data.length !== 0 ? (
          data.map((record) => (
            <tr key={Math.random()}>
              <td
              >
                {record.community_id}
              </td>
              <td
               style={{
                color: `${
                  record?.is_colored
                    ? "lightblue"
                    : ""
                }`,
                wordBreak: 'break-all'
              }}
                className="first-td-link"
                onClick={() =>
                  handleEditAction(record.id, record.community_name)
                }
              >
                {/* {record.community_name} */}
                {record.community_name.replace(stripSrciptRegex, " ")}
              </td>
              {/* <td>{record.community_name}</td> */}
              <td>
                {record.corporate ? record.corporate.corporate_name : "N/A"}
              </td>
              <td>{record.address.address_1}</td>
              <td>
                {record.address.address_2 ? record.address.address_2 : "N/A"}
              </td>
              <td>{record.address.city}</td>
              <td>{record.address.state}</td>
              <td>
                {/* <span className="tag zip-tag">{record.address.zip}</span> */}
                {record.address.zip}
              </td>
              <RowActions
                isEnable={record.is_enabled}
                setId={setId}
                type={type}
                dataType={"Community"}
                recordId={record.id}
                showEditModalHandler={showEditModalHandler}
                showDeleteModal={showDeleteModal}
                showEnableModal={showEnableModal}
                showDisableModal={showDisableModal}
                name={record.community_name}
                page="main"
              />
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        
        </>
        }
        
      </tbody>
    </Fragment>
  );
};

export default TableBody;
