const data = [{
    date: '12/02/2021',
    status: 'complete',
}, {
    date: '13/03/2021',
    status: 'pending',
}, {
    date: '14/04/2021',
    status: 'failed',
}, {
    date: '15/05/2021',
    status: 'complete',
}, {
    date: '16/06/2021',
    status: 'complete',
}];

export default data;