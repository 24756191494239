import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import useInput from "../../../hooks/use-input";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader2 from "../../UI/loader2/loader2";
import SelectComponent from "../../SelectComponent/SelectComponent";
import { getWithExpiry } from "../../utils/helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import "./EditCorporateModal.scss";

// import images
import CloseModal from "../../../assets/close-outline.svg";
import DisabledIcon from "../../../assets/disabled-row.svg";
import Feedback from "react-bootstrap/esm/Feedback";

const isNotEmpty = (value) => value.trim() !== "";
const isEmailValid = (value) => value.includes("@");

function EditCorporateModal(props) {
  const { isShowModal, hideModal, type, setDataFlag, setShowToast, outerEdit } =
    props;

  const [corporateName, setCorporateName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  const [getData, setGetData] = useState(false); // don't know what this is

  const [corporate, setCorporate] = useState({
    corporate_name: "",
    address_1: "",
    address_2: "",
    country: "",
    city: "",
    state: "",
    zip: "",
    default_admin: 0,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "zip") {
      const regex = /^\d+$/;
      if (value === "") {
        setCorporate({ ...corporate, [name]: value });
        return;
      }

      if (regex.test(value) === false) return;
    }
    setCorporate({ ...corporate, [name]: value });
  };

  //End Creating State Object for Form

  const setAddress2Handler = (e) => setAddress2(e.target.value);
  const setStateHandler = (e) => setState(e.target.value);
  const setZipHandler = (e) => setZip(e.target.value);

  const [nameError, setNameError] = useState("");
  const [address1Error, setAddress1Error] = useState("");
  const [cityError, setCityError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [zipError, setZipError] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [roleError, setRoleError] = useState("");

  const corporateAdmins = useSelector(
    (state) => state.corporate.corporateAdmins
  );

  const params = useParams();
  const { corporateID: corporateId } = params;

  const token = "Bearer " + getWithExpiry("expiry_token");

  const [disableBtn, setDisableBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultAdminUsers, setDefaultAdminUsers] = useState("");

  const corporateInfo = useSelector((state) => state.corporate.corporateInfo);
  const idToBeEdited = useSelector((state) => state.corporate.idToBeEdited);

  const corporateNameChangeHandler = (e) => setCorporateName(e.target.value);
  const address1ChangeHandler = (e) => setAddress1(e.target.value);
  const address2ChangeHandler = (e) => setAddress2(e.target.value);
  const cityChangeHandler = (e) => setCity(e.target.value);
  const countryChangeHandler = (e) => setCountry(e.target.value);
  const zipChangeHandler = (e) => setZip(e.target.value);
  const stateChangeHandler = (e) => setState(e.target.value);

  const defaultUser =
    corporateAdmins && corporateAdmins.find((admin) => admin.is_default);
  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    if (outerEdit) {
      axios({
        url: `${process.env.REACT_APP_URL_PATH}/corporate/update_corporate/${idToBeEdited}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((response) => {

          const resData = response.data.data;

          setDefaultAdminUsers(resData.corporate_admins);
          const defaultAdmin =
            resData.corporate_admins &&
            resData.corporate_admins?.find((admin) => admin.is_default);

          setCorporate({
            corporate_name: resData.corporate.corporate_name,
            address_1: resData.corporate.address["address_1"],
            address_2: resData.corporate.address["address_2"],
            country: resData.corporate.address["country"],
            city: resData.corporate.address["city"],
            state: resData.corporate.address["state"],
            zip: resData.corporate.address["zip"],
            default_admin: defaultAdmin ? defaultAdmin?.id : "",
          });
        })
        .catch((error) => {
        });
    } else {
      setCorporate({
        corporate_name: corporateInfo.corporateName,
        address_1: corporateInfo.address1,
        address_2: corporateInfo.address2,
        country: corporateInfo.country,
        city: corporateInfo.city,
        state: corporateInfo.state,
        zip: corporateInfo.zip,
        default_admin: defaultUser ? defaultUser?.id : "",
      });
    }
  }, []);

  const editCorporateSubmissionHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    setDisableBtn(true);

    setNameError("");
    setAddress1Error("");
    setCountryError("");
    setStateError("");
    setCityError("");
    setZipError("");

    const fd = new FormData();
    fd.append("corporate_name", corporate.corporate_name);
    fd.append("address_1", corporate.address_1);
    fd.append("address_2", corporate.address_2);
    fd.append("country", corporate.country);
    fd.append("city", corporate.city);
    fd.append("state", corporate.state);
    fd.append("zip", corporate.zip);
    fd.append("default_admin", corporate.default_admin);

    let url = "";
    if (type === "Corporate") {
      url = `${process.env.REACT_APP_URL_PATH}/corporate/update_corporate/${
        corporateId ? corporateId : idToBeEdited
      }`;
    } else {
      url = `${process.env.REACT_APP_URL_PATH}/community/add_community/`;
    }

    // make http call
    axios({
      method: "post",
      url: url,
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      
      })
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          setDisableBtn(false);
          if (error.response.data.message === "Field Errors") {
            if (
              error.response.data.data["address_form"] &&
              "address_1" in error.response.data.data["address_form"]
            ) {
              setAddress1Error(
                error.response.data.data["address_form"]["address_1"][0]
              );
            }
            if (
              error.response.data.data["address_form"] &&
              "city" in error.response.data.data["address_form"]
            ) {
              setCityError(error.response.data.data["address_form"]["city"][0]);
            }
            if (
              error.response.data.data["address_form"] &&
              "country" in error.response.data.data["address_form"]
            ) {
              setCountryError(
                error.response.data.data["address_form"]["country"][0]
              );
            }
            if (
              error.response.data.data["address_form"] &&
              "state" in error.response.data.data["address_form"]
            ) {
              setStateError(
                error.response.data.data["address_form"]["state"][0]
              );
            }
            if (
              error.response.data.data["address_form"] &&
              "zip" in error.response.data.data["address_form"]
            ) {
              setZipError(error.response.data.data["address_form"]["zip"][0]);
            }
            if (
              error.response.data.data["corporate_form"] &&
              "corporate_name" in error.response.data.data["corporate_form"]
            ) {
              setNameError(
                error.response.data.data["corporate_form"]["corporate_name"][0]
              );
            }
          } else {
            notifyError(error.response.data.message);
          }
        }
      });
    setGetData((prevState) => !prevState);
  };

  // const changeHandler = (e) => {
  //   const { name, value } = e.target;
  //   setCorporate({ corporate, default_admin: value });
  //   setDefaultAdmin(value);
  // };
  return (
    <div className="action-modal-wrapper">
      <Modal
        className="comunitie-modal modal-loader"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header
          className="comubitie-modal-header"
          style={{ padding: "1rem 0rem" }}
        >
          <h1 className="modal-header-title">Edit Corporate</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="comunitie-modal-body">
          <form
            
            method="post"
            className="edit-corporate-form"
            onSubmit={editCorporateSubmissionHandler}
          >
            <div className="edit-corporate-fields">
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="corporate_name" className="label">
                      Name *
                    </label>
                    <input
                      id="corporate_name"
                      name="corporate_name"
                      type="text"
                      placeholder="Name"
                      className="custom-input"
                      // value={corporateName}
                      // onChange={corporateNameChangeHandler}
                      value={corporate.corporate_name}
                      onChange={handleChange}
                      required
                    />
                    {nameError && <p className="error-text">{nameError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="address_1" className="label">
                      Address 01 *
                    </label>
                    <input
                      id="address_1"
                      name="address_1"
                      type="text"
                      placeholder="Address 01"
                      className="custom-input"
                      // value={address1}
                      // onChange={address1ChangeHandler}
                      value={corporate.address_1}
                      onChange={handleChange}
                      required
                    />
                    {address1Error && (
                      <p className="error-text">{address1Error}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="address_2" className="label">
                      Address 02
                    </label>
                    <input
                      type="text"
                      name="address_2"
                      id="address_2"
                      className="custom-input"
                      placeholder="Address 02"
                      // value={address2}
                      // onChange={address2ChangeHandler}
                      value={corporate.address_2}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="city" className="label">
                      City *
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="custom-input"
                      placeholder="City"
                      // value={city}
                      // onChange={cityChangeHandler}
                      value={corporate.city}
                      onChange={handleChange}
                      required
                    />
                    {cityError && <p className="error-text">{cityError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="state" className="label">
                      State *
                    </label>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      className="custom-input"
                      placeholder="State"
                      // value={state}
                      // onChange={stateChangeHandler}
                      value={corporate.state}
                      onChange={handleChange}
                      required
                    />
                    {stateError && <p className="error-text">{stateError}</p>}
                  </div>
                </div>

                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="zip" className="label">
                      Zip *
                    </label>
                    <input
                      type="text"
                      name="zip"
                      id="zip"
                      className="custom-input"
                      placeholder="Zip"
                      // value={zip}
                      // onChange={zipChangeHandler}
                      value={corporate.zip}
                      onChange={handleChange}
                      required
                    />
                    {zipError && <p className="error-text">{zipError}</p>}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="country" className="label">
                      Country *
                    </label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      className="custom-input"
                      placeholder="Country"
                      // value={country}
                      // onChange={countryChangeHandler}
                      value={corporate.country}
                      onChange={handleChange}
                      required
                    />
                    {countryError && (
                      <p className="error-text">{countryError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 left-col">
                  <label htmlFor="default_admin" className="label">
                    Default Admin *
                  </label>
                  <select
                    name="default_admin"
                    id="default_admin"
                    className="custom-select-modal"
                    // onChange={changeHandler}
                    // value={defaultAdmin}
                    onChange={(e) => {
                      setCorporate({
                        ...corporate,
                        default_admin: e.target.value,
                      });
                    }}
                    value={corporate.default_admin}
                  >
                    {outerEdit ? (
                      <>
                        {corporate?.default_admin === "" && (
                          <option>Select Default Admin User</option>
                        )}
                        {defaultAdminUsers &&
                          defaultAdminUsers.map((name) => (
                            <option key={name.id} value={name.id}>
                              {name.first_name} {name.last_name}
                            </option>
                          ))}
                      </>
                    ) : (
                      <>
                        {corporate?.default_admin === "" && (
                          <option>Select Default Admin User</option>
                        )}
                        {corporateAdmins &&
                          corporateAdmins.map((name) => (
                            <option key={name.id} value={name.id}>
                              {name.first_name} {name.last_name}
                            </option>
                          ))}
                      </>
                    )}
                  </select>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            id="modal-close-btn"
            className="btn btn-secondary-fill btn-primary-16"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            // type="submit"
            onClick={editCorporateSubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={disableBtn}
          >
            Update
          </button>
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

export default EditCorporateModal;
