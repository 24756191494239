import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImportCorporateModal from "../Corporate/Modals/ImportCorporateModal/ImportCorporateModal";
import FiltersModal from "../Corporate/Modals/FiltersModal/FiltersModal";
import FilterClose from "../../assets/filter-close.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BilleeCommunityModal from "../Corporate/Modals/FiltersModal/BilleeCommunityModal";
import BilleeInvoicesModal from "../Corporate/Modals/FiltersModal/BilleeInvoicesModal";
import { useParams, useHistory } from "react-router-dom";
import TableEditBody from "../Communities/Table/TableEditBody";

import axios from "axios";
import { billingActions } from "../../store/billing";
import { rowOrderActions } from "../../store/orders";
import {
  removeEmptyParams,
  setOrderAndIcon,
} from "../../services/helpers/sorting.hepler";
import Loader from "../UI/loader/loader";
import SearchInput from "../General/filters/SearchInput.Component";
import FilterButton from "../General/filters/FilterButton.Component";
import useQueryString from "../../hooks/useQueryString";
import Pagination from "../UI/Pagination/Pagination";
import Select, { components } from "react-select";
import { getWithExpiry } from "../utils/helper";
import { filterActions } from "../../store/filter";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { Link } from "react-router-dom";
import { equipmentsActions } from "../../store/equipments";

const TableDataEdit = (props) => {
  const {
    tableHeadings,
    data,
    setInvoiceStatus,
    allPatients,
    setPatientsIds,
    allInvoices,
    type,
    dataType,
    communityId,
    communityName,
    heading,
    totalRecords,
    setDataFlag,
    setShowToast,
    isImport,
    isAdd,
    TabKey,
    otherLoader,
    setOtherLoader,
    billingData,
  } = props;

  const [importCorpModalOpen, setImportCorpModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const token = "Bearer " + getWithExpiry("expiry_token");

  const showImportCorpModal = () => setImportCorpModalOpen(true);
  const hideImportCorpModal = () => setImportCorpModalOpen(false);

  const showFilterModal = () => setFilterModalOpen(true);
  const hideFilterModal = () => setFilterModalOpen(false);

  const communityList = useSelector((state) => state.corporate.communityList);
  const userList = useSelector((state) => state.corporate.userList);

  const parm = useParams();
  const history = useHistory();
  const urlParams = useQueryString();

  const dispatch = useDispatch();
  //const [selectedPatients, setSelectedPatients] = useState([]);
  const [selectedinvoices, setSelectedInvoices] = useState([]);

  // const handleSelectPatients = async (event) => {
  //   setSelectedPatients(event);
  //   let values =
  //     event &&
  //     event.map((patient) => {
  //       return patient.value;
  //     });

  //   setPatientsIds(values);
  //   // dispatch(filterActions.setFilterPatients(values));
  // };
  const handleSelectInvoices = async (event) => {
    setSelectedInvoices(event);
    let values =
      event &&
      event.map((status) => {
        return status.value;
      });

    var result = values.filter((x) => x === "ALL");
    if (result && result.length > 0) {
      setInvoiceStatus("ALL");
      // dispatch(filterActions.setFilterBillingStatus("ALL"));
      return;
    }
    setInvoiceStatus(values);
    // dispatch(filterActions.setFilterBillingStatus(values));
  };

  // move to separate file later
  const iphoneFormData = {
    // iphone_id: "",
    iphone_number: "",
    description: "",
  };

  const userFormData = {
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    role: "",
  };
  const billeeFormData = {
    company: "",
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    address_01: "",
    address_02: "",
    country: "",
    city: "",
    state: "",
    zip: "",
  };

  const patientFormData = {
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    telephone: "",
  };
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [loading, setLoading] = useState(false);
  const [headingName, setHeadingName] = useState("");
  const [search, setSearch] = useState(false);
  const handleSearchChange = (e) => {
    const { value, name } = e.target;

    if (type === "billing" && dataType === "testToBill") {
      dispatch(filterActions.setSearchTestsBillingText(value));
    } else if (type === "billing" && dataType === "pastInvoices") {
      dispatch(filterActions.setSearchInvoicesBillingText(value));
    } else if (type === "billing" && dataType === "creditHistory") {
      dispatch(filterActions.setSearchCreditsBillingText(value));
    } else if (type === "billing" && dataType === "equipments") {
      dispatch(filterActions.setSearchEquipmentsText(value));
    }
  };
  const testsBillingOrderMapping = {
    "Test ID": "test_id",
    "Community Name": "community_name",
    "Billee Name": "billee_name",
    "Billee Company": "billee_company",
    "Patient Name": "patient_name",
    "Test Date": "test_date",
    "Date of Birth": "date_of_birth",
    // "Test Location": "test_location",
    // Remote: "remote",
    "Test Price": "test_price",
    // "Credit Amount": "credit_amount",
    // "Discounted Amount": "after_discount_amount",
    // "Credit Reason": "amount_reason",
  };
  const invoicesBillingOrderMapping = {
    "Invoice #": "invoice_id",
    "Invoice Code": "invoice_code",
    "Invoice Due Date": "due_date",
    "Community Name": "community_name",
    "Billee Name": "billee_name",
    "Billee Company": "billee_company",
    "Invoice Date": "invoice_date",
    "Invoice Status": "status",
    "Amount Due": "total",
    // "Community Name": "community_id",
    "Billee Address": "billee_address",
  };
  const creditHistoryOrderMapping = {
    "Community Name": "community_name",
    "Credit Type": "credit_type",
    "Test ID": "test_id",
    "Billee Name": "billee_name",
    "Credit Amount": "credit_amount",
    "Credit Reason": "amount_reason",
    "Credit Date": "credit_date",
    "Person issuing Credit": "person_issuing_credit",
    "Invoice #": "invoice_id",
  };

  const equipmentsOrderMapping = {
    "Community ID": "community_id",
    "Community Name": "community_name",
    "Billee Name": "billee_name",
    "Company Name": "billee_company",
    "Equipments Price": "equipment_price",
  };

  const urlMapping = {
    testToBill: "/billing",
    pastInvoices: "/billing",
    creditHistory: "/billing",
    equipments: "/billing",
  };

  const searchTestsBillingText = useSelector(
    (state) => state.filter.searchTestsBillingText
  );
  const searchInvoicesBillingText = useSelector(
    (state) => state.filter.searchInvoicesBillingText
  );
  const searchCreditsBillingText = useSelector(
    (state) => state.filter.searchCreditsBillingText
  );

  const searchEquipmentsText = useSelector(
    (state) => state.filter.searchEquipmentsText
  );

  const rowOrderStateBillingTestsBill = useSelector(
    (state) => state.rowOrder.rowOrderBillingTestsBill
  );
  const rowOrderStateBillingPastInvoices = useSelector(
    (state) => state.rowOrder.rowOrderBillingPastInvoices
  );
  const rowOrderBillingEquipments = useSelector(
    (state) => state.rowOrder.rowOrderBillingEquipments
  );

  const rowOrderStateCreditHistory = useSelector(
    (state) => state.rowOrder.rowOrderCreditHistory
  );

  const testsBillingOrderQuery = useSelector(
    (state) => state.billing.testsBillingOrderQuery
  );
  const invoicesBillingOrderQuery = useSelector(
    (state) => state.billing.invoicesBillingOrderQuery
  );
  const creditHistoryOrderQuery = useSelector(
    (state) => state.billing.creditHistoryOrderQuery
  );

  const filterBillingCommunities = useSelector(
    (state) => state.filter.filterBillingCommunities
  );
  const testToBillCommunities = useSelector(
    (state) => state.filter.testToBillCommunities
  );
  const creditHistoryCommunities = useSelector(
    (state) => state.filter.creditHistoryCommunities
  );
  const pastInvoicesCommunities = useSelector(
    (state) => state.filter.pastInvoicesCommunities
  );
  const filterBillingStatus = useSelector(
    (state) => state.filter.filterBillingStatus
  );

  const [orderState, setOrderState] = useState(null);
  const [prefix, setPrefix] = useState("");

  const orderMapping = {
    testToBill: testsBillingOrderMapping,
    pastInvoices: invoicesBillingOrderMapping,
    creditHistory: creditHistoryOrderMapping,
    equipments: equipmentsOrderMapping,
  };
  const rowOrderMapping = {
    testToBill: rowOrderStateBillingTestsBill,
    pastInvoices: rowOrderStateBillingPastInvoices,
    creditHistory: rowOrderStateCreditHistory,
    Equipments: {},
  };
  useEffect(() => {
    updateUrl();
  }, [orderState]);

  const buildRowOrderQuery = () => {
    let newOrderQuery = "";
    if (dataType && dataType === "testToBill") {
      newOrderQuery = testsBillingOrderQuery.slice();
    } else if (dataType && dataType === "pastInvoices") {
      newOrderQuery = invoicesBillingOrderQuery.slice();
    } else if (dataType && dataType === "creditHistory") {
      newOrderQuery = creditHistoryOrderQuery.slice();
    }

    let mappingHeaderName, rowOrderHeaderName;
    if (type === "billing") {
      if (dataType === "testToBill") {
        mappingHeaderName = testsBillingOrderMapping[headingName];
        rowOrderHeaderName =
          rowOrderStateBillingTestsBill[testsBillingOrderMapping[headingName]];
      } else if (dataType === "pastInvoices") {
        mappingHeaderName = invoicesBillingOrderMapping[headingName];
        rowOrderHeaderName =
          rowOrderStateBillingPastInvoices[
            invoicesBillingOrderMapping[headingName]
          ];
      } else if (dataType === "creditHistory") {
        mappingHeaderName = creditHistoryOrderMapping[headingName];
        rowOrderHeaderName =
          rowOrderStateCreditHistory[creditHistoryOrderMapping[headingName]];
      }
    }

    if (mappingHeaderName) {
      let prevOrder = rowOrderHeaderName.order === "asc" ? "desc" : "asc";
      let prevInstance = `&${mappingHeaderName}:orderby=${prevOrder}`;
      if (newOrderQuery.includes(prevInstance)) {
        newOrderQuery = newOrderQuery.replace(prevInstance, "");
      }
      if (!search) {
        newOrderQuery = `&${mappingHeaderName}:orderby=${rowOrderHeaderName.order}`;
      }
    }
    if (dataType && dataType === "testToBill") {
      dispatch(billingActions.setTestsBillingOrderQuery(newOrderQuery));
    } else if (dataType && dataType === "pastInvoices") {
      dispatch(billingActions.setInvoicesBillingOrderQuery(newOrderQuery));
    } else if (dataType && dataType === "creditHistory") {
      dispatch(billingActions.setCreditHistoryOrderQuery(newOrderQuery));
    }

    return newOrderQuery;
  };

  const buildFilterQuery = () => {
    let filterQuery = "";
    // if (type === "Corporate") {
    //   if (dataType === "community") {
    //     filterQuery =
    //       `country:eq=${communityCorporateFilteredCountry}` +
    //       `&city:eq=${communityCorporateFilteredCity}` +
    //       `&state:eq=${communityCorporateFilteredState}` +
    //       `&zip:eq=${communityCorporateFilteredZip}`;
    //   } else if (dataType === "user") {
    //     filterQuery = `role:eq=${userCorporateFilteredRole}`;
    //   }
    // }
    return filterQuery;
  };

  const buildSearchTextQuery = () => {
    let searchQuery = "";
    if (type === "billing" && dataType === "testToBill") {
      searchQuery =
        searchTestsBillingText !== ""
          ? `search_query=${searchTestsBillingText}`
          : "";
    } else if (type === "billing" && dataType === "pastInvoices") {
      searchQuery =
        searchInvoicesBillingText !== ""
          ? `search_query=${searchInvoicesBillingText}`
          : "";
    } else if (type === "billing" && dataType === "creditHistory") {
      searchQuery =
        searchCreditsBillingText !== ""
          ? `search_query=${searchCreditsBillingText}`
          : "";
    } else if (type === "billing" && dataType === "equipments") {
      searchQuery =
        searchEquipmentsText !== ""
          ? `search_query=${searchEquipmentsText}`
          : "";
    }
    return searchQuery;
  };
  const getPrefix = () => {
    let newPrefix = prefix;

    return newPrefix;
  };

  const updateUrl = () => {
    const searchQuery = buildSearchTextQuery();
    const newOrderQuery = buildRowOrderQuery();
    const filterQuery = buildFilterQuery();

    let customQuery = "";

    customQuery = `${searchQuery}${newOrderQuery}&${filterQuery}`;

    let modifiedString = removeEmptyParams(customQuery);

    let urlParamString = urlParams.toString();

    const newPrefix = getPrefix();
    let searchParam = "";

    if (!modifiedString) {
      searchParam =
        "?" +
        `${
          search === true
            ? newPrefix +
              modifiedString +
              `page=${search ? 1 : urlParams.get("page")}`
            : newPrefix + urlParamString
        }`;
    } else {
      if (
        !modifiedString.includes("page") &&
        urlParams.toString().includes("page")
      ) {
        searchParam =
          "?" +
          newPrefix +
          modifiedString +
          `&page=${search ? 1 : urlParams.get("page")}`;
      } else {
        searchParam = "?" + newPrefix + modifiedString;
      }
    }

    searchParam = searchParam.replace("&&", "&");
    history.replace({
      pathname: urlMapping[dataType],
      search: searchParam,
    });

    let str =
      modifiedString && modifiedString !== ""
        ? "?" +
          `${
            dataType === "testToBill"
              ? "tab_key=test&"
              : dataType === "creditHistory"
              ? "tab_key=credit_history&"
              : dataType === "equipments"
              ? "tab_key=equipments&"
              : "tab_key=invoice&"
          }` +
          modifiedString
        : "";

    if (dataType && dataType === "testToBill") {
      dispatch(billingActions.setTestsBillingApiUrl(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "pastInvoices") {
      dispatch(billingActions.setInvoicesBillingApiUrl(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "equipastInvoices") {
      dispatch(equipmentsActions.setInvoicesBillingApiUrl(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "creditHistory") {
      dispatch(billingActions.setCreditHistoryApiUrl(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "Equipments") {
      dispatch(billingActions.setEquipmentsApiUrl(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    }
  };

  const onClickTableHeading = async (e) => {
    const { id } = e.target;

    if (id === "Actions" || id === "View") return;
    setOtherLoader(true);
    setSearch(false);
    setHeadingName(id);

    //let prefix = "";
    if (type === "billing") {
      if (dataType && dataType === "testToBill") {
        setPrefix("tab_key=test&");
        //prefix = "table=schedule_test&";
      } else if (dataType && dataType === "pastInvoices") {
        setPrefix("tab_key=invoice&");
        //prefix = "table=upcoming_test&";
      } else if (dataType && dataType === "creditHistory") {
        setPrefix("tab_key=credit_history&");
        //prefix = "table=upcoming_test&";
      } else if (dataType && dataType === "equipments") {
        setPrefix("tab_key=equipments&");
        //prefix = "table=upcoming_test&";
      }
    }

    let name = "";
    let obj;
    if (type === "billing") {
      if (dataType === "testToBill") {
        switch (id) {
          case "Test ID":
            name = "test_id";
            obj = setOrderAndIcon(rowOrderStateBillingTestsBill[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingTestsBillOrder({
                ...rowOrderStateBillingTestsBill,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                patient_name: { state: 1, order: "", icon: "" },
                test_date: { state: 1, order: "", icon: "" },
                date_of_birth: { state: 1, order: "", icon: "" },
                test_location: { state: 1, order: "", icon: "" },
                remote: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                test_price: { state: 1, order: "", icon: "" },
                after_discount_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Community Name":
            name = "community_name";
            obj = setOrderAndIcon(rowOrderStateBillingTestsBill[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingTestsBillOrder({
                ...rowOrderStateBillingTestsBill,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                test_id: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                patient_name: { state: 1, order: "", icon: "" },
                test_date: { state: 1, order: "", icon: "" },
                date_of_birth: { state: 1, order: "", icon: "" },
                test_location: { state: 1, order: "", icon: "" },
                remote: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                test_price: { state: 1, order: "", icon: "" },
                after_discount_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Billee Name":
            name = "billee_name";
            obj = setOrderAndIcon(rowOrderStateBillingTestsBill[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingTestsBillOrder({
                ...rowOrderStateBillingTestsBill,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                patient_name: { state: 1, order: "", icon: "" },
                test_date: { state: 1, order: "", icon: "" },
                date_of_birth: { state: 1, order: "", icon: "" },
                test_location: { state: 1, order: "", icon: "" },
                remote: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                test_price: { state: 1, order: "", icon: "" },
                after_discount_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Billee Company":
            name = "billee_company";

            obj = setOrderAndIcon(rowOrderStateBillingTestsBill[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingTestsBillOrder({
                ...rowOrderStateBillingTestsBill,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                patient_name: { state: 1, order: "", icon: "" },
                test_date: { state: 1, order: "", icon: "" },
                date_of_birth: { state: 1, order: "", icon: "" },
                test_location: { state: 1, order: "", icon: "" },
                remote: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                test_price: { state: 1, order: "", icon: "" },
                after_discount_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Patient Name":
            name = "patient_name";
            obj = setOrderAndIcon(rowOrderStateBillingTestsBill[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingTestsBillOrder({
                ...rowOrderStateBillingTestsBill,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                test_date: { state: 1, order: "", icon: "" },
                date_of_birth: { state: 1, order: "", icon: "" },
                test_location: { state: 1, order: "", icon: "" },
                remote: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                test_price: { state: 1, order: "", icon: "" },
                after_discount_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Test Date":
            name = "test_date";
            obj = setOrderAndIcon(rowOrderStateBillingTestsBill[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingTestsBillOrder({
                ...rowOrderStateBillingTestsBill,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                patient_name: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                date_of_birth: { state: 1, order: "", icon: "" },
                test_location: { state: 1, order: "", icon: "" },
                remote: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                test_price: { state: 1, order: "", icon: "" },
                after_discount_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Date of Birth":
            name = "date_of_birth";
            obj = setOrderAndIcon(rowOrderStateBillingTestsBill[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingTestsBillOrder({
                ...rowOrderStateBillingTestsBill,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                patient_name: { state: 1, order: "", icon: "" },
                test_date: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                test_location: { state: 1, order: "", icon: "" },
                remote: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                test_price: { state: 1, order: "", icon: "" },
                after_discount_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Test Location":
            name = "test_location";
            obj = setOrderAndIcon(rowOrderStateBillingTestsBill[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingTestsBillOrder({
                ...rowOrderStateBillingTestsBill,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                patient_name: { state: 1, order: "", icon: "" },
                test_date: { state: 1, order: "", icon: "" },
                date_of_birth: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                remote: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                test_price: { state: 1, order: "", icon: "" },
                after_discount_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Remote":
            name = "remote";
            obj = setOrderAndIcon(rowOrderStateBillingTestsBill[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingTestsBillOrder({
                ...rowOrderStateBillingTestsBill,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                patient_name: { state: 1, order: "", icon: "" },
                test_date: { state: 1, order: "", icon: "" },
                date_of_birth: { state: 1, order: "", icon: "" },
                test_location: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                test_price: { state: 1, order: "", icon: "" },
                after_discount_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Credit Amount":
            name = "credit_amount";
            obj = setOrderAndIcon(rowOrderStateBillingTestsBill[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingTestsBillOrder({
                ...rowOrderStateBillingTestsBill,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                patient_name: { state: 1, order: "", icon: "" },
                test_date: { state: 1, order: "", icon: "" },
                date_of_birth: { state: 1, order: "", icon: "" },
                test_location: { state: 1, order: "", icon: "" },
                remote: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                test_price: { state: 1, order: "", icon: "" },
                after_discount_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Test Price":
            name = "test_price";
            obj = setOrderAndIcon(rowOrderStateBillingTestsBill[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingTestsBillOrder({
                ...rowOrderStateBillingTestsBill,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                patient_name: { state: 1, order: "", icon: "" },
                test_date: { state: 1, order: "", icon: "" },
                date_of_birth: { state: 1, order: "", icon: "" },
                test_location: { state: 1, order: "", icon: "" },
                remote: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                after_discount_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Discounted Amount":
            name = "after_discount_amount";
            obj = setOrderAndIcon(rowOrderStateBillingTestsBill[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingTestsBillOrder({
                ...rowOrderStateBillingTestsBill,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                patient_name: { state: 1, order: "", icon: "" },
                test_date: { state: 1, order: "", icon: "" },
                date_of_birth: { state: 1, order: "", icon: "" },
                test_location: { state: 1, order: "", icon: "" },
                remote: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                test_price: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
            // case "Credit Reason":
            //   name = "amount_reason";
            //   obj = setOrderAndIcon(rowOrderStateBillingTestsBill[name]);
            //   dispatch(
            //     rowOrderActions.setRowOrderBillingTestsBillOrder({
            //       ...rowOrderStateBillingTestsBill,
            //       [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            //       community_name: { state: 1, order: "", icon: "" },
            //       billee_name: { state: 1, order: "", icon: "" },
            //       billee_company: { state: 1, order: "", icon: "" },
            //       patient_name: { state: 1, order: "", icon: "" },
            //       test_date: { state: 1, order: "", icon: "" },
            //       date_of_birth: { state: 1, order: "", icon: "" },
            //       test_location: { state: 1, order: "", icon: "" },
            //       reason: { state: 1, order: "", icon: "" },
            //       credit_amount: { state: 1, order: "", icon: "" },
            //       test_price: { state: 1, order: "", icon: "" },
            //       after_discount_amount: { state: 1, order: "", icon: "" },
            //       test_id: { state: 1, order: "", icon: "" },
            //     })
            //   );
            //   setOrderState({
            //     ...orderState,
            //     [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            //   });
            return;
          default:
            return;
        }
      } else if (dataType === "equipments") {
        switch (id) {
          case "Community Name":
            name = "community_name";
            obj = setOrderAndIcon(rowOrderBillingEquipments[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingEquipments({
                ...rowOrderBillingEquipments,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
        }
      } else if (dataType === "pastInvoices") {
        switch (id) {
          case "Community Name":
            name = "community_name";
            obj = setOrderAndIcon(rowOrderStateBillingPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingPastInvoicesOrder({
                ...rowOrderStateBillingPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Invoice #":
            name = "invoice_id";
            obj = setOrderAndIcon(rowOrderStateBillingPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingPastInvoicesOrder({
                ...rowOrderStateBillingPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Invoice Code":
            name = "invoice_code";
            obj = setOrderAndIcon(rowOrderStateBillingPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingPastInvoicesOrder({
                ...rowOrderStateBillingPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Billee Name":
            name = "billee_name";
            obj = setOrderAndIcon(rowOrderStateBillingPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingPastInvoicesOrder({
                ...rowOrderStateBillingPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Billee Company":
            name = "billee_company";
            obj = setOrderAndIcon(rowOrderStateBillingPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingPastInvoicesOrder({
                ...rowOrderStateBillingPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Invoice Date":
            name = "invoice_date";
            obj = setOrderAndIcon(rowOrderStateBillingPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingPastInvoicesOrder({
                ...rowOrderStateBillingPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Invoice Due Date":
            name = "due_date";
            obj = setOrderAndIcon(rowOrderStateBillingPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingPastInvoicesOrder({
                ...rowOrderStateBillingPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Amount Due":
            name = "total";
            obj = setOrderAndIcon(rowOrderStateBillingPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingPastInvoicesOrder({
                ...rowOrderStateBillingPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Invoice Status":
            name = "status";
            obj = setOrderAndIcon(rowOrderStateBillingPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingPastInvoicesOrder({
                ...rowOrderStateBillingPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Community Name":
            name = "community_id";
            obj = setOrderAndIcon(rowOrderStateBillingPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderBillingPastInvoicesOrder({
                ...rowOrderStateBillingPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          // case "Billee Address":
          //   name = "billee_address";
          //   obj = setOrderAndIcon(rowOrderStateBillingPastInvoices[name]);
          //   dispatch(
          //     rowOrderActions.setRowOrderBillingPastInvoicesOrder({
          //       ...rowOrderStateBillingPastInvoices,
          //       [name]: { state: obj.state, order: obj.order, icon: obj.icon },
          //       invoice_code: { state: 1, order: "", icon: "" },
          //       billee_name: { state: 1, order: "", icon: "" },
          //       billee_company: { state: 1, order: "", icon: "" },
          //       community_name: { state: 1, order: "", icon: "" },
          //       invoice_date: { state: 1, order: "", icon: "" },
          //       due_date: { state: 1, order: "", icon: "" },
          //       invoice_id: { state: 1, order: "", icon: "" },
          //       total: { state: 1, order: "", icon: "" },

          //     })
          //   );
          //   setOrderState({
          //     ...orderState,
          //     [name]: { state: obj.state, order: obj.order, icon: obj.icon },
          //   });
          //   return;
          // default:
          //   return;
        }
      } else if (dataType === "creditHistory") {
        switch (id) {
          case "Community Name":
            name = "community_name";
            obj = setOrderAndIcon(rowOrderStateCreditHistory[name]);
            dispatch(
              rowOrderActions.setRowOrderCreditHistory({
                ...rowOrderStateCreditHistory,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                credit_type: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
                credit_date: { state: 1, order: "", icon: "" },
                person_issuing_credit: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Credit Type":
            name = "credit_type";
            obj = setOrderAndIcon(rowOrderStateCreditHistory[name]);
            dispatch(
              rowOrderActions.setRowOrderCreditHistory({
                ...rowOrderStateCreditHistory,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
                credit_date: { state: 1, order: "", icon: "" },
                person_issuing_credit: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Test ID":
            name = "test_id";
            obj = setOrderAndIcon(rowOrderStateCreditHistory[name]);
            dispatch(
              rowOrderActions.setRowOrderCreditHistory({
                ...rowOrderStateCreditHistory,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                credit_type: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
                credit_date: { state: 1, order: "", icon: "" },
                person_issuing_credit: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Billee Name":
            name = "billee_name";
            obj = setOrderAndIcon(rowOrderStateCreditHistory[name]);
            dispatch(
              rowOrderActions.setRowOrderCreditHistory({
                ...rowOrderStateCreditHistory,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                credit_type: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
                credit_date: { state: 1, order: "", icon: "" },
                person_issuing_credit: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Credit Amount":
            name = "credit_amount";
            obj = setOrderAndIcon(rowOrderStateCreditHistory[name]);
            dispatch(
              rowOrderActions.setRowOrderCreditHistory({
                ...rowOrderStateCreditHistory,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                credit_type: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
                credit_date: { state: 1, order: "", icon: "" },
                person_issuing_credit: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Credit Reason":
            name = "amount_reason";

            obj = setOrderAndIcon(rowOrderStateCreditHistory[name]);

            dispatch(
              rowOrderActions.setRowOrderCreditHistory({
                ...rowOrderStateCreditHistory,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                credit_type: { state: 1, order: "", icon: "" },
                credit_date: { state: 1, order: "", icon: "" },
                person_issuing_credit: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
              })
            );

            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Credit Date":
            name = "credit_date";
            obj = setOrderAndIcon(rowOrderStateCreditHistory[name]);
            dispatch(
              rowOrderActions.setRowOrderCreditHistory({
                ...rowOrderStateCreditHistory,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
                credit_type: { state: 1, order: "", icon: "" },
                person_issuing_credit: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Person issuing Credit":
            name = "person_issuing_credit";
            obj = setOrderAndIcon(rowOrderStateCreditHistory[name]);
            dispatch(
              rowOrderActions.setRowOrderCreditHistory({
                ...rowOrderStateCreditHistory,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
                credit_type: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Invoice #":
            name = "invoice_id";
            obj = setOrderAndIcon(rowOrderStateCreditHistory[name]);
            dispatch(
              rowOrderActions.setRowOrderCreditHistory({
                ...rowOrderStateCreditHistory,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                test_id: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                credit_amount: { state: 1, order: "", icon: "" },
                amount_reason: { state: 1, order: "", icon: "" },
                credit_type: { state: 1, order: "", icon: "" },
                person_issuing_credit: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          default:
            return;
        }
      }
    }
    return;
  };

  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      setSearch(true);
      setOtherLoader(true);
      if (type === "billing") {
        if (dataType && dataType === "testToBill") {
          setPrefix("tab_key=test&");
        } else if (dataType && dataType === "pastInvoices") {
          setPrefix("tab_key=invoice&");
        } else if (dataType && dataType === "creditHistory") {
          setPrefix("tab_key=credit_history&");
        } else if (dataType && dataType === "equipments") {
          setPrefix("tab_key=equipments&");
          setDataFlag((prevState) => !prevState);
        }
      }
      setOrderState({
        ...orderState,
      });
    }
  };
  const onClearSorting = () => {
    setOtherLoader(true);
    if (dataType && dataType === "testToBill") {
      dispatch(
        rowOrderActions.setRowOrderBillingTestsBillOrder({
          ...rowOrderStateBillingTestsBill,
          test_id: { state: 1, order: "", icon: "" },
          patient_name: { state: 1, order: "", icon: "" },
          test_date: { state: 1, order: "", icon: "" },
          date_of_birth: { state: 1, order: "", icon: "" },
          test_location: { state: 1, order: "", icon: "" },
          remote: { state: 1, order: "", icon: "" },
          credit_amount: { state: 1, order: "", icon: "" },
          amount_reason: { state: 1, order: "", icon: "" },
        })
      );
      dispatch(billingActions.setTestsBillingOrderQuery(""));
      history.replace({
        pathname: "/billing",
        search: "?page=1",
      });
      dispatch(billingActions.setTestsBillingApiUrl(""));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "pastInvoices") {
      dispatch(
        rowOrderActions.setRowOrderBillingPastInvoicesOrder({
          ...rowOrderStateBillingPastInvoices,
          invoice_id: { state: 1, order: "", icon: "" },
          invoice_date: { state: 1, order: "", icon: "" },
          due_date: { state: 1, order: "", icon: "" },
          status: { state: 1, order: "", icon: "" },
          total: { state: 1, order: "", icon: "" },
        })
      );
      dispatch(billingActions.setInvoicesBillingOrderQuery(""));
      history.replace({
        pathname: "/billing",
        search: "?page=1",
      });
      dispatch(billingActions.setInvoicesBillingApiUrl(""));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "creditHistory") {
      dispatch(
        rowOrderActions.setRowOrderCreditHistory({
          ...rowOrderStateCreditHistory,
          credit_type: { state: 1, order: "", icon: "" },
          test_id: { state: 1, order: "", icon: "" },
          billee_name: { state: 1, order: "", icon: "" },
          credit_amount: { state: 1, order: "", icon: "" },
          amount_reason: { state: 1, order: "", icon: "" },
          credit_date: { state: 1, order: "", icon: "" },
        })
      );
      dispatch(billingActions.setCreditHistoryOrderQuery(""));
      history.replace({
        pathname: "/billing",
        search: "?page=1",
      });
      dispatch(billingActions.setCreditHistoryApiUrl(""));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    }
  };
  const onClearSearch = () => {
    setOtherLoader(true);
    if (type === "billing" && dataType === "testToBill") {
      dispatch(filterActions.setSearchTestsBillingText(""));
    } else if (type === "billing" && dataType === "pastInvoices") {
      dispatch(filterActions.setSearchInvoicesBillingText(""));
    } else if (type === "billing" && dataType === "creditHistory") {
      dispatch(filterActions.setSearchCreditsBillingText(""));
    } else if (type === "billing" && dataType === "equipmetns") {
      dispatch(filterActions.setSearchEquipmentsText(""));
    }
    setSearch(true);
    setOrderState({
      ...orderState,
    });
  };
  function importFormData() {
    if (type === "Community") {
      if (dataType === "iphone") {
        return iphoneFormData;
      } else if (dataType === "user") {
        return userFormData;
      } else if (dataType === "billee") {
        return billeeFormData;
      } else if (dataType === "patient") {
        return patientFormData;
      }
    }
  }

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const downloadReport = () => {
    if (startDate && endDate) {
      setLoading(true);
      let invoice_from = "";
      let invoice_to = "";
      invoice_from = Moment(startDate.toLocaleDateString()).format(
        "MM-DD-YYYY"
      );
      invoice_to = Moment(endDate.toLocaleDateString()).format("MM-DD-YYYY");
      const fd = new FormData();

      fd.append("invoice_date_from", invoice_from);
      fd.append("invoice_date_to", invoice_to);

      axios({
        url: `${process.env.REACT_APP_URL_PATH}/billing/download_reconciliation_report/`,
        method: "POST",
        data: fd,
        //responseType: "arraybuffer",

        headers: {
          //"Content-Type": "blob",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((response) => {
          notifySuccess(
            "Reconciliation report generated and sent to your email."
          );
          setLoading(false);
        })
        .catch((error) => {
          notifyError("Something went wrong. Try again later.");
          setLoading(false);
          //notifyError(error.response.data.message);
        });
    } else {
      notifyError("Date range not specified");
    }
  };

  const billeeObject = () => {
    if (dataType === "testToBill") {
      const newEvent = billingData.all_communities.reduce((results, event) => {
        if (testToBillCommunities.includes(event.community_id)) {
          results.push({
            value: event.community_id,
            label: event.community_name,
          });
        }
        return results;
      }, []);
      return newEvent;
    } else if (dataType === "pastInvoices") {
      const newEvent = billingData?.all_communities?.reduce(
        (results, event) => {
          if (pastInvoicesCommunities.includes(event.community_id)) {
            results.push({
              value: event.community_id,
              label: event.community_name,
            });
          }
          return results;
        },
        []
      );
      return newEvent;
    } else {
      const newEvent = billingData.all_communities.reduce((results, event) => {
        if (creditHistoryCommunities.includes(event.community_id)) {
          results.push({
            value: event.community_id,
            label: event.community_name,
          });
        }
        return results;
      }, []);
      return newEvent;
    }
  };
  const removeSelectedCommunity = (community) => {
    if (dataType === "testToBill") {
      if (testToBillCommunities.length === 1) {
        dispatch(filterActions.setFilterBillingCommunities("ALL"));
        dispatch(filterActions.setTestToBillCommunities("ALL"));
        history.replace({
          pathname: "/billing",
          search: "",
        });
      } else {
        const updatedList = testToBillCommunities.filter(
          (item) => item !== community.value
        );
        dispatch(filterActions.setFilterBillingCommunities(updatedList));
        dispatch(filterActions.setTestToBillCommunities(updatedList));
      }
    } else if (dataType === "pastInvoices") {
      if (pastInvoicesCommunities.length === 1) {
        dispatch(filterActions.setFilterBillingCommunities("ALL"));
        dispatch(filterActions.setPastInvoicesCommunities("ALL"));
        history.replace({
          pathname: "/billing",
          search: "",
        });
      } else {
        const updatedList = pastInvoicesCommunities.filter(
          (item) => item !== community.value
        );
        dispatch(filterActions.setFilterBillingCommunities(updatedList));
        dispatch(filterActions.setPastInvoicesCommunities(updatedList));
      }
    } else {
      if (creditHistoryCommunities.length === 1) {
        dispatch(filterActions.setFilterBillingCommunities("ALL"));
        dispatch(filterActions.setCreditHistoryCommunities("ALL"));
        history.replace({
          pathname: "/billing",
          search: "",
        });
      } else {
        const updatedList = creditHistoryCommunities.filter(
          (item) => item !== community.value
        );
        dispatch(filterActions.setFilterBillingCommunities(updatedList));
        dispatch(filterActions.setCreditHistoryCommunities(updatedList));
      }
    }

    setOrderState({
      ...orderState,
    });
  };
  const removeSelectedStatus = (status) => {
    if (filterBillingStatus.length === 1) {
      dispatch(filterActions.setFilterBillingStatus([]));
      history.replace({
        pathname: "/billing",
        search: "",
      });
    } else {
      const updatedList = filterBillingStatus.filter((item) => item !== status);
      dispatch(filterActions.setFilterBillingStatus(updatedList));
    }
    setOrderState({
      ...orderState,
    });
  };
  const clearFilters = () => {
    if (dataType === "testToBill") {
      dispatch(filterActions.setFilterBillingCommunities("ALL"));
      dispatch(filterActions.setTestToBillCommunities("ALL"));
    } else if (dataType === "pastInvoices") {
      dispatch(filterActions.setFilterBillingCommunities("ALL"));
      dispatch(filterActions.setPastInvoicesCommunities("ALL"));
      dispatch(filterActions.setFilterBillingStatus("ALL"));
    } else {
      dispatch(filterActions.setFilterBillingCommunities("ALL"));
      dispatch(filterActions.setCreditHistoryCommunities("ALL"));
    }

    setOrderState({
      ...orderState,
    });
  };
  const paramType = !dataType ? type : dataType;
  return (
    <Fragment>
      {importCorpModalOpen && (
        <ImportCorporateModal
          isShowModal={importCorpModalOpen}
          hideModal={hideImportCorpModal}
          type={type}
          dataType={dataType}
          communityId={communityId}
          modalFormData={importFormData()}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      {filterModalOpen && type === "billing" && dataType === "testToBill" && (
        <BilleeCommunityModal
          isShowModal={filterModalOpen}
          hideModal={hideFilterModal}
          billingData={billingData}
          setShowToast={setShowToast}
          setDataFlag={setDataFlag}
          orderBy={testsBillingOrderQuery}
          setOtherLoader={setOtherLoader}
          searchText={searchTestsBillingText}
          dataType={dataType}
        />
      )}
      {filterModalOpen &&
        type === "billing" &&
        dataType === "creditHistory" && (
          <BilleeCommunityModal
            isShowModal={filterModalOpen}
            hideModal={hideFilterModal}
            billingData={billingData}
            setShowToast={setShowToast}
            setDataFlag={setDataFlag}
            orderBy={creditHistoryOrderQuery}
            setOtherLoader={setOtherLoader}
            searchText={searchCreditsBillingText}
            dataType={dataType}
          />
        )}
      {filterModalOpen && type === "billing" && dataType === "pastInvoices" && (
        <BilleeInvoicesModal
          isShowModal={filterModalOpen}
          hideModal={hideFilterModal}
          billingData={billingData}
          setShowToast={setShowToast}
          setDataFlag={setDataFlag}
          orderBy={invoicesBillingOrderQuery}
          setOtherLoader={setOtherLoader}
          searchText={searchInvoicesBillingText}
        />
      )}
      {/* {filterModalOpen && (
        <FiltersModal
          type={type}
          isShowModal={filterModalOpen}
          hideModal={hideFilterModal}
        />
      )} */}

      {otherLoader || loading ? (
        <Loader />
      ) : (
        <div className="corpoarte-table">
          <div className="table-header-filter">
            <div className="cor-header-change">
              <h1 className="page-name-heading">{heading}</h1>
              {dataType !== "history" && (
                <div className="right-cor-header">
                  <ul className="rc-header-listing">
                    {dataType === "pastInvoices" && (
                      <>
                        <li className="single-rc">
                          <div className="date-time-wrapper">
                            <DatePicker
                              className="custom-dateTime-input"
                              placeholderText="Select your Date Range"
                              isClearable
                              selectsRange={true}
                              startDate={startDate}
                              endDate={endDate}
                              onChange={(update) => {
                                setDateRange(update);
                              }}
                              dateFormat="MM/dd/yyyy"
                              maxDate={new Date()}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              disabledKeyboardNavigation

                              // isClearable={true}
                            />
                            <span className="dateTime-divider"></span>
                          </div>
                        </li>
                        <li className="single-rc">
                          <button
                            className="btn btn-primary-fill btn-primary-16"
                            onClick={downloadReport}
                          >
                            Send Invoice Report
                          </button>
                        </li>
                      </>
                    )}
                    {/* <li className="single-rc">
                    <button onClick={onClearSorting}>Clear Sorting</button>
                  </li> */}
                    {/* <li className="single-rc">
                    <button onClick={onClearSearch}>Clear Search</button>
                  </li> */}
                    <li className="single-rc">
                      {type === "billing" && dataType === "testToBill" && (
                        <SearchInput
                          value={searchTestsBillingText}
                          handleChange={handleSearchChange}
                          onKeyEnter={onEnterPress}
                          onClearSearch={onClearSearch}
                        />
                      )}
                      {type === "billing" && dataType === "pastInvoices" && (
                        <SearchInput
                          value={searchInvoicesBillingText}
                          handleChange={handleSearchChange}
                          onKeyEnter={onEnterPress}
                          onClearSearch={onClearSearch}
                        />
                      )}
                      {type === "billing" && dataType === "creditHistory" && (
                        <SearchInput
                          value={searchCreditsBillingText}
                          handleChange={handleSearchChange}
                          onKeyEnter={onEnterPress}
                          onClearSearch={onClearSearch}
                        />
                      )}
                      {type === "billing" && dataType === "equipments" && (
                        <SearchInput
                          value={searchEquipmentsText}
                          handleChange={handleSearchChange}
                          onKeyEnter={onEnterPress}
                          onClearSearch={onClearSearch}
                        />
                      )}
                    </li>
                    {type === "billing" && dataType != "equipments" && (
                      <li className="single-rc">
                        <FilterButton handleClick={showFilterModal} />
                      </li>
                    )}

                    {/* {type === "billing" && dataType === "testToBill" && (
                    <div>
                      <li className="cdh-inner-single">
                        <Select
                          name="patients"
                          id="patients"
                          onChange={handleSelectPatients}
                          placeholder={"Select Patients"}
                          closeMenuOnSelect={false}
                          isMulti
                          value={selectedPatients}
                          options={
                            allPatients &&
                            allPatients.map((patient) => ({
                              value: patient.patient_id,
                              label: patient.patient_name,
                            }))
                          }
                          components={{ MultiValue }}
                        />
                      </li>
                    </div>
                  )} */}

                    {/* {type === "billing" && dataType === "pastInvoices" && (
                    <div>
                      <li className="cdh-inner-single">
                        <Select
                          name="invoiceStatus"
                          id="invoiceStatus"
                          placeholder={"Select Invoice"}
                          // className="custom-select-modal"
                          onChange={handleSelectInvoices}
                          value={selectedinvoices}
                          closeMenuOnSelect={false}
                          isMulti
                          options={
                            allInvoices &&
                            allInvoices.map((invoiceStatus) => ({
                              value: invoiceStatus,
                              label: invoiceStatus,
                            }))
                          }
                        />
                      </li>
                    </div>
                  )} */}
                  </ul>
                </div>
              )}
            </div>
            {type === "billing" && dataType === "testToBill" && (
              <>
                {testToBillCommunities.length !== 0 &&
                  testToBillCommunities !== "ALL" && (
                    <div className="filter-header">
                      <h2 className="apply-filter-name">Applied Filters:</h2>
                      <ul className="apply-filter-list">
                        {billeeObject().map((community) => (
                          <li className="apply-filter-single">
                            <h3 className="filter-name-type">
                              Community: <strong>{community.label}</strong>{" "}
                            </h3>
                            <img
                              src={FilterClose}
                              alt=""
                              onClick={() => removeSelectedCommunity(community)}
                            />
                          </li>
                        ))}
                      </ul>
                      <Link
                        to={"/billing"}
                        className="clear-all-filter"
                        onClick={clearFilters}
                      >
                        Clear all filters
                      </Link>
                    </div>
                  )}
              </>
            )}
            {type === "billing" && dataType === "creditHistory" && (
              <>
                {creditHistoryCommunities.length !== 0 &&
                  creditHistoryCommunities !== "ALL" && (
                    <div className="filter-header">
                      <h2 className="apply-filter-name">Applied Filters:</h2>
                      <ul className="apply-filter-list">
                        {billeeObject().map((community) => (
                          <li className="apply-filter-single">
                            <h3 className="filter-name-type">
                              Community: <strong>{community.label}</strong>{" "}
                            </h3>
                            <img
                              src={FilterClose}
                              alt=""
                              onClick={() => removeSelectedCommunity(community)}
                            />
                          </li>
                        ))}
                      </ul>
                      <Link
                        to={"/billing"}
                        className="clear-all-filter"
                        onClick={clearFilters}
                      >
                        Clear all filters
                      </Link>
                    </div>
                  )}
              </>
            )}
            {type === "billing" && dataType === "pastInvoices" && (
              <>
                {((pastInvoicesCommunities.length !== 0 &&
                  pastInvoicesCommunities !== "ALL") ||
                  (filterBillingStatus?.length !== 0 &&
                    filterBillingStatus !== [])) && (
                  <div className="filter-header">
                    <h2 className="apply-filter-name">Applied Filters:</h2>
                    <ul className="apply-filter-list">
                      {billeeObject()?.map((community) => (
                        <li className="apply-filter-single">
                          <h3 className="filter-name-type">
                            Community: <strong>{community.label}</strong>{" "}
                          </h3>
                          <img
                            src={FilterClose}
                            alt=""
                            onClick={() => removeSelectedCommunity(community)}
                          />
                        </li>
                      ))}
                      {filterBillingStatus?.map((status) => (
                        <li className="apply-filter-single">
                          <h3 className="filter-name-type">
                            Invoice: <strong>{status}</strong>{" "}
                          </h3>
                          <img
                            src={FilterClose}
                            alt=""
                            onClick={() => removeSelectedStatus(status)}
                          />
                        </li>
                      ))}
                    </ul>
                    <Link
                      to={"/billing"}
                      className="clear-all-filter"
                      onClick={clearFilters}
                    >
                      Clear all filters
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="table-responsive">
            <table className="table" width="en-width">
              <thead>
                <tr>
                  {tableHeadings &&
                    tableHeadings?.map((tableHead) => (
                      <th
                        id={tableHead}
                        name={tableHead}
                        key={tableHead}
                        onClick={onClickTableHeading}
                      >
                        {tableHead}
                        {tableHead !== "Actions" && tableHead !== "View" && (
                          <>
                            {rowOrderMapping[paramType][
                              orderMapping[paramType][tableHead]
                            ]?.icon === "" ? (
                              <span className="icons-th">
                                <span></span>
                                <span className="order-4"></span>
                              </span>
                            ) : (
                              <span className="icons-th">
                                <span
                                  className={
                                    rowOrderMapping[paramType][
                                      orderMapping[paramType][tableHead]
                                    ]?.icon === "asc"
                                      ? "caret-4-asc-icon"
                                      : "caret-4-desc-icon"
                                  }
                                ></span>
                                <span className="order-4"></span>
                              </span>
                            )}
                          </>
                        )}
                      </th>
                    ))}
                </tr>
              </thead>
              <TableEditBody
                type={type}
                data={data}
                dataType={dataType}
                deleteCorporate={props.deleteCorporate}
                communityName={communityName}
                setDataFlag={setDataFlag}
                setShowToast={setShowToast}
              />
            </table>
          </div>

          {type === "billing" && dataType === "pastInvoices" && (
            <Pagination
              dataType={dataType}
              type={type}
              data={data}
              totalRecords={totalRecords}
              parm={parm.id}
              prefix={"tab_key=invoice&"}
              setOtherLoader={setOtherLoader}
            />
          )}
          {type === "billing" && dataType === "testToBill" && (
            <Pagination
              dataType={dataType}
              type={type}
              data={data}
              totalRecords={totalRecords}
              parm={parm.id}
              prefix={"tab_key=test&"}
              setOtherLoader={setOtherLoader}
            />
          )}
          {type === "billing" && dataType === "creditHistory" && (
            <Pagination
              dataType={dataType}
              type={type}
              data={data}
              totalRecords={totalRecords}
              parm={parm.id}
              prefix={"tab_key=credit_history&"}
              setOtherLoader={setOtherLoader}
            />
          )}
          {type === "billing" && dataType === "equipments" && (
            <Pagination
              dataType={dataType}
              type={type}
              data={data}
              totalRecords={totalRecords}
              parm={parm.id}
              prefix={"tab_key=credit_history&"}
              setOtherLoader={setOtherLoader}
            />
          )}
        </div>
      )}
      {/* {dataType === TabKey && (
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      )} */}
    </Fragment>
  );
};

export default TableDataEdit;

const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    borderRadius: "4px",
    fontFamily: "Open Sans",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 3;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};
