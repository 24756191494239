import React from "react";
import "./loader2.scss";
import LoaderImage from "../../../assets/loader.gif";
function Loader2() {
  return (
    <div className="loader2-wrapper">
      <img className="loader2-image" src={LoaderImage} alt="loader_image" />
    </div>
  );
}

export default Loader2;
