import React, { useState, useEffect, Fragment } from "react";
import { Modal } from "react-bootstrap";
import "./GroupTestHistoryModal.scss";
import TableComponent from "../../Table/TableData";
import CloseModal from "../../../../assets/close-outline.svg";
import { Link } from "react-router-dom";
import Filter_Icon from "../../../../assets/filter-icon.svg";
import axios from "axios";
import { getWithExpiry } from "../../../utils/helper";
import Loader2 from "../../../UI/loader2/loader2";
import ProviderModal from "../../../PatientDetail/Modals/ProviderModal";
import { filterActions } from "../../../../store/filter";
import {
  removeEmptyParams,
  setOrderAndIcon,
} from "../../../../services/helpers/sorting.hepler";
import SearchInput from "../../../General/filters/SearchInput.Component";
import { useSelector, useDispatch } from "react-redux";
import { rowOrderActions } from "../../../../store/orders";
import ResendButton from "../../../../assets/resend-button.svg";
import { formatCurrency } from "../../../../utils/helper";
function GroupTestHistoryModal(props) {
  const {
    isShowModal,
    hideModal,
    showModal,
    type,
    setDataFlag,
    setShowToast,
    selectedTest,
    isEdit,
    communityInfo,
    groupTestId,
  } = props;

  const tableHeadings = [
    "First Name",
    "Last Name",
    "Ordered",
    "Test Date",
    "Location",
    "Remote",
    "Status",
    "Credit Amount",
    "Credit Reason",
    "Actions",
  ];

  // apiPath = `community/group_test_history/community_id?page=PAGE_NO`

  const [groupTestHistoryDetail, setGroupTestHistoryDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [otherLoader, setOtherLoader] = useState(false);
  const [filterTestID, setFilterTestID] = useState(null);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const hideFilterModal = () => setFilterModalOpen(false);
  const showFilterModal = (testID) => {
    setFilterTestID(testID);
    setFilterModalOpen(true);
  };

  const dispatch = useDispatch();

  const [orderState, setOrderState] = useState(null);
  const [orderState2, setOrderState2] = useState(null);

  // const [showToast, setShowToast] = useState(false);

  const token = getWithExpiry("expiry_token");

  const [orderQuery, setOrderQuery] = useState("");
  const [headingName, setHeadingName] = useState("");
  const [search, setSearch] = useState(false);
  const [apiUrl, setApiUrl] = useState("");
  const handleSearchChange = (e) => {
    const { value, name } = e.target;
    dispatch(filterActions.setSearchGroupTestHistoryText(value));
  };

  useEffect(() => {
    // if (!showToast) {
    //    setLoading(true);
    // }
    let query = apiUrl ? apiUrl + "&" : "?";
    const newUrl = `${process.env.REACT_APP_URL_PATH}/community/group_test_details/${groupTestId}${query}`;
    axios({
      url: newUrl,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        const resData = response.data.data;
        setGroupTestHistoryDetail(resData.test_history);
        setOtherLoader(false);
      })
      .catch((error) => {
        setLoading(false);
        setOtherLoader(false);
      });
  }, [orderState]);

  const groupTestHistoryOrderMapping = {
    "First Name": "patient_first_name",
    "Last Name": "patient_last_name",
    Ordered: "test_ordered_date",
    "Test Date": "date_scheduled",
    Location: "test_location",
    Remote: "remote",
    Status: "status",
    "Credit Amount": "credit_amount",
    "Credit Reason": "amount_reason",
  };

  const searchGroupTestHistoryText = useSelector(
    (state) => state.filter.searchGroupTestHistoryText
  );

  const rowOrderStateGroupTestHistory = useSelector(
    (state) => state.rowOrder.rowOrderGroupTestHistory
  );

  useEffect(() => {
    updateUrl();
  }, [orderState2]);

  const buildRowOrderQuery = () => {
    let newOrderQuery = orderQuery.slice();

    let mappingHeaderName = groupTestHistoryOrderMapping[headingName];
    let rowOrderHeaderName =
      rowOrderStateGroupTestHistory[groupTestHistoryOrderMapping[headingName]];

    if (mappingHeaderName) {
      let prevOrder = rowOrderHeaderName.order === "asc" ? "desc" : "asc";
      let prevInstance = `&${mappingHeaderName}:orderby=${prevOrder}`;
      if (newOrderQuery.includes(prevInstance)) {
        newOrderQuery = newOrderQuery.replace(prevInstance, "");
      }
      if (!search) {
        newOrderQuery = `&${mappingHeaderName}:orderby=${rowOrderHeaderName.order}`;
      }
    }
    setOrderQuery(newOrderQuery);
    return newOrderQuery;
  };

  const buildFilterQuery = () => {
    let filterQuery = "";

    return filterQuery;
  };

  const buildSearchTextQuery = () => {
    let searchQuery = "";
    searchQuery =
      searchGroupTestHistoryText !== ""
        ? `search_query=${searchGroupTestHistoryText}`
        : "";
    return searchQuery;
  };

  const updateUrl = () => {
    const searchQuery = buildSearchTextQuery();
    const newOrderQuery = buildRowOrderQuery();
    const filterQuery = buildFilterQuery();

    let customQuery = "";

    customQuery = `${searchQuery}${newOrderQuery}&${filterQuery}`;

    let modifiedString = removeEmptyParams(customQuery);

    let str =
      modifiedString && modifiedString !== "" ? "?" + modifiedString : "";

    setApiUrl(str);
    setOrderState({
      ...orderState,
    });
  };

  const onClickTableHeading = async (e) => {
    const { id } = e.target;
    setSearch(false);
    setOtherLoader(true);
    setHeadingName(id);

    let name = "";
    let obj;
    switch (id) {
      case "First Name":
        name = "patient_first_name";
        obj = setOrderAndIcon(rowOrderStateGroupTestHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderGroupTestHistory({
            ...rowOrderStateGroupTestHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            patient_last_name: { state: 1, order: "", icon: "" },
            test_ordered_date: { state: 1, order: "", icon: "" },
            date_scheduled: { state: 1, order: "", icon: "" },
            test_location: { state: 1, order: "", icon: "" },
            remote: { state: 1, order: "", icon: "" },
            status: { state: 1, order: "", icon: "" },
            credit_amount: { state: 1, order: "", icon: "" },
            amount_reason: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      case "Last Name":
        name = "patient_last_name";
        obj = setOrderAndIcon(rowOrderStateGroupTestHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderGroupTestHistory({
            ...rowOrderStateGroupTestHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            patient_first_name: { state: 1, order: "", icon: "" },
            test_ordered_date: { state: 1, order: "", icon: "" },
            date_scheduled: { state: 1, order: "", icon: "" },
            test_location: { state: 1, order: "", icon: "" },
            remote: { state: 1, order: "", icon: "" },
            status: { state: 1, order: "", icon: "" },
            credit_amount: { state: 1, order: "", icon: "" },
            amount_reason: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      case "Ordered":
        name = "test_ordered_date";
        obj = setOrderAndIcon(rowOrderStateGroupTestHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderGroupTestHistory({
            ...rowOrderStateGroupTestHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            patient_last_name: { state: 1, order: "", icon: "" },
            patient_first_name: { state: 1, order: "", icon: "" },
            date_scheduled: { state: 1, order: "", icon: "" },
            test_location: { state: 1, order: "", icon: "" },
            remote: { state: 1, order: "", icon: "" },
            status: { state: 1, order: "", icon: "" },
            credit_amount: { state: 1, order: "", icon: "" },
            amount_reason: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      case "Test Date":
        name = "date_scheduled";
        obj = setOrderAndIcon(rowOrderStateGroupTestHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderGroupTestHistory({
            ...rowOrderStateGroupTestHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            patient_last_name: { state: 1, order: "", icon: "" },
            test_ordered_date: { state: 1, order: "", icon: "" },
            patient_first_name: { state: 1, order: "", icon: "" },
            test_location: { state: 1, order: "", icon: "" },
            remote: { state: 1, order: "", icon: "" },
            status: { state: 1, order: "", icon: "" },
            credit_amount: { state: 1, order: "", icon: "" },
            amount_reason: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      case "Location":
        name = "test_location";
        obj = setOrderAndIcon(rowOrderStateGroupTestHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderGroupTestHistory({
            ...rowOrderStateGroupTestHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            patient_last_name: { state: 1, order: "", icon: "" },
            test_ordered_date: { state: 1, order: "", icon: "" },
            date_scheduled: { state: 1, order: "", icon: "" },
            patient_first_name: { state: 1, order: "", icon: "" },
            remote: { state: 1, order: "", icon: "" },
            status: { state: 1, order: "", icon: "" },
            credit_amount: { state: 1, order: "", icon: "" },
            amount_reason: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      case "Remote":
        name = "remote";
        obj = setOrderAndIcon(rowOrderStateGroupTestHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderGroupTestHistory({
            ...rowOrderStateGroupTestHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            patient_last_name: { state: 1, order: "", icon: "" },
            test_ordered_date: { state: 1, order: "", icon: "" },
            date_scheduled: { state: 1, order: "", icon: "" },
            test_location: { state: 1, order: "", icon: "" },
            patient_first_name: { state: 1, order: "", icon: "" },
            status: { state: 1, order: "", icon: "" },
            credit_amount: { state: 1, order: "", icon: "" },
            amount_reason: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      case "Status":
        name = "status";
        obj = setOrderAndIcon(rowOrderStateGroupTestHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderGroupTestHistory({
            ...rowOrderStateGroupTestHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            patient_last_name: { state: 1, order: "", icon: "" },
            test_ordered_date: { state: 1, order: "", icon: "" },
            date_scheduled: { state: 1, order: "", icon: "" },
            test_location: { state: 1, order: "", icon: "" },
            remote: { state: 1, order: "", icon: "" },
            patient_first_name: { state: 1, order: "", icon: "" },
            credit_amount: { state: 1, order: "", icon: "" },
            amount_reason: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      case "Credit Amount":
        name = "credit_amount";
        obj = setOrderAndIcon(rowOrderStateGroupTestHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderGroupTestHistory({
            ...rowOrderStateGroupTestHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            patient_last_name: { state: 1, order: "", icon: "" },
            test_ordered_date: { state: 1, order: "", icon: "" },
            date_scheduled: { state: 1, order: "", icon: "" },
            test_location: { state: 1, order: "", icon: "" },
            remote: { state: 1, order: "", icon: "" },
            status: { state: 1, order: "", icon: "" },
            patient_first_name: { state: 1, order: "", icon: "" },
            amount_reason: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      case "Credit Reason":
        name = "amount_reason";
        obj = setOrderAndIcon(rowOrderStateGroupTestHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderGroupTestHistory({
            ...rowOrderStateGroupTestHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            patient_last_name: { state: 1, order: "", icon: "" },
            test_ordered_date: { state: 1, order: "", icon: "" },
            date_scheduled: { state: 1, order: "", icon: "" },
            test_location: { state: 1, order: "", icon: "" },
            remote: { state: 1, order: "", icon: "" },
            status: { state: 1, order: "", icon: "" },
            credit_amount: { state: 1, order: "", icon: "" },
            patient_first_name: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      default:
        return;
    }
  };
  const onClearSorting = () => {
    setOtherLoader(true);
    dispatch(
      rowOrderActions.setRowOrderGroupTestHistory({
        ...rowOrderStateGroupTestHistory,
        patient_first_name: { state: 1, order: "", icon: "" },
        patient_last_name: { state: 1, order: "", icon: "" },
        test_ordered_date: { state: 1, order: "", icon: "" },
        date_scheduled: { state: 1, order: "", icon: "" },
        test_location: { state: 1, order: "", icon: "" },
        remote: { state: 1, order: "", icon: "" },
        status: { state: 1, order: "", icon: "" },
        credit_amount: { state: 1, order: "", icon: "" },
        amount_reason: { state: 1, order: "", icon: "" },
      })
    );
    setOrderQuery("");
    setHeadingName("");
    setOrderState2({
      ...orderState2,
    });
  };
  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      setSearch(true);
      setOtherLoader(true);
      updateUrl();
    }
  };
  const onClearSearch = () => {
    setOtherLoader(true);
    dispatch(filterActions.setSearchGroupTestHistoryText(""));
    setSearch(true);
    setOrderState2({
      ...orderState2,
    });
  };
  return (
    <Fragment>
      {filterModalOpen && (
        <ProviderModal
          isShowModal={filterModalOpen}
          hideModal={hideFilterModal}
          setShowToast={setShowToast}
          setDataFlag={setDataFlag}
          testID={filterTestID}
          showModal={showModal}
          groupTestId={groupTestId}
        />
      )}
      <div className="action-modal-wrapper">
        <Modal
          className="groupTest-modal audit-modal"
          show={isShowModal}
          size="lg"
          onHide={hideModal}
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable
        >
          <Modal.Header className="audit-modal-header">
            <h1 className="modal-header-title"></h1>
            <span
              className="close-icon-modal "
              onClick={() => {
                dispatch(
                  rowOrderActions.setRowOrderGroupTestHistory({
                    ...rowOrderStateGroupTestHistory,
                    patient_first_name: { state: 1, order: "", icon: "" },
                    patient_last_name: { state: 1, order: "", icon: "" },
                    test_ordered_date: { state: 1, order: "", icon: "" },
                    date_scheduled: { state: 1, order: "", icon: "" },
                    test_location: { state: 1, order: "", icon: "" },
                    remote: { state: 1, order: "", icon: "" },
                    status: { state: 1, order: "", icon: "" },
                    credit_amount: { state: 1, order: "", icon: "" },
                    amount_reason: { state: 1, order: "", icon: "" },
                  })
                );
                hideModal();
              }}
            >
              <img src={CloseModal} alt="" />
            </span>
          </Modal.Header>

          <Modal.Body className="filter-modal-body">
            <div className="cor-header">
              <h1 className="page-name-heading">Patient History</h1>

              <div className="right-cor-header">
                <ul className="rc-header-listing">
                  {/* <li className="single-rc">
                    <button onClick={onClearSorting}>Clear Sorting</button>
                  </li> */}
                  {/* <li className="single-rc">
                    <button onClick={onClearSearch}>Clear Search</button>
                  </li> */}
                  <li className="single-rc">
                    <SearchInput
                      value={searchGroupTestHistoryText}
                      handleChange={handleSearchChange}
                      onKeyEnter={onEnterPress}
                      onClearSearch={onClearSearch}
                    />
                  </li>
                </ul>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table" id="en-width">
                <thead>
                  <tr>
                    {tableHeadings &&
                      tableHeadings.map((tableHead) => (
                        <th
                          id={tableHead}
                          name={tableHead}
                          key={tableHead}
                          onClick={onClickTableHeading}
                        >
                          {tableHead}
                          {tableHead !== "Actions" && (
                            <>
                              {rowOrderStateGroupTestHistory[
                                groupTestHistoryOrderMapping[tableHead]
                              ].icon === "" ? (
                                <span className="icons-th">
                                  <span></span>
                                  <span className="order-4"></span>
                                </span>
                              ) : (
                                <span className="icons-th">
                                  <span
                                    className={
                                      rowOrderStateGroupTestHistory[
                                        groupTestHistoryOrderMapping[tableHead]
                                      ].icon === "asc"
                                        ? "caret-4-asc-icon"
                                        : "caret-4-desc-icon"
                                    }
                                  ></span>
                                  <span className="order-4"></span>
                                </span>
                              )}
                            </>
                          )}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {groupTestHistoryDetail.length === 0 && (
                    <tr>
                      <td colSpan="10" className="text-center">
                        No data available in table
                      </td>
                    </tr>
                  )}
                  {groupTestHistoryDetail &&
                    groupTestHistoryDetail.map((record) => (
                      <tr key={record.id}>
                        <td>{record.patient_first_name}</td>
                        <td>{record.patient_last_name}</td>
                        <td>{record.test_ordered_date}</td>
                        <td>{record.date_scheduled}</td>
                        <td>{record.test_location}</td>
                        <td>
                          <span className="tag zip-tag">
                            {record.is_remote ? "Yes" : "No"}
                          </span>
                        </td>
                        <td>
                          <span className="text-tag">
                            <p className="before-tag-text">{record.status}</p>
                          </span>
                        </td>
                        <td> {record.credit_amount==="N/A" || !record.credit_amount || typeof (parseInt(record.credit_amount)) !=="number"? "N/A" :`$${formatCurrency(record.credit_amount)}` }</td>
                        <td>{record.amount_reason}</td>
                        <td>
                          <span
                            title="Resend Report"
                            className="td-action"
                            onClick={() => {
                              //hideModal();
                              showFilterModal(record.id);
                            }}
                          >
                            {record.status === "Success" && (
                              <img src={ResendButton} alt="Resend Report" />
                            )}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          {(loading || otherLoader) && <Loader2 />}
        </Modal>
      </div>
    </Fragment>
  );
}

export default GroupTestHistoryModal;
