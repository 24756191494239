import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import useInput from "../../../../hooks/use-input";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// import RolesList from "./RolesList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "react-phone-number-input/input";
import axios from "axios";
import "./EditUserModal.scss";
import { getWithExpiry } from "../../../utils/helper";
// import images
import CloseModal from "../../../../assets/close-outline.svg";
import DisabledIcon from "../../../../assets/disabled-row.svg";
import regUser from "../../../../store/regUser";
import Loader2 from "../../../UI/loader2/loader2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const isNotEmpty = (value) => value.trim() !== "";
const isEmailValid = (value) => value.includes("@");

function EditUserModal(props) {

  const regUserInfo = useSelector((state) => state.reguser.regUserInfo);
  const { isShowModal, hideModal, type, setDataFlag, setShowToast } = props;

  const [role, setRole] = useState("");
  const [otherRole, setOtherRole] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [addBtnDisabled, setAddBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showCorporates, setShowCorporates] = useState(false);
  const [showCommunities, setShowCommunities] = useState(false);
  const [corporatePermissions, setCorporatePermissions] = useState(true);
  const [communityPermissions, setCommunityPermissions] = useState(true);
  const [showIndivCommunities, setShowIndivCommunities] = useState(false);

  const [phoneValue, setPhoneValue] = useState("");
  const communityList = useSelector((state) => state.corporate.communityList);
  const isCatchuAdmin = useSelector((state) => state.user.isCatchuAdmin);
  const permissions = useSelector((state) => state.user.permissions);
  const indEnabledCommunities = communityList.filter(
    (community) => community.is_individual_practice === true
  );

  const params = useParams();
  const { corporateID: corporateId } = params;
  const idToBeEdited = useSelector((state) => state.reguser.idToBeEdited);

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    telephone_number: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };


  useEffect(() => {
    setUser({
      ...user,
      first_name: regUserInfo.first_name,
      last_name: regUserInfo.last_name,
      email: regUserInfo.email,
      telephone_number: regUserInfo.telephone_number,
    });
    setPhoneValue(regUserInfo.telephone_number);

    if (regUserInfo.role === "Community Admin") {
      setShowCommunities(true);
      setRole(regUserInfo.role);
      setOtherRole(regUserInfo.community_id);
    } else {
      setRole(regUserInfo.role);
    }

    return () => {
      setShowCommunities(false);
      setRole("");
      setOtherRole("");
    };
  }, [regUserInfo]);

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const token = "Bearer " + getWithExpiry("expiry_token");

  let url;

  if (type === "RegUser") {
    url = `${process.env.REACT_APP_URL_PATH}/user/add_regular_user/`;
  } else {
    url = `${process.env.REACT_APP_URL_PATH}/user/add_admin/`;
  }
  const addUserSubmissionHandler = (event) => {
    event.preventDefault();

    setLoading(true);

    setAddBtnDisabled(true);


    const fd = new FormData();
    fd.append("first_name", user.first_name);
    fd.append("last_name", user.last_name);
    fd.append("email", user.email);

    if (phoneValue && !phoneValue.includes("+", 0)) {
      fd.append("telephone_number", "+" + phoneValue);
    } else if (phoneValue) {
      fd.append("telephone_number", phoneValue);
    }

    if (role === "Corporate HQ Administrator") {
      fd.append("role", role);
      fd.append("corporate_id", corporateId);
    } else {
      fd.append("role", role);
      fd.append("community_id", otherRole);
      fd.append("corporate_id", corporateId);
    }

    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/corporate/update_corporate_user/${idToBeEdited}`,
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      })
      .catch((error) => {
        setLoading(false);
        setAddBtnDisabled(false);
        if (error.response.data.message === "Field Errors") {
          if (
            error.response.data.data["phone_form"] &&
            "telephone_number" in error.response.data.data["phone_form"]
          ) {
            setPhoneError(
              error.response.data.data["phone_form"]["telephone_number"][0]
            );
          }
          if (
            error.response.data.data["user_form"] &&
            "email" in error.response.data.data["user_form"]
          ) {
            setEmailError(error.response.data.data["user_form"]["email"][0]);
          }
          if (
            error.response.data.data["user_form"] &&
            "first_name" in error.response.data.data["user_form"]
          ) {
            setFirstNameError(
              error.response.data.data["user_form"]["first_name"][0]
            );
          }
          if (
            error.response.data.data["user_form"] &&
            "last_name" in error.response.data.data["user_form"]
          ) {
            setLastNameError(
              error.response.data.data["user_form"]["last_name"][0]
            );
          }
        } else {
          notifyError(error.response.data.message);
        }
      });
  };

  const setPageStates = (a, b, c) => {
    setShowCorporates(a);
    setShowCommunities(b);
    setShowIndivCommunities(c);
    setOtherRole("");
  };

  const roleChangeHandler = (e) => {
    setRole(e.target.value);
    if (e.target.value !== "") {
      if (e.target.value === "Corporate HQ Administrator") {
        setPageStates(true, false, false);
      } else if (e.target.value === "Individual Practice Admin") {
        setPageStates(false, false, true);
      } else {
        setPageStates(false, true, false);
      }
    } else {
      setPageStates(false, false, false);
    }
  };

  return (
    <div className="action-modal-wrapper">
      <Modal
        className="comunitie-modal modal-loader"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header
          className="comubitie-modal-header"
          style={{ padding: "1rem 0rem" }}
        >
          <h1 className="modal-header-title">Edit User</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="comunitie-modal-body">
          <form
          
            method="post"
            className="add-corporate-form"
            onSubmit={addUserSubmissionHandler}
          >
            <div className="communities-fields">
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="first_name" className="label">
                      First Name *
                    </label>
                    <input
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="First Name"
                      className="custom-input"
                      value={user.first_name}
                      onChange={handleChange}
                      required
                    />
                    {firstNameError && (
                      <p className="error-text">{firstNameError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="last_name" className="label">
                      Last Name *
                    </label>
                    <input
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Last Name"
                      className="custom-input"
                      value={user.last_name}
                      onChange={handleChange}
                      required
                    />
                    {lastNameError && (
                      <p className="error-text">{lastNameError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="email" className="label">
                      Email *
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="custom-input"
                      placeholder="Email"
                      value={user.email}
                      onChange={handleChange}
                    />
                    {emailError && <p className="error-text">{emailError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="telephone_number" className="label">
                      Telephone *
                    </label>
                    {/* <input
                      type="text"
                      name="telephone_number"
                      id="telephone_number"
                      className="custom-input"
                      placeholder="Telephone"
                      onChange={(e) => {
                        const { name, value } = e.target;

                        const regex = /^([\s+0-9])+$/i;
                        if (value === "") {
                          // case to remove whole highlighted text
                          setUser({ ...user, [name]: value });
                          return;
                        }
                        if (regex.test(value) === false) return;

                        if (value.length === 1 && value === "+") {
                          // case to remove + sign when last number is removed
                          setUser({ ...user, [name]: "" });
                        } else if (value.length >= 1 && value[0] !== "+") {
                          // case to append + sign with first number
                          setUser({ ...user, [name]: "+" + value });
                        } else {
                          // case to append other numbers
                          setUser({ ...user, [name]: value });
                        }
                      }}
                      value={user.telephone_number}
                      required
                    /> */}
                    <PhoneInput
                      country={"us"}
                      value={phoneValue}
                      onChange={setPhoneValue}
                      inputProps={{
                        required: true,
                      }}
                    />
                    {phoneError && <p className="error-text">{phoneError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="role" className="label">
                      Role *
                    </label>
                    {type === "AdminUser" ? (
                      <>
                        <select
                          name="role"
                          id="role"
                          className="custom-select-modal"
                          // onChange={roleChangeHandler}
                          value={role}
                        >
                          <option value="">{"Catchu Admin"}</option>
                        </select>
                      </>
                    ) : (
                      <>
                        <select
                          name="role"
                          id="role"
                          className="custom-select-modal"
                          onChange={roleChangeHandler}
                          value={role}
                        >
                          {/* {role === "" && (
                            <option value="">{"Select a Role"}</option>
                          )} */}
                          <>
                            <option value="Corporate HQ Administrator">
                              Corporate HQ Administrator
                            </option>
                            <option value="Community Admin">
                              Community Admin
                            </option>
                          </>

                          {/* {communityPermissions && (
                            <>
                              {isCatchuAdmin && (
                                <option value="Individual Practice Admin">
                                  Individual Practice Admin
                                </option>
                              )}
                              <option value="Community Admin">
                                Community Admin
                              </option>
                              <option value="Support Staff User">
                                Support Staff User
                              </option>
                            </>
                          )} */}
                        </select>
                      </>
                    )}
                  </div>
                </div>
                {showCommunities && (
                  <div className="col-lg-6 right-col">
                    <div className="field-wrapper">
                      <label htmlFor="community_id" className="label">
                        Communities *
                      </label>
                      <select
                        name="community_id"
                        id="community_id"
                        className="custom-select-modal"
                        onChange={(e) => setOtherRole(e.target.value)}
                        value={otherRole}
                      >
                        {otherRole === "" && (
                          <option value="">{"Select a Community"}</option>
                        )}
                        {communityList.map((community) => (
                          <option key={community.id} value={`${community.id}`}>
                            {community.community_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
                {/* {showIndivCommunities && (
                  <div className="col-lg-6 right-col">
                    <div className="field-wrapper">
                      <label htmlFor="community_id" className="label">
                        Communities *
                      </label>
                      <select
                        name="community_id"
                        id="community_id"
                        className="custom-select-modal"
                        onChange={(e) => setOtherRole(e.target.value)}
                        value={otherRole}
                      >
                        {otherRole === "" && (
                          <option value="">{"Select a Role"}</option>
                        )}
                        {indEnabledCommunities.map((community) => (
                          <option key={community.id} value={`${community.id}`}>
                            {community.community_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16"
            onClick={hideModal}
            id="modal-close-btn"
          >
            Close
          </button>
          <button
            // type="submit"
            onClick={addUserSubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={addBtnDisabled}
          >
            Update
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

export default EditUserModal;
