import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import useInput from "../../../hooks/use-input";
import { authActions } from "../../../store/auth";
import { Form } from "react-bootstrap";
import { getWithExpiry } from "../../utils/helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TermsModal from "./TermsModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import RestrictedInput from "restricted-input";

import "./Signup.scss";

// import images
import BrandLogo from "../../../assets/logo.png";
import ArrowRight from "../../../assets/arrow-right.svg";
import Input from "react-phone-number-input/input";
const SignUp = () => {
  const form = document.querySelector(".login-form");
  const history = useHistory();
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  const [termsCheckbox, setTermsCheckbox] = useState(false);
  const [termsCheckboxError, setTermsCheckboxError] = useState("");
  const [termsModalOpen, setTermsModalOpen] = useState(false);

  const [disabledBtn, setDisabledBtn] = useState(false);
  const [phoneVal, setPhoneVal] = useState("");

  const showTermsModal = () => setTermsModalOpen(true);
  const hideTermsModal = () => setTermsModalOpen(false);

  const token = getWithExpiry("expiry_token");

  // setTermsCheckbox(termsCheckbox)

  const handleTermsCheckbox = () => {
    setTermsCheckbox(!termsCheckbox);

  };

  const phoneRef = useRef();

  const dispatch = useDispatch();

  const passwordRef = useRef({ value: "" });
  const confirmPasswordRef = useRef({ value: "" });

  const isNotEmpty = (value) => value.trim() !== "";
  const isNameValid = (value) => {
    const regex = /^[a-zA-Z ]{1,30}$/;
    return isNotEmpty(value) && regex.test(value);
  };
  const isEmailValid = (value) => value.includes("@");
  const isPhoneValid = (value) => {
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return (
      isNotEmpty(value) &&
      regex.test(value) &&
      ((value != 8 && value != 0 && value < 48) || value > 57)
    );
  };
  const isPasswordValid = (value) => {
    const passwordRegex = /^[a-zA-Z0-9]+$/;
    return value.trim().length >= 8 && passwordRegex.test(value);
  };
  const isConfirmPasswordValid = (password, confirmPassword) =>
    isNotEmpty(confirmPassword) && password === confirmPassword;
  const isPermissionSelected = () => {
    let checkboxes = document.querySelectorAll('input[type="checkbox"]');
    return Array.prototype.slice.call(checkboxes).some((x) => x.checked);
  };
  const confirmPwdValid = isConfirmPasswordValid.bind(
    this,
    passwordRef.current.value,
    confirmPasswordRef.current.value
  );

  const {
    value: firstNameVal,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    inputChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    reset: firstNameResetHandler,
  } = useInput(isNameValid);

  const {
    value: lastNameVal,
    isValid: lastNameIsValid,
    hasError: lastNameHasError,
    inputChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    reset: lastNameResetHandler,
  } = useInput(isNameValid);

  const {
    value: emailVal,
    isValid: emailIsValid,
    hasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: emailResetHandler,
  } = useInput(isEmailValid);

  // const {
  //   value: phoneVal,
  //   isValid: phoneIsValid,
  //   hasError: phoneHasError,
  //   inputChangeHandler: phoneChangeHandler,
  //   inputBlurHandler: phoneBlurHandler,
  //   reset: phoneResetHandler,
  // } = useInput(isPhoneValid);

  const {
    value: passwordVal,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    inputChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: passwordResetHandler,
  } = useInput(isPasswordValid);

  const {
    value: confirmPasswordVal,
    isValid: confirmPasswordIsValid,
    hasError: confirmPasswordHasError,
    inputChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
    reset: confirmPasswordResetHandler,
  } = useInput(confirmPwdValid);

  const {
    value: permissionVal,
    isValid: permissionIsValid,
    hasError: permissionHasError,
    checkboxHandler: permissionChangeHandler,
    inputBlurHandler: permissionBlurHandler,
    reset: permissionResetHandler,
  } = useInput(isPermissionSelected);

  // const formattedPhoneNumber = new RestrictedInput({
  //     element: phoneRef,
  //     pattern: "^\+?\d+$",
  // });

  const resetCheckboxes = () => {
    const checkboxes = document.getElementsByClassName("check-form");
    for (const checkbox of checkboxes) {
      checkbox.removeAttribute("checked");
    }
  };

  let formIsValid = false;
  const signupBtnClass = formIsValid ? "" : "disabled";

  // if (firstNameIsValid && lastNameIsValid && emailIsValid &&
  //     phoneIsValid && passwordIsValid && confirmPasswordIsValid) {
  //     formIsValid = true;
  // }
  const notifyError = (error) =>
    toast.error(error, {
      zposition: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const formSubmissionHandler = async (event) => {
    event.preventDefault();

    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");
    setPasswordError("");
    setPasswordConfirmError("");
    if (!termsCheckbox) {
      setTermsCheckboxError("Please agree to terms and conditions");
      return;
    } else {
      setTermsCheckboxError("");
    }

    // if (!firstNameIsValid || !lastNameIsValid || !emailIsValid ||
    // !phoneIsValid || !passwordIsValid || !confirmPasswordIsValid ) {
    //     formIsValid = false;
    //     return;
    // }

    formIsValid = true;
  

    const fd = new FormData(form);
    // let phoneValNew = phoneVal.replace(/\s+/g, "");
    // fd.append("telephone_number", phoneValNew);
    if (phoneVal) {
      fd.append("telephone_number", "+" + phoneVal);
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/user/signup/`,
      data: fd,
      headers: { "Content-Type": "multipart/form-data" , Authorization: `token ${token}`, },
    })
      .then(function (response) {
        //handle success
        // if (termsCheckbox === "Please agree to terms and conditions") {
        //   setDisabledBtn(false);
        // }
        setDisabledBtn(true);
        let successMessage = response.data.message;
        dispatch(authActions.signup(successMessage));
        history.replace("/login");
      })
      .catch(function (error) {
        setDisabledBtn(!true);
        if (error.response.data.message === "Field Errors") {
          if ("phone_form" in error.response.data.data) {
            if ("telephone_number" in error.response.data.data["phone_form"]) {
              let phoneError = "";
              error.response.data.data["phone_form"]["telephone_number"].map(
                (phoneErr) => (phoneError += phoneErr)
              );
              setPhoneError(phoneError);
            }
          }
          if ("user_form" in error.response.data.data) {
            if ("first_name" in error.response.data.data["user_form"]) {
              let firstNameError = "";
              error.response.data.data["user_form"]["first_name"].map(
                (firstNameErr) => (firstNameError += firstNameErr)
              );
              setFirstNameError(firstNameError);
            }
            if ("last_name" in error.response.data.data["user_form"]) {
              let lastNameError = "";
              error.response.data.data["user_form"]["last_name"].map(
                (lastNameErr) => (lastNameError += lastNameErr)
              );
              setLastNameError(lastNameError);
            }
            if ("email" in error.response.data.data["user_form"]) {
              let emailError = "";
              error.response.data.data["user_form"].email.map(
                (emailErr) => (emailError += emailErr)
              );
              setEmailError(emailError);
            }
            if ("password2" in error.response.data.data["user_form"]) {
              let pwdError = "";
              error.response.data.data["user_form"]["password2"].map(
                (pwdErr) => {
                  pwdError += pwdErr;
                }
              );
              setPasswordError(pwdError);
            }
          }
        } else {
          notifyError(error.response.data.message);
          setDisabledBtn(false);
        }
      });
  };

  return (
    <div className="login-screen-wrapper">
      <div className="login-box signup-box">
        <div className="login-logo">
          <img src={BrandLogo} alt="" />
        </div>
        <div className="login-header">
          <h1 className="login-heading">Sign Up</h1>
          <p className="login-des">Register a CatchU Admin </p>
        </div>
        <form
         
          method="POST"
          className="login-form"
          onSubmit={formSubmissionHandler}
        >
          <div className="row">
            <div className="col-6 left-col-form">
              <div className="login-field">
                <label htmlFor="first_name" className="login-label">
                  First Name *
                </label>
                <input
                  id="first_name"
                  name="first_name"
                  type="text"
                  className="custom-input"
                  placeholder="First Name"
                  value={firstNameVal}
                  onChange={firstNameChangeHandler}
                  onBlur={firstNameBlurHandler}
                  required
                />
                {firstNameError && (
                  <p className="error-text">{firstNameError}</p>
                )}
              </div>
            </div>
            <div className="col-6 right-col-form">
              <div className="login-field">
                <label htmlFor="last_name" className="login-label">
                  Last Name *
                </label>
                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  className="custom-input"
                  placeholder="Last Name"
                  value={lastNameVal}
                  onChange={lastNameChangeHandler}
                  onBlur={lastNameBlurHandler}
                  required
                />
                {lastNameError && <p className="error-text">{lastNameError}</p>}
              </div>
            </div>
            <div className="col-6 left-col-form">
              <div className="login-field">
                <label htmlFor="email" className="login-label">
                  Email *
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="custom-input"
                  placeholder="Email"
                  value={emailVal}
                  onChange={emailChangeHandler}
                  onBlur={emailBlurHandler}
                />
                {emailError && <p className="error-text">{emailError}</p>}
              </div>
            </div>
            <div className="col-6 right-col-form">
              <div className="login-field">
                <label
                  htmlFor="telephone_number"
                  id="phone-number"
                  className="login-label"
                >
                  Telephone *
                </label>

                {/* <input
                  id="telephone_number"
                  name="telephone_number"
                  type="text"
                  className="custom-input"
                  placeholder="Telephone"
                  value={phoneVal}
                  //onChange={setPhoneVal}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    const regex = /^([\s+0-9])+$/i;
                    if (value === "") {
                      // case to remove whole highlighted text
                      setPhoneVal(value);
                      return;
                    }
                    if (regex.test(value) === false) return;

                    if (value.length === 1 && value === "+") {
                      // case to remove + sign when last number is removed
                      setPhoneVal("");
                    } else if (value.length >= 1 && value[0] !== "+") {
                      // case to append + sign with first number
                      setPhoneVal("+" + value);
                    } else {
                      // case to append other numbers
                      setPhoneVal(value);
                    }
                  }}
                  required
                /> */}
                <PhoneInput
                  country={"us"}
                  value={phoneVal}
                  onChange={setPhoneVal}
                  inputProps={{
                    required: true,
                  }}
                />
                {phoneError && <p className="error-text">{phoneError}</p>}
              </div>
            </div>
            <div className="col-6 left-col-form">
              <div className="login-field">
                <label htmlFor="password1" className="login-label">
                  Password *
                </label>
                <input
                  ref={passwordRef}
                  id="password1"
                  name="password1"
                  type="password"
                  className="custom-input"
                  placeholder="Password"
                  value={passwordVal}
                  onChange={passwordChangeHandler}
                  onBlur={passwordBlurHandler}
                  required
                />
                {passwordError && <p className="error-text">{passwordError}</p>}
              </div>
            </div>
            <div className="col-6 right-col-form">
              <div className="login-field">
                <label htmlFor="password2" className="login-label">
                  Confirm Password *
                </label>
                <input
                  ref={confirmPasswordRef}
                  id="password2"
                  name="password2"
                  type="password"
                  className="custom-input"
                  placeholder="Confirm Password"
                  value={confirmPasswordVal}
                  onChange={confirmPasswordChangeHandler}
                  onBlur={confirmPasswordBlurHandler}
                  required
                />
                {passwordConfirmError && (
                  <p className="error-text">{passwordConfirmError}</p>
                )}
              </div>
            </div>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                inline
                type="checkbox"
                label="I agree to"
                className="mr-0"
                style={{ marginRight: "0" }}
                onChange={handleTermsCheckbox}
              />{" "}
              <Link
                style={{ display: "inline", color: "#8AD0EF" }}
                to="#"
                onClick={showTermsModal}
              >
                Terms and Conditions
              </Link>
              {termsCheckboxError && (
                <p className="error-text">{termsCheckboxError}</p>
              )}
            </Form.Group>

            {termsModalOpen && (
              <TermsModal
                isShowModal={termsModalOpen}
                hideModal={hideTermsModal}
                document={
                  "https://docs.google.com/document/d/e/2PACX-1vS9FKKjoc8eSm1Ijp55iSxsBaAG-ItNGFGmcXmPIlzBO9zFbjP7p9sfxzdrvZuWo_EK2qODbisl3oG3/pub?embedded=true"
                }
              />
            )}
          </div>

          <div className="login-action signup-action">
            <button
              className={`btn btn-primary-fill btn-primary-16`}
              type="submit"
              disabled={disabledBtn}
            >
              SIGN UP
              <img className="arrow-right" src={ArrowRight} alt="" />
            </button>
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <li className="password-forget">
              <Link to="/login">
                <p>Already registered?</p>
              </Link>
            </li>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
