import React from "react"
import "./CorporateDetail.scss"

// imports Image
import Email from "../../assets/email.svg"
import Calendar from "../../assets/calendar.svg"
import Phone from "../../assets/phone.svg"
import DPhone from "../../assets/d-phone.svg"
import DPhone1 from "../../assets/d-phone1.svg"
import TableComponent from "../UI/Table/TableData"
import Pencil from "../../assets/pencil-icon.svg"

function CorpDetail() {
  return (
    <div className="corp-d-wrapper">
      <div className="corp-d-header-content">
        <div className="first-cdh-content">
          <div className="cdh-first-top-row">
            <div className="cdh-left">
              <h1 className="cdh-heading">Patient Details</h1>
            </div>
            <ul className="cdh-right">
              <li className="cdh-inner-single">
                <div className="cdh-select">
                  <select className="custom-select">
                    <option selected disabled>
                      Patient Action
                    </option>
                    <option>Action</option>
                    <option>3</option>
                    <option>2</option>
                  </select>
                </div>
              </li>

              <li className="cdh-inner-single">
                <button className="btn btn-primary-fill btn-primary-16">
                  Create Test
                </button>
              </li>
            </ul>
          </div>
          <div className="cdh-second-row-content">
            <h3 className="cdh-s-heading">Jane Botosh</h3>
            <ul className="cdh-details">
              <li className="cdh-single-detail cdh-class-second">
                <div className="cdh-inner-single">
                  <img src={Email} alt="" />
                  <p className="cdh-inner-des">janebotosh1@gmail.com</p>
                </div>
                <img className="pencil-icon" src={Pencil} alt="" />
              </li>
              <li className="cdh-single-detail">
                <img src={Calendar} alt="" />
                <p className="cdh-inner-des">12/02/1994</p>
              </li>
              <li className="cdh-single-detail">
                <img src={Phone} alt="" />
                <p className="cdh-inner-des">05/23/1995</p>
              </li>
              <li className="cdh-single-detail">
                <img src={DPhone} alt="" />
                <p className="cdh-inner-des">449-12-0301</p>
              </li>
              <li className="cdh-single-detail">
                <img src={DPhone1} alt="" />
                <p className="cdh-inner-des">453-98-0976</p>
              </li>
            </ul>
          </div>
        </div>

        <div className="second-cdh-content">
          <div className="cdh-first-top-row">
            <div className="cdh-left">
              <h1 className="cdh-heading heading-tag">Patient Details</h1>
              <span className="tag zip-tag">Pending</span>
            </div>
            <ul className="cdh-right">
              <li className="cdh-inner-single">
                <button className="btn btn-primary-outline">Edit</button>
              </li>
              <li className="cdh-inner-single">
                <button className="btn btn-primary-fill btn-primary-16">
                  Initiate Test
                </button>
              </li>
            </ul>
          </div>
          <ul className="second-inner-row-content">
            <li className="inner-s-single">
              <h3 className="iss-heading">Test ID</h3>
              <span className="iss-des">CBR455643A</span>
            </li>
            <li className="inner-s-single">
              <h3 className="iss-heading">Ordered Test</h3>
              <span className="iss-des">12/03/2021</span>
            </li>
            <li className="inner-s-single">
              <h3 className="iss-heading">Test Mobile</h3>
              <span className="iss-des iss-not-defined">Not Defined</span>
            </li>
            <li className="inner-s-single">
              <h3 className="iss-heading">Scheduled</h3>
              <span className="iss-des iss-not-defined">Not Defined</span>
            </li>
            <li className="inner-s-single">
              <h3 className="iss-heading">Test Location</h3>
              <span className="iss-des">
                6391 Elgin St. Celina, Delaware 102991
              </span>
            </li>
          </ul>
        </div>
      </div>
      <TableComponent />
    </div>
  )
}

export default CorpDetail
