import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth";
import useInput from "../../../hooks/use-input";
import { useParams, useHistory, Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ResetPassword.scss";

// import images
import BrandLogo from "../../../assets/logo.png";
import ArrowRight from "../../../assets/arrow-right.svg";
import Loader from "../../UI/loader/loader";
import Loader2 from "../../UI/loader2/loader2";

const isPasswordValid = (value) => {
  const passwordRegex = /^[a-zA-Z0-9]+$/;
  return value.trim().length >= 8 && passwordRegex.test(value);
};

function ResetPassword() {
  const history = useHistory();

  const params = useParams();
  const dispatch = useDispatch();
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pswError, setPswError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  // const auth_token = "Bearer " + getWithExpiry("expiry_token");

  const { id, token } = params;

  useEffect(() => {
    setLoading(true);

    axios({
      method: "get",
      url: `${process.env.REACT_APP_URL_PATH}/user/password-token-check/${id}/${token}/`,
      headers: {
        "Content-Type": "application/json",
        //Authorization: auth_token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
        } else {
          history.replace("/login");
          notifyError(response.data.message);
        }
      })
      .catch((error) => {
        history.replace("/login");
        notifyError(error.response.data.message);
      });
  }, []);

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const resetPasswordSubmissionHandler = (event) => {
    event.preventDefault();
    setDisabledBtn(true);

    setPswError("");
    setConfirmPasswordError("");
    const form = document.querySelector(".reset-password-form");
    const fd = new FormData(form);
    fd.append("uidb64", id);
    fd.append("token", token);

    axios({
      method: "patch",
      url: `${process.env.REACT_APP_URL_PATH}/user/password-set-complete/`,
      data: fd,
      headers: {
        "Content-Type": "application/json",
        //Authorization: auth_token,
      },
    })
      .then((response) => {
        history.replace("/login");
        notifySuccess(response.data.message);
      })
      .catch((error) => {
        setDisabledBtn(false);
        if (
          "field_errors" in error.response.data.data &&
          "password" in error.response.data.data["field_errors"]
        ) {
          setPswError(error.response.data.data["field_errors"]["password"]);
        } else if (
          "field_errors" in error.response.data.data &&
          "confirm_password" in error.response.data.data["field_errors"]
        ) {
          setConfirmPasswordError(
            error.response.data.data["field_errors"]["confirm_password"]
          );
        }
      });
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="login-screen-wrapper">
      <div className="login-box">
        <div className="login-logo">
          <img src={BrandLogo} alt="" />
        </div>
        <div className="login-header">
          <h1 className="login-heading">Set Password</h1>
        </div>
        <form
         
          method="post"
          className="reset-password-form"
          onSubmit={resetPasswordSubmissionHandler}
        >
          
          <div className="login-field">
            <label htmlFor="new_password" className="login-label">
              New Password *
            </label>
            <input
              id="password"
              name="password"
              type="password"
              className="custom-input"
              placeholder="New Password"
        
              required
            />
            {pswError && <p className="error-text">{pswError}</p>}
          </div>
          <div className="login-field">
            <label htmlFor="confirm_password" className="login-label">
              Confirm Password *
            </label>
            <input
              id="confirm_password"
              name="confirm_password"
              type="password"
              className="custom-input"
              placeholder="Confirm Password"
              // value={confirmNewPasswordVal}
              // onChange={confirmNewPasswordChangeHandler}
              // onBlur={confirmNewPasswordBlurHandler}
              required
            />
            {confirmPasswordError && (
              <p className="error-text">{confirmPasswordError}</p>
            )}
            {/* {confirmNewPasswordHasError && <p className="error-text">Confirm new password has error</p>} */}
          </div>

          <ul className="check-forget-password back-login">
            <li className="password-forget set-password">
              <Link to="/login">
                <p>Back to Login</p>
              </Link>
            </li>
          </ul>
          <div className="login-action">
            <button
              className="btn btn-primary-fill btn-14-24 "
              disabled={disabledBtn}
              type="submit"
            >
              SET PASSWORD
              <img className="arrow-right" src={ArrowRight} alt="right-arrow" />
            </button>
            {/* <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            /> */}
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
