import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import axios from "axios";
import Moment from "moment";
import DateTime from "../../../components/DateTimePicker/DateTimePicker";
import Loader2 from "../loader2/loader2";
import CloseModal from "../../../assets/close-outline.svg";
import Calendar from "../../../assets/calendar.svg";
import TestLight from "../../../assets/test-light.svg";
import { getWithExpiry } from "../../utils/helper";
import "./CreateTestModal.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const CreateTest = (props) => {
  const {
    isShowModal,
    hideModal,
    type,
    providerList,
    //billeeList,
    iphones,
    setDataFlag,
    setShowToast,
    location,
    testInfo,
    user,
  } = props;

  const [userLocation, setUserLocation] = useState("");

  const params = useParams();

  const { patientID: patientId } = params;

  const [startDate, setStartDate] = useState(
    new Date(new Date().getTime() + 2100000)
  );
  const Patent_id = useSelector((state) => state.patient.patientsID);

  const [disabledBtn, setDisabledBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneNumValue, setPhoneNumValue] = useState("");
  const [email, setEmail] = useState("");

  const [providerVal, setProviderVal] = useState("");
  const [billeeVal, setBilleeVal] = useState("");
  const [billeeList, setBilleeList] = useState(null);
  const [testRemoteHandle, setTestRemoteHandle] = useState(true);
  const [iphoneNumber, setIphoneNumber] = useState("");
  const [iphoneNumberID, setIphoneNumberID] = useState("");

  const [providerValError, setProviderValError] = useState("");
  const [billeeValError, setBilleeValError] = useState("");
  const [scheduledError, setScheduledError] = useState("");
  const [iphoneNumError, setIphoneNumError] = useState("");
  const [iphoneIDError, setIphoneIDError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [locationError, setLocationError] = useState("");
  const IphoneListData = useSelector((state) => state.patient.iphoneList);

  const iPhoneList = type === "Test" ? iphones : IphoneListData;

  const token = "Bearer " + getWithExpiry("expiry_token");

  useEffect(() => {
    if (testInfo) {
      setIphoneNumber(testInfo.telephone ? testInfo.telephone : "");
      setPhoneNumValue(testInfo.telephone ? testInfo.telephone : "");
      setEmail(testInfo.email);
    } else {
      setIphoneNumber(user.telephone_number ? user.telephone_number : "");
      setPhoneNumValue(user.telephone_number ? user.telephone_number : "");
      setEmail(user.email);
    }
  }, [testInfo, user]);

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const selectAction = (e) => {
    const { name, value } = e.target;
    switch (e.target.value) {
      case "provider":
        setProviderVal(value);
        break;
      case "billee":
        setBilleeVal(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setUserLocation(location);
  }, [location]);

  const handleProviderChange = (e) => {
    const { name, value } = e.target;
    setProviderVal(value);

    axios({
      url: `${process.env.REACT_APP_URL_PATH}/test/billee_list/${
        patientId ? patientId : testInfo.id
      }/${value}/`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        setBilleeList(response.data.data.billees_list);
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };
  const checkBoxHandle = () => {
    setTestRemoteHandle(!testRemoteHandle);
  };
  function diff_minutes(currentTime, selectedTime) {
    var diff = (selectedTime.getTime() - currentTime.getTime()) / 1000;
    diff /= 60;
    return Math.round(diff);
  }
  function ISOtoLongDate(isoString, locale = "en-US") {
    const options = { month: "numeric", day: "numeric", year: "numeric" };
    const date = new Date(isoString);
    const longDate = new Intl.DateTimeFormat(locale, options).format(date);
    return longDate;
  }
  const createTestSubmissionHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    setDisabledBtn(true);

    setScheduledError("");
    setProviderValError("");
    setBilleeValError("");
    setIphoneIDError("");
    setIphoneNumError("");
    setEmailError("");
    setPhoneError("");

    const form = document.querySelector(".add-corporate-form");

    const fd = new FormData(form);

    if (!startDate || diff_minutes(new Date(), startDate) >= 0) {
      let dateTime = "";
      if (startDate) {
        let newDate = ISOtoLongDate(startDate.toISOString(), "en-GB");
        dateTime =
          Moment(newDate, "DD/MM/YYYY").format("YYYY-MM-DD") +
          " " +
          new Intl.DateTimeFormat("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(startDate);

      }

      fd.append("providerId", providerVal);
      fd.append("billeeId", billeeVal);
      fd.append("dateScheduled", dateTime);
      fd.append("timezone", Intl.DateTimeFormat().resolvedOptions().timeZone);
      fd.append("patientId", patientId ? patientId : testInfo.id);

      if (testRemoteHandle) {
        fd.append("remote", "1");
        if (iphoneNumber && !iphoneNumber.includes("+", 0)) {
          fd.append("iphonNumber", "+" + iphoneNumber);
        } else if (iphoneNumber) {
          fd.append("iphonNumber", iphoneNumber);
        }

        fd.append("location", "Remote");
      } else {
        fd.append("iphoneId", iphoneNumberID);
        fd.append("location", userLocation);
      }

    
      if (phoneNumValue && !phoneNumValue.includes("+", 0)) {
        fd.append("telephone", "+" + phoneNumValue);
      } else if (phoneNumValue) {
        fd.append("telephone", phoneNumValue);
      }

      if (email) {
        fd.append("email", email);
      }

      axios({
        url: `${process.env.REACT_APP_URL_PATH}/test/create_test/`,
        method: "post",
        data: fd,
        headers: {
          "Content-type": "application/json",
          Authorization: token,
        },
      })
        .then((response) => {
          setLoading(false);
          hideModal();
          notifySuccess(response.data.message);
          setShowToast(true);
          setDataFlag((prevState) => !prevState);
        })
        .catch((error) => {
          setLoading(false);
          setDisabledBtn(false);

          if (error.response.data.message === "Field Errors") {
            if (
              error.response.data.data &&
              "dateScheduled" in error.response.data.data
            ) {
              setScheduledError(error.response.data.data.dateScheduled);
            } else if (
              error.response.data.data &&
              "billeeId" in error.response.data.data
            ) {
              setBilleeValError(error.response.data.data.billeeId);
            } else if (
              error.response.data.data &&
              "iphoneId" in error.response.data.data
            ) {
              setIphoneIDError(error.response.data.data.iphoneId);
            } else if (
              error.response.data.data &&
              "providerId" in error.response.data.data
            ) {
              setProviderValError(error.response.data.data.providerId);
            } else if (
              error.response.data.data &&
              "iphonNumber" in error.response.data.data
            ) {
              setIphoneNumError(error.response.data.data.iphonNumber);
            } else if (
              error.response.data.data &&
              "email" in error.response.data.data
            ) {
              setEmailError(error.response.data.data.email);
            } else if (
              error.response.data.data &&
              "telephone" in error.response.data.data
            ) {
              setPhoneError(error.response.data.data.telephone);
            } else if (
              error.response.data.data &&
              "phone_form" in error.response.data.data
            ) {
              setIphoneNumError(
                error.response.data.data["phone_form"]["number"]
              );
            }
          } else {
            notifyError(error.response.data.message);
          }
          setDisabledBtn(false);
        });
    } else {
      // setScheduledError(
      //   "Selected DateTime should be 30 mints after the current DateTime"
      // );
      setScheduledError("Selected DateTime cannot be from the past");
      setLoading(false);
      setDisabledBtn(false);
    }
  };

  return (
    <div className="action-modal-wrapper">
      <Modal
        className="comunitie-modal initiate-test-patient-modal modal-loader"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header className="comubitie-modal-header" id="rm-padding">
          <h1 className="modal-header-title">Create Test</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="close-modal" />
          </span>
        </Modal.Header>
        <Modal.Body className="comunitie-modal-body">
          <form
           
            method="post"
            className="add-corporate-form"
            onSubmit={createTestSubmissionHandler}
          >
            {type === "Test" && (
              <div className="comunties-fields community-pt-header">
                <div className="cdh-first-top-row community-first-row">
                  <div className="cdh-left">
                    <h1 className="cdh-heading">Patient Details</h1>
                  </div>
                </div>
                <div className="cdh-second-row-content">
                  <ul className="cdh-details">
                    <li className="cdh-single-detail cdh-class-second">
                      <h3 className="cdh-s-heading">
                        {" "}
                        {testInfo.first_name} {testInfo.last_name}{" "}
                      </h3>
                    </li>
                    <li className="cdh-single-detail cdh-class-second">
                      <div className="cdh-inner-single">
                        <img className="detail-img" src={Calendar} alt="" />
                        <p className="cdh-inner-des">{testInfo.dob}</p>
                      </div>
                    </li>

                    {/* <li className="cdh-single-detail cdh-class-second">
                      <div className="cdh-inner-single">
                         <img className="detail-img" src={TestLight} alt="" />
                        <p className="cdh-inner-des">
                          {testInfo.last_test_date}
                        </p>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-lg-6 left-col">
                <div className="field-wrapper">
                  <label htmlFor="provider" className="label">
                    Provider *
                  </label>
                  <select
                    id="providerId"
                    name="providerId"
                    className="custom-select"
                    onChange={handleProviderChange}
                    value={providerVal}
                  >
                    {providerVal === "" && <option>Select Provider</option>}
                    {providerList &&
                      providerList.map((record) => (
                        <option
                          key={record.id}
                          id={record.id}
                          value={record.id}
                        >
                          {record.name}
                        </option>
                      ))}
                  </select>
                  {providerValError && (
                    <p className="error-text">{providerValError}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-6 right-col">
                <div className="field-wrapper">
                  <label htmlFor="billee" className="label">
                    Billee *
                  </label>
                  <select
                    id="billeeId"
                    name="billeeId"
                    className="custom-select"
                    onChange={(e) => setBilleeVal(e.target.value)}
                    value={billeeVal}
                  >
                    {billeeVal === "" && <option>Select Billee</option>}
                    {billeeList &&
                      billeeList.map((record) => (
                        <option
                          key={record.billee_id}
                          id={record.billee_id}
                          value={record.billee_id}
                        >
                          {record.billee_name}
                        </option>
                      ))}
                  </select>
                  {billeeValError && (
                    <p className="error-text">{billeeValError}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 right-col">
                <div className="field-wrapper">
                  <label htmlFor="scheduled" className="label">
                    Scheduled *
                  </label>
                  <DateTime
                    startDate={startDate}
                    setStartDate={setStartDate}
                    // maxDate={new Date()}
                  />
                  {scheduledError && (
                    <p className="error-text">{scheduledError}</p>
                  )}
                </div>
              </div>
              {/* {!testRemoteHandle && (
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="location" className="label">
                      Location
                    </label>
                    <input
                      type="text"
                      name="location"
                      id="location"
                      className="custom-input"
                      placeholder="Location"
                      value={userLocation}
                      onChange={(e) => setUserLocation(e.target.value)}
                      required
                    />
                    {locationError && (
                      <p className="error-text">{locationError}</p>
                    )}
                  </div>
                </div>
              )} */}
              <div className="col-lg-6 right-col">
                <div className="field-wrapper">
                  <label htmlFor="location" className="label">
                    Location
                  </label>
                  {testRemoteHandle ? (
                    <input
                      type="text"
                      name="location"
                      id="location"
                      className="custom-input"
                      placeholder="Location"
                      value={"Remote"}
                      // onChange={(e) => {
                      //   setUserLocation(e.target.value);
                      // }}
                      required
                    />
                  ) : (
                    <input
                      type="text"
                      name="location"
                      id="location"
                      className="custom-input"
                      placeholder="Location"
                      value={userLocation}
                      onChange={(e) => {
                        setUserLocation(e.target.value);
                      }}
                      required
                    />
                  )}

                  {locationError && (
                    <p className="error-text">{locationError}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="field-wrapper checkbox-create-test">
                  <input
                    type="checkbox"
                    className="float-left"
                    id="remote12"
                    name="remote12"
                    value={testRemoteHandle}
                    checked={testRemoteHandle}
                    onClick={checkBoxHandle}
                  />
                  <label className="label mx-2  " htmlFor="remote12">
                    Remote
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 left-col">
                <div className="field-wrapper">
                  <label htmlFor="iphone_number" className="label">
                    iPhone Number *
                  </label>
                  {testRemoteHandle ? (
                    <>
                      {/* <input
                        type="text"
                        id="iphonNumber"
                        name="iphonNumber"
                        className="custom-input"
                        placeholder="iPhone Number"
                        value={iphoneNumber}
                        onChange={(e) => {
                          const { name, value } = e.target;

                          const regex = /^([\s+0-9])+$/i;
                          if (value === "") {
                            // case to remove whole highlighted text
                            setIphoneNumber(value);
                            return;
                          }
                          if (regex.test(value) === false) return;

                          if (value.length === 1 && value === "+") {
                            // case to remove + sign when last number is removed
                            setIphoneNumber("");
                          } else if (value.length >= 1 && value[0] !== "+") {
                            // case to append + sign with first number
                            setIphoneNumber("+" + value);
                          } else {
                            // case to append other numbers
                            setIphoneNumber(value);
                          }
                        }}
                        // onBlur={stateBlurHandler}
                        required
                      /> */}
                      <PhoneInput
                        country={"us"}
                        value={iphoneNumber}
                        onChange={setIphoneNumber}
                        inputProps={{
                          required: true,
                        }}
                      />
                      {iphoneNumError && (
                        <p className="error-text">{iphoneNumError}</p>
                      )}
                    </>
                  ) : (
                    <>
                      <select
                        id="iphoneId"
                        name="iphoneId"
                        className="custom-select"
                        onChange={(e) => setIphoneNumberID(e.target.value)}
                        value={iphoneNumberID}
                      >
                        {iphoneNumberID === "" && (
                          <option>Select iPhone</option>
                        )}

                        {iPhoneList &&
                          iPhoneList.map((record) => (
                            <option key={record.id} value={record.id}>
                              {record.number}
                            </option>
                          ))}
                      </select>
                      {iphoneIDError && (
                        <p className="error-text">{iphoneIDError}</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* </div> */}
            <div className="addminstraion-fields">
              <div className="addminstraion-header">
                <h2 className="adsd-heading">Patient Information</h2>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="email" className="label">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="custom-input"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {emailError && <p className="error-text">{emailError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="telephone" className="label">
                      Telephone
                    </label>
                    {/* <input
                      type="text"
                      name="telephone"
                      id="telephone"
                      className="custom-input"
                      placeholder="Telephone"
                      value={phoneNumValue}
                      onChange={(e) => {
                        const { name, value } = e.target;

                        const regex = /^([\s+0-9])+$/i;
                        if (value === "") {
                          // case to remove whole highlighted text
                          setPhoneNumValue(value);
                          return;
                        }
                        if (regex.test(value) === false) return;

                        if (value.length === 1 && value === "+") {
                          // case to remove + sign when last number is removed
                          setPhoneNumValue("");
                        } else if (value.length >= 1 && value[0] !== "+") {
                          // case to append + sign with first number
                          setPhoneNumValue("+" + value);
                        } else {
                          // case to append other numbers
                          setPhoneNumValue(value);
                        }
                      }}
                      //required
                    /> */}
                    <PhoneInput
                      country={"us"}
                      value={phoneNumValue}
                      onChange={setPhoneNumValue}
                      inputProps={{
                        required: true,
                      }}
                    />
                    {phoneError && <p className="error-text">{phoneError}</p>}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            id="close-btn"
            className="btn btn-secondary-fill btn-primary-16"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            // type="submit"
            onClick={createTestSubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={disabledBtn}
          >
            Create
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
};

export default CreateTest;
