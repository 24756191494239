import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {
  isLogin: false,
  isSignup: false,
  hasForgottenPassword: false,
  otpVerification: false,
  hasChangePassword: false,
  email: "",
  token: "",
  successMessage: "",
  isLoggedIn: undefined,
  pathCheck: false,
  rememberMe: undefined,
  newTabOpen: undefined,
  // errorMessage: '',
};

const authSlice = createSlice({
  name: "authentication",
  initialState: initialAuthState,
  reducers: {
    setIsLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    },
    setNewTabOpen(state, action) {
      state.newTabOpen = action.payload;
    },
    login(state, action) {
      state.isLogin = true;
      state.email = action.payload.email;
      state.successMessage = action.payload.successMessage;

    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    resetLoginStatus(state, action) {
      state.isLogin = false;
    },
    verifyOtp(state, action) {
      state.token = action.payload;
    },
    verifyForgotPasswordOtp(state, action) {
      state.email = action.payload;
    },
    resetPassword(state, action) {
      state.successMessage = action.payload;
    },
    logout(state, action) {
      state.isLogin = false;
      state.token = "";
    },
    signup(state, action) {
      state.isSignup = true;
      state.successMessage = action.payload;
    },
    resetSignup(state, action) {
      state.isSignup = false;
    },
    setForgotPasswordSuccessMsg(state, action) {
      state.successMessage = action.payload;
      state.hasForgottenPassword = true;
    },
    resetForgotPasswordStatus(state, action) {
      state.hasForgottenPassword = false;
    },
    verifyOtpSuccessMsg(state, action) {
      state.otpVerification = true;
      state.successMessage = action.payload;
    },
    resetVerifyOtpStatus(state, action) {
      state.otpVerification = false;
    },
    setChangePasswordSuccessMsg(state, action) {
      state.successMessage = action.payload;
      state.hasChangePassword = true;
    },
    resetPasswordChangeStatus(state, action) {
      state.hasChangePassword = false;
    },
    setPathCheck(state, action) {
      state.pathCheck = action.payload;
    },
    setRememberMe(state, action) {
      state.rememberMe = action.payload;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
