import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./AddDevice.scss";
import axios from "axios";
// import images
import CloseModal from "../../../assets/close-outline.svg";
import { getWithExpiry } from "../../utils/helper";
import Loader2 from "../../UI/loader2/loader2";
import { useDispatch } from "react-redux";
import { billingActions } from "../../../store/billing";
import { communityActions } from "../../../store/community";

const AddPatient = (props) => {
  const {
    isShowModal,
    hideModal,
    setDataFlag,
    setShowToast,
    billeesList
  } = props;
  const dispatch = useDispatch()
  const token = "Bearer " + getWithExpiry("expiry_token");

  const [nameError, setnameError] = useState("");
  const [billeeError, setBilleeError] = useState("");
  const [priceError, setPriceError] = useState("");


  const [loading, setLoading] = useState(false);
  const [device, setDevice] = useState({});

  let formData = new FormData();
  const addDeviceSubmissionHandler = (event) => {
    event.preventDefault();
    // make http call
    setLoading(true);
    formData.append('name', device?.name || '');
    formData.append('description', device?.description || '');
    formData.append('bill_in_next_invoice', device?.bill_in_next_invoice ? 1 : 0);
    formData.append('billee_id', parseInt(device.billee));
    formData.append('price', parseInt(device.price));

    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/community/add_equipment/`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
        dispatch(communityActions.setAddEquipmentSucess(true))
      })
      .catch((error) => {
        setLoading(false);
        const errorData = error.response.data;
        if (error.response && errorData.message === "Field Errors") {
          if (errorData.message === "Field Errors") {

            if (errorData.data["name"]) {
              setnameError("Name is required");
            }
            if (errorData.data["price"]) {
              setPriceError("Price is required");
            }
            if (errorData.data["billee_id"]) {
              setBilleeError("Billee is required");
            }

          }
        }
      });
  };
  const onChange = (e) => {
    let { name, value } = e.target;
    setDevice({ ...device, [name]: value })
  }
  const handleSwitch = (e) => {
    let { checked } = e.target;
    if (checked) {
      setDevice({ ...device, bill_in_next_invoice: 1 })
    } else {
      setDevice({ ...device, bill_in_next_invoice: 0 })
    }
  }


  return (
    <div className="action-modal-wrapper">
      <Modal
        className="comunitie-modal initiate-test-patient-modal modal-loader"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
        scrollable
      >
        <Modal.Header
          className="comubitie-modal-header"
          style={{ padding: "1rem 0rem" }}
        >
          <h1 className="modal-header-title">
            Add Device
          </h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="comunitie-modal-body">
          <form
            method="post"
            className="add-patient-form"
            onSubmit={addDeviceSubmissionHandler}
          >
            <div className="communities-fields">
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="first_name" className="label">
                      Name
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Name"
                      className="custom-input"
                      value={device ? device.name : ""}
                      onChange={onChange}
                      required
                    />
                    {nameError && (
                      <p className="error-text">{nameError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="first_name" className="label">
                      Description
                    </label>
                    <input
                      id="description"
                      name="description"
                      type="text"
                      placeholder="Description"
                      className="custom-input"
                      value={device ? device.description : ""}
                      onChange={onChange}
                      required
                    />
                    {/* {firstNameError && (
                      <p className="error-text">{firstNameError}</p>
                    )} */}
                  </div>
                </div>

              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="first_name" className="label">
                      Price
                    </label>
                    <input
                      id="price"
                      name="price"
                      type="number"
                      placeholder="Price"
                      className="custom-input"
                      value={device ? device.price : ""}
                      onChange={onChange}
                      required
                    />
                    {priceError && (
                      <p className="error-text">{priceError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="first_name" className="label">
                      Billee
                    </label>
                    <select
                      name="billee"
                      id="role"
                      className="custom-select-modal"
                      onChange={onChange}

                    >
                      <option value="">Select Billee</option>
                      {billeesList && billeesList.map(billee => <option value={billee?.id}>{billee?.company}</option>)}
                    </select>
                    {billeeError && (
                      <p className="error-text">{billeeError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="first_name" className="label">
                      Bill In Next Month
                    </label>
                    <div className="form-check form-switch d-flex align-items-center">
                      <input className="form-check-input" onChange={handleSwitch} type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                    </div>
                    {/* {firstNameError && (
                      <p className="error-text">{firstNameError}</p>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="user-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16 text-capitalize"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            onClick={addDeviceSubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={loading}
          >
            Add
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
};

export default AddPatient;
