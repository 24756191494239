import { createSlice } from "@reduxjs/toolkit";

const initialPatientState = {
  patients: [],
  idToBeDeleted: "",
  idToBeDisabled: "",
  idToBeEnabled: "",
  successMessage: "",
  actionType: "",
  fileUploadData: [],
  communityList: [],
  isEnabled: "",
  patientDetails: [],
  upcomingTests: [],
  providerList: [],
  billeeList: [],
  patientsID: null,
  iphoneList: [],

  //patient history
  testPageNumber: 1,
  testTotalRecords: 0,
  testCurrentPage: 1,

  //patient
  patientPageNumber: 1,
  patientTotalRecords: 0,
  patientCurrentPage: 1,

  patientApiUrl: "",
  patientHistoryApiUrl: "",
  patientHistoryOrderQuery: "",

  patientHistoryTestDateFrom: "",
  patientHistoryTestDateTo: "",
  patientHistoryOrderedFrom: "",
  patientHistoryOrderedTo: "",
  patientHistoryStatus: "",
};

const patientSlice = createSlice({
  name: "patient",
  initialState: initialPatientState,
  reducers: {
    setPatientHistoryStatus(state, action) {
      state.patientHistoryStatus = action.payload;
    },
    setPatientHistoryTestDateFrom(state, action) {
      state.patientHistoryTestDateFrom = action.payload;
    },
    setPatientHistoryTestDateTo(state, action) {
      state.patientHistoryTestDateTo = action.payload;
    },
    setPatientHistoryOrderedFrom(state, action) {
      state.patientHistoryOrderedFrom = action.payload;
    },
    setPatientHistoryOrderedTo(state, action) {
      state.patientHistoryOrderedTo = action.payload;
    },

    setPatientApiUrl(state, action) {
      state.patientApiUrl = action.payload;
    },
    setPatientHistoryApiUrl(state, action) {
      state.patientHistoryApiUrl = action.payload;
    },
    setPatientHistoryOrderQuery(state, action) {
      state.patientHistoryOrderQuery = action.payload;
    },

    setPatientCurrentPage(state, action) {
      state.patientCurrentPage = action.payload;
    },

    setTestPageNumber(state, action) {
      state.testPageNumber = action.payload;
    },
    setTestTotalRecords(state, action) {
      state.testTotalRecords = action.payload;
    },
    setTestCurrentPage(state, action) {
      state.testCurrentPage = action.payload;
    },

    setPatientsPageNumber(state, action) {
      state.patientPageNumber = action.payload;
    },
    setIphoneList(state, action) {
      state.iphoneList = action.payload;
    },
    setProviderTestID(state, action) {
      state.patientsID = action.payload;
    },
    addPatients(state, action) {
      state.patients = action.payload;
    },
    // editPatient(state, payload) {
    //   const user = state.users.find((user) => user.id === payload);
    // },
    deletePatient(state, payload) {},
    disablePatient(state, payload) {},
    importPatients(state, payload) {
      state = payload;
    },
    addPatientSuccessMessage(state, action) {
      state.successMessage = action.payload;
    },
    setIdToBeDeleted(state, action) {
      state.idToBeDeleted = action.payload;
    },
    setIdToBeDisabled(state, action) {
      state.idToBeDisabled = action.payload;
    },
    setIdToBeEnabled(state, action) {
      state.idToBeEnabled = action.payload;
    },
    setActionType(state, action) {
      state.actionType = action.payload;
    },

    setFileUploadData(state, { payload }) {
      state.fileUploadData = payload;
    },
    setCommunityList(state, action) {
      state.communityList = action.payload;
    },
    setIsEnabled(state, action) {
      state.isEnabled = action.payload;
    },
    setPatientDetails(state, action) {
      state.patientDetails = action.payload;
    },
    setUpcomingTests(state, action) {
      state.upcomingTests = action.payload;
    },
    setProviderList(state, action) {
      state.providerList = action.payload;
    },
    setBilleeList(state, action) {
      state.billeeList = action.payload;
    },
    setPatientTotalRecords(state, action) {
      state.patientTotalRecords = action.payload;
    },
  },
});

export const patientActions = patientSlice.actions;

export default patientSlice.reducer;
