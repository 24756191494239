import React, { useState, useEffect, Fragment } from "react";
import TableDataEdit from "./Equipment.TableDataEdit";
import Select, { components } from "react-select";
import axios from "axios";
import { equipmentsActions } from "../../store/equipments";
import { useSelector, useDispatch } from "react-redux";
import { getWithExpiry } from "../utils/helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useQueryString from "../../hooks/useQueryString";
import { Tab, Tabs } from "react-bootstrap";
import Loader from "../UI/loader/loader";
import TableComponent from "../Communities/Table/TableData";

function Equipments() {
  const equipmentsTableHeadings = [
    "Community Name",
    "Billee Name",
    "Billee Company",
    "Equipment ID",
    "Equipment Price",
  ];

  const pastInvoicesTableHeadings = [
    "Invoice #",
    // "Invoice Date",
    "Invoice Due Date",
    "Community Name",
    "Billee Name",
    "Billee Company",
    // "Billee Address",
    "Invoice Status",
    "Amount Due",
    "View",
  ];

  const dispatch = useDispatch();
  const urlParams = useQueryString();
  const [key2, setKey2] = useState("Equipments");
  const [showToast, setShowToast] = useState(false);
  const [otherLoader2, setOtherLoader2] = useState(false);
  const [dataFlag, setDataFlag] = useState(false);
  const [auditLogModalOpen, setAuditLogModalOpen] = useState(false);
  const [applyCreditModalOpen, setApplyCreditModalOpen] = useState(false);
  const showApplyCreditModal = () => setApplyCreditModalOpen(true);
  const hideApplyCreditModal = () => setApplyCreditModalOpen(false);
  const [loading, setLoading] = useState(true);
  const [otherLoader, setOtherLoader] = useState(false);
  //
  const pastInvoiceList = useSelector(
    (state) => state.equipments.pastInvoiceList
  );
  const pastInvoicetotalRecords = useSelector(
    (state) => state.equipments.pastInvoicetotalRecords
  );
  const invoicesBillingApiUrl = useSelector(
    (state) => state.equipments.invoicesBillingApiUrl
  );
  const [dataFlag3, setDataFlag3] = useState(false);
  const [otherLoader3, setOtherLoader3] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState("ALL");
  const [invoiceBbillingData, setInvoiceBillingData] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [pastInvoicesRecords, setPasInvoicesRecords] = useState(0);
  const [pastInvoicesPages, setPasInvoicesPages] = useState(0);

  //
  const {
    equipments,
    equipmentsCurrentPage,
    total_equipment_records,
    equipmentsPageNumbers,
    equipmentOrderQuery,
  } = useSelector((state) => state.equipments);
  const { invoicesEquipmentsOrderQuery, pastInvoiceCurrentPage } = useSelector(
    (state) => state.equipments
  );

  const token = getWithExpiry("expiry_token");
  const equipmentsApiUrl = useSelector(
    (state) => state.equipments.equipmentsApiUrl
  );

  const equipmentQuery = equipmentOrderQuery.replace("?","")

  const equipmentQueryURL = `${process.env.REACT_APP_URL_PATH}/billing/get_billing_data_equipment/?equipment_to_bill_page=${equipmentsCurrentPage}&community_ids=ALL&${equipmentQuery}`;

  const equipmentURL = `${process.env.REACT_APP_URL_PATH}/billing/get_billing_data_equipment/?community_ids=ALL`;
  useEffect(() => {
    const load = async (url) => {
      axios({
        url: url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          setOtherLoader(false);
          setLoading(false);
          const resData = response.data.data;
          dispatch(equipmentsActions.setEquipmentsList(resData));
        })
        .catch((error) => {
          setLoading(false);
        });
    };
    if (equipmentOrderQuery) {
      load(equipmentQueryURL);
    } else {
      load(equipmentURL);
    }
  }, [equipmentOrderQuery]);

  const pastInvoicesQueryURL = `${process.env.REACT_APP_URL_PATH}/billing/get_past_invoices_equipment/${invoicesEquipmentsOrderQuery?invoicesEquipmentsOrderQuery:""}&community_ids=ALL&invoice_status=ALL&past_invoices_page=${pastInvoiceCurrentPage}`;
  const equipPastInvoicesURL = `${process.env.REACT_APP_URL_PATH}/billing/get_past_invoices_equipment/?invoice_status=ALL&community_ids=ALL`;
  useEffect(() => {
    const load = async (url) => {
      axios({
        url: url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((resp) => {
          setOtherLoader(false);
          setLoading(false);
          setInvoiceBillingData(resp.data.data);
          setInvoiceList(resp.data.data.past_invoices || []);
          setPasInvoicesRecords(resp.data.data.past_invoices_total_records);
          setPasInvoicesPages(resp.data.data.past_invoices_pages);
          dispatch(
            equipmentsActions.setPastInvoiceList(resp.data.data.past_invoices)
          );

          dispatch(
            equipmentsActions.setPastInvoiceTotalRecords(
              resp.data.data.past_invoices_total_records
            )
          );
          dispatch(
            equipmentsActions.setPastInvoicePageNumber(
              resp.data.data.past_invoices_pages
            )
          );
        })
        .catch((error) => {
          setLoading(false);
        });
    };
    if (invoicesEquipmentsOrderQuery) {
      load(pastInvoicesQueryURL);
    } else {
      load(equipPastInvoicesURL);
    }
  }, [invoicesEquipmentsOrderQuery]);

  const handleApplyCreditClickAction = () => {
    showApplyCreditModal();
  };
  return (
    <Fragment>
      {/* {error && <p className="error-text" style={{fontSize: "14px"}}>{`Corporates ${error}`}</p>} */}
      {/* {corporatesError && (
        <p
          className="error-text"
          style={{ fontSize: "14px" }}
        >{`Corporates ${corporatesError}`}</p>
      )} */}
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* <TableComponent
              tableHeadings={equipmentsTableHeadings}
              data={equipments}
              totalRecords={total_equipment_records}
              type="Equipments"
              setDataFlag={setDataFlag}
              setShowToast={setShowToast}
              otherLoader={otherLoader}
              setOtherLoader={setOtherLoader}
            /> */}

          <Tabs
            id="controlled-tab-example"
            activeKey={key2}
            onSelect={(k) => setKey2(k)}
            className="mb-3"
          >
            <Tab eventKey="Equipments" title="Equipments">
              <TableDataEdit
                tableHeadings={equipmentsTableHeadings}
                data={equipments}
                totalRecords={total_equipment_records}
                type="Equipments"
                dataType="Equipments"
                heading={"Equipments"}
                setDataFlag={setDataFlag}
                setShowToast={setShowToast}
                otherLoader={otherLoader}
                setOtherLoader={setOtherLoader}
                TabKey={key2}
              />
            </Tab>

            <Tab eventKey="pastInvoice" title="Invoices">
              <TableDataEdit
                tableHeadings={pastInvoicesTableHeadings}
                billingData={invoiceBbillingData}
                // data={billingData.past_invoices ? billingData.past_invoices : []}
                data={invoiceList}
                setInvoiceStatus={setInvoiceStatus}
                totalRecords={pastInvoicetotalRecords}
                allInvoices={invoiceBbillingData?.all_invoice_statuses}
                type="Equipments"
                dataType="equipastInvoices"
                heading={"Invoices"}
                communityName={"Community Name"}
                setDataFlag={setDataFlag3}
                setShowToast={setShowToast}
                otherLoader={otherLoader}
                setOtherLoader={setOtherLoader}
                TabKey={key2}
              />
            </Tab>
          </Tabs>
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </>
      )}

      {/* <ToastContainer position="bottom-right" /> */}
    </Fragment>
  );
}

export default Equipments;
