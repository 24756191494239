import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import useInput from "../../../../hooks/use-input";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import RolesList from "./RolesList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "react-phone-number-input/input";
import axios from "axios";
import "./AddUserModal.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { stripSrciptRegex } from "../../../../constants";

// import images
import CloseModal from "../../../../assets/close-outline.svg";
import DisabledIcon from "../../../../assets/disabled-row.svg";
import Loader2 from "../../../UI/loader2/loader2";
import { getWithExpiry } from "../../../utils/helper";
const isNotEmpty = (value) => value.trim() !== "";
const isEmailValid = (value) => value.includes("@");

function AddUserModal(props) {
  const {
    isShowModal,
    hideModal,
    type,
    regUserRoles,
    setDataFlag,
    setShowToast,
  } = props;
  const [loading, setLoading] = useState(false);

  const [inputList, setInputList] = useState([regUserRoles]);

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [otherRoleError, setOtherRoleError] = useState("");

  const [phoneValue, setPhoneValue] = useState("");

  const [addBtnDisabled, setAddBtnDisabled] = useState(false);
  const [role, setRole] = useState([""]);
  const [otherRole, setOtherRole] = useState([""]);
  const [checkedState, setCheckedState] = useState(new Array(7).fill(true));
  const [showCorporates, setShowCorporates] = useState([false]);
  const [showCommunities, setShowCommunities] = useState([false]);
  const [showAllCommunities, setShowAllCommunities] = useState([false]);
  const [corporatePermissions, setCorporatePermissions] = useState(false);
  const [communityPermissions, setCommunityPermissions] = useState(false);
  const [showIndivCommunities, setShowIndivCommunities] = useState([false]);

  const [phoneNumValue, setPhoneNumValue] = useState("");

  //Start Creating State Object for Form
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    telephone_number: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  //End Creating State Object for Form

  const communityList = useSelector((state) => state.reguser.communityList);
  const corporateList = useSelector((state) => state.reguser.corporateList);
  const isCatchuAdmin = useSelector((state) => state.user.isCatchuAdmin);
  const permissions = useSelector((state) => state.user.permissions);
  const PermissionsArray = [
    "Corporate",
    "Community",
    "Patients",
    "Tests",
    "Providers",
    "Billing",
    "Users",
  ];

  const indEnabledCommunities = communityList.filter(
    (community) => community.is_individual_practice === true
  );

  const nonIndCommunities = communityList.filter(
    (community) => community.is_individual_practice === false
  );
  const params = useParams();
  const { corporateID: corporateId } = params;

  useEffect(() => {
    if (!permissions) {
      setCorporatePermissions(false);
      setCommunityPermissions(false);
      return;
    }
    if (permissions.includes("Corporate")) {
      setCorporatePermissions(true);
      setCommunityPermissions(false);
    } else if (permissions.includes("Community")) {
      setCorporatePermissions(false);
      setCommunityPermissions(true);
    } else {
      setCorporatePermissions(false);
      setCommunityPermissions(false);
    }
  }, []);

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const token = "Bearer " + getWithExpiry("expiry_token");

  let url;

  if (type === "RegUser") {
    url = `${process.env.REACT_APP_URL_PATH}/user/add_regular_user/`;
  } else {
    url = `${process.env.REACT_APP_URL_PATH}/user/add_admin/`;
  }

  const addRoleFieldHandler = (event) => {
    event.preventDefault();
    //
    setInputList([...inputList, regUserRoles]);
    setRole([...role, ""]);
    setOtherRole([...otherRole, ""]);
  };

  const removeRoleFieldHandler = (event) => {
    event.preventDefault();
    //
    if (inputList.length > 1) {
      // const list = [...inputList];
      // list.pop();
      // setInputList(list);

      setInputList((prevItems) => prevItems.slice(0, -1));
      setRole((prevItems) => prevItems.slice(0, -1));
      setOtherRole((prevItems) => prevItems.slice(0, -1));
    }
  };
  const addUserSubmissionHandler = (event) => {
    event.preventDefault();
    setLoading(true);

    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");

    //if ((role === "" || otherRole === "") && type !== "AdminUser") return;

    setAddBtnDisabled(true);

    // create form data here

    const fd = new FormData();
    fd.append("first_name", user.first_name);
    fd.append("last_name", user.last_name);
    fd.append("email", user.email);

    //let phoneVal = user.telephone_number.replace(/\s+/g, "");
    if (phoneValue) {
      fd.append("telephone_number", "+" + phoneValue);
    }

    if (type === "AdminUser") {
      checkedState.map((item, index) => {
        if (item) {
          let indVal = PermissionsArray[index];
          fd.append(indVal, indVal);
        }
      });

      //fd.append("role", "Catchu Admin");
      //fd.append("corporate_id", otherRole);
    } else if (type === "RegUser") {
      let result = [];
      for (let i = 0; i < role.length; i++) {
        const tempObj = {
          [role[i]]: otherRole[i],
        };

        result.push(tempObj);
      }

      // let result = otherRole.reduce(function (result, field, index) {
      //   result[role[index]] = field;
      //   return result;
      // }, {});
      fd.append("roles_mapping", JSON.stringify(result));
    }
    // make http call
    axios({
      method: "post",
      url: url,
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      })
      .catch((error) => {
        setAddBtnDisabled(false);
        if (error.response.data.message === "Field Errors") {
          if (
            error.response.data.data["user_form"] &&
            "first_name" in error.response.data.data["user_form"]
          ) {
            setFirstNameError(
              error.response.data.data["user_form"]["first_name"][0]
            );
          }
          if (
            error.response.data.data["user_form"] &&
            "last_name" in error.response.data.data["user_form"]
          ) {
            setLastNameError(
              error.response.data.data["user_form"]["last_name"][0]
            );
          }

          if (
            error.response.data.data["user_form"] &&
            "email" in error.response.data.data["user_form"]
          ) {
            setEmailError(error.response.data.data["user_form"]["email"][0]);
          }

          if (
            error.response.data.data["phone_form"] &&
            "telephone_number" in error.response.data.data["phone_form"]
          ) {
            setPhoneError(
              error.response.data.data["phone_form"]["telephone_number"][0]
            );
          }
        } else {
          notifyError(error.response.data.message);
        }
        setLoading(false);
      });
  };

  const setPageStates = (a, b, c, d, index) => {
    let temp1 = [...showCorporates];
    temp1[index] = a;
    let temp2 = [...showCommunities];
    temp2[index] = b;
    let temp3 = [...showIndivCommunities];
    temp3[index] = c;
    let temp4 = [...otherRole];
    temp4[index] = "";
    let temp5 = [...showAllCommunities];
    temp5[index] = d;

    setShowCorporates(temp1);
    setShowCommunities(temp2);
    setShowIndivCommunities(temp3);
    setOtherRole(temp4);
    setShowAllCommunities(temp5);
  };

  const roleChangeHandler = (e, index) => {
    const { value } = e.target;
    let temp = [...role];
    temp[index] = value;
    setRole(temp);

    if (e.target.value !== "") {
      if (e.target.value === "Corporate HQ Administrator") {
        setPageStates(true, false, false, false, index);
      } else if (e.target.value === "Individual Practice Admin") {
        setPageStates(false, false, true, false, index);
      } else if (e.target.value.includes("Support")) {
        setPageStates(false, false, false, true, index);
      } else {
        setPageStates(false, true, false, false, index);
      }
    } else {
      setPageStates(false, false, false, false, index);
    }
  };

  const otherRoleChangeHandler = (e, index) => {
    const { value } = e.target;

    let temp = [...otherRole];

    temp[index] = value;
    setOtherRole(temp);
  };

  const checkBoxHandler = (position, e) => {
    const { name, value } = e.target;

    const updatedCheckedState = checkedState.map((item, index) => {
      return index === position ? !item : item;
    });

    setCheckedState(updatedCheckedState);
  };

  return (
    <div className="action-modal-wrapper">
      <Modal
        className="comunitie-modal modal-loader"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        {/* {loading && <Loader2 />} */}
        <Modal.Header
          className="comubitie-modal-header"
          style={user_modal_header}
        >
          <h1 className="modal-header-title">Add User</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>

        <Modal.Body className="comunitie-modal-body">
          <form
            
            method="post"
            className="add-corporate-form"
            onSubmit={addUserSubmissionHandler}
          >
            <div className="communities-fields">
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="first_name" className="label">
                      First Name *
                    </label>
                    <input
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="First Name"
                      className="custom-input"
                      value={user.first_name}
                      onChange={handleChange}
                      required
                    />
                    {firstNameError && (
                      <p className="error-text">{firstNameError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="last_name" className="label">
                      Last Name *
                    </label>
                    <input
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Last Name"
                      className="custom-input"
                      value={user.last_name}
                      onChange={handleChange}
                      required
                    />
                    {lastNameError && (
                      <p className="error-text">{lastNameError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="email" className="label">
                      Email *
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="custom-input"
                      placeholder="Email"
                      value={user.email}
                      onChange={handleChange}
                      required
                    />
                    {emailError && <p className="error-text">{emailError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="telephone_number" className="label">
                      Telephone *
                    </label>
                    {/* <input
                      type="text"
                      id="telephone_number"
                      name="telephone_number"
                      className="custom-input"
                      placeholder="Telephone"
                      value={user.telephone_number}
                      onChange={(e) => {
                        const { name, value } = e.target;

                        const regex = /^([\s+0-9])+$/i;
                        if (value === "") {
                          // case to remove whole highlighted text
                          setUser({ ...user, [name]: value });
                          return;
                        }
                        if (regex.test(value) === false) return;

                        if (value.length === 1 && value === "+") {
                          // case to remove + sign when last number is removed
                          setUser({ ...user, [name]: "" });
                        } else if (value.length >= 1 && value[0] !== "+") {
                          // case to append + sign with first number
                          setUser({ ...user, [name]: "+" + value });
                        } else {
                          // case to append other numbers
                          setUser({ ...user, [name]: value });
                        }
                      }}
                      required
                    /> */}
                    <PhoneInput
                      country={"us"}
                      value={phoneValue}
                      onChange={setPhoneValue}
                      inputProps={{
                        required: true,
                      }}
                    />
                    {phoneError && <p className="error-text">{phoneError}</p>}
                  </div>
                </div>
              </div>
              {type === "AdminUser" && (
                <hr
                  style={{
                    height: 0.4,
                    color: "gray",
                  }}
                />
              )}
              {type === "AdminUser" ? (
                <div className="field-wrapper">
                  <label htmlFor="permissions" className="label">
                    Permissions *
                  </label>

                  <ul className="check-list">
                    {[
                      "Corporates",
                      "Communities",
                      "Patients",
                      "Tests",
                      "Providers",
                      "Billing",
                      "Users",
                    ].map((name, index) => {
                      return (
                        <li key={index} className="custom-check-orange">
                          <div className="toppings-list-item">
                            <div className="left-section">
                              <input
                                className="styled-checkbox check-form"
                                type="checkbox"
                                id={`custom-checkbox-${index}`}
                                name={name}
                                value={name}
                                checked={checkedState[index]}
                                onChange={(e) => checkBoxHandler(index, e)}
                              />
                              <label htmlFor={`custom-checkbox-${index}`}>
                                {name}
                              </label>
                            </div>
                            {/* <div className="right-section">{getFormattedPrice(price)}</div> */}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <>
                  {inputList.map((value, index) => {
                    return (
                      <div key={index} className="row">
                        <div className="col-lg-6 right-col">
                          <div className="field-wrapper">
                            <label htmlFor="permissions" className="label">
                              Role *
                            </label>

                            <select
                              name="role"
                              id="role"
                              className="custom-select-modal"
                              onChange={(e) => roleChangeHandler(e, index)}
                              value={role[index]}
                            >
                              {role[index] === "" && (
                                <option value="">{"Select a Role"}</option>
                              )}
                              {value.map((field) => (
                                <option key={Math.random()} value={field}>
                                  {field}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {showCorporates[index] === true && (
                          <div className="col-lg-6 right-col">
                            <div className="field-wrapper">
                              <label htmlFor="community_id" className="label">
                                Corporates *
                              </label>
                              <select
                                name="community_id"
                                id="community_id"
                                className="custom-select-modal"
                                onChange={(e) =>
                                  otherRoleChangeHandler(e, index)
                                }
                                value={otherRole[index]}
                              >
                                {/* <RolesList customList={corporateList} /> */}
                                {otherRole[index] === "" && (
                                  <option value="">
                                    {"Select a Corporate"}
                                  </option>
                                )}
                                {corporateList.map((corporate) => (
                                  <option
                                    key={corporate.id}
                                    value={`${corporate.id}`}
                                  >
                                    {corporate.corporate_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                        {showAllCommunities[index] === true && (
                          <div className="col-lg-6 right-col">
                            <div className="field-wrapper">
                              <label htmlFor="community_id" className="label">
                                Communities *
                              </label>
                              <select
                                name="community_id"
                                id="community_id"
                                className="custom-select-modal"
                                onChange={(e) =>
                                  otherRoleChangeHandler(e, index)
                                }
                                value={otherRole[index]}
                              >
                                {otherRole[index] === "" && (
                                  <option value="">
                                    {"Select a Community"}
                                  </option>
                                )}
                                {communityList.map((community) => (
                                  <option
                                    key={community.id}
                                    value={`${community.id}`}
                                  >
                                    {community.community_name.replace(stripSrciptRegex, "")}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                        {showCommunities[index] === true && (
                          <div className="col-lg-6 right-col">
                            <div className="field-wrapper">
                              <label htmlFor="community_id" className="label">
                                Communities *
                              </label>
                              <select
                                name="community_id"
                                id="community_id"
                                className="custom-select-modal"
                                onChange={(e) =>
                                  otherRoleChangeHandler(e, index)
                                }
                                value={otherRole[index]}
                              >
                                {otherRole[index] === "" && (
                                  <option value="">
                                    {"Select a Community"}
                                  </option>
                                )}
                                {nonIndCommunities.map((community) => (
                                  <option
                                    key={community.id}
                                    value={`${community.id}`}
                                  >
                                    {community.community_name.replace(stripSrciptRegex, "")}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                        {showIndivCommunities[index] === true && (
                          <div className="col-lg-6 right-col">
                            <div className="field-wrapper">
                              <label htmlFor="community_id" className="label">
                                Communities *
                              </label>
                              <select
                                name="community_id"
                                id="community_id"
                                className="custom-select-modal"
                                onChange={(e) =>
                                  otherRoleChangeHandler(e, index)
                                }
                                value={otherRole[index]}
                              >
                                {otherRole[index] === "" && (
                                  <option value="">
                                    {"Select an Individual Community"}
                                  </option>
                                )}
                                {indEnabledCommunities.map((community) => (
                                  <option
                                    key={community.id}
                                    value={`${community.id}`}
                                  >
                                    {community.community_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}

                  <div>
                    <button
                      // type="submit"
                      onClick={addRoleFieldHandler}
                      style={add_user_btns}
                      className="btn btn-primary-fill btn-primary-16 add-user-btns"

                      //disabled={addBtnDisabled}
                    >
                      Add
                    </button>

                    <button
                      // type="submit"
                      onClick={removeRoleFieldHandler}
                      style={remove_user_btn}
                      className="btn btn-primary-fill btn-primary-16 add-user-btns ml-1"
                      //disabled={addBtnDisabled}
                    >
                      Remove
                    </button>
                  </div>
                </>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16"
            onClick={hideModal}
            id="modal-close-btn"
          >
            Close
          </button>
          <button
            // type="submit"
            onClick={addUserSubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={addBtnDisabled}
          >
            Add
          </button>
          {/* <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          /> */}
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

const add_user_btns = {
  display: "inline-block",
  padding: "6px 16px",
  fontSize: "12px",
};

const remove_user_btn = {
  display: "inline-block",
  padding: "4px 6px",
  fontSize: "12px",
  marginLeft: "0.5rem",
};

const user_modal_header = {
  paddingLeft: 0,
  paddingRight: 0,
};

export default AddUserModal;
