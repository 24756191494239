import axios from "axios";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../UI/loader/loader";
import Loader2 from "../UI/loader2/loader2";
import { getWithExpiry } from "../utils/helper";
import "./ContactUs.scss";

const ContactUs = () => {
  const token = "Bearer " + getWithExpiry("expiry_token");
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setIsError] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [formItems, setFormItems] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (value == "") {
      setIsError({
        ...error,
        [name]: `${name.charAt(0).toUpperCase() + name.slice(1)} is required`,
      });
    } else {
      setIsError({ ...error, [name]: `` });
    }
    setFormItems({ ...formItems, [name]: value });
  };

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const contactUsSubmitHandler = (e) => {
    e.preventDefault();
    if (
      formItems.name &&
      formItems.email &&
      formItems.subject &&
      formItems.message
    ) {
      setIsLoading(true);
      axios({
        url: `${process.env.REACT_APP_URL_PATH}/system/contact_us/`,
        data: formItems,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((response) => {
          setIsLoading(false);
          notifySuccess(response.data.message);
        })
        .catch((error) => {
          setIsLoading(false);
          notifyError(error.response.data.message);
        });
    }
    setFormItems({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  useEffect(() => {
    if (
      formItems.name &&
      formItems.email &&
      formItems.subject &&
      formItems.message
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [formItems, error]);

  return (
    <div className="contac-us-form-container">
      <ToastContainer />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <form
            method="post"
            className="contact-us-form"
            onSubmit={contactUsSubmitHandler}
          >
            <div className="communities-fields">
              <div className="col-lg-6 left-col">
                <div className="field-wrapper">
                  <label htmlFor="name" className="label">
                    Name *
                  </label>
                  <input
                    value={formItems.name}
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Name"
                    className="custom-input"
                    onChange={changeHandler}
                    required
                  />
                  {error.name && <p className="error-text">{error.name}</p>}
                </div>
              </div>
              <div className="col-lg-6 left-col">
                <div className="field-wrapper">
                  <label htmlFor="email" className="label">
                    Email *
                  </label>
                  <input
                    value={formItems.email}
                    id="email"
                    name="email"
                    type="email"
                    placeholder="email"
                    className="custom-input"
                    onChange={changeHandler}
                    required
                  />
                  {error.email && <p className="error-text">{error.email}</p>}
                </div>
              </div>
              <div className="col-lg-6 left-col">
                <div className="field-wrapper">
                  <label htmlFor="subject" className="label">
                    Subject *
                  </label>
                  <input
                    value={formItems.subject}
                    id="subject"
                    name="subject"
                    type="text"
                    placeholder="subject"
                    className="custom-input"
                    onChange={changeHandler}
                    required
                  />
                  {error.subject && (
                    <p className="error-text">{error.subject}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-6 left-col">
                <div className="field-wrapper">
                  <label htmlFor="message" className="label">
                    Message *
                  </label>
                  <textarea
                    value={formItems.message}
                    id="message"
                    name="message"
                    type="textarea"
                    placeholder="Message"
                    className="custom-input textarea"
                    onChange={changeHandler}
                    required
                  />
                  {error.message && (
                    <p className="error-text">{error.message}</p>
                  )}
                </div>
              </div>
            </div>
            <button
              className="btn btn-primary-fill btn-primary-16"
              type="submit"
              disabled={isValid}
            >
              Submit
            </button>
          </form>
        </>
      )}
    </div>
  );
};
export default ContactUs;
