import React, { useState, useEffect, Fragment } from "react";
import "./PatientDetail.scss";
import { useParams } from "react-router-dom";
import ResendModal from "./ResendLink";
// imports Image
import Email from "../../assets/email.svg";
import Calendar from "../../assets/calendar.svg";
import Phone from "../../assets/phone.svg";
import DPhone from "../../assets/d-phone.svg";
import DPhone1 from "../../assets/d-phone1.svg";
// import TableComponent from "../UI/Table/TableData";
import TableComponent from "../UI/Table/PatientHistory.TableDataEdit";
import Pencil from "../../assets/pencil-icon.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PatientHistory from "./PatientHistory";
import { useDispatch, useSelector } from "react-redux";
import { patientActions } from "../../store/patient";
import { sidebarActions } from "../../store/sidebar";
import { crumbActions } from "../../store/breadcrumb";
import DisableModal from "../Corporate/Modals/DisableModal/DisableModal";
import EnableModal from "../Corporate/Modals/EnableModal/EnableModal";
import CreateTest from "../UI/Modals/CreateTestModal";
import AddPatient from "../Patient/Modals/AddPatient";
import InitiateTest from "./Modals/InitiateTest";
import axios from "axios";
import EditCreateModel from "../UI/Modals/EditCreateModel";
import NotFound from "../NotFound/NotFound";
import Loader from "../UI/loader/loader";
import { getWithExpiry } from "../utils/helper";
import useQueryString from "../../hooks/useQueryString";
function PatientsDetail() {
  const params = useParams();
  const urlParams = useQueryString();
  const { patientID: patientId } = params;

  const [loading, setLoading] = useState(true);
  const [otherLoader, setOtherLoader] = useState(false);

  const [dataFlag, setDataFlag] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const patientHistoryTableHeadings = [
    "Provider",
    "Ordered",
    "Status",
    "Test Date",
    "Location",
    "Remote",
    "Credit Amount",
    "Credit Reason",
    "Actions",
  ];

  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);
  const [disableModalOpen, setDisableModalOpen] = useState(false);
  const showDisableModal = () => setDisableModalOpen(true);
  const hideDisableModal = () => setDisableModalOpen(false);
  const [patientActionVal, setPatientActionVal] = useState("Patient Action");

  const [createTestModalOpen, setCreateTestModalOpen] = useState(false);
  const [addPatientModalOpen, setAddPatientModalOpen] = useState(false);
  const [enableModalOpen, setEnableModalOpen] = useState(false);
  const showEnableModal = () => setEnableModalOpen(true);
  const hideEnableModal = () => setEnableModalOpen(false);
  const showAddPatientModalOpen = () => setAddPatientModalOpen(true);
  const hideAddPatientModalOpen = () => setAddPatientModalOpen(false);
  const showCreateTestModal = () => setCreateTestModalOpen(true);
  const hideCreateTestModal = () => setCreateTestModalOpen(false);
  const [resendID, setResendID] = useState("");
  const [resendModalOpen, setResendModalOpen] = useState(false);
  const showResendModal = () => setResendModalOpen(true);
  const hideResendModal = () => setResendModalOpen(false);

  const [initiateTestModalOpen, setInitiateTestModalOpen] = useState(false);
  const [editTestModalOpen, setEditTestModalOpen] = useState(false);
  const showInitiateTestModal = () => setInitiateTestModalOpen(true);
  const hideInitiateTestModal = () => setInitiateTestModalOpen(false);
  const showEditTestModal = () => setEditTestModalOpen(true);
  const hideEditTestModal = () => setEditTestModalOpen(false);
  const dispatch = useDispatch();
  const token = "Bearer " + getWithExpiry("expiry_token");
  const providerList = useSelector((state) => state.patient.providerList);
  const billeeList = useSelector((state) => state.patient.billeeList);
  const patientDetails = useSelector((state) => state.patient.patientDetails);

  const testTotalRecords = useSelector(
    (state) => state.patient.testTotalRecords
  );
  const testCurrentPage = useSelector((state) => state.patient.testCurrentPage);

  const upcomingTests = useSelector((state) => state.patient.upcomingTests);
  const crumbs = useSelector((state) => state.sidebar.crumbs);
  const [location, setLocation] = useState(null);
  const [user, setUser] = useState(null);
  const [testData, setTestData] = useState(null);
  const [initiateTestData, setInitiateTestData] = useState(null);
  const [initiateTestID, setInitiateTestID] = useState(null);
  const [pageExist, setPageExist] = useState(true);

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    if (params.corporateID) {
      axios({
        url: `${process.env.REACT_APP_URL_PATH}/corporate/get_corporate_name/${params.corporateID}/`,
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((response) => {
          const resData = response.data.data;
          dispatch(
            crumbActions.setCorporateName({
              [params.corporateID]: resData.corporate_name,
            })
          );
        })
        .catch((error) => {});
    }
  }, []);

  useEffect(() => {
    if (params.communityID) {
      axios({
        url: `${process.env.REACT_APP_URL_PATH}/community/get_community_name/${params.communityID}/`,
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((response) => {
          const resData = response.data.data;
          dispatch(
            crumbActions.setCommunityName({
              [params.communityID]: resData.community_name,
            })
          );
        })
        .catch((error) => {});
    }
  }, []);

  const patientHistoryApiUrl = useSelector(
    (state) => state.patient.patientHistoryApiUrl
  );
  useEffect(() => {
    if (!showToast) {
      setLoading(true);
    }

    let urlKeys = [...urlParams.keys()];
    let urlValues = [...urlParams.values()];

    let query;
    if (!patientHistoryApiUrl && urlKeys.length > 0) {
      query = "?";
      for (let i = 0; i < urlKeys.length; i++) {
        if (urlKeys[i] !== "page") {
          query = query + urlKeys[i] + "=" + urlValues[i] + "&";
        }
      }
    } else {
      query = patientHistoryApiUrl ? patientHistoryApiUrl + "&" : "?";
    }

    let pageNumber = urlParams.has("page")
      ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
        ? urlParams.get("page")
        : testCurrentPage
      : testCurrentPage;

    const Url = `${process.env.REACT_APP_URL_PATH}/community/view_patient/${patientId}${query}page=${pageNumber}`;
    query = query.replace("&&", "&");
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/community/view_patient/${patientId}${query}page=${pageNumber}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        const resData = response.data.data;

        dispatch(
          crumbActions.setPatientName({
            [resData.patient.id]:
              resData.patient.first_name + " " + resData.patient.last_name,
          })
        );

        setLocation(resData.location);

        dispatch(
          patientActions.setProviderTestID(resData.patient.patient_test_id)
        );

        dispatch(patientActions.setPatientDetails(resData.tests));
        dispatch(patientActions.setUpcomingTests(resData.upcoming_tests));
        dispatch(patientActions.setProviderList(resData.provider_list));
        dispatch(patientActions.setBilleeList(resData.billee_list));
        dispatch(patientActions.setIphoneList(resData.iphones));

        dispatch(patientActions.setTestPageNumber(resData.test_pages));
        dispatch(patientActions.setTestTotalRecords(resData.total_records));

        setUser(resData.patient);
        setLoading(false);
        setOtherLoader(false);
      })
      .catch((error) => {
        if (
          error.response.data.message === "Invalid Patient" ||
          error.response.data.message === "404 Not Found!"
        ) {
          setPageExist(false);
        }
        setLoading(false);
        setOtherLoader(false);
      });
  }, [dataFlag]);

  const handleEditTestAction = (data) => {
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/test/edit_test/${data.id}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        setTestData(response.data.data);
        showEditTestModal();
      })
      .catch((error) => {});
  };

  const handleInitiateTestAction = (data) => {
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/test/initiate_test/${data.id}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        setInitiateTestData(response.data.data);
        setInitiateTestID(data.id);
        showInitiateTestModal();
      })
      .catch((error) => {});
  };

  const selectAction = (e) => {
    setPatientActionVal(e.target.value);
    switch (e.target.value) {
      case "edit":
        showAddPatientModalOpen();
        break;
      case "disable":
        showDisableModal();
        break;
      case "enable":
        showEnableModal();
        break;
      case "transfer":
        break;
      default:
        break;
    }
    setPatientActionVal("Patient Action");
  };

  const checkPermission = () => {
    if (
      userRole &&
      (userRole.includes("Super Admin") ||
        userRole.includes("Support Staff") ||
        userRole.includes("Community Admin") ||
        userRole.includes("Individual Practice Admin") ||
        userRole.includes("Provider"))
      // ||
      // userRole.includes("CatchU Admin"))
    ) {
      return true;
    } else if (
      permissions &&
      permissions.includes("Patients") &&
      !permissions.includes("Tests")
    ) {
      return false;
    } else if (permissions && permissions.includes("Patients")) {
      return true;
    } else if (isCatchUAdmin) {
      return false;
    } else {
      return false;
    }
  };

  if (!pageExist) {
    return <NotFound />;
  }

  return (
    <Fragment>
      {resendModalOpen && (
        <ResendModal
          isShowModal={resendModalOpen}
          hideModal={hideResendModal}
          //type={type}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          resendID={resendID}
        />
      )}
      {addPatientModalOpen && (
        <AddPatient
          isShowModal={addPatientModalOpen}
          hideModal={hideAddPatientModalOpen}
          record={user}
          type={"PatientDetails"}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          isEdit={true}
        />
      )}

      {disableModalOpen && (
        <DisableModal
          isShowModal={disableModalOpen}
          hideModal={hideDisableModal}
          type="PatientDetails"
          id={user?.id}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          disabledId={user?.id}
        />
      )}
      {enableModalOpen && (
        <EnableModal
          type={"PatientDetails"}
          isShowModal={enableModalOpen}
          hideModal={hideEnableModal}
          id={user?.id}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          enabledId={user?.id}
        />
      )}
      {createTestModalOpen && (
        <CreateTest
          isShowModal={createTestModalOpen}
          hideModal={hideCreateTestModal}
          providerList={providerList}
          billeeList={billeeList}
          type="PatientDetails"
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          location={location}
          user={user}
        />
      )}
      {initiateTestModalOpen && (
        <InitiateTest
          isShowModal={initiateTestModalOpen}
          hideModal={hideInitiateTestModal}
          type="PatientDetails"
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          initiateTestData={initiateTestData}
          initiateTestID={initiateTestID}
        />
      )}
      {editTestModalOpen && (
        <EditCreateModel
          isShowModal={editTestModalOpen}
          hideModal={hideEditTestModal}
          providerList={providerList}
          billeeList={billeeList}
          type="PatientDetails"
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          testData={testData}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <div className="corp-d-wrapper">
          <div className="corp-d-header-content">
            <div className="first-cdh-content">
              <div className="cdh-first-top-row">
                <div className="cdh-left">
                  <h1 className="cdh-heading">Patient Details</h1>
                </div>

                <ul className="cdh-right">
                  {/* <li className="cdh-inner-single">
                    <div className="cdh-select">
                      <select
                        className="custom-select"
                        onChange={selectAction}
                        value={patientActionVal}
                      >
                        <option selected disabled>
                          Patient Action
                        </option>
                        <option value="edit">Edit</option>
                        {user?.is_enabled ? (
                          <option value="disable">Disable</option>
                        ) : (
                          <option value="enable">Enable</option>
                        )}
                        <option value="transfer">Transfer</option>
                      </select>
                    </div>
                  </li> */}

                  <li className="cdh-inner-single">
                    <button
                      className="btn btn-primary-outline"
                      onClick={showAddPatientModalOpen}
                    >
                      Edit
                    </button>
                    {/* <button className="btn btn-primary-outline">Edit</button> */}
                  </li>

                  {checkPermission() === true && (
                    <li className="cdh-inner-single">
                      <button
                        className="btn btn-primary-fill btn-primary-16"
                        onClick={() => {
                          if (user?.is_enabled) {
                            showCreateTestModal();
                          } else {
                            notifyError(
                              "Cannot Create Test for Disabled Patient"
                            );
                          }
                        }}
                      >
                        Create Test
                      </button>
                    </li>
                  )}
                </ul>
              </div>
              <div className="cdh-second-row-content">
                <h3 className="cdh-s-heading">
                  {user?.first_name} {user?.last_name}
                </h3>
                <ul className="cdh-details">
                  <li className="cdh-single-detail cdh-class-second">
                    <div className="cdh-inner-single">
                      <img className="detail-img" src={Calendar} alt="" />
                      <p className="cdh-inner-des">{user?.dob}</p>
                    </div>
                    {/* <img className="pencil-icon" src={Pencil} alt="" /> */}
                  </li>
                  <li className="cdh-single-detail">
                    <img className="detail-img" src={DPhone} alt="" />
                    <p className="cdh-inner-des">{user?.telephone_number}</p>
                  </li>
                  <li className="cdh-single-detail cdh-class-second">
                    <div className="cdh-inner-single">
                      <img className="detail-img" src={Email} alt="" />
                      <p className="cdh-inner-des">{user?.email}</p>
                    </div>
                    {/* <img className="pencil-icon" src={Pencil} alt="" /> */}
                  </li>
                  {/* <li className="cdh-single-detail">
                    <img className="detail-img" src={Phone} alt="" />
                    <p className="cdh-inner-des">{user?.dob}</p>
                  </li> */}
                </ul>
              </div>
            </div>

            {checkPermission() === true && (
              <div className="second-cdh-content">
                <div className="cdh-first-top-row">
                  <div className="cdh-left">
                    <h1 className="cdh-heading heading-tag">Upcoming Tests</h1>
                    {/* <span className="tag zip-tag">Pending</span> */}
                  </div>
                </div>
                {upcomingTests?.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className="communitie-edit-view second-row-edit-communitie"
                    >
                      <ul className="second-inner-row-content">
                        <li className="inner-s-single">
                          <h3 className="iss-heading">Test ID</h3>
                          <span className="iss-des">{value.test_id}</span>
                        </li>
                        <li className="inner-s-single">
                          <h3 className="iss-heading">Ordered Test</h3>
                          <span className="iss-des">{value.ordered}</span>
                        </li>
                        <li className="inner-s-single">
                          <h3 className="iss-heading">Test Mobile</h3>
                          <span className="iss-des iss-not-defined">
                            {value.test_number}
                          </span>
                        </li>
                        <li className="inner-s-single">
                          <h3 className="iss-heading">Scheduled</h3>
                          <span className="iss-des iss-not-defined">
                            {value.scheduled}
                          </span>
                        </li>
                        <li className="inner-s-single">
                          <h3 className="iss-heading">Test Location</h3>
                          <span className="iss-des" title={value.location}>
                            {value.location ? value.location : "N/A"}
                          </span>
                        </li>
                      </ul>
                      <ul className="cdh-right">
                        <li className="cdh-inner-single">
                          <button
                            className="btn btn-primary-fill btn-primary-16"
                            onClick={() => {
                              if (user?.is_enabled) {
                                setResendID(value.id);
                                showResendModal();
                              } else {
                                notifyError(
                                  "Cannot Resend Test for Disabled Patient"
                                );
                              }
                            }}
                          >
                            Resend Link
                          </button>
                        </li>
                        <li className="cdh-inner-single">
                          <button
                            className="btn btn-primary-outline"
                            onClick={() => handleEditTestAction(value)}
                          >
                            Edit
                          </button>
                        </li>
                        <li className="cdh-inner-single">
                          <button
                            className="btn btn-primary-fill btn-primary-16"
                            onClick={() => {
                              if (user?.is_enabled) {
                                handleInitiateTestAction(value);
                              } else {
                                notifyError(
                                  "Cannot Initiate Test for Disabled Patient"
                                );
                              }
                            }}
                          >
                            Initiate Test
                          </button>
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {checkPermission() === true && (
            <TableComponent
              heading="Patient History"
              tableHeadings={patientHistoryTableHeadings}
              data={patientDetails}
              totalRecords={testTotalRecords}
              isImport={false}
              isAdd={false}
              type="Patient"
              subType="Patient History"
              setDataFlag={setDataFlag}
              setShowToast={setShowToast}
              otherLoader={otherLoader}
              setOtherLoader={setOtherLoader}
            />
          )}
        </div>
      )}
    </Fragment>
  );
}

export default PatientsDetail;
