import { createSlice } from "@reduxjs/toolkit";

const initialEquipmentsState = {
  pastInvoiceList: [],
  pastInvoiceDetail: {},
  pastInvoicePageNumber: 1,
  pastInvoiceCurrentPage: 1,
  pastInvoicetotalRecords: 0,

  pastInvoiceHistoryPageNumber: 1,
  pastInvoiceHistoryCurrentPage: 1,
  pastInvoiceHistorytotalRecords: 0,
  invoicesEquipmentsOrderQuery: "",
  equipments: {},
  equipmentsCurrentPage: 1,
  equipmentsApiUrl: "",
  total_equipment_records: 0,
  equipmentsPageNumbers: "",
  equipmentOrderQuery: "",
};

const EquipmentSlice = createSlice({
  name: "equipments",
  initialState: initialEquipmentsState,
  reducers: {
    setPastInvoiceHistoryPageNumber(state, action) {
      state.pastInvoiceHistoryPageNumber = action.payload;
    },
    setPastInvoiceHistoryCurrentPage(state, action) {
      state.pastInvoiceHistoryCurrentPage = action.payload;
    },
    setPastInvoiceHistorytotalRecords(state, action) {
      state.pastInvoiceHistorytotalRecords = action.payload;
    },

    setInvoicesEquipmentsOrderQuery(state, action) {
      state.invoicesEquipmentsOrderQuery = action.payload;
    },

    setInvoicesBillingApiUrl(state, action) {
      state.invoicesBillingApiUrl = action.payload;
    },

    setPastInvoiceList(state, action) {
      state.pastInvoiceList = action.payload;
    },
    setPastInvoicePageNumber(state, action) {
      state.pastInvoicePageNumber = action.payload;
    },
    setPastInvoiceTotalRecords(state, action) {
      state.pastInvoicetotalRecords = action.payload;
    },

    setPastInvoiceCurrentPage(state, action) {
      state.pastInvoiceCurrentPage = action.payload;
    },

    setEquipmentPastInvoiceDetail(state, action) {
      state.pastInvoiceDetail = action.payload;
    },

    setEquipmentsApiUrl(state, action) {
      state.equipmentsApiUrl = action.payload;
    },

    setEquipmentsCurrentPage(state, action) {
      state.equipmentsCurrentPage = action.payload;
    },
    setEquipmentOrderQuery(state, action) {
      state.equipmentOrderQuery = action.payload;
    },

    setEquipmentsList(state, action) {
      state.equipments = action.payload.equipment_to_bill_list;
      state.equipmentsPageNumbers = action.payload.equipment_to_bill_pages;
      state.total_equipment_records =
        action.payload.equipment_to_bill_total_records;
    },
  },
});

export const equipmentsActions = EquipmentSlice.actions;

export default EquipmentSlice.reducer;
