import { createSlice } from "@reduxjs/toolkit";

const initialCommunityState = {
  communities: [],
  communitypage: 1,
  communityTotalRecords: 1,
  pagehit: "",
  idToBeDeleted: "",
  idToBeDisabled: "",
  idToBeEnabled: "",
  idToBeEdited: "",
  adminId: null,
  usersList: [],
  iphonesList: [],
  iphonesPage: 1,
  userPage: 1,
  communityInfo: {},
  communityUserRoles: [],
  isCatchuAdmin: "",
  corporates: [],
  actionType: "",
  PageNumbers: 1,
  billeeList: [],
  patientList: [],
  BilleePage: 1,
  PatientPage: 1,
  patientTotalRecords: 0,
  userTotalRecords: 0,
  iPhoneTotalRecords: 0,
  community_admins: [],
  equipment_list: [],
  equipment_pages: 1,
  //Newly Added Store Properties
  activeGroupTests: [],
  activeGroupTestsPage: 1,
  activeGroupTestsTotalRecords: 0,

  groupTestHistory: [],

  groupTestHistoryPage: 1,
  groupTestHistoryTotalRecords: 0,

  communityEntitiesCurrentPage: 1,
  patientCommunityCurrentPage: 1,
  userCommunityCurrentPage: 1,
  billeeCommunityCurrentPage: 1,
  iphoneCommunityCurrentPage: 1,
  historyCommunityCurrentPage: 1,

  communityApiUrl: "",

  //End Newly Added Store Properties

  communityCurrentPage: 1,

  patientCommunityApiUrl: "",
  userCommunityApiUrl: "",
  iphoneCommunityApiUrl: "",
  billeeCommunityApiUrl: "",
  testCommunityApiUrl: "",

  patientCommunityOrderQuery: "",
  userCommunityOrderQuery: "",
  iphoneCommunityOrderQuery: "",
  billeeCommunityOrderQuery: "",
  devicesCommunityOrderQuery: "",
  testCommunityOrderQuery: "",

  //filters
  communityPatientDobFrom: [],
  communityPatientDobTo: [],

  communityUserFilteredRole: [],

  groupHistoryCreatedDateFrom: "",
  groupHistoryCreatedDateTo: "",
  groupHistoryExpiryDateFrom: "",
  groupHistoryExpiryDateTo: "",
  groupHistoryStatus: "",

  providerTotalRecords: 0,
  ProviderPage: 1,
  providerList: [],
  providerCommunityCurrentPage: 1,
  providerCommunityApiUrl: "",
  providerCommunityOrderQuery: "",
  isAddSuccess: false,
  devicesList: {},
  devicesCurrentPage: 1,
  devicesTotalPages: "",
  devicesTotalRecord: "",
  devicesApiURL: "",
};

const communitySlice = createSlice({
  name: "community",
  initialState: initialCommunityState,
  reducers: {
    setProviderTotalRecords(state, action) {
      state.providerTotalRecords = action.payload;
    },
    setProviderPage(state, action) {
      state.ProviderPage = action.payload;
    },
    setProviderList(state, action) {
      state.providerList = action.payload;
    },
    setProviderCommunityCurrentPage(state, action) {
      state.providerCommunityCurrentPage = action.payload;
    },
    setProviderCommunityApiUrl(state, action) {
      state.providerCommunityApiUrl = action.payload;
    },
    setProviderCommunityOrderQuery(state, action) {
      state.providerCommunityOrderQuery = action.payload;
    },
    //filters
    setGroupHistoryStatus(state, action) {
      state.groupHistoryStatus = action.payload;
    },
    setGroupHistoryCreatedDateFrom(state, action) {
      state.groupHistoryCreatedDateFrom = action.payload;
    },
    setGroupHistoryCreatedDateTo(state, action) {
      state.groupHistoryCreatedDateTo = action.payload;
    },
    setGroupHistoryExpiryDateFrom(state, action) {
      state.groupHistoryExpiryDateFrom = action.payload;
    },
    setGroupHistoryExpiryDateTo(state, action) {
      state.groupHistoryExpiryDateTo = action.payload;
    },

    setCommunityPatientDobFrom(state, action) {
      state.communityPatientDobFrom = action.payload;
    },
    setCommunityPatientDobTo(state, action) {
      state.communityPatientDobTo = action.payload;
    },
    setCommunityUserFilteredRole(state, action) {
      state.communityUserFilteredRole = action.payload;
    },
    //api urls
    setPatientCommunityApiUrl(state, action) {
      state.patientCommunityApiUrl = action.payload;
    },
    setUserCommunityApiUrl(state, action) {
      state.userCommunityApiUrl = action.payload;
    },
    setIphoneCommunityApiUrl(state, action) {
      state.iphoneCommunityApiUrl = action.payload;
    },
    setBilleeCommunityApiUrl(state, action) {
      state.billeeCommunityApiUrl = action.payload;
    },
    setTestCommunityApiUrl(state, action) {
      state.testCommunityApiUrl = action.payload;
    },

    //order queries
    setPatientCommunityOrderQuery(state, action) {
      state.patientCommunityOrderQuery = action.payload;
    },
    setUserCommunityOrderQuery(state, action) {
      state.userCommunityOrderQuery = action.payload;
    },
    setIphoneCommunityOrderQuery(state, action) {
      state.iphoneCommunityOrderQuery = action.payload;
    },
    setBilleeCommunityOrderQuery(state, action) {
      state.billeeCommunityOrderQuery = action.payload;
    },
    setTestCommunityOrderQuery(state, action) {
      state.testCommunityOrderQuery = action.payload;
    },
    setDevicesCommunityOrderQuery(state, action) {
      state.devicesCommunityOrderQuery = action.payload;
    },

    setCommunityApiUrl(state, action) {
      state.communityApiUrl = action.payload;
    },

    setCommunityPageNumber(state, action) {
      state.communityCurrentPage = action.payload;
    },
    setCommunityEntitiesPageNumber(state, action) {
      state.communityEntitiesCurrentPage = action.payload;
    },
    setUserCommunityCurrentPage(state, action) {
      state.userCommunityCurrentPage = action.payload;
    },
    setPatientCommunityCurrentPage(state, action) {
      state.patientCommunityCurrentPage = action.payload;
    },
    setBilleeCommunityCurrentPage(state, action) {
      state.billeeCommunityCurrentPage = action.payload;
    },
    setIphoneCommunityCurrentPage(state, action) {
      state.iphoneCommunityCurrentPage = action.payload;
    },
    setHistoryCommunityCurrentPage(state, action) {
      state.historyCommunityCurrentPage = action.payload;
    },

    addCommunity(state, action) {
      state.communities = action.payload;
    },

    addCummunityActiveGroupTests(state, action) {
      state.activeGroupTests = action.payload;
    },

    addCummunityActiveGroupTestsPage(state, action) {
      state.activeGroupTestsPage = action.payload;
    },
    addCummunityActiveGroupTestsTotalRecords(state, action) {
      state.activeGroupTestsTotalRecords = action.payload;
    },

    addCummunityGroupTestHistory(state, action) {
      state.groupTestHistory = action.payload;
    },

    addCummunityGroupTestHistoryPage(state, action) {
      state.groupTestHistoryPage = action.payload;
    },

    addCummunityGroupTestHistoryTotalRecords(state, action) {
      state.groupTestHistoryTotalRecords = action.payload;
    },

    addCmmunityPage(state, action) {
      state.communitypage = action.payload;
    },
    setPage(state, action) {
      state.pagehit = action.payload;
    },
    updateCommunity(state, payload) {
      const index = state.communities.findIndex(
        (community) => community.id === payload.id
      );
      state.communities[index] = payload;
    },
    deleteCommunity(state, action) {},
    disableCommunity(state, action) {},
    importCommunity(state, action) {
      state.communities = action.payload;
    },
    setIdToBeDeleted(state, action) {
      state.idToBeDeleted = action.payload;
    },
    setIphonesPage(state, { payload }) {
      state.iphonesPage = payload;
    },
    setUsersPage(state, { payload }) {
      state.userPage = payload;
    },
    setCommunities(state, { payload }) {
      state.communities = payload;
    },
    setUsersList(state, action) {
      state.usersList = action.payload;
    },
    setIphonesList(state, action) {
      state.iphonesList = action.payload;
    },
    setCommunityInfo(state, action) {
      state.communityInfo = action.payload;
    },
    setIsCatchuAdmin(state, action) {
      state.isCatchuAdmin = action.payload;
    },
    setCorporates(state, action) {
      state.corporates = action.payload;
    },
    setActionType(state, action) {
      state.actionType = action.payload;
    },
    setPageNumber(state, action) {
      state.PageNumbers = action.payload;
    },
    setIdToBeEnabled(state, action) {
      state.idToBeEnabled = action.payload;
    },
    setIdToBeDisabled(state, action) {
      state.idToBeDisabled = action.payload;
    },
    setIdToBeEdited(state, action) {
      state.idToBeEdited = action.payload;
    },
    setBilleeList(state, action) {
      state.billeeList = action.payload;
    },
    setPatientList(state, action) {
      state.patientList = action.payload;
    },
    setPatientPage(state, action) {
      state.PatientPage = action.payload;
    },
    setBilleePage(state, action) {
      state.BilleePage = action.payload;
    },
    addCommunityUserRoles(state, action) {
      state.communityUserRoles = action.payload;
    },
    setCommunityTotalRecords(state, action) {
      state.communityTotalRecords = action.payload;
    },
    setPatientTotalRecords(state, action) {
      state.patientTotalRecords = action.payload;
    },
    setUserTotalRecords(state, action) {
      state.userTotalRecords = action.payload;
    },
    setiPhoneTotalRecords(state, action) {
      state.iPhoneTotalRecords = action.payload;
    },
    setBilleeTotalRecords(state, action) {
      state.billeeTotalRecords = action.payload;
    },
    setCommunityAdmins(state, action) {
      state.community_admins = action.payload;
    },
    setDevicesCurrentPage(state, action) {
      state.devicesCurrentPage = action.payload;
    },

    setDeviceData(state, action) {
      state.devicesTotalPages = action.payload.equipment_pages;
      state.devicesTotalRecord = action.payload.total_records;
      state.devicesList = action.payload.equipment_list;
    },
    setAddEquipmentSucess(state, action) {
      state.isAddSuccess = action.payload;
    },
    setDevicesApiURL(state, action) {
      state.devicesApiURL = action.payload;
    },
  },
});

export const communityActions = communitySlice.actions;

export default communitySlice.reducer;
