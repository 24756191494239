import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useSelector } from "react-redux";
import Loader2 from "../loader2/loader2";
import axios from "axios";
import Moment from "moment";
import { dateParse, getWithExpiry } from "../../utils/helper";
import DateTime from "../../../components/DateTimePicker/DateTimePicker";
import CloseModal from "../../../assets/close-outline.svg";
import Calendar from "../../../assets/calendar.svg";
import TestLight from "../../../assets/test-light.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./CreateTestModal.scss";

const EditCreateModel = (props) => {
  const {
    isShowModal,
    hideModal,
    type,
    providerList,
    //billeeList,
    setDataFlag,
    setShowToast,
    testData,
  } = props;
  const Patent_id = useSelector((state) => state.patient.patientsID);

  const [startDate, setStartDate] = useState(
    dateParse(testData?.date_scheduled)
  );
  const [initialDate, setInitialDate] = useState(
    dateParse(testData?.date_scheduled)
  );
  const testInfo = useSelector((state) => state.test.testInfo);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneNumValue, setPhoneNumValue] = useState("");

  const [providerVal, setProviderVal] = useState();
  const [billeeVal, setBilleeVal] = useState("");
  const [billeeList, setBilleeList] = useState(testData?.billees_list);
  const [Address2Handler, setAddress2Handler] = useState();
  const [changeHandler, stateChangeHandler] = useState();
  const [testRemoteHandle, setTestRemoteHandle] = useState(false);
  const [iphoneNumber, setIphoneNumber] = useState();
  const [scheduledError, setScheduledError] = useState("");
  const [iphoneNumError, setIphoneNumError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const IphoneListData = useSelector((state) => state.patient.iphoneList);
  const token = "Bearer " + getWithExpiry("expiry_token");

  const [editTest, setEditTest] = useState({
    id: testData?.id,
    providerId: testData?.provider_id,
    billeeId: testData?.billee_id,
    patientId: testData?.patient_id,
    iphoneId: testData?.iphone_id,
    iphonNumber: testData?.iphonNumber,
    remote: testData?.is_remote,
    dateScheduled: testData?.date_scheduled,
    location: testData?.test_location,
    email: testData?.email ? testData?.email : "",
    telephone: testData?.telephone ? testData?.telephone : "",
  });
  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const validatePhoneNumber = (e) => {
    const { value } = e.target;
    let char = value.substr(value.length - 1);
    if (
      (char.charCodeAt() != 8 &&
        char.charCodeAt() != 43 &&
        char.charCodeAt() != 0 &&
        char.charCodeAt() < 48) ||
      char.charCodeAt() > 57
    )
      return;
    setPhoneNumValue(value);
  };



  const checkBoxHandle = () => {
    setTestRemoteHandle(!testRemoteHandle);
  };

  const handleProviderChange = (e) => {
    const { name, value } = e.target;
    //setBilleeList(null);
    setEditTest({ ...editTest, [name]: value });

    axios({
      url: `${process.env.REACT_APP_URL_PATH}/test/billee_list/${editTest?.patientId}/${value}/`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        setBilleeList(response.data.data.billees_list);
      })
      .catch((error) => {
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditTest({ ...editTest, [name]: value });
  };

  function diff_minutes(currentTime, selectedTime) {
    var diff = (selectedTime.getTime() - currentTime.getTime()) / 1000;
    diff /= 60;
    return Math.round(diff);
  }
  function ISOtoLongDate(isoString, locale = "en-US") {
    const options = { month: "numeric", day: "numeric", year: "numeric" };
    const date = new Date(isoString);
    const longDate = new Intl.DateTimeFormat(locale, options).format(date);
    return longDate;
  }
  const createTestSubmissionHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    setDisabledBtn(true);

    setScheduledError("");
    setIphoneNumError("");
    setEmailError("");
    setPhoneError("");

    const form = document.querySelector(".add-corporate-form");

    const fd = new FormData(form);

    if (!startDate || diff_minutes(new Date(), startDate) > 0) {
      let dateTime;

      if (startDate) {
        let newDate = ISOtoLongDate(startDate.toISOString(), "en-GB");
        dateTime =
          Moment(newDate, "DD/MM/YYYY").format("YYYY-MM-DD") +
          " " +
          new Intl.DateTimeFormat("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(startDate);

      }

      fd.append("providerId", editTest?.providerId);
      fd.append("billeeId", editTest?.billeeId);
      fd.append("dateScheduled", dateTime);

      fd.append("patientId", editTest?.patientId);
      if (editTest?.remote) {
        fd.append("remote", "1");

        // let phoneVal = editTest?.iphonNumber.replace(/\s+/g, "");
        // fd.append("iphonNumber", phoneVal);

        if (editTest?.iphonNumber && !editTest?.iphonNumber.includes("+", 0)) {
          fd.append("iphonNumber", "+" + editTest?.iphonNumber);
        } else if (editTest?.iphonNumber) {
          fd.append("iphonNumber", editTest?.iphonNumber);
        }

        fd.append("location", "Remote");
      } else {
        fd.append("iphoneId", editTest?.iphoneId);

        fd.append("location", editTest?.location);
      }

      if (editTest?.telephone && !editTest?.telephone.includes("+", 0)) {
        fd.append("telephone", "+" + editTest?.telephone);
      } else if (editTest?.iphonNumber) {
        fd.append("telephone", editTest?.telephone);
      }

      fd.append("email", editTest?.email);

      axios({
        url: `${process.env.REACT_APP_URL_PATH}/test/edit_test/${editTest?.id}`,
        method: "post",
        data: fd,
        headers: {
          "Content-type": "application/json",
          Authorization: token,
        },
      })
        .then((response) => {
          setLoading(false);
          hideModal();
          notifySuccess(response.data.message);
          setShowToast(true);
          setDataFlag((prevState) => !prevState);
        })
        .catch((error) => {
          setLoading(false);
          setDisabledBtn(false);

          if (error.response.data.message === "Field Errors") {
            if ("dateScheduled" in error.response.data.data) {
              setScheduledError(error.response.data.data.dateScheduled);
            } else if ("iphonNumber" in error.response.data.data) {
              setIphoneNumError(error.response.data.data.iphonNumber);
            } else if ("email" in error.response.data.data) {
              setEmailError(error.response.data.data.email);
            } else if ("telephone" in error.response.data.data) {
              setPhoneError(error.response.data.data.telephone);
            } else if ("phone_form" in error.response.data.data) {
              setIphoneNumError(
                error.response.data.data["phone_form"]["number"]
              );
            }
          } else {
            notifyError(error.response.data.message);
          }
          setDisabledBtn(false);
        });
    } else {
      setScheduledError("Selected DateTime should be more than current time");
      setLoading(false);
      setDisabledBtn(false);
    }
  };


  return (
    <div className="action-modal-wrapper">
      <Modal
        className="comunitie-modal modal-loader"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header className="comubitie-modal-header" id="rm-padding">
          <h1 className="modal-header-title">Edit Test</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="close-modal" />
          </span>
        </Modal.Header>
        <Modal.Body className="comunitie-modal-body">
          <form
           
            method="post"
            className="add-corporate-form"
            onSubmit={createTestSubmissionHandler}
          >
            {type === "Test" && (
              <div className="comunties-fields community-pt-header">
                <div className="cdh-first-top-row community-first-row">
                  <div className="cdh-left">
                    <h1 className="cdh-heading">Patient Details</h1>
                  </div>
                </div>
                <div className="cdh-second-row-content">
                  <ul className="cdh-details">
                    <li className="cdh-single-detail cdh-class-second">
                      <h3 className="cdh-s-heading">Jane Botosh</h3>
                    </li>
                    <li className="cdh-single-detail cdh-class-second">
                      <div className="cdh-inner-single">
                        <img className="detail-img" src={Calendar} alt="" />
                        <p className="cdh-inner-des">12/02/1994</p>
                      </div>
                    </li>
                    <li className="cdh-single-detail cdh-class-second">
                      <div className="cdh-inner-single">
                        <img className="detail-img" src={TestLight} alt="" />
                        <p className="cdh-inner-des">12/02/1994</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-lg-6 left-col">
                <div className="field-wrapper">
                  <label htmlFor="provider" className="label">
                    Provider *
                  </label>
                  <select
                    id="providerId"
                    name="providerId"
                    className="custom-select"
                    //onChange={handleChange}
                    onChange={handleProviderChange}
                    value={editTest?.providerId}
                  >
                    {providerList.map((record) => (
                      <option key={record.id} id={record.id} value={record.id}>
                        {record.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-6 right-col">
                <div className="field-wrapper">
                  <label htmlFor="billee" className="label">
                    Billee *
                  </label>
                  <select
                    id="billeeId"
                    name="billeeId"
                    className="custom-select"
                    onChange={handleChange}
                    value={editTest?.billeeId}
                  >
                    {billeeList &&
                      billeeList.map((record) => (
                        <option
                          key={record.billee_id}
                          id={record.billee_id}
                          value={record.billee_id}
                        >
                          {record.billee_name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 right-col">
                <div className="field-wrapper">
                  <label htmlFor="scheduled" className="label">
                    Scheduled *
                  </label>
                  <DateTime
                    startDate={startDate}
                    setStartDate={setStartDate}
                    //maxDate={new Date()}
                  />
                  {scheduledError && (
                    <p className="error-text">{scheduledError}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-6 right-col">
                <div className="field-wrapper">
                  <label htmlFor="location" className="label">
                    Location
                  </label>
                  {editTest?.remote ? (
                    <input
                      type="text"
                      name="location"
                      id="location"
                      className="custom-input"
                      placeholder="Location"
                      value={"Remote"}
                      // onChange={handleChange}
                      required
                    />
                  ) : (
                    <input
                      type="text"
                      name="location"
                      id="location"
                      className="custom-input"
                      placeholder="Location"
                      value={editTest?.location}
                      onChange={handleChange}
                      required
                    />
                  )}
                </div>
              </div>
              {/* {!editTest?.remote && (
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="location" className="label">
                      Location
                    </label>
                    <input
                      type="text"
                      name="location"
                      id="location"
                      className="custom-input"
                      placeholder="Location"
                      value={editTest?.location}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              )} */}
            </div>
            <div className="row">
              <div className="col-12">
                <input
                  type="checkbox"
                  className="float-left"
                  id="remote12"
                  name="remote12"
                  value=""
                  checked={editTest?.remote}
                  onChange={() => {
                    setEditTest({
                      ...editTest,
                      remote: !editTest.remote,
                      iphonNumber: "",
                    });
                  }}
                />
                <label className="label mx-2  " htmlFor="remote12">
                  Remote
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 left-col">
                <div className="field-wrapper">
                  <label htmlFor="iphone_number" className="label">
                    iPhone Number *
                  </label>
                  {editTest?.remote ? (
                    <>
                      {/* <input
                        type="text"
                        id="iphonNumber"
                        name="iphonNumber"
                        className="custom-input"
                        placeholder="iPhone Number"
                        value={editTest?.iphonNumber}
                        onChange={(e) => {
                          const { name, value } = e.target;

                          const regex = /^([\s+0-9])+$/i;
                          if (value === "") {
                            // case to remove whole highlighted text
                            setEditTest({ ...editTest, [name]: value });
                            return;
                          }
                          if (regex.test(value) === false) return;

                          if (value.length === 1 && value === "+") {
                            // case to remove + sign when last number is removed
                            setEditTest({ ...editTest, [name]: "" });
                          } else if (value.length >= 1 && value[0] !== "+") {
                            // case to append + sign with first number
                            setEditTest({ ...editTest, [name]: "+" + value });
                          } else {
                            // case to append other numbers
                            setEditTest({ ...editTest, [name]: value });
                          }
                        }}
                        required
                      /> */}
                      <PhoneInput
                        country={"us"}
                        value={editTest?.iphonNumber}
                        onChange={(val) => {
                          setEditTest({ ...editTest, iphonNumber: val });
                        }}
                        inputProps={{
                          required: true,
                        }}
                      />
                      {iphoneNumError && (
                        <p className="error-text">{iphoneNumError}</p>
                      )}
                    </>
                  ) : (
                    <>
                      <select
                        id="iphoneId"
                        name="iphoneId"
                        className="custom-select"
                        onChange={handleChange}
                        value={editTest?.iphoneId}
                      >
                        {IphoneListData.map((record) => (
                          <option value={record.id}>{record.number}</option>
                        ))}
                      </select>
                      {/* {iphoneIDError && (
                        <p className="error-text">{iphoneIDError}</p>
                      )} */}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="addminstraion-fields">
              <div className="addminstraion-header">
                <h2 className="adsd-heading">Patient Information</h2>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="email" className="label">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="custom-input"
                      placeholder="Email"
                      value={editTest?.email}
                      onChange={handleChange}
                      required
                    />
                    {emailError && <p className="error-text">{emailError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="telephone" className="label">
                      Telephone
                    </label>
                    {/* <input
                      type="text"
                      name="telephone"
                      id="telephone"
                      className="custom-input"
                      placeholder="Telephone"
                      value={editTest?.telephone}
                      onChange={(e) => {
                        const { name, value } = e.target;

                        const regex = /^([\s+0-9])+$/i;
                        if (value === "") {
                          // case to remove whole highlighted text
                          setEditTest({ ...editTest, [name]: value });
                          return;
                        }
                        if (regex.test(value) === false) return;

                        if (value.length === 1 && value === "+") {
                          // case to remove + sign when last number is removed
                          setEditTest({ ...editTest, [name]: "" });
                        } else if (value.length >= 1 && value[0] !== "+") {
                          // case to append + sign with first number
                          setEditTest({ ...editTest, [name]: "+" + value });
                        } else {
                          // case to append other numbers
                          setEditTest({ ...editTest, [name]: value });
                        }
                      }}
                      required
                    /> */}
                    <PhoneInput
                      country={"us"}
                      value={editTest?.telephone}
                      onChange={(val) => {
                        setEditTest({ ...editTest, telephone: val });
                      }}
                      inputProps={{
                        required: true,
                      }}
                    />
                    {phoneError && <p className="error-text">{phoneError}</p>}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            id="close-btn"
            className="btn btn-secondary-fill btn-primary-16"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            // type="submit"
            onClick={createTestSubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={disabledBtn}
          >
            Update
          </button>
          {/* <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          /> */}
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
};

export default EditCreateModel;
