import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import { userActions } from "../../../store/user";
import { authActions } from "../../../store/auth";
import { getWithExpiry, setWithExpiry } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import "./TermsModal.scss";
import axios from "axios";
// imports image
import Loader2 from "../../UI/loader2/loader2";
import CloseModal from "../../../assets/close-outline.svg";

function TermsServiesLoggedModal(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    isShowModal,
    hideModal,
    document,
    email,
    emailOtp,
    otpCheck,
    setModalState,
    logoutHandler,
  } = props;
  const rememberMe = useSelector((state) => state.auth.rememberMe);
  const token = getWithExpiry("expiry_token");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const acceptTerms = () => {
    setBtnDisabled(true);
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/user/accept_terms_conditions_logged_in/`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        setModalState(false);
        hideModal();
      })
      .catch((error) => {
        setBtnDisabled(false);
        setLoading(false);
        hideModal();
      });
  };

  const rejectTerms = () => {
    setBtnDisabled(true);
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/user/reject_terms_conditions_logged_in/`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        logoutHandler();
        notifyError(res.data.message);
      })
      .catch((error) => {
        setBtnDisabled(false);
        setLoading(false);
        hideModal();
        history.replace("/login");
      });
  };
  return (
    <div className="test-modal-wrapper">
      <Modal
        className="test-modal terms-condition"
        show={isShowModal}
        onHide={hideModal}
        backdrop="static"
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header className="audit-modal-header">
          <h1 className="modal-header-title">Terms and Conditions</h1>
          {/* <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span> */}
        </Modal.Header>
        <Modal.Body className="action-modal-body">
          {/* <h2 className="action-heading">
            Terms and Conditions
          </h2> */}
          <iframe
            title={"IFRAME"}
            style={{ width: "100%", height: "100vh" }}
            src={document}
          ></iframe>
        </Modal.Body>
        <Modal.Footer className="action-footer-modal">
          <button
            className="btn btn-primary-fill btn-14-24"
            onClick={acceptTerms}
          >
            Accept
          </button>
          <button
            className="btn btn-secondary-fill btn-14-24 text-capitalize"
            onClick={rejectTerms}
          >
            Decline
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

export default TermsServiesLoggedModal;
