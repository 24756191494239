import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getWithExpiry } from "../../../utils/helper";
import "./DeleteModal.scss";
import Loader2 from "../../../UI/loader2/loader2";
import axios from "axios";

// import images
import CloseModal from "../../../../assets/close-outline.svg";
import DisabledIcon from "../../../../assets/disabled-row.svg";
import { useHistory, useParams } from "react-router-dom";
import EnableIcon from "../../../../assets/checkicon.svg";
import DeleteRowIcon from "../../../../assets/delete-row.svg";

function DeleteProviderModal(props) {
  const parm = useParams();
  const history = useHistory();
  const {
    isShowModal,
    hideModal,
    type,
    data,
    setDataFlag,
    setShowToast,
    deletedId,
  } = props;
  const token = "Bearer " + getWithExpiry("expiry_token");
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [loading, setLoading] = useState(false);

  const idToBeDeleted1 = useSelector((state) => state.corporate.idToBeDeleted);
  const idToBeDeleted2 = useSelector((state) => state.adminuser.idToBeDeleted);
  const idToBeDeleted3 = useSelector((state) => state.reguser.idToBeDeleted);
  const idToBeDeleted4 = useSelector((state) => state.patient.idToBeDeleted);
  const idToBeDeleted5 = useSelector((state) => state.community.idToBeDeleted);

  const editProviderInfo = useSelector(
    (state) => state.provider.editProviderInfo
  );


  const notifySuccesss = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  let model = "",
    delID;


  if (type.toLowerCase() === "community") {
    model = "/community/delete_community/";
    delID = deletedId;
  } else if (type.toLowerCase() === "corporate") {
    model = "/corporate/delete_corporate/";
    delID = deletedId;
  } else if (type.toLowerCase() === "reguser") {
    model = "/user/delete_regular_user/";
    delID = deletedId;
  } else if (type.toLowerCase() === "adminuser") {
    model = "/user/delete_admin/";
    delID = deletedId;
  } else if (type.toLowerCase() === "user") {
    model = "/user/delete_user/";
    delID = deletedId;
  } else if (type.toLowerCase() === "patient") {
    model = "/user/delete_user/";
    delID = deletedId;
  } else if (type.toLowerCase() === "billee") {
    model = "/user/delete_user/";
    delID = deletedId;
  } else if (type.toLowerCase() === "provider") {
    model = "/user/delete_provider/";
    delID = deletedId;
  } else if (type.toLowerCase() === "iphone") {
    model = "/test/delete_iphone/";
    delID = deletedId;
  } else if (type.toLowerCase() === "history") {
    model = "/community/delete_group_test/";
    delID = deletedId;
  }
  const deleteCorporate = () => {
    setLoading(true);
    setDisabledBtn(true);
    const fd = new FormData();
    fd.append("community_id", editProviderInfo.community_id);
    fd.append("delete_id", deletedId);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}${model}${deletedId}`,
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();

        notifySuccesss(response.data.message);

        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      
      })
      .catch((error) => {
        setLoading(false);
        setDisabledBtn(false);
        if (error.response && error.response.data.message !== "Field Errors") {
          notifyError(error.response.data.message);
        }
      });
  };

  return (
    <div className="action-modal-wrapper">
      <Modal
        className="action-modal modal-loader"
        show={isShowModal}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="action-modal-header" id="disable-modal">
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="action-modal-body">
          <img src={DeleteRowIcon} alt="" />
          <h2 className="action-heading">Are you sure you want to delete?</h2>
        </Modal.Body>
        <Modal.Footer className="action-footer-modal" id="disable-modal-footer">
          <button
            className="btn btn-primary-fill btn-14-24"
            onClick={deleteCorporate}
            disabled={disabledBtn}
          >
            Yes, I’m Sure.
          </button>
          <button
            className="btn btn-primary-outline btn-14-24"
            onClick={hideModal}
          >
            Cancel Delete
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

export default DeleteProviderModal;
