import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sidebarActions } from "../../../store/sidebar";
import { Link, useLocation, useHistory } from "react-router-dom";
import "./Breadcrumb.scss";

function BreadCrumb() {
  const dispatch = useDispatch();
  const history = useHistory();
  const crumbs = useSelector((state) => state.sidebar.crumbs);

  const removeRecordName = (index) => {
    dispatch(sidebarActions.removeCrumbs(index));
  };

  return (
    <div className="breadcrumb-wrapper">
      <nav>
        <ol className="breadcrumb">
          {crumbs.map(({ name, path }, index) => {
            if (index + 1 === crumbs.length) {
              return (
                <li
                  key={index}
                  className="breadcrumb-item active"
                  aria-current="page"
                >
                  {name}
                </li>
              );
            } else {
              return (
                <li key={index} className="breadcrumb-item">
                  <h3
                    className="bi-link"
                    onClick={() => {
                      history.go((crumbs.length - 1 - index) * -1);
                    }}
                  >
                    {name}
                  </h3>
                  {/* <Link
                    to={`${path}`}
                    className="bi-link"
                    // onClick={() => removeRecordName(index)}
                  >
                    {name}
                  </Link> */}
                </li>
              );
            }
          })}
        </ol>
      </nav>
    </div>
  );
}

export default BreadCrumb;
