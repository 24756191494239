import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sidebarActions } from "../../../store/sidebar";
import Logo from "../../../assets/logo.png";
import Corporate from "../../../assets/corporator.svg";
import Communities from "../../../assets/communities.svg";
import Patient_one from "../../../assets/patient_last.png";
import Contact from "../../../assets/contact-us.png";

import Provider from "../../../assets/patients.svg";
import Tests from "../../../assets/test.svg";
import Users from "../../../assets/laptop_users.svg";
import Billing from "../../../assets/billing.svg";
import Equipment from "../../../assets/billing.svg";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { userActions } from "../../../store/user";
import { communityActions } from "../../../store/community";
import { corporateActions } from "../../../store/corporate";
import { patientActions } from "../../../store/patient";
import { providerActions } from "../../../store/provider";
import { AdminUserActions } from "../../../store/adminUser";
import { RegUserActions } from "../../../store/regUser";
import { testActions } from "../../../store/test";
import { billingActions } from "../../../store/billing";
import { rowOrderActions } from "../../../store/orders";
import { filterActions } from "../../../store/filter";
import { getWithExpiry } from "../../utils/helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Sidebar.scss";
import { externalProvidersActions } from "../../../store/externalProviders";

const E_MAILS_WITH_EXPORT_IMPORT_ACCESS = ["jeannette@catchu.net", "abdul.ghaffar@tkxel.io", "moiz.waqar@tkxel.io"];

const SidebarList = ({ selectedNav, setNav, name, icon, link }) => {
  return (
    <li className="single-nav">
      <NavLink
        to={{ pathname: link }}
        className={`s-nav-link ${selectedNav === name ? "active" : ""}`}
        onClick={(e) => setNav(e, name, link)}
      >
        <img className="sn-image" src={icon} alt="corporates-link" />
        {name}
      </NavLink>
    </li>
  );
};

function Sidebar(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedNav = useSelector((state) => state.sidebar.selectedNav);

  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);
  const loginEmail = useSelector((state) => state.auth.email);
  const userInfo = useSelector((state) => state.user.userInfo);
  const [userEmail, setUserEmail] = useState(loginEmail);
  
  const token = "Bearer " + getWithExpiry("expiry_token");

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: ".csv",
      onDrop: (acceptedFiles) => {
        bulkImport(acceptedFiles);
      },
    });

  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/user/logged_user_details`,
      method: "get",
      headers: {
        "Content-Type": "Application/json",
        Authorization: token,
      },
    })
      .then((response) => {
        setUserEmail(response.data.data.email);
      })
      .catch((error) => {});
  }, [userEmail]);

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));
  const redirectToHomePage = () => {
    let lcv = false;
    if (!isCatchUAdmin) {
      for (let key in defaultRouteMapping) {
        const roleArray = defaultRouteMapping[key].roles;
        const route = defaultRouteMapping[key].route;
        for (let i = 0; i < roleArray.length; i++) {
          if (userRole.includes(roleArray[i])) {
            history.replace(route);
            dispatch(sidebarActions.setSelectedNav(key));
            // dispatch(sidebarActions.resetPageCrumbs());
            lcv = true;
            break;
          }
        }
        if (lcv) break;
      }
    } else if (isCatchUAdmin) {
      for (let key in defaultRouteMapping) {
        const roleArray = defaultRouteMapping[key].roles;
        const permission = defaultRouteMapping[key].permission;
        const route = defaultRouteMapping[key].route;
        for (let i = 0; i < roleArray.length; i++) {
          if (userRole.includes(roleArray[i])) {
            history.replace(route);
            dispatch(sidebarActions.setSelectedNav(key));

            lcv = true;
            break;
          }
        }
        if (permissions.includes(permission)) {
          history.replace(route);
          dispatch(sidebarActions.setSelectedNav(key));

          lcv = true;
          break;
        }
      }
    }
  };

  const resetPageNumber = (newSelectedNav) => {
    if (newSelectedNav === "Corporate") {
      dispatch(corporateActions.setCorporatePageNumber(1));
      dispatch(corporateActions.setCommunityCorporateCurrentPage(1));
      dispatch(corporateActions.setUserCorporateCurrentPage(1));
      dispatch(corporateActions.setCorporateEntitiesPageNumber(1));
    } else if (newSelectedNav === "Community") {
      dispatch(communityActions.setCommunityPageNumber(1));
      dispatch(communityActions.setHistoryCommunityCurrentPage(1));
      dispatch(communityActions.setUserCommunityCurrentPage(1));
      dispatch(communityActions.setPatientCommunityCurrentPage(1));
      dispatch(communityActions.setBilleeCommunityCurrentPage(1));
      dispatch(communityActions.setIphoneCommunityCurrentPage(1));
    } else if (newSelectedNav === "Patients") {
      dispatch(patientActions.setPatientCurrentPage(1));
      dispatch(patientActions.setTestCurrentPage(1));
    } else if (newSelectedNav === "Tests") {
      dispatch(testActions.setScheduleTestCurrentPage(1));
      dispatch(testActions.setUpcomingTestCurrentPage(1));
      dispatch(testActions.setTestCurrentPage(1)); //useless?
    } else if (newSelectedNav === "Internal Providers") {
      dispatch(providerActions.setProviderCurrentPage(1));
    } else if (newSelectedNav === "Users") {
      dispatch(RegUserActions.setRegUserCurrentPage(1));
      dispatch(AdminUserActions.setAdminUserCurrentPage(1));
    } else if (newSelectedNav === "Billing") {
      dispatch(billingActions.setTestBillCurrentPage(1));
      dispatch(billingActions.setPastInvoiceCurrentPage(1));
      dispatch(billingActions.setCreditHistoryCurrentPage(1));
    }
  };

  const setNav = (e, newSelectedNav, link) => {
    dispatch(sidebarActions.setSelectedNav(newSelectedNav));
    dispatch(filterActions.reset());
    dispatch(rowOrderActions.reset());

    let Url;
    if (newSelectedNav === "Corporate") {
      Url = `${process.env.REACT_APP_URL_PATH}/corporate/corporates_list/`;
    } else if (newSelectedNav === "Community") {
      Url = `${process.env.REACT_APP_URL_PATH}/community/community_list/`;
    } else if (newSelectedNav === "Patients") {
      Url = `${process.env.REACT_APP_URL_PATH}/community/patient_list/`;
    } else if (newSelectedNav === "Internal Providers") {
      Url = `${process.env.REACT_APP_URL_PATH}/community/provider_list/`;
    }

    if (Url) {
      axios({
        method: "get",
        url: Url,
        headers: {
          Authorization: token,
        },
      })
        .then((response) => {
          resetPageNumber(newSelectedNav);
          if (newSelectedNav === "Corporate") {
            dispatch(
              corporateActions.addCorporates(response.data.data.corporates)
            );
            dispatch(
              corporateActions.addCorporatePage(
                response.data.data.corporate_pages
              )
            );
            dispatch(
              corporateActions.addCorporateTotalRecords(
                response.data.data.total_records
              )
            );
            dispatch(corporateActions.setCorporateApiUrl(""));
          } else if (newSelectedNav === "Community") {
            dispatch(
              communityActions.setCommunities(response.data.data.communities)
            );
            dispatch(
              communityActions.setPageNumber(
                response.data.data.communities_pages
              )
            );
            dispatch(
              communityActions.setCommunityTotalRecords(
                response.data.data.total_records
              )
            );

            dispatch(communityActions.setCommunityApiUrl(""));
          } else if (newSelectedNav === "Patients") {
            const Patients = response.data.data["patient_list"];
            const communities = response.data.data["communities"];

            dispatch(patientActions.addPatients(Patients));
            dispatch(
              patientActions.setPatientsPageNumber(
                response.data.data.patient_pages
              )
            );
            dispatch(
              patientActions.setPatientTotalRecords(
                response.data.data.total_records
              )
            );
            dispatch(patientActions.setCommunityList(communities));

            dispatch(patientActions.setPatientApiUrl(""));
          } else if (newSelectedNav === "Internal Providers") {
            dispatch(
              providerActions.setProviderData(response.data.data.provider_list)
            );
            dispatch(
              providerActions.setProviderCommunites(
                response.data.data.communities
              )
            );
            dispatch(
              providerActions.setProviderPageNumber(
                response.data.data.provider_pages
              )
            );
            dispatch(
              providerActions.setProviderTotalRecords(
                response.data.data.total_records
              )
            );
            dispatch(providerActions.setProviderApiUrl(""));
          }
        })
        .catch((error) => {});
    } else if (newSelectedNav === "Tests") {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_URL_PATH}/test/upcoming_tests/`,
        headers: {
          Authorization: token,
        },
      })
        .then((response) => {
          const resData = response.data.data.upcoming_test_list;
          dispatch(testActions.setUpcomingTestList(resData));

          dispatch(testActions.setTestList(response.data.data)); //wrong

          dispatch(
            testActions.setUpcomingTestPageNumbers(
              response.data.data.total_pages
            )
          );
          dispatch(
            testActions.setUpcomingTestTotalRecords(
              response.data.data.total_records
            )
          );

          dispatch(testActions.setUpcomingTestApiUrl(""));
        })
        .catch((error) => {});

      axios({
        method: "get",
        url: `${process.env.REACT_APP_URL_PATH}/test/tests_to_schedule/`,
        headers: {
          Authorization: token,
        },
      })
        .then((response) => {
          const resData = response.data.data;
          dispatch(testActions.setScheduleTestList(resData));
          dispatch(
            testActions.setScheduleTestPageNumbers(
              response.data.data.total_pages
            )
          );
          dispatch(
            testActions.setScheduleTestTotalRecords(
              response.data.data.total_records
            )
          );

          dispatch(testActions.setScheduleTestApiUrl(""));
        })
        .catch((error) => {});
    } else if (newSelectedNav === "Users") {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_URL_PATH}/user/admins_list/`,
        headers: {
          Authorization: token,
        },
      })
        .then((response) => {
          const AdminUsers = response.data.data["Admins"];
          dispatch(AdminUserActions.addAdminUsers(AdminUsers));
          dispatch(
            AdminUserActions.setAdminUsersPageNumber(
              response.data.data.admins_pages
            )
          );
          dispatch(
            AdminUserActions.setAdminsTotalRecords(
              response.data.data.total_records
            )
          );
          dispatch(AdminUserActions.setAdminUserApiUrl(""));
        })
        .catch((error) => {});

      axios({
        method: "get",
        url: `${process.env.REACT_APP_URL_PATH}/user/regular_user_list/`,
        headers: {
          Authorization: token,
        },
      })
        .then((response) => {
          const RegUsers = response.data.data;
          dispatch(RegUserActions.addRegUsers(RegUsers["Regular Users"]));
          dispatch(RegUserActions.setCommunityList(RegUsers["communities"]));
          dispatch(RegUserActions.setCorporateList(RegUsers["corporates"]));
          dispatch(
            RegUserActions.setCorporatePageNumber(
              response.data.data.regular_users_pages
            )
          );
          dispatch(
            RegUserActions.setRegularUsersTotalRecords(
              response.data.data.total_records
            )
          );
          dispatch(RegUserActions.setRegUserApiUrl(""));
        })
        .catch((error) => {});
    }
    else if(newSelectedNav==="External Providers"){
      axios({
        method: "get",
        url: `${process.env.REACT_APP_URL_PATH}/user/individualproviders_list/`,
        headers: {
          Authorization: token,
        },
      })
        .then((response) => {
          const resData = response.data.data.providers;
          dispatch(externalProvidersActions.setExternalProvidersList(resData));
          dispatch(
            externalProvidersActions.setExternalProvidersPageNumbers (
              response.data.data.provider_pages
            )
          );
          dispatch(
            externalProvidersActions.setExternalProvidersTotalRecords (
              response.data.data.total_records
            )
          );

          dispatch(externalProvidersActions.setExternalProvidersApiUrl (""));
        })
        .catch((error) => {});
    }
  };

  function checkPermission(userType) {
    if (userRole.includes("Super Admin")) {
      return true;
    } else {
      const roles = rolesMapping[userType];

      for (let i = 0; i < roles?.length; i++) {
        if (userRole.includes(roles[i])) {
          return true;
        }
      }
      return false;
    }
  }

  // catchu admin
  function checkAdminPermission(userType) {
    const userRolePermission = checkPermission(userType);
    if (!userRolePermission) {
      if (!permissions.includes(userType)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const bulkExport = () => {
    dispatch(userActions.setAppLoader(true));
    axios.post(`${process.env.REACT_APP_URL_PATH}/test/export_file/`, {}, {
      headers: {
        Authorization: `${token}`,
      }
    })
      .then((response) => {
        dispatch(userActions.setAppLoader(false));
        notifySuccess(response.data.message);
      })
      .catch((error) => {
        dispatch(userActions.setAppLoader(false));
        notifyError(error.response.data.message);
      });
  };
  const bulkImport = (file) => {
    const formDataFile = new FormData();
    formDataFile.append("raw_measurements_file", file[0]);
    if (file[0]) {
      notifySuccess("Your file is uploaded for processing");
      axios
        .post(
          `${process.env.REACT_APP_URL_PATH}/test/generate_bulk_report/`,
          formDataFile,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        )
        .then(({ data }) => {
          const fileData = data.data;

          notifySuccess("Raw measurements have been successfully processed");
        })
        .catch((error) => {
          if (error.response.data.message) {
            notifyError(error.response.data.message);
          } else {
            notifyError("Something went wrong. Please try again");
            setTimeout(() => {
              window.location.reload(true);
            }, 3000);
          }
        });
    } else {
    }
  };
  return (
    <div className="lf-warpper">
      <div className="barnd-log" onClick={redirectToHomePage}>
        <img src={Logo} alt="" />
      </div>
      <div className="seprater">
        <ul className="nav-list">
          {navItems.map((value, index) => {
            if (!isCatchUAdmin) {
              if (checkPermission(value.name)) {
                return (
                  <SidebarList
                    key={index}
                    name={value.name}
                    selectedNav={selectedNav}
                    setNav={setNav}
                    icon={value.icon}
                    link={value.link}
                  />
                );
              }
            } else if (isCatchUAdmin) {
              if (checkAdminPermission(value.name)) {
                return (
                  <SidebarList
                    key={index}
                    name={value.name}
                    selectedNav={selectedNav}
                    setNav={setNav}
                    icon={value.icon}
                    link={value.link}
                  />
                );
              }
            }
          })}
          { E_MAILS_WITH_EXPORT_IMPORT_ACCESS.includes(userEmail) && (
            <li className="single-nav">
              <div {...getRootProps({ className: "fileDropBox" })}>
                <h2
                  //to={{ pathname: "/" }}
                  className={`s-nav-link ${
                    selectedNav === "Bulk Import" ? "active" : ""
                  }`}
                  onClick={bulkImport}
                >
                  <input
                    type="file"
                    className="file-upload"
                    {...getInputProps()}
                    multiple={false}
                  />
                  {"Bulk Import"}
                </h2>
              </div>
            </li>
          )}
          { E_MAILS_WITH_EXPORT_IMPORT_ACCESS.includes(userEmail) && (
            <li className="single-nav">
              <div>
                <h2
                  //to={{ pathname: "/" }}
                  className={`s-nav-link ${
                    selectedNav === "Bulk Export" ? "active" : ""
                  }`}
                  onClick={bulkExport}
                >
                  {"Bulk Export"}
                </h2>
              </div>
            </li>
          )}
        </ul>
        {/* <NavLink to={{ pathname: "/contact-us" }} className="contact-us-link">
          <img src={Contact} /> Contact Us
        </NavLink> */}
      </div>
    </div>
  );
}

export default Sidebar;

const navItems = [
  { name: "Corporate", icon: Corporate, link: "/corporates" },
  { name: "Community", icon: Communities, link: "/communities" },
  { name: "Internal Providers", icon: Provider, link: "/providers" },
  { name: "External Providers", icon: Provider, link: "/external-providers" },
  { name: "Patients", icon: Patient_one, link: "/patients" },
  { name: "Users", icon: Users, link: "/users" },
  { name: "Tests", icon: Tests, link: "/tests" },
  { name: "Billing", icon: Billing, link: "/billing" },
  { name: "Billing Equipments", icon: Equipment, link: "/equipments" },
];
const defaultRouteMapping = {
  Corporate: {
    roles: ["Super Admin", "Corporate HQ Administrator"],
    permission: "Corporate",
    route: "/corporates",
  },
  Community: {
    roles: [
      "Community Admin",
      "Individual Practice Admin",
      "Support Staff",
      "Provider",
    ],
    permission: "Community",
    route: "/communities",
  },
  Patients: {
    roles: [],
    permission: "Patients",
    route: "/patients",
  },
  // Tests: {
  //   roles: [],
  //   permission: "Tests",
  //   route: "/tests",
  // },
  'Internal Providers': {
    roles: [],
    permission: "Internal Providers",
    route: "/providers",
  },
  Users: {
    roles: [],
    permission: "Users",
    route: "/users",
  },
  Billing: {
    roles: ["Billee"],
    permission: "Billing",
    route: "/billing",
  },
  Equipments: {
    roles: [],
    permission: "Equipments",
    route: "/equipments",
  },
  "External Providers": {
    roles: [],
    permission: "External Providers",
    route: "/external-providers",
  },
};
const rolesMapping = {
  Corporate: ["Super Admin", "Corporate HQ Administrator"],
  Community: [
    "Super Admin",
    "Corporate HQ Administrator",
    "Community Admin",
    "Individual Practice Admin",
    "Support Staff",
    "Provider",
  ],
  Patients: [
    "Super Admin",
    "Corporate HQ Administrator",
    "Support Staff",
    "Community Admin",
    "Individual Practice Admin",
    "Provider",
  ],
  Tests: [
    "Super Admin",
    "Support Staff",
    "Provider",
    "Community Admin",
    "Individual Practice Admin",
  ],
  'Internal Providers': [
    "Super Admin",
    "Community Admin",
    "Individual Practice Admin",
    "Corporate HQ Administrator",
    "Support Staff",
  ],
  Users: [
    "Super Admin",
    "Corporate HQ Administrator",
    "Community Admin",
    "Individual Practice Admin",
  ],
  Billing: [
    "Super Admin",
    "Community Admin",
    "Individual Practice Admin",
    "Billee",
  ],
  // please check roles
  Equipments: ["Super Admin", "CatchU Admin"],
  "External Providers" : ["Super Admin", "CatchU Admin"]

};
