import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import auth, { authActions } from "../../../store/auth";
import { userActions } from "../../../store/user";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Login.scss";
import { getOTPWithExpiry, setWithExpiry } from "../../utils/helper";
import useInput from "../../../hooks/use-input";

// import images
import BrandLogo from "../../../assets/logo.png";
import ArrowRight from "../../../assets/arrow-right.svg";
import TermsServiesModal from "../OTPVerification/TermsServicesModal";

const isNotEmpty = (value) => value.trim() !== "";
const isEmailValid = (value) => value.includes("@");
const isPasswordValid = (value) => {
  const passwordRegex = /^[a-zA-Z0-9]+$/;
  return value.trim().length >= 8 && passwordRegex.test(value);
};

function Login() {
  const [rememberMeState, setRememberMe] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const showTermsModal = () => setTermsModalOpen(true);
  const hideTermsModal = () => setTermsModalOpen(false);
  const [isPasswordShow, setIsPasswordShow] = useState(false);

  const [iframeDoc, setIframeDoc] = useState("");
  let isSignup = useSelector((state) => state.auth.isSignup);
  const rememberMe = useSelector((state) => state.auth.rememberMe);
  const successMessage = useSelector((state) => state.auth.successMessage);
  const hasChangePassword = useSelector(
    (state) => state.auth.hasChangePassword
  );

  useEffect(() => {
    if (isSignup) {
      notifySuccess(successMessage);
      dispatch(authActions.resetSignup(false));
    }
  }, []);

  useEffect(() => {
    if (hasChangePassword) {
      notifySuccess(successMessage);
      dispatch(authActions.resetPasswordChangeStatus(false));
    }
  }, []);

  useEffect(() => {
    dispatch(authActions.setPathCheck(false));
  }, []);

  const changeRememberMeHandler = (e) => {
    setRememberMe(e.target.checked);
  };

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

 

  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  const getResponse = (response, res) => {
    const token = response.data.data.token;
    const isSuperAdmin = response.data.data.is_super_admin;
    // const successMessage = response.data.message;
    const successMessage = "Login Successful";
    dispatch(authActions.verifyOtp(token)); // dispatch token to auth store
    dispatch(authActions.verifyOtpSuccessMsg(successMessage)); // store login success message

    localStorage.setItem("an", isSuperAdmin);
    if (rememberMeState === true) {
      setWithExpiry("expiry_token", token, 86400000);
    } else {
      setWithExpiry("expiry_token", token, 7200000);
    }
  
    if (res.data.data.is_enabled) {
      dispatch(authActions.setIsLoggedIn(true));
    } else {
      notifyError("Cannot Login. User is Disabled");
      localStorage.removeItem("expiry_token");
      sessionStorage.clear();
    }


    history.replace("/login");
  
  };
  const loginHandler = async (event) => {
    event.preventDefault();
    setDisabledBtn(true);
    let verify_otp;
    const otpFlag = getOTPWithExpiry("otp_expiry_" + emailValue);
    if (otpFlag) {
      verify_otp = "no";
    } else {
      verify_otp = "yes";
    }
    setEmailError("");
    setPasswordError("");

    // create form data
    const form = document.querySelector(".login-form");
    const fd = new FormData(form);
    // const password = fd.get("password");
    fd.append("verify_otp", verify_otp);

    //make an http call to log user
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/user/login/`,
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        setDisabledBtn(false);
        dispatch(authActions.setEmail(fd.get("email")));
        if (verify_otp === "no") {
          if (response.data.data.accepted) {
            const token = response.data.data.token;
            //
            axios({
              url: `${process.env.REACT_APP_URL_PATH}/user/permissions/`,
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
              .then((response) => {
                const permissions = response.data.data.permissions;
                const catchu_admin = response.data.data.catchu_admin;
                dispatch(userActions.addPermissions(permissions));
                dispatch(userActions.setIsCatchuAdmin(catchu_admin));
              })
              .catch((error) => {
                if (error.response) {
                  setDisabledBtn(false);

                  notifyError(error.response.data.message);
                }
              });

            //
            axios({
              url: `${process.env.REACT_APP_URL_PATH}/user/roles/`,
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
              .then((res) => {
                const resData = res.data.data.roles;
                dispatch(userActions.setRole(resData));

                getResponse(response, res);
              })
              .catch((error) => {
                if (error.response) {
                  setDisabledBtn(false);

                  notifyError(error.response.data.message);
                }
              });
          } else {
            setIframeDoc(response.data.data.iframe_url);
            showTermsModal();
          }
        } else {
          dispatch(
            authActions.login({
              successMessage: response.data.message,
              email: emailValue,
            })
          );
          dispatch(authActions.setRememberMe(rememberMeState));
          dispatch(authActions.setPathCheck(true));
          history.replace({
            pathname: "/verify-otp",
            state: response.data.data.otp_check,
          });
        }
      })
      .catch((error) => {
        setDisabledBtn(false);
        if (error.response) {
          if (error.response.data.message === "Field Errors") {
            if (
              error.response.data.data["user_form"] &&
              "email" in error.response.data.data["user_form"]
            ) {
              setEmailError(error.response.data.data["user_form"]["email"][0]);
            }
            if (
              error.response.data.data["user_form"] &&
              "password" in error.response.data.data["user_form"]
            ) {
              setPasswordError(
                error.response.data.data["user_form"]["password"][0]
              );
            }
            if (error.response.data.data && error.response.data.data.password) {
              setPasswordError(error.response.data.data.password[0]);
            }
          } else {
            notifyError(error.response.data.message);
          }
        }
      });
  };

  const loginBtnClass = disabledBtn ? "disabled" : "";

  return (
    <Fragment>
      {termsModalOpen && (
        <TermsServiesModal
          isShowModal={termsModalOpen}
          hideModal={hideTermsModal}
          document={iframeDoc}
          email={emailValue}
          password={passwordValue}
          type={"login"}
        />
      )}
      <div className="login-screen-wrapper">
        <div className="login-box">
          <div className="login-logo">
            <img src={BrandLogo} alt="" />
          </div>
          <div className="login-header">
            <h1 className="login-heading">Login</h1>
            <p className="login-des">Enter your credentials to login</p>
          </div>
          <form
           
            method="post"
            className="login-form"
            onSubmit={loginHandler}
          >
            <div className="login-field">
              <label htmlFor="email" className="login-label">
                Email Address *
              </label>
              <input
                id="email"
                type="email"
                name="email"
                className="custom-input"
                placeholder="Email Address"
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value)}
                required
              />
              {emailError && <p className="error-text">{emailError}</p>}
            </div>
            <div className="login-field">
              <label htmlFor="password" className="login-label">
                Password *
              </label>
              <input
                id="password"
                type={`${isPasswordShow ? 'text' : 'password'}`}
                name="password"
                className="custom-input"
                placeholder="Password"
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                required
              />
              <div class="field-icon" onClick={() => setIsPasswordShow(prev => !prev)}>
                <i class={`fa ${isPasswordShow ? 'fa-eye' : 'fa-eye-slash'}`} aria-hidden="true"></i>
            </div>
              {passwordError && <p className="error-text">{passwordError}</p>}
            </div>
            {error && (
              <p className="error-text" style={{ paddingBottom: "10px" }}>
                {"Invalid credentials"}
              </p>
            )}
            <ul className="check-forget-password">
              <li className="custom-check-orange">
                <input
                  className="styled-checkbox"
                  id="styled-checkbox-1"
                  type="checkbox"
                  value="remember-me"
                  onChange={changeRememberMeHandler}
                />
                <label htmlFor="styled-checkbox-1">Remember Me</label>
              </li>
              <li className="password-forget">
                <Link to="/forgot-password">
                  <p>Forgot Password?</p>
                </Link>
              </li>
            </ul>
            <div className="login-action">
              <button
                className={`btn btn-primary-fill btn-14-24 ${loginBtnClass}`}
                type="submit"
                disabled={disabledBtn}
              >
                Login
                <img
                  className="arrow-right"
                  src={ArrowRight}
                  alt="login-button"
                />
              </button>
              <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Fragment>
  );
}

export default Login;
