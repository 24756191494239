import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import useInput from "../../../../hooks/use-input";
import Input from "react-phone-number-input/input";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader2 from "../../../UI/loader2/loader2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getWithExpiry } from "../../../utils/helper";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
//import "./EditBilleeModal.scss";

// import images
import CloseModal from "../../../../assets/close-outline.svg";
import DisabledIcon from "../../../../assets/disabled-row.svg";
const isNotEmpty = (value) => value.trim() !== "";
const isEmailValid = (value) => value.includes("@");

function EditBilleeModal(props) {
  const { isShowModal, hideModal, type, setDataFlag, setShowToast } = props;

  const [companyError, setCompanyError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [billeAddress1Error, setBilleeAddress1Error] = useState("");
  const [billeAddress2Error, setBilleeAddress2Error] = useState("");
  const [billeCountryError, setBilleeCountryError] = useState("");
  const [billeCityError, setBilleeCityError] = useState("");
  const [billeStateError, setBilleeStateError] = useState("");
  const [billeZipError, setBilleeZipError] = useState("");

  const [addBtnDisabled, setAddBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");
  const [phoneValue, setPhoneValue] = useState("");

  const idToBeEdited = useSelector((state) => state.community.idToBeEdited);

  const params = useParams();
  const { communityID: communityId } = params;

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) => {
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const token = "Bearer " + getWithExpiry("expiry_token");

  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/community/update_billee/${idToBeEdited}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {

        const resData = response.data.data;
        setUser(resData.billee);
        setPhoneValue(resData.billee.telephone_number);
      })
      .catch((error) => {
      });
  }, []);

  const addBilleeSubmissionHandler = (event) => {
    event.preventDefault();

    setLoading(true);

    setCompanyError("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");
    setBilleeAddress1Error("");
    setBilleeAddress2Error("");
    setBilleeCountryError("");
    setBilleeCityError("");
    setBilleeStateError("");
    setBilleeZipError("");

    setAddBtnDisabled(true);

    // create form data here
    //const form = document.querySelector(".update-billee-form");
    //const fd = new FormData(form);

    const fd = new FormData();
    fd.append("company", user.company);
    fd.append("first_name", user.first_name);
    fd.append("last_name", user.last_name);
    fd.append("email", user.email);
    fd.append("address_1", user.address_1);
    fd.append("address_2", user.address_2);
    fd.append("country", user.country);
    fd.append("city", user.city);
    fd.append("state", user.state);
    fd.append("zip", user.zip);

    //let phoneVal = user.telephone_number.replace(/\s+/g, "");
    if (phoneValue && !phoneValue.includes("+", 0)) {
      fd.append("telephone_number", "+" + phoneValue);
    } else if (phoneValue) {
      fd.append("telephone_number", phoneValue);
    }

    fd.append("community_id", user?.community_id);

    // make http call
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/community/update_billee/${idToBeEdited}`,
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      })
      .catch((error) => {
        setLoading(false);
        setAddBtnDisabled(false);
        console.error("ADD BILLEE ERROR", error.response);
        const errorData = error.response.data;
        if (error.response && errorData.message === "Field Errors") {
          if (errorData.message === "Field Errors") {
            // Company
            if (
              errorData.data["user_form"] &&
              "company" in errorData.data["user_form"]
            ) {
           
              setCompanyError(errorData.data["user_form"]["company"][0]);
            }
            // First name
            if (
              errorData.data["user_form"] &&
              "first_name" in errorData.data["user_form"]
            ) {
            
              setFirstNameError(errorData.data["user_form"]["first_name"][0]);
            }
            // last name
            if (
              errorData.data["user_form"] &&
              "last_name" in errorData.data["user_form"]
            ) {
            
              setLastNameError(errorData.data["user_form"]["last_name"][0]);
            }
            // Email
            if (
              errorData.data["user_form"] &&
              "email" in errorData.data["user_form"]
            ) {
             
              setEmailError(errorData.data["user_form"]["email"][0]);
            }

            // Telephone Number
            if (
              errorData.data["phone_form"] &&
              "telephone_number" in errorData.data["phone_form"]
            ) {
              
              setPhoneError(
                errorData.data["phone_form"]["telephone_number"][0]
              );
            }

            if (
              errorData.data["address_form"] &&
              "address_1" in errorData.data["address_form"]
            ) {
              setBilleeAddress1Error(
                errorData.data["address_form"]["address_1"][0]
              );
            }
            if (
              errorData.data["address_form"] &&
              "address_2" in errorData.data["address_form"]
            ) {
              setBilleeAddress2Error(
                errorData.data["address_form"]["address_2"][0]
              );
            }
            if (
              errorData.data["address_form"] &&
              "country" in errorData.data["address_form"]
            ) {
              setBilleeCountryError(
                errorData.data["address_form"]["country"][0]
              );
            }
            if (
              errorData.data["address_form"] &&
              "city" in errorData.data["address_form"]
            ) {
              setBilleeCityError(errorData.data["address_form"]["city"][0]);
            }
            if (
              errorData.data["address_form"] &&
              "state" in errorData.data["address_form"]
            ) {
              setBilleeStateError(errorData.data["address_form"]["state"][0]);
            }
            if (
              errorData.data["address_form"] &&
              "zip" in errorData.data["address_form"]
            ) {
              setBilleeZipError(errorData.data["address_form"]["zip"][0]);
            }
          } else {
            notifyError(errorData.message);
          }
        } else {
          notifyError(errorData.message);
        }
      });
  };

  const nameChangeHandler = (e) => {
    if (user) {
      const { name, value } = e.target;
      if (name === "zip") {
        const regex = /^\d+$/;
        if (value === "") {
          setUser({ ...user, [name]: value });
          return;
        }

        if (regex.test(value) === false) return;
      }
      setUser({ ...user, [name]: value });
    }
  };

  return (
    <div className="action-modal-wrapper">
      <Modal
        className="comunitie-modal modal-loader"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header
          className="comubitie-modal-header"
          style={{ padding: "1rem 0rem" }}
        >
          <h1 className="modal-header-title">Edit Billee</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="comunitie-modal-body">
          <form
           
            method="post"
            className="update-billee-form"
            onSubmit={addBilleeSubmissionHandler}
          >
            <div className="comunities-fields">
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="company" className="label">
                      Company *
                    </label>
                    <input
                      id="company"
                      name="company"
                      type="text"
                      placeholder="Company"
                      className="custom-input"
                      value={user ? user.company : ""}
                      onChange={nameChangeHandler}
                      required
                    />
                    {companyError && (
                      <p className="error-text">{companyError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="first_name" className="label">
                      First Name *
                    </label>
                    <input
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="First Name"
                      className="custom-input"
                      value={user ? user.first_name : ""}
                      onChange={nameChangeHandler}
                      required
                    />
                    {firstNameError && (
                      <p className="error-text">{firstNameError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="last_name" className="label">
                      Last Name *
                    </label>
                    <input
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Last Name"
                      className="custom-input"
                      value={user ? user.last_name : ""}
                      onChange={nameChangeHandler}
                      required
                    />
                    {lastNameError && (
                      <p className="error-text">{lastNameError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="email" className="label">
                      Email *
                    </label>

                    <input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      className="custom-input"
                      value={user ? user.email : ""}
                      onChange={nameChangeHandler}
                      required
                    />
                    {emailError && <p className="error-text">{emailError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="telephone_number" className="label">
                      Telephone *
                    </label>
                    {/* <input
                      id="telephone_number"
                      name="telephone_number"
                      type="text"
                      placeholder="Telephone"
                      className="custom-input"
                      onChange={(e) => {
                        const { name, value } = e.target;

                        const regex = /^([\s+0-9])+$/i;
                        if (value === "") {
                          // case to remove whole highlighted text
                          setUser({ ...user, [name]: value });
                          return;
                        }
                        if (regex.test(value) === false) return;

                        if (value.length === 1 && value === "+") {
                          // case to remove + sign when last number is removed
                          setUser({ ...user, [name]: "" });
                        } else if (value.length >= 1 && value[0] !== "+") {
                          // case to append + sign with first number
                          setUser({ ...user, [name]: "+" + value });
                        } else {
                          // case to append other numbers
                          setUser({ ...user, [name]: value });
                        }
                      }}
                      value={user ? user.telephone_number : ""}
                      required
                    /> */}
                    <PhoneInput
                      country={"us"}
                      value={phoneValue}
                      onChange={setPhoneValue}
                      inputProps={{
                        required: true,
                      }}
                    />
                    {phoneError && <p className="error-text">{phoneError}</p>}
                  </div>
                </div>

                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="address_1" className="label">
                      Address 01 *
                    </label>
                    <input
                      id="address_1"
                      name="address_1"
                      type="text"
                      className="custom-input"
                      // value={address2}
                      // onChange={setAddress2Handler}
                      placeholder="Address 01"
                      value={user?.address_1}
                      onChange={nameChangeHandler}
                    />
                    {billeAddress1Error && (
                      <p className="error-text">{billeAddress1Error}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="address_2" className="label">
                      Address 02
                    </label>
                    <input
                      id="address_2"
                      name="address_2"
                      type="text"
                      className="custom-input"
                      placeholder="Address 02"
                      value={user?.address_2}
                      onChange={nameChangeHandler}
                    />
                    {billeAddress2Error && (
                      <p className="error-text">{billeAddress2Error}</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="city" className="label">
                      City *
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="custom-input"
                      placeholder="City"
                      value={user?.city}
                      onChange={nameChangeHandler}
                      required
                    />
                    {billeCityError && (
                      <p className="error-text">{billeCityError}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="state" className="label">
                      State *
                    </label>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      className="custom-input"
                      placeholder="State"
                      value={user?.state}
                      onChange={nameChangeHandler}
                      required
                    />
                    {billeStateError && (
                      <p className="error-text">{billeStateError}</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="zip" className="label">
                      Zip *
                    </label>
                    <input
                      type="text"
                      name="zip"
                      id="zip"
                      className="custom-input"
                      placeholder="Zip"
                      value={user?.zip}
                      onChange={nameChangeHandler}
                      required
                    />
                    {billeZipError && (
                      <p className="error-text">{billeZipError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="country" className="label">
                      Country *
                    </label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      className="custom-input"
                      placeholder="Country"
                      value={user?.country}
                      onChange={nameChangeHandler}
                      required
                    />
                    {billeCountryError && (
                      <p className="error-text">{billeCountryError}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16 text-capitalize"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            onClick={addBilleeSubmissionHandler}
            disabled={addBtnDisabled}
            className="btn btn-primary-fill btn-primary-16"
          >
            Update
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

export default EditBilleeModal;
