import axios from "axios";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { usePagination, DOTS } from "./usePagination";
import { Link } from "react-router-dom";
import { getWithExpiry } from "../../utils/helper";
import { userActions } from "../../../store/user";
import "./Pagination.scss";
import { billingActions } from "../../../store/billing";
const InvoiceDetailPagination = (props) => {
  // const globalCurrentPage = useSelector(
  //   (state) => state.user.globalCurrentPage
  // );
  // const [currentPage, setCurrentPage] = useState(1);
  const [url, setUrl] = useState("");
  const [selectedPage, setSelectedPage] = useState(1);
  const dispatch = useDispatch();

  const token = "Bearer " + getWithExpiry("expiry_token");

  const PageNumbers = useSelector(
    (state) => state.billing.pastInvoiceHistoryPageNumber
  );
  const currentPage = useSelector(
    (state) => state.billing.pastInvoiceHistoryCurrentPage
  );

  let Url;
  //   let PageNumbers;
  //   let currentPage;
  //   if (props.funcType === "Audit") {
  //     PageNumbers = auditLogsPageNumber;
  //     currentPage = auditLogsCurrentPage;
  //   }

  let totalRecords = props.totalRecords;
  let offset = totalRecords > 0 ? 1 : 0;
  let multiplyFactor = props.type === "Test" ? 5 : 10;
  let siblingCount = 1;
  let pageSize = props.type === "Test" ? 5 : 10;

  const paginationRange = usePagination({
    currentPage,
    totalRecords,
    siblingCount,
    pageSize,
  });
  const paginate = (pageNumber) => {
    setSelectedPage(pageNumber);
    let query = props.LogsApiUrl ? props.LogsApiUrl + "&" : "?";

    Url = `${process.env.REACT_APP_URL_PATH}/billing/get_invoice_details/${props.groupTestId}/${query}page=${pageNumber}`;
    axios({
      method: "get",
      url: Url,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        props.setInvoiceTests(response.data.data.tests);
        dispatch(billingActions.setPastInvoiceHistoryCurrentPage(pageNumber));
        props.setOtherLoader(false);
      })

      .catch((error) => {
      });
  };

  const pageNumbersArray = [];

  for (let i = 1; i <= PageNumbers; i++) {
    pageNumbersArray.push(i);
  }

  return (
    <div className="row pagination-row align-row-pg">
      <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
        <div className="react-bs-table-sizePerPage-dropdown dropdown">
          <span className="totalRecord text-muted pagination-align">
            Displaying
            <div className="icon">
              <span className="dropdown-avatar">
                {currentPage > 1
                  ? (currentPage - 1) * multiplyFactor + 1
                  : offset}{" "}
                -{" "}
                {currentPage === PageNumbers
                  ? totalRecords
                  : currentPage * multiplyFactor}
              </span>
            </div>
            out of {totalRecords}
          </span>
        </div>
      </div>
      <div className=" col-md-6 col-xs-6 col-sm-6 col-lg-6">
        <ul className="pagination ">
          {currentPage > 1 && (
            <li
              key={Math.random()}
              className="page-item previous-item"
              title="Go to previous"
              style={{ backgroundColor: currentPage > 1 && "#031f4b" }}
            >
              <span
                // href="#"
                onClick={() => {
                  props.setOtherLoader(true);
                  if (currentPage > 1) {
                    paginate(currentPage - 1);
                  } else {
                    paginate(currentPage);
                  }
                }}
                className="page-link"
              ></span>
            </li>
          )}
          {paginationRange &&
            paginationRange.length > 1 &&
            paginationRange.map((pageNumber) => {
              // If the pageItem is a DOT, render the DOTS unicode character
              if (pageNumber === DOTS) {
                return (
                  <li
                    key={Math.random()}
                    className="pagination-item pagination-item-dots"
                  >
                    &#8230;
                  </li>
                );
              }

              // Render our Page Pills
              return (
                <li
                  key={pageNumber}
                  className={
                    pageNumber === currentPage
                      ? "page-item active"
                      : "page-item"
                  }
                  // className={"page-item"}
                >
                  <span
                    onClick={() => {
                      props.setOtherLoader(true);
                      paginate(pageNumber);
                    }}
                    // href="#"
                    className="page-link"
                  >
                    {pageNumber}
                  </span>
                </li>
              );
            })}
          {/*  Right Navigation arrow */}
          {currentPage < pageNumbersArray.length && (
            <li
              key={Math.random()}
              className="page-item next-item"
              title="Go to next"
              style={{
                backgroundColor:
                  currentPage < pageNumbersArray.length && "#031f4b",
              }}
            >
              <span
                // href="#"
                onClick={() => {
                  props.setOtherLoader(true);
                  if (currentPage < pageNumbersArray.length) {
                    paginate(currentPage + 1);
                  } else {
                    paginate(currentPage);
                  }
                }}
                className="page-link "
              ></span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default InvoiceDetailPagination;
