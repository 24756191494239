import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import { userActions } from "../../../store/user";
import { authActions } from "../../../store/auth";
import { setWithExpiry } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import "./TermsModal.scss";
import axios from "axios";
import Loader2 from "../../UI/loader2/loader2";
// imports image

import CloseModal from "../../../assets/close-outline.svg";

function TermsServiesModal(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    isShowModal,
    hideModal,
    document,
    email,
    password,
    emailOtp,
    otpCheck,
    type,
  } = props;
  const rememberMe = useSelector((state) => state.auth.rememberMe);

  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  function getResponse(response, res) {
    const token = response.data.data.token;
    const successMessage = "Login Successful";
    dispatch(authActions.verifyOtp(token)); // dispatch token to auth store
    dispatch(authActions.verifyOtpSuccessMsg(successMessage)); // store login success message

    if (rememberMe === true) {
      setWithExpiry("expiry_token", token, 86400000);
    } else {
      setWithExpiry("expiry_token", token, 7200000);
    }
    setWithExpiry("otp_expiry_" + email, "available", 2592000000);
    if (res.data.data.is_enabled) {
      dispatch(authActions.setIsLoggedIn(true));
    } else {
      notifyError("Cannot Login. User is Disabled");
      localStorage.removeItem("expiry_token");
      sessionStorage.clear();
    }
    history.replace("/login");
  }
  const acceptTerms = () => {
    setBtnDisabled(true);
    setLoading(true);
    const fd = new FormData();

    if (type === "login") {
      fd.append("email", email);
      fd.append("password", password);
      fd.append("verify_otp", "no");
    } else {
      fd.append("email", email);
      fd.append("otp", emailOtp);
      fd.append("otp_check", otpCheck);
      fd.append("verify_otp", "yes");
    }

    axios({
      url: `${process.env.REACT_APP_URL_PATH}/user/accept_terms_conditions/`,
      method: "POST",
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        const token = response.data.data.token;
        //
        axios({
          url: `${process.env.REACT_APP_URL_PATH}/user/permissions/`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            const permissions = response.data.data.permissions;
            const catchu_admin = response.data.data.catchu_admin;
            dispatch(userActions.addPermissions(permissions));
            dispatch(userActions.setIsCatchuAdmin(catchu_admin));
          })
          .catch((error) => {
            if (error.response) {
              //setDisabledBtn(false);

              notifyError(error.response.data.message);
            }
          });

        //
        axios({
          url: `${process.env.REACT_APP_URL_PATH}/user/roles/`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            const resData = res.data.data.roles;
            dispatch(userActions.setRole(resData));

            getResponse(response, res);
          })
          .catch((error) => {
            if (error.response) {
              //setDisabledBtn(false);

              notifyError(error.response.data.message);
            }
          });
      })
      .catch((error) => {
        setBtnDisabled(false);
        setLoading(false);
      });
  };

  const rejectTerms = () => {
    const fd = new FormData();
    setBtnDisabled(true);
    setLoading(true);

    if (type === "login") {
      fd.append("email", email);
      fd.append("password", password);
      fd.append("verify_otp", "no");
    } else {
      fd.append("email", email);
      fd.append("otp", emailOtp);
      fd.append("otp_check", otpCheck);
      fd.append("verify_otp", "yes");
    }

    axios({
      url: `${process.env.REACT_APP_URL_PATH}/user/reject_terms_conditions/`,
      method: "POST",
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        //const resData = res.data.data.roles;
        hideModal();
        history.replace("/login");
        notifyError(res.data.message);
      })
      .catch((error) => {
        setBtnDisabled(false);
        setLoading(false);
        hideModal();
        history.replace("/login");
        notifyError("Something Went Wrong. Try Again!");
      });
  };

  return (
    <div className="test-modal-wrapper">
      <Modal
        className="test-modal terms-condition"
        show={isShowModal}
        onHide={hideModal}
        backdrop="static"
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header className="audit-modal-header">
          <h1 className="modal-header-title">Terms and Conditions</h1>
          {/* <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span> */}
        </Modal.Header>
        <Modal.Body className="action-modal-body">
          {/* <h2 className="action-heading">
            Terms and Conditions
          </h2> */}
          <iframe
            title={"IFRAME"}
            style={{ width: "100%", height: "100vh" }}
            src={document}
          ></iframe>
        </Modal.Body>
        <Modal.Footer className="action-footer-modal">
          <button
            className="btn btn-primary-fill btn-14-24"
            onClick={acceptTerms}
            disabled={btnDisabled}
          >
            Accept
          </button>
          <button
            className="btn btn-secondary-fill btn-14-24 text-capitalize"
            onClick={rejectTerms}
            disabled={btnDisabled}
          >
            Decline
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

export default TermsServiesModal;
