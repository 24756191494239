import axios from "axios";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { usePagination, DOTS } from "./usePagination";
import { useHistory, useLocation } from "react-router-dom";
import community, { communityActions } from "../../../store/community";
import { corporateActions } from "../../../store/corporate";
import { patientActions } from "../../../store/patient";
import { providerActions } from "../../../store/provider";
import { AdminUserActions } from "../../../store/adminUser";
import { RegUserActions } from "../../../store/regUser";
import { testActions } from "../../../store/test";
import billing, { billingActions } from "../../../store/billing";
import "./Pagination.scss";
import { getWithExpiry } from "../../utils/helper";
import useQueryString from "../../../hooks/useQueryString";
import { equipmentsActions } from "../../../store/equipments";
import { externalProvidersActions } from "../../../store/externalProviders";
import { prepaidPatientsActions } from "../../../store/prepaidPatients";

const Pagination = (props) => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = useQueryString();
  let urlKeys = [...urlParams.keys()];
  let urlValues = [...urlParams.values()];
  let urlParamString = urlParams.toString();

  const dispatch = useDispatch();

  const token = "Bearer " + getWithExpiry("expiry_token");

  const corporateApiUrl = useSelector(
    (state) => state.corporate.corporateApiURL
  );
  const corporatePagenumber = useSelector(
    (state) => state.corporate.corporatespage
  );
  const corporateCurrentPage = useSelector(
    (state) => state.corporate.corporateCurrentPage
  );

  const communityApiUrl = useSelector(
    (state) => state.community.communityApiUrl
  );
  const CommunityPageNumbers = useSelector(
    (state) => state.community.PageNumbers
  );
  const CommunityCurrentPage = useSelector(
    (state) => state.community.communityCurrentPage
  );

  const communityCorporateApiUrl = useSelector(
    (state) => state.corporate.communityCorporateApiUrl
  );
  const corporateCommunityPageNumbers = useSelector(
    (state) => state.corporate.communityPage
  );
  const corporateCommunityCurrentPage = useSelector(
    (state) => state.corporate.communityCorporateCurrentPage
  );

  const userCorporateApiUrl = useSelector(
    (state) => state.corporate.userCorporateApiUrl
  );
  const userPageNumbers = useSelector((state) => state.corporate.userPages);
  const userCurrentPage = useSelector(
    (state) => state.corporate.userCorporateCurrentPage
  );

  const patientApiUrl = useSelector((state) => state.patient.patientApiUrl);
  const patientPageNumbers = useSelector(
    (state) => state.patient.patientPageNumber
  );
  const patientCurrentPage = useSelector(
    (state) => state.patient.patientCurrentPage
  );

  const providerApiUrl = useSelector((state) => state.provider.providerApiUrl);
  const providerPageNumbers = useSelector(
    (state) => state.provider.ProviderPageNumber
  );
  const providerCurrentPage = useSelector(
    (state) => state.provider.providerCurrentPage
  );

  const regUserApiUrl = useSelector((state) => state.reguser.regUserApiUrl);
  const RegUserPageNumbers = useSelector((state) => state.reguser.pageNumber);
  const RegUserCurrentPage = useSelector(
    (state) => state.reguser.regUserCurrentPage
  );

  const adminUserApiUrl = useSelector(
    (state) => state.adminuser.adminUserApiUrl
  );
  const externalProvidersApiUrl = useSelector(
    (state) => state.externalProviders.externalProvidersApiUrl
  );
  const prepaidPatientsApiUrl = useSelector(
    (state) => state.prepaidPatients.prepaidPatientsApiUrl
  );
  const AdminUserPageNumbers = useSelector(
    (state) => state.adminuser.pageNumber
  );
  const AdminUserCurrentPage = useSelector(
    (state) => state.adminuser.adminUserCurrentPage
  );

  //
  const patientCommunityApiUrl = useSelector(
    (state) => state.community.patientCommunityApiUrl
  );
  const userCommunityApiUrl = useSelector(
    (state) => state.community.userCommunityApiUrl
  );
  const iphoneCommunityApiUrl = useSelector(
    (state) => state.community.iphoneCommunityApiUrl
  );
  const devicesCommunityApiUrl = useSelector(
    (state) => state.community.devicesCommunityApiUrl
  );

  const devicesPageNumbersCommunity = useSelector(
    (state) => state.community.DevicesPage
  );

  const billeeCommunityApiUrl = useSelector(
    (state) => state.community.billeeCommunityApiUrl
  );
  const testCommunityApiUrl = useSelector(
    (state) => state.community.testCommunityApiUrl
  );
  //
  const iphonePageNumbers = useSelector((state) => state.community.iphonesPage);
  const iphoneCurrentPage = useSelector(
    (state) => state.community.iphoneCommunityCurrentPage
  );

  const userPageNumbersCommunity = useSelector(
    (state) => state.community.userPage
  );
  const userCurrentPageCommunity = useSelector(
    (state) => state.community.userCommunityCurrentPage
  );

  const billeePageNumbersCommunity = useSelector(
    (state) => state.community.BilleePage
  );
  const billeeCurrentPageCommunity = useSelector(
    (state) => state.community.billeeCommunityCurrentPage
  );

  const patientPageNumbersCommunity = useSelector(
    (state) => state.community.PatientPage
  );
  const patientCurrentPageCommunity = useSelector(
    (state) => state.community.patientCommunityCurrentPage
  );

  const historyPageNumbersCommunity = useSelector(
    (state) => state.community.groupTestHistoryPage
  );
  const historyCurrentPageCommunity = useSelector(
    (state) => state.community.historyCommunityCurrentPage
  );

  const patientHistoryApiUrl = useSelector(
    (state) => state.patient.patientHistoryApiUrl
  );
  const patientHistoryPageNumbers = useSelector(
    (state) => state.patient.testPageNumber
  );
  const patientHistoryCurrentPage = useSelector(
    (state) => state.patient.testCurrentPage
  );
  const externalProvidersCurrentPage = useSelector(
    (state) => state.externalProviders.externalProvidersCurrentPage
  );
  const externalProvidersPageNumbers = useSelector(
    (state) => state.externalProviders.externalProvidersPageNumbers
  );
  const prepaidPatientsPageNumbers = useSelector(
    (state) => state.prepaidPatients.prepaidPatientsPageNumbers
  );
  const prepaidPatientsCurrentPage = useSelector(
    (state) => state.prepaidPatients.prepaidPatientsCurrentPage
  );
  //why???
  // const TestPageNumbersCommunity = useSelector(
  //   (state) => state.test.PageNumber
  // );
  // const TestCurrentPageCommunity = useSelector(
  //   (state) => state.test.PageNumber
  // );
  //

  const upcomingTestsApiUrl = useSelector(
    (state) => state.test.upcomingTestsApiUrl
  );
  const upcomingTestPageNumbers = useSelector(
    (state) => state.test.upcomingTestPageNumbers
  );
  const upcomingTestCurrentPage = useSelector(
    (state) => state.test.upcomingTestCurrentPage
  );

  const testsToScheduleApiUrl = useSelector(
    (state) => state.test.testsToScheduleApiUrl
  );
  const scheduleTestPageNumbers = useSelector(
    (state) => state.test.scheduleTestPageNumbers
  );
  const scheduleTestCurrentPage = useSelector(
    (state) => state.test.scheduleTestCurrentPage
  );

  const testsBillingApiUrl = useSelector(
    (state) => state.billing.communityCorporateApiUrl
  );

  const testBillPageNumber = useSelector(
    (state) => state.billing.testBillPageNumber
  );
  const testBillCurrentPage = useSelector(
    (state) => state.billing.testBillCurrentPage
  );

  const invoicesBillingApiUrl = useSelector(
    (state) => state.billing.invoicesBillingApiUrl
  );
  const invoicesEquipmentApiUrl = useSelector(
    (state) => state.equipments.invoicesBillingApiUrl
  );
  const pastInvoicePageNumber = useSelector(
    (state) => state.billing.pastInvoicePageNumber
  );
  const pastInvoiceEquipmentPageNumber = useSelector(
    (state) => state.equipments.pastInvoicePageNumber
  );
  const pastInvoiceCurrentPage = useSelector(
    (state) => state.billing.pastInvoiceCurrentPage
  );
  const pastInvoiceEquipmentCurrentPage = useSelector(
    (state) => state.equipments.pastInvoiceCurrentPage
  );

  const creditHistoryApiUrl = useSelector(
    (state) => state.billing.creditHistoryApiUrl
  );
  const creditHistoryPageNumber = useSelector(
    (state) => state.billing.creditHistoryPageNumber
  );
  const creditHistoryCurrentPage = useSelector(
    (state) => state.billing.creditHistoryCurrentPage
  );

  const auditLogsPageNumber = useSelector(
    (state) => state.user.auditLogsPageNumber
  );
  const auditLogsCurrentPage = useSelector(
    (state) => state.billing.auditLogsCurrentPage
  );

  const {
    equipments,
    equipmentsCurrentPage,
    equipmentsPageNumbers,
    equipmentsApiUrl,
  } = useSelector((state) => state.billing);
  const {
    devicesCurrentPage,
    devicesTotalRecord,
    devicesTotalPages,
    devicesList,
    devicesApiURL,
  } = useSelector((state) => state.community);
  let Url;
  let PageNumbers;
  let currentPage;
  if (props.type === "Test" && props.dataType === "upcoming") {
    PageNumbers = upcomingTestPageNumbers;
    if (urlValues.includes("upcoming_test")) {
      currentPage = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? parseInt(urlParams.get("page"))
          : upcomingTestCurrentPage
        : upcomingTestCurrentPage;
    } else {
      currentPage = upcomingTestCurrentPage;
    }
  } else if (props.type === "Test" && props.dataType === "schedule") {
    PageNumbers = scheduleTestPageNumbers;
    if (urlValues.includes("schedule_test")) {
      currentPage = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? parseInt(urlParams.get("page"))
          : scheduleTestCurrentPage
        : scheduleTestCurrentPage;
    } else {
      currentPage = scheduleTestCurrentPage;
    }
  } else if (props.dataType === "testToBill" && props.type === "billing") {
    PageNumbers = testBillPageNumber;
    if (urlValues.includes("test")) {
      currentPage = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? parseInt(urlParams.get("page"))
          : testBillCurrentPage
        : testBillCurrentPage;
    } else {
      currentPage = testBillCurrentPage;
    }
  } else if (props.dataType === "pastInvoices" && props.type === "billing") {
    PageNumbers = pastInvoicePageNumber;
    if (urlValues.includes("invoice")) {
      currentPage = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? parseInt(urlParams.get("page"))
          : pastInvoiceCurrentPage
        : pastInvoiceCurrentPage;
    } else {
      currentPage = pastInvoiceCurrentPage;
    }
  } else if (
    props.dataType === "equipastInvoices" &&
    props.type === "Equipments"
  ) {
    PageNumbers = pastInvoiceEquipmentPageNumber;
    if (urlValues.includes("invoice")) {
      currentPage = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? parseInt(urlParams.get("page"))
          : pastInvoiceEquipmentCurrentPage
        : pastInvoiceEquipmentCurrentPage;
    } else {
      currentPage = pastInvoiceEquipmentCurrentPage;
    }
  } else if (props.dataType === "creditHistory" && props.type === "billing") {
    PageNumbers = creditHistoryPageNumber;
    if (urlValues.includes("credit_history")) {
      currentPage = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? parseInt(urlParams.get("page"))
          : creditHistoryCurrentPage
        : creditHistoryCurrentPage;
    } else {
      currentPage = creditHistoryCurrentPage;
    }
  } else if (props.type === "corporate") {
    PageNumbers = corporatePagenumber;
    currentPage = urlParams.has("page")
      ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
        ? parseInt(urlParams.get("page"))
        : corporateCurrentPage
      : corporateCurrentPage;
  } else if (props.type === "Equipments" && props.dataType === "Equipments") {
    PageNumbers = equipmentsPageNumbers;
    currentPage = urlParams.has("page")
      ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
        ? parseInt(urlParams.get("page"))
        : equipmentsCurrentPage
      : equipmentsCurrentPage;
  } else if (props.type === "community") {
    PageNumbers = CommunityPageNumbers;
    currentPage = urlParams.has("page")
      ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
        ? parseInt(urlParams.get("page"))
        : CommunityCurrentPage
      : CommunityCurrentPage;
  } else if (props.dataType === "community" && props.type === "Corporate") {
    PageNumbers = corporateCommunityPageNumbers;
    if (urlValues.includes("community")) {
      currentPage = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? parseInt(urlParams.get("page"))
          : corporateCommunityCurrentPage
        : corporateCommunityCurrentPage;
    } else {
      currentPage = corporateCommunityCurrentPage;
    }
  } else if (props.dataType === "user" && props.type === "Corporate") {
    PageNumbers = userPageNumbers;
    if (urlValues.includes("user")) {
      currentPage = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? parseInt(urlParams.get("page"))
          : userCurrentPage
        : userCurrentPage;
    } else {
      currentPage = userCurrentPage;
    }
  } else if (props.type === "Patient" && props.dataType !== "Patient History") {
    PageNumbers = patientPageNumbers;
    currentPage = urlParams.has("page")
      ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
        ? parseInt(urlParams.get("page"))
        : patientCurrentPage
      : patientCurrentPage;
  } else if (props.type === "Provider") {
    PageNumbers = providerPageNumbers;
    currentPage = urlParams.has("page")
      ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
        ? parseInt(urlParams.get("page"))
        : providerCurrentPage
      : providerCurrentPage;
  } else if (props.type === "RegUser") {
    PageNumbers = RegUserPageNumbers;
    if (urlValues.includes("regular_user")) {
      currentPage = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? parseInt(urlParams.get("page"))
          : RegUserCurrentPage
        : RegUserCurrentPage;
    } else {
      currentPage = RegUserCurrentPage;
    }
  } else if (props.type === "AdminUser") {
    PageNumbers = AdminUserPageNumbers;
    if (urlValues.includes("admin_user")) {
      currentPage = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? parseInt(urlParams.get("page"))
          : AdminUserCurrentPage
        : AdminUserCurrentPage;
    } else {
      currentPage = AdminUserCurrentPage;
    }
  } else if (props.dataType === "iphone" && props.type === "Community") {
    PageNumbers = iphonePageNumbers;
    if (urlValues.includes("iphone")) {
      currentPage = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? parseInt(urlParams.get("page"))
          : iphoneCurrentPage
        : iphoneCurrentPage;
    } else {
      currentPage = iphoneCurrentPage;
    }
  } else if (props.dataType === "devices" && props.type === "Community") {
    PageNumbers = devicesTotalPages;
    if (urlValues.includes("devices")) {
      currentPage = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? parseInt(urlParams.get("page"))
          : devicesCurrentPage
        : devicesCurrentPage;
    } else {
      currentPage = devicesCurrentPage;
    }
  } else if (props.dataType === "user" && props.type === "Community") {
    PageNumbers = userPageNumbersCommunity;
    if (urlValues.includes("user")) {
      currentPage = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? parseInt(urlParams.get("page"))
          : userCurrentPageCommunity
        : userCurrentPageCommunity;
    } else {
      currentPage = userCurrentPageCommunity;
    }
  } else if (props.dataType === "billee" && props.type === "Community") {
    PageNumbers = billeePageNumbersCommunity;
    if (urlValues.includes("billee")) {
      currentPage = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? parseInt(urlParams.get("page"))
          : billeeCurrentPageCommunity
        : billeeCurrentPageCommunity;
    } else {
      currentPage = billeeCurrentPageCommunity;
    }
  } else if (props.dataType === "patient" && props.type === "Community") {
    PageNumbers = patientPageNumbersCommunity;
    if (urlValues.includes("billee")) {
      currentPage = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? parseInt(urlParams.get("page"))
          : patientCurrentPageCommunity
        : patientCurrentPageCommunity;
    } else {
      currentPage = patientCurrentPageCommunity;
    }
  } else if (props.dataType === "history" && props.type === "Community") {
    PageNumbers = historyPageNumbersCommunity;
    if (urlValues.includes("group_test")) {
      currentPage = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? parseInt(urlParams.get("page"))
          : historyCurrentPageCommunity
        : historyCurrentPageCommunity;
    } else {
      currentPage = historyCurrentPageCommunity;
    }
  }
  // else if (props.type === "Test") {
  //   PageNumbers = TestPageNumbersCommunity;
  //   currentPage =
  // }
  else if (props.dataType === "Patient History" && props.type === "Patient") {
    PageNumbers = patientHistoryPageNumbers;
    currentPage = urlParams.has("page")
      ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
        ? parseInt(urlParams.get("page"))
        : patientHistoryCurrentPage
      : patientHistoryCurrentPage;
  } else if (props.type === "External Providers") {
    PageNumbers = externalProvidersPageNumbers;
    currentPage = urlParams.has("page")
      ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
        ? parseInt(urlParams.get("page"))
        : externalProvidersCurrentPage
      : externalProvidersCurrentPage;
  }else if (props.type === "Prepaid Patients") {
    PageNumbers = prepaidPatientsPageNumbers;
    currentPage = urlParams.has("page")
      ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
        ? parseInt(urlParams.get("page"))
        : prepaidPatientsCurrentPage
      : prepaidPatientsCurrentPage;
  }

  if (currentPage > PageNumbers) {
    currentPage = PageNumbers;
  }

  let totalRecords = props.totalRecords;
  let offset = totalRecords > 0 ? 1 : 0;
  let multiplyFactor = props.type === "Test" ? 5 : 10;
  let siblingCount = 1;
  let pageSize = props.type === "Test" ? 5 : 10;

  const paginationRange = usePagination({
    currentPage,
    totalRecords,
    siblingCount,
    pageSize,
  });

  const billingStatusFilters = useSelector(
    (state) => state.filter.filterBillingStatus
  );
  const communityFilters = useSelector(
    (state) => state.filter.filterCommunities
  );
  const patientsFilters = useSelector((state) => state.filter.filterPatients);

  const paginate = (pageNumber) => {
    if (props.type === "corporate") {
      let query;
      if (!corporateApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = corporateApiUrl ? corporateApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/corporate/corporates_list/${query}page=${pageNumber}`;
    }
    if (props.type === "Equipments" && props.dataType === "Equipments") {
      let query;
      if (!equipmentsApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = equipmentsApiUrl ? equipmentsApiUrl + "&" : "?";
      }
      Url = `${
        process.env.REACT_APP_URL_PATH
      }/billing/get_billing_data_equipment/?equipment_to_bill_page=${pageNumber}&community_ids=ALL${
        equipmentsApiUrl ? "&" + equipmentsApiUrl : ""
      }`;
    } else if (props.dataType === "community" && props.type === "Corporate") {
      let query;
      if (!communityCorporateApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = communityCorporateApiUrl ? communityCorporateApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/corporate/view_corporate/${props.parm}${query}community_page=${pageNumber}&user_page=${userCurrentPage}`;
    } else if (props.dataType === "user" && props.type === "Corporate") {
      let query;
      if (!userCorporateApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = userCorporateApiUrl ? userCorporateApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/corporate/view_corporate/${props.parm}${query}community_page=${corporateCommunityCurrentPage}&user_page=${pageNumber}`;
    } else if (props.dataType === "testToBill" && props.type === "billing") {
      let query;
      if (!testsBillingApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = testsBillingApiUrl ? testsBillingApiUrl + "&" : "?";
      }

      Url = `${process.env.REACT_APP_URL_PATH}/billing/get_billing_data/${query}test_to_bill_page=${pageNumber}&community_ids=ALL&patient_ids=${patientsFilters}`;
    } else if (props.dataType === "pastInvoices" && props.type === "billing") {
      let query;
      if (!invoicesBillingApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = invoicesBillingApiUrl ? invoicesBillingApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/billing/get_past_invoices/${query}community_ids=ALL&patient_ids=${patientsFilters}&invoice_status=ALL&past_invoices_page=${pageNumber}`;
    } else if (
      props.dataType === "equipastInvoices" &&
      props.type === "Equipments"
    ) {
      let query;
      if (!invoicesEquipmentApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = invoicesEquipmentApiUrl ? invoicesEquipmentApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/billing/get_past_invoices_equipment/${query}community_ids=ALL&invoice_status=ALL&past_invoices_page=${pageNumber}`;
    } else if (props.dataType === "creditHistory" && props.type === "billing") {
      let query;
      if (!creditHistoryApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = creditHistoryApiUrl ? creditHistoryApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/billing/get_credit_history/${query}credit_history_page=${pageNumber}`;
    } else if (
      props.type === "community" &&
      props.dataType !== "iphone" &&
      props.dataType !== "user" &&
      props.dataType !== "billee" &&
      props.dataType !== "patient" &&
      props.dataType !== "devices"
    ) {
      let query;
      if (!communityApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = communityApiUrl ? communityApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/community/community_list/${query}page=${pageNumber}`;
    } else if (
      props.type === "Patient" &&
      props.dataType !== "Patient History"
    ) {

      let query;
      if (!patientApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = patientApiUrl ? patientApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/community/patient_list/${query}page=${pageNumber}`;
    } else if (props.type === "Provider") {
      let query;
      if (!providerApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = providerApiUrl ? providerApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/community/provider_list/${query}page=${pageNumber}`;
    } else if (props.type === "RegUser") {
      let query;
      if (!regUserApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = regUserApiUrl ? regUserApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/user/regular_user_list/${query}page=${pageNumber}`;
    } else if (props.type === "AdminUser") {
      let query;
      if (!adminUserApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = adminUserApiUrl ? adminUserApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/user/admins_list/${query}page=${pageNumber}`;
    }else if(props.type === "External Providers"){
      let query;
      if (!externalProvidersApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = externalProvidersApiUrl ? externalProvidersApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/user/individualproviders_list/${query}page=${pageNumber}`;    
    }else if(props.type === "Prepaid Patients"){
      let query;
      if (!prepaidPatientsApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = prepaidPatientsApiUrl ? prepaidPatientsApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/user/prepaid_patients_list/${props.params.providerId}${query}page=${pageNumber}`;    
    } else if (props.dataType === "iphone" && props.type === "Community") {
      let query;
      if (!iphoneCommunityApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = iphoneCommunityApiUrl ? iphoneCommunityApiUrl + "&" : "?";
      }

      Url = `${process.env.REACT_APP_URL_PATH}/community/view_community/${props.parm}${query}patient_page=${patientCurrentPageCommunity}&user_page=${userCurrentPageCommunity}&iphone_page=${pageNumber}&billee_page=${billeeCurrentPageCommunity}`;
    } else if (props.dataType === "devices" && props.type === "Community") {
      let query;
      if (!devicesApiURL && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = devicesApiURL ? devicesApiURL + "&" : "?";
      }
      // only insert key of number

      Url = `${process.env.REACT_APP_URL_PATH}/community/equipment_list/?page=${pageNumber}`;
    } else if (props.dataType === "user" && props.type === "Community") {
      let query;
      if (!userCommunityApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = userCommunityApiUrl ? userCommunityApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/community/view_community/${props.parm}${query}patient_page=${patientCurrentPageCommunity}&user_page=${pageNumber}&iphone_page=${iphoneCurrentPage}&billee_page=${billeeCurrentPageCommunity}`;
    } else if (props.dataType === "billee" && props.type === "Community") {
      let query;
      if (!billeeCommunityApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = billeeCommunityApiUrl ? billeeCommunityApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/community/view_community/${props.parm}${query}patient_page=${patientCurrentPageCommunity}&user_page=${userCurrentPageCommunity}&iphone_page=${iphoneCurrentPage}&billee_page=${pageNumber}`;
    } else if (props.dataType === "patient" && props.type === "Community") {
      let query;
      if (!patientCommunityApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = patientCommunityApiUrl ? patientCommunityApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/community/view_community/${props.parm}${query}patient_page=${pageNumber}&user_page=${userCurrentPageCommunity}&iphone_page=${iphoneCurrentPage}&billee_page=${billeeCurrentPageCommunity}`;
    } else if (props.dataType === "history" && props.type === "Community") {
      let query;
      if (!testCommunityApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = testCommunityApiUrl ? testCommunityApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/community/group_test_history/${props.parm}${query}page=${pageNumber}`;
    } else if (props.dataType === "upcoming") {
      let query;
      if (!upcomingTestsApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = upcomingTestsApiUrl ? upcomingTestsApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/test/upcoming_tests/${query}page=${pageNumber}`;
    } else if (props.dataType === "schedule") {
      let query;
      if (!testsToScheduleApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = testsToScheduleApiUrl ? testsToScheduleApiUrl + "&" : "?";
      }
      Url = `${process.env.REACT_APP_URL_PATH}/test/tests_to_schedule/${query}page=${pageNumber}`;
    } else if (props.type === "Test") {
      Url = `${process.env.REACT_APP_URL_PATH}/test/test_list/?page=${pageNumber}`;
    } else if (
      props.dataType === "Patient History" &&
      props.type === "Patient"
    ) {
      let query;
      if (!patientHistoryApiUrl && urlKeys.length > 0) {
        query = "?";
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = patientHistoryApiUrl ? patientHistoryApiUrl + "&" : "?";
      }

      Url = `${process.env.REACT_APP_URL_PATH}/community/view_patient/${props.params}${query}page=${pageNumber}`;
    }

    axios({
      method: "get",
      url: Url,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        if (
          props.type === "corporate" &&
          props.dataType !== "community" &&
          props.dataType !== "user"
        ) {
          dispatch(
            corporateActions.addCorporates(response.data.data.corporates)
          );
          dispatch(corporateActions.setCorporatePageNumber(pageNumber));
        } else if (
          props.dataType === "testToBill" &&
          props.type === "billing"
        ) {
          dispatch(
            billingActions.setTestToBillList(
              response.data.data.tests_to_bill_list
            )
          );
          dispatch(billingActions.setTestBillCurrentPage(pageNumber));
        } else if (
          props.dataType === "pastInvoices" &&
          props.type === "billing"
        ) {
          dispatch(
            billingActions.setPastInvoiceList(response.data.data.past_invoices)
          );
          dispatch(billingActions.setPastInvoiceCurrentPage(pageNumber));
        } else if (
          props.dataType === "equipastInvoices" &&
          props.type === "Equipments"
        ) {
          dispatch(
            equipmentsActions.setPastInvoiceList(
              response.data.data.past_invoices
            )
          );
          dispatch(equipmentsActions.setPastInvoiceCurrentPage(pageNumber));
        } else if (
          props.dataType === "creditHistory" &&
          props.type === "billing"
        ) {
          dispatch(
            billingActions.setCreditHistoryList(
              response.data.data.credit_history
            )
          );
          dispatch(billingActions.setCreditHistoryCurrentPage(pageNumber));
        } else if (
          props.dataType === "creditHistory" &&
          props.type === "billing"
        ) {
          dispatch(
            billingActions.setCreditHistoryList(
              response.data.data.credit_history
            )
          );
          dispatch(billingActions.setCreditHistoryCurrentPage(pageNumber));
        } else if (props.type === "community") {
          dispatch(
            communityActions.setCommunities(response.data.data.communities)
          );
          dispatch(communityActions.setCommunityPageNumber(pageNumber));
        } else if (
          props.type === "Equipments" &&
          props.dataType === "Equipments"
        ) {
          dispatch(equipments.setEquipmentsCurrentPage(pageNumber));
          dispatch(communityActions.setCommunityPageNumber(pageNumber));
        } else if (props.dataType === "community") {
          dispatch(
            corporateActions.setCommunityList(response.data.data.community_list)
          );
          dispatch(
            corporateActions.setCommunityCorporateCurrentPage(pageNumber)
          );
        } else if (
          props.type === "Patient" &&
          props.dataType !== "Patient History"
        ) {
          dispatch(
            patientActions.addPatients(response.data.data["patient_list"])
          );
          dispatch(patientActions.setPatientCurrentPage(pageNumber));
        } else if (props.type === "Provider") {
          dispatch(
            providerActions.setProviderData(response.data.data.provider_list)
          );
          dispatch(providerActions.setProviderCurrentPage(pageNumber));
        } else if (props.type === "RegUser") {
          const RegUsers = response.data.data;

          dispatch(RegUserActions.addRegUsers(RegUsers["Regular Users"]));
          dispatch(RegUserActions.setRegUserCurrentPage(pageNumber));
        } else if (props.type === "AdminUser") {
          const AdminUsers = response.data.data["Admins"];
          dispatch(AdminUserActions.addAdminUsers(AdminUsers));
          dispatch(AdminUserActions.setAdminUserCurrentPage(pageNumber));
        } else if (props.dataType === "user" && props.type === "Corporate") {
          dispatch(corporateActions.setUserList(response.data.data.user_list));
          dispatch(corporateActions.setUserCorporateCurrentPage(pageNumber));
        } else if (props.dataType === "iphone" && props.type === "Community") {
          dispatch(
            communityActions.setIphonesList(response.data.data.iphone_list)
          );
          dispatch(communityActions.setIphoneCommunityCurrentPage(pageNumber));
        } else if (props.dataType === "user" && props.type === "Community") {
          dispatch(communityActions.setUsersList(response.data.data.user_list));
          dispatch(communityActions.setUserCommunityCurrentPage(pageNumber));
        } else if (props.dataType === "billee" && props.type === "Community") {
          dispatch(
            communityActions.setBilleeList(response.data.data.billee_list)
          );
          dispatch(communityActions.setBilleeCommunityCurrentPage(pageNumber));
        } else if (props.dataType === "patient" && props.type === "Community") {
          dispatch(
            communityActions.setPatientList(response.data.data.patient_list)
          );
          dispatch(communityActions.setPatientCommunityCurrentPage(pageNumber));
        } else if (props.dataType === "history" && props.type === "Community") {
          dispatch(
            communityActions.addCummunityGroupTestHistory(
              response.data.data.group_test_history
            )
          );
          dispatch(communityActions.setHistoryCommunityCurrentPage(pageNumber));
        } else if (props.dataType === "devices" && props.type === "Community") {
          dispatch(community.setDevicesCurrentPage(pageNumber));
        } else if (props.dataType === "upcoming") {
          dispatch(
            testActions.setUpcomingTestList(
              response.data.data.upcoming_test_list
            )
          );
          dispatch(testActions.setUpcomingTestCurrentPage(pageNumber));
        } else if (props.dataType === "schedule") {
          dispatch(testActions.setScheduleTestList(response.data.data));
          dispatch(testActions.setScheduleTestCurrentPage(pageNumber));
        } else if (
          props.dataType === "Patient History" &&
          props.type === "Patient"
        ) {
          dispatch(patientActions.setPatientDetails(response.data.data.tests));
          dispatch(patientActions.setTestCurrentPage(pageNumber));
        }else if (
          props.type === "External Providers"
        ) {
          dispatch(externalProvidersActions.setExternalProvidersList(response.data.data.providers));
          dispatch(externalProvidersActions.setExternalProvidersCurrentPage(pageNumber));
        }else if (
          props.type === "Prepaid Patients"
        ) {
          dispatch(prepaidPatientsActions.setPrepaidPatientsList(response.data.data.patients));
          dispatch(prepaidPatientsActions.setPrepaidPatientsCurrentPage(pageNumber));
        }
        props.setOtherLoader(false);
      })

      .catch((error) => {});
  };

  const pageNumbersArray = [];

  for (let i = 1; i <= PageNumbers; i++) {
    pageNumbersArray.push(i);
  }

  return (
    <div className="row pagination-row align-row-pg">
      <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
        <div className="react-bs-table-sizePerPage-dropdown dropdown">
          {/* <span className="totalRecord text-muted pagination-align">
            Displaying
            <div className="icon">
              <span className="dropdown-avatar">
                {currentPage > 1
                  ? (currentPage - 1) * multiplyFactor + 1
                  : offset}{" "}
                -{" "}
                {parseInt(currentPage) === PageNumbers
                  ? totalRecords
                  : currentPage * multiplyFactor}
              </span>
            </div>
            out of {totalRecords}
          </span> */}
          <span className="totalRecord text-muted pagination-align">
            Displaying{" "}
            <div className="icon">
              <span className="dropdown-avatar">
                {/* Calculate starting record number */}
                {totalRecords > 0 ? (
                  currentPage > 1
                    ? (currentPage - 1) * multiplyFactor + 1
                    : 1
                ) : (
                  0
                )}{" "}
                -{" "}
                {/* Calculate ending record number */}
                {totalRecords > 0 ? (
                  currentPage === PageNumbers
                    ? totalRecords
                    : Math.min(currentPage * multiplyFactor, totalRecords)
                ) : (
                  0
                )}
              </span>
            </div>{" "}
            out of {totalRecords}
          </span>
        </div>
      </div>
      <div className=" col-md-6 col-xs-6 col-sm-6 col-lg-6">
        <ul className="pagination">
          {parseInt(currentPage) > 1 && (
            <li
              key={Math.random()}
              className="page-item previous-item"
              title="Go to previous"
              style={{ backgroundColor: currentPage > 1 && "#031f4b" }}
            >
              <span
                // href="#"
                onClick={() => {
                  props.setOtherLoader(true);
                  let searchParam = "";
                  let newPrefix = "";
                  if (
                    (props.dataType === "schedule" ||
                      props.dataType === "upcoming") &&
                    !urlParams.toString().includes(props.prefix)
                  ) {
                    urlParams.delete("table");
                    newPrefix = props.prefix;
                  } else if (
                    props.type === "Corporate" &&
                    (props.dataType === "community" ||
                      props.dataType === "user") &&
                    !urlParams.toString().includes(props.prefix)
                  ) {
                    urlParams.delete("tab_key");
                    newPrefix = props.prefix;
                  } else if (
                    props.type === "Community" &&
                    (props.dataType === "patient" ||
                      props.dataType === "user" ||
                      props.dataType === "iphone" ||
                      props.dataType === "billee" ||
                      props.dataType === "history" ||
                      props.dataType === "devices") &&
                    !urlParams.toString().includes(props.prefix)
                  ) {
                    urlParams.delete("tab_key");
                    newPrefix = props.prefix;
                  } else if (
                    props.type === "billing" &&
                    (props.dataType === "testToBill" ||
                      props.dataType === "pastInvoices" ||
                      props.dataType === "creditHistory") &&
                    !urlParams.toString().includes(props.prefix)
                  ) {
                    urlParams.delete("tab_key");
                    newPrefix = props.prefix;
                  } else if (
                    (props.type === "RegUser" || props.type === "AdminUser") &&
                    !urlParams.toString().includes(props.prefix)
                  ) {
                    urlParams.delete("tab_key");
                    newPrefix = props.prefix;
                  }
                  if (parseInt(currentPage) > 1) {
                    paginate(parseInt(currentPage) - 1);
                    urlParams.set("page", parseInt(currentPage) - 1);
                    searchParam = "?" + newPrefix + urlParams.toString();
                  } else {
                    paginate(parseInt(currentPage));
                    urlParams.set("page", parseInt(currentPage));
                    searchParam = "?" + newPrefix + urlParams.toString();
                  }
                  searchParam = searchParam.replace("&&", "&");
                  history.replace({
                    pathname: location.pathname,
                    search: searchParam,
                  });
                }}
                className="page-link"
              ></span>
            </li>
          )}
          {paginationRange &&
            paginationRange.length > 1 &&
            paginationRange.map((pageNumber) => {
              // If the pageItem is a DOT, render the DOTS unicode character
              if (pageNumber === DOTS) {
                return (
                  <li
                    key={Math.random()}
                    className="pagination-item pagination-item-dots"
                  >
                    &#8230;
                  </li>
                );
              }

              // Render our Page Pills
              return (
                <li
                  key={pageNumber}
                  className={
                    pageNumber === parseInt(currentPage)
                      ? "page-item active"
                      : "page-item"
                  }
                  // className={"page-item"}
                >
                  <span
                    onClick={() => {
                      props.setOtherLoader(true);
                      let searchParam = "";
                      let newPrefix = "";
                      if (
                        (props.dataType === "schedule" ||
                          props.dataType === "upcoming") &&
                        !urlParams.toString().includes(props.prefix)
                      ) {
                        urlParams.delete("table");
                        newPrefix = props.prefix;
                      } else if (
                        props.type === "Corporate" &&
                        (props.dataType === "community" ||
                          props.dataType === "user") &&
                        !urlParams.toString().includes(props.prefix)
                      ) {
                        urlParams.delete("tab_key");
                        newPrefix = props.prefix;
                      } else if (
                        props.type === "Community" &&
                        (props.dataType === "patient" ||
                          props.dataType === "user" ||
                          props.dataType === "iphone" ||
                          props.dataType === "billee" ||
                          props.dataType === "history" ||
                          props.dataType === "devices") &&
                        !urlParams.toString().includes(props.prefix)
                      ) {
                        urlParams.delete("tab_key");
                        newPrefix = props.prefix;
                      } else if (
                        props.type === "billing" &&
                        (props.dataType === "testToBill" ||
                          props.dataType === "pastInvoices" ||
                          props.dataType === "creditHistory") &&
                        !urlParams.toString().includes(props.prefix)
                      ) {
                        urlParams.delete("tab_key");
                        newPrefix = props.prefix;
                      } else if (
                        (props.type === "RegUser" ||
                          props.type === "AdminUser") &&
                        !urlParams.toString().includes(props.prefix)
                      ) {
                        urlParams.delete("tab_key");
                        newPrefix = props.prefix;
                      }
                      paginate(pageNumber);
                      urlParams.set("page", pageNumber);
                      searchParam = "?" + newPrefix + urlParams.toString();
                      searchParam = searchParam.replace("&&", "&");
                      history.replace({
                        pathname: location.pathname,
                        search: searchParam,
                      });
                    }}
                    // href="#"
                    className="page-link"
                  >
                    {pageNumber}
                  </span>
                </li>
              );
            })}
          {/*  Right Navigation arrow */}
          {parseInt(currentPage) < pageNumbersArray.length && (
            <li
              key={Math.random()}
              className="page-item next-item"
              title="Go to next"
              style={{
                backgroundColor:
                  parseInt(currentPage) < pageNumbersArray.length && "#031f4b",
              }}
            >
              <span
                // href="#"
                onClick={() => {
                  props.setOtherLoader(true);
                  let searchParam = "";
                  let newPrefix = "";
                  if (
                    (props.dataType === "schedule" ||
                      props.dataType === "upcoming") &&
                    !urlParams.toString().includes(props.prefix)
                  ) {
                    urlParams.delete("table");
                    newPrefix = props.prefix;
                  }else if(
                    (props.ype === "External Providers") &&
                    !urlParams.toString().includes(props.prefix)
                  ) {
                    urlParams.delete("table");
                    newPrefix = props.prefix;
                  } else if (
                    props.type === "Corporate" &&
                    (props.dataType === "community" ||
                      props.dataType === "user") &&
                    !urlParams.toString().includes(props.prefix)
                  ) {
                    urlParams.delete("tab_key");
                    newPrefix = props.prefix;
                  } else if (
                    props.type === "Community" &&
                    (props.dataType === "patient" ||
                      props.dataType === "user" ||
                      props.dataType === "iphone" ||
                      props.dataType === "billee" ||
                      props.dataType === "history" ||
                      props.dataType === "devices") &&
                    !urlParams.toString().includes(props.prefix)
                  ) {
                    urlParams.delete("tab_key");
                    newPrefix = props.prefix;
                  } else if (
                    props.type === "billing" &&
                    (props.dataType === "testToBill" ||
                      props.dataType === "pastInvoices" ||
                      props.dataType === "creditHistory") &&
                    !urlParams.toString().includes(props.prefix)
                  ) {
                    urlParams.delete("tab_key");
                    newPrefix = props.prefix;
                  } else if (
                    (props.type === "RegUser" || props.type === "AdminUser") &&
                    !urlParams.toString().includes(props.prefix)
                  ) {
                    urlParams.delete("tab_key");
                    newPrefix = props.prefix;
                  }

                  if (parseInt(currentPage) < pageNumbersArray.length) {
                    paginate(parseInt(currentPage) + 1);
                    urlParams.set("page", parseInt(currentPage) + 1);
                    searchParam = "?" + newPrefix + urlParams.toString();
                  } else {
                    paginate(parseInt(currentPage));
                    urlParams.set("page", parseInt(currentPage));
                    searchParam = "?" + newPrefix + urlParams.toString();
                  }

                  searchParam = searchParam.replace("&&", "&");
                  history.replace({
                    pathname: location.pathname,
                    search: searchParam,
                  });
                }}
                className="page-link "
              ></span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Pagination;
