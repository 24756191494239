import React, { Fragment, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { corporateActions } from "../../../store/corporate";
import { sidebarActions } from "../../../store/sidebar";
import { getWithExpiry } from "../../utils/helper";
import Edit from "../../../assets/edit.svg";
import Delete_Btn from "../../../assets/delete-btn.png";
import DisabledEdit from "../../../assets/disabled-edit.svg";
import Delete_Icon from "../../../assets/delete-btn.png";
import Save from "../../../assets/save.svg";
import Eye from "../../../assets/eye-icon.svg";
import Preview from "../../../assets/preview.svg";
import EnableIcon from "../../../assets/checkicon.svg";
import ResendIcon from "../../../assets/resend-icon.svg";
import DisabledResendIcon from "../../../assets/disabled-resend.svg";

import EditUserModal from "../Modals/EditUserModal/EditUserModal";

import { RegUserActions } from "../../../store/regUser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "./Table.scss";

const RowActions = (props) => {
  const {
    showDeleteModal,
    showDisableModal,
    showEnableModal,
    showEditUserModal,
    showEditModalHandler,
    recordId,
    showTestsModal,
    actionType,
    setId,
    isEnable,
    isDefault,
    name,
    type,
    dataType,
    delete_id,
    page,
    record,
    corporateId,
    showResendModal,
  } = props;

  const selectedRecordName = useSelector((state) => state.sidebar.recordName);

  const history = useHistory();
  const dispatch = useDispatch();
  const token = "Bearer " + getWithExpiry("expiry_token");
  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);

  const notifySuccesss = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const showModalAndHandleActionType = (type) => {
    setId(recordId);
    if (type === "delete") {
      showDeleteModal();
    } else if (type === "disable") {
      showDisableModal();
    }
  };

  const dispatchActionTypeDelete = () => {
    if (!isDefault) {
      showDeleteModal();
      dispatch(corporateActions.setActionType("delete"));
      if (delete_id) {
        setId(delete_id, "delete");
      } else {
        setId(recordId, "delete");
      }
    } else {
      notifyError("Default User Cannot be Deleted");
    }
  };

  const dispatchActionTypeDisable = () => {
    if (!isDefault) {
      showDisableModal();
      dispatch(corporateActions.setActionType("disable"));

      if (delete_id) {
        setId(delete_id, "disable");
      } else {
        setId(recordId, "disable");
      }
    } else {
      notifyError("Default User Cannot be Disabled");
    }
  };

  const dispatchActionTypeEnable = () => {
    showEnableModal();
    dispatch(corporateActions.setActionType("enable"));
    if (delete_id) {
      setId(delete_id, "enable");
    } else {
      setId(recordId, "enable");
    }
  };

  const disabledClassName = isEnable ? "" : "disabled-icon";
  const defaultClassName = isDefault ? "disabled-icon" : "";

  const setBreadcrumb = (name, path) => {
    const record = {
      name: name,
      path: path,
    };
    //dispatch(sidebarActions.setCrumbs(record));
  };

  const handleEditAction = (e) => {
    if (type === "Corporate" && dataType === "community") {
      // history.replace(`/edit/communities/${recordId}`);
      // setBreadcrumb(name, `/edit/communities/${recordId}`);
      showEditModalHandler();
      setId(recordId, "edit");
    } else if (type === "Corporate" && dataType === undefined) {
      showEditModalHandler();
      setId(recordId, "edit");
    }

    // else if (type === "Corporate" && dataType === undefined) {
    //   // workaround for new link with userid
    //   history.replace(`/edit/corporates/${recordId}`);

    //   setBreadcrumb(name, `/edit/corporates/${recordId}`);
    // }

    dispatch(RegUserActions.setIdToBeEdited(delete_id));

    if (page === "sub-page") {
      if (record.data.is_logged_in_user) {
        notifyError("Edit your details from the profile section");
      } else {
        let model;
        if (type === "Corporate") {
          model = "/corporate/update_corporate_user/";
        }

        axios({
          url: `${process.env.REACT_APP_URL_PATH}${model}${delete_id}`,
          method: "GET",
          headers: {
            "Content-Type": "Application/json",
            Authorization: token,
          },
        })
          .then((response) => {
            dispatch(RegUserActions.setRegUserInfo(response.data.data.user));
          })
          .catch((error) => {
          });
        showEditUserModal();
      }
    }
  };

  const handleDigAction = () => {
    if (type === "Corporate" && dataType === "community") {
      history.push(`/edit/corporates/${corporateId}/community/${recordId}`);
    } else if (type === "Corporate" && dataType === undefined) {
      history.push(`/edit/corporates/${recordId}`);
    }
  };

  const resendEmail = () => {
    if (record.data.is_logged_in_user) {
      notifyError("You cannot send email to yourself");
    } else {
      showResendModal();
      setId(recordId, "resend");
    }
  };
  return (
    <Fragment>
      <td>
        {dataType !== "user" && (
          <span className="td-action" title="Details" onClick={handleDigAction}>
            <img src={Eye} alt="" />
          </span>
        )}
        <span className="td-action" title="Edit" onClick={handleEditAction}>
          <img
            src={
              dataType === "user"
                ? record.data.is_logged_in_user
                  ? DisabledEdit
                  : Edit
                : Edit
            }
            alt=""
          />
        </span>

        {/* <span onClick={dispatchActionTypeDelete}><img src={Delete_Btn} style={{width:"20px",height:"20px", cursor:"pointer"}}/></span> */}
        {dataType === "user" && (
          <span
            title="Resend Email"
            className="td-action"
            // className={`td-action ${disabledClassName}${defaultClassName}`}
            onClick={resendEmail}
          >
            <img
              src={
                record.data.is_logged_in_user ? DisabledResendIcon : ResendIcon
              }
              alt="edit-icon"
            />
          </span>
        )}

        {type === "Corporate" &&
        dataType !== "community" &&
        dataType !== "user" ? (
          <>
            {!isCatchUAdmin && userRole.includes("Super Admin") ? (
              <span
                className="td-action"
                title="Delete"
                // className={`td-action ${disabledClassName}${defaultClassName}`}
                onClick={dispatchActionTypeDelete}
              >
                <img src={Delete_Btn} style={{width:"20px",height:"20px", cursor:"pointer"}} alt="delete-icon" />
              </span>
            ) : (
              <>
                {isCatchUAdmin &&
                  (userRole.includes("Super Admin") ||
                    permissions.includes("Corporate")) && (
                    <span
                      className="td-action"
                      title="Delete"
                      // className={`td-action ${disabledClassName}${defaultClassName}`}
                      onClick={dispatchActionTypeDelete}
                    >
                      <img src={Delete_Btn} style={{width:"20px",height:"20px", cursor:"pointer"}} alt="delete-icon" />
                    </span>
                  )}
              </>
            )}
          </>
        ) : (
          <span
            className="td-action"
            title="Delete"
            // className={`td-action ${disabledClassName}${defaultClassName}`}
            onClick={dispatchActionTypeDelete}
          >
            <img src={Delete_Icon} alt="delete-icon" style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}/>
          </span>
        )}

        {/* uncomment the following to get the enable/disable functionality */}
        {/* {isEnable && (
          <span
            title="Disable"
            className="td-action"
            // className={`td-action ${disabledClassName}${defaultClassName}`}
            onClick={dispatchActionTypeDisable}
          >
            <img src={Save} alt="disable-icon" />
          </span>
        )}
        {!isEnable && (
          <span
            title="Enable"
            className="td-action"
            onClick={dispatchActionTypeEnable}
          >
            <img src={EnableIcon} alt="enable-icon" />
          </span>
        )} */}
      </td>
    </Fragment>
  );
};

export default RowActions;
