import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./AddIphoneModal.scss";
import useInput from "../../../../hooks/use-input";
import { useDispatch } from "react-redux";
import { communityActions } from "../../../../store/community";
import CloseModal from "../../../../assets/close-outline.svg";
import Input from "react-phone-number-input/input";
import { getWithExpiry } from "../../../utils/helper";
import Loader2 from "../../../UI/loader2/loader2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const AddIphoneModal = (props) => {

  const token = "Bearer " + getWithExpiry("expiry_token");
  const { isShowModal, hideModal, type, setDataFlag, setShowToast } = props;
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const { communityID: cummunityId } = params;
  const [PhoneNumberError, setPhoneNumberError] = useState("");
  const [PhoneDescError, setPhoneDescError] = useState("");

  //State for Component
  const [phoneValue, setPhoneValue] = useState("");
  const [description, setDescription] = useState("");
  // End State for Component

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });


  const addIphoneSubmissionHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    setDisabledBtn(true);

    setPhoneNumberError("");
    setPhoneDescError("");
    const fd = new FormData();
    fd.append("description", description);

    //let phoneVal = phoneValue.replace(/\s+/g, "");
    if (phoneValue) {
      fd.append("number", "+" + phoneValue);
    }

    fd.append("community_id", cummunityId);
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/test/add_iphone/`,
      method: "post",
      data: fd,
      headers: {
        "Content-type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.message === "Field Errors") {
          if (
            error.response.data.data["iphone_form"] &&
            "number" in error.response.data.data["iphone_form"]
          ) {
            setPhoneNumberError(
              error.response.data.data["iphone_form"]["number"][0]
            );
          }
          if (
            error.response.data.data["iphone_form"] &&
            "description" in error.response.data.data["iphone_form"]
          ) {
            setPhoneDescError(
              error.response.data.data["iphone_form"]["description"][0]
            );
          }
        } else {
          notifyError(error.response.data.message);
        }
        setDisabledBtn(false);
      });
  };

  return (
    <div className="filter-modal-wrapper ">
      <Modal
        className="filter-modal modal-loader"
        show={true}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
      >
        <Modal.Header className="filter-modal-header">
          <h1 className="modal-header-title">Add iPhone</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="filter-modal-body">
          <form
           
            method="post"
            className="add-corporate-form"
            onSubmit={addIphoneSubmissionHandler}
          >
            <div className="field-wrapper">
              <label htmlFor="iphone_number" className="label">
                iPhone Number *
              </label>
              {/* <input
                type="text"
                id="number"
                name="number"
                className="custom-input"
                placeholder="iPhone Number"
                onChange={(e) => {
                  const { name, value } = e.target;

                  const regex = /^([\s+0-9])+$/i;
                  if (value === "") {
                    // case to remove whole highlighted text
                    setPhoneValue(value);
                    return;
                  }
                  if (regex.test(value) === false) return;

                  if (value.length === 1 && value === "+") {
                    // case to remove + sign when last number is removed
                    setPhoneValue("");
                  } else if (value.length >= 1 && value[0] !== "+") {
                    // case to append + sign with first number
                    setPhoneValue("+" + value);
                  } else {
                    // case to append other numbers
                    setPhoneValue(value);
                  }
                }}
                value={phoneValue}
                required
              /> */}
              <PhoneInput
                country={"us"}
                value={phoneValue}
                onChange={setPhoneValue}
                inputProps={{
                  required: true,
                }}
              />
              {PhoneNumberError && (
                <p className="error-text">{PhoneNumberError}</p>
              )}
            </div>

            <div className="field-wrapper">
              <label htmlFor="first_name" className="label">
                Description
              </label>
              {/* <input
                type="text"
                name="description"
                id="description"
                className="custom-input-iphone-desc"
                placeholder="Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                required
              /> */}
              <textarea
                className="custom-text-area"
                rows="3"
                type="text"
                name="description"
                id="description"
                placeholder="Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              ></textarea>
              {/* {PhoneDescError && <p className="error-text">{PhoneDescError}</p>} */}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            id="modal-close-btn"
            className="btn btn-secondary-fill btn-primary-16"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            // type="submit"
            onClick={addIphoneSubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={disabledBtn}
          >
            Add
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
};

export default AddIphoneModal;
