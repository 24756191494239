import React from 'react';
import { useEffect, useState } from 'react';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getWithExpiry } from '../utils/helper';
import TableComponent from '../../components/UI/Table/TableData';
import { externalProvidersActions } from '../../store/externalProviders';
import Loader from '../UI/loader/loader';
import { sidebarActions } from '../../store/sidebar';
import useQueryString from '../../hooks/useQueryString';

const ExternalProviders = () => {
    const dispatch = useDispatch();
    const urlParams = useQueryString();
    const token = "Bearer " + getWithExpiry("expiry_token");
    const [otherLoader, setOtherLoader] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const externalProvidersCurrentPage = useSelector(
        (state) => state.externalProviders.externalProvidersCurrentPage
    );
    const externalProvidersList = useSelector(
        (state) => state.externalProviders.externalProvidersList
    );
    const externalProvidersApiUrl = useSelector(
        (state) => state.externalProviders.externalProvidersApiUrl
    );
    const externalProvidersTotalRecords = useSelector(
        (state) => state.externalProviders.externalProvidersTotalRecords
    );
    const externalProviderMapping = [
        "Provider Status Code",
        "First Name",
        "Last Name",
        "Email"
    ];
    const [dataFlag, setDataFlag] = useState(false);
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        if (!showToast) {
            setLoading1(true);
            dispatch(sidebarActions.setSelectedNav("External Providers"));
        }
        let urlKeys = [...urlParams.keys()];
        let urlValues = [...urlParams.values()];
        let query = "";
        if (!externalProvidersApiUrl && urlKeys.length > 0) {
            query = "?";
            if (urlValues.includes("individualproviders_list")) {
                for (let i = 0; i < urlKeys.length; i++) {
                    if (urlKeys[i] !== "page") {
                        query = query + urlKeys[i] + "=" + urlValues[i] + "&";
                    }
                }
            }
        } else {
            query = externalProvidersApiUrl ? externalProvidersApiUrl + "&" : "?";
        }

        let pageNumber = 1;
        if (urlValues.includes("individualproviders_list")) {
            pageNumber = urlParams.has("page")
                ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
                    ? urlParams.get("page")
                    : externalProvidersCurrentPage
                : externalProvidersCurrentPage;
        }
        query = query.replace("&&", "&");

        axios({
            url: `${process.env.REACT_APP_URL_PATH}/user/individualproviders_list/${query}page=${externalProvidersCurrentPage}`,
            method: "GET",
            headers: {
                "Content-Type": "Application/json",
                Authorization: token,
            },
        })
            .then((response) => {
                const resData = response.data.data.providers;
                dispatch(externalProvidersActions.setExternalProvidersList(resData));
                dispatch(
                    externalProvidersActions.setExternalProvidersPageNumbers(response.data.data.provider_pages)
                );
                dispatch(
                    externalProvidersActions.setExternalProvidersTotalRecords(
                        response.data.data.total_records
                    )
                );
                if (externalProvidersCurrentPage > response.data.data.provider_pages) {
                    dispatch(
                        externalProvidersActions.setExternalProvidersCurrentPage(
                            response.data.data.provider_pages
                        )
                    );
                }
                setLoading1(false);
                setOtherLoader(false)
            })
            .catch((error) => {
                setLoading1(false);
                setOtherLoader(false)
            });
    }, [dataFlag]);

    return (

        <div>
            {loading1 ? (
                <Loader />
            ) : (
                <TableComponent
                    tableHeadings={externalProviderMapping}
                    data={externalProvidersList}
                    totalRecords={externalProvidersTotalRecords}
                    type="External Providers"
                    setDataFlag={setDataFlag}
                    setShowToast={setShowToast}
                    heading="External Providers"
                    otherLoader={otherLoader}
                    setOtherLoader={setOtherLoader}
                />
            )}
        </div>
    );
};

export default ExternalProviders;