import React, { useState, useEffect, Fragment } from "react";
import "./billing.scss";
// import TableComponent from "../../UI/Table/TableData";
import { Tab, Table, Tabs } from "react-bootstrap";
import TableDataEdit from "./Billing.TableDataEdit";
import Select, { components } from "react-select";
import axios from "axios";
import Loader from "../UI/loader/loader";
import { sidebarActions } from "../../store/sidebar";
import { filterActions } from "../../store/filter";
import { billingActions } from "../../store/billing";
import { useSelector, useDispatch } from "react-redux";
import { getWithExpiry } from "../utils/helper";
import ApplyCredit from "./Modal/Billing.ApplyCredit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useQueryString from "../../hooks/useQueryString";
import AuditLogButton from "../General/AuditLogModal/AuditLogButton.Component";
import AuditLogsModal from "../General/AuditLogModal/AuditLogsModal.Component";
import { formatCurrency } from "../../utils/helper";

function Billing() {
  const testToBillTableHeadings = [
    "Test ID",
    "Community Name",
    "Billee Name",
    "Billee Company",
    "Test Date",
    "Patient Name",
    "Date of Birth",
    "Test Price",
  ];

  const pastInvoicesTableHeadings = [
    "Invoice #",
    // "Invoice Date",
    "Invoice Due Date",
    "Community Name",
    "Billee Name",
    "Billee Company",
    // "Billee Address",
    "Invoice Status",
    "Amount Due",
    "View",
  ];

  const creditHistoryTableHeadings = [
    "Test ID",
    "Community Name",
    "Billee Name",
    "Credit Reason",
    // "Credit Type",
    "Credit Amount",
    "Credit Date",
    "Person issuing Credit",
    "Invoice #"
  ];
  const equipmentsTableHeadings = [
    "Community ID",
    "Community Name",
    "Billee Name",
    "Billee Company",
    "Equipment ID",
    "Equipment Price",

  ];

  const dispatch = useDispatch();
  const urlParams = useQueryString();

  const [billingData, setBillingData] = useState([]);
  const [testBillingData, setTestBillingData] = useState([]);
  const [invoiceBbillingData, setInvoiceBillingData] = useState([]);
  const [key, setKey] = useState("testToBill");
  const [key2, setKey2] = useState("testToBill");

  const [testsToBillList, setTestsToBillList] = useState([]);
  const [allPatients, setAllPatients] = useState([]);


  const [testsToBillRecords, settTestsToBillRecords] = useState(0);
  const [testsToBillPages, settTestsToBillPages] = useState(0);

  const [invoiceList, setInvoiceList] = useState([]);
  const [pastInvoicesRecords, setPasInvoicesRecords] = useState(0);
  const [pastInvoicesPages, setPasInvoicesPages] = useState(0);

  const [loading, setLoading] = useState(true);
  const [otherLoader, setOtherLoader] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [dataFlag, setDataFlag] = useState(false);

  const [loading1, setLoading1] = useState(true);
  const [otherLoader1, setOtherLoader1] = useState(false);
  const [otherLoader2, setOtherLoader2] = useState(false);
  const [otherLoader3, setOtherLoader3] = useState(false);
  const [dataFlag1, setDataFlag1] = useState(false);
  const [dataFlag2, setDataFlag2] = useState(false);
  const [dataFlag3, setDataFlag3] = useState(false);

  const [auditLogModalOpen, setAuditLogModalOpen] = useState(false);
  const showAuditLogModal = () => setAuditLogModalOpen(true);
  const hideAuditLogModal = () => setAuditLogModalOpen(false);

  const [applyCreditModalOpen, setApplyCreditModalOpen] = useState(false);
  const showApplyCreditModal = () => setApplyCreditModalOpen(true);
  const hideApplyCreditModal = () => setApplyCreditModalOpen(false);

  //
  const testBilltotalRecords = useSelector(
    (state) => state.billing.testBilltotalRecords
  );

  const pastInvoicetotalRecords = useSelector(
    (state) => state.billing.pastInvoicetotalRecords
  );
  const creditHistorytotalRecords = useSelector(
    (state) => state.billing.creditHistorytotalRecords
  );

  const testBillCurrentPage = useSelector(
    (state) => state.billing.testBillCurrentPage
  );
  const pastInvoiceCurrentPage = useSelector(
    (state) => state.billing.pastInvoiceCurrentPage
  );
  const creditHistoryCurrentPage = useSelector(
    (state) => state.billing.creditHistoryCurrentPage
  );

  const testToBillList = useSelector((state) => state.billing.testToBillList);
  const pastInvoiceList = useSelector((state) => state.billing.pastInvoiceList);
  const creditHistoryList = useSelector(
    (state) => state.billing.creditHistoryList
  );

  const {equipment_to_bill_list, equipment_to_bill_total_records} = useSelector((state) => state.billing.equipments);

  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);
  //
  const handleSelectCommunity = async (event) => {
    let values =
      event &&
      event.map((community) => {
        return community.value;
      });
    setCommunityIds(values);
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/billing/get_patients/?community_ids=${values}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const resData = response.data.data;
        setAllPatients(resData.patients);
      })
      .catch((error) => {
      });
  };

  const token = getWithExpiry("expiry_token");

  const [communityIds, setCommunityIds] = useState("ALL");
  const [invoiceStatus, setInvoiceStatus] = useState("ALL");
  const [patientsIds, setPatientsIds] = useState("ALL");

  const [applyCreditData, setApplyCreditData] = useState(null);

  const testsBillingApiUrl = useSelector(
    (state) => state.billing.communityCorporateApiUrl
  );
  const invoicesBillingApiUrl = useSelector(
    (state) => state.billing.invoicesBillingApiUrl
  );
  const creditHistoryApiUrl = useSelector(
    (state) => state.billing.creditHistoryApiUrl
  );

  const filterBillingCommunities = useSelector(
    (state) => state.filter.filterBillingCommunities
  );
  const filterBillingStatus = useSelector(
    (state) => state.filter.filterBillingStatus
  );

  function checkPermission() {
    if (isCatchUAdmin || userRole.includes("Super Admin")) {
      return true;
    }
    return false;
  }
  useEffect(() => {
    if (checkPermission()) {
      if (!showToast) {
        //setLoading(true);
        dispatch(sidebarActions.setSelectedNav("Billing"));
      }

      let urlKeys = [...urlParams.keys()];
      let urlValues = [...urlParams.values()];

      let query = "";
      if (!creditHistoryApiUrl && urlKeys.length > 0) {
        query = "?";
        //setKey2("creditHistory");
        if (urlValues.includes("credit_history")) {
          for (let i = 0; i < urlKeys.length; i++) {
            if (urlKeys[i] !== "page") {
              query = query + urlKeys[i] + "=" + urlValues[i] + "&";
            }
          }
        }
      } else {
        query = creditHistoryApiUrl ? creditHistoryApiUrl + "&" : "?";
      }

      let pageNumber = 1;
      if (urlValues.includes("credit_history")) {
        pageNumber = urlParams.has("page")
          ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
            ? urlParams.get("page")
            : creditHistoryCurrentPage
          : creditHistoryCurrentPage;
      }

      const Url = `${process.env.REACT_APP_URL_PATH}/billing/get_credit_history/${query}credit_history_page=${pageNumber}&community_ids=${filterBillingCommunities}`;

      query = query.replace("&&", "&");
      axios({
        url: `${process.env.REACT_APP_URL_PATH}/billing/get_credit_history/${query}credit_history_page=${pageNumber}&community_ids=${filterBillingCommunities}`,
        method: "GET",
        headers: {
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          const resData = response.data.data;
          dispatch(billingActions.setCreditHistoryList(resData.credit_history));

          dispatch(
            billingActions.setCreditHistoryPageNumber(
              resData.credit_history_pages
            )
          );
          dispatch(
            billingActions.setCreditHistorytotalRecords(
              resData.credit_history_total_records
            )
          );
         
          setOtherLoader1(false);
        })
        .catch((error) => {
        });
    }
  }, [dataFlag1]);

  useEffect(() => {
    if (!showToast) {
      dispatch(sidebarActions.setSelectedNav("Billing"));
    }

    let urlKeys = [...urlParams.keys()];
    let urlValues = [...urlParams.values()];

    let query = "";
    if (!testsBillingApiUrl && urlKeys.length > 0) {
      query = "?";
      //setKey2("creditHistory");
      if (urlValues.includes("test")) {
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      }
    } else {
      query = testsBillingApiUrl ? testsBillingApiUrl + "&" : "?";
    }

    let pageNumber = 1;
    if (urlValues.includes("test")) {
      pageNumber = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? urlParams.get("page")
          : testBillCurrentPage
        : testBillCurrentPage;
    }

    const Url = `${process.env.REACT_APP_URL_PATH}/billing/get_billing_data/${query}test_to_bill_page=${pageNumber}&community_ids=${filterBillingCommunities}&patient_ids=${patientsIds}`;

    query = query.replace("&&", "&");
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/billing/get_billing_data/${query}test_to_bill_page=${pageNumber}&community_ids=${filterBillingCommunities}&patient_ids=${patientsIds}`,
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resp) => {
        setTestBillingData(resp.data.data);
        setTestsToBillList(resp.data.data.tests_to_bill_list);
        // setAllPatients(resp.data.data.all_patients);
        settTestsToBillRecords(resp.data.data.test_to_bill_total_records);
        settTestsToBillPages(resp.data.data.past_invoices_pages);

        dispatch(
          billingActions.setTestToBillList(resp.data.data.tests_to_bill_list)
        );

        dispatch(
          billingActions.setTestBillTotalRecords(
            resp.data.data.test_to_bill_total_records
          )
        );
        dispatch(
          billingActions.setTestBillPageNumber(
            resp.data.data.test_to_bill_pages
          )
        );

        setLoading(false);
        setOtherLoader2(false);
      })
      .catch((error) => {
        setLoading(false);
        setOtherLoader2(false);
      });
  }, [dataFlag2]);

  useEffect(() => {
    if (!showToast) {
      //setLoading(true);
      dispatch(sidebarActions.setSelectedNav("Billing"));
    }

    let urlKeys = [...urlParams.keys()];
    let urlValues = [...urlParams.values()];

    let query = "";
    if (!invoicesBillingApiUrl && urlKeys.length > 0) {
      query = "?";
      //setKey2("creditHistory");
      if (urlValues.includes("invoice")) {
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      }
    } else {
      query = invoicesBillingApiUrl ? invoicesBillingApiUrl + "&" : "?";
    }

    let pageNumber = 1;
    if (urlValues.includes("test")) {
      pageNumber = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? urlParams.get("page")
          : pastInvoiceCurrentPage
        : pastInvoiceCurrentPage;
    }

    const Url = `${process.env.REACT_APP_URL_PATH}/billing/get_past_invoices/${query}community_ids=${filterBillingCommunities}&patient_ids=${patientsIds}&invoice_status=${filterBillingStatus}&past_invoices_page=${pageNumber}`;

    query = query.replace("&&", "&");

    axios({
      url: `${process.env.REACT_APP_URL_PATH}/billing/get_past_invoices/${query}community_ids=${filterBillingCommunities}&patient_ids=${patientsIds}&invoice_status=${filterBillingStatus}&past_invoices_page=${pageNumber}`,
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resp) => {


        setInvoiceBillingData(resp.data.data);
        setInvoiceList(resp.data.data.past_invoices || []);
        setPasInvoicesRecords(resp.data.data.past_invoices_total_records);
        setPasInvoicesPages(resp.data.data.past_invoices_pages);
        dispatch(
          billingActions.setPastInvoiceList(resp.data.data.past_invoices)
        );

        dispatch(
          billingActions.setPastInvoiceTotalRecords(
            resp.data.data.past_invoices_total_records
          )
        );
        dispatch(
          billingActions.setPastInvoicePageNumber(
            resp.data.data.past_invoices_pages
          )
        );
        //setLoading(false);
        setOtherLoader3(false);
      })
      .catch((error) => {
        //setLoading(false);
        setOtherLoader3(false);
      });
  }, [dataFlag3]);

  // const fetchBillings = async () => {
  //   let urlKeys = [...urlParams.keys()];
  //   let urlValues = [...urlParams.values()];

  //   let keyCheck = checkPermission();
  //   let Url = "";
  //   let query = "";
  //   let pageNumber = 1;
  //   if (urlValues.includes("test")) {
  //     if (!testsBillingApiUrl && urlKeys.length > 0) {
  //       query = "?";
  //       // if (keyCheck === true) {
  //       //   setKey2("testToBill");
  //       // } else {
  //       //   setKey("testToBill");
  //       // }
  //       for (let i = 0; i < urlKeys.length; i++) {
  //         if (urlKeys[i] !== "page") {
  //           query = query + urlKeys[i] + "=" + urlValues[i] + "&";
  //         }
  //       }
  //     } else {
  //       query = testsBillingApiUrl ? testsBillingApiUrl + "&" : "?";
  //     }

  //     query = query.replace("&&", "&");

  //     pageNumber = urlParams.has("page")
  //       ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
  //         ? urlParams.get("page")
  //         : testBillCurrentPage
  //       : testBillCurrentPage;

  //     Url = `${
  //       process.env.REACT_APP_URL_PATH
  //     }/billing/get_billing_information/${query}test_to_bill_page=${pageNumber}&community_ids=${filterBillingCommunities}&patient_ids=${patientsIds}&invoice_status=${filterBillingStatus}&past_invoices_page=${1}`;
  //   } else if (urlValues.includes("invoice")) {
  //     if (!invoicesBillingApiUrl && urlKeys.length > 0) {
  //       query = "?";
  //       // if (keyCheck === true) {
  //       //   setKey2("pastInvoice");
  //       // } else {
  //       //   setKey("pastInvoice");
  //       // }

  //       for (let i = 0; i < urlKeys.length; i++) {
  //         if (urlKeys[i] !== "page") {
  //           query = query + urlKeys[i] + "=" + urlValues[i] + "&";
  //         }
  //       }
  //     } else {
  //       query = invoicesBillingApiUrl ? invoicesBillingApiUrl + "&" : "?";
  //     }

  //     pageNumber = urlParams.has("page")
  //       ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
  //         ? urlParams.get("page")
  //         : pastInvoiceCurrentPage
  //       : pastInvoiceCurrentPage;

  //     Url = `${
  //       process.env.REACT_APP_URL_PATH
  //     }/billing/get_billing_information/${query}test_to_bill_page=${1}&community_ids=${filterBillingCommunities}&patient_ids=${patientsIds}&invoice_status=${filterBillingStatus}&past_invoices_page=${pageNumber}`;
  //   } else {
  //     Url = `${
  //       process.env.REACT_APP_URL_PATH
  //     }/billing/get_billing_information/?test_to_bill_page=${1}&community_ids=${filterBillingCommunities}&patient_ids=${patientsIds}&invoice_status=${filterBillingStatus}&past_invoices_page=${1}`;
  //   }

  //   try {
  //     const resp = await axios({
  //       method: "get",
  //       url: Url,
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     setBillingData(resp.data.data);
  //     setTestsToBillList(resp.data.data.tests_to_bill_list);
  //     //setAllPatients(resp.data.data.all_patients);
  //     settTestsToBillRecords(resp.data.data.test_to_bill_total_records);
  //     settTestsToBillPages(resp.data.data.past_invoices_pages);

  //     setInvoiceList(billingData.past_invoices || []);
  //     setPasInvoicesRecords(resp.data.data.past_invoices_total_records);
  //     setPasInvoicesPages(resp.data.data.past_invoices_pages);

  //     dispatch(
  //       billingActions.setTestToBillList(resp.data.data.tests_to_bill_list)
  //     );

  //     dispatch(billingActions.setPastInvoiceList(resp.data.data.past_invoices));

  //     dispatch(
  //       billingActions.setTestBillTotalRecords(
  //         resp.data.data.test_to_bill_total_records
  //       )
  //     );
  //     dispatch(
  //       billingActions.setTestBillPageNumber(resp.data.data.test_to_bill_pages)
  //     );

  //     dispatch(
  //       billingActions.setPastInvoiceTotalRecords(
  //         resp.data.data.past_invoices_total_records
  //       )
  //     );
  //     dispatch(
  //       billingActions.setPastInvoicePageNumber(
  //         resp.data.data.past_invoices_pages
  //       )
  //     );
  //     setLoading(false);
  //     setOtherLoader(false);
  //   } catch (error) {
  //     setLoading(false);
  //     setOtherLoader(false);
  //   }
  // };

  // useEffect(() => {
  //   if (!showToast) {
  //     setLoading(true);
  //     dispatch(sidebarActions.setSelectedNav("Billing"));
  //   }
  //   //fetchBillings();
  // }, [dataFlag]);
  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/billing/get_patients/?community_ids=ALL`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const resData = response.data.data;
        setAllPatients(resData.patients);
      })
      .catch((error) => {
      });
  }, []);
  

  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/billing/get_billing_data_equipment/?community_ids=ALL`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const resData = response.data.data;
        dispatch(billingActions.setEquipmentsList(resData));
      })
      .catch((error) => {
      });
  }, []);

  // const billingStatusFilters = useSelector(
  //   (state) => state.filter.filterBillingStatus
  // );
  // const communityFilters = useSelector(
  //   (state) => state.filter.filterCommunities
  // );
  // const patientsFilters = useSelector((state) => state.filter.filterPatients);

  const handleFilterClick = async () => {
    dispatch(billingActions.setTestBillCurrentPage(1));
    dispatch(billingActions.setPastInvoiceCurrentPage(1));
    dispatch(billingActions.setCreditHistoryCurrentPage(1));

    dispatch(filterActions.setFilterCommunities(communityIds));
    dispatch(filterActions.setFilterPatients(patientsIds));
    dispatch(filterActions.setFilterBillingStatus(invoiceStatus));
    // await fetchBillings();
    setDataFlag((prevState) => !prevState);
    setDataFlag1((prevState) => !prevState);
  };

  const handleApplyCreditClickAction = () => {
    showApplyCreditModal();
  };


  return (
    <Fragment>
      {auditLogModalOpen && (
        <AuditLogsModal
          isShowModal={auditLogModalOpen}
          hideModal={hideAuditLogModal}
          LogTitle={"Audit Log"}
          type={"Billing"}
          dataType={""}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          refId={0}
        />
      )}
      {applyCreditModalOpen && (
        <ApplyCredit
          isShowModal={applyCreditModalOpen}
          hideModal={hideApplyCreditModal}
          type="Billing"
          billeeList={applyCreditData}
          setDataFlag={setDataFlag1}
          setShowToast={setShowToast}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <div className="corp-d-wrapper">
          <div className="corp-d-header-content">
            <div className="second-cdh-content">
              <div className="cdh-first-top-row">
                <ul className="second-inner-row-content cdh-left align-start">
                  <li className="inner-s-single">
                    <h3 className="iss-heading billing-heading">
                      Amount to be Billed
                    </h3>
                    <span className="iss-des billing-des">
                      $ {formatCurrency(testBillingData.amount_to_be_billed)}
                    </span>
                  </li>
                  {/* <li className="inner-s-single ">
                    <h3 className="iss-heading billing-heading">
                      Tests to be Billed
                    </h3>
                    <span className="iss-des billing-des">
                      {testBillingData.tests_to_be_billed}
                    </span>
                  </li> */}
                </ul>
                <ul className="cdh-right">
                  <li className="cdh-inner-single">
                    <AuditLogButton handleClick={showAuditLogModal} />
                  </li>
                  {(isCatchUAdmin || userRole.includes("Super Admin")) && (
                    <li className="cdh-inner-single">
                      <button
                        className="btn btn-primary-outline"
                        onClick={handleApplyCreditClickAction}
                      >
                        Apply General Credit
                      </button>
                    </li>
                  )}
                  {/* <li className="cdh-inner-single">
                    <button
                      className="btn btn-primary-fill btn-primary-16"
                      onClick={handleFilterClick}
                    >
                      Filter
                    </button>
                  </li>
                  <li className="cdh-inner-single">
                    <div>
                      <Select
                        name="communityId"
                        id="communityId"
                        placeholder={"Select Community"}
                        // className="custom-select-modal"
                        onChange={handleSelectCommunity}
                        isMulti
                        //value={role}
                        options={
                          billingData &&
                          billingData.all_communities &&
                          billingData.all_communities.map((community) => ({
                            value: community.community_id,
                            label: community.community_name,
                          }))
                        }
                        closeMenuOnSelect={false}
                        components={{ MultiValue }}
                      />
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>

          {/* <TableComponent /> */}
          {isCatchUAdmin || userRole.includes("Super Admin") ? (
            <Tabs
              id="controlled-tab-example"
              activeKey={key2}
              onSelect={(k) => setKey2(k)}
              className="mb-3"
            >
              <Tab eventKey="testToBill" title="Test Billings">
                <TableDataEdit
                  tableHeadings={testToBillTableHeadings}
                  data={testToBillList}
                  allPatients={allPatients}
                  setPatientsIds={setPatientsIds}
                  totalRecords={testBilltotalRecords}
                  type="billing"
                  dataType="testToBill"
                  heading={"Tests To Bill"}
                  isImport={false}
                  isAdd={false}
                  billingData={testBillingData}
                  // communityId={communityId}
                  communityName={"communityName"}
                  setDataFlag={setDataFlag2}
                  setShowToast={setShowToast}
                  TabKey={key2}
                  otherLoader={otherLoader2}
                  setOtherLoader={setOtherLoader2}
                />
              </Tab>

              <Tab eventKey="creditHistory" title="Credits">
                <TableDataEdit
                  tableHeadings={creditHistoryTableHeadings}
                  billingData={testBillingData}
                  // data={billingData.past_invoices ? billingData.past_invoices : []}
                  data={creditHistoryList}
                  // setInvoiceStatus={setInvoiceStatus}
                  totalRecords={creditHistorytotalRecords}
                  // allInvoices={billingData?.all_invoice_statuses}
                  type="billing"
                  dataType="creditHistory"
                  heading={"Credit History"}
                  isImport={false}
                  isAdd={false}
                  communityName={"Community Name"}
                  setDataFlag={setDataFlag1}
                  setShowToast={setShowToast}
                  TabKey={key2}
                  otherLoader={otherLoader1}
                  setOtherLoader={setOtherLoader1}
                />
              </Tab>

              <Tab eventKey="pastInvoice" title="Invoices">
                <TableDataEdit
                  tableHeadings={pastInvoicesTableHeadings}
                  billingData={invoiceBbillingData}
                  // data={billingData.past_invoices ? billingData.past_invoices : []}
                  data={pastInvoiceList}
                  setInvoiceStatus={setInvoiceStatus}
                  totalRecords={pastInvoicetotalRecords}
                  allInvoices={invoiceBbillingData?.all_invoice_statuses}
                  type="billing"
                  dataType="pastInvoices"
                  heading={"Past Invoices"}
                  isImport={false}
                  isAdd={false}
                  // communityId={communityId}
                  communityName={"Community Name"}
                  setDataFlag={setDataFlag3}
                  setShowToast={setShowToast}
                  TabKey={key2}
                  otherLoader={otherLoader3}
                  setOtherLoader={setOtherLoader3}
                />
              </Tab>
             
            </Tabs>
          ) : (
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="testToBill" title="Tests to Bill">
                <TableDataEdit
                  tableHeadings={testToBillTableHeadings}
                  billingData={testBillingData}
                  data={testToBillList}
                  allPatients={allPatients}
                  setPatientsIds={setPatientsIds}
                  totalRecords={testBilltotalRecords}
                  type="billing"
                  dataType="testToBill"
                  heading={"Tests To Bill"}
                  isImport={false}
                  isAdd={false}
                  // communityId={communityId}
                  communityName={"communityName"}
                  setDataFlag={setDataFlag2}
                  setShowToast={setShowToast}
                  TabKey={key}
                  otherLoader={otherLoader2}
                  setOtherLoader={setOtherLoader2}
                />
              </Tab>

              <Tab eventKey="pastInvoice" title="Past Invoices">
                <TableDataEdit
                  tableHeadings={pastInvoicesTableHeadings}
                  // data={billingData.past_invoices ? billingData.past_invoices : []}
                  data={pastInvoiceList}
                  billingData={invoiceBbillingData}
                  setInvoiceStatus={setInvoiceStatus}
                  totalRecords={pastInvoicetotalRecords}
                  allInvoices={invoiceBbillingData?.all_invoice_statuses}
                  type="billing"
                  dataType="pastInvoices"
                  heading={"Past Invoices"}
                  isImport={false}
                  isAdd={false}
                  // communityId={communityId}
                  communityName={"Community Name"}
                  setDataFlag={setDataFlag3}
                  setShowToast={setShowToast}
                  TabKey={key}
                  otherLoader={otherLoader3}
                  setOtherLoader={setOtherLoader3}
                />
              </Tab>
            </Tabs>
          )}
        </div>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Fragment>
  );
}

export default Billing;

const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    borderRadius: "4px",
    fontFamily: "Open Sans",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 3;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};
