import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import useInput from "../../../../hooks/use-input";
import { useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "react-phone-number-input/input";
import axios from "axios";
import "./AddCommunityModal.scss";
import { getWithExpiry } from "../../../utils/helper";
// import images
import CloseModal from "../../../../assets/close-outline.svg";
import DisabledIcon from "../../../../assets/disabled-row.svg";
import { useDispatch } from "react-redux";
import Loader2 from "../../../UI/loader2/loader2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const isNotEmpty = (value) => value.trim() !== "";
const isEmailValid = (value) => value.includes("@");

function AddCommunityModal(props) {
  const { isShowModal, hideModal, type, setDataFlag, setShowToast } = props;
  const [address2, setAddress2] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  const setAddress2Handler = (e) => setAddress2(e.target.value);
  const setStateHandler = (e) => setState(e.target.value);
  const setZipHandler = (e) => setZip(e.target.value);

  const [nameError, setNameError] = useState("");
  const [address1Error, setAddress1Error] = useState("");
  const [cityError, setCityError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [zipError, setZipError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [addBtnDisabled, setAddBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("Community Admin");
  const [communityNameError, setCommunityNameError] = useState("");
  const [phoneValue, setPhoneValue] = useState("");

  const params = useParams();
  const { corporateID: corporateId } = params;

  //Start Creating State Object for Form

  const [community, setCommunity] = useState({
    community_name: "",
    address_1: "",
    address_2: "",
    country: "United States",
    city: "",
    state: "",
    zip: "",
    first_name: "",
    last_name: "",
    email: "",
    telephone_number: "",
  });

  const handleChange = (event, val) => {
    const { name, value } = event.target;
    if (name === "zip") {
      const regex = /^\d+$/;
      if (value === "") {
        setCommunity({ ...community, [name]: value });
        return;
      }

      if (regex.test(value) === false) return;
    }
    setCommunity({ ...community, [name]: value });
  };

  //End Creating State Object for Form

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const token = "Bearer " + getWithExpiry("expiry_token");

  const addCorporateSubmissionHandler = (event) => {
    event.preventDefault();
    setLoading(true);

    setCommunityNameError("");
    setAddress1Error("");
    setCityError("");
    setCountryError("");
    setStateError("");
    setZipError("");
    setNameError("");
    setPhoneError("");
    setEmailError("");
    setFirstNameError("");
    setLastNameError("");

    setAddBtnDisabled(true);

    // create form data here
    //const form = document.querySelector(".add-corporate-form");
    //const fd = new FormData(form);

    const fd = new FormData();
    fd.append("community_name", community.community_name);
    fd.append("address_1", community.address_1);
    fd.append("address_2", community.address_2);
    fd.append("country", community.country);
    fd.append("city", community.city);
    fd.append("state", community.state);
    fd.append("zip", community.zip);
    fd.append("first_name", community.first_name);
    fd.append("last_name", community.last_name);
    fd.append("email", community.email);

    if (phoneValue) {
      fd.append("telephone_number", "+" + phoneValue);
    }

    fd.append("corporate_id", corporateId);
    fd.append("role", role);

    // make http call
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/community/add_community/`,
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        notifySuccess(response.data.message);
        hideModal();
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      })
      .catch((error) => {
        setLoading(false);
        setAddBtnDisabled(false);
        if (error.response.data.message === "Field Errors") {
          if (
            error.response.data.data["community_form"] &&
            "community_name" in error.response.data.data["community_form"]
          ) {
            setCommunityNameError(
              error.response.data.data["community_form"]["community_name"][0]
            );
          }
          if (
            error.response.data.data["address_form"] &&
            "address_1" in error.response.data.data["address_form"]
          ) {
            let address1ErrorMsg = "";
            error.response.data.data["address_form"]["address_1"].map(
              (addressErr) => (address1ErrorMsg += addressErr)
            );
            setAddress1Error(address1ErrorMsg);
          }
          if (
            error.response.data.data["address_form"] &&
            "city" in error.response.data.data["address_form"]
          ) {
            let cityErrorMsg = "";
            error.response.data.data["address_form"]["city"].map(
              (cityErr) => (cityErrorMsg += cityErr)
            );
            setCityError(cityErrorMsg);
          }
          if (
            error.response.data.data["address_form"] &&
            "country" in error.response.data.data["address_form"]
          ) {
            let countryErrMsg = "";
            error.response.data.data["address_form"]["country"].map(
              (countryErr) => (countryErrMsg += countryErr)
            );
            setCountryError(countryErrMsg);
          }
          if (
            error.response.data.data["address_form"] &&
            "state" in error.response.data.data["address_form"]
          ) {
            setStateError(error.response.data.data["address_form"]["state"][0]);
          }
          if (
            error.response.data.data["address_form"] &&
            "zip" in error.response.data.data["address_form"]
          ) {
            setZipError(error.response.data.data["address_form"]["zip"][0]);
          }
          if (
            error.response.data.data["corporate_form"] &&
            "corporate_name" in error.response.data.data["corporate_form"]
          ) {
       
            setNameError(
              error.response.data.data["corporate_form"]["corporate_name"][0]
            );
          }
          if (
            error.response.data.data["phone_form"] &&
            "telephone_number" in error.response.data.data["phone_form"]
          ) {
            setPhoneError(
              error.response.data.data["phone_form"]["telephone_number"][0]
            );
          }
          if (
            error.response.data.data["user_form"] &&
            "email" in error.response.data.data["user_form"]
          ) {
            setEmailError(error.response.data.data["user_form"]["email"][0]);
          }
          if (
            error.response.data.data["user_form"] &&
            "first_name" in error.response.data.data["user_form"]
          ) {
            setFirstNameError(
              error.response.data.data["user_form"]["first_name"][0]
            );
          }
          if (
            error.response.data.data["user_form"] &&
            "last_name" in error.response.data.data["user_form"]
          ) {
            setLastNameError(
              error.response.data.data["user_form"]["last_name"][0]
            );
          }
        } else {
          notifyError(error.response.data.message);
        }
      });
  };

  const selectRole = (e) => {
    setRole(e.target.value);
  };

  return (
    <div className="action-modal-wrapper">
      <Modal
        className="comunitie-modal modal-loader"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header
          className="comubitie-modal-header"
          style={{ padding: "1rem 0rem" }}
        >
          <h1 className="modal-header-title">Add Community</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="comunitie-modal-body">
          <form
            
            method="post"
            className="add-corporate-form"
            onSubmit={addCorporateSubmissionHandler}
          >
            <div className="comunties-fields">
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="community_name" className="label">
                      Name *
                    </label>
                    <input
                      id="community_name"
                      name="community_name"
                      type="text"
                      placeholder="Name"
                      className="custom-input"
                      value={community.community_name}
                      onChange={handleChange}
                      required
                    />
                    {communityNameError && (
                      <p className="error-text">{communityNameError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="address_1" className="label">
                      Address 01 *
                    </label>
                    <input
                      id="address_1"
                      name="address_1"
                      type="text"
                      placeholder="Address 01"
                      className="custom-input"
                      value={community.address_1}
                      onChange={handleChange}
                      required
                    />
                    {address1Error && (
                      <p className="error-text">{address1Error}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="country" className="label">
                      Address 02
                    </label>
                    <input
                      type="text"
                      name="address_2"
                      id="address_2"
                      className="custom-input"
                      placeholder="Address 02"
                      value={community.address_2}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="zip" className="label">
                      City *
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="custom-input"
                      placeholder="City"
                      value={community.city}
                      onChange={handleChange}
                      required
                    />
                    {cityError && <p className="error-text">{cityError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="state" className="label">
                      State *
                    </label>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      className="custom-input"
                      placeholder="State"
                      value={community.state}
                      onChange={handleChange}
                      required
                    />
                    {stateError && <p className="error-text">{stateError}</p>}
                  </div>
                </div>

                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="zip" className="label">
                      Zip *
                    </label>
                    <input
                      type="text"
                      name="zip"
                      id="zip"
                      className="custom-input"
                      placeholder="Zip"
                      value={community.zip}
                      onChange={handleChange}
                      required
                    />
                    {zipError && <p className="error-text">{zipError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="country" className="label">
                      Country *
                    </label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      className="custom-input"
                      placeholder="Country"
                      value={community.country}
                      onChange={handleChange}
                      required
                    />
                    {countryError && (
                      <p className="error-text">{countryError}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="addminstraion-fields">
              <div className="addminstraion-header">
                <h2 className="adsd-heading">Administrator Information</h2>
                <p className="ad-des">Default User</p>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="first_name" className="label">
                      First Name *
                    </label>
                    <input
                      id="first_name"
                      name="first_name"
                      type="text"
                      className="custom-input"
                      placeholder="First Name"
                      value={community.first_name}
                      onChange={handleChange}
                      required
                    />
                    {firstNameError && (
                      <p className="error-text">{firstNameError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="last_name" className="label">
                      Last Name *
                    </label>
                    <input
                      id="last_name"
                      name="last_name"
                      type="text"
                      className="custom-input"
                      placeholder="Last Name"
                      value={community.last_name}
                      onChange={handleChange}
                      required
                    />
                    {lastNameError && (
                      <p className="error-text">{lastNameError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="email" className="label">
                      Email *
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className="custom-input"
                      placeholder="Email"
                      value={community.email}
                      onChange={handleChange}
                      required
                    />
                    {emailError && <p className="error-text">{emailError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="telephone_number" className="label">
                      Telephone *
                    </label>
                    {/* <input
                      type="text"
                      id="telephone_number"
                      name="telephone_number"
                      className="custom-input"
                      placeholder="Telephone"
                      value={community.telephone_number}
                      onChange={(e) => {
                        const { name, value } = e.target;

                        const regex = /^([\s+0-9])+$/i;
                        if (value === "") {
                          // case to remove whole highlighted text
                          setCommunity({ ...community, [name]: value });
                          return;
                        }
                        if (regex.test(value) === false) return;

                        if (value.length === 1 && value === "+") {
                          // case to remove + sign when last number is removed
                          setCommunity({ ...community, [name]: "" });
                        } else if (value.length >= 1 && value[0] !== "+") {
                          // case to append + sign with first number
                          setCommunity({ ...community, [name]: "+" + value });
                        } else {
                          // case to append other numbers
                          setCommunity({ ...community, [name]: value });
                        }
                      }}
                      required
                    /> */}
                    <PhoneInput
                      country={"us"}
                      value={phoneValue}
                      onChange={setPhoneValue}
                      inputProps={{
                        required: true,
                      }}
                    />
                    {phoneError && <p className="error-text">{phoneError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <label htmlFor="role" className="label">
                    Role *
                  </label>
                  <select
                    name="role"
                    id="role"
                    className="custom-select-modal"
                    onChange={selectRole}
                    value={role}
                  >
                    <option value="Community Admin">Community Admin</option>
                    {/* <option value="Individual Practice Admin">
                      Individual Practice Admin
                    </option> */}
                  </select>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16 text-capitalize"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            // type="submit"
            onClick={addCorporateSubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={addBtnDisabled}
          >
            Add
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

export default AddCommunityModal;
