import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  permissions: ["Equipments"],
  isCatchuAdmin: null,
  role: [],
  userInfo: null,
  userdisplay: [],
  isPassword: null,
  globalCurrentPage: 1,
  appLoader: false,

  //audit logs pagination
  auditLogsPageNumber: 1,
  auditLogsCurrentPage: 1,
  auditLogsTotalRecords: 0,
  auditLogsList: [],
};

const UserSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setAppLoader(state, action) {
      state.appLoader = action.payload;
    },
    setAuditLogsPageNumber(state, action) {
      state.auditLogsPageNumber = action.payload;
    },
    setAuditLogsCurrentPage(state, action) {
      state.auditLogsCurrentPage = action.payload;
    },
    setAuditLogsTotalRecords(state, action) {
      state.auditLogsTotalRecords = action.payload;
    },
    setAuditLogsList(state, action) {
      state.auditLogsList = action.payload;
    },
    // addPermissions(state, action) {
    //   state.permissions.push(action.payload);
    // },
    setGlobalCurrentPage(state, action) {
      state.globalCurrentPage = action.payload;
    },
    addPermissions(state, action) {
      state.permissions = action.payload;
    },
    setUsersdisplay(state, action) {
      state.userdisplay = action.payload;
    },
    setRole(state, action) {
      state.role = action.payload;
    },
    setIsCatchuAdmin(state, action) {
      state.isCatchuAdmin = action.payload;
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    setIsPassword(state, action) {
      state.isPassword = action.payload;
    },
  },
});

export const userActions = UserSlice.actions;

export default UserSlice.reducer;
