import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import auth, { authActions } from "../../../store/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import useInput from "../../../hooks/use-input";
import verifyOtp from "./verifyOtp";
import "./OTPVerification.scss";

// import images
import BrandLogo from "../../../assets/logo.png";
import ArrowRight from "../../../assets/arrow-right.svg";
import { userActions } from "../../../store/user";
const isNotEmpty = (value) => value.trim() !== "";
const isSingleDigit = (value) => {
  const regex = /^\d$/;
  return isNotEmpty(value) && regex.test(value);
};

const VerifyOTP = (props) => {
  const firstOTPField = useRef();
  const secondOTPField = useRef();
  const thirdOTPField = useRef();
  const fourthOTPField = useRef();
  const fifthOTPField = useRef();
  const sixthOTPField = useRef();

  const PhonesecondOTPField = useRef();
  const PhonethirdOTPField = useRef();
  const PhonefourthOTPField = useRef();
  const PhonefifthOTPField = useRef();
  const PhonesixthOTPField = useRef();

  const { type } = props;
  const history = useHistory();
  const email = useSelector((state) => state.auth.email);
  const dispatch = useDispatch();
  const [emailOtpError, setEmailOtpError] = useState("");
  const [smsOtpError, setSmsOtpError] = useState("");
  const [error, setError] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(false);

  const {
    value: emailOtpNum1,
    isValid: emailOtpNum1IsValid,
    hasError: emailOtp1HasError,
    inputChangeHandler: emailOtp1ChangeHandler,
    inputBlurHandler: emailOtp1BlurHandler,
    reset: emailOtp1ResetHandler,
  } = useInput(isSingleDigit);

  const {
    value: emailOtpNum2,
    isValid: emailOtpNum2IsValid,
    hasError: emailOtp2HasError,
    inputChangeHandler: emailOtp2ChangeHandler,
    inputBlurHandler: emailOtp2BlurHandler,
    reset: emailOtp2ResetHandler,
  } = useInput(isNotEmpty);

  const {
    value: emailOtpNum3,
    isValid: emailOtpNum3IsValid,
    hasError: emailOtp3HasError,
    inputChangeHandler: emailOtp3ChangeHandler,
    inputBlurHandler: emailOtp3BlurHandler,
    reset: emailOtp3ResetHandler,
  } = useInput(isNotEmpty);

  const {
    value: emailOtpNum4,
    isValid: emailOtpNum4IsValid,
    hasError: emailOtp4HasError,
    inputChangeHandler: emailOtp4ChangeHandler,
    inputBlurHandler: emailOtp4BlurHandler,
    reset: emailOtp4ResetHandler,
  } = useInput(isNotEmpty);

  const {
    value: emailOtpNum5,
    isValid: emailOtpNum5IsValid,
    hasError: emailOtp5HasError,
    inputChangeHandler: emailOtp5ChangeHandler,
    inputBlurHandler: emailOtp5BlurHandler,
    reset: emailOtp5ResetHandler,
  } = useInput(isNotEmpty);

  const {
    value: emailOtpNum6,
    isValid: emailOtpNum6IsValid,
    hasError: emailOtp6HasError,
    inputChangeHandler: emailOtp6ChangeHandler,
    inputBlurHandler: emailOtp6BlurHandler,
    reset: emailOtp6ResetHandler,
  } = useInput(isNotEmpty);

  const {
    value: smsOtpNum1,
    isValid: smsOtpNum1IsValid,
    hasError: smsOtp1HasError,
    inputChangeHandler: smsOtp1ChangeHandler,
    inputBlurHandler: smsOtp1BlurHandler,
    reset: smsOtp1ResetHandler,
  } = useInput(isNotEmpty);

  const {
    value: smsOtpNum2,
    isValid: smsOtpNum2IsValid,
    hasError: smsOtp2HasError,
    inputChangeHandler: smsOtp2ChangeHandler,
    inputBlurHandler: smsOtp2BlurHandler,
    reset: smsOtp2ResetHandler,
  } = useInput(isNotEmpty);

  const {
    value: smsOtpNum3,
    isValid: smsOtpNum3IsValid,
    hasError: smsOtp3HasError,
    inputChangeHandler: smsOtp3ChangeHandler,
    inputBlurHandler: smsOtp3BlurHandler,
    reset: smsOtp3ResetHandler,
  } = useInput(isNotEmpty);

  const {
    value: smsOtpNum4,
    isValid: smsOtpNum4IsValid,
    hasError: smsOtp4HasError,
    inputChangeHandler: smsOtp4ChangeHandler,
    inputBlurHandler: smsOtp4BlurHandler,
    reset: smsOtp4ResetHandler,
  } = useInput(isNotEmpty);

  const {
    value: smsOtpNum5,
    isValid: smsOtpNum5IsValid,
    hasError: smsOtp5HasError,
    inputChangeHandler: smsOtp5ChangeHandler,
    inputBlurHandler: smsOtp5BlurHandler,
    reset: smsOtp5ResetHandler,
  } = useInput(isNotEmpty);

  const {
    value: smsOtpNum6,
    isValid: smsOtpNum6IsValid,
    hasError: smsOtp6HasError,
    inputChangeHandler: smsOtp6ChangeHandler,
    inputBlurHandler: smsOtp6BlurHandler,
    reset: smsOtp6ResetHandler,
  } = useInput(isNotEmpty);

  const hasForgottenPassword = useSelector(
    (state) => state.auth.hasForgottenPassword
  );
  const successMessage = useSelector((state) => state.auth.successMessage);
  const isLogin = useSelector((state) => state.auth.isLogin);

  useEffect(() => {
    if (type === "login") {
      if (isLogin) {
        notifySuccess(successMessage);
        dispatch(authActions.resetLoginStatus());
      }
    } else {
      if (hasForgottenPassword) {
        notifySuccess(successMessage);
        dispatch(authActions.resetForgotPasswordStatus(false));
      }
    }
  }, []);

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  let formIsValid = false;
  let url;

  if (type === "login") {
    url = `${process.env.REACT_APP_URL_PATH}/user/verify_otp/`;
  } else {
    url = `${process.env.REACT_APP_URL_PATH}/user/verify_forget_password_otp/`;
  }

  if (type === "login") {
    // if (emailOtpNum1 && emailOtpNum2 && emailOtpNum3 && emailOtpNum4 && emailOtpNum5 && emailOtpNum6
    //     && smsOtpNum1 && smsOtpNum2 && smsOtpNum3 && smsOtpNum4 && smsOtpNum5 && smsOtpNum6) {
    //     formIsValid = true;
    // }
  } else {
    // if (emailOtpNum1 && emailOtpNum2 && emailOtpNum3 && emailOtpNum4 && emailOtpNum5 && emailOtpNum6) {
    //      formIsValid = true;
    // }
  }

  const getResponse = (response) => {
    if (type === "login") {
      const token = response.data.data.token;
      // const successMessage = response.data.message;
      const successMessage = "Login Successful";
      dispatch(authActions.verifyOtp(token)); // dispatch token to auth store
      dispatch(authActions.verifyOtpSuccessMsg(successMessage)); // store login success message
      localStorage.setItem("token", token); // store token in localstorage
      dispatch(authActions.setIsLoggedIn(true));
      history.replace("/login"); // redirect to homepage
    } else {
      const email = response.data.data.email;
      dispatch(authActions.verifyOtpSuccessMsg("OTP Verified!"));
      dispatch(authActions.verifyForgotPasswordOtp(email));
      dispatch(authActions.setPathCheck(true));
      history.replace("/change-password");
    }
  };

  const otpModalSubmissionHandler = (event) => {
    event.preventDefault();
    setDisabledBtn(true);
    // create form data
    const fd = new FormData();
    const emailOtp = `${emailOtpNum1}${emailOtpNum2}${emailOtpNum3}${emailOtpNum4}${emailOtpNum5}${emailOtpNum6}`;
    const phoneOtp = `${smsOtpNum1}${smsOtpNum2}${smsOtpNum3}${smsOtpNum4}${smsOtpNum5}${smsOtpNum6}`;

    if (type === "login") {
      fd.append("email", email);
      fd.append("email_otp", emailOtp);
      fd.append("phone_otp", phoneOtp);
    } else {
      fd.append("email", email);
      fd.append("email_otp", emailOtp);
    }

    // make HTTP call
    axios({
      method: "post",
      url: url,
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        const token = response.data.data.token;
        getResponse(response);
      })
      .catch((error) => {
        if (error.response) {
          setDisabledBtn(false);
          if (error.response.data.message === "Field Errors") {
            setEmailOtpError(error.response.data.data.email_otp);
          } else {
            notifyError(error.response.data.message);
          }
        }
      });
  };

  useLayoutEffect(() => {

  }, [verifyOtp]);

  return (
    <div className="login-screen-wrapper">
      <div className="login-box">
        <div className="login-logo">
          <img src={BrandLogo} alt="" />
        </div>
        <div className="login-header">
          <h1 className="login-heading">OTP Verification</h1>
          <p className="login-des">Please enter your 6-digit code to verify</p>
        </div>
        <form
          
          className="otp-verification-form"
          onSubmit={otpModalSubmissionHandler}
        >
          <div className="code-wrapper">
            <label htmlFor="number" className="login-label">
              Enter your email address code to verify *
            </label>
            <ul className="code-list" data-group-name="digits" id="email-otp">
              <li className="single-code">
                <input
                  type="number"
                  placeholder="0"
                  className="code-input digit-1"
                  maxLength="1"
                  data-next="digit-2"
                  value={emailOtpNum1}
                  onChange={emailOtp1ChangeHandler}
                  onBlur={emailOtp1BlurHandler}
                  onKeyDown={(e) => {
                    if (e.keyCode == "69") e.preventDefault();
                  }}
                  onKeyUp={(e) =>
                    (e.keyCode >= 48 && e.keyCode <= 57) ||
                    (e.keyCode >= 96 && e.keyCode <= 105) ||
                    e.keyCode == 39
                      ? secondOTPField.current.focus()
                      : false
                  }
                  ref={firstOTPField}
                />
              </li>
              <li className="single-code">
                <input
                  type="number"
                  placeholder="0"
                  className="code-input digit-2"
                  maxLength="1"
                  data-previous="digit-1"
                  data-next="digit-3"
                  value={emailOtpNum2}
                  onChange={emailOtp2ChangeHandler}
                  onBlur={emailOtp2BlurHandler}
                  onKeyDown={(e) => {
                    if (e.keyCode == "69") e.preventDefault();
                  }}
                  onKeyUp={(e) =>
                    e.keyCode == 37
                      ? firstOTPField.current.focus()
                      : (e.keyCode >= 48 && e.keyCode <= 57) ||
                        (e.keyCode >= 96 && e.keyCode <= 105) ||
                        e.keyCode == 39
                      ? thirdOTPField.current.focus()
                      : false
                  }
                  ref={secondOTPField}
                />
              </li>
              <li className="single-code">
                <input
                  type="number"
                  placeholder="0"
                  className="code-input digit-3"
                  maxLength="1"
                  data-previous="digit-2"
                  data-next="digit-4"
                  value={emailOtpNum3}
                  onChange={emailOtp3ChangeHandler}
                  onBlur={emailOtp3BlurHandler}
                  onKeyDown={(e) => {
                    if (e.keyCode == "69") e.preventDefault();
                  }}
                  onKeyUp={(e) =>
                    e.keyCode == 37
                      ? secondOTPField.current.focus()
                      : (e.keyCode >= 48 && e.keyCode <= 57) ||
                        (e.keyCode >= 96 && e.keyCode <= 105) ||
                        e.keyCode == 39
                      ? fourthOTPField.current.focus()
                      : false
                  }
                  ref={thirdOTPField}
                />
              </li>
              <li className="single-code">
                <input
                  type="number"
                  placeholder="0"
                  className="code-input digit-4"
                  maxLength="1"
                  data-previous="digit-3"
                  data-next="digit-5"
                  value={emailOtpNum4}
                  onChange={emailOtp4ChangeHandler}
                  onBlur={emailOtp4BlurHandler}
                  onKeyDown={(e) => {
                    if (e.keyCode == "69") e.preventDefault();
                  }}
                  onKeyUp={(e) =>
                    e.keyCode == 37
                      ? thirdOTPField.current.focus()
                      : (e.keyCode >= 48 && e.keyCode <= 57) ||
                        (e.keyCode >= 96 && e.keyCode <= 105) ||
                        e.keyCode == 39
                      ? fifthOTPField.current.focus()
                      : false
                  }
                  ref={fourthOTPField}
                />
              </li>
              <li className="single-code">
                <input
                  type="number"
                  placeholder="0"
                  className="code-input digit-5"
                  maxLength="1"
                  data-previous="digit-4"
                  data-next="digit-6"
                  value={emailOtpNum5}
                  onChange={emailOtp5ChangeHandler}
                  onBlur={emailOtp5BlurHandler}
                  onKeyDown={(e) => {
                    if (e.keyCode == "69") e.preventDefault();
                  }}
                  onKeyUp={(e) =>
                    e.keyCode == 37
                      ? fourthOTPField.current.focus()
                      : (e.keyCode >= 48 && e.keyCode <= 57) ||
                        (e.keyCode >= 96 && e.keyCode <= 105) ||
                        e.keyCode == 39
                      ? sixthOTPField.current.focus()
                      : false
                  }
                  ref={fifthOTPField}
                />
              </li>
              <li className="single-code">
                <input
                  type="number"
                  placeholder="0"
                  className="code-input digit-6"
                  maxLength="1"
                  data-previous="digit-5"
                  value={emailOtpNum6}
                  onChange={emailOtp6ChangeHandler}
                  onBlur={emailOtp6BlurHandler}
                  onKeyDown={(e) => {
                    if (e.keyCode == "69") e.preventDefault();
                  }}
                  onKeyUp={(e) =>
                    e.keyCode == 37 ? fifthOTPField.current.focus() : false
                  }
                  ref={sixthOTPField}
                />
              </li>
            </ul>
            {emailOtpError && <p className="error-text">{emailOtpError}</p>}
          </div>
          {type === "login" && (
            <div className="code-wrapper">
              <label htmlFor="" className="login-label">
                Enter your phone number code to verify *
              </label>
              <ul className="code-list" data-group-name="digits" id="sms-otp">
                <li className="single-code">
                  <input
                    type="number"
                    placeholder="0"
                    className="code-input digit-1"
                    maxLength="1"
                    data-next="digit-2"
                    value={smsOtpNum1}
                    onChange={smsOtp1ChangeHandler}
                    onBlur={smsOtp1BlurHandler}
                  />
                </li>
                <li className="single-code">
                  <input
                    type="number"
                    placeholder="0"
                    className="code-input digit-2"
                    maxLength="1"
                    data-previous="digit-1"
                    data-next="digit-3"
                    value={smsOtpNum2}
                    onChange={smsOtp2ChangeHandler}
                    onBlur={smsOtp2BlurHandler}
                  />
                </li>
                <li className="single-code">
                  <input
                    type="number"
                    placeholder="0"
                    className="code-input digit-3"
                    maxLength="1"
                    data-previous="digit-2"
                    data-next="digit-4"
                    value={smsOtpNum3}
                    onChange={smsOtp3ChangeHandler}
                    onBlur={smsOtp3BlurHandler}
                  />
                </li>
                <li className="single-code">
                  <input
                    type="number"
                    placeholder="0"
                    className="code-input digit-4"
                    maxLength="1"
                    data-previous="digit-3"
                    data-next="digit-5"
                    value={smsOtpNum4}
                    onChange={smsOtp4ChangeHandler}
                    onBlur={smsOtp4BlurHandler}
                  />
                </li>
                <li className="single-code">
                  <input
                    type="number"
                    placeholder="0"
                    className="code-input digit-5"
                    maxLength="1"
                    data-previous="digit-4"
                    data-next="digit-6"
                    value={smsOtpNum5}
                    onChange={smsOtp5ChangeHandler}
                    onBlur={smsOtp5BlurHandler}
                  />
                </li>
                <li className="single-code">
                  <input
                    type="number"
                    placeholder="0"
                    className="code-input digit-6"
                    maxLength="1"
                    data-previous="digit-5"
                    value={smsOtpNum6}
                    onChange={smsOtp6ChangeHandler}
                    onBlur={smsOtp6BlurHandler}
                  />
                </li>
              </ul>
              {/* {smsOtpError && <p className="error-text">{smsOtpError}</p>} */}
              {/* <Link to="#" className="send-code">Resend Code?</Link> */}
            </div>
          )}
          {error && <p className="error-text">{error}</p>}
          <div className="code-action">
            <button
              className="btn btn-primary-fill btn-14-24"
              type="submit"
              disabled={disabledBtn}
            >
              CONFIRM
              <img className="arrow-right" src={ArrowRight} alt="right-arrow" />
            </button>
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerifyOTP;
