import { createSlice } from "@reduxjs/toolkit";

const generic = "";
const asc = "asc";
const desc = "desc";

const initialOrderState = {
  orderQuery: "",
  rowOrderCorporate: {
    name: { state: 1, order: generic, icon: "" },
    address_1: { state: 1, order: generic, icon: "" },
    address_2: { state: 1, order: generic, icon: "" },
    state: { state: 1, order: generic, icon: "" },
    city: { state: 1, order: generic, icon: "" },
    zip: { state: 1, order: generic, icon: "" },
  },
  rowOrderCorporateCommunity: {
    community_id: { state: 1, order: generic, icon: "" },
    name: { state: 1, order: generic, icon: "" },
    address_1: { state: 1, order: generic, icon: "" },
    address_2: { state: 1, order: generic, icon: "" },
    state: { state: 1, order: generic, icon: "" },
    city: { state: 1, order: generic, icon: "" },
    zip: { state: 1, order: generic, icon: "" },
  },
  rowOrderCorporateUser: {
    first_name: { state: 1, order: generic, icon: "" },
    last_name: { state: 1, order: generic, icon: "" },
    email: { state: 1, order: generic, icon: "" },
    telephone_number: { state: 1, order: generic, icon: "" },
    role: { state: 1, order: generic, icon: "" },
  },
  rowOrderCommunity: {
    community_id: { state: 1, order: generic, icon: "" },
    name: { state: 1, order: generic, icon: "" },
    corporate: { state: 1, order: generic, icon: "" },
    address_1: { state: 1, order: generic, icon: "" },
    address_2: { state: 1, order: generic, icon: "" },
    state: { state: 1, order: generic, icon: "" },
    city: { state: 1, order: generic, icon: "" },
    zip: { state: 1, order: generic, icon: "" },
  },
  rowOrderCommunityPatient: {
    first_name: { state: 1, order: generic, icon: "" },
    last_name: { state: 1, order: generic, icon: "" },
    dob: { state: 1, order: generic, icon: "" },
    email: { state: 1, order: generic, icon: "" },
    telephone_number: { state: 1, order: generic, icon: "" },
    status: { state: 1, order: generic, icon: false },
  },
  rowOrderCommunityUser: {
    first_name: { state: 1, order: generic, icon: "" },
    last_name: { state: 1, order: generic, icon: "" },
    email: { state: 1, order: generic, icon: "" },
    telephone_number: { state: 1, order: generic, icon: "" },
    role: { state: 1, order: generic, icon: "" },
  },
  rowOrderCommunityIphone: {
    iphone_number: { state: 1, order: generic, icon: "" },
    iphone_description: { state: 1, order: generic, icon: "" },
  },
  rowOrderCommunityBillee: {
    company: { state: 1, order: generic, icon: "" },
    first_name: { state: 1, order: generic, icon: "" },
    last_name: { state: 1, order: generic, icon: "" },
    email: { state: 1, order: generic, icon: "" },
    telephone_number: { state: 1, order: generic, icon: "" },
  },
  rowOrderCommunityProvider: {
    first_name: { state: 1, order: generic, icon: "" },
    last_name: { state: 1, order: generic, icon: "" },
    email: { state: 1, order: generic, icon: "" },
    telephone_number: { state: 1, order: generic, icon: "" },
  },
  rowOrderCommunityTest: {
    name: { state: 1, order: generic, icon: "" },
    created_date: { state: 1, order: generic, icon: "" },
    expiry_date: { state: 1, order: generic, icon: "" },
    location: { state: 1, order: generic, icon: "" },
    tests: { state: 1, order: generic, icon: "" },
    status: { state: 1, order: generic, icon: "" },
  },
  rowOrderPatient: {
    first_name: { state: 1, order: generic, icon: "" },
    last_name: { state: 1, order: generic, icon: "" },
    community_name: { state: 1, order: generic, icon: "" },
    dob: { state: 1, order: generic, icon: "" },
    email: { state: 1, order: generic, icon: "" },
    telephone_number: { state: 1, order: generic, icon: "" },
    status: { state: 1, order: generic, icon: false },
  },
  rowOrderScheduleTest: {
    first_name: { state: 1, order: generic, icon: "" },
    last_name: { state: 1, order: generic, icon: "" },
    dob: { state: 1, order: generic, icon: "" },
    last_test_date: { state: 1, order: generic, icon: "" },
  },
  rowOrderUpcomingTest: {
    patient_name: { state: 1, order: generic, icon: "" },
    dob: { state: 1, order: generic, icon: "" },
    status: { state: 1, order: generic, icon: "" },
    schedule_date: { state: 1, order: generic, icon: "" },
    location: { state: 1, order: generic, icon: "" },
    remote: { state: 1, order: generic, icon: "" },
  },
  rowOrderProvider: {
    first_name: { state: 1, order: generic, icon: "" },
    last_name: { state: 1, order: generic, icon: "" },
    email: { state: 1, order: generic, icon: "" },
    telephone_number: { state: 1, order: generic, icon: "" },
  },
  rowOrderAdminUser: {
    first_name: { state: 1, order: generic, icon: "" },
    last_name: { state: 1, order: generic, icon: "" },
    email: { state: 1, order: generic, icon: "" },
    telephone_number: { state: 1, order: generic, icon: "" },
  },
  rowOrderExternalProviders: {
    first_name: { state: 1, order: generic, icon: "" },
    last_name: { state: 1, order: generic, icon: "" },
    email: { state: 1, order: generic, icon: "" },
    provider_status_code: { state: 1, order: generic, icon: "" },
  },
  rowOrderPrepaidPatients: {
    first_name: { state: 1, order: generic, icon: "" },
    last_name: { state: 1, order: generic, icon: "" },
    provider_status_code: { state: 1, order: generic, icon: "" },
    test_status: { state: 1, order: generic, icon: "" },
  },
  rowOrderRegUser: {
    first_name: { state: 1, order: generic, icon: "" },
    last_name: { state: 1, order: generic, icon: "" },
    email: { state: 1, order: generic, icon: "" },
    telephone_number: { state: 1, order: generic, icon: "" },
  },
  rowOrderBillingTestsBill: {
    test_id: { state: 1, order: generic, icon: "" },
    community_name: { state: 1, order: generic, icon: "" },
    billee_name: { state: 1, order: generic, icon: "" },
    billee_company: { state: 1, order: generic, icon: "" },
    patient_name: { state: 1, order: generic, icon: "" },
    test_date: { state: 1, order: generic, icon: "" },
    date_of_birth: { state: 1, order: generic, icon: "" },
    test_location: { state: 1, order: generic, icon: "" },
    remote: { state: 1, order: generic, icon: "" },
    initial_amount: { state: 1, order: generic, icon: "" },
    credit_amount: { state: 1, order: generic, icon: "" },
    after_discount_amount: { state: 1, order: generic, icon: "" },
    amount_reason: { state: 1, order: generic, icon: "" },
  },
  rowOrderBillingPastInvoices: {
    invoice_id: { state: 1, order: generic, icon: "" },
    invoice_code: { state: 1, order: generic, icon: "" },
    community_name: { state: 1, order: generic, icon: "" },
    billee_name: { state: 1, order: generic, icon: "" },
    billee_company: { state: 1, order: generic, icon: "" },
    invoice_date: { state: 1, order: generic, icon: "" },
    due_date: { state: 1, order: generic, icon: "" },
    status: { state: 1, order: generic, icon: "" },
    total: { state: 1, order: generic, icon: "" },
    community_id: { state: 1, order: generic, icon: "" },
    billee_address: { state: 1, order: generic, icon: "" },
  },
  rowOrderEquipmentsPastInvoices: {
    invoice_id: { state: 1, order: generic, icon: "" },
    invoice_code: { state: 1, order: generic, icon: "" },
    community_name: { state: 1, order: generic, icon: "" },
    billee_name: { state: 1, order: generic, icon: "" },
    billee_company: { state: 1, order: generic, icon: "" },
    invoice_date: { state: 1, order: generic, icon: "" },
    due_date: { state: 1, order: generic, icon: "" },
    status: { state: 1, order: generic, icon: "" },
    total: { state: 1, order: generic, icon: "" },
    community_id: { state: 1, order: generic, icon: "" },
    billee_address: { state: 1, order: generic, icon: "" },
  },
  rowOrderBillingEquipments: {
    community_name: { state: 1, order: generic, icon: "" },
    billee_name: { state: 1, order: generic, icon: "" },
    billee_company: { state: 1, order: generic, icon: "" },
    equipment_id: { state: 1, order: generic, icon: "" },
    equipment_price: { state: 1, order: generic, icon: "" },
  },
  rowOrderEquipments: {
    community_name: { state: 1, order: generic, icon: "" },
    billee_name: { state: 1, order: generic, icon: "" },
    billee_company: { state: 1, order: generic, icon: "" },
    equipment_id: { state: 1, order: generic, icon: "" },
    equipment_price: { state: 1, order: generic, icon: "" },
  },
  rowOrderAuditLogs: {
    created_at: { state: 1, order: generic, icon: "" },
    modified_at: { state: 1, order: generic, icon: "" },
    user: { state: 1, order: generic, icon: "" },
    event_type: { state: 1, order: generic, icon: "" },
    event_description: { state: 1, order: generic, icon: "" },
  },

  rowOrderPatientHistory: {
    provider: { state: 1, order: generic, icon: "" },
    remote: { state: 1, order: generic, icon: "" },
    ordered: { state: 1, order: generic, icon: "" },
    test_date: { state: 1, order: generic, icon: "" },
    status: { state: 1, order: generic, icon: "" },
    location: { state: 1, order: generic, icon: "" },
    credit_amount: { state: 1, order: generic, icon: "" },
    amount_reason: { state: 1, order: generic, icon: "" },
  },

  rowOrderGroupTestHistory: {
    patient_first_name: { state: 1, order: generic, icon: "" },
    patient_last_name: { state: 1, order: generic, icon: "" },
    test_ordered_date: { state: 1, order: generic, icon: "" },
    date_scheduled: { state: 1, order: generic, icon: "" },
    test_location: { state: 1, order: generic, icon: "" },
    remote: { state: 1, order: generic, icon: "" },
    status: { state: 1, order: generic, icon: "" },
    credit_amount: { state: 1, order: generic, icon: "" },
    amount_reason: { state: 1, order: generic, icon: "" },
  },

  rowOrderPastInvoicesHistory: {
    test_id: { state: 1, order: generic, icon: "" },
    patient_name: { state: 1, order: generic, icon: "" },
    test_date: { state: 1, order: generic, icon: "" },
    date_of_birth: { state: 1, order: generic, icon: "" },
    location: { state: 1, order: generic, icon: "" },
    remote: { state: 1, order: generic, icon: "" },
    credit_amount: { state: 1, order: generic, icon: "" },
    amount_reason: { state: 1, order: generic, icon: "" },
  },

  rowOrderCreditHistory: {
    community_name: { state: 1, order: generic, icon: "" },
    credit_type: { state: 1, order: generic, icon: "" },
    test_id: { state: 1, order: generic, icon: "" },
    billee_name: { state: 1, order: generic, icon: "" },
    credit_amount: { state: 1, order: generic, icon: "" },
    amount_reason: { state: 1, order: generic, icon: "" },
    credit_date: { state: 1, order: generic, icon: "" },
    person_issuing_credit: { state: 1, order: generic, icon: "" },
    invoice_id: { state: 1, order: generic, icon: "" },
  },
  rowOrderDevicesList: {
    name: { state: 1, order: generic, icon: "" },
    price: { state: 1, order: generic, icon: "" },
    description: { state: 1, order: generic, icon: "" },
    bill_in_next_invoice: { state: 1, order: generic, icon: "" },
    billee: { state: 1, order: generic, icon: "" },
    equipment_id: { state: 1, order: generic, icon: "" },
    bllee_name: { state: 1, order: generic, icon: "" },
  },
};

const rowOrderSlicer = createSlice({
  name: "rowOrder",
  initialState: initialOrderState,
  reducers: {
    reset: () => {
      return { ...initialOrderState };
    },
    setOrderQuery(state, action) {
      state.orderQuery = action.payload;
    },
    setRowOrderPatientHistory(state, action) {
      state.rowOrderPatientHistory = action.payload;
    },
    setCorporateOrder(state, action) {
      state.rowOrderCorporate = action.payload;
    },
    setCorporateCommunityOrder(state, action) {
      return {
        ...state,
        rowOrderCorporateCommunity: action.payload,
      };
    },
    setCorporateUserOrder(state, action) {
      return {
        ...state,
        rowOrderCorporateUser: action.payload,
      };
    },

    setCommunityOrder(state, action) {
      return {
        ...state,
        rowOrderCommunity: action.payload,
      };
    },
    setCommunityPatientOrder(state, action) {
      return {
        ...state,
        rowOrderCommunityPatient: action.payload,
      };
    },
    setCommunityUserOrder(state, action) {
      return {
        ...state,
        rowOrderCommunityUser: action.payload,
      };
    },
    setCommunityIphoneOrder(state, action) {
      return {
        ...state,
        rowOrderCommunityIphone: action.payload,
      };
    },
    setCommunityBilleeOrder(state, action) {
      return {
        ...state,
        rowOrderCommunityBillee: action.payload,
      };
    },
    setCommunityProviderOrder(state, action) {
      return {
        ...state,
        rowOrderCommunityProvider: action.payload,
      };
    },
    setCommunityTestOrder(state, action) {
      return {
        ...state,
        rowOrderCommunityTest: action.payload,
      };
    },

    setPatientOrder(state, action) {
      return {
        ...state,
        rowOrderPatient: action.payload,
      };
    },

    setScheduledTestOrder(state, action) {
      return {
        ...state,
        rowOrderScheduleTest: action.payload,
      };
    },

    setUpcomingTestOrder(state, action) {
      return {
        ...state,
        rowOrderUpcomingTest: action.payload,
      };
    },

    setProviderOrder(state, action) {
      return {
        ...state,
        rowOrderProvider: action.payload,
      };
    },

    setAdminUserOrder(state, action) {
      return {
        ...state,
        rowOrderAdminUser: action.payload,
      };
    },


    setExternalProviderOrder(state, action) {
      return {
        ...state,
        rowOrderExternalProviders: action.payload,
      };
    },

    setPrepaidPatientsOrder(state, action) {
      return {
        ...state,
        rowOrderPrepaidPatients: action.payload,
      };
    },

    setRegUserOrder(state, action) {
      return {
        ...state,
        rowOrderRegUser: action.payload,
      };
    },

    setRowOrderBillingTestsBillOrder(state, action) {
      return {
        ...state,
        rowOrderBillingTestsBill: action.payload,
      };
    },

    setRowOrderBillingPastInvoicesOrder(state, action) {
      return {
        ...state,
        rowOrderBillingPastInvoices: action.payload,
      };
    },
    setRowOrderEquipmentsPastInvoicesOrder(state, action) {
      return {
        ...state,
        rowOrderEquipmentsPastInvoices: action.payload,
      };
    },
    setRowOrderBillingEquipments(state, action) {
      return {
        ...state,
        rowOrderBillingEquipments: action.payload,
      };
    },
    setRowOrderEquipments(state, action) {
      return {
        ...state,
        rowOrderEquipments: action.payload,
      };
    },
    setRowOrderAuditLogs(state, action) {
      return {
        ...state,
        rowOrderAuditLogs: action.payload,
      };
    },

    setRowOrderGroupTestHistory(state, action) {
      return {
        ...state,
        rowOrderGroupTestHistory: action.payload,
      };
    },

    setRowOrderPastInvoicesHistory(state, action) {
      return {
        ...state,
        rowOrderPastInvoicesHistory: action.payload,
      };
    },

    setRowOrderCreditHistory(state, action) {
      return {
        ...state,
        rowOrderCreditHistory: action.payload,
      };
    },
    setRowOrderDevicesList(state, action) {
      return {
        ...state,
        rowOrderDevicesList: action.payload,
      };
    },
  },
});

export const rowOrderActions = rowOrderSlicer.actions;
export default rowOrderSlicer.reducer;
