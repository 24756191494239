import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import CustomSelect from "../../../General/filters/CustomSelect.Component";
import { filterActions } from "../../../../store/filter";
import { removeEmptyParams } from "../../../../services/helpers/sorting.hepler";
import { communityActions } from "../../../../store/community";
import { getWithExpiry } from "../../../utils/helper";
import axios from "axios";
import useQueryString from "../../../../hooks/useQueryString";
import "./FiltersModal.scss";
// imports image

import CloseModal from "../../../../assets/close-outline.svg";
import DisabledIcon from "../../../../assets/disabled-row.svg";
import Select, { components } from "react-select";
import { billingActions } from "../../../../store/billing";

function BilleeInvoicesModal(props) {
  const {
    isShowModal,
    hideModal,
    orderBy,
    setDataFlag,
    setShowToast,
    billingData,
    searchText,
    dataType,
    setOtherLoader,
  } = props;

  const history = useHistory();
  const { communityID } = useParams();
  const dispatch = useDispatch();
  const urlParams = useQueryString();
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(true);

  const filterSubmissionHandler = () => {
    setOtherLoader(true);
    let customQuery = `search_query=${searchText}&${orderBy}`;

    var modifiedString = removeEmptyParams(customQuery);
    let prefix = "";
    let searchParam = "";
    prefix = "tab_key=invoice&";

    if (
      !modifiedString.includes("page") &&
      urlParams.toString().includes("page")
    ) {
      searchParam = "?" + prefix + modifiedString + `&page=${1}`;
    } else {
      searchParam = "?" + prefix + modifiedString;
    }

    searchParam = searchParam.replace("&&", "&");
    modifiedString = modifiedString.replace("&&", "&");
    history.replace({
      pathname: `/billing`,
      search: searchParam,
    });
    let newObj = invoiceCommunities.map((value) => value.value);
    dispatch(filterActions.setPastInvoicesCommunities(newObj));
    dispatch(filterActions.setFilterBillingCommunities(newObj));

    let newObj2 = selectedinvoices.map((value) => value.value);
    dispatch(filterActions.setFilterBillingStatus(newObj2));
    dispatch(
      billingActions.setTestsBillingApiUrl("?tab_key=invoice&" + modifiedString)
    );

    setShowToast(true);
    setDataFlag((prevState) => !prevState);
    hideModal();
  };

  const clearFilters = () => {
    setOtherLoader(true);
    var modifiedString = "";
    let prefix = "";
    let searchParam = "";
    prefix = "tab_key=invoice&";
    if (
      !modifiedString.includes("page") &&
      urlParams.toString().includes("page")
    ) {
      searchParam = "?" + prefix + modifiedString + `&page=${1}`;
    } else {
      searchParam = "?" + prefix + modifiedString;
    }

    searchParam = searchParam.replace("&&", "&");
    modifiedString = modifiedString.replace("&&", "&");
    history.replace({
      pathname: `/billing`,
      search: searchParam,
    });

    dispatch(
      billingActions.setInvoicesBillingApiUrl(
        "?tab_key=invoice&" + modifiedString
      )
    );
    dispatch(filterActions.setFilterBillingCommunities("ALL"));
    dispatch(filterActions.setPastInvoicesCommunities("ALL"));
    dispatch(filterActions.setFilterBillingStatus("ALL"));
    setShowToast(true);
    setDataFlag((prevState) => !prevState);
    hideModal();
  };

  const pastInvoicesCommunities = useSelector(
    (state) => state.filter.pastInvoicesCommunities
  );

  const filterBillingStatus = useSelector(
    (state) => state.filter.filterBillingStatus
  );
  const [invoiceCommunities, setInvoiceCommunities] = useState([]);
  const [selectedinvoices, setSelectedInvoices] = useState([]);

  useEffect(() => {
    const newEvent = billingData.all_communities.reduce((results, event) => {
      if (pastInvoicesCommunities.includes(event.community_id)) {
        results.push({
          value: event.community_id,
          label: event.community_name,
        });
      }
      return results;
    }, []);
    setInvoiceCommunities(newEvent);

    const newEvent2 = billingData.all_invoice_statuses.reduce(
      (results, event) => {
        if (filterBillingStatus.includes(event)) {
          results.push({
            value: event,
            label: event,
          });
        }
        return results;
      },
      []
    );
    setSelectedInvoices(newEvent2);
  }, []);

  const handleSelectCommunity = async (event) => {
    setInvoiceCommunities(event);
    // let values =
    //   event &&
    //   event.map((community) => {
    //     return community.value;
    //   });
    // dispatch(filterActions.setPastInvoicesCommunities(values));
  };

  const handleSelectInvoices = async (event) => {
    setSelectedInvoices(event);
    // let values =
    //   event &&
    //   event.map((status) => {
    //     return status.value;
    //   });

    // var result = values.filter((x) => x === "ALL");
    // if (result && result.length > 0) {
    //   dispatch(filterActions.setFilterBillingStatus("ALL"));
    //   return;
    // }
    // dispatch(filterActions.setFilterBillingStatus(values));
  };
  return (
    <div className="filter-modal-wrapper">
      <Modal
        className="filter-modal"
        show={isShowModal}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
      >
        <Modal.Header className="filter-modal-header">
          <h1 className="modal-header-title">Filters</h1>
          <span
            className="close-icon-modal "
            onClick={() => {
              //dispatch(filterActions.setPastInvoicesCommunities("ALL"));
              hideModal();
            }}
          >
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>

        <Modal.Body className="filter-modal-body show-select">
          <Fragment>
            <div className="field-wrapper">
              <label htmlFor="" className="label">
                {"Community Name"}
              </label>
              <Select
                name="communityId"
                id="communityId"
                placeholder={"Select Community"}
                onChange={handleSelectCommunity}
                isMulti
                value={invoiceCommunities}
                options={
                  billingData &&
                  billingData.all_communities &&
                  billingData.all_communities.map((community) => ({
                    value: community.community_id,
                    label: community.community_name,
                  }))
                }
                //closeMenuOnSelect={false}
                components={{ MultiValue }}
              />
            </div>
            <div className="field-wrapper">
              <label htmlFor="" className="label">
                {"Invoice"}
              </label>
              <Select
                name="invoiceStatus"
                id="invoiceStatus"
                placeholder={"Select Invoice"}
                onChange={handleSelectInvoices}
                value={selectedinvoices}
                //closeMenuOnSelect={false}
                isMulti
                options={
                  billingData?.all_invoice_statuses &&
                  billingData?.all_invoice_statuses.map((invoiceStatus) => ({
                    value: invoiceStatus,
                    label: invoiceStatus,
                  }))
                }
              />
            </div>
          </Fragment>
        </Modal.Body>
        <Modal.Footer className="filter-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16"
            onClick={clearFilters}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary-fill btn-primary-16"
            onClick={filterSubmissionHandler}
          >
            Apply Filters
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BilleeInvoicesModal;
const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    borderRadius: "4px",
    fontFamily: "Open Sans",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 3;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};
