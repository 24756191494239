import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DateTime from "../../DateTimePicker/DateTimePicker";
import Moment from "moment";
import axios from "axios";
import { dateParse } from "../../utils/helper";
import "./InitiateTest.scss";
import Loader2 from "../../UI/loader2/loader2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getWithExpiry } from "../../utils/helper";
import CloseModal from "../../../assets/close-outline.svg";
import CustomSelect from "../../General/filters/CustomSelect.Component";

const ProviderModal = (props) => {
  const {
    isShowModal,
    hideModal,
    showModal,
    groupTestId,
    type,
    testId,
    setDataFlag,
    setShowToast,
    testID,
  } = props;

  const [disabledBtn, setDisabledBtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [providerData, setProviderData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [providerID, setProviderID] = useState("");

  const token = "Bearer " + getWithExpiry("expiry_token");

  const fetchData = async () => {
    try {
      const url = `${process.env.REACT_APP_URL_PATH}/test/get_providers_list/?test_id=${testID}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: token, //the token holds the user token
        },
      });
      var resData = await response.data.data;
      setLoading(false);
      return resData;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };
  useEffect(() => {
    (async () => {
      const resData = await fetchData();
      setProviderData(resData);
    })();
  }, []);


  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const formSubmitHandler = () => {
    setLoading(true);

    setDisabledBtn(true);

    const fd = new FormData();

    fd.append("test_id", testID);
    fd.append("provider_id", providerID);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/test/resend_report/`,
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);

        hideModal();
        notifySuccess(response.data.message);
      
      })
      .catch((error) => {
        setLoading(false);

        setDisabledBtn(false);
        notifyError(error.response.data.message);
      });
  };
  const returnSelectedArray = async (object) => {
    if (!object || object.length <= 0) return [];
    var _selected = [];
    setProviderID(object.id);
    _selected.push(object.value.trim());
    return _selected;
  };
  const handleChange = async (e) => {
    const _selected = await returnSelectedArray(e);
    setSelectedData(_selected);
  };

  return (
    <div className="filter-modal-wrapper">
      <Modal
        className="filter-modal initiate-test-patient-modal modal-loader"
        show={true}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
      >
        <Modal.Header className="filter-modal-header">
          <h1 className="modal-header-title">Resend Report</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="close-modal" />
          </span>
        </Modal.Header>
        <Modal.Body className="filter-modal-body">
          <form
           
            method="post"
            className="add-corporate-form"
            onSubmit={formSubmitHandler}
          >
            <CustomSelect
              selectedValues={selectedData}
              labelText="Send the report to"
              name="provider"
              id="provider"
              isMulti={false}
              data={providerData}
              handleSelectChange={handleChange}
            />
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            id="close-btn"
            className="btn btn-secondary-fill btn-primary-16"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            type="submit"
            onClick={formSubmitHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={disabledBtn}
          >
            Send
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
};

export default ProviderModal;
