import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AdminUserActions } from "../../../store/adminUser";
import { RegUserActions } from "../../../store/regUser";
import { patientActions } from "../../../store/patient";
import { providerActions } from "../../../store/provider";
import { testActions } from "../../../store/test";
import { sidebarActions } from "../../../store/sidebar";
import AddIcon from "../../../assets/plus-list.svg";
import { getWithExpiry } from "../../utils/helper";
import Edit from "../../../assets/edit.svg";
import DisabledEdit from "../../../assets/disabled-edit.svg";
// import Delete_Btn from "../../../assets/delete-btn.png";
import Delete_Btn from "../../../assets/delete-btn.png";
import Save from "../../../assets/save.svg";
import EnableIcon from "../../../assets/checkicon.svg";
import EyeIcon from "../../../assets/eye-icon.svg";
import ResendIcon from "../../../assets/resend-icon.svg";
import DisabledResendIcon from "../../../assets/disabled-resend.svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import "./Table.scss";

const RowActions = (props) => {
  const {
    showAddPatientModalOpen,
    showDeleteModal,
    showDeleteProviderModal,
    showDisableModal,
    showEnableModal,
    showCreateTestModal,
    showAdminEditModal,
    showRegUserEditModal,
    handleClick,
    recordId,
    showTestsModal,
    actionType,
    setId,
    isEnable,
    isDefault,
    type,
    testInfo,
    name,
    subType,
    record,
    showEditModal,
    showResendModal,
    dataType,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const selectedRecordName = useSelector((state) => state.sidebar.recordName);
  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);

  const token = "Bearer " + getWithExpiry("expiry_token");

  const notifySuccesss = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const dispatchActionTypeCreate = () => {
    showCreateTestModal();
    if (type === "Test") {
      dispatch(testActions.setCreateTestInfo(testInfo));
    }

    setId(recordId, "create");
  };
  const dispatchActionTypeEdit = () => {
    if (type === "RegUser") {
      if (record.is_logged_in_user) {
        notifyError("Edit your details from the profile section");
      } else {
        showRegUserEditModal();
        dispatch(RegUserActions.setActionType("edit"));
      }
    } else if (type === "AdminUser") {
      if (record.is_logged_in_user) {
        notifyError("Edit your details from the profile section");
      } else {
        showAdminEditModal();
        dispatch(AdminUserActions.setActionType("edit"));
      }
    } else if (type === "Patient" || type === "patient") {
      showAddPatientModalOpen();
    }

    setId(recordId, "edit");
  };
  const dispatchActionTypeDelete = () => {
    if (type === "RegUser") {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_URL_PATH}/user/delete_regular_user/${recordId}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      })
        .then((response) => {
          const isDefaultKey = response.data.data.is_default;
          if (!isDefaultKey) {
            showDeleteModal();
            dispatch(RegUserActions.setActionType("delete"));
            setId(recordId, "delete");
          } else {
            notifyError("Default User Cannot be Deleted");
          }
        })
        .catch((error) => {
          notifyError(error.response.message);
        });
    } else if (type === "AdminUser") {
      if (!isDefault) {
        showDeleteModal();
        dispatch(AdminUserActions.setActionType("delete"));
        setId(recordId, "delete");
      } else {
        notifyError("Default User Cannot be Deleted");
      }
    } else if (type === "Patient" || type === "patient") {
      showDeleteModal();
      dispatch(patientActions.setActionType("delete"));
      setId(recordId, "delete");
    } else if (type === "Test") {
      showDeleteModal();
      dispatch(patientActions.setActionType("delete"));
      setId(recordId, "delete");
    } else if (
      type.toLowerCase() === "provider" &&
      dataType?.toLowerCase() === "provider"
    ) {
      showDeleteModal();
      dispatch(providerActions.setActionType("delete"));
      setId(record.user_id, "delete");
    } else if (
      type.toLowerCase() === "provider" &&
      dataType?.toLowerCase() !== "provider"
    ) {
      showDeleteModal();
      dispatch(patientActions.setActionType("delete"));
      setId(record.delete_id, "delete");
    }
  };

  const dispatchActionTypeDisable = () => {
    if (type === "RegUser") {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_URL_PATH}/user/disable_regular_user/${recordId}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      })
        .then((response) => {
          const isDefaultKey = response.data.data.is_default;
          if (!isDefaultKey) {
            showDisableModal();
            dispatch(RegUserActions.setActionType("disable"));
            setId(recordId, "disable");
          } else {
            notifyError("Default User Cannot be Disabled");
          }
        })
        .catch((error) => {
          notifyError(error.response.message);
        });
    } else if (type === "AdminUser") {
      if (!isDefault) {
        showDisableModal();
        dispatch(AdminUserActions.setActionType("disable"));
        setId(recordId, "disable");
      } else {
        notifyError("Default User Cannot be Disabled");
      }
    } else if (type === "Patient" || type === "patient") {
      showDisableModal();
      dispatch(patientActions.setActionType("disable"));
      setId(recordId, "disable");
    } else if (type === "provider" || type === "Provider") {
      showDisableModal();
      showDisableModal(record);
      setId(recordId, "disable");
    }

    setId(recordId, "disable");
  };

  const ProviderHandler = () => {
    if (record.is_logged_in_user) {
      notifyError("Edit your details from the profile section");
    } else {
      axios({
        url: `${process.env.REACT_APP_URL_PATH}/community/update_provider/${
          record.id ? record.id : record.provider_id
        }`,
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            showEditModal(response.data.data);
          }
        })
        .catch((error) => {
          showEditModal(false);
        });
    }
  };
  const dispatchActionTypeEnable = () => {
    showEnableModal();
    if (type === "RegUser") {
      dispatch(RegUserActions.setActionType("enable"));
    } else if (type === "AdminUser") {
      dispatch(AdminUserActions.setActionType("enable"));
    } else if (type === "Patient" || type === "patient") {
      dispatch(patientActions.setActionType("enable"));
    } else if (type === "provider" || type === "Provider") {
      showEnableModal(record);
    }

    setId(recordId, "enable");
  };

  const disabledClassName = isEnable ? "" : "disabled-icon";
  const defaultClassName = isDefault ? "disabled-icon" : "";

  const setBreadcrumb = (name, path) => {
    const record = {
      name: name,
      path: path,
    };
  };

  const handleDigAction = () => {
    if (type === "patient") {
      if (params.corporateID) {
        history.push(
          `/edit/corporates/${params.corporateID}/community/${params.communityID}/patient/${recordId}`
        );
      } else {
        history.push(
          `/edit/communities/${params.communityID}/edit/patient/${recordId}`
        );
      }
    } else if (type === "Patient") {
      history.push(`/edit/patients/${recordId}`);
    }
  };

  const resendEmail = () => {
    if (record.is_logged_in_user) {
      notifyError("You cannot send email to yourself");
    } else {
      showResendModal();
      setId(record?.user_id, "resend");
    }
  };
  return (
    <Fragment>
      {type === "AdminUser" && !subType && (
        <td>
          <span
            title="Edit"
            className="td-action"
            onClick={dispatchActionTypeEdit}
          >
            <img
              src={record.is_logged_in_user ? DisabledEdit : Edit}
              alt="edit-icon"
            />
          </span>
          <span
            title="Resend Email"
            className="td-action"
            onClick={resendEmail}
          >
            <img
              src={record.is_logged_in_user ? DisabledResendIcon : ResendIcon}
              alt="resend-icon"
            />
          </span>
          <span
            title="Delete"
            className="td-action"
            // className={`td-action ${disabledClassName}${defaultClassName}`}
            onClick={dispatchActionTypeDelete}
          >
            <img
              src={Delete_Btn}
              alt="delete-icon"
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
            />
          </span>
        </td>
      )}
      {type === "RegUser" && !subType && (
        <td>
          <span
            title="Edit"
            className="td-action"
            onClick={dispatchActionTypeEdit}
          >
            <img
              src={record.is_logged_in_user ? DisabledEdit : Edit}
              alt="edit-icon"
            />
          </span>
          <span
            title="Resend Email"
            className="td-action"
            onClick={resendEmail}
          >
            <img
              src={record.is_logged_in_user ? DisabledResendIcon : ResendIcon}
              alt="edit-icon"
            />
          </span>
        </td>
      )}

      {(type === "Patient" || type === "patient") && !subType && (
        <td>
          <span title="Details" className="td-action" onClick={handleDigAction}>
            <img src={EyeIcon} alt="" />
          </span>
          <span
            title="Edit"
            className="td-action"
            onClick={dispatchActionTypeEdit}
          >
            <img src={Edit} alt="" />
          </span>

          {!isCatchUAdmin ? (
            // && !userRole.includes("Corporate HQ Administrator")
            <>
              {/* uncomment the following to get the delete functionality */}
              <span
                title="Delete"
                className="td-action"
                // className={`td-action ${disabledClassName}${defaultClassName}`}
                onClick={dispatchActionTypeDelete}
              >
                <img
                  src={Delete_Btn}
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                  alt="delete-icon"
                />
              </span>

              {/* uncomment the following to get the enable/disable functionality */}
              {/* {isEnable && (
                <span
                  title="Disable"
                  className="td-action"
                  // className={`td-action ${disabledClassName}${defaultClassName}`}
                  onClick={dispatchActionTypeDisable}
                >
                  <img src={Save} alt="disable-icon" />
                </span>
              )}
              {!isEnable && (
                <span
                  title="Enable"
                  className="td-action"
                  onClick={dispatchActionTypeEnable}
                >
                  <img src={EnableIcon} alt="enable-icon" />
                </span>
              )} */}
            </>
          ) : (
            <>
              {isCatchUAdmin && (
                // (permissions.includes("Community") || permissions.includes("Patients")) &&
                <>
                  {/* uncomment the following to get the delete functionality */}
                  <span
                    title="Delete"
                    className="td-action"
                    // className={`td-action ${disabledClassName}${defaultClassName}`}
                    onClick={dispatchActionTypeDelete}
                  >
                    <img
                      src={Delete_Btn}
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      alt="delete-icon"
                    />
                  </span>

                  {/* uncomment the following to get the enable/disable functionality */}
                  {/* {isEnable && (
                      <span
                        title="Disable"
                        className="td-action"
                        // className={`td-action ${disabledClassName}${defaultClassName}`}
                        onClick={dispatchActionTypeDisable}
                      >
                        <img src={Save} alt="disable-icon" />
                      </span>
                    )}
                    {!isEnable && (
                      <span
                        title="Enable"
                        className="td-action"
                        onClick={dispatchActionTypeEnable}
                      >
                        <img src={EnableIcon} alt="enable-icon" />
                      </span>
                    )} */}
                </>
              )}
            </>
          )}
        </td>
      )}
      {type === "Patient" && subType === "Patient History" && <></>}
      {(type === "Provider" || type === "provider") && (
        <td>
          <span title={"Edit"} className="td-action" onClick={ProviderHandler}>
            <img src={Edit} alt="" />
          </span>
          <span
            title="Resend Email"
            className="td-action"
            onClick={resendEmail}
          >
            <img src={ResendIcon} alt="edit-icon" />
          </span>
          <span
            title="Delete"
            className="td-action"
            // className={`td-action ${disabledClassName}${defaultClassName}`}
            onClick={dispatchActionTypeDelete}
          >
            <img
              src={Delete_Btn}
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
              alt="delete-icon"
            />
          </span>
        </td>
      )}

      {type === "Test" && subType === "upcoming" && (
        <td>
          <span title="View" className="td-action" onClick={handleClick}>
            <img
              src={EyeIcon}
              alt="delete-icon"
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
            />
          </span>
          <span
            title="Delete"
            className="td-action"
            // className={`td-action ${disabledClassName}${defaultClassName}`}
            onClick={dispatchActionTypeDelete}
          >
            <img
              src={Delete_Btn}
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
              alt="delete-icon"
            />
          </span>
        </td>
      )}

      {type === "Test" && subType === "schedule" && (
        <td>
          <span title="Add" className="td-action" onClick={handleClick}>
            <img src={AddIcon} alt="add-icon" />
          </span>
        </td>
      )}
    </Fragment>
  );
};

export default RowActions;
