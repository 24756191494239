import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import images
import Filter_Icon from "../../../assets/filter-icon.svg";
import Edit from "../../../assets/edit.svg";
import Delete_Icon from "../../../assets/delete-btn.png";
import Save from "../../../assets/save.svg";
import Eye from "../../../assets/eye-icon.svg";
import Preview from "../../../assets/preview.svg";
import FilterClose from "../../../assets/filter-close.svg";
import Loader from "../../UI/loader/loader";
import AddCommunityModal from "../../Communities/Modals/AddCommunityModal/AddCommunityModal";
import ImportCorporateModal from "../../Corporate/Modals/ImportCorporateModal/ImportCorporateModal";
import ImportCommunityModal from "../../Corporate/Modals/ImportCommunityModal";
// import FiltersModal from "../../Corporate/Modals/FiltersModal/FiltersModal";
import FiltersModal from "../../Corporate/Modals/FiltersModal/CommunityFilter";
import TableBody from "./TableBody";
import { filterActions } from "../../../store/filter";
import { rowOrderActions } from "../../../store/orders";
import { communityActions } from "../../../store/community";
import Pagination from "../../UI/Pagination/Pagination";
import useQueryString from "../../../hooks/useQueryString";
//Ordering Helpers
import {
  removeEmptyParams,
  setOrderAndIcon,
} from "../../../services/helpers/sorting.hepler";
import AuditLogsModal from "../../../components/General/AuditLogModal/AuditLogsModal.Component";
import AuditLogButton from "../../General/AuditLogModal/AuditLogButton.Component";
import SearchInput from "../../General/filters/SearchInput.Component";
import FilterButton from "../../General/filters/FilterButton.Component";
import { billingActions } from "../../../store/billing";

// import "./Table.scss";

const AddImport = ({ showImportCorpModal, showModal }) => {
  return (
    <>
      <li className="single-rc">
        <button
          className="btn btn-primary-outline"
          onClick={showImportCorpModal}
        >
          Import
        </button>
      </li>
      <li className="single-rc">
        <button
          className="btn btn-primary-fill btn-primary-16"
          onClick={showModal}
        >
          Add
        </button>
      </li>
    </>
  );
};

const TableData = (props) => {
  const {
    tableHeadings,
    data,
    type,
    deleteCorporate,
    totalRecords,
    setDataFlag,
    setShowToast,
    otherLoader,
    setOtherLoader,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const urlParams = useQueryString();
  const [addComModalOpen, setAddComModalOpen] = useState(false);
  const [importCorpModalOpen, setImportCorpModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);

  const showModal = () => setAddComModalOpen(true);
  const hideModal = () => setAddComModalOpen(false);
  const showImportCorpModal = () => setImportCorpModalOpen(true);
  const hideImportCorpModal = () => setImportCorpModalOpen(false);
  //const showFilterModal = () => setFilterModalOpen(true);
  const hideFilterModal = () => setFilterModalOpen(false);

  const [auditLogModalOpen, setAuditLogModalOpen] = useState(false);
  const showAuditLogModal = () => setAuditLogModalOpen(true);
  const hideAuditLogModal = () => setAuditLogModalOpen(false);

  const searchFilteredText = useSelector((state) => state.filter.filterText);
  const filteredCountry = useSelector((state) => state.filter.filteredCountry);
  const filteredCity = useSelector((state) => state.filter.filteredCity);
  const filteredState = useSelector((state) => state.filter.filteredState);
  const filteredZip = useSelector((state) => state.filter.filteredZip);

  //const [searchVal, setSearchVal] = useState(searchFilteredText);

  const [search, setSearch] = useState(false);
  const [orderBy, setOrderBy] = useState("");
  const [headingName, setHeadingName] = useState("");

  const handleSearchChange = (e) => {
    const { value, name } = e.target;
    dispatch(filterActions.setFilterText(value));
    //setSearchVal(value);
  };

  const communityFormData = {
    name: "",
    address_01: "",
    address_02: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    corporate: "",
    first_name: "",
    last_name: "",
    email: "",
    telephone: "",
    role: "",
  };
  const EquipmentsFormData = {
    "Community Name": "",
    "Billee Name": "",
    "Billee Company": "",
    "Equipment ID": "",
    "Equipment Price": "",
  };
  const orderMapping = {
    ID: "community_id",
    Name: "name",
    Corporate: "corporate",
    "Address 01": "address_1",
    "Address 02": "address_2",
    State: "state",
    City: "city",
    Zip: "zip",
  };
  const EquipmentsorderMapping = {
    "Community Name": "community_name",
    "Billee Name": "billee_name",
    "Billee Company": "billee_company",
    "Equipment ID": "equipment_id",
    "Equipment Price": "equipment_price",
  };

  const rowOrderState = useSelector(
    (state) => state.rowOrder.rowOrderCommunity
  );
  const EquipmentsrowOrderState = useSelector(
    (state) => state.rowOrder.rowOrderBillingEquipments
  );

  const orderQuery = useSelector((state) => state.rowOrder.orderQuery);
  const EquipmentorderQuery = useSelector((state) => state.rowOrder.orderQuery);
  const [orderState, setOrderState] = useState(rowOrderState);
  const [EquipmentsorderState, setEquipmentsOrderState] = useState(
    EquipmentsrowOrderState
  );

  useEffect(() => {
    updateUrl();
  }, [orderState, EquipmentsorderState]);

  const buildRowOrderQuery = () => {
    let newOrderQuery = orderQuery.slice();
    if (orderMapping[headingName]) {
      let prevOrder =
        rowOrderState[orderMapping[headingName]].order === "asc"
          ? "desc"
          : "asc";
      let prevInstance = `&${orderMapping[headingName]}:orderby=${prevOrder}`;
      if (newOrderQuery.includes(prevInstance)) {
        newOrderQuery = newOrderQuery.replace(prevInstance, "");
      }
      if (!search) {
        newOrderQuery = `&${orderMapping[headingName]}:orderby=${
          rowOrderState[orderMapping[headingName]].order
        }`;
      }
    } else if (EquipmentsorderMapping[headingName]) {
      let prevOrder =
        EquipmentsrowOrderState[EquipmentsorderMapping[headingName]].order ===
        "asc"
          ? "desc"
          : "asc";
      let prevInstance = `&${EquipmentsorderMapping[headingName]}:orderby=${prevOrder}`;
      if (newOrderQuery.includes(prevInstance)) {
        newOrderQuery = newOrderQuery.replace(prevInstance, "");
      }
      if (!search) {
        newOrderQuery = `&${EquipmentsorderMapping[headingName]}:orderby=${
          EquipmentsrowOrderState[EquipmentsorderMapping[headingName]].order
        }`;
      }
    }
    dispatch(rowOrderActions.setOrderQuery(newOrderQuery));

    return newOrderQuery;
  };

  const buildFilterQuery = () => {
    let filterQuery = "";

    filterQuery =
      `country:eq=${filteredCountry}` +
      `&city:eq=${filteredCity}` +
      `&state:eq=${filteredState}` +
      `&zip:eq=${filteredZip}`;
    return filterQuery;
  };

  const buildSeatchTextQuery = () => {
    let searchQuery = "";
    searchQuery =
      searchFilteredText !== "" ? `search_query=${searchFilteredText}` : "";
    return searchQuery;
  };

  const updateUrl = () => {
    const searchQuery = buildSeatchTextQuery();
    const newOrderQuery = buildRowOrderQuery();
    const filterQuery = buildFilterQuery();

    let customQuery = "";
    customQuery = `${searchQuery}${newOrderQuery}&${filterQuery}`;
    let modifiedString = removeEmptyParams(customQuery);

    let searchParam = "";
    if (!modifiedString) {
      searchParam =
        "?" +
        `${
          search === true
            ? modifiedString + `page=${search ? 1 : urlParams.get("page")}`
            : urlParams.toString()
        }`;
    } else {
      if (
        !modifiedString.includes("page") &&
        urlParams.toString().includes("page")
      ) {
        searchParam =
          "?" + modifiedString + `&page=${search ? 1 : urlParams.get("page")}`;
      } else {
        searchParam = "?" + modifiedString;
      }
    }

    searchParam = searchParam.replace("&&", "&");
    // history.replace({
    //   pathname: "/communities",
    //   search: searchParam,
    // });

    let str =
      modifiedString && modifiedString !== "" ? "?" + modifiedString : "";
    if (type == "Equipments") {
      dispatch(billingActions.setEquipmentsApiUrl(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else {
      dispatch(communityActions.setCommunityApiUrl(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    }
  };

  const onClickTableHeading = async (e) => {
    const { id } = e.target;
    if (id === "Actions" || id === "View") return;
    setOtherLoader(true);
    setSearch(false);
    setHeadingName(id);
    let name = "";
    let obj;
    if (type !== "Equipments") {
      switch (id) {
        case "ID":
          name = "community_id";
          obj = setOrderAndIcon(rowOrderState[name]);
          dispatch(
            rowOrderActions.setCommunityOrder({
              ...rowOrderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              name: { state: 1, order: "", icon: "" },
              corporate: { state: 1, order: "", icon: "" },
              address_1: { state: 1, order: "", icon: "" },
              address_2: { state: 1, order: "", icon: "" },
              state: { state: 1, order: "", icon: "" },
              city: { state: 1, order: "", icon: "" },
              zip: { state: 1, order: "", icon: "" },
            })
          );
          setOrderState({
            ...orderState,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
          });

          return;
        case "Name":
          name = "name";
          obj = setOrderAndIcon(rowOrderState[name]);
          dispatch(
            rowOrderActions.setCommunityOrder({
              ...rowOrderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              community_id: { state: 1, order: "", icon: "" },
              corporate: { state: 1, order: "", icon: "" },
              address_1: { state: 1, order: "", icon: "" },
              address_2: { state: 1, order: "", icon: "" },
              state: { state: 1, order: "", icon: "" },
              city: { state: 1, order: "", icon: "" },
              zip: { state: 1, order: "", icon: "" },
            })
          );
          setOrderState({
            ...orderState,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
          });

          return;
        case "Corporate":
          name = "corporate";
          obj = setOrderAndIcon(rowOrderState[name]);
          dispatch(
            rowOrderActions.setCommunityOrder({
              ...rowOrderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              name: { state: 1, order: "", icon: "" },
              community_id: { state: 1, order: "", icon: "" },
              address_1: { state: 1, order: "", icon: "" },
              address_2: { state: 1, order: "", icon: "" },
              state: { state: 1, order: "", icon: "" },
              city: { state: 1, order: "", icon: "" },
              zip: { state: 1, order: "", icon: "" },
            })
          );
          setOrderState({
            ...orderState,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
          });

          return;
        case "Address 01":
          name = "address_1";
          obj = setOrderAndIcon(rowOrderState[name]);
          dispatch(
            rowOrderActions.setCommunityOrder({
              ...rowOrderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              name: { state: 1, order: "", icon: "" },
              corporate: { state: 1, order: "", icon: "" },
              community_id: { state: 1, order: "", icon: "" },
              address_2: { state: 1, order: "", icon: "" },
              state: { state: 1, order: "", icon: "" },
              city: { state: 1, order: "", icon: "" },
              zip: { state: 1, order: "", icon: "" },
            })
          );
          setOrderState({
            ...orderState,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
          });

          return;
        case "Address 02":
          name = "address_2";
          obj = setOrderAndIcon(rowOrderState[name]);
          dispatch(
            rowOrderActions.setCommunityOrder({
              ...rowOrderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              name: { state: 1, order: "", icon: "" },
              corporate: { state: 1, order: "", icon: "" },
              address_1: { state: 1, order: "", icon: "" },
              community_id: { state: 1, order: "", icon: "" },
              state: { state: 1, order: "", icon: "" },
              city: { state: 1, order: "", icon: "" },
              zip: { state: 1, order: "", icon: "" },
            })
          );
          setOrderState({
            ...orderState,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
          });

          return;
        case "State":
          name = "state";
          obj = setOrderAndIcon(rowOrderState[name]);
          dispatch(
            rowOrderActions.setCommunityOrder({
              ...rowOrderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              name: { state: 1, order: "", icon: "" },
              corporate: { state: 1, order: "", icon: "" },
              address_1: { state: 1, order: "", icon: "" },
              address_2: { state: 1, order: "", icon: "" },
              community_id: { state: 1, order: "", icon: "" },
              city: { state: 1, order: "", icon: "" },
              zip: { state: 1, order: "", icon: "" },
            })
          );
          setOrderState({
            ...orderState,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
          });

          return;
        case "City":
          name = "city";
          obj = setOrderAndIcon(rowOrderState[name]);
          dispatch(
            rowOrderActions.setCommunityOrder({
              ...rowOrderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              name: { state: 1, order: "", icon: "" },
              corporate: { state: 1, order: "", icon: "" },
              address_1: { state: 1, order: "", icon: "" },
              address_2: { state: 1, order: "", icon: "" },
              state: { state: 1, order: "", icon: "" },
              community_id: { state: 1, order: "", icon: "" },
              zip: { state: 1, order: "", icon: "" },
            })
          );
          setOrderState({
            ...orderState,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
          });

          return;
        case "Zip":
          name = "zip";
          obj = setOrderAndIcon(rowOrderState[name]);
          dispatch(
            rowOrderActions.setCommunityOrder({
              ...rowOrderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              name: { state: 1, order: "", icon: "" },
              corporate: { state: 1, order: "", icon: "" },
              address_1: { state: 1, order: "", icon: "" },
              address_2: { state: 1, order: "", icon: "" },
              state: { state: 1, order: "", icon: "" },
              city: { state: 1, order: "", icon: "" },
              community_id: { state: 1, order: "", icon: "" },
            })
          );
          setOrderState({
            ...orderState,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
          });

          return;
        default:
          return;
      }
    } else {
      switch (id) {
        case "Community Name":
          name = "community_name";
          obj = setOrderAndIcon(EquipmentsrowOrderState[name]);
          dispatch(
            rowOrderActions.setRowOrderBillingEquipments({
              ...EquipmentsrowOrderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              billee_name: { state: 1, order: "", icon: "" },
              billee_company: { state: 1, order: "", icon: "" },
              equipment_id: { state: 1, order: "", icon: "" },
              equipment_price: { state: 1, order: "", icon: "" },
            })
          );
          setEquipmentsOrderState({
            ...EquipmentsorderState,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
          });

          return;
        case "Billee Name":
          name = "billee_name";
          obj = setOrderAndIcon(EquipmentsrowOrderState[name]);
          dispatch(
            rowOrderActions.setRowOrderBillingEquipments({
              ...EquipmentsrowOrderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              community_name: { state: 1, order: "", icon: "" },
              billee_company: { state: 1, order: "", icon: "" },
              equipment_id: { state: 1, order: "", icon: "" },
              equipment_price: { state: 1, order: "", icon: "" },
            })
          );
          setEquipmentsOrderState({
            ...EquipmentsorderState,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
          });

          return;
        case "Billee Company":
          name = "billee_company";
          obj = setOrderAndIcon(EquipmentsrowOrderState[name]);
          dispatch(
            rowOrderActions.setRowOrderBillingEquipments({
              ...EquipmentsrowOrderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              community_name: { state: 1, order: "", icon: "" },
              billee_name: { state: 1, order: "", icon: "" },
              equipment_id: { state: 1, order: "", icon: "" },
              equipment_price: { state: 1, order: "", icon: "" },
            })
          );
          setEquipmentsOrderState({
            ...EquipmentsorderState,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
          });

          return;
        case "Equipment ID":
          name = "equipment_id";
          obj = setOrderAndIcon(EquipmentsrowOrderState[name]);
          dispatch(
            rowOrderActions.setRowOrderBillingEquipments({
              ...EquipmentsrowOrderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              community_name: { state: 1, order: "", icon: "" },
              billee_name: { state: 1, order: "", icon: "" },
              billee_company: { state: 1, order: "", icon: "" },
              equipment_price: { state: 1, order: "", icon: "" },
            })
          );
          setEquipmentsOrderState({
            ...EquipmentsorderState,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
          });

          return;
        case "Equipment Price":
          name = "equipment_price";
          obj = setOrderAndIcon(EquipmentsrowOrderState[name]);
          dispatch(
            rowOrderActions.setRowOrderBillingEquipments({
              ...EquipmentsrowOrderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              community_name: { state: 1, order: "", icon: "" },
              billee_name: { state: 1, order: "", icon: "" },
              billee_company: { state: 1, order: "", icon: "" },
              equipment_id: { state: 1, order: "", icon: "" },
            })
          );
          setEquipmentsOrderState({
            ...EquipmentsorderState,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
          });

          return;
        default:
          return;
      }
    }
  };

  const onClearSorting = () => {
    setOtherLoader(true);
    dispatch(
      rowOrderActions.setCommunityOrder({
        ...rowOrderState,
        community_id: { state: 1, order: "", icon: "" },
        name: { state: 1, order: "", icon: "" },
        corporate: { state: 1, order: "", icon: "" },
        address_1: { state: 1, order: "", icon: "" },
        address_2: { state: 1, order: "", icon: "" },
        state: { state: 1, order: "", icon: "" },
        city: { state: 1, order: "", icon: "" },
        zip: { state: 1, order: "", icon: "" },
      })
    );
    dispatch(rowOrderActions.setOrderQuery(""));
    setHeadingName("");
    // history.replace({
    //   pathname: "/communities",
    //   search: "?page=1",
    // });

    dispatch(communityActions.setCommunityApiUrl(""));
    setShowToast(true);

    setDataFlag((prevState) => !prevState);
  };
  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      setSearch(true);
      setOtherLoader(true);
      setOrderState({
        ...orderState,
      });
    }
  };

  const onClearSearch = () => {
    setOtherLoader(true);
    dispatch(filterActions.setFilterText(""));
    setSearch(true);
    setOrderState({
      ...orderState,
    });
  };
  const removeCityFilter = (city) => {
    if (
      filteredState.length === 0 &&
      filteredZip.length === 0 &&
      filteredCity.length === 1
    ) {
      dispatch(filterActions.setFilteredCity([]));
      history.replace({
        pathname: "/communities",
        search: "",
      });
    } else {
      const updatedCity = filteredCity.filter((item) => item !== city);
      dispatch(filterActions.setFilteredCity(updatedCity));
    }
    setOrderState({
      ...orderState,
    });
  };
  const removeStateFilter = (state) => {
    if (
      filteredCity.length === 0 &&
      filteredZip.length === 0 &&
      filteredState.length === 1
    ) {
      dispatch(filterActions.setFilteredsState([]));
      history.replace({
        pathname: "/communities",
        search: "",
      });
    } else {
      const updatedState = filteredState.filter((item) => item !== state);
      dispatch(filterActions.setFilteredsState(updatedState));
    }
    setOrderState({
      ...orderState,
    });
  };
  const removeZipFilter = (zip) => {
    if (
      filteredCity.length === 0 &&
      filteredState.length === 0 &&
      filteredZip.length === 1
    ) {
      dispatch(filterActions.setFilteredZip([]));
      history.replace({
        pathname: "/communities",
        search: "",
      });
    } else {
      const updatedZip = filteredZip.filter((item) => item !== zip);
      dispatch(filterActions.setFilteredZip(updatedZip));
    }
    setOrderState({
      ...orderState,
    });
  };

  const clearFilters = () => {
    var modifiedString = "";
    dispatch(communityActions.setCommunityApiUrl("?" + modifiedString));
    dispatch(filterActions.setFilteredCity([]));
    dispatch(filterActions.setFilteredsState([]));
    dispatch(filterActions.setFilteredZip([]));
    setOrderState({
      ...orderState,
    });
  };
  const showFilterModal = () => {
    // buildRowOrderQuery();
    setFilterModalOpen(true);
  };
  return (
    <Fragment>
      {/* For Audit Log */}
      {auditLogModalOpen && (
        <AuditLogsModal
          isShowModal={auditLogModalOpen}
          hideModal={hideAuditLogModal}
          LogTitle={"Audit Log"}
          type={type}
          dataType={""}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          refId={0}
        />
      )}

      {addComModalOpen && (
        <AddCommunityModal
          type={type}
          isShowModal={addComModalOpen}
          hideModal={hideModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}

      {importCorpModalOpen && (
        <ImportCorporateModal
          isShowModal={importCorpModalOpen}
          hideModal={hideImportCorpModal}
          dataType={type}
          modalFormData={communityFormData}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      {filterModalOpen && (
        <FiltersModal
          isShowModal={filterModalOpen}
          hideModal={hideFilterModal}
          setShowToast={setShowToast}
          setDataFlag={setDataFlag}
          orderBy={orderQuery}
          setOtherLoader={setOtherLoader}
        />
      )}

      {otherLoader ? (
        <Loader />
      ) : (
        <div className="corpoarte-table">
          <div className="table-header-filter">
            <div className="cor-header-change">
              <h1 className="page-name-heading">
                {type == "Equipments" ? "Equipments" : "Communities"}
              </h1>
              <div className="right-cor-header">
                <ul className="rc-header-listing">
                  {/* <li className="single-rc">
                  <button onClick={onClearSorting}>Clear Sorting</button>
                </li> */}
                  {/* <li className="single-rc">
                  <button onClick={onClearSearch}>Clear Search</button>
                </li> */}
                  <li className="single-rc">
                    <SearchInput
                      value={searchFilteredText}
                      handleChange={handleSearchChange}
                      onKeyEnter={onEnterPress}
                      onClearSearch={onClearSearch}
                    />
                  </li>
                  {type == "Equipments" ? (
                    ""
                  ) : (
                    <>
                      <li className="single-rc">
                        <FilterButton handleClick={showFilterModal} />
                      </li>
                      <li className="single-rc">
                        <AuditLogButton handleClick={showAuditLogModal} />
                      </li>

                      {!isCatchUAdmin &&
                      (userRole.includes("Super Admin") ||
                        userRole.includes("Corporate HQ Administrator")) ? (
                        <AddImport
                          showImportCorpModal={showImportCorpModal}
                          showModal={showModal}
                        />
                      ) : (
                        <>
                          {isCatchUAdmin &&
                            (userRole.includes("Super Admin") ||
                              userRole.includes("Corporate HQ Administrator") ||
                              permissions.includes("Community")) && (
                              <AddImport
                                showImportCorpModal={showImportCorpModal}
                                showModal={showModal}
                              />
                            )}
                        </>
                      )}
                    </>
                  )}
                </ul>
              </div>
            </div>
            {(filteredCity.length !== 0 ||
              filteredState.length !== 0 ||
              filteredZip.length !== 0) && (
              <div className="filter-header">
                <h2 className="apply-filter-name">Applied Filters:</h2>
                <ul className="apply-filter-list">
                  {filteredCity.map((city) => (
                    <li className="apply-filter-single">
                      <h3 className="filter-name-type">
                        City: <strong>{city}</strong>{" "}
                      </h3>
                      <img
                        src={FilterClose}
                        alt=""
                        onClick={() => removeCityFilter(city)}
                      />
                    </li>
                  ))}
                  {filteredState.map((state) => (
                    <li className="apply-filter-single">
                      <h3 className="filter-name-type">
                        State: <strong>{state}</strong>{" "}
                      </h3>
                      <img
                        src={FilterClose}
                        alt=""
                        onClick={() => removeStateFilter(state)}
                      />
                    </li>
                  ))}
                  {filteredZip.map((zip) => (
                    <li className="apply-filter-single">
                      <h3 className="filter-name-type">
                        Zip: <strong>{zip}</strong>{" "}
                      </h3>
                      <img
                        src={FilterClose}
                        alt=""
                        onClick={() => removeZipFilter(zip)}
                      />
                    </li>
                  ))}
                </ul>
                <Link
                  to="/communities"
                  className="clear-all-filter"
                  onClick={clearFilters}
                >
                  Clear all filters
                </Link>
              </div>
            )}
          </div>
          <div className="table-responsive">
            <table className="table" id="en-width">
              <thead>
                <tr>
                  {tableHeadings &&
                    tableHeadings.map((tableHead) => (
                      <th
                        id={tableHead}
                        name={tableHead}
                        key={tableHead}
                        onClick={onClickTableHeading}
                      >
                        {tableHead}
                        {tableHead !== "Actions" && (
                          <>
                            {rowOrderState[orderMapping[tableHead]]?.icon ===
                            "" ? (
                              <span className="icons-th">
                                <span></span>
                                <span className="order-4"></span>
                              </span>
                            ) : (
                              <span className="icons-th">
                                <span
                                  className={
                                    rowOrderState[orderMapping[tableHead]]
                                      ?.icon === "asc"
                                      ? "caret-4-asc-icon"
                                      : "caret-4-desc-icon"
                                  }
                                ></span>
                                <span className="order-4"></span>
                              </span>
                            )}
                          </>
                        )}
                      </th>
                    ))}

                  {!type === "GroupTestDetail" && <th>Actions</th>}
                </tr>
              </thead>
              <TableBody
                type={type}
                data={data}
                deleteCorporate={(corpId) => deleteCorporate(corpId)}
                setDataFlag={setDataFlag}
                setShowToast={setShowToast}
              />
            </table>
          </div>
          {type == "Equipments" ? (
            <Pagination
              data={data}
              totalRecords={totalRecords}
              type="equipments"
              setOtherLoader={setOtherLoader}
            />
          ) : (
            <Pagination
              data={data}
              totalRecords={totalRecords}
              type="community"
              setOtherLoader={setOtherLoader}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};

export default TableData;
