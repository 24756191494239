const iphoneModalFields = [
  { label: "iPhone Number", name: "iphone_number" },
  { label: "Description", name: "description" },
];

const corporateModalFields = [
  { label: "Name", name: "name" },
  { label: "Address 01", name: "address_01" },
  { label: "Address 02", name: "address_02" },
  { label: "City", name: "city" },
  { label: "State", name: "state" },
  { label: "Zip", name: "zip" },
  { label: "Country", name: "country" },
  { label: "First Name", name: "first_name" },
  { label: "Last Name", name: "last_name" },
  { label: "Email", name: "email" },
  { label: "Telephone", name: "telephone" },
  { label: "Role", name: "role" },
];
const corporateCommunityModalFields = [
  { label: "Name", name: "name" },
  { label: "Address 01", name: "address_01" },
  { label: "Address 02", name: "address_02" },
  { label: "City", name: "city" },
  { label: "State", name: "state" },
  { label: "Zip", name: "zip" },
  { label: "Country", name: "country" },
  { label: "First Name", name: "first_name" },
  { label: "Last Name", name: "last_name" },
  { label: "Email", name: "email" },
  { label: "Telephone", name: "telephone" },
  { label: "Role", name: "role" },
];
const communityModalFields = [
  { label: "Name", name: "name" },
  { label: "Address 01", name: "address_01" },
  { label: "Address 02", name: "address_02" },
  { label: "City", name: "city" },
  { label: "State", name: "state" },
  { label: "Zip", name: "zip" },
  { label: "Country", name: "country" },
  { label: "Corporate", name: "corporate" },
  { label: "First Name", name: "first_name" },
  { label: "Last Name", name: "last_name" },
  { label: "Email", name: "email" },
  { label: "Telephone", name: "telephone" },
  { label: "Role", name: "role" },
];

const userModalFieldsCorporate = [
  { label: "First Name", name: "firstName" },
  { label: "Last Name", name: "lastName" },
  { label: "Email", name: "email" },
  { label: "Telephone", name: "telephone" },
  { label: "Role", name: "role" },
  { label: "Community", name: "community" },
];

const userModalFieldsCommunity = [
  { label: "First Name", name: "firstName" },
  { label: "Last Name", name: "lastName" },
  { label: "Email", name: "email" },
  { label: "Telephone", name: "telephone" },
  { label: "Role", name: "role" },
];

const regularUserModalFields = [
  { label: "First Name", name: "firstName" },
  { label: "Last Name", name: "lastName" },
  { label: "Email", name: "email" },
  { label: "Telephone", name: "telephone" },
  { label: "Role", name: "role" },
  { label: "Corporate", name: "corporate" },
  { label: "Community", name: "community" },
];

const adminUserModalFields = [
  { label: "First Name", name: "firstName" },
  { label: "Last Name", name: "lastName" },
  { label: "Email", name: "email" },
  { label: "Telephone", name: "telephone" },
  { label: "Permissions", name: "permissions" },
];

const patientModalFields = [
  { label: "First Name", name: "firstName" },
  { label: "Last Name", name: "lastName" },
  { label: "DOB", name: "dob" },
  { label: "Email", name: "email" },
  { label: "Telephone", name: "telephone" },
  { label: "Community", name: "community" },
];

const patientCommunityModalFields = [
  { label: "First Name", name: "firstName" },
  { label: "Last Name", name: "lastName" },
  { label: "DOB", name: "dob" },
  { label: "Email", name: "email" },
  { label: "Telephone", name: "telephone" },
  //{ label: "Community", name: "community" },
];

const billeeModalFields = [
  { label: "Company", name: "company" },
  { label: "First Name", name: "firstName" },
  { label: "Last Name", name: "lastName" },
  { label: "Email", name: "email" },
  { label: "Telephone", name: "telephone" },
  { label: "Address 01", name: "address_01" },
  { label: "Address 02", name: "address_02" },
  { label: "City", name: "city" },
  { label: "State", name: "state" },
  { label: "Zip", name: "zip" },
  { label: "Country", name: "country" },
];

const providerModalFields = [
  { label: "Title", name: "title" },
  { label: "Suffix", name: "suffix" },
  { label: "First Name", name: "firstName" },
  { label: "Last Name", name: "lastName" },
  { label: "Email", name: "email" },
  { label: "Telephone", name: "telephone" },
  { label: "Billee Company", name: "billeeCompany" },
  { label: "Billee First Name", name: "billeeFirstName" },
  { label: "Billee Last Name", name: "billeeLastName" },
  { label: "Billee Email", name: "billeeEmail" },
  { label: "Billee Telephone", name: "billeeTelephone" },
  { label: "Address 01", name: "address_01" },
  { label: "Address 02", name: "address_02" },
  { label: "City", name: "city" },
  { label: "State", name: "state" },
  { label: "Zip", name: "zip" },
  { label: "Country", name: "country" },
  { label: "Community", name: "community" },
  { label: "Can Bill Community", name: "can_bill_community" },
];

export {
  iphoneModalFields,
  corporateModalFields,
  corporateCommunityModalFields,
  communityModalFields,
  userModalFieldsCorporate,
  userModalFieldsCommunity,
  regularUserModalFields,
  adminUserModalFields,
  patientModalFields,
  billeeModalFields,
  patientCommunityModalFields,
  providerModalFields,
};
