import React from "react";

const AuditLogButton = (props) => {
  const { handleClick } = props;

  return (
    <button className="btn btn-primary-outline" onClick={props.handleClick}>
      {" "}
      View Audit Log
    </button>
  );
};

export default AuditLogButton;
