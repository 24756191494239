import { Parser } from "html-to-react";
import DOMPurify from "dompurify";

export const formatCurrency = (cur) => {
  let usLocalCurrency = Intl.NumberFormat("en-US");
  return usLocalCurrency.format(+cur);
};

export const textHighlighter = (str, strArray) => {
  return strArray
    ?.map((item) => item.toLowerCase())
    .includes(str?.toLowerCase());
};

export const senitizeText = (text) => {
  if (text) {
    const parsedText = Parser().parse(DOMPurify.sanitize(text));
    const newStr = typeof parsedText == "object" ? parsedText?.props?.children?.replace(/[^a-zA-Z0-9]/g, "") : parsedText?.replace(/[^a-zA-Z0-9]/g, "");
    return newStr ? newStr : false;
  }
};
