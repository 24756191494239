import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DateTime from "../../DateTimePicker/DateTimePicker";
import Moment from "moment";
import axios from "axios";
import { dateParse } from "../../utils/helper";
import "./InitiateTest.scss";
import Loader2 from "../../UI/loader2/loader2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getWithExpiry } from "../../utils/helper";
import CloseModal from "../../../assets/close-outline.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const InitiateTest = (props) => {
  const {
    isShowModal,
    hideModal,
    type,
    testId,
    setDataFlag,
    setShowToast,
    initiateTestData,
    initiateTestID,
  } = props;


  const [disabledBtn, setDisabledBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scheduledError, setScheduledError] = useState("");

  const [iphoneList, setIphoneList] = useState(initiateTestData?.iphone_list);
  const [iphoneNumber, setIphoneNumber] = useState(
    initiateTestData?.iphone?.phone_number
  );

  const [phoneError, setPhoneError] = useState("");
  const [startDate, setStartDate] = useState(
    dateParse(initiateTestData?.date_scheduled)
  );

  let [initiateTest, setInitiateTest] = useState({
    remote: initiateTestData?.iphone?.remote,
    date_scheduled: dateParse(initiateTestData?.date_scheduled),
    iphone_id: initiateTestData?.iphone?.id,
  });

  const token = "Bearer " + getWithExpiry("expiry_token");

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInitiateTest({ ...initiateTest, [name]: value });
  };

  function diff_minutes(currentTime, selectedTime) {
    var diff = (selectedTime.getTime() - currentTime.getTime()) / 1000;
    diff /= 60;
    return Math.round(diff);
  }
  function ISOtoLongDate(isoString, locale = "en-US") {
    const options = { month: "numeric", day: "numeric", year: "numeric" };
    const date = new Date(isoString);
    const longDate = new Intl.DateTimeFormat(locale, options).format(date);
    return longDate;
  }
  const initiateTestSubmissionHandler = async () => {
    setLoading(true);
    setDisabledBtn(true);

    setScheduledError("");
    setPhoneError("");
    const fd = new FormData();

    if (initiateTest?.remote) {
      fd.append("remote", "");
    }
 

    if (iphoneNumber && !iphoneNumber.includes("+", 0)) {
      fd.append("iphone_number", "+" + iphoneNumber);
    } else if (iphoneNumber) {
      fd.append("iphone_number", iphoneNumber);
    }

    fd.append("iphone_id", initiateTest.iphone_id);

    await axios({
      url: `${process.env.REACT_APP_URL_PATH}/test/initiate_test/${initiateTestID}/`,
      method: "POST",
      data: fd,
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      })
      .catch((error) => {
        setLoading(false);
        setDisabledBtn(false);


        if (error.response.data.message === "Field Errors") {
          if ("date_scheduled" in error.response.data.data) {
            setScheduledError(error.response.data.data.date_scheduled);
          } else if ("number" in error.response.data.data.test_form) {
            setPhoneError(error.response.data.data.test_form.number);
          }
        } else {
          notifyError(error.response.data.message);
        }
        setDisabledBtn(false);
      });
  
  };

  return (
    <div className="filter-modal-wrapper">
      <Modal
        className="filter-modal initiate-test-patient-modal modal-loader"
        show={true}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
      >
        <Modal.Header className="filter-modal-header">
          <h1 className="modal-header-title">Initiate Test</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="close-modal" />
          </span>
        </Modal.Header>
        <Modal.Body className="filter-modal-body">
          <form
           
            method="post"
            className="add-corporate-form"
            onSubmit={initiateTestSubmissionHandler}
          >
            {/* <div className="field-wrapper">
              <label htmlFor="scheduled" className="label">
                Scheduled *
              </label>
              <DateTime startDate={startDate} setStartDate={setStartDate} />
              {scheduledError && <p className="error-text">{scheduledError}</p>}
            </div> */}
            <div className="field-wrapper">
              <label htmlFor="test_mobile" className="label">
                Test Mobile *
              </label>
              {initiateTest?.remote ? (
                <>
                  {/* <input
                    type="text"
                    id="iphone_number"
                    name="iphone_number"
                    className="custom-input"
                    placeholder="iPhone Number"
                    value={iphoneNumber}
                    onChange={(e) => {
                      const { name, value } = e.target;

                      const regex = /^([\s+0-9])+$/i;
                      if (value === "") {
                        // case to remove whole highlighted text
                        setIphoneNumber(value);
                        return;
                      }
                      if (regex.test(value) === false) return;

                      if (value.length === 1 && value === "+") {
                        // case to remove + sign when last number is removed
                        setIphoneNumber("");
                      } else if (value.length >= 1 && value[0] !== "+") {
                        // case to append + sign with first number
                        setIphoneNumber("+" + value);
                      } else {
                        // case to append other numbers
                        setIphoneNumber(value);
                      }
                    }}
                    // onBlur={stateBlurHandler}
                    required
                  /> */}
                  <PhoneInput
                    country={"us"}
                    value={iphoneNumber}
                    onChange={setIphoneNumber}
                  />
                  {phoneError && <p className="error-text">{phoneError}</p>}
                </>
              ) : (
                <select
                  name="iphone_id"
                  id="iphone_id"
                  className="custom-select-modal"
                  onChange={handleChange}
                  value={initiateTest?.iphone_id}
                >
                  {iphoneList &&
                    iphoneList.map((iphone) => {
                      return (
                        <option
                          key={iphone.id}
                          id={iphone.id}
                          value={iphone.id}
                        >
                          {iphone.number}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            id="close-btn"
            className="btn btn-secondary-fill btn-primary-16"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            // type="submit"
            onClick={initiateTestSubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={disabledBtn}
          >
            Send Link
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
};

export default InitiateTest;
