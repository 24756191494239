import { createSlice } from "@reduxjs/toolkit";

const initialRegUserState = {
  users: [],
  userRoles: [],
  idToBeDeleted: "",
  idToBeDisabled: "",
  idToBeEnabled: "",

  successMessage: "",
  actionType: "",
  idToBeEdited: "",
  communityList: [],
  corporateList: [],
  pageNumber: 1,
  regUserCurrentPage: 1,

  fileUploadData: [],

  regUserInfo: {},
  regularUsersTotalRecords: 0,

  regUserApiUrl: "",
  regUserOrderQuery: "",
};

const RegUserSlice = createSlice({
  name: "reguser",
  initialState: initialRegUserState,
  reducers: {
    addRegUser(state, action) {
      // useless, I thik so!
      state.users.push(action.payload);
    },

    setRegUserApiUrl(state, action) {
      state.regUserApiUrl = action.payload;
    },
    setRegUserOrderQuery(state, action) {
      state.regUserOrderQuery = action.payload;
    },
    setCorporatePageNumber(state, action) {
      state.pageNumber = action.payload;
    },
    setRegUserCurrentPage(state, action) {
      state.regUserCurrentPage = action.payload;
    },
    addRegUsers(state, action) {
      state.users = action.payload;
    },
    addUserRoles(state, action) {
      state.userRoles = action.payload;
    },
    editRegUser(state, payload) {
      const user = state.users.find((user) => user.id === payload);
    },
    deleteRegUser(state, payload) {},
    disableRegUser(state, payload) {},
    importRegUsers(state, payload) {
      state = payload;
    },
    addRegUserSuccessMessage(state, action) {
      state.successMessage = action.payload;
    },
    setIdToBeDeleted(state, action) {
      state.idToBeDeleted = action.payload;
    },
    setIdToBeDisabled(state, action) {
      state.idToBeDisabled = action.payload;
    },
    setIdToBeEnabled(state, action) {
      state.idToBeEnabled = action.payload;
    },
    setActionType(state, action) {
      state.actionType = action.payload;
    },
    setCommunityList(state, action) {
      state.communityList = action.payload;
    },
    setCorporateList(state, action) {
      state.corporateList = action.payload;
    },
    setFileUploadData(state, { payload }) {
      state.fileUploadData = payload;
    },
    
    setRegUserInfo(state, action) {
      state.regUserInfo = action.payload;
    },
    setIdToBeEdited(state, action) {
      state.idToBeEdited = action.payload;
    },
    setRegularUsersTotalRecords(state, action) {
      state.regularUsersTotalRecords = action.payload;
    },
  },
});

export const RegUserActions = RegUserSlice.actions;

export default RegUserSlice.reducer;
