import React, { Fragment, useState } from "react";

import DeleteModal from "../../Corporate/Modals/DeleteModal/DeleteModal";
import DisableModal from "../../Corporate/Modals/DisableModal/DisableModal";
import EnableModal from "../Modals/EnableModal/EnableModal";
import ShowTestsModal from "../../Corporate/Modals/ShowTestsModal/ShowTestsModal";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import EditCorporateModal from "../../EditCorporate/EditCorporateModal/EditCorporateModal";
import { sidebarActions } from "../../../store/sidebar";
import { corporateActions } from "../../../store/corporate";
import { stripSrciptRegex } from "../../../constants";

import "./Table.scss";
import RowActions from "./RowActions";
import axios from "axios";
import { textHighlighter } from "../../../utils/helper";

const TableBody = (props) => {
  const { type, data, dataType, setDataFlag, setShowToast } = props;
  const history = useHistory();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disableModalOpen, setDisableModalOpen] = useState(false);
  const [enableModalOpen, setEnableModalOpen] = useState(false);

  const [editCorporateModalShow, setEditCorporateModalShow] = useState(false);
  const showEditModalHandler = () => setEditCorporateModalShow(true);
  const hideEditModalHandler = () => setEditCorporateModalShow(false);

  const [showTests, setShowTests] = useState(false);
  const [corpId, setCorpId] = useState();

  const showDeleteModal = () => setDeleteModalOpen(true);
  const hideDeleteModal = () => setDeleteModalOpen(false);
  const showDisableModal = () => setDisableModalOpen(true);
  const hideDisableModal = () => setDisableModalOpen(false);
  const showEnableModal = () => setEnableModalOpen(true);
  const hideEnableModal = () => setEnableModalOpen(false);

  const showTestsModal = () => setShowTests(true);
  const hideTestsModal = () => setShowTests(false);

  const [deletedId, setDeletedId] = useState(null);
  const [enabledId, setEnabledId] = useState(null);
  const [disabledId, setDisabledId] = useState(null);

  const dispatch = useDispatch();

  const deleteModalActions = (corpId) => {
    axios
      .post({
        url: `${process.env.REACT_APP_URL_PATH}/corporate/delete_corporate/${corpId}`,
      })
      .then((response) => {
      })
      .catch((error) => {
      });
    hideDeleteModal();
  };

  const disbleModalActions = (corpId) => {
    axios
      .post({
        url: `${process.env.REACT_APP_URL_PATH}/corporate/disable_corporate/${corpId}`,
      })
      .then((response) => {
      })
      .catch((error) => {
      });
    hideDisableModal();
  };

  const handleActionType = (corpId) => {
    // const type = props.something();
    if (actionType === "delete") {
      deleteModalActions(corpId);
    } else if (actionType === "disable") {
      disbleModalActions(corpId);
    }
  };

  const actionType = useSelector((state) => state.corporate.actionType);

  const setId = (id, type) => {
    
    if (type === "delete") {
      setDeletedId(id);
    } else if (type === "disable") {
      setDisabledId(id);
    } else if (type === "enable") {
      setEnabledId(id);
    } else if (type === "edit") {
      dispatch(corporateActions.setIdToBeEdited(id));
    }
  };

  const setBreadcrumb = (name, path) => {
    const record = {
      name: name,
      path: path,
    };
  };
  const handleEditAction = (id, name) => {
    history.push(`/edit/corporates/${id}`);
  };

  const displayNotAvailableVal = (val) => (val ? val : "N/A");


    // for test
    const strArr = [
      "Alika Kennedy",
      "Carl Rocha",
    ];
    //for production
    const strArr1 = [
      "CatchURes",
      "JET WORLDWIDE ENTERPRISES INC.",
     
    ];
  return (
    <Fragment>
      {editCorporateModalShow && (
        <EditCorporateModal
          isShowModal={showEditModalHandler}
          hideModal={hideEditModalHandler}
          type="Corporate"
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          outerEdit={true}
        />
      )}
      {deleteModalOpen && (
        <DeleteModal
          type={type}
          isShowModal={deleteModalOpen}
          hideModal={hideDeleteModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          deletedId={deletedId}
        />
      )}
      {disableModalOpen && (
        <DisableModal
          type={type}
          isShowModal={disableModalOpen}
          hideModal={hideDisableModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          disabledId={disabledId}
        />
      )}
      {enableModalOpen && (
        <EnableModal
          type={type}
          isShowModal={enableModalOpen}
          hideModal={hideEnableModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          enabledId={enabledId}
        />
      )}
      {showTests && (
        <ShowTestsModal isShowModal={showTests} hideModal={hideTestsModal} />
      )}

      <tbody>
        {data.length !== 0 &&
          data.map((record) => (
            <tr key={Math.random()}>
              <td
              style={{
                color: `${
                  textHighlighter(record.corporate_name, strArr1)
                    ? "lightblue"
                    : ""
                }`,
                wordBreak: 'break-all'
              }}
                className="first-td-link"
                onClick={() =>
                  handleEditAction(record.id, record.corporate_name)
                }
              >
                {/* {record.corporate_name} */}
              {record.corporate_name.replace(stripSrciptRegex, " ")}
              </td>
              <td>{record.address.address_1}</td>
              <td>{displayNotAvailableVal(record.address.address_2)}</td>
              <td>{record.address.city}</td>
              <td>{record.address.state}</td>
              <td>
            
                {displayNotAvailableVal(record.address.zip)}
              </td>
              <RowActions
                isEnable={record.is_enabled}
                setId={setId}
                onClick={(e) => handleActionType(record.id)}
                recordId={record.id}
                showDeleteModal={showDeleteModal}
                showDisableModal={showDisableModal}
                showEnableModal={showEnableModal}
                showTestsModal={showTestsModal}
                showEditModalHandler={showEditModalHandler}
                actionType={record.actions}
                name={record.corporate_name}
                type={type}
              />
            </tr>
          ))}
        {type === "Corporate" && data.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        {type === "Community" && data.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        {dataType === "community" && data.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        {dataType === "user" && data.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
      </tbody>

      {type === "Community" && (
        <tbody>
          {data &&
            data.map((record) => (
              <tr key={Math.random()}>
                <td>{record.communityId}</td>
                <td>{record.name}</td>
                <td>{record.corporate}</td>
                <td>{record.address1}</td>
                <td>{record.address2}</td>
                <td>{record.state}</td>
                <td>{record.city}</td>
                <td>
                  {/* <span className="tag zip-tag">{record.zip}</span> */}
                  {record.zip}
                </td>
                <RowActions
                  showDeleteModal={showDeleteModal}
                  showDisableModal={showDisableModal}
                  name={record.name}
                />
              </tr>
            ))}
        </tbody>
      )}
    </Fragment>
  );
};

export default TableBody;
