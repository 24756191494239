import React from "react";
import Select from "react-select";

const CustomSelect = (props) => {
  const {
    labelText,
    name,
    id,
    isMulti,
    data,
    handleSelectChange,
    selectedValues,
  } = props;
  var options = [];
  if (name === "provider") {
    data.map((dataKey) => {
      if (dataKey) {
        options.push({
          id: dataKey.id,
          value: dataKey.user.first_name + " " + dataKey.user.last_name,
          label: dataKey.user.first_name + " " + dataKey.user.last_name,
        });
      }
    });
  } else {
    data.map((dataKey) => {
      if (dataKey) {
        options.push({
          value: dataKey.toString(),
          label: dataKey.toString(),
        });
      }
    });
  }

  var selectedOptions = [];

  selectedValues &&
    selectedValues.map((dataKey) => {
      selectedOptions.push({
        value: dataKey.toString(),
        label: dataKey.toString(),
      });
    });

  return (
    <div className="field-wrapper">
      <label htmlFor="" className="label">
        {labelText}
      </label>
      <Select
        id={id}
        name={name}
        className="basic-multi-select"
        classNamePrefix="select"
        value={selectedOptions ? selectedOptions : []}
        isMulti={isMulti}
        options={options}
        onChange={handleSelectChange}
        // defaultMenuIsOpen={true}
      />
    </div>
  );
};

export default CustomSelect;
