import React, { useState } from "react";
import { useCookies } from "react-cookie";

import Corporate from "../../assets/corporator.svg";
import Communities from "../../assets/communities.svg";
import Practice from "../../assets/practice.svg";
import Patients from "../../assets/patients.svg";
import Tests from "../../assets/test.svg";
import Providers from "../../assets/providers.svg";
import Users from "../../assets/user.svg";
import Billing from "../../assets/billing.svg";

const navItems = [
  { name: "Corporate", icon: Corporate, link: "/corporates" },
  { name: "Community", icon: Communities, link: "/communities" },
  { name: "Patients", icon: Patients, link: "/patients" },
  { name: "Tests", icon: Tests, link: "/tests" },
  { name: "Providers", icon: Providers, link: "/providers" },
  { name: "Users", icon: Users, link: "/users" },
  { name: "Equipments", icon: Users, link: "/equipments" },
  // { name: "Billing", icon: Billing, link: "/billing" },
];
const defaultRouteMapping = {
  Corporate: {
    roles: ["Super Admin", "Corporate HQ Administrator"],
    permission: "Corporate",
    route: "/corporates",
  },
  Community: {
    roles: [
      "Community Admin",
      "Individual Practice Admin",
      "Support Staff",
      "Provider",
    ],
    permission: "Community",
    route: "/communities",
  },
  Patients: {
    roles: [],
    permission: "Patients",
    route: "/patients",
  },

  Tests: {
    roles: [],
    permission: "Tests",
    route: "/tests",
  },
  Providers: {
    roles: [],
    permission: "Providers",
    route: "/providers",
  },
  Users: {
    roles: [],
    permission: "Users",
    route: "/users",
  },
  Billing: {
    roles: ["Billee"],
    permission: "Billing",
    route: "/billing",
  },
  Equipments: {
    roles: [],
    permission: "Equipments",
    route: "/equipments",
  },
};
const rolesMapping = {
  Corporate: ["Super Admin", "Corporate HQ Administrator"],
  Community: [
    "Super Admin",
    "Corporate HQ Administrator",
    "Community Admin",
    "Individual Practice Admin",
    "Support Staff",
    "Provider",
  ],
  Patients: [
    "Super Admin",
    "Corporate HQ Administrator",
    "Support Staff",
    "Community Admin",
    "Individual Practice Admin",
    "Provider",
  ],
  Tests: [
    "Super Admin",
    "Support Staff",
    "Provider",
    "Community Admin",
    "Individual Practice Admin",
  ],
  Providers: [
    "Super Admin",
    "Community Admin",
    "Individual Practice Admin",
    "Corporate HQ Administrator",
    "Support Staff",
  ],
  Users: [
    "Super Admin",
    "Corporate HQ Administrator",
    "Community Admin",
    "Individual Practice Admin",
  ],
  Billing: [
    "Super Admin",
    "Community Admin",
    "Individual Practice Admin",
    "Billee",
  ],
  Equipments: [
    "Super Admin",
    "Community Admin",
    "Individual Practice Admin",
    "Billee",
    "CatchU Admin"
  ],
};

// Users that are not catchu admin
function checkPermission(userType, userRole) {
  if (userRole.includes("Super Admin")) {
    return true;
  } else {
    const roles = rolesMapping[userType];
    for (let i = 0; i < roles.length; i++) {
      if (userRole.includes(roles[i])) {
        return true;
      }
    }
    return false;
  }
}

// catchu admin
function checkAdminPermission(userType, userRole, permissions) {
  const userRolePermission = checkPermission(userType, userRole);
  if (!userRolePermission) {
    if (!permissions.includes(userType)) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

function dateParse(dateTime) {
  let parts = dateTime.split(" ");
  let date = parts[0].split("-");
  let time = parts[1].split(":");
  if (time[2]) {
    return new Date(date[2], date[0] - 1, date[1], time[0], time[1], time[2]);
  } else {
    return new Date(date[2], date[0] - 1, date[1], time[0], time[1]);
  }
}

const logoutHandler = () => {
  localStorage.removeItem("expiry_token");
  sessionStorage.clear();
  // dispatch(authActions.setIsLoggedIn(false));
  // dispatch(authActions.setRememberMe(false));
  window.location.reload();
};
function setWithExpiry(key, value, ttl) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    logoutHandler();
    // localStorage.removeItem(key);
    return null;
  }
  return item.value;
}
function getOTPWithExpiry(key) {
  const itemStr = localStorage.getItem(key);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null

    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export {
  navItems,
  rolesMapping,
  defaultRouteMapping,
  checkPermission,
  checkAdminPermission,
  dateParse,
  setWithExpiry,
  getWithExpiry,
  getOTPWithExpiry,
};
