import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import ImportCorporateModal from "../../Corporate/Modals/ImportCorporateModal/ImportCorporateModal";
import FiltersModal from "../../Corporate/Modals/FiltersModal/FiltersModal";
import AddCommunityModal from "../Modals/AddCommunityModal/AddCommunityModal";
import AddUserModalReal from "../Modals/AddUserModalReal/AddUserModalReal";
// import AuditLogsModal from "../Modals/AuditLogModal/AuditLogsModal";
import AuditLogsModal from "../../../components/General/AuditLogModal/AuditLogsModal.Component";
import { filterActions } from "../../../store/filter";
import { rowOrderActions } from "../../../store/orders";
import { corporateActions } from "../../../store/corporate";
import { useParams, useHistory } from "react-router-dom";
import TableEditBody from "./TableEditBody";
import Pagination from "../../UI/Pagination/Pagination";
import useQueryString from "../../../hooks/useQueryString";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  removeEmptyParams,
  setOrderAndIcon,
} from "../../../services/helpers/sorting.hepler";
import SearchInput from "../../General/filters/SearchInput.Component";
import FilterButton from "../../General/filters/FilterButton.Component";
import CorporateCommunityFilter from "../../Corporate/Modals/FiltersModal/CorporateCommunityFilter";
import CorporateUserFilter from "../../Corporate/Modals/FiltersModal/CorporateUserFilter";
import Loader from "../../UI/loader/loader";
import FilterClose from "../../../assets/filter-close.svg";
import { Link } from "react-router-dom";
const TableDataEdit = (props) => {
  const {
    tableHeadings,
    data,
    type,
    dataType,
    corporateId,
    heading,
    totalRecords,
    setDataFlag,
    setShowToast,
    TabKey,
    otherLoader,
    setOtherLoader,
  } = props;

  const dispatch = useDispatch();
  const urlParams = useQueryString();
  const history = useHistory();
  // const [addCorpModalOpen, setAddCorpModalOpen] = useState(false);
  const [addCommunityModalOpen, setAddCommunityModalOpen] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [importCorpModalOpen, setImportCorpModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const [auditLogModalOpen, setAuditLogModalOpen] = useState(false);
  const showAuditLogModal = () => setAuditLogModalOpen(true);
  const hideAuditLogModal = () => setAuditLogModalOpen(false);

  // const showModal = () => setAddCorpModalOpen(true);
  // const hideModal = () => setAddCorpModalOpen(false);

  const showAddCommunityModal = () => setAddCommunityModalOpen(true);
  const hideAddCommunityModal = () => setAddCommunityModalOpen(false);

  const showAddUserModal = () => setAddUserModalOpen(true);
  const hideAddUserModal = () => setAddUserModalOpen(false);

  const showImportCorpModal = () => setImportCorpModalOpen(true);
  const hideImportCorpModal = () => setImportCorpModalOpen(false);

  const showFilterModal = () => setFilterModalOpen(true);
  const hideFilterModal = () => setFilterModalOpen(false);

  const communityList = useSelector((state) => state.corporate.communityList);
  const userList = useSelector((state) => state.corporate.userList);
  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);

  // only two are required as there are only two dataTypes
  // move to separate file later
  const communityFormData = {
    name: "",
    address_01: "",
    address_02: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    corporate: "",
    first_name: "",
    last_name: "",
    email: "",
    telephone: "",
    role: "",
  };

  const userFormData = {
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    role: "",
    community: "",
  };
  function importFormData() {
    if (type === "Corporate" && dataType === "community") {
      return communityFormData;
    } else {
      return userFormData;
    }
  }
  const showAddModalAccToType = () => {
    if (dataType === "community") {
      showAddCommunityModal();
    } else if (dataType === "user") {
      showAddUserModal();
    }
  };
  const parm = useParams();
  const [headingName, setHeadingName] = useState("");
  const [search, setSearch] = useState(false);
  const handleSearchChange = (e) => {
    const { value, name } = e.target;

    if (type === "Corporate" && dataType === "community") {
      dispatch(filterActions.setSearchCorporateCommunityText(value));
    } else if (type === "Corporate" && dataType === "user") {
      dispatch(filterActions.setSearchCorporateUserText(value));
    }
  };
  const corporateCommunityOrderMapping = {
    ID: "community_id",
    Name: "name",
    "Address 01": "address_1",
    "Address 02": "address_2",
    City: "city",
    State: "state",
    Zip: "zip",
  };
  const corporateUserOrderMapping = {
    "First Name": "first_name",
    "Last Name": "last_name",
    Email: "email",
    Telephone: "telephone_number",
    Role: "role",
  };
  const orderMapping = {
    community: corporateCommunityOrderMapping,
    user: corporateUserOrderMapping,
  };
  const urlMapping = {
    community: `/edit/corporates/${parm.corporateID}`,
    user: `/edit/corporates/${parm.corporateID}`,
  };
  const userCorporateFilteredRole = useSelector(
    (state) => state.corporate.userCorporateFilteredRole
  );
  const communityCorporateFilteredCountry = useSelector(
    (state) => state.corporate.communityCorporateFilteredCountry
  );
  const communityCorporateFilteredCity = useSelector(
    (state) => state.corporate.communityCorporateFilteredCity
  );
  const communityCorporateFilteredState = useSelector(
    (state) => state.corporate.communityCorporateFilteredState
  );
  const communityCorporateFilteredZip = useSelector(
    (state) => state.corporate.communityCorporateFilteredZip
  );
  const searchCorporateCommunityText = useSelector(
    (state) => state.filter.searchCorporateCommunityText
  );
  const searchCorporateUserText = useSelector(
    (state) => state.filter.searchCorporateUserText
  );
  const rowOrderStateCorporateCommunity = useSelector(
    (state) => state.rowOrder.rowOrderCorporateCommunity
  );
  const rowOrderStateCorporateUser = useSelector(
    (state) => state.rowOrder.rowOrderCorporateUser
  );
  const communityCorporateOrderQuery = useSelector(
    (state) => state.corporate.communityCorporateOrderQuery
  );
  const userCorporateOrderQuery = useSelector(
    (state) => state.corporate.userCorporateOrderQuery
  );

  const rowOrderMapping = {
    community: rowOrderStateCorporateCommunity,
    user: rowOrderStateCorporateUser,
  };
  const [orderState, setOrderState] = useState(null);
  const [prefix, setPrefix] = useState("");

  useEffect(() => {
    updateUrl();
  }, [orderState]);

  const buildRowOrderQuery = () => {
    let newOrderQuery = "";
    if (dataType && dataType === "community") {
      newOrderQuery = communityCorporateOrderQuery.slice();
    } else if (dataType && dataType === "user") {
      newOrderQuery = userCorporateOrderQuery.slice();
    }
    let mappingHeaderName, rowOrderHeaderName;
    if (type === "Corporate") {
      if (dataType === "community") {
        mappingHeaderName = corporateCommunityOrderMapping[headingName];
        rowOrderHeaderName =
          rowOrderStateCorporateCommunity[
            corporateCommunityOrderMapping[headingName]
          ];
      } else if (dataType === "user") {
        mappingHeaderName = corporateUserOrderMapping[headingName];
        rowOrderHeaderName =
          rowOrderStateCorporateUser[corporateUserOrderMapping[headingName]];
      }
    }

    if (mappingHeaderName) {
      let prevOrder = rowOrderHeaderName.order === "asc" ? "desc" : "asc";
      let prevInstance = `&${mappingHeaderName}:orderby=${prevOrder}`;
      if (newOrderQuery.includes(prevInstance)) {
        newOrderQuery = newOrderQuery.replace(prevInstance, "");
      }
      if (!search) {
        newOrderQuery = `&${mappingHeaderName}:orderby=${rowOrderHeaderName.order}`;
      }
    }
    if (dataType && dataType === "community") {
      dispatch(corporateActions.setCommunityCorporateOrderQuery(newOrderQuery));
    } else if (dataType && dataType === "user") {
      dispatch(corporateActions.setUserCorporateOrderQuery(newOrderQuery));
    }

    return newOrderQuery;
  };

  const buildFilterQuery = () => {
    let filterQuery = "";
    if (type === "Corporate") {
      if (dataType === "community") {
        filterQuery =
          `country:eq=${communityCorporateFilteredCountry}` +
          `&city:eq=${communityCorporateFilteredCity}` +
          `&state:eq=${communityCorporateFilteredState}` +
          `&zip:eq=${communityCorporateFilteredZip}`;
      } else if (dataType === "user") {
        filterQuery = `role:eq=${userCorporateFilteredRole}`;
      }
    }
    return filterQuery;
  };

  const buildSearchTextQuery = () => {
    let searchQuery = "";
    if (type === "Corporate" && dataType === "community") {
      searchQuery =
        searchCorporateCommunityText !== ""
          ? `search_query=${searchCorporateCommunityText}`
          : "";
    } else if (type === "Corporate" && dataType === "user") {
      searchQuery =
        searchCorporateUserText !== ""
          ? `search_query=${searchCorporateUserText}`
          : "";
    }
    return searchQuery;
  };
  const getPrefix = () => {
    let newPrefix = prefix;

    return newPrefix;
  };

  const updateUrl = () => {
    const searchQuery = buildSearchTextQuery();
    const newOrderQuery = buildRowOrderQuery();
    const filterQuery = buildFilterQuery();
    let customQuery = "";

    customQuery = `${searchQuery}${newOrderQuery}&${filterQuery}`;
    let modifiedString = removeEmptyParams(customQuery);
    let urlParamString = urlParams.toString();
    const newPrefix = getPrefix();
    let searchParam = "";

    if (!modifiedString) {
      searchParam =
        "?" +
        `${
          search === true
            ? newPrefix +
              modifiedString +
              `page=${search ? 1 : urlParams.get("page")}`
            : newPrefix + urlParamString
        }`;
    } else {
      if (
        !modifiedString.includes("page") &&
        urlParams.toString().includes("page")
      ) {
        searchParam =
          "?" +
          newPrefix +
          modifiedString +
          `&page=${search ? 1 : urlParams.get("page")}`;
      } else {
        searchParam = "?" + newPrefix + modifiedString;
      }
    }

    searchParam = searchParam.replace("&&", "&");
    history.replace({
      pathname: urlMapping[dataType],
      search: searchParam,
    });

    let str =
      modifiedString && modifiedString !== ""
        ? "?" +
          `${
            dataType === "community" ? "tab_key=community&" : "tab_key=users&"
          }` +
          modifiedString
        : "";
    if (dataType && dataType === "community") {
      dispatch(corporateActions.setCommunityCorporateApiUrl(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "user") {
      dispatch(corporateActions.setUserCorporateApiUrl(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    }
  };

  const onClickTableHeading = async (e) => {
    setOtherLoader(true);
    const { id } = e.target;
    setSearch(false);
    setHeadingName(id);
    let urlParamString = urlParams.toString();
    //let prefix = "";
    if (type === "Corporate") {
      if (dataType && dataType === "community") {
        setPrefix("tab_key=community&");
        //prefix = "table=schedule_test&";
      } else if (dataType && dataType === "user") {
        setPrefix("tab_key=users&");
        //prefix = "table=upcoming_test&";
      }
    }

    let name = "";
    let obj;
    // for (let i = 0; i < tableHeadings.length; i++) {
    //   if (id === tableHeadings[i]) {
    //   }
    // }
    if (type === "Corporate") {
      if (dataType === "community") {
        switch (id) {
          case "ID":
            name = "community_id";

            obj = setOrderAndIcon(rowOrderStateCorporateCommunity[name]);
            dispatch(
              rowOrderActions.setCorporateCommunityOrder({
                ...rowOrderStateCorporateCommunity,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                name: { state: 1, order: "", icon: "" },
                address_1: { state: 1, order: "", icon: "" },
                address_2: { state: 1, order: "", icon: "" },
                state: { state: 1, order: "", icon: "" },
                city: { state: 1, order: "", icon: "" },
                zip: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;

          case "Name":
            name = "name";

            obj = setOrderAndIcon(rowOrderStateCorporateCommunity[name]);
            dispatch(
              rowOrderActions.setCorporateCommunityOrder({
                ...rowOrderStateCorporateCommunity,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_id: { state: 1, order: "", icon: "" },
                address_1: { state: 1, order: "", icon: "" },
                address_2: { state: 1, order: "", icon: "" },
                state: { state: 1, order: "", icon: "" },
                city: { state: 1, order: "", icon: "" },
                zip: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;

          case "Address 01":
            name = "address_1";

            obj = setOrderAndIcon(rowOrderStateCorporateCommunity[name]);
            dispatch(
              rowOrderActions.setCorporateCommunityOrder({
                ...rowOrderStateCorporateCommunity,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                name: { state: 1, order: "", icon: "" },
                community_id: { state: 1, order: "", icon: "" },
                address_2: { state: 1, order: "", icon: "" },
                state: { state: 1, order: "", icon: "" },
                city: { state: 1, order: "", icon: "" },
                zip: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;

          case "Address 02":
            name = "address_2";

            obj = setOrderAndIcon(rowOrderStateCorporateCommunity[name]);
            dispatch(
              rowOrderActions.setCorporateCommunityOrder({
                ...rowOrderStateCorporateCommunity,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                name: { state: 1, order: "", icon: "" },
                address_1: { state: 1, order: "", icon: "" },
                community_id: { state: 1, order: "", icon: "" },
                state: { state: 1, order: "", icon: "" },
                city: { state: 1, order: "", icon: "" },
                zip: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;

          case "City":
            name = "city";

            obj = setOrderAndIcon(rowOrderStateCorporateCommunity[name]);
            dispatch(
              rowOrderActions.setCorporateCommunityOrder({
                ...rowOrderStateCorporateCommunity,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                name: { state: 1, order: "", icon: "" },
                address_1: { state: 1, order: "", icon: "" },
                address_2: { state: 1, order: "", icon: "" },
                state: { state: 1, order: "", icon: "" },
                community_id: { state: 1, order: "", icon: "" },
                zip: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;

          case "State":
            name = "state";

            obj = setOrderAndIcon(rowOrderStateCorporateCommunity[name]);
            dispatch(
              rowOrderActions.setCorporateCommunityOrder({
                ...rowOrderStateCorporateCommunity,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                name: { state: 1, order: "", icon: "" },
                address_1: { state: 1, order: "", icon: "" },
                address_2: { state: 1, order: "", icon: "" },
                community_id: { state: 1, order: "", icon: "" },
                city: { state: 1, order: "", icon: "" },
                zip: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;

          case "Zip":
            name = "zip";

            obj = setOrderAndIcon(rowOrderStateCorporateCommunity[name]);
            dispatch(
              rowOrderActions.setCorporateCommunityOrder({
                ...rowOrderStateCorporateCommunity,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                name: { state: 1, order: "", icon: "" },
                address_1: { state: 1, order: "", icon: "" },
                address_2: { state: 1, order: "", icon: "" },
                state: { state: 1, order: "", icon: "" },
                city: { state: 1, order: "", icon: "" },
                community_id: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          default:
            return;
        }
      } else if (dataType === "user") {
        switch (id) {
          case "First Name":
            name = "first_name";

            obj = setOrderAndIcon(rowOrderStateCorporateUser[name]);
            dispatch(
              rowOrderActions.setCorporateUserOrder({
                ...rowOrderStateCorporateUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
                role: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Last Name":
            name = "last_name";

            obj = setOrderAndIcon(rowOrderStateCorporateUser[name]);
            dispatch(
              rowOrderActions.setCorporateUserOrder({
                ...rowOrderStateCorporateUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                first_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
                role: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Email":
            name = "email";

            obj = setOrderAndIcon(rowOrderStateCorporateUser[name]);
            dispatch(
              rowOrderActions.setCorporateUserOrder({
                ...rowOrderStateCorporateUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
                role: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Telephone":
            name = "telephone_number";

            obj = setOrderAndIcon(rowOrderStateCorporateUser[name]);
            dispatch(
              rowOrderActions.setCorporateUserOrder({
                ...rowOrderStateCorporateUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                role: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Role":
            name = "role";

            obj = setOrderAndIcon(rowOrderStateCorporateUser[name]);
            dispatch(
              rowOrderActions.setCorporateUserOrder({
                ...rowOrderStateCorporateUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          default:
            return;
        }
      }
    }
    return;
  };

  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      setSearch(true);
      setOtherLoader(true);
      if (type === "Corporate") {
        if (dataType && dataType === "community") {
          setPrefix("tab_key=community&");
        } else if (dataType && dataType === "user") {
          setPrefix("tab_key=users&");
        }
      }
      setOrderState({
        ...orderState,
      });
    }
  };
  const onClearSorting = () => {
    setOtherLoader(true);

    if (dataType && dataType === "community") {
      dispatch(
        rowOrderActions.setCorporateCommunityOrder({
          ...rowOrderStateCorporateCommunity,
          community_id: { state: 1, order: "", icon: "" },
          name: { state: 1, order: "", icon: "" },
          address_1: { state: 1, order: "", icon: "" },
          address_2: { state: 1, order: "", icon: "" },
          state: { state: 1, order: "", icon: "" },
          city: { state: 1, order: "", icon: "" },
          zip: { state: 1, order: "", icon: "" },
        })
      );
      dispatch(corporateActions.setCommunityCorporateOrderQuery(""));
      history.replace({
        pathname: `/edit/corporates/${parm.corporateID}`,
        search: "?page=1",
      });
      dispatch(corporateActions.setCommunityCorporateApiUrl(""));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "user") {
      dispatch(
        rowOrderActions.setCorporateUserOrder({
          ...rowOrderStateCorporateUser,
          first_name: { state: 1, order: "", icon: "" },
          last_name: { state: 1, order: "", icon: "" },
          email: { state: 1, order: "", icon: "" },
          telephone_number: { state: 1, order: "", icon: "" },
          role: { state: 1, order: "", icon: "" },
        })
      );
      dispatch(corporateActions.setUserCorporateOrderQuery(""));
      history.replace({
        pathname: `/edit/corporates/${parm.corporateID}`,
        search: "?page=1",
      });
      dispatch(corporateActions.setUserCorporateApiUrl(""));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    }
  };
  const onClearSearch = () => {
    setOtherLoader(true);
    if (type === "Corporate" && dataType === "community") {
      dispatch(filterActions.setSearchCorporateCommunityText(""));
    } else if (type === "Corporate" && dataType === "user") {
      dispatch(filterActions.setSearchCorporateUserText(""));
    }
    setSearch(true);
    setOrderState({
      ...orderState,
    });
  };
  const removeCityFilter = (city) => {
    if (
      communityCorporateFilteredState.length === 0 &&
      communityCorporateFilteredZip.length === 0 &&
      communityCorporateFilteredCity.length === 1
    ) {
      dispatch(corporateActions.setCommunityCorporateFilteredCity([]));
      history.replace({
        pathname: urlMapping[dataType],
        search: "",
      });
    } else {
      const updatedCity = communityCorporateFilteredCity.filter(
        (item) => item !== city
      );
      dispatch(corporateActions.setCommunityCorporateFilteredCity(updatedCity));
    }
    setOrderState({
      ...orderState,
    });
  };
  const removeStateFilter = (state) => {
    if (
      communityCorporateFilteredState.length === 1 &&
      communityCorporateFilteredZip.length === 0 &&
      communityCorporateFilteredCity.length === 0
    ) {
      dispatch(corporateActions.setCommunityCorporateFilteredState([]));
      history.replace({
        pathname: urlMapping[dataType],
        search: "",
      });
    } else {
      const updatedState = communityCorporateFilteredState.filter(
        (item) => item !== state
      );
      dispatch(
        corporateActions.setCommunityCorporateFilteredState(updatedState)
      );
    }
    setOrderState({
      ...orderState,
    });
  };
  const removeZipFilter = (zip) => {
    if (
      communityCorporateFilteredState.length === 0 &&
      communityCorporateFilteredZip.length === 1 &&
      communityCorporateFilteredCity.length === 0
    ) {
      dispatch(corporateActions.setCommunityCorporateFilteredZip([]));
      history.replace({
        pathname: urlMapping[dataType],
        search: "",
      });
    } else {
      const updatedZip = communityCorporateFilteredZip.filter(
        (item) => item !== zip
      );
      dispatch(corporateActions.setCommunityCorporateFilteredZip(updatedZip));
    }
    setOrderState({
      ...orderState,
    });
  };
  const removeRoleFilter = (role) => {
    dispatch(corporateActions.setUserCorporateFilteredRole([]));
    history.replace({
      pathname: urlMapping[dataType],
      search: "",
    });
    setOrderState({
      ...orderState,
    });
  };
  const clearFilters = () => {
    var modifiedString = "";
    if (type === "Corporate" && dataType === "community") {
      dispatch(
        corporateActions.setCommunityCorporateApiUrl(
          "?tab_key=community&" + modifiedString
        )
      );

      dispatch(corporateActions.setCommunityCorporateFilteredCity([]));
      dispatch(corporateActions.setCommunityCorporateFilteredState([]));
      dispatch(corporateActions.setCommunityCorporateFilteredZip([]));
    } else if (type === "Corporate" && dataType === "user") {
      dispatch(
        corporateActions.setUserCorporateApiUrl(
          "?tab_key=users&" + modifiedString
        )
      );
      dispatch(corporateActions.setUserCorporateFilteredRole([]));
    }

    setOrderState({
      ...orderState,
    });
  };
  const paramType = dataType;
  return (
    <Fragment>
      {addCommunityModalOpen && (
        <AddCommunityModal
          isShowModal={addCommunityModalOpen}
          hideModal={hideAddCommunityModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      {addUserModalOpen && (
        <AddUserModalReal
          isShowModal={addUserModalOpen}
          hideModal={hideAddUserModal}
          type={type}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}

      {importCorpModalOpen && (
        <ImportCorporateModal
          isShowModal={importCorpModalOpen}
          hideModal={hideImportCorpModal}
          type={type}
          dataType={dataType}
          corporateId={corporateId}
          modalFormData={importFormData()}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}

      {/* For Audit Log */}
      {auditLogModalOpen && (
        <AuditLogsModal
          isShowModal={auditLogModalOpen}
          hideModal={hideAuditLogModal}
          LogTitle={"Audit Log"}
          type={type}
          dataType={dataType}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          refId={corporateId}
        />
      )}

      {filterModalOpen && type === "Corporate" && dataType === "community" && (
        <CorporateCommunityFilter
          isShowModal={filterModalOpen}
          hideModal={hideFilterModal}
          setShowToast={setShowToast}
          setDataFlag={setDataFlag}
          orderBy={communityCorporateOrderQuery}
          setOtherLoader={setOtherLoader}
        />
      )}
      {filterModalOpen && type === "Corporate" && dataType === "user" && (
        <CorporateUserFilter
          isShowModal={filterModalOpen}
          hideModal={hideFilterModal}
          setShowToast={setShowToast}
          setDataFlag={setDataFlag}
          orderBy={userCorporateOrderQuery}
          setOtherLoader={setOtherLoader}
        />
      )}
      {otherLoader ? (
        <Loader />
      ) : (
        <div className="corpoarte-table">
          <div className="table-header-filter">
            <div className="cor-header-change">
              <h1 className="page-name-heading">{heading}</h1>
              <div className="right-cor-header">
                <ul className="rc-header-listing">
                  {/* <li className="single-rc">
                  <button onClick={onClearSorting}>Clear Sorting</button>
                </li> */}
                  {/* <li className="single-rc">
                  <button onClick={onClearSearch}>Clear Search</button>
                </li> */}
                  <li className="single-rc">
                    {type === "Corporate" && dataType === "community" && (
                      <SearchInput
                        value={searchCorporateCommunityText}
                        handleChange={handleSearchChange}
                        onKeyEnter={onEnterPress}
                        onClearSearch={onClearSearch}
                      />
                    )}
                    {type === "Corporate" && dataType === "user" && (
                      <SearchInput
                        value={searchCorporateUserText}
                        handleChange={handleSearchChange}
                        onKeyEnter={onEnterPress}
                      />
                    )}
                  </li>
                  <li className="single-rc">
                    <FilterButton handleClick={showFilterModal} />
                  </li>
                  {/* {!isCatchUAdmin &&
              (userRole.includes("Super Admin") ||
                userRole.includes("Corporate HQ Administrator") ||
                userRole.includes("Community Admin") ||
                userRole.includes("Individual Practice Admin")) ? (
                <li className="single-rc">
                  <button
                    className="btn btn-primary-outline"
                    onClick={showAuditLogModal}
                  >
                    View Audit Logs
                  </button>
                </li>
              ) : (
                <>
                  {isCatchUAdmin && (
                    <li className="single-rc">
                      <button
                        className="btn btn-primary-outline"
                        onClick={showAuditLogModal}
                      >
                        View Audit Logs
                      </button>
                    </li>
                  )}
                </>
              )} */}

                  <li className="single-rc">
                    <button
                      className="btn btn-primary-outline"
                      onClick={showAuditLogModal}
                    >
                      View Audit Log
                    </button>
                  </li>

                  <li className="single-rc">
                    <button
                      className="btn btn-primary-outline"
                      onClick={showImportCorpModal}
                    >
                      Import
                    </button>
                  </li>
                  <li className="single-rc">
                    <button
                      className="btn btn-primary-fill btn-primary-16"
                      onClick={showAddModalAccToType}
                    >
                      Add
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            {type === "Corporate" && dataType === "community" && (
              <>
                {(communityCorporateFilteredCity.length !== 0 ||
                  communityCorporateFilteredState.length !== 0 ||
                  communityCorporateFilteredZip.length !== 0) && (
                  <div className="filter-header">
                    <h2 className="apply-filter-name">Applied Filters:</h2>
                    <ul className="apply-filter-list">
                      {communityCorporateFilteredCity.map((city) => (
                        <li className="apply-filter-single">
                          <h3 className="filter-name-type">
                            City: <strong>{city}</strong>{" "}
                          </h3>
                          <img
                            src={FilterClose}
                            alt=""
                            onClick={() => removeCityFilter(city)}
                          />
                        </li>
                      ))}
                      {communityCorporateFilteredState.map((state) => (
                        <li className="apply-filter-single">
                          <h3 className="filter-name-type">
                            State: <strong>{state}</strong>{" "}
                          </h3>
                          <img
                            src={FilterClose}
                            alt=""
                            onClick={() => removeStateFilter(state)}
                          />
                        </li>
                      ))}
                      {communityCorporateFilteredZip.map((zip) => (
                        <li className="apply-filter-single">
                          <h3 className="filter-name-type">
                            Zip: <strong>{zip}</strong>{" "}
                          </h3>
                          <img
                            src={FilterClose}
                            alt=""
                            onClick={() => removeZipFilter(zip)}
                          />
                        </li>
                      ))}
                    </ul>
                    <Link
                      to={`/edit/corporates/${parm.corporateID}`}
                      className="clear-all-filter"
                      onClick={clearFilters}
                    >
                      Clear all filters
                    </Link>
                  </div>
                )}
              </>
            )}

            {type === "Corporate" && dataType === "user" && (
              <>
                {userCorporateFilteredRole.length !== 0 && (
                  <div className="filter-header">
                    <h2 className="apply-filter-name">Applied Filters:</h2>
                    <ul className="apply-filter-list">
                      {userCorporateFilteredRole.map((role) => (
                        <li className="apply-filter-single">
                          <h3 className="filter-name-type">
                            Role: <strong>{role}</strong>{" "}
                          </h3>
                          <img
                            src={FilterClose}
                            alt=""
                            onClick={() => removeRoleFilter(role)}
                          />
                        </li>
                      ))}
                    </ul>
                    <Link
                      to={`/edit/corporates/${parm.corporateID}`}
                      className="clear-all-filter"
                      onClick={clearFilters}
                    >
                      Clear all filters
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="table-responsive">
            <table className="table" id="en-width">
              <thead>
                <tr>
                  {tableHeadings &&
                    tableHeadings.map((tableHead) => (
                      <th
                        id={tableHead}
                        name={tableHead}
                        key={tableHead}
                        onClick={onClickTableHeading}
                      >
                        {tableHead}
                        {tableHead !== "Actions" && (
                          <>
                            {rowOrderMapping[paramType][
                              orderMapping[paramType][tableHead]
                            ].icon === "" ? (
                              <span className="icons-th">
                                <span></span>
                                <span className="order-4"></span>
                              </span>
                            ) : (
                              <span className="icons-th">
                                <span
                                  className={
                                    rowOrderMapping[paramType][
                                      orderMapping[paramType][tableHead]
                                    ].icon === "asc"
                                      ? "caret-4-asc-icon"
                                      : "caret-4-desc-icon"
                                  }
                                ></span>
                                <span className="order-4"></span>
                              </span>
                            )}
                          </>
                          // <span className="icons-th">
                          //   <span className="caret-4-desc caret-4-asc"></span>
                          //   <span className="order-4"></span>
                          // </span>
                        )}
                      </th>
                    ))}
                  <th>Actions</th>
                </tr>
              </thead>
              <TableEditBody
                data={data}
                dataType={dataType}
                deleteCorporate={props.deleteCorporate}
                type="Corporate"
                setDataFlag={setDataFlag}
                setShowToast={setShowToast}
              />
            </table>
          </div>
          {type === "Corporate" && dataType === "community" && (
            <Pagination
              data={data}
              parm={parm.corporateID}
              dataType={dataType}
              type={type}
              totalRecords={totalRecords}
              prefix={"tab_key=community&"}
              setOtherLoader={setOtherLoader}
            />
          )}
          {type === "Corporate" && dataType === "user" && (
            <Pagination
              data={data}
              parm={parm.corporateID}
              dataType={dataType}
              type={type}
              totalRecords={totalRecords}
              prefix={"tab_key=users&"}
              setOtherLoader={setOtherLoader}
            />
          )}
        </div>
      )}
      {dataType === TabKey && (
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      )}
    </Fragment>
  );
};

export default TableDataEdit;
