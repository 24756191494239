import React, { Fragment, useEffect, useState } from "react";
import TableComponent from "../UI/Table/TableData";
import { ToastContainer, toast } from "react-toastify";
import TestData from "../Test/TestData";
import "./Test.scss";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { testActions } from "../../store/test";
import { sidebarActions } from "../../store/sidebar";
import Loader from "../UI/loader/loader";
import { getWithExpiry } from "../utils/helper";
import useQueryString from "../../hooks/useQueryString";
function Test(props) {
  const dispatch = useDispatch();
  const urlParams = useQueryString();

  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [otherLoader1, setOtherLoader1] = useState(false);
  const [otherLoader2, setOtherLoader2] = useState(false);
  const [scheduleTestsData, setScheduleTestsData] = useState([]);
  const [upComingTestsData, setUpComingTestsData] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [dataFlag1, setDataFlag1] = useState(false);
  const [dataFlag2, setDataFlag2] = useState(false);

  const upcomingTestList = useSelector((state) => state.test.upcomingTestList);
  const scheduleTestList = useSelector((state) => state.test.scheduleTestList);

  const upcomingTestCurrentPage = useSelector(
    (state) => state.test.upcomingTestCurrentPage
  );
  const upcomingTestTotalRecords = useSelector(
    (state) => state.test.upcomingTestTotalRecords
  );
  const scheduleTestCurrentPage = useSelector(
    (state) => state.test.scheduleTestCurrentPage
  );
  const scheduleTestTotalRecords = useSelector(
    (state) => state.test.scheduleTestTotalRecords
  );
  const testsToScheduleApiUrl = useSelector(
    (state) => state.test.testsToScheduleApiUrl
  );
  const upcomingTestsApiUrl = useSelector(
    (state) => state.test.upcomingTestsApiUrl
  );

  const token = "Bearer " + getWithExpiry("expiry_token");
  const scheduleTestsHeadings = [
    "First Name",
    "Last Name",
    "Date of Birth",
    "Last Test Date",
  ];
  const upcomingTestsHeadings = [
    "Patient Name",
    "Date of Birth",
    "Status",
    "Schedule Date",
    "Location",
    "Remote",
  ];
  useEffect(() => {
    if (!showToast) {
      setLoading1(true);
      dispatch(sidebarActions.setSelectedNav("Tests"));
    }

    let urlKeys = [...urlParams.keys()];
    let urlValues = [...urlParams.values()];

    let query = "";
    if (!upcomingTestsApiUrl && urlKeys.length > 0) {
      query = "?";
      if (urlValues.includes("upcoming_test")) {
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      }
    } else {
      query = upcomingTestsApiUrl ? upcomingTestsApiUrl + "&" : "?";
    }

    let pageNumber = 1;
    if (urlValues.includes("upcoming_test")) {
      pageNumber = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? urlParams.get("page")
          : upcomingTestCurrentPage
        : upcomingTestCurrentPage;
    }
    query = query.replace("&&", "&");
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/test/upcoming_tests/${query}page=${pageNumber}`,
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: token,
      },
    })
      .then((response) => {
        const resData = response.data.data.upcoming_test_list;
        dispatch(testActions.setUpcomingTestList(resData));

        dispatch(testActions.setTestList(response.data.data)); //wrong

        dispatch(
          testActions.setUpcomingTestPageNumbers(response.data.data.total_pages)
        );
        dispatch(
          testActions.setUpcomingTestTotalRecords(
            response.data.data.total_records
          )
        );
        if (upcomingTestCurrentPage > response.data.data.total_pages) {
          dispatch(
            testActions.setUpcomingTestCurrentPage(
              response.data.data.total_pages
            )
          );
        }
        setLoading1(false);
        setOtherLoader1(false);
      })
      .catch((error) => {
        setLoading1(false);
      });
  }, [dataFlag2]);

  useEffect(() => {
    if (!showToast) {
      setLoading2(true);
    }

    let urlKeys = [...urlParams.keys()];
    let urlValues = [...urlParams.values()];

    let query = "";
    if (!testsToScheduleApiUrl && urlKeys.length > 0) {
      query = "?";
      if (urlValues.includes("schedule_test")) {
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      }
    } else {
      query = testsToScheduleApiUrl ? testsToScheduleApiUrl + "&" : "?";
    }

    let pageNumber2 = 1;
    if (urlValues.includes("schedule_test")) {
      pageNumber2 = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? urlParams.get("page")
          : scheduleTestCurrentPage
        : scheduleTestCurrentPage;
    }

    const Url = `${process.env.REACT_APP_URL_PATH}/test/tests_to_schedule/${query}page=${pageNumber2}`;

    query = query.replace("&&", "&");
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/test/tests_to_schedule/${query}page=${pageNumber2}`,
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: token,
      },
    })
      .then((response) => {
        const resData = response.data.data;
        dispatch(testActions.setScheduleTestList(resData));
        dispatch(
          testActions.setScheduleTestPageNumbers(response.data.data.total_pages)
        );
        dispatch(
          testActions.setScheduleTestTotalRecords(
            response.data.data.total_records
          )
        );

        if (scheduleTestCurrentPage > resData.total_pages) {
          dispatch(testActions.setScheduleTestCurrentPage(resData.total_pages));
        }

        setLoading2(false);
        setOtherLoader2(false);
      })
      .catch((error) => {
        setLoading2(false);
      });
  }, [dataFlag1]);

  return (
    <div className="corporate-wrapper">
      {loading1 || loading2 ? (
        <Loader />
      ) : (
        <Fragment>
          <TableComponent
            tableHeadings={scheduleTestsHeadings}
            data={scheduleTestList}
            totalRecords={scheduleTestTotalRecords}
            heading="Tests to Schedule"
            subType="schedule"
            isImport={false}
            isAdd={false}
            type="Test"
            setShowToast={setShowToast}
            setDataFlag1={setDataFlag1}
            otherLoader={otherLoader2}
            setOtherLoader={setOtherLoader2}
          />

          <TableComponent
            tableHeadings={upcomingTestsHeadings}
            data={upcomingTestList}
            totalRecords={upcomingTestTotalRecords}
            heading="Upcoming Tests"
            isImport={false}
            isAdd={false}
            subType="upcoming"
            type="Test"
            setShowToast={setShowToast}
            setDataFlag2={setDataFlag2}
            otherLoader={otherLoader1}
            setOtherLoader={setOtherLoader1}
          />
        </Fragment>
      )}
    </div>
  );
}

export default Test;
