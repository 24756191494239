import React, { Fragment, useEffect, useState } from "react";
import RowActions from "./RowActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { testActions } from "../../../store/test";
import AddGroupTestModal from "../../Communities/Modals/AddGroupTestModal/AddGroupTestModal";
import { getWithExpiry } from "../../utils/helper";
const UpcomingTestsBody = (props) => {
  const { data, type, subType, setId, showDeleteModal } = props;

  const [groupIsEditMode, setGroupIsEditMode] = useState(false);
  const [addGroupTestModalShow, setAddGroupTestModalShow] = useState(false);
  const hideGroupTestModalHandler = () => setAddGroupTestModalShow(false);

  const showGroupTestModalHandler = async () => {
    await setAddGroupTestModalShow(true);
  };

  const token = "Bearer " + getWithExpiry("expiry_token");
  const dispatch = useDispatch();
  const testList = useSelector((state) => state.test.testList);
  const history = useHistory();

  if (subType === "upcoming") {
    return (
      <>
        <tbody>
          {data && data.length === 0 && (
            <tr>
              <td colSpan="10" className="text-center">
                No data available in table
              </td>
            </tr>
          )}
        </tbody>

        <tbody>
          {data &&
            data.map((test) => (
              <tr key={Math.random()}>
                <td>{test.patient_name}</td>
                <td>{test.dob}</td>
                <td>
                  <span className="text-tag">
                    <span
                      className={`tag ${
                        (test.status === "Pending" && "complete-tag") ||
                        (test.status === "Success" && "resend-tag") ||
                        (test.status === "Failed" && "failed-tag") ||
                        (test.status === "Expired" && "failed-tag") ||
                        (test.status === "Initiated" && "pending-tag")
                      }`}
                    >
                      {test.status}
                    </span>
                  </span>
                </td>
                <td>{test.schedule_date}</td>
                <td>{test.location === "" ? "N/A" : test.location}</td>
                <td>
                  <span className="tag zip-tag">
                    {test.remote ? "Yes" : "No"}
                  </span>
                </td>

                <RowActions
                  recordId={test.patient_id}
                  type={type}
                  subType={subType}
                  setId={setId}
                  showDeleteModal={showDeleteModal}
                  handleClick={() => {
                    history.push(
                      `/tests/${test.id}/edit/patient/${test.patient_id}`
                    );
                  }}
                  // setId={setId}
                />
              </tr>
            ))}
        </tbody>
      </>
    );
  }
};

export default UpcomingTestsBody;
