import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "react-phone-number-input/input";
import axios from "axios";
import { getWithExpiry } from "../../utils/helper";
import CloseModal from "../../../assets/close-outline.svg";
import DisabledIcon from "../../../assets/disabled-row.svg";
import Loader2 from "../../UI/loader2/loader2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const isNotEmpty = (value) => value.trim() !== "";
const isEmailValid = (value) => value.includes("@");

function EditUserModal(props) {
  const { isShowModal, hideModal, type, setDataFlag, setShowToast } = props;


  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [addBtnDisabled, setAddBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [phoneValue, setPhoneValue] = useState("");
  const [checkedState, setCheckedState] = useState(new Array(7).fill(false));
  const PermissionsArray = [
    "Corporate",
    "Community",
    "Patients",
    "Tests",
    "Providers",
    "Billing",
    "Users",
  ];


  const idToBeEdited = useSelector((state) => state.adminuser.idToBeEdited);


  const params = useParams();
  const { corporateID: corporateId } = params;


  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const token = "Bearer " + getWithExpiry("expiry_token");

  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/user/update_admin/${idToBeEdited}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {

        const resData = response.data.data;
        const permissions = resData.admin.permissions;
        setUser(resData.admin);
        setPhoneValue(resData.admin.telephone_number);


        const updatedState = checkedState.map((item, index) => {
          if (permissions.includes(PermissionsArray[index])) {
            return true;
          }
          return false;
        });

        setCheckedState(updatedState);
      })
      .catch((error) => {
      });
  }, []);


  const addUserSubmissionHandler = (event) => {
    event.preventDefault();

    setLoading(true);

    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");
    setAddBtnDisabled(true);
   

    const fd = new FormData();
    fd.append("first_name", user.first_name);
    fd.append("last_name", user.last_name);
    fd.append("email", user.email);
    if (phoneValue && !phoneValue.includes("+", 0)) {
      fd.append("telephone_number", "+" + phoneValue);
    } else if (phoneValue) {
      fd.append("telephone_number", phoneValue);
    }

    checkedState.map((item, index) => {
      if (item) {
        let indVal = PermissionsArray[index];
        fd.append(indVal, indVal);
      }
    });

    // make http call
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/user/update_admin/${idToBeEdited}`,
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      
      })
      .catch((error) => {
        setLoading(false);
        setAddBtnDisabled(false);
        if (error.response.data.message === "Field Errors") {
          if (
            error.response.data.data["user_form"] &&
            "first_name" in error.response.data.data["user_form"]
          ) {
            setFirstNameError(
              error.response.data.data["user_form"]["first_name"][0]
            );
          }
          if (
            error.response.data.data["user_form"] &&
            "last_name" in error.response.data.data["user_form"]
          ) {
            setLastNameError(
              error.response.data.data["user_form"]["last_name"][0]
            );
          }

          if (
            error.response.data.data["user_form"] &&
            "email" in error.response.data.data["user_form"]
          ) {
            setEmailError(error.response.data.data["user_form"]["email"][0]);
          }

          if (
            error.response.data.data["phone_form"] &&
            "telephone_number" in error.response.data.data["phone_form"]
          ) {
            setPhoneError(
              error.response.data.data["phone_form"]["telephone_number"][0]
            );
          }
        } else {
          notifyError(error.response.data.message);
        }
      });
    setDataFlag((prevState) => !prevState);
  };

  const nameChangeHandler = (e) => {
    if (user) {
      const { name, value } = e.target;

      setUser({ ...user, [name]: value });
    }
  };

 

  const checkBoxHandler = (position, e) => {
    const { name, value } = e.target;
    const updatedCheckedState = checkedState.map((item, index) => {
      return index === position ? !item : item;
    });


    setCheckedState(updatedCheckedState);
  };

  return (
    <div className="action-modal-wrapper">
      <Modal
        className="comunitie-modal modal-loader"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header className="comubitie-modal-header" id="rm-padding">
          <h1 className="modal-header-title">Edit User</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="comunitie-modal-body">
          <form
            method="post"
            className="add-corporate-form"
            onSubmit={addUserSubmissionHandler}
          >
            <div className="communities-fields">
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="first_name" className="label">
                      First Name *
                    </label>
                    <input
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="First Name"
                      className="custom-input"
                      value={user ? user.first_name : ""}
                      onChange={nameChangeHandler}
                      required
                    />
                    {firstNameError && (
                      <p className="error-text">{firstNameError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="last_name" className="label">
                      Last Name *
                    </label>
                    <input
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Last Name"
                      className="custom-input"
                      value={user ? user.last_name : ""}
                      onChange={nameChangeHandler}
                      required
                    />
                    {lastNameError && (
                      <p className="error-text">{lastNameError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="email" className="label">
                      Email *
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="custom-input"
                      placeholder="Email"
                      value={user ? user.email : ""}
                      onChange={nameChangeHandler}
                      required
                    />
                    {emailError && <p className="error-text">{emailError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="telephone_number" className="label">
                      Telephone *
                    </label>
                    
                    <PhoneInput
                      country={"us"}
                      value={phoneValue}
                      onChange={setPhoneValue}
                      inputProps={{
                        required: true,
                      }}
                    />
                    {phoneError && <p className="error-text">{phoneError}</p>}
                  </div>
                </div>
              </div>
              <hr
                style={{
                  height: 0.4,
                  color: "gray",
                }}
              />
              <div className="field-wrapper">
                <label htmlFor="permissions" className="label">
                  Permissions *
                </label>
                <ul className="check-list">
                  {[
                    "Corporate",
                    "Community",
                    "Patient",
                    "Test",
                    "Provider",
                    "Billee",
                    "User",
                  ].map((name, index) => {
                    return (
                      <li key={index} className="custom-check-orange">
                        <div className="toppings-list-item">
                          <div className="left-section">
                            <input
                              className="styled-checkbox check-form"
                              type="checkbox"
                              id={`custom-checkbox-${index}`}
                              name={name}
                              value={name}
                              checked={checkedState[index]}
                              onChange={(e) => checkBoxHandler(index, e)}
                            />
                            <label htmlFor={`custom-checkbox-${index}`}>
                              {name}
                            </label>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16"
            onClick={hideModal}
            id="modal-close-btn"
          >
            Close
          </button>
          <button
            onClick={addUserSubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={addBtnDisabled}
          >
            Update
          </button>
        
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

export default EditUserModal;
