import React from "react"
import { useState } from "react"
import { Tab, Table, Tabs } from "react-bootstrap"
import TableComponent from "../../UI/Table/TableData"

function Users() {
  const [key, setKey] = useState("home")
  return (
    <div className="edit-corp-wrapper">
      {/* tabs */}
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="home" title="CatchU Admins">
          {/* <TableComponent /> */}
        </Tab>
        <Tab eventKey="profile" title="Regular Users">
          {/* <TableComponent /> */}
        </Tab>
      </Tabs>
      {/* tabs */}
    </div>
  )
}

export default Users
