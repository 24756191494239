import React from "react";
import Filter_Icon from "../../../assets/filter-icon.svg";

const FilterButton = (props) => {
  let { handleClick } = props;
  return (
    <div className="filter-button" onClick={handleClick}>
      <img src={Filter_Icon} alt="" />
    </div>
  );
};

export default FilterButton;
