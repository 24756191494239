import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
//import useInput from "../../../../hooks/use-input";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
//import RolesList from "./RolesList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "react-phone-number-input/input";
import axios from "axios";
import "./EditRegUser.scss";
import { getWithExpiry } from "../../utils/helper";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import images
import CloseModal from "../../../assets/close-outline.svg";
import DisabledIcon from "../../../assets/disabled-row.svg";
import Loader2 from "../../UI/loader2/loader2";
function EditRegUser(props) {
  const { isShowModal, hideModal, type, setDataFlag, setShowToast } = props;

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  //   const [roleError, setRoleError] = useState("");
  //   const [otherRoleError, setOtherRoleError] = useState("");

  const [deletedIDList, setDeletedIDList] = useState([]);

  const [addBtnDisabled, setAddBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [role, setRole] = useState([]);
  const [otherRole, setOtherRole] = useState([]);
  const [showCorporates, setShowCorporates] = useState([]);
  const [showCommunities, setShowCommunities] = useState([]);
  const [showIndivCommunities, setShowIndivCommunities] = useState([]);
  const [showAllCommunities, setShowAllCommunities] = useState([]);

  const communityList = useSelector((state) => state.reguser.communityList);
  const corporateList = useSelector((state) => state.reguser.corporateList);
  const isCatchuAdmin = useSelector((state) => state.user.isCatchuAdmin);
  const permissions = useSelector((state) => state.user.permissions);
  const idToBeEdited = useSelector((state) => state.reguser.idToBeEdited);
  const regUserRoles = useSelector((state) => state.reguser.userRoles);

  const [phoneValue, setPhoneValue] = useState("");

  const nonIndCommunities = communityList.filter(
    (community) => community.is_individual_practice === false
  );
  const indEnabledCommunities = communityList.filter(
    (community) => community.is_individual_practice === true
  );

  const params = useParams();
  const { corporateID: corporateId } = params;

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const token = "Bearer " + getWithExpiry("expiry_token");

  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/user/edit_regular_user/${idToBeEdited}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        const resData = response.data.data;

        const rolesArray = resData.user_roles.map((object) => {
          return object.role;
        });

        const otherRolesArray = resData.user_roles.map((object) => {
          return object.id;
        });

        setRole(rolesArray);
        setOtherRole(otherRolesArray);
        setUser(resData.user);
        setPhoneValue(resData.user.telephone_number);
        setUserRoles(resData.user_roles);

        setShowCorporates(
          resData.user_roles.map((object) => {
            if (object.type === "Corporate") return true;
            return false;
          })
        );

        setShowCommunities(
          resData.user_roles.map((object) => {
            if (object.type === "Community") return true;
            return false;
          })
        );

        setShowIndivCommunities(
          resData.user_roles.map((object) => {
            if (object.type === "Individual Practice") return true;
            return false;
          })
        );

        setShowAllCommunities(
          resData.user_roles.map((object) => {
            if (object.type.includes("Support")) return true;
            return false;
          })
        );
      })
      .catch((error) => {
      });
  }, []);

  const addRoleFieldHandler = (event) => {
    event.preventDefault();
    //
    setUserRoles([...userRoles, regUserRoles]);
    setRole([...role, ""]);
    setOtherRole([...otherRole, ""]);
  };

  const removeRoleFieldHandler = (event) => {
    event.preventDefault();
    //
    if (userRoles.length > 1) {
      const lastElem = userRoles.slice(-1);
      if ("is_default" in lastElem[0] && !lastElem[0].is_default) {
        setUserRoles((prevItems) => prevItems.slice(0, -1));
        setRole((prevItems) => prevItems.slice(0, -1));
        setOtherRole((prevItems) => prevItems.slice(0, -1));
        setDeletedIDList([...deletedIDList, lastElem[0].user_role_id]);
      } else {
        setUserRoles((prevItems) => prevItems.slice(0, -1));
        setRole((prevItems) => prevItems.slice(0, -1));
        setOtherRole((prevItems) => prevItems.slice(0, -1));
      }
    }
  };
  const addUserSubmissionHandler = (event) => {
    event.preventDefault();

    setLoading(true);

    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");

    setAddBtnDisabled(true);

    // create form data here
    const form = document.querySelector(".add-corporate-form");
    const fd = new FormData(form);

    //let phoneVal = user.telephone_number.replace(/\s+/g, "");
    if (phoneValue && !phoneValue.includes("+", 0)) {
      fd.append("telephone_number", "+" + phoneValue);
    } else if (phoneValue) {
      fd.append("telephone_number", phoneValue);
    }

    let result = [];
    for (let i = 0; i < role.length; i++) {
      if (!userRoles[i].is_default) {
        const tempObj = {
          user_role_id: userRoles[i].user_role_id
            ? userRoles[i].user_role_id
            : "none",
          [role[i]]: otherRole[i],
        };

        result.push(tempObj);
      }
    }

    fd.append("roles_mapping", JSON.stringify(result));
    fd.append("deleted_roles", JSON.stringify(deletedIDList));
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/user/edit_regular_user/${idToBeEdited}`,
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      })
      .catch((error) => {
        setLoading(false);
        setAddBtnDisabled(false);
        if (error.response.data.message === "Field Errors") {
          if (
            error.response.data.data["user_form"] &&
            "first_name" in error.response.data.data["user_form"]
          ) {
            setFirstNameError(
              error.response.data.data["user_form"]["first_name"][0]
            );
          }
          if (
            error.response.data.data["user_form"] &&
            "last_name" in error.response.data.data["user_form"]
          ) {
            setLastNameError(
              error.response.data.data["user_form"]["last_name"][0]
            );
          }

          if (
            error.response.data.data["user_form"] &&
            "email" in error.response.data.data["user_form"]
          ) {
            setEmailError(error.response.data.data["user_form"]["email"][0]);
          }

          if (
            error.response.data.data["phone_form"] &&
            "telephone_number" in error.response.data.data["phone_form"]
          ) {
            setPhoneError(
              error.response.data.data["phone_form"]["telephone_number"][0]
            );
          }
        } else {
          notifyError(error.response.data.message);
        }
      });
  };

  const nameChangeHandler = (e) => {
    if (user) {
      const { name, value } = e.target;

      setUser({ ...user, [name]: value });
    }
  };

  const setPageStates = (a, b, c, d, index) => {
    let temp1 = [...showCorporates];
    temp1[index] = a;
    let temp2 = [...showCommunities];
    temp2[index] = b;
    let temp3 = [...showIndivCommunities];
    temp3[index] = c;
    let temp4 = [...otherRole];
    temp4[index] = "";
    let temp5 = [...showAllCommunities];
    temp5[index] = d;

    setShowCorporates(temp1);
    setShowCommunities(temp2);
    setShowIndivCommunities(temp3);
    setOtherRole(temp4);
    setShowAllCommunities(temp5);
  };

  const roleChangeHandler = (e, index) => {
    const { value } = e.target;
    let temp = [...role];
    temp[index] = value;
    setRole(temp);

    if (e.target.value !== "") {
      if (e.target.value === "Corporate HQ Administrator") {
        setPageStates(true, false, false, false, index);
      } else if (e.target.value === "Individual Practice Admin") {
        setPageStates(false, false, true, false, index);
      } else if (e.target.value.includes("Support")) {
        setPageStates(false, false, false, true, index);
      } else {
        setPageStates(false, true, false, false, index);
      }
    } else {
      setPageStates(false, false, false, false, index);
    }
  };

  const otherRoleChangeHandler = (e, index) => {
    const { value } = e.target;

    let temp = [...otherRole];

    temp[index] = value;
    setOtherRole(temp);
  };

  const handlePhoneInput = (e) => {
    const [value, name] = e.traget;
    setPhoneValue(value);
  };
  return (
    <div className="action-modal-wrapper">
      <Modal
        className="comunitie-modal modal-loader"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header className="comubitie-modal-header" id="rm-padding">
          <h1 className="modal-header-title">Edit User</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="comunitie-modal-body">
          <form
          
            method="post"
            className="add-corporate-form"
            onSubmit={addUserSubmissionHandler}
          >
            <div className="communities-fields reg-user">
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="first_name" className="label">
                      First Name *
                    </label>
                    <input
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="First Name"
                      className="custom-input"
                      value={user ? user.first_name : ""}
                      onChange={nameChangeHandler}
                      required
                    />
                    {firstNameError && (
                      <p className="error-text">{firstNameError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="last_name" className="label">
                      Last Name *
                    </label>
                    <input
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Last Name"
                      className="custom-input"
                      value={user ? user.last_name : ""}
                      onChange={nameChangeHandler}
                      required
                    />
                    {lastNameError && (
                      <p className="error-text">{lastNameError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="email" className="label">
                      Email *
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="custom-input"
                      placeholder="Email"
                      value={user ? user.email : ""}
                      onChange={nameChangeHandler}
                    />
                    {emailError && <p className="error-text">{emailError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="telephone_number" className="label">
                      Telephone *
                    </label>
                    {/* <input
                      type="text"
                      name="telephone_number"
                      id="telephone_number"
                      className="custom-input"
                      placeholder="Telephone"
                      value={user ? user.telephone_number : ""}
                      onChange={(e) => {
                        const { name, value } = e.target;

                        const regex = /^([\s+0-9])+$/i;
                        if (value === "") {
                          // case to remove whole highlighted text
                          setUser({ ...user, [name]: value });
                          return;
                        }
                        if (regex.test(value) === false) return;

                        if (value.length === 1 && value === "+") {
                          // case to remove + sign when last number is removed
                          setUser({ ...user, [name]: "" });
                        } else if (value.length >= 1 && value[0] !== "+") {
                          // case to append + sign with first number
                          setUser({ ...user, [name]: "+" + value });
                        } else {
                          // case to append other numbers
                          setUser({ ...user, [name]: value });
                        }

                        // const regex = /^([\s+0-9])+$/i;
                        // if (regex.test(value) === false) return;

                        // if (
                        //   (value === "" || value.length === 1) &&
                        //   value !== "+"
                        // ) {
                        //   setPhoneValue("+" + phoneValue);
                        //   return;
                        // } else {
                        //   setPhoneValue(value);
                        // }
                      }}
                      required
                    /> */}
                    <PhoneInput
                      country={"us"}
                      value={phoneValue}
                      onChange={setPhoneValue}
                      inputProps={{
                        required: true,
                      }}
                    />
                    {phoneError && <p className="error-text">{phoneError}</p>}
                  </div>
                </div>
              </div>

              {userRoles?.map((value, index) => {
                return (
                  <div key={index} className="row">
                    <div className="col-lg-6 right-col">
                      <div className="field-wrapper">
                        <label htmlFor="permissions" className="label">
                          {value.is_default ? (
                            <>
                              Role *{" "}
                              <span style={{ color: "green", fontSize: 12, paddingLeft:'.2rem' }}>
                                (Default User)
                              </span>
                            </>
                          ) : (
                            <>Role *</>
                          )}
                        </label>

                        <select
                          name="role"
                          id="role"
                          className="custom-select-modal"
                          onChange={(e) => roleChangeHandler(e, index)}
                          value={role[index]}
                          disabled={value.is_default}
                        >
                          {role[index] === "" && (
                            <option value="">{"Select a Role"}</option>
                          )}
                          {regUserRoles.map((field) => (
                            <option key={Math.random()} value={field}>
                              {field}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {showCorporates[index] === true && (
                      <div className="col-lg-6 right-col">
                        <div className="field-wrapper">
                          <label htmlFor="community_id" className="label">
                            Corporates *
                          </label>
                          <select
                            name="community_id"
                            id="community_id"
                            className="custom-select-modal"
                            onChange={(e) => otherRoleChangeHandler(e, index)}
                            value={otherRole[index]}
                            disabled={value.is_default}
                          >
                            {/* <RolesList customList={corporateList} /> */}
                            {otherRole[index] === "" && (
                              <option value="">{"Select a Corporate"}</option>
                            )}
                            {corporateList.map((corporate) => (
                              <option key={corporate.id} value={corporate.id}>
                                {corporate.corporate_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    {showAllCommunities[index] === true && (
                      <div className="col-lg-6 right-col">
                        <div className="field-wrapper">
                          <label htmlFor="community_id" className="label">
                            Communities *
                          </label>
                          <select
                            name="community_id"
                            id="community_id"
                            className="custom-select-modal"
                            onChange={(e) => otherRoleChangeHandler(e, index)}
                            value={otherRole[index]}
                            disabled={value.is_default}
                          >
                            {otherRole[index] === "" && (
                              <option value="">{"Select a Community"}</option>
                            )}

                            {communityList.map((community) => (
                              <option key={community.id} value={community.id}>
                                {community.community_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    {showCommunities[index] === true && (
                      <div className="col-lg-6 right-col">
                        <div className="field-wrapper">
                          <label htmlFor="community_id" className="label">
                            Communities *
                          </label>
                          <select
                            name="community_id"
                            id="community_id"
                            className="custom-select-modal"
                            onChange={(e) => otherRoleChangeHandler(e, index)}
                            value={otherRole[index]}
                            disabled={value.is_default}
                          >
                            {/* <option value="0">{"YOYO"}</option>

                            <option value="1">{"SOMETHING"}</option> */}
                            {otherRole[index] === "" && (
                              <option value="">{"Select a Community"}</option>
                            )}

                            {nonIndCommunities.map((community) => (
                              <option key={community.id} value={community.id}>
                                {community.community_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    {showIndivCommunities[index] === true && (
                      <div className="col-lg-6 right-col">
                        <div className="field-wrapper">
                          <label htmlFor="community_id" className="label">
                            Communities *
                          </label>
                          <select
                            name="community_id"
                            id="community_id"
                            className="custom-select-modal"
                            onChange={(e) => otherRoleChangeHandler(e, index)}
                            value={otherRole[index]}
                            disabled={value.is_default}
                          >
                            {otherRole[index] === "" && (
                              <option value="">
                                {"Select an Individual Community"}
                              </option>
                            )}
                            {indEnabledCommunities.map((community) => (
                              <option
                                key={community.id}
                                value={`${community.id}`}
                              >
                                {community.community_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
              <div>
                <button
                  // type="submit"
                  onClick={addRoleFieldHandler}
                  style={add_user_btns}
                  className="btn btn-primary-fill btn-primary-16 add-user-btns"

                  //disabled={addBtnDisabled}
                >
                  Add
                </button>

                <button
                  // type="submit"
                  onClick={removeRoleFieldHandler}
                  style={remove_user_btn}
                  className="btn btn-primary-fill btn-primary-16 add-user-btns ml-1"
                  //disabled={addBtnDisabled}
                >
                  Remove
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16"
            onClick={hideModal}
            id="modal-close-btn"
          >
            Close
          </button>
          <button
            // type="submit"
            onClick={addUserSubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={addBtnDisabled}
          >
            Update
          </button>
          {/* <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          /> */}
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

const add_user_btns = {
  display: "inline-block",
  padding: "6px 16px",
  fontSize: "12px",
};

const remove_user_btn = {
  display: "inline-block",
  padding: "4px 6px",
  fontSize: "12px",
  marginLeft: "0.5rem",
};

export default EditRegUser;
