import React, { Fragment } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import Edit from "../../../assets/edit.svg";
import Delete_Icon from "../../../assets/delete-btn.png";
import RevertIcon from "../../../assets/revert.svg";
import Save from "../../../assets/save.svg";
import Eye from "../../../assets/eye-icon.svg";
import Preview from "../../../assets/preview.svg";

import axios from "axios";
import "./Table.scss";
import DisableModal from "../../Corporate/Modals/DisableModal/DisableModal";
import { useDispatch, useSelector } from "react-redux";
import { corporateActions } from "../../../store/corporate";
import { communityActions } from "../../../store/community";
import { RegUserActions } from "../../../store/regUser";
import { sidebarActions } from "../../../store/sidebar";
import { Alert } from "react-bootstrap";
import { getWithExpiry } from "../../utils/helper";

const RowActionButtons = (props) => {
  const {
    showDeleteModal,
    showDisableModal,
    showEnableModal,
    type,
    recordId,
    setId,
    isEnable,
    isDefault,
    dataType,
    showEditModal,
    delete_id,
    name,
    page,
    data,
    showGroupTestDetailModal,
    deleteCredit,
  } = props;


  const token = "Bearer " + getWithExpiry("expiry_token");

  const history = useHistory();

  const params = useParams();

  const dispatch = useDispatch();
  const setBreadcrumb = (record) => {
    dispatch(sidebarActions.setSelectedRecord(record));
  };

  const deleteActionHandler = () => {
    showDeleteModal();
    setId(recordId, "delete");
  };

  const dispatchActionTypeEnable = () => {
    showEnableModal();
    dispatch(corporateActions.setActionType("enable"));
    setId(recordId, "enable");
  };
  const dispatchActionTypeEdit = () => {
    showEditModal();
    dispatch(corporateActions.setIphoneEditId(recordId));
    dispatch(corporateActions.setIphoneEditnumber(data.number));
    dispatch(corporateActions.setIphoneEditDesc(data.description));
    setId(recordId, "edit");
  };

  const handleEditAction = () => {
    dispatch(RegUserActions.setIdToBeEdited(delete_id));
    setBreadcrumb(name);

    if (page === "sub-page") {
      axios({
        url: `${process.env.REACT_APP_URL_PATH}/community/update_community_user/${delete_id}`,
        method: "GET",
        headers: {
          "Content-Type": "Application/json",
          Authorization: token,
        },
      })
        .then((response) => {
          dispatch(RegUserActions.setRegUserInfo(response.data.data.user));
        })
        .catch((error) => {
        });
      showEditModal();
    }
  };

  return (
    <Fragment>
      <td>
        {type === "Community" && dataType === "history" && (
          <div>
            <span className="td-action" onClick={showGroupTestDetailModal}>
              <img src={Eye} alt="" />
            </span>
            {/* uncomment the following to get the delete functionality */}
            {/* <span
              title="Delete"
              className="td-action"
              // className={`td-action ${disabledClassName}${defaultClassName}`}
              onClick={deleteActionHandler}
            >
              <img src={Delete_Icon} alt="delete-icon" />
            </span> */}
          </div>
        )}

        {type === "billing" && dataType === "pastInvoices" && (
          <div>
            <span
              title="View"
              className="td-action"
              onClick={showGroupTestDetailModal}
            >
              <img src={Eye} alt="" />
            </span>
          </div>
        )}
        {type === "billing" && dataType === "testToBill" && (
          <div>
            <span
              title="Revert Credit"
              className="td-action"
              onClick={deleteCredit}
            >
              <img src={RevertIcon} alt="" />
            </span>
          </div>
        )}
      </td>
    </Fragment>
  );
};

export default RowActionButtons;
