export const setOrderAndIcon = (object) => {
  var obj = { ...object };

  const generic = "";
  const asc = "asc";
  const desc = "desc";
  const iconAsc = "asc";
  const iconDesc = "desc";

  if (obj.state === 1) {
    obj.icon = iconDesc;
    obj.order = desc;
    obj.state = 2;
    return obj;
  } else if (object.state === 2) {
    obj.icon = iconAsc;
    obj.order = asc;
    obj.state = 1;
    return obj;
  }

  else {
    return obj;
  }
};
export const buildSeatchTextQuery = (seatchText) => {
  let searchQuery = "";
  searchQuery = seatchText !== "" ? `search_query=${seatchText}` : "";
  return searchQuery;
};

export function removeEmptyParams(query) {
  query = query.replace(/[^=&]+=(?:&|$)/g, "");
  let first = query.trim().slice(0, 1);
  let last = query.trim().slice(-1);

  if (first === "&") {
    query = query.substring(1);
  }

  if (last === "&") {
    query = query.slice(0, -1);
  }
  return query;
}
