import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Input from "react-phone-number-input/input";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./UpdateIphoneModel.scss";
import CloseModal from "../../../../assets/close-outline.svg";
import { useSelector } from "react-redux";
import { getWithExpiry } from "../../../utils/helper";
import Loader2 from "../../../UI/loader2/loader2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const UpdateIphoneModel = (props) => {
  const { hideModal, data, setDataFlag, setShowToast } = props;
  const dataid = useSelector((state) => state.corporate.iphoneEditId);
  const dataNumber = useSelector((state) => state.corporate.IphoneEditnumber);
  const datadesc = useSelector((state) => state.corporate.IphoneEditDesc);
  const [number, setNumber] = useState("");
  const [user, setUser] = useState([]);

  const [description, setDescription] = useState("");
  const [phoneValue, setPhoneValue] = useState("");

  const [PhoneIdError, setPhoneIdError] = useState("");
  const [PhoneNumberError, setPhoneNumberError] = useState("");
  const [PhoneDescError, setPhoneDescError] = useState("");
  const token = "Bearer " + getWithExpiry("expiry_token");

  const [disableBtn, setDisableBtn] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPhoneValue(dataNumber);
    setDescription(datadesc);
  }, []);

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const editCommunitySubmissionHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    setDisableBtn(true);

    setPhoneNumberError("");
    setPhoneDescError("");
    // create form data here
    // const form = document.querySelector(".edit-corporate-form");
    //const fd = new FormData(form);

    const fd = new FormData();

    //let phoneVal = phoneValue.replace(/\s+/g, "");
    if (phoneValue && !phoneValue.includes("+", 0)) {
      fd.append("number", "+" + phoneValue);
    } else if (phoneValue) {
      fd.append("number", phoneValue);
    }

    fd.append("description", description);

    // make http call
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/test/update_iphone/${data.id}`,
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      })
      .catch((error) => {
        setLoading(false);
        setDisableBtn(false);
        if (error.response.data.message === "Field Errors") {
          if (
            error.response.data.data["iphone_form"] &&
            "iphone_id" in error.response.data.data["iphone_form"]
          ) {
            setPhoneIdError(
              error.response.data.data["iphone_form"]["iphone_id"][0]
            );
          }
          if (
            error.response.data.data["iphone_form"] &&
            "number" in error.response.data.data["iphone_form"]
          ) {
            setPhoneNumberError(
              error.response.data.data["iphone_form"]["number"][0]
            );
          }
          if (
            error.response.data.data["iphone_form"] &&
            "description" in error.response.data.data["iphone_form"]
          ) {
            setPhoneDescError(
              error.response.data.data["iphone_form"]["description"][0]
            );
          }
        } else {
          notifyError(error.response.data.message);
        }

        setDisableBtn(false);
      });
  };
  const xid = data.id;

  return (
    <div className="filter-modal-wrapper ">
      {dataid === xid && (
        <Modal
          className="filter-modal modal-loader"
          show={true}
          onHide={hideModal}
          backdrop="static"
          keyboard={false}
          centered
          scrollable
        >
          <Modal.Header className="filter-modal-header">
            <h1 className="modal-header-title">Edit iPhone</h1>
            <span className="close-icon-modal " onClick={hideModal}>
              <img src={CloseModal} alt="" />
            </span>
          </Modal.Header>
          <Modal.Body className="filter-modal-body">
            <form
             
              method="post"
              className="edit-corporate-form"
              onSubmit={editCommunitySubmissionHandler}
            >
              <div className="field-wrapper">
                <label htmlFor="address_1" className="label">
                  iPhone Number *
                </label>
                {/* <input
                  type="text"
                  id="iphone_number"
                  name="number"
                  className="custom-input"
                  placeholder="iPhone Number"
                  onChange={(e) => {
                    const { name, value } = e.target;

                    const regex = /^([\s+0-9])+$/i;
                    if (value === "") {
                      // case to remove whole highlighted text
                      setPhoneValue(value);
                      return;
                    }
                    if (regex.test(value) === false) return;

                    if (value.length === 1 && value === "+") {
                      // case to remove + sign when last number is removed
                      setPhoneValue("");
                    } else if (value.length >= 1 && value[0] !== "+") {
                      // case to append + sign with first number
                      setPhoneValue("+" + value);
                    } else {
                      // case to append other numbers
                      setPhoneValue(value);
                    }
                  }}
                  value={phoneValue}
                  required
                /> */}
                <PhoneInput
                  country={"us"}
                  value={phoneValue}
                  onChange={setPhoneValue}
                  inputProps={{
                    required: true,
                  }}
                />
                {PhoneNumberError && (
                  <p className="error-text">{PhoneNumberError}</p>
                )}
              </div>
              <div className="field-wrapper">
                <label htmlFor="address_2" className="label">
                  Description
                </label>
                <textarea
                  className="custom-text-area"
                  rows="3"
                  type="text"
                  name="description"
                  id="description"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
                {/* <input
                  type="text"
                  name="description"
                  id="description"
                  className="custom-input-iphone-desc"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                /> */}
                {/* {PhoneDescError && (
                  <p className="error-text">{PhoneDescError}</p>
                )} */}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer className="addmisnis-footer-modal">
            <button
              className="btn btn-secondary-fill btn-primary-16 text-capitalize"
              onClick={hideModal}
            >
              Close
            </button>
            <button
              // type="submit"
              onClick={editCommunitySubmissionHandler}
              className="btn btn-primary-fill btn-primary-16"
              disabled={disableBtn}
            >
              Update
            </button>
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Modal.Footer>
          {loading && <Loader2 />}
        </Modal>
      )}
    </div>
  );
};

export default UpdateIphoneModel;
