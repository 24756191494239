import React from "react";
import pageNotImage from "../../assets/pagenoteimage.png";
import "./NotFound.scss";

function NotFound() {
  return (
    <div className="profile-wrapper pageNotFoundWraper">
      <div className="inner-pageNotFound">
        <img src={pageNotImage} className="not-found-img" />
        <div className="textArea-notFound">
          <h1 className="oops-heading">oops!</h1>
          <h2 className="oops-detail">
            We can’t seem to find the page you are looking for
          </h2>
          <span className="oops-delay">Don’t worry, try it later</span>
          {/* <button className="btn btn-back-home">Back Home</button> */}
        </div>
      </div>
    </div>
  );
}

export default NotFound;
