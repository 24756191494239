import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "./DateTimePicker.scss";

function DateTime({ startDate, setStartDate, type, ...rest }) {
  // const [startDate, setStartDate] = useState(new Date());
  // const { startDate, setStartDate } = props;

  return (
    <div className="date-time-wrapper">
      <DatePicker
        className="custom-dateTime-input"
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        // onChangeRaw={(e) => {
        //   e.preventDefault();
        // }}
        timeInputLabel="Time:"
        dateFormat="MM/dd/yyyy h:mm aa"
        showTimeInput
        minDate={
          type === "group"
            ? new Date(new Date().setDate(new Date().getDate() + 1))
            : new Date()
        }
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        {...rest}
      />
      <span className="dateTime-divider"></span>
    </div>
  );
}

export default DateTime;
