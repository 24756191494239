import { createSlice } from "@reduxjs/toolkit";

const initialCorporateState = {
  corporates: [],
  corporatespage: 1,
  corporateTotalRecords: 0,
  communityPage: 1,
  pagehit: "",
  userPages: 1,
  idToBeDeleted: "",
  idToBeDisabled: "",
  idToBeEnabled: "",
  idToBeEdited: "",
  successMessage: "",
  actionType: "",
  communityList: [],
  userList: [],
  corporateInfo: {},
  corporateAdmins: [],
  fileUploadData: [],
  corporateFile: [],
  filteredFileData: [],
  isEable: "",
  communityTotalRecords: 0,
  userTotalRecords: 0,
  iphoneEditId: 0,
  IphoneEditDesc: "",
  IphoneEditnumber: 0,
  corporateApiURL: "",
  corporateCurrentPage: 1,
  corporateEntitiesCurrentPage: 1,
  communityCorporateCurrentPage: 1,
  userCorporateCurrentPage: 1,

  communityCorporateApiUrl: "",
  communityCorporateOrderQuery: "",
  userCorporateApiUrl: "",
  userCorporateOrderQuery: "",

  communityCorporateFilteredCountry: [],
  communityCorporateFilteredCity: [],
  communityCorporateFilteredState: [],
  communityCorporateFilteredZip: [],
  userCorporateFilteredRole: [],
};

const corporateSlice = createSlice({
  name: "corporate",
  initialState: initialCorporateState,
  reducers: {
    setCorporateApiUrl(state, action) {
      state.corporateApiURL = action.payload;
    },
    setCommunityCorporateApiUrl(state, action) {
      state.communityCorporateApiUrl = action.payload;
    },
    setUserCorporateApiUrl(state, action) {
      state.userCorporateApiUrl = action.payload;
    },
    setCommunityCorporateOrderQuery(state, action) {
      state.communityCorporateOrderQuery = action.payload;
    },
    setUserCorporateOrderQuery(state, action) {
      state.userCorporateOrderQuery = action.payload;
    },
    setCorporatePageNumber(state, action) {
      state.corporateCurrentPage = action.payload;
    },
    setCorporateEntitiesPageNumber(state, action) {
      state.corporateEntitiesCurrentPage = action.payload;
    },
    setCommunityCorporateCurrentPage(state, action) {
      state.communityCorporateCurrentPage = action.payload;
    },
    setUserCorporateCurrentPage(state, action) {
      state.userCorporateCurrentPage = action.payload;
    },

    setUserCorporateFilteredRole(state, action) {
      state.userCorporateFilteredRole = action.payload;
    },

    setCommunityCorporateFilteredCountry(state, action) {
      state.communityCorporateFilteredCountry = action.payload;
    },

    setCommunityCorporateFilteredCity(state, action) {
      state.communityCorporateFilteredCity = action.payload;
    },

    setCommunityCorporateFilteredState(state, action) {
      state.communityCorporateFilteredState = action.payload;
    },

    setCommunityCorporateFilteredZip(state, action) {
      state.communityCorporateFilteredZip = action.payload;
    },

    setIphoneEditDesc(state, action) {
      state.IphoneEditDesc = action.payload;
    },
    setIphoneEditnumber(state, action) {
      state.IphoneEditnumber = action.payload;
    },
    setIphoneEditId(state, action) {
      state.iphoneEditId = action.payload;
    },
    setIsEnable(state, action) {
      state.isEable = action.payload;
    },
    addUserpages(state, action) {
      state.userPages = action.payload;
    },
    addcommunityPage(state, action) {
      state.communityPage = action.payload;
    },
    addCorporate(state, action) {
      state.corporates.push(action.payload);
    },
    addCorporatePage(state, action) {
      state.corporatespage = action.payload;
    },
    addCorporateTotalRecords(state, action) {
      state.corporateTotalRecords = action.payload;
    },
    setPage(state, action) {
      state.pagehit = action.payload;
    },
    addCorporates(state, action) {
      state.corporates = action.payload;
    },
    editCorporate(state, payload) {
      const corporate = state.corporates.find(
        (corporate) => corporate.id === payload
      );
    },
    deleteCorporate(state, payload) {},
    disableCorporate(state, payload) {},
    importCorporates(state, payload) {
      state = payload;
    },
    addCorporateSuccessMessage(state, action) {
      state.successMessage = action.payload;
    },
    setIdToBeDeleted(state, action) {
      state.idToBeDeleted = action.payload;
    },
    setIdToBeDisabled(state, action) {
      state.idToBeDisabled = action.payload;
    },
    setIdToBeEnabled(state, action) {
      state.idToBeEnabled = action.payload;
    },
    setIdToBeEdited(state, action) {
      state.idToBeEdited = action.payload;
    },
    setActionType(state, action) {
      state.actionType = action.payload;
    },
    setCommunityList(state, action) {
      state.communityList = action.payload;
    },
    setUserList(state, action) {
      state.userList = action.payload;
    },
    setCorporateInfo(state, action) {
      state.corporateInfo = action.payload;
    },
    setCorporateAdmins(state, action) {
      state.corporateAdmins = action.payload;
    },
    setFileUploadData(state, { payload }) {
      state.fileUploadData = payload;
      state.filteredFileData = state.fileUploadData;
    },
    saveUploadFile(state, { payload }) {
      state.corporateFile = payload;
    },
    filteredFile(state, { payload }) {
      const data = payload;
      state.filteredFileData = state.fileUploadData.filter(
        (item) => item === "last name"
      );
    },
    setCommunityTotalRecords(state, action) {
      state.communityTotalRecords = action.payload;
    },
    setUserTotalRecords(state, action) {
      state.userTotalRecords = action.payload;
    },
  },
});

export const corporateActions = corporateSlice.actions;

export default corporateSlice.reducer;
