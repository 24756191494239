import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { Tab, Table, Tabs } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { AdminUserActions } from "../../store/adminUser";
import { userActions } from "../../store/user";
import { sidebarActions } from "../../store/sidebar";
import { RegUserActions } from "../../store/regUser";
import TableComponent from "../UI/Table/TableData";
import Loader from "../UI/loader/loader";
import axios from "axios";
import { getWithExpiry } from "../utils/helper";
import useQueryString from "../../hooks/useQueryString";
function Users() {
  const urlParams = useQueryString();
  const tableHeadings = ["First Name", "Last Name", "Email", "Telephone"];
  const [key, setKey] = useState("AdminUser");
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);

  const [otherLoader1, setOtherLoader1] = useState(false);
  const [otherLoader2, setOtherLoader2] = useState(false);

  const [dataFlag, setDataFlag] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const userRole = useSelector((state) => state.user.role);

  const dispatch = useDispatch();
  const token = "Bearer " + getWithExpiry("expiry_token");
  const adminUsers = useSelector((state) => state.adminuser.admins);
  const totalRecordsCatchUAdmins = useSelector(
    (state) => state.adminuser.totalRecordsAdmins
  );
  const regUsers = useSelector((state) => state.reguser.users);
  const regUserRoles = useSelector((state) => state.reguser.userRoles);
  const totalRecordsRegularUsers = useSelector(
    (state) => state.reguser.regularUsersTotalRecords
  );

  const RegUserCurrentPage = useSelector(
    (state) => state.reguser.regUserCurrentPage
  );
  const AdminUserCurrentPage = useSelector(
    (state) => state.adminuser.adminUserCurrentPage
  );
  const regUserApiUrl = useSelector((state) => state.reguser.regUserApiUrl);
  const adminUserApiUrl = useSelector(
    (state) => state.adminuser.adminUserApiUrl
  );

  useEffect(() => {
    dispatch(sidebarActions.setSelectedNav("Users"));
    setLoading1(true);
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/user/regular_user_roles/`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        const userRoles = response.data.data.roles;

        dispatch(RegUserActions.addUserRoles(userRoles));
        setLoading1(false);
      })
      .catch((error) => {
        setLoading1(false);
      });
  }, []);

  //AdminsList
  useEffect(() => {
    dispatch(sidebarActions.setSelectedNav("Users"));
    if (!showToast) {
      setLoading2(true);
    }

    let urlKeys = [...urlParams.keys()];
    let urlValues = [...urlParams.values()];

    let query = "";
    if (!adminUserApiUrl && urlKeys.length > 0) {
      query = "?";
      if (urlValues.includes("admin_user")) {
        setKey("AdminUser");
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      }
    } else {
      query = adminUserApiUrl ? adminUserApiUrl + "&" : "?";
    }

    let pageNumber = 1;
    if (urlValues.includes("admin_user")) {
      pageNumber = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? urlParams.get("page")
          : AdminUserCurrentPage
        : AdminUserCurrentPage;
    }

    const Url = `${process.env.REACT_APP_URL_PATH}/user/admins_list/${query}page=${pageNumber}`;

    query = query.replace("&&", "&");
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/user/admins_list/${query}page=${pageNumber}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        const AdminUsers = response.data.data["Admins"];
        dispatch(AdminUserActions.addAdminUsers(AdminUsers));
        dispatch(
          AdminUserActions.setAdminUsersPageNumber(
            response.data.data.admins_pages
          )
        );
        dispatch(
          AdminUserActions.setAdminsTotalRecords(
            response.data.data.total_records
          )
        );
        if (AdminUserCurrentPage > response.data.data.admins_pages) {
          dispatch(
            AdminUserActions.setAdminUserCurrentPage(
              response.data.data.admins_pages
            )
          );
        }
        setLoading2(false);
        setOtherLoader1(false);
      })
      .catch((error) => {
        setLoading2(false);
        setOtherLoader1(false);
      });
  }, [dataFlag]);

  // UsersList;
  useEffect(() => {
    dispatch(sidebarActions.setSelectedNav("Users"));
    if (!showToast) {
      setLoading3(true);
    }

    let urlKeys = [...urlParams.keys()];
    let urlValues = [...urlParams.values()];

    let query = "";
    if (!regUserApiUrl && urlKeys.length > 0) {
      query = "?";
      if (urlValues.includes("regular_user")) {
        setKey("RegUser");
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      }
    } else {
      query = regUserApiUrl ? regUserApiUrl + "&" : "?";
    }

    let pageNumber = 1;
    if (urlValues.includes("regular_user")) {
      pageNumber = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? urlParams.get("page")
          : RegUserCurrentPage
        : RegUserCurrentPage;
    }

    query = query.replace("&&", "&");
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/user/regular_user_list/${query}page=${pageNumber}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        const RegUsers = response.data.data;
        dispatch(RegUserActions.addRegUsers(RegUsers["Regular Users"]));
        dispatch(RegUserActions.setCommunityList(RegUsers["communities"]));
        dispatch(RegUserActions.setCorporateList(RegUsers["corporates"]));
        dispatch(
          RegUserActions.setCorporatePageNumber(
            response.data.data.regular_users_pages
          )
        );
        dispatch(
          RegUserActions.setRegularUsersTotalRecords(
            response.data.data.total_records
          )
        );

        if (RegUserCurrentPage > response.data.data.regular_users_pages) {
          dispatch(
            RegUserActions.setRegUserCurrentPage(
              response.data.data.regular_users_pages
            )
          );
        }

        setLoading3(false);
        setOtherLoader2(false);
      })
      .catch((error) => {
        setLoading3(false);
        setOtherLoader2(false);
      });
  }, [dataFlag]);

  return (
    <Fragment>
      {loading1 && loading2 && loading3 ? (
        <Loader />
      ) : (
        <div className="edit-corp-wrapper">
          {userRole.includes("Super Admin") ? (
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="AdminUser" title="CatchU Admins">
                <TableComponent
                  tableHeadings={tableHeadings}
                  data={adminUsers}
                  totalRecords={totalRecordsCatchUAdmins}
                  type="AdminUser"
                  heading={"CatchU Admins"}
                  isAdd={true}
                  isImport={true}
                  setDataFlag={setDataFlag}
                  setShowToast={setShowToast}
                  TabKey={key}
                  otherLoader={otherLoader1}
                  setOtherLoader={setOtherLoader1}
                />
              </Tab>
              <Tab eventKey="RegUser" title="Regular Users">
                <TableComponent
                  tableHeadings={tableHeadings}
                  data={regUsers}
                  totalRecords={totalRecordsRegularUsers}
                  regUserRoles={regUserRoles}
                  type="RegUser"
                  heading={"Regular Users"}
                  isAdd={true}
                  isImport={true}
                  setDataFlag={setDataFlag}
                  setShowToast={setShowToast}
                  TabKey={key}
                  otherLoader={otherLoader2}
                  setOtherLoader={setOtherLoader2}
                />
              </Tab>
            </Tabs>
          ) : (
            <TableComponent
              tableHeadings={tableHeadings}
              data={regUsers}
              totalRecords={totalRecordsRegularUsers}
              regUserRoles={regUserRoles}
              type="RegUser"
              heading={"Regular Users"}
              isAdd={true}
              isImport={true}
              setDataFlag={setDataFlag}
              setShowToast={setShowToast}
              TabKey={"RegUser"}
              otherLoader={otherLoader2}
              setOtherLoader={setOtherLoader2}
            />
          )}
        </div>
      )}
      
    </Fragment>
  );
}

export default Users;
