import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DateTime from "../../DateTimePicker/DateTimePicker";
import Moment from "moment";
import axios from "axios";
import { dateParse } from "../../utils/helper";
import "./ApplyCredit.scss";
import Loader2 from "../../UI/loader2/loader2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getWithExpiry } from "../../utils/helper";
import Select from "react-select";
import DatePicker from "react-datepicker";
import CloseModal from "../../../assets/close-outline.svg";

const ApplyCredit = (props) => {
  const { isShowModal, hideModal, setDataFlag, setShowToast } = props;

  const [disabledBtn, setDisabledBtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [billeeList, setBilleeList] = useState([]);
  const [testList, setTestList] = useState([]);
  const [billeeID, setBilleeID] = useState("");

  const [amountError, setAmountError] = useState("");
  const [amountReasonError, setAmountReasonError] = useState("");
  const [amount, setAmount] = useState("");
  const [amountReason, setAmountReason] = useState("");

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const token = "Bearer " + getWithExpiry("expiry_token");

  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/billing/active_billee_list/`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        setBilleeList(response.data.data.billee_list);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);
  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const handleBilleeSelect = (e) => {
    setBilleeID(e.value);
  };
  useEffect(() => {
    if (billeeID) {
      handleDateSelect();
    }
  }, [dateRange]);

  const handleDateSelect = () => {
    if (billeeID) {
      let myUrl = `${process.env.REACT_APP_URL_PATH}/billing/billable_test_list/${billeeID}`;
      if (startDate && endDate) {
        let date_from = Moment(startDate.toLocaleDateString()).format(
          "MM-DD-YYYY"
        );
        let date_to = Moment(endDate.toLocaleDateString()).format("MM-DD-YYYY");

        myUrl = `${process.env.REACT_APP_URL_PATH}/billing/billable_test_list/${billeeID}?test_date_from=${date_from}&test_date_to=${date_to}`;
      }
      setLoading(true);
      axios({
        url: myUrl,
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((response) => {
          setLoading(false);
          setTestList(response.data.data.test_list);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const applyCrediSubmissionHandler = async () => {
    setLoading(true);
    setDisabledBtn(true);
    setAmountError("");
    setAmountReasonError("");

    const form = document.querySelector(".apply-credit-form");
    const fd = new FormData(form);

    let credit_amount = fd.get("credit_amount");
    if (credit_amount > 0) {
      axios({
        url: `${process.env.REACT_APP_URL_PATH}/billing/apply_credit/`,
        method: "POST",
        data: fd,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((response) => {
          setLoading(false);
          hideModal();
          notifySuccess(response.data.message);
          setShowToast(true);
          setDataFlag((prevState) => !prevState);
        })
        .catch((error) => {
          setLoading(false);
          setDisabledBtn(false);

          notifyError(error.response.data.message);
        });
    } else {
      setLoading(false);
      setDisabledBtn(false);
      if (!fd.get("credit_amount")) {
        setAmountError("This field is required");
      } else {
        setAmountError("Credit Amount Should be Greater than 0");
      }
    }
  };

  const billeeOptions = [
    billeeList.map((data) => ({
      value: data.id,
      label: data.name + " (" + data.company + ")",
    })),
  ];

  const testOptions = [
    testList.map((data) => ({
      value: data.id,
      label: data.patient_name
        ? data.test_id + ", " + data.patient_name
        : data.test_id,
    })),
  ];

  return (
    <div className="filter-modal-wrapper">
      <Modal
        className="filter-modal initiate-test-patient-modal modal-loader"
        show={true}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
      >
        <Modal.Header className="filter-modal-header">
          <h1 className="modal-header-title">Apply Credit</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="close-modal" />
          </span>
        </Modal.Header>
        <Modal.Body className="filter-modal-body">
          <form
            method="post"
            className="apply-credit-form"
            onSubmit={applyCrediSubmissionHandler}
          >
            <div className="field-wrapper">
              <label htmlFor="billee" className="label">
                Select Billee *
              </label>
              <Select
                className="basic-multi-select"
                classNamePrefix="select"
                options={billeeOptions[0]}
                onChange={handleBilleeSelect}
                id="billee_id"
                name="billee_id"
                placeholder={"Select Billee"}
              />
            </div>

            {/* <div className="field-wrapper">
              <label htmlFor="scheduled" className="label">
                Select Test Date Range
              </label>
              <div className="date-time-wrapper">
                <DatePicker
                  className="custom-dateTime-input"
                  placeholderText="Select your Date Range"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  //isClearable
                  onChange={(update) => {
                    setDateRange(update);
                    //handleDateSelect();
                  }}
                  dateFormat="MM/dd/yyyy"
                  //maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                <span className="dateTime-divider"></span>
              </div>
            </div>
            <div className="field-wrapper">
              <label htmlFor="billee" className="label">
                Select Test *
              </label>
              <Select
                className="basic-multi-select"
                classNamePrefix="select"
                options={testOptions[0]}
                onChange={(e) => {
                  if (e.value === -1) {
                    setAmount("");
                  } else {
                    setAmount("29.00");
                  }
                }}
                id="test_id"
                name="test_id"
                placeholder={"Select Test"}
              />
            </div> */}
            <div className="field-wrapper">
              <label htmlFor="amount_reason" className="label">
                Credit Reason *
              </label>

              <input
                type="text"
                id="amount_reason"
                name="amount_reason"
                className="custom-input"
                placeholder="Credit Reason"
                onChange={(e) => setAmountReason(e.target.value)}
                value={amountReason}
                required
              />
            </div>

            <div className="field-wrapper">
              <label htmlFor="credit_amount" className="label">
                Credit Amount *
              </label>

              <input
                type="text"
                id="credit_amount"
                name="credit_amount"
                className="custom-input"
                placeholder="Credit Amount"
                onChange={(e) => {
                  const { name, value } = e.target;

                  const regex = /^\d{1,5}(\.$|\.\d{1,2}$|$)/;
                  if (value === "") {
                    // case to remove whole highlighted text
                    setAmount(value);
                    return;
                  }
                  if (regex.test(value) === false) return;

                  // const regex = /^\d{1,5}(\.$|\.\d{1,2}$|$)/;

                  // if (value === "") {
                  //   // case to remove whole highlighted text
                  //   setAmount(value);
                  //   return;
                  // }
                  // if (regex.test(value) === false) return;

                  // if (value.length === 1 && value === "$") {
                  //   // case to remove + sign when last number is removed
                  //   setAmount("");
                  // } else if (value.length >= 1 && value[0] !== "$") {
                  //   // case to append + sign with first number
                  //   setAmount("$" + value);
                  // } else {
                  //   // case to append other numbers
                  //   setAmount(value);
                  // }
                  setAmount(value);
                }}
                value={amount}
                required
              />
              {amountError && <p className="error-text">{amountError}</p>}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            id="close-btn"
            className="btn btn-secondary-fill btn-primary-16"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            // type="submit"
            onClick={applyCrediSubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={disabledBtn}
          >
            Apply
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
};

export default ApplyCredit;
