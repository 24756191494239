import { createSlice } from "@reduxjs/toolkit";

const initialBillingState = {
  testToBillList: [],
  testBillPageNumber: 1,
  testBillCurrentPage: 1,
  testBilltotalRecords: 0,

  pastInvoiceList: [],
  pastInvoiceDetail: {},
  pastInvoicePageNumber: 1,
  pastInvoiceCurrentPage: 1,
  pastInvoicetotalRecords: 0,

  pastInvoiceHistoryPageNumber: 1,
  pastInvoiceHistoryCurrentPage: 1,
  pastInvoiceHistorytotalRecords: 0,

  creditHistoryList: [],
  creditHistoryPageNumber: 1,
  creditHistoryCurrentPage: 1,
  creditHistorytotalRecords: 0,

  billingEntityCurrentPage: 1,

  testsBillingApiUrl: "",
  invoicesBillingApiUrl: "",
  creditHistoryApiUrl: "",


  testsBillingOrderQuery: "",
  invoicesBillingOrderQuery: "",
  creditHistoryOrderQuery: "",

  equipments: {},
  equipmentsCurrentPage: 1,
  equipmentsApiUrl: "",
  total_equipment_records: "",
  equipmentsPageNumbers:""


};

const BillingSlice = createSlice({
  name: "billing",
  initialState: initialBillingState,
  reducers: {
    setPastInvoiceHistoryPageNumber(state, action) {
      state.pastInvoiceHistoryPageNumber = action.payload;
    },
    setPastInvoiceHistoryCurrentPage(state, action) {
      state.pastInvoiceHistoryCurrentPage = action.payload;
    },
    setPastInvoiceHistorytotalRecords(state, action) {
      state.pastInvoiceHistorytotalRecords = action.payload;
    },

    setCreditHistoryList(state, action) {
      state.creditHistoryList = action.payload;
    },
    setCreditHistoryPageNumber(state, action) {
      state.creditHistoryPageNumber = action.payload;
    },
    setCreditHistoryCurrentPage(state, action) {
      state.creditHistoryCurrentPage = action.payload;
    },
    setCreditHistorytotalRecords(state, action) {
      state.creditHistorytotalRecords = action.payload;
    },
    setCreditHistoryApiUrl(state, action) {
      state.creditHistoryApiUrl = action.payload;
    },

    setEquipmentsApiUrl(state, action) {
      state.equipmentsApiUrl = action.payload;
    },

    setCreditHistoryOrderQuery(state, action) {
      state.creditHistoryOrderQuery = action.payload;
    },

    setTestsBillingOrderQuery(state, action) {
      state.testsBillingOrderQuery = action.payload;
    },
    setInvoicesBillingOrderQuery(state, action) {
      state.invoicesBillingOrderQuery = action.payload;
    },

    setTestsBillingApiUrl(state, action) {
      state.testsBillingApiUrl = action.payload;
    },
    setInvoicesBillingApiUrl(state, action) {
      state.invoicesBillingApiUrl = action.payload;
    },

    setTestToBillList(state, action) {
      state.testToBillList = action.payload;

    },

    setTestBillPageNumber(state, action) {
      state.testBillPageNumber = action.payload;

    },
    setTestBillTotalRecords(state, action) {
      state.testBilltotalRecords = action.payload;
    },

    setPastInvoiceList(state, action) {
      state.pastInvoiceList = action.payload;

    },
    setPastInvoicePageNumber(state, action) {

      state.pastInvoicePageNumber = action.payload;
    },
    setPastInvoiceTotalRecords(state, action) {
      state.pastInvoicetotalRecords = action.payload;
    },

    setBillingEntityCurrentPage(state, action) {
      state.billingEntityCurrentPage = action.payload;
    },
    setTestBillCurrentPage(state, action) {
      state.testBillCurrentPage = action.payload;
    },
    setPastInvoiceCurrentPage(state, action) {
      state.pastInvoiceCurrentPage = action.payload;
    },
    setEquipmentsCurrentPage(state, action) {
      state.equipmentsCurrentPage = action.payload;
    },

    setBillingPastInvoiceDetail(state, action) {
      state.pastInvoiceDetail = action.payload
    },
    setEquipmentsList(state, action) {
      state.equipments = action.payload.equipment_to_bill_list
      state.equipmentsPageNumbers =action.payload.equipment_to_bill_pages
      state.total_equipment_records =action.payload.equipment_to_bill_total_records
    },


  },
});



export const billingActions = BillingSlice.actions;

export default BillingSlice.reducer;
