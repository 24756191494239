import React from "react";
import CloseButton from "../../../assets/close-outline.svg";
import SearchButton from "../../../assets/search-icon.svg";
const SearchInput = (props) => {
  const { handleChange, onKeyEnter, value, onClearSearch } = props;
  return (
    <div className="search-wrapper">
      <input
        type="search"
        name="search"
        id="search"
        value={value}
        onChange={handleChange}
        onKeyDown={onKeyEnter}
        placeholder="Search"
        className="custom-search"
      />
      {value ? (
        <img src={CloseButton} alt={""} onClick={onClearSearch} />
      ) : (
        <img src={SearchButton} alt={""} />
      )}
    </div>
  );
};

export default SearchInput;
