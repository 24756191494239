import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import useInput from "../../../../hooks/use-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getWithExpiry } from "../../../utils/helper";
import Loader2 from "../../../UI/loader2/loader2";
import "./AddCommunityModal.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import images
import CloseModal from "../../../../assets/close-outline.svg";
import DisabledIcon from "../../../../assets/disabled-row.svg";
import { useHistory } from "react-router-dom";
import Input from "react-phone-number-input/input";

const isNotEmpty = (value) => value.trim() !== "";
const isEmailValid = (value) => value.includes("@");

const isPhoneValid = (value) => {
  const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  return isNotEmpty(value) && regex.test(value);
};

function AddCommunityModal(props) {
  const { isShowModal, hideModal, type, setDataFlag, setShowToast } = props;
  const [address2, setAddress2] = useState("");

  const setAddress2Handler = (e) => setAddress2(e.target.value);

  const [nameError, setNameError] = useState("");
  const [address1Error, setAddress1Error] = useState("");
  const [cityError, setCityError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [zipError, setZipError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [roleError, setRoleError] = useState("");

  const [country, setCountry] = useState("United States");
  const [zip, setZip] = useState("");

  const [isIndividualPractice, setIsIndividualPractice] = useState(null);

  const [role, setRole] = useState("Community Admin");
  const [corpoarte, setCorporate] = useState("");

  const [phoneValue, setPhoneValue] = useState("");

  const [disabledBtn, setDisabledBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkedeBox, setCheckedeBox] = useState(true);
  const isCatchuAdmin = useSelector((state) => state.community.isCatchuAdmin);
  const corporates = useSelector((state) => state.community.corporates);
  const userRole = useSelector((state) => state.user.role);
  const enabledCorporates = corporates.filter(
    (corporate) => corporate.is_enabled === true
  );

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const setRoleHandler = (e) => {
    e.preventDefault();
    setRole(e.target.value);
  };

  const setCorporateHandler = (e) => {
    e.preventDefault();
    setCorporate(e.target.value);
  };

  const token = "Bearer " + getWithExpiry("expiry_token");

  const addCommunitySubmissionHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    setDisabledBtn(true);
    setNameError("");
    setAddress1Error("");
    setCountryError("");
    setStateError("");
    setCityError("");
    setZipError("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");
    // create form data here
    const form = document.querySelector(".add-corporate-form");
    const fd = new FormData(form);

    //let phoneVal = fd.get("telephone_number").replace(/\s+/g, "");
    if (phoneValue) {
      fd.append("telephone_number", "+" + phoneValue);
    }

    // make http call
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/community/add_community/`,
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      })
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          setDisabledBtn(false);
          setRoleError(error.response.data.message);
          if (error.response.data.message === "Field Errors") {
            if (
              error.response.data.data["address_form"] &&
              "address_1" in error.response.data.data["address_form"]
            ) {
              setAddress1Error(
                error.response.data.data["address_form"]["address_1"][0]
              );
            }
            if (
              error.response.data.data["address_form"] &&
              "city" in error.response.data.data["address_form"]
            ) {
              setCityError(error.response.data.data["address_form"]["city"][0]);
            }
            if (
              error.response.data.data["address_form"] &&
              "country" in error.response.data.data["address_form"]
            ) {
              setCountryError(
                error.response.data.data["address_form"]["country"][0]
              );
            }
            if (
              error.response.data.data &&
              error.response.data.data["address_form"] &&
              "state" in error.response.data.data["address_form"]
            ) {
              setStateError(
                error.response.data.data["address_form"]["state"][0]
              );
            }
            if (
              error.response.data.data["address_form"] &&
              "zip" in error.response.data.data["address_form"]
            ) {
              setZipError(error.response.data.data["address_form"]["zip"][0]);
            }
            if (
              error.response.data.data["community_form"] &&
              "community_name" in error.response.data.data["community_form"]
            ) {
              setNameError(
                error.response.data.data["community_form"]["community_name"][0]
              );
            }
            if (
              error.response.data.data["phone_form"] &&
              "telephone_number" in error.response.data.data["phone_form"]
            ) {
              setPhoneError(
                error.response.data.data["phone_form"]["telephone_number"][0]
              );
            }
            if (
              error.response.data.data["user_form"] &&
              "email" in error.response.data.data["user_form"]
            ) {
              setEmailError(error.response.data.data["user_form"]["email"][0]);
            }
            if (
              error.response.data.data["user_form"] &&
              "first_name" in error.response.data.data["user_form"]
            ) {
              setFirstNameError(
                error.response.data.data["user_form"]["first_name"][0]
              );
            }
            if (
              error.response.data.data["user_form"] &&
              "last_name" in error.response.data.data["user_form"]
            ) {
              setLastNameError(
                error.response.data.data["user_form"]["last_name"][0]
              );
            }
          } else {
            notifyError(error.response.data.message);
          }
        }
      });
  };

  const selectIndividualPractice = (e) => {
    if (e.target.value === "Individual Practice") {
      setIsIndividualPractice(true);
    }
  };

  const IndivdualChange = () => {
    setCheckedeBox(!checkedeBox);
  };

  return (
    <div className="action-modal-wrapper">
      <Modal
        className="comunitie-modal modal-loader"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header
          className="comubitie-modal-header"
          style={{ padding: "1rem 0rem" }}
        >
          <h1 className="modal-header-title">Add Community</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="comunitie-modal-body">
          <form
        
            method="post"
            className="add-corporate-form"
            onSubmit={addCommunitySubmissionHandler}
          >
            <div className="comunties-fields">
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="community_name" className="label">
                      Name *
                    </label>
                    <input
                      id="community_name"
                      name="community_name"
                      type="text"
                      placeholder="Name"
                      className="custom-input"
                      required
                    />
                    {nameError && <p className="error-text">{nameError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="address_1" className="label">
                      Address 01 *
                    </label>
                    <input
                      id="address_1"
                      name="address_1"
                      type="text"
                      placeholder="Address 01"
                      className="custom-input"
                      required
                    />
                    {address1Error && (
                      <p className="error-text">{address1Error}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="address_2" className="label">
                      Address 02
                    </label>
                    <input
                      type="text"
                      name="address_2"
                      id="address_2"
                      className="custom-input"
                      placeholder="Address 02"
                      value={address2}
                      onChange={setAddress2Handler}
                    />
                  </div>
                </div>

                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="city" className="label">
                      City *
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="custom-input"
                      placeholder="City"
                      required
                    />
                    {cityError && <p className="error-text">{cityError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="state" className="label">
                      State *
                    </label>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      className="custom-input"
                      placeholder="State"
                      required
                    />
                    {stateError && <p className="error-text">{stateError}</p>}
                  </div>
                </div>

                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="zip" className="label">
                      Zip *
                    </label>
                    <input
                      type="text"
                      name="zip"
                      id="zip"
                      className="custom-input"
                      placeholder="Zip"
                      value={zip}
                      onChange={(e) => {
                        const { value } = e.target;
                        const regex = /^\d+$/;
                        if (value === "") {
                          setZip(value);
                          return;
                        }

                        if (regex.test(value) === false) return;
                        setZip(value);
                      }}
                      required
                    />
                    {zipError && <p className="error-text">{zipError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="country" className="label">
                      Country *
                    </label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      className="custom-input"
                      placeholder="Country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      required
                    />
                    {countryError && (
                      <p className="error-text">{countryError}</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 right-col">
                  {checkedeBox && (
                    <div className="field-wrapper">
                      <label htmlFor="corporate_id" className="label">
                        Corporates *
                      </label>
                      <select
                        name="corporate_id"
                        id="corporate_id"
                        className="custom-select-modal"
                        onChange={setCorporateHandler}
                      >
                        {corporates.map((name) => (
                          <option value={name.id}>{name.corporate_name}</option>
                        ))}
                        {/* <option value="0">Saqib</option>
                        <option value="1">Sumbal</option> */}
                      </select>
                    </div>
                  )}
                </div>
              </div>
              {(userRole.includes("CatchU Admin") ||
                userRole.includes("Super Admin")) && (
                <div className="row">
                  <div className="field-wrapper">
                    <ul className="check-forget-password add-community-check">
                      <li className="custom-check-orange">
                        <input
                          name="is_individual_practice"
                          className="styled-checkbox"
                          id="styled-checkbox-1"
                          type="checkbox"
                          value="individual-practice"
                          onClick={IndivdualChange}
                        />
                        <label
                          htmlFor="styled-checkbox-1"
                          className="individual-practice text-dark "
                        >
                          Individual Practice
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div className="addminstraion-fields">
              <div className="addminstraion-header">
                <h2 className="adsd-heading">Administrator Information</h2>
                <p className="ad-des">Default User</p>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="first_name" className="label">
                      First Name *
                    </label>
                    <input
                      id="first_name"
                      name="first_name"
                      type="text"
                      className="custom-input"
                      placeholder="First Name"
                      required
                    />
                    {firstNameError && (
                      <p className="error-text">{firstNameError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="last_name" className="label">
                      Last Name *
                    </label>
                    <input
                      id="last_name"
                      name="last_name"
                      type="text"
                      className="custom-input"
                      placeholder="Last Name"
                      required
                    />
                    {lastNameError && (
                      <p className="error-text">{lastNameError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="email" className="label">
                      Email *
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className="custom-input"
                      placeholder="Email"
                      required
                    />
                    {emailError && <p className="error-text">{emailError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="telephone_number" className="label">
                      Telephone *
                    </label>
                    {/* <input
                      type="text"
                      id="telephone_number"
                      name="telephone_number"
                      className="custom-input"
                      placeholder="Telephone"
                      value={phoneValue}
                      onChange={(e) => {
                        const { value } = e.target;

                        const regex = /^([\s+0-9])+$/i;
                        if (value === "") {
                          // case to remove whole highlighted text
                          setPhoneValue(value);
                          return;
                        }
                        if (regex.test(value) === false) return;

                        if (value.length === 1 && value === "+") {
                          // case to remove + sign when last number is removed
                          setPhoneValue("");
                        } else if (value.length >= 1 && value[0] !== "+") {
                          // case to append + sign with first number
                          setPhoneValue("+" + value);
                        } else {
                          // case to append other numbers
                          setPhoneValue(value);
                        }
                      }}
                      required
                    /> */}
                    <PhoneInput
                      country={"us"}
                      value={phoneValue}
                      onChange={setPhoneValue}
                      inputProps={{
                        required: true,
                      }}
                    />
                    {phoneError && <p className="error-text">{phoneError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <label htmlFor="role" className="label">
                    Role *
                  </label>
                  <select
                    name="role"
                    id="role"
                    className="custom-select-modal"
                    onChange={setRoleHandler}
                  >
                    {checkedeBox ? (
                      <option value="Community Admin">Community Admin</option>
                    ) : (
                      <option value="Individual Practice Admin">
                        Individual Practice Admin
                      </option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            id="modal-close-btn"
            className="btn btn-secondary-fill btn-primary-16"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            // type="submit"
            onClick={addCommunitySubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={disabledBtn}
          >
            Add
          </button>
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

export default AddCommunityModal;
