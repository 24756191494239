import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// import images
import Filter_Icon from "../../../assets/filter-icon.svg";

import AddCorporateModal from "../../Corporate/Modals/AddCorporateModal/AddCorporateModal";
import AddUserModal from "../../Corporate/Modals/AddUserModal/AddUserModal";
import ImportUserModal from "../../Users/ImportUserModal";
import ImportCorporateModal from "../../Corporate/Modals/ImportCorporateModal/ImportCorporateModal";
import PatientFiltersModal from "../../Corporate/Modals/FiltersModal/PatientFilter";
import ScheduleFiltersModal from "../../Corporate/Modals/FiltersModal/ScheduleTestFilter";
import UpcomingFiltersModal from "../../Corporate/Modals/FiltersModal/UpcomingTestFilter";
import Loader from "../loader/loader";
import AddPatient from "../../Patient/Modals/AddPatient";
import AddProvider from "../../Provider/Modals/AddProvider";
import { useParams, useHistory, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getWithExpiry } from "../../utils/helper";
import TableBody from "./TableBody";
import "./Table.scss";

import {
  adminUserModalFields,
  regularUserModalFields,
} from "../../Corporate/Modals/ImportCorporateModal/utils";
import { providerActions } from "../../../store/provider";
import Pagination from "../Pagination/Pagination";
import { filterActions } from "../../../store/filter";
import { rowOrderActions } from "../../../store/orders";
import { patientActions } from "../../../store/patient";
import { testActions } from "../../../store/test";
import { RegUserActions } from "../../../store/regUser";
import { AdminUserActions } from "../../../store/adminUser";
import AuditLogsModal from "../../../components/General/AuditLogModal/AuditLogsModal.Component";
import AuditLogButton from "../../General/AuditLogModal/AuditLogButton.Component";
import useQueryString from "../../../hooks/useQueryString";
import {
  removeEmptyParams,
  setOrderAndIcon,
} from "../../../services/helpers/sorting.hepler";
import SearchInput from "../../General/filters/SearchInput.Component";
import FilterButton from "../../General/filters/FilterButton.Component";
import FilterClose from "../../../assets/filter-close.svg";
import { externalProvidersActions } from "../../../store/externalProviders";
import { prepaidPatientsActions } from "../../../store/prepaidPatients";
const TableData = (props) => {
  const {
    tableHeadings,
    data,
    type,
    subType,
    heading,
    isImport,
    isAdd,
    regUserRoles,
    totalRecords,
    setDataFlag,
    setDataFlag1,
    setDataFlag2,
    setShowToast,
    TabKey,
    otherLoader,
    setOtherLoader,
  } = props;

  const urlParams = useQueryString();
  const history = useHistory();
  const [addCorpModalOpen, setAddCorpModalOpen] = useState(false);
  const [importCorpModalOpen, setImportCorpModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const showModal = () => setAddCorpModalOpen(true);
  const hideModal = () => setAddCorpModalOpen(false);
  const showImportCorpModal = () => setImportCorpModalOpen(true);
  const hideImportCorpModal = () => setImportCorpModalOpen(false);
  const showFilterModal = () => setFilterModalOpen(true);
  const hideFilterModal = () => setFilterModalOpen(false);

  const [addPatientModalOpen, setAddPatientModalOpen] = useState(false);
  const showAddPatientModalOpen = () => setAddPatientModalOpen(true);
  const hideAddPatientModalOpen = () => setAddPatientModalOpen(false);

  const [addProviderModalOpen, setAddProviderModalOpen] = useState(false);
  const showAddProviderModalOpen = () => setAddProviderModalOpen(true);
  const hideAddProviderModalOpen = () => setAddProviderModalOpen(false);

  const [addRegUserModalOpen, setRegUserModalOpen] = useState(false);
  const showRegUserModalOpen = () => setRegUserModalOpen(true);
  const hideRegUserModalOpen = () => setRegUserModalOpen(false);

  const [addAdminUserModalOpen, setAddAdminUserModalOpen] = useState(false);
  const showAddAdminUserModalOpen = () => setAddAdminUserModalOpen(true);
  const hideAddAdminUserModalOpen = () => setAddAdminUserModalOpen(false);

  const [auditLogModalOpen, setAuditLogModalOpen] = useState(false);
  const showAuditLogModal = () => setAuditLogModalOpen(true);
  const hideAuditLogModal = () => setAuditLogModalOpen(false);

  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);
  const params = useParams();
  const [dateRange, setDateRange] = useState([null, null]);
  const [testDateRange, setTestDateRange] = useState([null, null]);
  const dispatch = useDispatch();

  const [headingName, setHeadingName] = useState("");
  const [search, setSearch] = useState(false);
  const handleSearchChange = (e) => {
    const { value, name } = e.target;
    if (type === "Patient" || type === "Provider") {
      dispatch(filterActions.setFilterText(value));
    } else if (type === "Test" && subType === "schedule") {
      dispatch(filterActions.setSearchScheduleText(value));
    } else if (type === "Test" && subType === "upcoming") {
      dispatch(filterActions.setSearchUpcomingText(value));
    } else if (type === "AdminUser") {
      dispatch(filterActions.setSearchAdminUserText(value));
    } else if (type === "RegUser") {
      dispatch(filterActions.setSearchRegUserText(value));
    }else if (type === "External Providers") {
      dispatch(filterActions.setSearchExternalProviderText(value));
    }else if (type === "Prepaid Patients") {
      dispatch(filterActions.setSearchPrepaidPatientsText(value));
    }

    //setSearchVal(value);
  };
  const regUserFormData = {
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    role: "",
    corporate: "",
    community: "",
  };
  const adminUserFormData = {
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    permissions: "",
  };
  const patientFormData = {
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    telephone: "",
    community: "",
    status: "",
  };

  const providerFormData = {
    title: "",
    suffix: "",
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    billeeCompany: "",
    billeeFirstName: "",
    billeeLastName: "",
    billeeEmail: "",
    billeeTelephone: "",
    billeeAddress_01: "",
    billeeAddress_02: "",
    billeeCity: "",
    billeeState: "",
    billeeZip: "",
    billeeCountry: "",
    community: "",
    can_bill_community: "",
  };

  function importFormData() {
    if (type === "RegUser") {
      return regUserFormData;
    } else if (type === "AdminUser") {
      return adminUserFormData;
    } else if (type === "Patient") {
      return patientFormData;
    } else if (type === "Provider") {
      return providerFormData;
    }
  }

  const openAddModal = () => {
    switch (type) {
      case "Corporate":
        showModal();
        break;
      case "Patient":
        showAddPatientModalOpen();
        break;
      case "Provider":
        showAddProviderModalOpen();
        break;
      case "RegUser":
        showRegUserModalOpen();
        break;
      case "AdminUser":
        showAddAdminUserModalOpen();
        break;
    }
  };

  const patientOrderMapping = {
    "First Name": "first_name",
    "Last Name": "last_name",
    Community: "community_name",
    "Date of Birth": "dob",
    Email: "email",
    Telephone: "telephone_number",
    Status: "status",
  };
  const scheduleTestOrderMapping = {
    "First Name": "first_name",
    "Last Name": "last_name",
    "Date of Birth": "dob",
    "Last Test Date": "last_test_date",
  };
  const upcomingTestOrderMapping = {
    "Patient Name": "patient_name",
    "Date of Birth": "dob",
    Status: "status",
    "Schedule Date": "schedule_date",
    Location: "location",
    Remote: "remote",
  };
  const providerOrderMapping = {
    "First Name": "first_name",
    "Last Name": "last_name",
    Email: "email",
    Telephone: "telephone_number",
  };
  const regUserOrderMapping = {
    "First Name": "first_name",
    "Last Name": "last_name",
    Email: "email",
    Telephone: "telephone_number",
  };
  const adminUserOrderMapping = {
    "First Name": "first_name",
    "Last Name": "last_name",
    Email: "email",
    Telephone: "telephone_number",
  };
  const externalProviderMapping = {
    "Provider Status Code" : "provider_status_code",
    "First Name": "first_name",
    "Last Name": "last_name",
    Email: "email",
  };
  const prepaidPatientsMapping = {
    "First Name": "first_name",
    "Last Name": "last_name",
    "Provider Status Code" : "provider_status_code",
    Status: "test_status",
  };
  const orderMapping = {
    Patient: patientOrderMapping,
    upcoming: upcomingTestOrderMapping,
    schedule: scheduleTestOrderMapping,
    Provider: providerOrderMapping,
    RegUser: regUserOrderMapping,
    AdminUser: adminUserOrderMapping,
    "External Providers": externalProviderMapping,
    "Prepaid Patients": prepaidPatientsMapping,

  };
  const urlMapping = {
    Patient: "/patients",
    Test: "/tests",
    upcoming: "/tests",
    schedule: "/tests",
    Provider: "/providers",
    RegUser: "/users",
    AdminUser: "/users",
    "External Providers": "/external-providers",
  };

  const searchFilteredText = useSelector((state) => state.filter.filterText);
  const searchUpcomingText = useSelector(
    (state) => state.filter.searchUpcomingText
  );
  const searchScheduleText = useSelector(
    (state) => state.filter.searchScheduleText
  );
  const searchAdminUserText = useSelector(
    (state) => state.filter.searchAdminUserText
  );
  const searchRegUserText = useSelector(
    (state) => state.filter.searchRegUserText
  );
  const searchExternalProviderText = useSelector(
    (state) => state.filter.searchExternalProviderText
  );
  const searchPrepaidPatientsText = useSelector(
    (state) => state.filter.searchPrepaidPatientsText
  );


  const filteredDobFrom = useSelector((state) => state.filter.filteredDobFrom);
  const filteredDobTo = useSelector((state) => state.filter.filteredDobTo);

  const scheduleFilteredDobFrom = useSelector(
    (state) => state.test.scheduleFilteredDobFrom
  );
  const scheduleFilteredDobTo = useSelector(
    (state) => state.test.scheduleFilteredDobTo
  );
  const scheduleFilteredLastDataFrom = useSelector(
    (state) => state.test.scheduleFilteredLastDataFrom
  );
  const scheduleFilteredLastDataTo = useSelector(
    (state) => state.test.scheduleFilteredLastDataTo
  );
  const upcomingFilteredDobFrom = useSelector(
    (state) => state.test.upcomingFilteredDobFrom
  );
  const upcomingFilteredDobTo = useSelector(
    (state) => state.test.upcomingFilteredDobTo
  );
  const upcomingFilteredScheduledDateFrom = useSelector(
    (state) => state.test.upcomingFilteredScheduledDateFrom
  );
  const upcomingFilteredScheduledDateTo = useSelector(
    (state) => state.test.upcomingFilteredScheduledDateTo
  );

  const rowOrderStatePatient = useSelector(
    (state) => state.rowOrder.rowOrderPatient
  );
  const rowOrderStateProvider = useSelector(
    (state) => state.rowOrder.rowOrderProvider
  );
  const rowOrderStateScheduleTest = useSelector(
    (state) => state.rowOrder.rowOrderScheduleTest
  );
  const rowOrderStateUpcomingTest = useSelector(
    (state) => state.rowOrder.rowOrderUpcomingTest
  );
  const rowOrderStateRegUser = useSelector(
    (state) => state.rowOrder.rowOrderRegUser
  );
  const rowOrderStateAdminUser = useSelector(
    (state) => state.rowOrder.rowOrderAdminUser
  );
  const rowOrderStateExternalProviders = useSelector(
    (state) => state.rowOrder.rowOrderExternalProviders
  );
  const rowOrderStatePrepaidPatients = useSelector(
    (state) => state.rowOrder.rowOrderPrepaidPatients
  );
  const rowOrderMapping = {
    Patient: rowOrderStatePatient,
    upcoming: rowOrderStateUpcomingTest,
    schedule: rowOrderStateScheduleTest,
    Provider: rowOrderStateProvider,
    RegUser: rowOrderStateRegUser,
    AdminUser: rowOrderStateAdminUser,
    "External Providers": rowOrderStateExternalProviders,
    "Prepaid Patients": rowOrderStatePrepaidPatients,
  };
  const orderQuery = useSelector((state) => state.rowOrder.orderQuery);
  const upcomingOrderQuery = useSelector(
    (state) => state.test.upcomingOrderQuery
  );
  const scheduleOrderQuery = useSelector(
    (state) => state.test.scheduleOrderQuery
  );

  const regUserOrderQuery = useSelector(
    (state) => state.reguser.regUserOrderQuery
  );
  const adminUserOrderQuery = useSelector(
    (state) => state.adminuser.adminUserOrderQuery
  );
  const [orderState, setOrderState] = useState(null);
  const [prefix, setPrefix] = useState("");

  useEffect(() => {
    updateUrl();
  }, [orderState]);

  const buildRowOrderQuery = () => {
    let newOrderQuery = "";
    if (subType && subType === "schedule") {
      newOrderQuery = scheduleOrderQuery.slice();
    } else if (subType && subType === "upcoming") {
      newOrderQuery = upcomingOrderQuery.slice();
    } else if (type === "RegUser") {
      newOrderQuery = regUserOrderQuery.slice();
    } else if (type === "AdminUser") {
      newOrderQuery = adminUserOrderQuery.slice();
    } else {
      newOrderQuery = orderQuery.slice();
    }
    let mappingHeaderName, rowOrderHeaderName;
    if (type === "Patient") {
      mappingHeaderName = patientOrderMapping[headingName];
      rowOrderHeaderName =
        rowOrderStatePatient[patientOrderMapping[headingName]];
    } else if (type === "Test") {
      if (subType === "schedule") {
        mappingHeaderName = scheduleTestOrderMapping[headingName];
        rowOrderHeaderName =
          rowOrderStateScheduleTest[scheduleTestOrderMapping[headingName]];
      } else if (subType === "upcoming") {
        mappingHeaderName = upcomingTestOrderMapping[headingName];
        rowOrderHeaderName =
          rowOrderStateUpcomingTest[upcomingTestOrderMapping[headingName]];
      }
    } else if (type === "Provider") {
      mappingHeaderName = providerOrderMapping[headingName];
      rowOrderHeaderName =
        rowOrderStateProvider[providerOrderMapping[headingName]];
    } else if (type === "RegUser") {
      mappingHeaderName = regUserOrderMapping[headingName];
      rowOrderHeaderName =
        rowOrderStateRegUser[regUserOrderMapping[headingName]];
    } else if (type === "AdminUser") {
      mappingHeaderName = adminUserOrderMapping[headingName];
      rowOrderHeaderName =
        rowOrderStateAdminUser[adminUserOrderMapping[headingName]];
    } else if (type === "External Providers") {
      mappingHeaderName = externalProviderMapping[headingName];
      rowOrderHeaderName =
        rowOrderStateExternalProviders[externalProviderMapping[headingName]];
    } else if (type === "Prepaid Patients") {
      mappingHeaderName = prepaidPatientsMapping[headingName];
      rowOrderHeaderName =
        rowOrderStatePrepaidPatients[prepaidPatientsMapping[headingName]];
    }

    if (mappingHeaderName) {
      let prevOrder = rowOrderHeaderName.order === "asc" ? "desc" : "asc";
      let prevInstance = `&${mappingHeaderName}:orderby=${prevOrder}`;
      if (newOrderQuery.includes(prevInstance)) {
        newOrderQuery = newOrderQuery.replace(prevInstance, "");
      }
      if (!search) {
        newOrderQuery = `&${mappingHeaderName}:orderby=${rowOrderHeaderName.order}`;
      }
    }
    if (subType && subType === "schedule") {
      dispatch(testActions.setScheduleOrderQuery(newOrderQuery));
    } else if (subType && subType === "upcoming") {
      dispatch(testActions.setUpcomingOrderQuery(newOrderQuery));
    } else if (type === "RegUser") {
      dispatch(RegUserActions.setRegUserOrderQuery(newOrderQuery));
    } else if (type === "AdminUser") {
      dispatch(AdminUserActions.setAdminUserOrderQuery(newOrderQuery));
    } else {
      dispatch(rowOrderActions.setOrderQuery(newOrderQuery));
    }

    return newOrderQuery;
  };

  const buildFilterQuery = () => {
    let filterQuery = "";

    if (type === "Patient") {
      filterQuery =
        `dob_from:eq=${filteredDobFrom}` + `&dob_to:eq=${filteredDobTo}`;
    } else if (type === "Test") {
      if (subType === "schedule") {
        filterQuery =
          `dob_from:eq=${scheduleFilteredDobFrom}` +
          `&dob_to:eq=${scheduleFilteredDobTo}` +
          `&last_test_date_from:eq=${scheduleFilteredLastDataFrom}` +
          `&last_test_date_to:eq=${scheduleFilteredLastDataTo}`;
      } else if (subType === "upcoming") {
        filterQuery =
          `dob_from:eq=${upcomingFilteredDobFrom}` +
          `&dob_to:eq=${upcomingFilteredDobTo}` +
          `&schedule_date_from:eq=${upcomingFilteredScheduledDateFrom}` +
          `&schedule_date_to:eq=${upcomingFilteredScheduledDateTo}`;
      }
    }
    return filterQuery;
  };

  const buildSearchTextQuery = () => {
    let searchQuery = "";
    if (type === "Patient" || type === "Provider") {
      searchQuery =
        searchFilteredText !== "" ? `search_query=${searchFilteredText}` : "";
    } else if (type === "Test" && subType === "schedule") {
      searchQuery =
        searchScheduleText !== "" ? `search_query=${searchScheduleText}` : "";
    } else if (type === "Test" && subType === "upcoming") {
      searchQuery =
        searchUpcomingText !== "" ? `search_query=${searchUpcomingText}` : "";
    } else if (type === "RegUser") {
      searchQuery =
        searchRegUserText !== "" ? `search_query=${searchRegUserText}` : "";
    } else if (type === "AdminUser") {
      searchQuery =
        searchAdminUserText !== "" ? `search_query=${searchAdminUserText}` : "";
    } else if (type === "External Providers") {
      searchQuery =
        searchExternalProviderText !== "" ? `search_query=${searchExternalProviderText}` : "";
    }else if (type === "Prepaid Patients") {
      searchQuery =
        searchPrepaidPatientsText !== "" ? `search_query=${searchPrepaidPatientsText}` : "";
    }
    

    return searchQuery;
  };
  const getPrefix = () => {
    let newPrefix = prefix;

    return newPrefix;
  };

  const updateUrl = () => {
    const searchQuery = buildSearchTextQuery();
    const newOrderQuery = buildRowOrderQuery();
    const filterQuery = buildFilterQuery();

    let customQuery = "";

    customQuery = `${searchQuery}${newOrderQuery}&${filterQuery}`;

    let modifiedString = removeEmptyParams(customQuery);

    let urlParamString = urlParams.toString();

    const newPrefix = getPrefix();
    let searchParam = "";
    if (subType !== "Patient History") {
      if (!modifiedString) {
        searchParam =
          "?" +
          `${
            search === true
              ? newPrefix + modifiedString
              : newPrefix + urlParamString
          }`;
      } else {
        if (
          !modifiedString.includes("page") &&
          urlParams.toString().includes("page")
        ) {
          searchParam =
            "?" +
            newPrefix +
            modifiedString +
            `&page=${search ? 1 : urlParams.get("page")}`;
        } else {
          searchParam = "?" + newPrefix + modifiedString;
        }
      }

      searchParam = searchParam.replace("&&", "&");
      history.replace({
        pathname: !subType ? urlMapping[type] : urlMapping[subType],
        search: searchParam,
      });

      let str =
        modifiedString && modifiedString !== "" ? "?" + modifiedString : "";

      if (type === "Patient") {
        dispatch(patientActions.setPatientApiUrl(str));
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      } else if (subType && subType === "schedule") {
        dispatch(testActions.setScheduleTestApiUrl(str));
        setShowToast(true);
        setDataFlag1((prevState) => !prevState);
      } else if (subType && subType === "upcoming") {
        dispatch(testActions.setUpcomingTestApiUrl(str));
        setShowToast(true);
        setDataFlag2((prevState) => !prevState);
      } else if (type === "Provider") {
        dispatch(providerActions.setProviderApiUrl(str));
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      } else if (type === "RegUser") {
        dispatch(RegUserActions.setRegUserApiUrl(str));
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      } else if (type === "AdminUser") {
        dispatch(AdminUserActions.setAdminUserApiUrl(str));
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      } else if (type === "External Providers") {
        dispatch(externalProvidersActions.setExternalProvidersApiUrl(str));
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      } else if (type === "Prepaid Patients") {
        dispatch(prepaidPatientsActions.setPrepaidPatientsApiUrl(str));
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      }
    }
  };

  const onClickTableHeading = async (e) => {
    // if(type === "External Providers" || type === "Prepaid Patients"){
    //   return;
    // }

    const { id } = e.target;
    if (id !== "Communities") {
      setOtherLoader(true);
      setSearch(false);
      setHeadingName(id);
      let urlParamString = urlParams.toString();
      if (type === "Test") {
        if (subType && subType === "schedule") {
          setPrefix("table=schedule_test&");
        } else if (subType && subType === "upcoming") {
          setPrefix("table=upcoming_test&");
        }
      } else if (type === "RegUser") {
        setPrefix("tab_key=regular_user&");
      } else if (type === "AdminUser") {
        setPrefix("tab_key=admin_user&");
      }
      let name = "";
      let obj;

      if (type === "Patient") {
        switch (id) {
          case "First Name":
            name = "first_name";

            obj = setOrderAndIcon(rowOrderStatePatient[name]);
            dispatch(
              rowOrderActions.setPatientOrder({
                ...rowOrderStatePatient,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                dob: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: false },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });

            return;
          case "Last Name":
            name = "last_name";

            obj = setOrderAndIcon(rowOrderStatePatient[name]);
            dispatch(
              rowOrderActions.setPatientOrder({
                ...rowOrderStatePatient,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                first_name: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                dob: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: false },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });

            return;
          case "Community":
            name = "community_name";

            obj = setOrderAndIcon(rowOrderStatePatient[name]);
            dispatch(
              rowOrderActions.setPatientOrder({
                ...rowOrderStatePatient,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                dob: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: false },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });

            return;
          case "Date of Birth":
            name = "dob";

            obj = setOrderAndIcon(rowOrderStatePatient[name]);
            dispatch(
              rowOrderActions.setPatientOrder({
                ...rowOrderStatePatient,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: false },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });

            return;
          case "Email":
            name = "email";

            obj = setOrderAndIcon(rowOrderStatePatient[name]);
            dispatch(
              rowOrderActions.setPatientOrder({
                ...rowOrderStatePatient,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                dob: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: false },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });

            return;
          case "Telephone":
            name = "telephone_number";

            obj = setOrderAndIcon(rowOrderStatePatient[name]);
            dispatch(
              rowOrderActions.setPatientOrder({
                ...rowOrderStatePatient,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                dob: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: false },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });

            return;

          case "Status":
            name = "status";

            obj = setOrderAndIcon(rowOrderStatePatient[name]);
            dispatch(
              rowOrderActions.setPatientOrder({
                ...rowOrderStatePatient,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                first_name: { state: 1, order: "", icon: "" },
                last_name: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                dob: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });

            return;

          default:
            return;
        }
      } else if (type === "Test") {
        if (subType === "schedule") {
          switch (id) {
            case "First Name":
              name = "first_name";

              obj = setOrderAndIcon(rowOrderStateScheduleTest[name]);
              dispatch(
                rowOrderActions.setScheduledTestOrder({
                  ...rowOrderStateScheduleTest,
                  [name]: {
                    state: obj.state,
                    order: obj.order,
                    icon: obj.icon,
                  },
                  last_name: { state: 1, order: "", icon: "" },
                  dob: { state: 1, order: "", icon: "" },
                  last_test_date: { state: 1, order: "", icon: "" },
                })
              );
              setOrderState({
                ...orderState,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              });
              return;

            case "Last Name":
              name = "last_name";

              obj = setOrderAndIcon(rowOrderStateScheduleTest[name]);
              dispatch(
                rowOrderActions.setScheduledTestOrder({
                  ...rowOrderStateScheduleTest,
                  [name]: {
                    state: obj.state,
                    order: obj.order,
                    icon: obj.icon,
                  },
                  first_name: { state: 1, order: "", icon: "" },
                  dob: { state: 1, order: "", icon: "" },
                  last_test_date: { state: 1, order: "", icon: "" },
                })
              );
              setOrderState({
                ...orderState,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              });

              return;
            case "Date of Birth":
              name = "dob";

              obj = setOrderAndIcon(rowOrderStateScheduleTest[name]);
              dispatch(
                rowOrderActions.setScheduledTestOrder({
                  ...rowOrderStateScheduleTest,
                  [name]: {
                    state: obj.state,
                    order: obj.order,
                    icon: obj.icon,
                  },
                  last_name: { state: 1, order: "", icon: "" },
                  first_name: { state: 1, order: "", icon: "" },
                  last_test_date: { state: 1, order: "", icon: "" },
                })
              );
              setOrderState({
                ...orderState,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              });
              return;
            case "Last Test Date":
              name = "last_test_date";

              obj = setOrderAndIcon(rowOrderStateScheduleTest[name]);
              dispatch(
                rowOrderActions.setScheduledTestOrder({
                  ...rowOrderStateScheduleTest,
                  [name]: {
                    state: obj.state,
                    order: obj.order,
                    icon: obj.icon,
                  },
                  last_name: { state: 1, order: "", icon: "" },
                  dob: { state: 1, order: "", icon: "" },
                  first_name: { state: 1, order: "", icon: "" },
                })
              );
              setOrderState({
                ...orderState,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              });
              return;
            default:
              return;
          }
        } else if (subType === "upcoming") {
          switch (id) {
            case "Patient Name":
              name = "patient_name";
              obj = setOrderAndIcon(rowOrderStateUpcomingTest[name]);
              dispatch(
                rowOrderActions.setUpcomingTestOrder({
                  ...rowOrderStateUpcomingTest,
                  [name]: {
                    state: obj.state,
                    order: obj.order,
                    icon: obj.icon,
                  },
                  dob: { state: 1, order: "", icon: "" },
                  status: { state: 1, order: "", icon: "" },
                  schedule_date: { state: 1, order: "", icon: "" },
                  location: { state: 1, order: "", icon: "" },
                  remote: { state: 1, order: "", icon: "" },
                })
              );
              setOrderState({
                ...orderState,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              });
              return;
            case "Date of Birth":
              name = "dob";
              obj = setOrderAndIcon(rowOrderStateUpcomingTest[name]);
              dispatch(
                rowOrderActions.setUpcomingTestOrder({
                  ...rowOrderStateUpcomingTest,
                  [name]: {
                    state: obj.state,
                    order: obj.order,
                    icon: obj.icon,
                  },
                  patient_name: { state: 1, order: "", icon: "" },
                  status: { state: 1, order: "", icon: "" },
                  schedule_date: { state: 1, order: "", icon: "" },
                  location: { state: 1, order: "", icon: "" },
                  remote: { state: 1, order: "", icon: "" },
                })
              );
              setOrderState({
                ...orderState,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              });
              return;
            case "Status":
              name = "status";
              obj = setOrderAndIcon(rowOrderStateUpcomingTest[name]);
              dispatch(
                rowOrderActions.setUpcomingTestOrder({
                  ...rowOrderStateUpcomingTest,
                  [name]: {
                    state: obj.state,
                    order: obj.order,
                    icon: obj.icon,
                  },
                  dob: { state: 1, order: "", icon: "" },
                  patient_name: { state: 1, order: "", icon: "" },
                  schedule_date: { state: 1, order: "", icon: "" },
                  location: { state: 1, order: "", icon: "" },
                  remote: { state: 1, order: "", icon: "" },
                })
              );
              setOrderState({
                ...orderState,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              });
              return;
            case "Schedule Date":
              name = "schedule_date";
              obj = setOrderAndIcon(rowOrderStateUpcomingTest[name]);
              dispatch(
                rowOrderActions.setUpcomingTestOrder({
                  ...rowOrderStateUpcomingTest,
                  [name]: {
                    state: obj.state,
                    order: obj.order,
                    icon: obj.icon,
                  },
                  dob: { state: 1, order: "", icon: "" },
                  status: { state: 1, order: "", icon: "" },
                  patient_name: { state: 1, order: "", icon: "" },
                  location: { state: 1, order: "", icon: "" },
                  remote: { state: 1, order: "", icon: "" },
                })
              );
              setOrderState({
                ...orderState,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              });
              return;
            case "Location":
              name = "location";
              obj = setOrderAndIcon(rowOrderStateUpcomingTest[name]);
              dispatch(
                rowOrderActions.setUpcomingTestOrder({
                  ...rowOrderStateUpcomingTest,
                  [name]: {
                    state: obj.state,
                    order: obj.order,
                    icon: obj.icon,
                  },
                  dob: { state: 1, order: "", icon: "" },
                  status: { state: 1, order: "", icon: "" },
                  schedule_date: { state: 1, order: "", icon: "" },
                  patient_name: { state: 1, order: "", icon: "" },
                  remote: { state: 1, order: "", icon: "" },
                })
              );
              setOrderState({
                ...orderState,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              });
              return;
            case "Remote":
              name = "remote";
              obj = setOrderAndIcon(rowOrderStateUpcomingTest[name]);
              dispatch(
                rowOrderActions.setUpcomingTestOrder({
                  ...rowOrderStateUpcomingTest,
                  [name]: {
                    state: obj.state,
                    order: obj.order,
                    icon: obj.icon,
                  },
                  dob: { state: 1, order: "", icon: "" },
                  status: { state: 1, order: "", icon: "" },
                  schedule_date: { state: 1, order: "", icon: "" },
                  location: { state: 1, order: "", icon: "" },
                  patient_name: { state: 1, order: "", icon: "" },
                })
              );
              setOrderState({
                ...orderState,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
              });
              return;
            default:
              return;
          }
        }
      } else if (type === "Provider") {
        switch (id) {
          case "First Name":
            name = "first_name";
            obj = setOrderAndIcon(rowOrderStateProvider[name]);
            dispatch(
              rowOrderActions.setProviderOrder({
                ...rowOrderStateProvider,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Last Name":
            name = "last_name";
            obj = setOrderAndIcon(rowOrderStateProvider[name]);
            dispatch(
              rowOrderActions.setProviderOrder({
                ...rowOrderStateProvider,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                first_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Email":
            name = "email";
            obj = setOrderAndIcon(rowOrderStateProvider[name]);
            dispatch(
              rowOrderActions.setProviderOrder({
                ...rowOrderStateProvider,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Telephone":
            name = "telephone_number";
            obj = setOrderAndIcon(rowOrderStateProvider[name]);
            dispatch(
              rowOrderActions.setProviderOrder({
                ...rowOrderStateProvider,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          default:
            return;
        }
      } else if (type === "RegUser") {
        switch (id) {
          case "First Name":
            name = "first_name";
            obj = setOrderAndIcon(rowOrderStateRegUser[name]);
            dispatch(
              rowOrderActions.setRegUserOrder({
                ...rowOrderStateRegUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Last Name":
            name = "last_name";
            obj = setOrderAndIcon(rowOrderStateRegUser[name]);
            dispatch(
              rowOrderActions.setRegUserOrder({
                ...rowOrderStateRegUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                first_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Email":
            name = "email";
            obj = setOrderAndIcon(rowOrderStateRegUser[name]);
            dispatch(
              rowOrderActions.setRegUserOrder({
                ...rowOrderStateRegUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Telephone":
            name = "telephone_number";
            obj = setOrderAndIcon(rowOrderStateRegUser[name]);
            dispatch(
              rowOrderActions.setRegUserOrder({
                ...rowOrderStateRegUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          default:
            return;
        }
      } else if (type === "AdminUser") {
        switch (id) {
          case "First Name":
            name = "first_name";
            obj = setOrderAndIcon(rowOrderStateAdminUser[name]);
            dispatch(
              rowOrderActions.setAdminUserOrder({
                ...rowOrderStateAdminUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Last Name":
            name = "last_name";
            obj = setOrderAndIcon(rowOrderStateAdminUser[name]);
            dispatch(
              rowOrderActions.setAdminUserOrder({
                ...rowOrderStateAdminUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                first_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Email":
            name = "email";
            obj = setOrderAndIcon(rowOrderStateAdminUser[name]);
            dispatch(
              rowOrderActions.setAdminUserOrder({
                ...rowOrderStateAdminUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Telephone":
            name = "telephone_number";
            obj = setOrderAndIcon(rowOrderStateAdminUser[name]);
            dispatch(
              rowOrderActions.setAdminUserOrder({
                ...rowOrderStateAdminUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          default:
            return;
        }
      } else if (type === "External Providers") {
        switch (id) {
          case "First Name":
            name = "first_name";

            obj = setOrderAndIcon(rowOrderStateExternalProviders[name]);
            dispatch(
              rowOrderActions.setExternalProviderOrder({
                ...rowOrderStateExternalProviders,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                provider_status_code: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });

            return;
          case "Last Name":
            name = "last_name";

            obj = setOrderAndIcon(rowOrderStateExternalProviders[name]);
            dispatch(
              rowOrderActions.setExternalProviderOrder({
                ...rowOrderStateExternalProviders,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                first_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                provider_status_code: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });

            return;
      
          case "Email":
            name = "email";

            obj = setOrderAndIcon(rowOrderStateExternalProviders[name]);
            dispatch(
              rowOrderActions.setExternalProviderOrder({
                ...rowOrderStateExternalProviders,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                provider_status_code: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });

            return;

          case "Provider Status Code":
            name = "provider_status_code";

            obj = setOrderAndIcon(rowOrderStateExternalProviders[name]);
            dispatch(
              rowOrderActions.setExternalProviderOrder({
                ...rowOrderStateExternalProviders,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                first_name: { state: 1, order: "", icon: "" },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });

            return;

          default:
            return;
        }
      } else if (type === "Prepaid Patients") {
        switch (id) {
          case "First Name":
            name = "first_name";

            obj = setOrderAndIcon(rowOrderStatePrepaidPatients[name]);
            dispatch(
              rowOrderActions.setPrepaidPatientsOrder({
                ...rowOrderStatePrepaidPatients,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                provider_status_code: { state: 1, order: "", icon: "" },
                test_status: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });

            return;
          case "Last Name":
            name = "last_name";

            obj = setOrderAndIcon(rowOrderStatePrepaidPatients[name]);
            dispatch(
              rowOrderActions.setPrepaidPatientsOrder({
                ...rowOrderStatePrepaidPatients,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                first_name: { state: 1, order: "", icon: "" },
                provider_status_code: { state: 1, order: "", icon: "" },
                test_status: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });

            return;
      
          case "Status":
            name = "test_status";

            obj = setOrderAndIcon(rowOrderStatePrepaidPatients[name]);
            dispatch(
              rowOrderActions.setPrepaidPatientsOrder({
                ...rowOrderStatePrepaidPatients,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                provider_status_code: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });

            return;

          case "Provider Status Code":
            name = "provider_status_code";

            obj = setOrderAndIcon(rowOrderStatePrepaidPatients[name]);
            dispatch(
              rowOrderActions.setPrepaidPatientsOrder({
                ...rowOrderStatePrepaidPatients,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                first_name: { state: 1, order: "", icon: "" },
                last_name: { state: 1, order: "", icon: "" },
                test_status: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });

            return;

          default:
            return;
        }
      } 
    }
    return;
  };

  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      setSearch(true);
      setOtherLoader(true);
      if (type === "Test") {
        if (subType && subType === "schedule") {
          setPrefix("table=schedule_test&");
        } else if (subType && subType === "upcoming") {
          setPrefix("table=upcoming_test&");
        }
      } else if (type === "RegUser") {
        setPrefix("tab_key=regular_user&");
      } else if (type === "AdminUser") {
        setPrefix("tab_key=admin_user&");
      }
      setOrderState({
        ...orderState,
      });
    }
  };
  const onClearSorting = () => {
    setOtherLoader(true);
    if (type === "Patient") {
      dispatch(
        rowOrderActions.setPatientOrder({
          ...rowOrderStatePatient,
          first_name: { state: 1, order: "", icon: "" },
          last_name: { state: 1, order: "", icon: "" },
          community_name: { state: 1, order: "", icon: "" },
          dob: { state: 1, order: "", icon: "" },
          email: { state: 1, order: "", icon: "" },
          telephone_number: { state: 1, order: "", icon: "" },
        })
      );
      dispatch(rowOrderActions.setOrderQuery(""));
      setHeadingName("");
      history.replace({
        pathname: "/patients",
        search: "?page=1",
      });
      dispatch(patientActions.setPatientApiUrl(""));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (subType && subType === "schedule") {
      dispatch(
        rowOrderActions.setScheduledTestOrder({
          ...rowOrderStateScheduleTest,
          first_name: { state: 1, order: "", icon: "" },
          last_name: { state: 1, order: "", icon: "" },
          dob: { state: 1, order: "", icon: "" },
          last_test_date: { state: 1, order: "", icon: "" },
        })
      );
      dispatch(testActions.setScheduleOrderQuery(""));
      history.replace({
        pathname: "/tests",
        search: "?page=1",
      });
      dispatch(testActions.setScheduleTestApiUrl(""));
      setShowToast(true);
      setDataFlag1((prevState) => !prevState);
    } else if (subType && subType === "upcoming") {
      dispatch(
        rowOrderActions.setUpcomingTestOrder({
          ...rowOrderStateUpcomingTest,
          patient_name: { state: 1, order: "", icon: "" },
          dob: { state: 1, order: "", icon: "" },
          status: { state: 1, order: "", icon: "" },
          schedule_date: { state: 1, order: "", icon: "" },
          location: { state: 1, order: "", icon: "" },
          remote: { state: 1, order: "", icon: "" },
        })
      );
      dispatch(testActions.setUpcomingOrderQuery(""));
      history.replace({
        pathname: "/tests",
        search: "?page=1",
      });
      dispatch(testActions.setUpcomingTestApiUrl(""));
      setShowToast(true);
      setDataFlag2((prevState) => !prevState);
    } else if (type === "Provider") {
      dispatch(
        rowOrderActions.setProviderOrder({
          ...rowOrderStateProvider,
          first_name: { state: 1, order: "", icon: "" },
          last_name: { state: 1, order: "", icon: "" },
          email: { state: 1, order: "", icon: "" },
          telephone_number: { state: 1, order: "", icon: "" },
        })
      );
      dispatch(rowOrderActions.setOrderQuery(""));
      history.replace({
        pathname: "/providers",
        search: "?page=1",
      });
      dispatch(providerActions.setProviderApiUrl(""));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (type === "RegUser") {
      dispatch(
        rowOrderActions.setRegUserOrder({
          ...rowOrderStateRegUser,
          first_name: { state: 1, order: "", icon: "" },
          last_name: { state: 1, order: "", icon: "" },
          email: { state: 1, order: "", icon: "" },
          telephone_number: { state: 1, order: "", icon: "" },
        })
      );
      dispatch(RegUserActions.setRegUserOrderQuery(""));
      history.replace({
        pathname: "/users",
        search: "?page=1",
      });
      dispatch(RegUserActions.setRegUserApiUrl(""));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (type === "AdminUser") {
      dispatch(
        rowOrderActions.setAdminUserOrder({
          ...rowOrderStateAdminUser,
          first_name: { state: 1, order: "", icon: "" },
          last_name: { state: 1, order: "", icon: "" },
          email: { state: 1, order: "", icon: "" },
          telephone_number: { state: 1, order: "", icon: "" },
        })
      );
      dispatch(AdminUserActions.setAdminUserOrderQuery(""));
      history.replace({
        pathname: "/users",
        search: "?page=1",
      });
      dispatch(AdminUserActions.setAdminUserApiUrl(""));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    }
  };
  const onClearSearch = () => {
    setOtherLoader(true);
    if (type === "Patient" || type === "Provider") {
      dispatch(filterActions.setFilterText(""));
    } else if (type === "Test" && subType === "schedule") {
      dispatch(filterActions.setSearchScheduleText(""));
    } else if (type === "Test" && subType === "upcoming") {
      dispatch(filterActions.setSearchUpcomingText(""));
    } else if (type === "AdminUser") {
      dispatch(filterActions.setSearchAdminUserText(""));
    } else if (type === "RegUser") {
      dispatch(filterActions.setSearchRegUserText(""));
    }else if (type === "External Providers") {
      dispatch(filterActions.setSearchExternalProviderText(""));
    }else if (type === "Prepaid Patients") {
      dispatch(filterActions.setSearchPrepaidPatientsText(""));
    }
    setSearch(true);
    setOrderState({
      ...orderState,
    });
  };

  const removePatientDOBFilter = () => {
    dispatch(filterActions.setFilteredDobFrom([]));
    dispatch(filterActions.setFilteredDobTo([]));
    setDateRange([null, null]);
    history.replace({
      pathname: !subType ? urlMapping[type] : urlMapping[subType],
      search: "",
    });

    setOrderState({
      ...orderState,
    });
  };
  const removeScheduleDOBFilter = () => {
    dispatch(testActions.setScheduleFilteredDobFrom([]));
    dispatch(testActions.setScheduleFilteredDobTo([]));
    setDateRange([null, null]);
    if (
      scheduleFilteredLastDataFrom.length === 0 &&
      scheduleFilteredLastDataTo.length === 0
    ) {
      history.replace({
        pathname: !subType ? urlMapping[type] : urlMapping[subType],
        search: "",
      });
    }
    setOrderState({
      ...orderState,
    });
  };
  const removeScheduleTestFilter = () => {
    dispatch(testActions.setScheduleFilteredLastDataFrom([]));
    dispatch(testActions.setScheduleFilteredLastDataTo([]));
    setTestDateRange([null, null]);
    if (
      scheduleFilteredDobFrom.length === 0 &&
      scheduleFilteredDobTo.length === 0
    ) {
      history.replace({
        pathname: !subType ? urlMapping[type] : urlMapping[subType],
        search: "",
      });
    }
    setOrderState({
      ...orderState,
    });
  };
  const removeUpcomingDOBFilter = () => {
    dispatch(testActions.setUpcomingFilteredDobFrom([]));
    dispatch(testActions.setUpcomingFilteredDobTo([]));
    setDateRange([null, null]);

    if (
      upcomingFilteredScheduledDateFrom.length === 0 &&
      upcomingFilteredScheduledDateTo.length === 0
    ) {
      history.replace({
        pathname: !subType ? urlMapping[type] : urlMapping[subType],
        search: "",
      });
    }

    setOrderState({
      ...orderState,
    });
  };
  const removeUpcomingScheduleFilter = () => {
    dispatch(testActions.setUpcomingFilteredScheduledDateFrom([]));
    dispatch(testActions.setUpcomingFilteredScheduledDateTo([]));
    setTestDateRange([null, null]);

    if (
      upcomingFilteredDobFrom.length === 0 &&
      upcomingFilteredDobTo.length === 0
    ) {
      history.replace({
        pathname: !subType ? urlMapping[type] : urlMapping[subType],
        search: "",
      });
    }
    setOrderState({
      ...orderState,
    });
  };
  const clearFilters = () => {
    var modifiedString = "";
    if (type === "Patient") {
      dispatch(filterActions.setFilteredDobFrom([]));
      dispatch(filterActions.setFilteredDobTo([]));
      setDateRange([null, null]);
      dispatch(patientActions.setPatientApiUrl("?" + modifiedString));
    } else if (type === "Test" && subType === "schedule") {
      dispatch(testActions.setScheduleFilteredDobFrom([]));
      dispatch(testActions.setScheduleFilteredDobTo([]));
      dispatch(testActions.setScheduleFilteredLastDataFrom([]));
      dispatch(testActions.setScheduleFilteredLastDataTo([]));
      setDateRange([null, null]);
      setTestDateRange([null, null]);
      dispatch(testActions.setScheduleTestApiUrl("?" + modifiedString));
    } else if (type === "Test" && subType === "upcoming") {
      dispatch(testActions.setUpcomingFilteredDobFrom([]));
      dispatch(testActions.setUpcomingFilteredDobTo([]));
      dispatch(testActions.setUpcomingFilteredScheduledDateFrom([]));
      dispatch(testActions.setUpcomingFilteredScheduledDateTo([]));
      setDateRange([null, null]);
      setTestDateRange([null, null]);
      dispatch(testActions.setUpcomingTestApiUrl("?" + modifiedString));
    }

    setOrderState({
      ...orderState,
    });
  };
  const paramType = !subType ? type : subType;

  return (
    <Fragment>
      {/* For Audit Log */}
      {auditLogModalOpen && (
        <AuditLogsModal
          isShowModal={auditLogModalOpen}
          hideModal={hideAuditLogModal}
          LogTitle={"Audit Log"}
          type={type}
          dataType={subType === "Patient History" ? subType : ""}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          refId={subType === "Patient History" ? params.patientID : 0}
        />
      )}

      {addRegUserModalOpen && (
        <AddUserModal
          type={type}
          isShowModal={addRegUserModalOpen}
          hideModal={hideRegUserModalOpen}
          regUserRoles={regUserRoles}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}

      {addAdminUserModalOpen && (
        <AddUserModal
          type={type}
          isShowModal={addAdminUserModalOpen}
          hideModal={hideAddAdminUserModalOpen}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      {addCorpModalOpen && (
        <AddUserModal
          type={type}
          isShowModal={addCorpModalOpen}
          hideModal={hideModal}
        />
      )}
      {importCorpModalOpen && (
        <ImportCorporateModal
          isShowModal={importCorpModalOpen}
          hideModal={hideImportCorpModal}
          dataType={type}
          modalFormData={importFormData()}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      {filterModalOpen && type === "Patient" && !subType && (
        <PatientFiltersModal
          isShowModal={filterModalOpen}
          hideModal={hideFilterModal}
          setShowToast={setShowToast}
          setDataFlag={setDataFlag}
          orderBy={orderQuery}
          setOtherLoader={setOtherLoader}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      )}
      {filterModalOpen && type === "Test" && subType === "schedule" && (
        <ScheduleFiltersModal
          isShowModal={filterModalOpen}
          hideModal={hideFilterModal}
          setShowToast={setShowToast}
          setDataFlag={setDataFlag1}
          orderBy={scheduleOrderQuery}
          setOtherLoader={setOtherLoader}
          testDateRange={testDateRange}
          setTestDateRange={setTestDateRange}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      )}
      {filterModalOpen && type === "Test" && subType === "upcoming" && (
        <UpcomingFiltersModal
          isShowModal={filterModalOpen}
          hideModal={hideFilterModal}
          setShowToast={setShowToast}
          setDataFlag={setDataFlag2}
          orderBy={upcomingOrderQuery}
          setOtherLoader={setOtherLoader}
          testDateRange={testDateRange}
          setTestDateRange={setTestDateRange}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      )}
      {addPatientModalOpen && (
        <AddPatient
          isShowModal={addPatientModalOpen}
          hideModal={hideAddPatientModalOpen}
          type={type}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      {addProviderModalOpen && (
        <AddProvider
          isShowModal={addProviderModalOpen}
          hideModal={hideAddProviderModalOpen}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          type={type}
        />
      )}
      {props.type === "Test" && <br />}
      {otherLoader ? (
        <Loader />
      ) : (
        <div className="corpoarte-table">
          <div className="table-header-filter">
            <div className="cor-header-change">
              <h1 className="page-name-heading">{heading}</h1>
              <div className="right-cor-header">
                <ul className="rc-header-listing">
                  {/* <li className="single-rc">
                  <button onClick={onClearSorting}>Clear Sorting</button>
                </li> */}
                  {/* <li className="single-rc">
                  <button onClick={onClearSearch}>Clear Search</button>
                </li> */}
                  <li className="single-rc">
                    {(type === "Patient" || type === "Provider") && (
                      <SearchInput
                        value={searchFilteredText}
                        handleChange={handleSearchChange}
                        onKeyEnter={onEnterPress}
                        onClearSearch={onClearSearch}
                      />
                    )}
                    {type === "AdminUser" && (
                      <SearchInput
                        value={searchAdminUserText}
                        handleChange={handleSearchChange}
                        onKeyEnter={onEnterPress}
                        onClearSearch={onClearSearch}
                      />
                    )}
                    {type === "RegUser" && (
                      <SearchInput
                        value={searchRegUserText}
                        handleChange={handleSearchChange}
                        onKeyEnter={onEnterPress}
                        onClearSearch={onClearSearch}
                      />
                    )}
                    {type === "Test" && subType === "upcoming" && (
                      <SearchInput
                        value={searchUpcomingText}
                        handleChange={handleSearchChange}
                        onKeyEnter={onEnterPress}
                        onClearSearch={onClearSearch}
                      />
                    )}
                    {type === "Test" && subType === "schedule" && (
                      <SearchInput
                        value={searchScheduleText}
                        handleChange={handleSearchChange}
                        onKeyEnter={onEnterPress}
                        onClearSearch={onClearSearch}
                      />
                    )}
                    {type === "External Providers" && (
                        <SearchInput
                          value={searchExternalProviderText}
                          handleChange={handleSearchChange}
                          onKeyEnter={onEnterPress}
                          onClearSearch={onClearSearch}
                        />
                    )}
                    {type === "Prepaid Patients" && (
                        <SearchInput
                          value={searchPrepaidPatientsText}
                          handleChange={handleSearchChange}
                          onKeyEnter={onEnterPress}
                          onClearSearch={onClearSearch}
                        />
                    )}
                  </li>
                  {type !== "Provider" &&
                    type !== "AdminUser" &&
                    type !== "RegUser" && type !== "External Providers" && type !== "Prepaid Patients" && (
                      <li className="single-rc">
                        <FilterButton handleClick={showFilterModal} />
                      </li>
                    )}
                  {type !== "Test" && type !=="External Providers" && type !== "Prepaid Patients" && (
                    <>
                      <li className="single-rc">
                        <AuditLogButton handleClick={showAuditLogModal} />
                      </li>
                    </>
                  )}

                  {type === "Provider" ? (
                    <>
                      {!isCatchUAdmin &&
                      (userRole.includes("Super Admin") ||
                        userRole.includes("Community Admin") ||
                        userRole.includes("Individual Practice Admin") ||
                        userRole.includes("Support Staff")) ? (
                        <>
                          {isImport && (
                            <li className="single-rc">
                              <button
                                className="btn btn-primary-outline"
                                onClick={showImportCorpModal}
                              >
                                Import
                              </button>
                            </li>
                          )}
                          {isAdd && (
                            <li className="single-rc">
                              <button
                                className="btn btn-primary-fill btn-primary-16"
                                onClick={openAddModal}
                              >
                                Add
                              </button>
                            </li>
                          )}
                        </>
                      ) : (
                        <>
                          {isCatchUAdmin &&
                            (userRole.includes("Super Admin") ||
                              permissions.includes("Providers")) && (
                              <>
                                {isImport && (
                                  <li className="single-rc">
                                    <button
                                      className="btn btn-primary-outline"
                                      onClick={showImportCorpModal}
                                    >
                                      Import
                                    </button>
                                  </li>
                                )}
                                {isAdd && (
                                  <li className="single-rc">
                                    <button
                                      className="btn btn-primary-fill btn-primary-16"
                                      onClick={openAddModal}
                                    >
                                      Add
                                    </button>
                                  </li>
                                )}
                              </>
                            )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {type === "Patient" || type === "patient" ? (
                        <>
                          {!isCatchUAdmin &&
                          (userRole.includes("Super Admin") ||
                            userRole.includes("Community Admin") ||
                            userRole.includes("Individual Practice Admin") ||
                            userRole.includes("Support Staff") ||
                            userRole.includes("Provider")) ? (
                            <>
                              {isImport && (
                                <li className="single-rc">
                                  <button
                                    className="btn btn-primary-outline"
                                    onClick={showImportCorpModal}
                                  >
                                    Import
                                  </button>
                                </li>
                              )}
                              {isAdd && (
                                <li className="single-rc">
                                  <button
                                    className="btn btn-primary-fill btn-primary-16"
                                    onClick={openAddModal}
                                  >
                                    Add
                                  </button>
                                </li>
                              )}
                            </>
                          ) : (
                            <>
                              {isCatchUAdmin &&
                                (userRole.includes("Super Admin") ||
                                  userRole.includes("Community Admin") ||
                                  userRole.includes(
                                    "Individual Practice Admin"
                                  ) ||
                                  userRole.includes("Support Staff") ||
                                  userRole.includes("Provider") ||
                                  permissions.includes("Tests")) && (
                                  <>
                                    {isImport && (
                                      <li className="single-rc">
                                        <button
                                          className="btn btn-primary-outline"
                                          onClick={showImportCorpModal}
                                        >
                                          Import
                                        </button>
                                      </li>
                                    )}
                                    {isAdd && (
                                      <li className="single-rc">
                                        <button
                                          className="btn btn-primary-fill btn-primary-16"
                                          onClick={openAddModal}
                                        >
                                          Add
                                        </button>
                                      </li>
                                    )}
                                  </>
                                )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {isImport && (
                            <li className="single-rc">
                              <button
                                className="btn btn-primary-outline"
                                onClick={showImportCorpModal}
                              >
                                Import
                              </button>
                            </li>
                          )}
                          {isAdd && (
                            <li className="single-rc">
                              <button
                                className="btn btn-primary-fill btn-primary-16"
                                onClick={openAddModal}
                              >
                                Add
                              </button>
                            </li>
                          )}
                        </>
                      )}
                    </>
                  )}
                </ul>
              </div>
            </div>
            {type === "Patient" && (
              <>
                {filteredDobFrom.length !== 0 && (
                  <div className="filter-header">
                    <h2 className="apply-filter-name">Applied Filters:</h2>
                    <ul className="apply-filter-list">
                      <li className="apply-filter-single">
                        <h3 className="filter-name-type">
                          Date of Birth: <strong>{filteredDobFrom}</strong>{" "}
                        </h3>
                        <img
                          src={FilterClose}
                          alt=""
                          onClick={removePatientDOBFilter}
                        />
                      </li>
                    </ul>
                    <Link
                      to={!subType ? urlMapping[type] : urlMapping[subType]}
                      className="clear-all-filter"
                      onClick={clearFilters}
                    >
                      Clear all filters
                    </Link>
                  </div>
                )}
              </>
            )}

            {type === "Test" && subType === "schedule" && (
              <>
                {((scheduleFilteredDobFrom.length !== 0 &&
                  scheduleFilteredDobTo.length !== 0) ||
                  (scheduleFilteredLastDataFrom.length !== 0 &&
                    scheduleFilteredLastDataTo.length !== 0)) && (
                  <div className="filter-header">
                    <h2 className="apply-filter-name">Applied Filters:</h2>
                    <ul className="apply-filter-list">
                      {scheduleFilteredDobFrom.length !== 0 &&
                        scheduleFilteredDobTo.length !== 0 && (
                          <li className="apply-filter-single">
                            <h3 className="filter-name-type">
                              Date of Birth:{" "}
                              <strong>
                                {scheduleFilteredDobFrom} -{" "}
                                {scheduleFilteredDobTo}
                              </strong>{" "}
                            </h3>
                            <img
                              src={FilterClose}
                              alt=""
                              onClick={removeScheduleDOBFilter}
                            />
                          </li>
                        )}
                      {scheduleFilteredLastDataFrom.length !== 0 &&
                        scheduleFilteredLastDataTo.length !== 0 && (
                          <li className="apply-filter-single">
                            <h3 className="filter-name-type">
                              Last Test Date:{" "}
                              <strong>
                                {scheduleFilteredLastDataFrom} -{" "}
                                {scheduleFilteredLastDataTo}
                              </strong>{" "}
                            </h3>
                            <img
                              src={FilterClose}
                              alt=""
                              onClick={removeScheduleTestFilter}
                            />
                          </li>
                        )}
                    </ul>
                    <Link
                      to={!subType ? urlMapping[type] : urlMapping[subType]}
                      className="clear-all-filter"
                      onClick={clearFilters}
                    >
                      Clear all filters
                    </Link>
                  </div>
                )}
              </>
            )}
            {type === "Test" && subType === "upcoming" && (
              <>
                {((upcomingFilteredDobFrom.length !== 0 &&
                  upcomingFilteredDobTo.length !== 0) ||
                  (upcomingFilteredScheduledDateFrom.length !== 0 &&
                    upcomingFilteredScheduledDateTo.length !== 0)) && (
                  <div className="filter-header">
                    <h2 className="apply-filter-name">Applied Filters:</h2>
                    <ul className="apply-filter-list">
                      {upcomingFilteredDobFrom.length !== 0 &&
                        upcomingFilteredDobTo.length !== 0 && (
                          <li className="apply-filter-single">
                            <h3 className="filter-name-type">
                              Date of Birth:{" "}
                              <strong>
                                {upcomingFilteredDobFrom} -{" "}
                                {upcomingFilteredDobTo}
                              </strong>{" "}
                            </h3>
                            <img
                              src={FilterClose}
                              alt=""
                              onClick={removeUpcomingDOBFilter}
                            />
                          </li>
                        )}
                      {upcomingFilteredScheduledDateFrom.length !== 0 &&
                        upcomingFilteredScheduledDateTo.length !== 0 && (
                          <li className="apply-filter-single">
                            <h3 className="filter-name-type">
                              Schedule Date:{" "}
                              <strong>
                                {upcomingFilteredScheduledDateFrom} -{" "}
                                {upcomingFilteredScheduledDateTo}
                              </strong>{" "}
                            </h3>
                            <img
                              src={FilterClose}
                              alt=""
                              onClick={removeUpcomingScheduleFilter}
                            />
                          </li>
                        )}
                    </ul>
                    <Link
                      to={!subType ? urlMapping[type] : urlMapping[subType]}
                      className="clear-all-filter"
                      onClick={clearFilters}
                    >
                      Clear all filters
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="table-responsive">
            <table className="table" id="en-width">
              <thead>
                <tr>
                  {tableHeadings &&
                    tableHeadings.map((tableHead) => {
                      return(
                      <th
                        id={tableHead}
                        name={tableHead}
                        key={tableHead}
                        // onClick={onClickTableHeading}
                        onClick={type === "Patient" && tableHead === "Status" ? undefined : onClickTableHeading }

                      >
                        {tableHead}
                        {tableHead !== "Actions" &&
                          tableHead !== "Communities" && (
                            <>
                            {rowOrderMapping[paramType][
                              orderMapping[paramType][tableHead]
                            ]?.icon !== false && (
                              <span className="icons-th">
                                {rowOrderMapping[paramType][
                                  orderMapping[paramType][tableHead]
                                ]?.icon === "" ? (
                                  <>
                                    <span></span>
                                    <span className="order-4"></span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      className={
                                        rowOrderMapping[paramType][
                                          orderMapping[paramType][tableHead]
                                        ]?.icon === "asc"
                                          ? "caret-4-asc-icon"
                                          : "caret-4-desc-icon"
                                      }
                                    ></span>
                                    <span className="order-4"></span>
                                  </>
                                )}
                              </span>
                            )}
                          </>
                          )}
                      </th>
                    )})}
                  {type === "Provider" ? (
                    <>
                      {!isCatchUAdmin &&
                      (userRole.includes("Super Admin") ||
                        userRole.includes("Community Admin") ||
                        userRole.includes("Individual Practice Admin") ||
                        userRole.includes("Support Staff")) ? (
                        <>{subType !== "Patient History" && <th>Actions</th>}</>
                      ) : (
                        <>
                          {isCatchUAdmin &&
                            (userRole.includes("Super Admin") ||
                              permissions.includes("Providers") ||
                              userRole.includes("Support Staff")) && (
                              <>
                                {subType !== "Patient History" && (
                                  <th>Actions</th>
                                )}
                              </>
                            )}
                        </>
                      )}
                    </>
                  ) : (
                    <>{subType !== "Patient History" && type !== "External Providers" && type !== "Prepaid Patients" && <th>Actions</th>}</>
                  )}
                </tr>
              </thead>

              <TableBody
                subType={subType}
                type={type}
                data={data}
                setDataFlag={setDataFlag}
                setDataFlag1={setDataFlag1}
                setDataFlag2={setDataFlag2}
                setShowToast={setShowToast}
              />
            </table>
          </div>
          {type === "Patient" && subType !== "Patient History" && (
            <Pagination
              type="Patient"
              data={data}
              totalRecords={totalRecords}
              setOtherLoader={setOtherLoader}
            />
          )}

          {type === "Provider" && (
            <Pagination
              type="Provider"
              data={data}
              totalRecords={totalRecords}
              setOtherLoader={setOtherLoader}
            />
          )}

            {type === "External Providers" && (
              <Pagination
                type="External Providers"
                data={data}
                totalRecords={totalRecords}
                setOtherLoader={setOtherLoader}
              />
            )}
             {type === "Prepaid Patients" && (
              <Pagination
                type="Prepaid Patients"
                data={data}
                totalRecords={totalRecords}
                setOtherLoader={setOtherLoader}
                params={params}

              />
            )}

          {type === "AdminUser" && (
            <Pagination
              type="AdminUser"
              data={data}
              totalRecords={totalRecords}
              prefix={"tab_key=admin_user&"}
              setOtherLoader={setOtherLoader}
            />
          )}

          {type === "RegUser" && (
            <Pagination
              type="RegUser"
              data={data}
              totalRecords={totalRecords}
              prefix={"tab_key=regular_user&"}
              setOtherLoader={setOtherLoader}
            />
          )}

          {subType === "schedule" && (
            <Pagination
              type={type}
              dataType={subType}
              data={data}
              totalRecords={totalRecords}
              prefix={"table=schedule_test&"}
              setOtherLoader={setOtherLoader}
            />
          )}

          {subType === "upcoming" && (
            <Pagination
              type={type}
              dataType={subType}
              data={data}
              totalRecords={totalRecords}
              prefix={"table=upcoming_test&"}
              setOtherLoader={setOtherLoader}
            />
          )}

          {type === "Patient" && subType === "Patient History" && (
            <Pagination
              type={type}
              dataType={subType}
              data={data}
              params={params.patientID}
              totalRecords={totalRecords}
              setOtherLoader={setOtherLoader}
            />
          )}
        </div>
      )}
      {TabKey === type && (
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      )}

      {type !== "RegUser" && type !== "AdminUser" && (
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      )}
    </Fragment>
  );
};

export default TableData;
