import React, { useEffect, useState } from "react";
import TableComponent from "./Table/TableData";
import data from "./Data";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { communityActions } from "../../store/community";
import { sidebarActions } from "../../store/sidebar";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../UI/loader/loader";
import { getWithExpiry } from "../utils/helper";
import useQueryString from "../../hooks/useQueryString";
const Communities = () => {
  const tableHeadings = [
    "ID",
    "Name",
    "Corporate",
    "Address 01",
    "Address 02",
    "City",
    "State",
    "Zip",
    "Actions",
  ];
  const dispatch = useDispatch();
  const urlParams = useQueryString();
  const token = "Bearer " + getWithExpiry("expiry_token");
  const communities = useSelector((state) => state.community.communities);
  const totalRecords = useSelector(
    (state) => state.community.communityTotalRecords
  );
  const CommunityPageNumber = useSelector(
    (state) => state.community.communityCurrentPage
  );

  const communityApiUrl = useSelector(
    (state) => state.community.communityApiUrl
  );
  const [loading, setLoading] = useState(true);
  const [otherLoader, setOtherLoader] = useState(false);
  const [dataFlag, setDataFlag] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (!showToast) {
      setLoading(true);
      dispatch(sidebarActions.setSelectedNav("Community"));
    }

    let urlKeys = [...urlParams.keys()];
    let urlValues = [...urlParams.values()];

    let query;
    if (!communityApiUrl && urlKeys.length > 0) {
      query = "?";
      for (let i = 0; i < urlKeys.length; i++) {
        if (urlKeys[i] !== "page") {
          query = query + urlKeys[i] + "=" + urlValues[i] + "&";
        }
      }
    } else {
      query = communityApiUrl ? communityApiUrl + "&" : "?";
    }

    let pageNumber = urlParams.has("page")
      ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
        ? urlParams.get("page")
        : CommunityPageNumber
      : CommunityPageNumber;

    const Url = `${process.env.REACT_APP_URL_PATH}/community/community_list/${query}page=${pageNumber}`;

    query = query.replace("&&", "&");
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/community/community_list/${query}page=${pageNumber}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        // const communities = response.data.data.communities

        dispatch(
          communityActions.setPageNumber(response.data.data.communities_pages)
        );
        dispatch(
          communityActions.setCommunityTotalRecords(
            response.data.data.total_records
          )
        );
        dispatch(
          communityActions.setCommunities(response.data.data.communities)
        );
        dispatch(
          communityActions.setIsCatchuAdmin(response.data.data.is_catchu_admin)
        );
        dispatch(communityActions.setCorporates(response.data.data.corporates));

        if (CommunityPageNumber > response.data.data.communities_pages) {
          dispatch(
            communityActions.setCommunityPageNumber(
              response.data.data.communities_pages
            )
          );
        }
        setLoading(false);
        setOtherLoader(false);
      })
      .catch((error) => {
        setLoading(false);
        setOtherLoader(false);
      });
  }, [dataFlag]);

  return (
    <div className="corporate-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <>
          <TableComponent
            tableHeadings={tableHeadings}
            data={communities}
            totalRecords={totalRecords}
            type="Community"
            setDataFlag={setDataFlag}
            setShowToast={setShowToast}
            otherLoader={otherLoader}
            setOtherLoader={setOtherLoader}
          />
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </>
      )}
      {/* <ToastContainer position="bottom-right" /> */}
    </div>
  );
};

export default Communities;
