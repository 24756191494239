import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./TermsModal.scss";

// imports image

import CloseModal from "../../../assets/close-outline.svg";

function TermsModal(props) {
  const { isShowModal, hideModal, document } = props;
  return (
    <div className="test-modal-wrapper">
      <Modal
        className="test-modal terms-condition"
        show={isShowModal}
        onHide={hideModal}
        backdrop="static"
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header className="audit-modal-header">
          <h1 className="modal-header-title">Terms and Conditions</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="action-modal-body">
          {/* <h2 className="action-heading">
            Terms and Conditions
          </h2> */}
          <iframe
            title={"IFRAME"}
            style={{ width: "100%", height: "100vh" }}
            src={document}
          ></iframe>
        </Modal.Body>
        <Modal.Footer className="action-footer-modal">
          {/* <button className="btn btn-primary-fill btn-14-24">
            Yes, I’m Sure.
          </button> */}
          <button
            className="btn btn-secondary-fill btn-14-24"
            onClick={hideModal}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TermsModal;
