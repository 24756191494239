import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { communityActions } from "../../../../store/community";
import { removeEmptyParams } from "../../../../services/helpers/sorting.hepler";
import DatePicker from "react-datepicker";
import Moment from "moment";
import "./FiltersModal.scss";

import CloseModal from "../../../../assets/close-outline.svg";
import useQueryString from "../../../../hooks/useQueryString";
import CustomSelect from "../../../General/filters/CustomSelect.Component";

function FilterModal(props) {
  const {
    hideModal,
    orderBy,
    setDataFlag,
    setShowToast,
    testDateRange,
    setTestDateRange,
    dateRange,
    setDateRange,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const { communityID } = useParams();
  const urlParams = useQueryString();
  const [filterData, setFilterData] = useState([]);
  // const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  // const [testDateRange, setTestDateRange] = useState([null, null]);
  const [testStartDate, testEndDate] = testDateRange;

  const searchCommunityPatientText = useSelector(
    (state) => state.filter.searchCommunityPatientText
  );
  const groupHistoryStatus = useSelector(
    (state) => state.community.groupHistoryStatus
  );
  const returnSelectedArray = async (object) => {
    if (!object || object.length <= 0) return [];
    var _selected = [];
    object.map((dataKey) => {
      _selected.push(dataKey.value.trim());
    });
    return _selected;
  };

  const handleSelectStatus = async (e) => {
    var _selected = await returnSelectedArray(e);
    dispatch(communityActions.setGroupHistoryStatus(_selected));
    //setFilterData(_selected);
  };
  const filterSubmissionHandler = () => {

    let dob_from = "";
    let dob_to = "";

    let test_from = "";
    let test_to = "";
    if (startDate && endDate) {
      dob_from =
        Moment(startDate.toLocaleDateString()).format("MM-DD-YYYY") +
        " 00:00:00";
      dob_to =
        Moment(endDate.toLocaleDateString()).format("MM-DD-YYYY") + " 00:00:00";
    }
    if (testStartDate && testEndDate) {
      test_from =
        Moment(testStartDate.toLocaleDateString()).format("MM-DD-YYYY") +
        " 00:00:00";
      test_to =
        Moment(testEndDate.toLocaleDateString()).format("MM-DD-YYYY") +
        " 00:00:00";
    }

    let customQuery = `search_query=${searchCommunityPatientText}&${orderBy}&created_date_from:eq=${dob_from}&created_date_to:eq=${dob_to}&expiry_date_from:eq=${test_from}&expiry_date_to:eq=${test_to}&status:eq=${groupHistoryStatus}`;

    var modifiedString = removeEmptyParams(customQuery);

    let searchParam = "";
    let prefix = "";
    if (modifiedString || urlParams.toString().includes("page")) {
      prefix = "tab_key=group_test&";
    }
    if (
      !modifiedString.includes("page") &&
      urlParams.toString().includes("page")
    ) {
      searchParam = "?" + prefix + modifiedString + `&page=${1}`;
    } else {
      searchParam = "?" + prefix + modifiedString;
    }

    searchParam = searchParam.replace("&&", "&");
    history.replace({
      pathname: `/edit/communities/${communityID}`,
      search: searchParam,
    });

    dispatch(communityActions.setGroupHistoryCreatedDateFrom(dob_from));
    dispatch(communityActions.setGroupHistoryCreatedDateTo(dob_to));
    dispatch(communityActions.setGroupHistoryExpiryDateFrom(test_from));
    dispatch(communityActions.setGroupHistoryExpiryDateTo(test_to));

    dispatch(
      communityActions.setTestCommunityApiUrl("?" + prefix + modifiedString)
    );
    setShowToast(true);
    setDataFlag((prevState) => !prevState);
    hideModal();
  };

  const clearFilters = () => {
    var modifiedString = "";

    let searchParam = "";
    let prefix = "";
    if (modifiedString || urlParams.toString().includes("page")) {
      prefix = "tab_key=group_test&";
    }
    if (
      !modifiedString.includes("page") &&
      urlParams.toString().includes("page")
    ) {
      searchParam = "?" + prefix + modifiedString + `&page=${1}`;
    } else {
      searchParam = "?" + prefix + modifiedString;
    }

    searchParam = searchParam.replace("&&", "&");
    history.replace({
      pathname: `/edit/communities/${communityID}`,
      search: searchParam,
    });

    dispatch(communityActions.setGroupHistoryCreatedDateFrom([]));
    dispatch(communityActions.setGroupHistoryCreatedDateTo([]));
    dispatch(communityActions.setGroupHistoryExpiryDateFrom([]));
    dispatch(communityActions.setGroupHistoryExpiryDateTo([]));
    dispatch(communityActions.setGroupHistoryStatus(""));
    setDateRange([null, null]);
    setTestDateRange([null, null]);

    dispatch(
      communityActions.setTestCommunityApiUrl("?" + prefix + modifiedString)
    );
    setShowToast(true);
    setDataFlag((prevState) => !prevState);
    hideModal();
  };
  const statusData = ["Active", "Terminated", "Completed", "Expired"];
  return (
    <div className="filter-modal-wrapper">
      <Modal
        className="filter-modal initiate-test-patient-modal modal-loader"
        show={true}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
      >
        <Modal.Header className="filter-modal-header">
          <h1 className="modal-header-title">Filters</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="close-modal" />
          </span>
        </Modal.Header>
        <Modal.Body className="filter-modal-body show-select">
          <form
          
            method="post"
            className="add-corporate-form"
            // onSubmit={initiateTestSubmissionHandler}
          >
            <div className="field-wrapper">
              <label htmlFor="dob" className="label">
                Test Date
              </label>
              <div className="date-time-wrapper">
                <DatePicker
                  className="custom-dateTime-input"
                  placeholderText="Select your Date Range"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  //timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy"
                  //showTimeInput
                  // maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  // isClearable={true}
                />
                <span className="dateTime-divider"></span>
              </div>
            </div>

            <div className="field-wrapper">
              <label htmlFor="last_test_date" className="label">
                Ordered
              </label>
              <div className="date-time-wrapper">
                <DatePicker
                  className="custom-dateTime-input"
                  placeholderText="Select your Schedule Date"
                  selectsRange={true}
                  startDate={testStartDate}
                  endDate={testEndDate}
                  onChange={(update) => {
                    setTestDateRange(update);
                  }}
                  //timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy"
                  //showTimeInput
                  //maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  // isClearable={true}
                />
                <span className="dateTime-divider"></span>
              </div>
            </div>

            <div className="field-wrapper">
              <CustomSelect
                selectedValues={groupHistoryStatus}
                labelText="Status"
                name="status"
                id="status"
                isMulti={true}
                data={statusData}
                handleSelectChange={handleSelectStatus}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16"
            onClick={clearFilters}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary-fill btn-primary-16"
            onClick={filterSubmissionHandler}
          >
            Apply Filter
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FilterModal;
