import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
  Fragment,
} from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import auth, { authActions } from "../../../store/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import useInput from "../../../hooks/use-input";
import verifyOtp from "./verifyOtp";
import "./OTPVerification.scss";
import Loader from "../../UI/loader/loader";
// import images
import BrandLogo from "../../../assets/logo.png";
import ArrowRight from "../../../assets/arrow-right.svg";
import { userActions } from "../../../store/user";
import { setWithExpiry } from "../../utils/helper";
import TermsServiesModal from "./TermsServicesModal";
const isNotEmpty = (value) => value.trim() !== "";
const isSingleDigit = (value) => {
  const regex = /^\d$/;
  return isNotEmpty(value) && regex.test(value);
};

const VerifyOTP = (props) => {
  const firstOTPField = useRef();
  const secondOTPField = useRef();
  const thirdOTPField = useRef();
  const fourthOTPField = useRef();
  const fifthOTPField = useRef();
  const sixthOTPField = useRef();

  const PhonefirstOTPField = useRef();
  const PhonesecondOTPField = useRef();
  const PhonethirdOTPField = useRef();
  const PhonefourthOTPField = useRef();
  const PhonefifthOTPField = useRef();
  const PhonesixthOTPField = useRef();

  const { type } = props;
  const location = useLocation();
  const history = useHistory();
  const email = useSelector((state) => state.auth.email);
  const rememberMe = useSelector((state) => state.auth.rememberMe);
  // const successMessage = useSelector(state => state.auth.successMessage);
  const dispatch = useDispatch();
  const [emailOtpError, setEmailOtpError] = useState("");
  const [smsOtpError, setSmsOtpError] = useState("");
  const [error, setError] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(false);

  const [emailOtp, setEmailOtp] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const [loading, setLoading] = useState(false);
  // const otpRef = useRef(null);

  const {
    value: emailOtpNum1,
    isValid: emailOtpNum1IsValid,
    hasError: emailOtp1HasError,
    inputChangeHandler: emailOtp1ChangeHandler,
    inputBlurHandler: emailOtp1BlurHandler,
    reset: emailOtp1ResetHandler,
  } = useInput(isSingleDigit);

  const {
    value: emailOtpNum2,
    isValid: emailOtpNum2IsValid,
    hasError: emailOtp2HasError,
    inputChangeHandler: emailOtp2ChangeHandler,
    inputBlurHandler: emailOtp2BlurHandler,
    reset: emailOtp2ResetHandler,
  } = useInput(isSingleDigit);

  const {
    value: emailOtpNum3,
    isValid: emailOtpNum3IsValid,
    hasError: emailOtp3HasError,
    inputChangeHandler: emailOtp3ChangeHandler,
    inputBlurHandler: emailOtp3BlurHandler,
    reset: emailOtp3ResetHandler,
  } = useInput(isSingleDigit);

  const {
    value: emailOtpNum4,
    isValid: emailOtpNum4IsValid,
    hasError: emailOtp4HasError,
    inputChangeHandler: emailOtp4ChangeHandler,
    inputBlurHandler: emailOtp4BlurHandler,
    reset: emailOtp4ResetHandler,
  } = useInput(isSingleDigit);

  const {
    value: emailOtpNum5,
    isValid: emailOtpNum5IsValid,
    hasError: emailOtp5HasError,
    inputChangeHandler: emailOtp5ChangeHandler,
    inputBlurHandler: emailOtp5BlurHandler,
    reset: emailOtp5ResetHandler,
  } = useInput(isSingleDigit);

  const {
    value: emailOtpNum6,
    isValid: emailOtpNum6IsValid,
    hasError: emailOtp6HasError,
    inputChangeHandler: emailOtp6ChangeHandler,
    inputBlurHandler: emailOtp6BlurHandler,
    reset: emailOtp6ResetHandler,
  } = useInput(isSingleDigit);

  const {
    value: smsOtpNum1,
    isValid: smsOtpNum1IsValid,
    hasError: smsOtp1HasError,
    inputChangeHandler: smsOtp1ChangeHandler,
    inputBlurHandler: smsOtp1BlurHandler,
    reset: smsOtp1ResetHandler,
  } = useInput(isSingleDigit);

  const {
    value: smsOtpNum2,
    isValid: smsOtpNum2IsValid,
    hasError: smsOtp2HasError,
    inputChangeHandler: smsOtp2ChangeHandler,
    inputBlurHandler: smsOtp2BlurHandler,
    reset: smsOtp2ResetHandler,
  } = useInput(isSingleDigit);

  const {
    value: smsOtpNum3,
    isValid: smsOtpNum3IsValid,
    hasError: smsOtp3HasError,
    inputChangeHandler: smsOtp3ChangeHandler,
    inputBlurHandler: smsOtp3BlurHandler,
    reset: smsOtp3ResetHandler,
  } = useInput(isSingleDigit);

  const {
    value: smsOtpNum4,
    isValid: smsOtpNum4IsValid,
    hasError: smsOtp4HasError,
    inputChangeHandler: smsOtp4ChangeHandler,
    inputBlurHandler: smsOtp4BlurHandler,
    reset: smsOtp4ResetHandler,
  } = useInput(isSingleDigit);

  const {
    value: smsOtpNum5,
    isValid: smsOtpNum5IsValid,
    hasError: smsOtp5HasError,
    inputChangeHandler: smsOtp5ChangeHandler,
    inputBlurHandler: smsOtp5BlurHandler,
    reset: smsOtp5ResetHandler,
  } = useInput(isSingleDigit);

  const {
    value: smsOtpNum6,
    isValid: smsOtpNum6IsValid,
    hasError: smsOtp6HasError,
    inputChangeHandler: smsOtp6ChangeHandler,
    inputBlurHandler: smsOtp6BlurHandler,
    reset: smsOtp6ResetHandler,
  } = useInput(isSingleDigit);

  const hasForgottenPassword = useSelector(
    (state) => state.auth.hasForgottenPassword
  );
  const successMessage = useSelector((state) => state.auth.successMessage);
  const isLogin = useSelector((state) => state.auth.isLogin);

  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const showTermsModal = () => setTermsModalOpen(true);
  const hideTermsModal = () => setTermsModalOpen(false);

  const [iframeDoc, setIframeDoc] = useState("");
  useEffect(() => {
    if (type === "login") {
      if (isLogin) {
        notifySuccess(successMessage);
        dispatch(authActions.resetLoginStatus());
      }
    } else {
      if (hasForgottenPassword) {
        notifySuccess(successMessage);
        dispatch(authActions.resetForgotPasswordStatus(false));
      }
    }
  }, []);

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  let formIsValid = false;
  let url;

  if (type === "login") {
    url = `${process.env.REACT_APP_URL_PATH}/user/verify_otp/`;
  } else {
    url = `${process.env.REACT_APP_URL_PATH}/user/verify_forget_password_otp/`;
  }

  if (type === "login") {
    // if (emailOtpNum1 && emailOtpNum2 && emailOtpNum3 && emailOtpNum4 && emailOtpNum5 && emailOtpNum6
    //     && smsOtpNum1 && smsOtpNum2 && smsOtpNum3 && smsOtpNum4 && smsOtpNum5 && smsOtpNum6) {
    //     formIsValid = true;
    // }
  } else {
    // if (emailOtpNum1 && emailOtpNum2 && emailOtpNum3 && emailOtpNum4 && emailOtpNum5 && emailOtpNum6) {
    //      formIsValid = true;
    // }
  }

  const getResponse = (response, res) => {
    if (type === "login") {
      const token = response.data.data.token;
      // const successMessage = response.data.message;
      const successMessage = "Login Successful";
      dispatch(authActions.verifyOtp(token)); // dispatch token to auth store
      dispatch(authActions.verifyOtpSuccessMsg(successMessage)); // store login success message

      //localStorage.setItem("token", token); // store token in localstorage
      if (rememberMe === true) {
        setWithExpiry("expiry_token", token, 86400000);
      } else {
        setWithExpiry("expiry_token", token, 7200000);
      }
      setWithExpiry("otp_expiry_" + email, "available", 2592000000);
      // if (rememberMe === true) {
      //   localStorage.setItem("rememberMe", rememberMe);
      // } else {
      //   sessionStorage.setItem("rememberMe", rememberMe);
      // }
      if (res.data.data.is_enabled) {
        dispatch(authActions.setIsLoggedIn(true));
      } else {
        notifyError("Cannot Login. User is Disabled");
        localStorage.removeItem("expiry_token");
        sessionStorage.clear();
      }

      // history.replace("/corporates"); // redirect to homepage
      history.replace("/login");
    } else {
      const email = response.data.data.email;
      dispatch(authActions.verifyOtpSuccessMsg("OTP Verified!"));
      dispatch(authActions.verifyForgotPasswordOtp(email));
      history.replace("/change-password");
    }
  };

  const otpModalSubmissionHandler = (event) => {
    event.preventDefault();
    setDisabledBtn(true);

    // if (type === "login") {
    //   // if (!emailOtpNum1 || !emailOtpNum2 || !emailOtpNum3 || !emailOtpNum4 || !emailOtpNum5 || !emailOtpNum6
    //   //     || !smsOtpNum1 || !smsOtpNum2 || !smsOtpNum3 || !smsOtpNum4 || !smsOtpNum5 || !smsOtpNum6) {
    //   //     formIsValid = false;
    //   //     return;
    //   // }
    // } else {
    //   // if (!emailOtpNum1 || !emailOtpNum2 || !emailOtpNum3 || !emailOtpNum4 || !emailOtpNum5 || !emailOtpNum6) {
    //   //     formIsValid = false;
    //   //     return;
    //   // }
    // }

    // create form data
    const fd = new FormData();
    // const emailOtp = `${emailOtpNum1}${emailOtpNum2}${emailOtpNum3}${emailOtpNum4}${emailOtpNum5}${emailOtpNum6}`;
    // const phoneOtp = `${smsOtpNum1}${smsOtpNum2}${smsOtpNum3}${smsOtpNum4}${smsOtpNum5}${smsOtpNum6}`;

    if (type === "login") {
      fd.append("email", email);
      fd.append("otp", emailOtp);
      fd.append("otp_check", location.state);
    } else {
      fd.append("email", email);
      fd.append("email_otp", emailOtp);
    }

    // make HTTP call
    axios({
      method: "post",
      url: url,
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        if (response.data.data.accepted) {
          const token = response.data.data.token;

          //
          axios({
            url: `${process.env.REACT_APP_URL_PATH}/user/permissions/`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => {
              const permissions = response.data.data.permissions;
              const catchu_admin = response.data.data.catchu_admin;
              dispatch(userActions.addPermissions(permissions));
              dispatch(userActions.setIsCatchuAdmin(catchu_admin));
            })
            .catch((error) => {
              if (error.response) {
                setDisabledBtn(false);

                notifyError(error.response.data.message);
              }
            });

          //
          axios({
            url: `${process.env.REACT_APP_URL_PATH}/user/roles/`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => {
              const resData = res.data.data.roles;
              dispatch(userActions.setRole(resData));

              getResponse(response, res);
            })
            .catch((error) => {
              if (error.response) {
                setDisabledBtn(false);

                notifyError(error.response.data.message);
              }
            });
       
        } else {
          setIframeDoc(response.data.data.iframe_url);
          showTermsModal();
        }
      })
      .catch((error) => {
        if (error.response) {
          setDisabledBtn(false);
          if (error.response.data.data && error.response.data.data.email)
            notifyError(error.response.data.data.email[0]);
          else if (error.response.data.message === "Field Errors") {
            setEmailOtpError(error.response.data.data.email_otp);
          } else {
            notifyError(error.response.data.message);
          }
        }
      });
  };

  const resendCode = () => {
    if (!loading) {
      setLoading(true);
      axios({
        url: `${process.env.REACT_APP_URL_PATH}/user/resend_otp/`,
        method: "POST",
        data: { email: email },
      })
        .then((response) => {
          notifySuccess(response.data.message);
          setLoading(true);

          setTimeout(() => {
            setLoading(false);
          }, 10000);
        })
        .catch((error) => {
          notifyError(error.response.data.message);
          setLoading(false);
        });
    } else {
      notifyError("You can't send another request before 1 minute");
    }
  };


  return (
    <Fragment>
      {termsModalOpen && (
        <TermsServiesModal
          isShowModal={termsModalOpen}
          hideModal={hideTermsModal}
          document={iframeDoc}
          email={email}
          emailOtp={emailOtp}
          otpCheck={location.state}
          type={"otp"}
        />
      )}

      <div className="login-screen-wrapper">
        <div className="login-box">
          <div className="login-logo">
            <img src={BrandLogo} alt="" />
          </div>
          <div className="login-header">
            <h1 className="login-heading">One Time Password Verification</h1>
            <p className="login-des">
              {location.state === "email"
                ? "Please enter your 6-digit code sent to your email address to verify"
                : "Please enter your 6-digit code sent to your phone to verify"}
            </p>
          </div>
          <form
           
            className="otp-verification-form"
            onSubmit={otpModalSubmissionHandler}
          >
            <div className="code-wrapper">
              <label htmlFor="number" className="login-label">
                Please enter your 6-digit code to verify *
              </label>

              {/* <label htmlFor="new_password" className="login-label">
                New Password *
              </label> */}
              <input
                id="otp"
                name="otp"
                type="text"
                maxLength="6"
                className="custom-input"
                placeholder="One Time Password"
                value={emailOtp}
                onChange={(e) => {
                  const { value, name } = e.target;
                  const regex = /^(\s*|\d+)$/i;
                  if (regex.test(value) === false) return;
                  if (value.trim().length > 6) return;
                  value.trim().length === 6
                    ? setDisabledBtn(false)
                    : setDisabledBtn(true);
                  setEmailOtp(e.target.value);
                }}
                required
              />
              <p
                className="send-code"
                style={{
                  float: "right",
                  color: !loading ? "#0645AD" : "#14192e",
                  opacity: !loading ? 1 : 0.4,
                }}
                onClick={resendCode}
              >
                Resend Code
              </p>
              {/* {pswError && <p className="error-text">{pswError}</p>} */}

              {/* <ul className="code-list" id="email-otp">
              <li className="single-code">
                <input
                  type="number"
                  placeholder="0"
                  className="code-input digit-1"
                  minLength="1"
                  maxLength="1"
                  onKeyDown={(e) => {
                    if (
                      e.keyCode === "69" ||
                      (e.target.value.length === 1 && e.keyCode !== 8)
                    )
                      e.preventDefault();
                  }}
                  data-next="digit-2"
                  value={emailOtpNum1}
                  onChange={emailOtp1ChangeHandler}
                  onBlur={emailOtp1BlurHandler}
                  onKeyUp={(e) =>
                    (e.keyCode >= 48 && e.keyCode <= 57) ||
                    (e.keyCode >= 96 && e.keyCode <= 105) ||
                    e.keyCode === 39
                      ? secondOTPField.current.focus()
                      : false
                  }
                  ref={firstOTPField}
                />
              </li>
              <li className="single-code">
                <input
                  type="number"
                  placeholder="0"
                  className="code-input digit-2"
                  minLength="1"
                  maxLength="1"
                  onKeyDown={(e) => {
                    if (
                      e.keyCode === "69" ||
                      (e.target.value.length === 1 && e.keyCode !== 8)
                    )
                      e.preventDefault();
                    if (e.target.value.length === 0 && e.keyCode === 8) {
                      firstOTPField.current.focus();
                      e.preventDefault();
                    }
                  }}
                  data-previous="digit-1"
                  data-next="digit-3"
                  value={emailOtpNum2}
                  onChange={emailOtp2ChangeHandler}
                  onBlur={emailOtp2BlurHandler}
                  onKeyUp={(e) =>
                    e.keyCode === 37
                      ? firstOTPField.current.focus()
                      : (e.keyCode >= 48 && e.keyCode <= 57) ||
                        (e.keyCode >= 96 && e.keyCode <= 105) ||
                        e.keyCode === 39
                      ? thirdOTPField.current.focus()
                      : false
                  }
                  ref={secondOTPField}
                />
              </li>
              <li className="single-code">
                <input
                  type="number"
                  placeholder="0"
                  className="code-input digit-3"
                  minLength="1"
                  maxLength="1"
                  data-previous="digit-2"
                  data-next="digit-4"
                  value={emailOtpNum3}
                  onChange={emailOtp3ChangeHandler}
                  onBlur={emailOtp3BlurHandler}
                  onKeyDown={(e) => {
                    if (
                      e.keyCode === "69" ||
                      (e.target.value.length === 1 && e.keyCode !== 8)
                    )
                      e.preventDefault();
                    if (e.target.value.length === 0 && e.keyCode === 8) {
                      secondOTPField.current.focus();
                      e.preventDefault();
                    }
                  }}
                  onKeyUp={(e) =>
                    e.keyCode === 37
                      ? secondOTPField.current.focus()
                      : (e.keyCode >= 48 && e.keyCode <= 57) ||
                        (e.keyCode >= 96 && e.keyCode <= 105) ||
                        e.keyCode === 39
                      ? fourthOTPField.current.focus()
                      : false
                  }
                  ref={thirdOTPField}
                />
              </li>
              <li className="single-code">
                <input
                  type="number"
                  placeholder="0"
                  className="code-input digit-4"
                  minLength="1"
                  maxLength="1"
                  data-previous="digit-3"
                  data-next="digit-5"
                  value={emailOtpNum4}
                  onChange={emailOtp4ChangeHandler}
                  onBlur={emailOtp4BlurHandler}
                  onKeyDown={(e) => {
                    if (
                      e.keyCode === "69" ||
                      (e.target.value.length === 1 && e.keyCode !== 8)
                    )
                      e.preventDefault();
                    if (e.target.value.length === 0 && e.keyCode === 8) {
                      thirdOTPField.current.focus();
                      e.preventDefault();
                    }
                  }}
                  onKeyUp={(e) =>
                    e.keyCode === 37
                      ? thirdOTPField.current.focus()
                      : (e.keyCode >= 48 && e.keyCode <= 57) ||
                        (e.keyCode >= 96 && e.keyCode <= 105) ||
                        e.keyCode === 39
                      ? fifthOTPField.current.focus()
                      : false
                  }
                  ref={fourthOTPField}
                />
              </li>
              <li className="single-code">
                <input
                  type="number"
                  placeholder="0"
                  className="code-input digit-5"
                  minLength="1"
                  maxLength="1"
                  data-previous="digit-4"
                  data-next="digit-6"
                  value={emailOtpNum5}
                  onChange={emailOtp5ChangeHandler}
                  onBlur={emailOtp5BlurHandler}
                  onKeyDown={(e) => {
                    if (
                      e.keyCode === "69" ||
                      (e.target.value.length === 1 && e.keyCode !== 8)
                    )
                      e.preventDefault();
                    if (e.target.value.length === 0 && e.keyCode === 8) {
                      fourthOTPField.current.focus();
                      e.preventDefault();
                    }
                  }}
                  onKeyUp={(e) =>
                    e.keyCode === 37
                      ? fourthOTPField.current.focus()
                      : (e.keyCode >= 48 && e.keyCode <= 57) ||
                        (e.keyCode >= 96 && e.keyCode <= 105) ||
                        e.keyCode === 39
                      ? sixthOTPField.current.focus()
                      : false
                  }
                  ref={fifthOTPField}
                />
              </li>
              <li className="single-code">
                <input
                  type="number"
                  placeholder="0"
                  className="code-input digit-6"
                  minLength="1"
                  maxLength="1"
                  data-previous="digit-5"
                  value={emailOtpNum6}
                  onChange={emailOtp6ChangeHandler}
                  onBlur={emailOtp6BlurHandler}
                  onKeyDown={(e) => {
                    if (
                      e.keyCode === "69" ||
                      (e.target.value.length === 1 && e.keyCode !== 8)
                    )
                      e.preventDefault();
                    if (e.target.value.length === 0 && e.keyCode === 8) {
                      fifthOTPField.current.focus();
                      e.preventDefault();
                    }
                  }}
                  onKeyUp={(e) =>
                    e.keyCode === 37 ? fifthOTPField.current.focus() : false
                  }
                  ref={sixthOTPField}
                />
              </li>
            </ul> */}
              {/* {emailOtpError && <p className="error-text">{emailOtpError}</p>} */}
            </div>
            {/* {type === "login" && (
            <div className="code-wrapper">
              <label htmlFor="" className="login-label">
                Enter your phone number code to verify *
              </label>
              <ul
                className="code-list"
                // data-group-name="digits"
                id="sms-otp"
              >
                <li className="single-code">
                  <input
                    type="number"
                    placeholder="0"
                    className="code-input digit-1"
                    minLength="1"
                    maxLength="1"
                    data-next="digit-2"
                    value={smsOtpNum1}
                    onChange={smsOtp1ChangeHandler}
                    onBlur={smsOtp1BlurHandler}
                    onKeyDown={(e) => {
                      if (
                        e.keyCode === "69" ||
                        (e.target.value.length === 1 && e.keyCode !== 8)
                      )
                        e.preventDefault();
                    }}
                    onKeyUp={(e) =>
                      (e.keyCode >= 48 && e.keyCode <= 57) ||
                      (e.keyCode >= 96 && e.keyCode <= 105) ||
                      e.keyCode === 39
                        ? PhonesecondOTPField.current.focus()
                        : false
                    }
                    ref={PhonefirstOTPField}
                  />
                </li>
                <li className="single-code">
                  <input
                    type="number"
                    placeholder="0"
                    className="code-input digit-2"
                    minLength="1"
                    maxLength="1"
                    data-previous="digit-1"
                    data-next="digit-3"
                    value={smsOtpNum2}
                    onChange={smsOtp2ChangeHandler}
                    onBlur={smsOtp2BlurHandler}
                    onKeyDown={(e) => {
                      if (
                        e.keyCode === "69" ||
                        (e.target.value.length === 1 && e.keyCode !== 8)
                      )
                        e.preventDefault();
                      if (e.target.value.length === 0 && e.keyCode === 8) {
                        PhonefirstOTPField.current.focus();
                        e.preventDefault();
                      }
                    }}
                    onKeyUp={(e) =>
                      e.keyCode === 37
                        ? PhonefirstOTPField.current.focus()
                        : (e.keyCode >= 48 && e.keyCode <= 57) ||
                          (e.keyCode >= 96 && e.keyCode <= 105) ||
                          e.keyCode === 39
                        ? PhonethirdOTPField.current.focus()
                        : false
                    }
                    ref={PhonesecondOTPField}
                  />
                </li>
                <li className="single-code">
                  <input
                    type="number"
                    placeholder="0"
                    className="code-input digit-3"
                    minLength="1"
                    maxLength="1"
                    data-previous="digit-2"
                    data-next="digit-4"
                    value={smsOtpNum3}
                    onChange={smsOtp3ChangeHandler}
                    onBlur={smsOtp3BlurHandler}
                    onKeyDown={(e) => {
                      if (
                        e.keyCode == "69" ||
                        (e.target.value.length === 1 && e.keyCode != 8)
                      )
                        e.preventDefault();
                      if (e.target.value.length == 0 && e.keyCode == 8) {
                        PhonesecondOTPField.current.focus();
                        e.preventDefault();
                      }
                    }}
                    onKeyUp={(e) =>
                      e.keyCode == 37
                        ? PhonesecondOTPField.current.focus()
                        : (e.keyCode >= 48 && e.keyCode <= 57) ||
                          (e.keyCode >= 96 && e.keyCode <= 105) ||
                          e.keyCode == 39
                        ? PhonefourthOTPField.current.focus()
                        : false
                    }
                    ref={PhonethirdOTPField}
                  />
                </li>
                <li className="single-code">
                  <input
                    type="number"
                    placeholder="0"
                    className="code-input digit-4"
                    minLength="1"
                    maxLength="1"
                    data-previous="digit-3"
                    data-next="digit-5"
                    value={smsOtpNum4}
                    onChange={smsOtp4ChangeHandler}
                    onBlur={smsOtp4BlurHandler}
                    onKeyDown={(e) => {
                      if (
                        e.keyCode == "69" ||
                        (e.target.value.length === 1 && e.keyCode != 8)
                      )
                        e.preventDefault();
                      if (e.target.value.length == 0 && e.keyCode == 8) {
                        PhonethirdOTPField.current.focus();
                        e.preventDefault();
                      }
                    }}
                    onKeyUp={(e) =>
                      e.keyCode == 37
                        ? PhonethirdOTPField.current.focus()
                        : (e.keyCode >= 48 && e.keyCode <= 57) ||
                          (e.keyCode >= 96 && e.keyCode <= 105) ||
                          e.keyCode == 39
                        ? PhonefifthOTPField.current.focus()
                        : false
                    }
                    ref={PhonefourthOTPField}
                  />
                </li>
                <li className="single-code">
                  <input
                    type="number"
                    placeholder="0"
                    className="code-input digit-5"
                    minLength="1"
                    maxLength="1"
                    data-previous="digit-4"
                    data-next="digit-6"
                    value={smsOtpNum5}
                    onChange={smsOtp5ChangeHandler}
                    onBlur={smsOtp5BlurHandler}
                    onKeyDown={(e) => {
                      if (
                        e.keyCode == "69" ||
                        (e.target.value.length === 1 && e.keyCode != 8)
                      )
                        e.preventDefault();
                      if (e.target.value.length == 0 && e.keyCode == 8) {
                        PhonefourthOTPField.current.focus();
                        e.preventDefault();
                      }
                    }}
                    onKeyUp={(e) =>
                      e.keyCode == 37
                        ? PhonefourthOTPField.current.focus()
                        : (e.keyCode >= 48 && e.keyCode <= 57) ||
                          (e.keyCode >= 96 && e.keyCode <= 105) ||
                          e.keyCode == 39
                        ? PhonesixthOTPField.current.focus()
                        : false
                    }
                    ref={PhonefifthOTPField}
                  />
                </li>
                <li className="single-code">
                  <input
                    type="number"
                    placeholder="0"
                    className="code-input digit-6"
                    minLength="1"
                    maxLength="1"
                    data-previous="digit-5"
                    value={smsOtpNum6}
                    onChange={smsOtp6ChangeHandler}
                    onBlur={smsOtp6BlurHandler}
                    onKeyDown={(e) => {
                      if (
                        e.keyCode == "69" ||
                        (e.target.value.length === 1 && e.keyCode != 8)
                      )
                        e.preventDefault();
                      if (e.target.value.length == 0 && e.keyCode == 8) {
                        PhonefifthOTPField.current.focus();
                        e.preventDefault();
                      }
                    }}
                    onKeyUp={(e) =>
                      e.keyCode == 37
                        ? PhonefifthOTPField.current.focus()
                        : false
                    }
                    ref={PhonesixthOTPField}
                  />
                </li>
              </ul>
              
            </div>
          )} */}
            {error && <p className="error-text">{error}</p>}
            <div className="code-action">
              <button
                className="btn btn-primary-fill btn-14-24"
                type="submit"
                disabled={disabledBtn}
              >
                CONFIRM
                <img
                  className="arrow-right"
                  src={ArrowRight}
                  alt="right-arrow"
                />
              </button>
              <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </form>
          {/* {loading && <Loader2 />} */}
        </div>
      </div>
    </Fragment>
  );
};

export default VerifyOTP;
