import { createSlice } from "@reduxjs/toolkit";

const initialFilterState = {
  filterCityList: [],
  filterLocationsList: [],
  filterText: "",
  searchPastInvoicesTestHistoryText: "",
  searchEquipmentsText: "",
  searchGroupTestHistoryText: "",
  searchPatientHistoryText: "",
  searchCorporateCommunityText: "",
  searchCorporateUserText: "",
  searchCommunityProviderText: "",
  searchCommunityPatientText: "",
  searchCommunityUserText: "",
  searchCommunityIphoneText: "",
  searchCommunityBilleeText: "",
  searchCommunityTestText: "",
  searchUpcomingText: "",
  searchScheduleText: "",
  searchAdminUserText: "",
  searchExternalProviderText: "",
  searchPrepaidPatientsText: "",
  searchRegUserText: "",
  searchTestsBillingText: "",
  searchInvoicesBillingText: "",
  searchCreditsBillingText: "",
  searchAuditLogText: "",
  filteredCountry: [],
  filteredCity: [],
  filteredState: [],
  filteredZip: [],
  filteredDobFrom: [],
  filteredDobTo: [],
  filterBillingStatus: [],
  filterPatients: "ALL",
  filterBillingCommunities: "ALL",
  testToBillCommunities: "ALL",
  pastInvoicesCommunities: "ALL",
  creditHistoryCommunities: "ALL",
  filterProviders: [],
  filterCommunities: [],
  filterLogType: "",
};

const filterSlicer = createSlice({
  name: "filters",
  initialState: initialFilterState,
  reducers: {
    reset: () => {
      return { ...initialFilterState };
    },

    setFilterBillingStatus(state, action) {
    
      state.filterBillingStatus = action.payload;
    },

    setFilterPatients(state, action) {
      state.filterPatients = action.payload;
    },

    setFilterBillingCommunities(state, action) {
      state.filterBillingCommunities = action.payload;
    },
    setTestToBillCommunities(state, action) {
      state.testToBillCommunities = action.payload;
    },
    setPastInvoicesCommunities(state, action) {
      state.pastInvoicesCommunities = action.payload;
    },
    setCreditHistoryCommunities(state, action) {
      state.creditHistoryCommunities = action.payload;
    },

    setFilterProviders(state, action) {
      state.filterProviders = action.payload;
    },

    setFilterCommunities(state, action) {
      state.filterCommunities = action.payload;
    },

    setFilterLogType(state, action) {
      state.filterLogType = action.payload;
    },

    setCityList(state, action) {
      state.filterCityList = action.payload;
    },

    setLocationsList(state, action) {
      state.filterLocationsList = action.payload;
    },

    setFilterText(state, action) {
      state.filterText = action.payload;
    },

    setSearchPastInvoicesTestHistoryText(state, action) {
      state.searchPastInvoicesTestHistoryText = action.payload;
    },

    setSearchEquipmentsText(state, action) {
      state.searchEquipmentsText = action.payload;
    },

    setSearchGroupTestHistoryText(state, action) {
      state.searchGroupTestHistoryText = action.payload;
    },
    setSearchPatientHistoryText(state, action) {
      state.searchPatientHistoryText = action.payload;
    },
    setSearchCorporateCommunityText(state, action) {
      state.searchCorporateCommunityText = action.payload;
    },
    setSearchCorporateUserText(state, action) {
      state.searchCorporateUserText = action.payload;
    },

    setSearchCommunityProviderText(state, action) {
      state.searchCommunityProviderText = action.payload;
    },
    setSearchCommunityPatientText(state, action) {
      state.searchCommunityPatientText = action.payload;
    },
    setSearchCommunityUserText(state, action) {
      state.searchCommunityUserText = action.payload;
    },
    setSearchCommunityIphoneText(state, action) {
      state.searchCommunityIphoneText = action.payload;
    },
    setSearchCommunityBilleeText(state, action) {
      state.searchCommunityBilleeText = action.payload;
    },
    setSearchCommunityTestText(state, action) {
      state.searchCommunityTestText = action.payload;
    },

    setSearchUpcomingText(state, action) {
      state.searchUpcomingText = action.payload;
    },
    setSearchScheduleText(state, action) {
      state.searchScheduleText = action.payload;
    },
    setSearchAdminUserText(state, action) {
      state.searchAdminUserText = action.payload;
    },
    setSearchExternalProviderText(state, action) {
      state.searchExternalProviderText = action.payload;
    },
    setSearchPrepaidPatientsText(state, action) {
      state.searchPrepaidPatientsText = action.payload;
    },
    setSearchRegUserText(state, action) {
      state.searchRegUserText = action.payload;
    },
    setSearchTestsBillingText(state, action) {
      state.searchTestsBillingText = action.payload;
    },
    setSearchInvoicesBillingText(state, action) {
      state.searchInvoicesBillingText = action.payload;
    },
    setSearchCreditsBillingText(state, action) {
      state.searchCreditsBillingText = action.payload;
    },
    setSearchAuditLogText(state, action) {
      state.searchAuditLogText = action.payload;
    },

    setFilteredCountry(state, action) {
      state.filteredCountry = action.payload;
    },

    setFilteredCity(state, action) {
      state.filteredCity = action.payload;
    },

    setFilteredsState(state, action) {
      state.filteredState = action.payload;
    },

    setFilteredZip(state, action) {
      state.filteredZip = action.payload;
    },
    setFilteredDobFrom(state, action) {
      state.filteredDobFrom = action.payload;
    },
    setFilteredDobTo(state, action) {
      state.filteredDobTo = action.payload;
    },
  },
});

export const filterActions = filterSlicer.actions;
export default filterSlicer.reducer;
