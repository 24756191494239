import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { providerActions } from "../../store/provider";
import { sidebarActions } from "../../store/sidebar";
import TableComponent from "../UI/Table/TableData";
import Loader from "../UI/loader/loader";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getWithExpiry } from "../utils/helper";
import useQueryString from "../../hooks/useQueryString";
const Provider = () => {
  const providerTableHeadings = [
    "First Name",
    "Last Name",
    "Communities",
    "Email",
    "Telephone",
  ];
  const dispatch = useDispatch();
  const urlParams = useQueryString();
  const ProviderPageNumber = useSelector(
    (state) => state.provider.providerCurrentPage
  );

  const [loading, setLoading] = useState(true);
  const [otherLoader, setOtherLoader] = useState(false);
  const [dataFlag, setDataFlag] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const token = "Bearer " + getWithExpiry("expiry_token");
  const ProviderData = useSelector((state) => state.provider.providerData);
  const totalRecords = useSelector(
    (state) => state.provider.providerTotalRecords
  );
  const providerApiUrl = useSelector((state) => state.provider.providerApiUrl);
  useEffect(() => {
    if (!showToast) {
      setLoading(true);
      dispatch(sidebarActions.setSelectedNav("Providers"));
    }

    let urlKeys = [...urlParams.keys()];
    let urlValues = [...urlParams.values()];

    let query = "";
    if (!providerApiUrl && urlKeys.length > 0) {
      query = "?";
      for (let i = 0; i < urlKeys.length; i++) {
        if (urlKeys[i] !== "page") {
          query = query + urlKeys[i] + "=" + urlValues[i] + "&";
        }
      }
    } else {
      query = providerApiUrl ? providerApiUrl + "&" : "?";
    }

    let pageNumber = urlParams.has("page")
      ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
        ? urlParams.get("page")
        : ProviderPageNumber
      : ProviderPageNumber;

    const Url = `${process.env.REACT_APP_URL_PATH}/community/provider_list/${query}page=${pageNumber}`;

    query = query.replace("&&", "&");
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/community/provider_list/${query}page=${pageNumber}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        dispatch(
          providerActions.setProviderData(response.data.data.provider_list)
        );
        dispatch(
          providerActions.setProviderCommunites(response.data.data.communities)
        );
        dispatch(
          providerActions.setProviderPageNumber(
            response.data.data.provider_pages
          )
        );
        dispatch(
          providerActions.setProviderTotalRecords(
            response.data.data.total_records
          )
        );

        if (ProviderPageNumber > response.data.data.provider_pages) {
          dispatch(
            providerActions.setProviderCurrentPage(
              response.data.data.provider_pages
            )
          );
        }
        setLoading(false);
        setOtherLoader(false);
      })
      .catch((error) => {
        setLoading(false);
        setOtherLoader(false);
      });
  }, [dataFlag]);

  return (
    <div className="corporate-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <>
          <TableComponent
            tableHeadings={providerTableHeadings}
            data={ProviderData}
            totalRecords={totalRecords}
            heading="Internal Providers"
            type="Provider"
            isAdd={true}
            isImport={true}
            setDataFlag={setDataFlag}
            setShowToast={setShowToast}
            otherLoader={otherLoader}
            setOtherLoader={setOtherLoader}
          />

         
        </>
      )}
    </div>
  );
};

export default Provider;
