import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import Select from "react-select";
import axios from "axios";

import "./FiltersModal.scss";
// imports image

import CloseModal from "../../../../assets/close-outline.svg";
import DisabledIcon from "../../../../assets/disabled-row.svg";

function FilterModal(props) {
  const { isShowModal, hideModal } = props;
  const history = useHistory();
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [objectKeys, setObjectKeys] = useState([]);

  let _objectKeys = [];
  const setKeys = async (object) => {
    if (object && object.filters) {
      Object.keys(object.filters).map((key) => {
        _objectKeys.push(key);
      });
      setObjectKeys(_objectKeys);
    }
  };

  const token = localStorage.getItem("token");

  const fetchData = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_URL_PATH}/corporate/corporate_filters/`;
      const response = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + token, //the token holds the user token
        },
      });
      const resData = await response.data.data;
      await setFilterData(resData);
      await setKeys(resData);
      await setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const SelectComponent = (filterData, index) => {
    return (
      <div className="field-wrapper">
        <label htmlFor="" className="label">
          {objectKeys[index]}
        </label>
        <Select
          className="basic-multi-select"
          classNamePrefix="select"
          isMulti
          id={objectKeys[index]}
          name={objectKeys[index]}
        />
      </div>
    );
  };

  useEffect(() => {
    fetchData();
   
  }, []);

  const filterSubmissionHandler = () => {
    history.replace("/corporates?search=something");
    hideModal();
  };

  if (loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="filter-modal-wrapper">
        <Modal
          className="filter-modal"
          show={isShowModal}
          onHide={hideModal}
          backdrop="static"
          keyboard={false}
          centered
          scrollable
        >
          <Modal.Header className="filter-modal-header">
            <h1 className="modal-header-title">Filters</h1>
            <span className="close-icon-modal " onClick={hideModal}>
              <img src={CloseModal} alt="" />
            </span>
          </Modal.Header>
          <Modal.Body className="filter-modal-body">
            {/* {
            filterData && filterData.filters && filterData.filters.map((filterData, index) => (
              <SelectComponent filter={filterData} index={index} />
            ))
          } */}
            <div className="field-wrapper">
              <label htmlFor="" className="label">
                Location
              </label>
              <Select
                className="basic-multi-select"
                classNamePrefix="select"
                isMulti
                id="community_id"
                name="community_id"
              />
            </div>
            <div className="field-wrapper">
              <label htmlFor="" className="label">
                Scheduled
              </label>
              <Select
                className="basic-multi-select"
                classNamePrefix="select"
                isMulti
                id="community_id"
                name="community_id"
              />
            </div>
            <div className="field-wrapper">
              <label htmlFor="" className="label">
                Status
              </label>
              <Select
                className="basic-multi-select"
                classNamePrefix="select"
                isMulti
                id="community_id"
                name="community_id"
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="filter-footer-modal">
            <button
              className="btn btn-primary-fill btn-primary-16"
              onClick={filterSubmissionHandler}
            >
              Apply Filters
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default FilterModal;
