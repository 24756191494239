import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import useInput from "../../../../hooks/use-input";
import Loader2 from "../../../UI/loader2/loader2";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "react-phone-number-input/input";
import axios from "axios";
import { getWithExpiry } from "../../../utils/helper";
import "./AddUserModalCommunity.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import images
import CloseModal from "../../../../assets/close-outline.svg";
import DisabledIcon from "../../../../assets/disabled-row.svg";
const isNotEmpty = (value) => value.trim() !== "";
const isEmailValid = (value) => value.includes("@");

function AddUserModalCommunity(props) {
  const { isShowModal, hideModal, type, setDataFlag, setShowToast } = props;
  // Creating State
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    telephone_number: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const [phoneValue, setPhoneValue] = useState("");

  //End Creating State

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [addBtnDisabled, setAddBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [showCorporates, setShowCorporates] = useState(false);
  const communityList = useSelector((state) => state.corporate.communityList);
  const communityUserRoles = useSelector(
    (state) => state.community.communityUserRoles
  );
  const enabledCommunities = communityList.filter(
    (community) => community.is_enabled === true
  );

  const params = useParams();
  const { communityID: communityId } = params;

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) => {
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const token = "Bearer " + getWithExpiry("expiry_token");

  let url;

  if (type === "Corporate") {
    url = `${process.env.REACT_APP_URL_PATH}/corporate/add_corporate_user/`;
  } else if (type === "Community") {
    url = `${process.env.REACT_APP_URL_PATH}/community/add_community_user/`;
  }
  const addUserSubmissionHandler = (event) => {
    event.preventDefault();
    setLoading(true);

    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");

    setAddBtnDisabled(true);

    // create form data here
    //const form = document.querySelector(".add-community-user-form");
    //const fd = new FormData(form);

    // if (role === "Corporate HQ Administrator") {
    //   fd.append("corporate_id", corporateId)
    // }
    // if (role === "Community Admin") {

    const fd = new FormData();
    fd.append("first_name", user.first_name);
    fd.append("last_name", user.last_name);
    fd.append("email", user.email);

    //let phoneVal = user.telephone_number.replace(/\s+/g, "");
    if (phoneValue) {
      fd.append("telephone_number", "+" + phoneValue);
    }

    fd.append("community_id", communityId);
    fd.append("role", role);

    // make http call
    axios({
      method: "post",
      url: url,
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      })
      .catch((error) => {
        setLoading(false);
        setAddBtnDisabled(false);
        const resData = error.response.data;
        if (resData.message === "Field Errors") {
          if (
            resData.data["user_form"] &&
            "first_name" in resData.data["user_form"]
          ) {
            setFirstNameError(resData.data["user_form"]["first_name"][0]);
          }
          if (
            resData.data["user_form"] &&
            "last_name" in resData.data["user_form"]
          ) {
            setLastNameError(resData.data["user_form"]["last_name"][0]);
          }

          if (
            resData.data["user_form"] &&
            "email" in resData.data["user_form"]
          ) {
            setEmailError(resData.data["user_form"]["email"][0]);
          }

          if (
            resData.data["phone_form"] &&
            "telephone_number" in resData.data["phone_form"]
          ) {
            setPhoneError(resData.data["phone_form"]["telephone_number"][0]);
          }
        } else {
          notifyError(resData.message);
        }
      });
  };

  const roleChangeHandler = (e) => {
    setRole(e.target.value);
  };

  return (
    <div className="action-modal-wrapper">
      <Modal
        className="comunitie-modal modal-loader"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header
          className="comubitie-modal-header"
          style={{ padding: "1rem 0rem" }}
        >
          <h1 className="modal-header-title">Add User</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="comunitie-modal-body">
          <form
            
            method="post"
            className="add-community-user-form"
            onSubmit={addUserSubmissionHandler}
          >
            <div className="communities-fields">
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="first_name" className="label">
                      First Name *
                    </label>
                    {/* <input type="text" className="custom-input" /> */}
                    <input
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="First Name"
                      className="custom-input"
                      value={user.first_name}
                      onChange={handleChange}
                      required
                    />
                    {firstNameError && (
                      <p className="error-text">{firstNameError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="last_name" className="label">
                      Last Name *
                    </label>
                    <input
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Last Name"
                      className="custom-input"
                      value={user.last_name}
                      onChange={handleChange}
                      required
                    />
                    {lastNameError && (
                      <p className="error-text">{lastNameError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="email" className="label">
                      Email *
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="custom-input"
                      placeholder="Email"
                      value={user.email}
                      onChange={handleChange}
                    />
                    {emailError && <p className="error-text">{emailError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="telephone_number" className="label">
                      Telephone *
                    </label>
                    {/* <input
                      type="text"
                      id="telephone_number"
                      name="telephone_number"
                      className="custom-input"
                      placeholder="Telephone"
                      value={user.telephone_number}
                      onChange={(e) => {
                        const { name, value } = e.target;

                        const regex = /^([\s+0-9])+$/i;
                        if (value === "") {
                          // case to remove whole highlighted text
                          setUser({ ...user, [name]: value });
                          return;
                        }
                        if (regex.test(value) === false) return;

                        if (value.length === 1 && value === "+") {
                          // case to remove + sign when last number is removed
                          setUser({ ...user, [name]: "" });
                        } else if (value.length >= 1 && value[0] !== "+") {
                          // case to append + sign with first number
                          setUser({ ...user, [name]: "+" + value });
                        } else {
                          // case to append other numbers
                          setUser({ ...user, [name]: value });
                        }
                      }}
                      required
                    /> */}
                    <PhoneInput
                      country={"us"}
                      value={phoneValue}
                      onChange={setPhoneValue}
                      inputProps={{
                        required: true,
                      }}
                    />
                    {phoneError && <p className="error-text">{phoneError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="role" className="label">
                      Role *
                    </label>
                    <select
                      name="role"
                      id="role"
                      className="custom-select-modal"
                      onChange={roleChangeHandler}
                      value={role}
                    >
                      {role === "" && <option value="">Select a Role</option>}
                      {communityUserRoles.map((name) => (
                        <option value={name}>{name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16 text-capitalize"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            // type="submit"
            onClick={addUserSubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={addBtnDisabled}
          >
            Add
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

export default AddUserModalCommunity;
