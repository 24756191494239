import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CloseModal from "../../../../assets/close-outline.svg";
import { getWithExpiry } from "../../../utils/helper";
import axios from "axios";
import Loader2 from "../../loader2/loader2";
import { toast } from "react-toastify";

function AddAdminModel(props) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const token = "Bearer " + getWithExpiry("expiry_token");

  const addAdminHandler = async (e) => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!regex.test(email)) {
      setError("Email is not valid");
      return;
    }
    setLoading(true);
    axios.post(`${process.env.REACT_APP_URL_PATH}/user/enable_super_admin/`, { email }, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        toast.success(res?.data?.message || 'Something went wrong', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
        setEmail("");
        props.handleClose();
      })
      .catch(error => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      });
  };
  return (
    <div className="filter-modal-wrapper ">
      <a className="dropdown-item" onClick={props.handleShow}>
        Enable Admin
      </a>

      <Modal
        className="filter-modal"
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
      >
        <Modal.Header className="filter-modal-header">
          <h1 className="modal-header-title">Enable Super Admin</h1>
          <span className="close-icon-modal " onClick={props.handleClose}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="filter-modal-body">
          <div className="field-wrapper">
            <label htmlFor="" className="label">
              Email *
            </label>
            <input
              type="email"
              className="custom-input"
              onChange={(e) => {
                const { name, value } = e.target;
                if (value === "") {
                  setError("Required");
                } else {
                  setError("");
                }

                setEmail(value);
              }}
              value={email}
              required
            />
            {error && <p className="error-text">{error}</p>}
          </div>
        </Modal.Body>
        <Modal.Footer className="user-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16"
            onClick={props.handleClose}
          >
            Close
          </button>
          <button
            onClick={addAdminHandler}
            type="submit"
            className="btn btn-primary-fill btn-primary-16"
            disabled={!email || error}
          >
            Add
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

export default AddAdminModel;
