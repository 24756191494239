import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImportCorporateModal from "../../Corporate/Modals/ImportCorporateModal/ImportCorporateModal";
import FiltersModal from "../../Corporate/Modals/FiltersModal/FiltersModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIphoneModal from "../Modals/AddIphoneModal/AddIphoneModal";
import { useParams, useHistory, Link } from "react-router-dom";
import TableEditBody from "./TableEditBody";
import FilterClose from "../../../assets/filter-close.svg";
import AddUserModalCommunity from "../Modals/AddUserModalCommunity/AddUserModalCommunity";
import axios from "axios";
import AddPatient from "../../Patient/Modals/AddPatient";
import AddDevice from "../../Devices/Modals/AddDevice";
import { communityActions } from "../../../store/community";
import { rowOrderActions } from "../../../store/orders";
import AddBilleeModalCommunity from "../Modals/AddBilleeModalCommunity/AddBilleeModalCommunity";
import Pagination from "../../UI/Pagination/Pagination";
import AuditLogsModal from "../../../components/General/AuditLogModal/AuditLogsModal.Component";
import { Tab } from "react-bootstrap";
import Filter_Icon from "../../../assets/filter-icon.svg";
import Select from "react-select";
import CommunityPatientFilter from "../../Corporate/Modals/FiltersModal/CommunityPatientFilter";
import CommunityUserFilter from "../../Corporate/Modals/FiltersModal/CommunityUserFilter";
import GroupTestHistoryFilter from "../../Corporate/Modals/FiltersModal/GroupTestHistoryFilter";
import { getWithExpiry } from "../../utils/helper";
import SearchInput from "../../General/filters/SearchInput.Component";
import FilterButton from "../../General/filters/FilterButton.Component";
import useQueryString from "../../../hooks/useQueryString";
import AddProvider from "../../Provider/Modals/AddProvider";
import {
  removeEmptyParams,
  setOrderAndIcon,
} from "../../../services/helpers/sorting.hepler";
import Loader from "../../UI/loader/loader";
import { filterActions } from "../../../store/filter";

const TableDataEdit = (props) => {
  const {
    tableHeadings,
    data,
    allPatients,
    type,
    dataType,
    communityId,
    communityName,
    heading,
    totalRecords,
    setDataFlag,
    setShowToast,
    otherLoader,
    setOtherLoader,
    // isImport,
    // isAdd,
    billeesList,
    TabKey,
  } = props;

  const [addTestModalOpen, setAddTestModalOpen] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);

  const [importCorpModalOpen, setImportCorpModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const [billeeModalOpen, setBilleeModalOpen] = useState(false);
  const showAddBilleeModal = () => setBilleeModalOpen(true);
  const hideAddBilleeModal = () => setBilleeModalOpen(false);

  const [providerModalOpen, setProviderModalOpen] = useState(false);
  const showAddProviderModal = () => setProviderModalOpen(true);
  const hideAddProviderModal = () => setProviderModalOpen(false);

  const [deviceModalOpen, setDeviceModalOpen] = useState(false);
  const showAddDeviceModal = () => setDeviceModalOpen(true);
  const hideAddDeviceModal = () => setDeviceModalOpen(false);

  const [addPatientModalOpen, setAddPatientModalOpen] = useState(false);
  const showAddPatientModal = () => setAddPatientModalOpen(true);
  const hideAddPatientModal = () => setAddPatientModalOpen(false);

  const token = "Bearer " + getWithExpiry("expiry_token");

  const showAddTestModal = () => setAddTestModalOpen(true);
  const hideAddTestModal = () => setAddTestModalOpen(false);

  const showAddUserModal = () => setAddUserModalOpen(true);
  const hideAddUserModal = () => setAddUserModalOpen(false);

  const showImportCorpModal = () => setImportCorpModalOpen(true);
  const hideImportCorpModal = () => setImportCorpModalOpen(false);

  const showFilterModal = () => setFilterModalOpen(true);
  const hideFilterModal = () => setFilterModalOpen(false);

  const [auditLogModalOpen, setAuditLogModalOpen] = useState(false);
  const showAuditLogModal = () => setAuditLogModalOpen(true);
  const hideAuditLogModal = () => setAuditLogModalOpen(false);

  const communityList = useSelector((state) => state.corporate.communityList);
  const userList = useSelector((state) => state.corporate.userList);
  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);
  const parm = useParams();
  const dispatch = useDispatch();
  const urlParams = useQueryString();
  const history = useHistory();

  const [dateRange, setDateRange] = useState([null, null]);
  const [testDateRange, setTestDateRange] = useState([null, null]);

  // move to separate file later
  const iphoneFormData = {
    iphone_number: "",
    description: "",
  };

  const userFormData = {
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    role: "",
  };
  const billeeFormData = {
    company: "",
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    address_01: "",
    address_02: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  };

  const patientFormData = {
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    telephone: "",
    status: "",
  };

  const providerFormData = {
    title: "",
    suffix: "",
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    billeeCompany: "",
    billeeFirstName: "",
    billeeLastName: "",
    billeeEmail: "",
    billeeTelephone: "",
    billeeAddress_01: "",
    billeeAddress_02: "",
    billeeCity: "",
    billeeState: "",
    billeeZip: "",
    billeeCountry: "",
    community: "",
    can_bill_community: "",
  };
  const showAddModalAccToType = () => {
    if (dataType === "iphone") {
      showAddTestModal();
    } else if (dataType === "user") {
      showAddUserModal();
    } else if (dataType === "billee") {
      showAddBilleeModal();
    } else if (dataType === "patient") {
      showAddPatientModal();
    } else if (dataType === "provider") {
      showAddProviderModal();
    } else if (dataType === "devices") {
      showAddDeviceModal();
    }
  };
  function importFormData() {
    if (type === "Community") {
      if (dataType === "iphone") {
        return iphoneFormData;
      } else if (dataType === "user") {
        return userFormData;
      } else if (dataType === "billee") {
        return billeeFormData;
      } else if (dataType === "patient") {
        return patientFormData;
      } else if (dataType === "provider") {
        return providerFormData;
      }
    }
  }
  const [headingName, setHeadingName] = useState("");
  const [search, setSearch] = useState(false);
  const handleSearchChange = (e) => {
    const { value, name } = e.target;

    if (type === "Community" && dataType === "patient") {
      dispatch(filterActions.setSearchCommunityPatientText(value));
    } else if (type === "Community" && dataType === "user") {
      dispatch(filterActions.setSearchCommunityUserText(value));
    } else if (type === "Community" && dataType === "iphone") {
      dispatch(filterActions.setSearchCommunityIphoneText(value));
    } else if (type === "Community" && dataType === "billee") {
      dispatch(filterActions.setSearchCommunityBilleeText(value));
    } else if (type === "Community" && dataType === "provider") {
      dispatch(filterActions.setSearchCommunityProviderText(value));
    } else if (type === "Community" && dataType === "history") {
      dispatch(filterActions.setSearchCommunityTestText(value));
    }
  };
  const communityPatietOrderMapping = {
    "First Name": "first_name",
    "Last Name": "last_name",
    "Date of Birth": "dob",
    Email: "email",
    Telephone: "telephone_number",
    Status: "status",
  };
  const communityUserOrderMapping = {
    "First Name": "first_name",
    "Last Name": "last_name",
    Email: "email",
    Telephone: "telephone_number",
    Role: "role",
  };
  const communityIphoneOrderMapping = {
    "iPhone Number": "iphone_number",
    Description: "iphone_description",
  };
  const communityBilleeOrderMapping = {
    Company: "company",
    "First Name": "first_name",
    "Last Name": "last_name",
    Email: "email",
    Telephone: "telephone_number",
  };
  const communityProviderOrderMapping = {
    "First Name": "first_name",
    "Last Name": "last_name",
    Email: "email",
    Telephone: "telephone_number",
  };
  const communityTestOrderMapping = {
    Name: "name",
    "Created Date": "created_date",
    "Expiry Date": "expiry_date",
    "No. of Tests": "tests",
    "Test Location": "location",
    Status: "status",
  };

  const deviceBillingOrderMapping = {
    Name: "name",
    Price: "price",
    Description: "description",
    Billee: "bllee_name",
    "Equipment Id": "equipment_id",
  };

  const orderMapping = {
    patient: communityPatietOrderMapping,
    user: communityUserOrderMapping,
    iphone: communityIphoneOrderMapping,
    billee: communityBilleeOrderMapping,
    provider: communityProviderOrderMapping,
    history: communityTestOrderMapping,
    devices: deviceBillingOrderMapping,
  };

  const urlMapping = {
    patient: parm.corporateID
      ? `/edit/corporates/${parm.corporateID}/community/${parm.communityID}`
      : `/edit/communities/${parm.communityID}`,
    user: parm.corporateID
      ? `/edit/corporates/${parm.corporateID}/community/${parm.communityID}`
      : `/edit/communities/${parm.communityID}`,
    iphone: parm.corporateID
      ? `/edit/corporates/${parm.corporateID}/community/${parm.communityID}`
      : `/edit/communities/${parm.communityID}`,
    billee: parm.corporateID
      ? `/edit/corporates/${parm.corporateID}/community/${parm.communityID}`
      : `/edit/communities/${parm.communityID}`,
    provider: parm.corporateID
      ? `/edit/corporates/${parm.corporateID}/community/${parm.communityID}`
      : `/edit/communities/${parm.communityID}`,
    history: parm.corporateID
      ? `/edit/corporates/${parm.corporateID}/community/${parm.communityID}`
      : `/edit/communities/${parm.communityID}`,
    devices: parm.corporateID
      ? `/edit/corporates/${parm.corporateID}/community/${parm.communityID}`
      : `/edit/communities/${parm.communityID}`,
  };
  const keyMapping = {
    patient: "tab_key=patient&",
    user: "tab_key=user&",
    iphone: "tab_key=iphone&",
    billee: "tab_key=billee&",
    provider: "tab_key=provider&",
    history: "tab_key=group_test&",
    devices: "tab_key=devices&",
  };
  const searchCommunityPatientText = useSelector(
    (state) => state.filter.searchCommunityPatientText
  );
  const searchCommunityUserText = useSelector(
    (state) => state.filter.searchCommunityUserText
  );
  const searchCommunityIphoneText = useSelector(
    (state) => state.filter.searchCommunityIphoneText
  );
  const searchCommunityBilleeText = useSelector(
    (state) => state.filter.searchCommunityBilleeText
  );
  const searchCommunityProviderText = useSelector(
    (state) => state.filter.searchCommunityProviderText
  );
  const searchCommunityTestText = useSelector(
    (state) => state.filter.searchCommunityTestText
  );

  const groupHistoryCreatedDateFrom = useSelector(
    (state) => state.community.groupHistoryCreatedDateFrom
  );
  const groupHistoryCreatedDateTo = useSelector(
    (state) => state.community.groupHistoryCreatedDateTo
  );
  const groupHistoryExpiryDateFrom = useSelector(
    (state) => state.community.groupHistoryExpiryDateFrom
  );
  const groupHistoryExpiryDateTo = useSelector(
    (state) => state.community.groupHistoryExpiryDateTo
  );
  const groupHistoryStatus = useSelector(
    (state) => state.community.groupHistoryStatus
  );

  const rowOrderStateCommunityPatient = useSelector(
    (state) => state.rowOrder.rowOrderCommunityPatient
  );
  const rowOrderStateCommunityUser = useSelector(
    (state) => state.rowOrder.rowOrderCommunityUser
  );
  const rowOrderStateCommunityIphone = useSelector(
    (state) => state.rowOrder.rowOrderCommunityIphone
  );
  const rowOrderStateCommunityBillee = useSelector(
    (state) => state.rowOrder.rowOrderCommunityBillee
  );
  const rowOrderStateCommunityProvider = useSelector(
    (state) => state.rowOrder.rowOrderCommunityProvider
  );
  const rowOrderStateCommunityTest = useSelector(
    (state) => state.rowOrder.rowOrderCommunityTest
  );
  const rowOrderCommunityDevicesList = useSelector(
    (state) => state.rowOrder.rowOrderDevicesList
  );

  const patientCommunityOrderQuery = useSelector(
    (state) => state.community.patientCommunityOrderQuery
  );
  const userCommunityOrderQuery = useSelector(
    (state) => state.community.userCommunityOrderQuery
  );
  const iphoneCommunityOrderQuery = useSelector(
    (state) => state.community.iphoneCommunityOrderQuery
  );
  const billeeCommunityOrderQuery = useSelector(
    (state) => state.community.billeeCommunityOrderQuery
  );
  const providerCommunityOrderQuery = useSelector(
    (state) => state.community.providerCommunityOrderQuery
  );
  const testCommunityOrderQuery = useSelector(
    (state) => state.community.testCommunityOrderQuery
  );
  const devicesCommunityOrderQuery = useSelector(
    (state) => state.community.devicesCommunityOrderQuery
  );

  const rowOrderMapping = {
    patient: rowOrderStateCommunityPatient,
    user: rowOrderStateCommunityUser,
    iphone: rowOrderStateCommunityIphone,
    billee: rowOrderStateCommunityBillee,
    provider: rowOrderStateCommunityProvider,
    history: rowOrderStateCommunityTest,
    devices: rowOrderCommunityDevicesList,
  };

  //filters
  const communityPatientDobFrom = useSelector(
    (state) => state.community.communityPatientDobFrom
  );
  const communityPatientDobTo = useSelector(
    (state) => state.community.communityPatientDobFrom
  );

  const communityUserFilteredRole = useSelector(
    (state) => state.community.communityUserFilteredRole
  );
  const [orderState, setOrderState] = useState(null);
  const [prefix, setPrefix] = useState("");

  useEffect(() => {
    updateUrl();
  }, [orderState]);

  const buildRowOrderQuery = () => {
    let newOrderQuery = "";
    if (dataType && dataType === "patient") {
      newOrderQuery = patientCommunityOrderQuery.slice();
    } else if (dataType && dataType === "user") {
      newOrderQuery = userCommunityOrderQuery.slice();
    } else if (dataType && dataType === "iphone") {
      newOrderQuery = iphoneCommunityOrderQuery.slice();
    } else if (dataType && dataType === "billee") {
      newOrderQuery = billeeCommunityOrderQuery.slice();
    } else if (dataType && dataType === "provider") {
      newOrderQuery = providerCommunityOrderQuery.slice();
    } else if (dataType && dataType === "history") {
      newOrderQuery = testCommunityOrderQuery.slice();
    } else if (dataType && dataType === "devices") {
      newOrderQuery = devicesCommunityOrderQuery.slice();
    }
    let mappingHeaderName, rowOrderHeaderName;
    if (type === "Community") {
      if (dataType === "patient") {
        mappingHeaderName = communityPatietOrderMapping[headingName];
        rowOrderHeaderName =
          rowOrderStateCommunityPatient[
            communityPatietOrderMapping[headingName]
          ];
      } else if (dataType === "user") {
        mappingHeaderName = communityUserOrderMapping[headingName];
        rowOrderHeaderName =
          rowOrderStateCommunityUser[communityUserOrderMapping[headingName]];
      } else if (dataType === "iphone") {
        mappingHeaderName = communityIphoneOrderMapping[headingName];
        rowOrderHeaderName =
          rowOrderStateCommunityIphone[
            communityIphoneOrderMapping[headingName]
          ];
      } else if (dataType === "billee") {
        mappingHeaderName = communityBilleeOrderMapping[headingName];
        rowOrderHeaderName =
          rowOrderStateCommunityBillee[
            communityBilleeOrderMapping[headingName]
          ];
      } else if (dataType === "provider") {
        mappingHeaderName = communityProviderOrderMapping[headingName];
        rowOrderHeaderName =
          rowOrderStateCommunityProvider[
            communityProviderOrderMapping[headingName]
          ];
      } else if (dataType === "history") {
        mappingHeaderName = communityTestOrderMapping[headingName];
        rowOrderHeaderName =
          rowOrderStateCommunityTest[communityTestOrderMapping[headingName]];
      } else if (dataType === "devices") {
        mappingHeaderName = deviceBillingOrderMapping[headingName];
        rowOrderHeaderName =
          rowOrderCommunityDevicesList[deviceBillingOrderMapping[headingName]];
      }
    }

    if (mappingHeaderName) {
      let prevOrder = rowOrderHeaderName.order === "asc" ? "desc" : "asc";
      let prevInstance = `&${mappingHeaderName}:orderby=${prevOrder}`;
      if (newOrderQuery.includes(prevInstance)) {
        newOrderQuery = newOrderQuery.replace(prevInstance, "");
      }
      if (!search) {
        newOrderQuery = `&${mappingHeaderName}:orderby=${rowOrderHeaderName.order}`;
      }
    }
    if (dataType && dataType === "patient") {
      dispatch(communityActions.setPatientCommunityOrderQuery(newOrderQuery));
    } else if (dataType && dataType === "user") {
      dispatch(communityActions.setUserCommunityOrderQuery(newOrderQuery));
    } else if (dataType && dataType === "iphone") {
      dispatch(communityActions.setIphoneCommunityOrderQuery(newOrderQuery));
    } else if (dataType && dataType === "billee") {
      dispatch(communityActions.setBilleeCommunityOrderQuery(newOrderQuery));
    } else if (dataType && dataType === "provider") {
      dispatch(communityActions.setProviderCommunityOrderQuery(newOrderQuery));
    } else if (dataType && dataType === "history") {
      dispatch(communityActions.setTestCommunityOrderQuery(newOrderQuery));
    } else if (dataType && dataType === "devices") {
      dispatch(communityActions.setDevicesCommunityOrderQuery(newOrderQuery));
    }

    return newOrderQuery;
  };

  const buildFilterQuery = () => {
    let filterQuery = "";
    if (type === "Community") {
      if (dataType === "patient") {
        filterQuery =
          `dob_from:eq=${communityPatientDobFrom}` +
          `&dob_to:eq=${communityPatientDobTo}`;
      } else if (dataType === "user") {
        filterQuery = `role:eq=${communityUserFilteredRole}`;
      } else if (dataType === "history") {
        filterQuery =
          `created_date_from:eq=${groupHistoryCreatedDateFrom}` +
          `&created_date_to:eq=${groupHistoryCreatedDateTo}` +
          `&expiry_date_from:eq=${groupHistoryExpiryDateFrom}` +
          `&expiry_date_to:eq=${groupHistoryExpiryDateTo}&status:eq=${groupHistoryStatus}`;
      }
    }
    return filterQuery;
  };

  const buildSearchTextQuery = () => {
    let searchQuery = "";
    if (type === "Community" && dataType === "patient") {
      searchQuery =
        searchCommunityPatientText !== ""
          ? `search_query=${searchCommunityPatientText}`
          : "";
    } else if (type === "Community" && dataType === "user") {
      searchQuery =
        searchCommunityUserText !== ""
          ? `search_query=${searchCommunityUserText}`
          : "";
    } else if (type === "Community" && dataType === "iphone") {
      searchQuery =
        searchCommunityIphoneText !== ""
          ? `search_query=${searchCommunityIphoneText}`
          : "";
    } else if (type === "Community" && dataType === "billee") {
      searchQuery =
        searchCommunityBilleeText !== ""
          ? `search_query=${searchCommunityBilleeText}`
          : "";
    } else if (type === "Community" && dataType === "provider") {
      searchQuery =
        searchCommunityProviderText !== ""
          ? `search_query=${searchCommunityProviderText}`
          : "";
    } else if (type === "Community" && dataType === "history") {
      searchQuery =
        searchCommunityTestText !== ""
          ? `search_query=${searchCommunityTestText}`
          : "";
    } else if (type === "Community" && dataType === "devices") {
      searchQuery =
        searchCommunityTestText !== ""
          ? `search_query=${searchCommunityTestText}`
          : "";
    }
    return searchQuery;
  };
  const getPrefix = () => {
    let newPrefix = prefix;

    return newPrefix;
  };

  const updateUrl = () => {
    const searchQuery = buildSearchTextQuery();
    const newOrderQuery = buildRowOrderQuery();
    const filterQuery = buildFilterQuery();

    let customQuery = "";

    customQuery = `${searchQuery}${newOrderQuery}&${filterQuery}`;

    let modifiedString = removeEmptyParams(customQuery);

    let urlParamString = urlParams.toString();

    const newPrefix = getPrefix();
    let searchParam = "";

    if (!modifiedString) {
      searchParam =
        "?" +
        `${
          search === true
            ? newPrefix +
              modifiedString +
              `page=${search ? 1 : urlParams.get("page")}`
            : newPrefix + urlParamString
        }`;
    } else {
      if (
        !modifiedString.includes("page") &&
        urlParams.toString().includes("page")
      ) {
        searchParam =
          "?" +
          newPrefix +
          modifiedString +
          `&page=${search ? 1 : urlParams.get("page")}`;
      } else {
        searchParam = "?" + newPrefix + modifiedString;
      }
    }

    searchParam = searchParam.replace("&&", "&");
    history.replace({
      pathname: urlMapping[dataType],
      search: searchParam,
    });

    let str =
      modifiedString && modifiedString !== ""
        ? "?" + keyMapping[dataType] + modifiedString
        : "";

    if (dataType && dataType === "patient") {
      dispatch(communityActions.setPatientCommunityApiUrl(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "user") {
      dispatch(communityActions.setUserCommunityApiUrl(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "iphone") {
      dispatch(communityActions.setIphoneCommunityApiUrl(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "billee") {
      dispatch(communityActions.setBilleeCommunityApiUrl(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "provider") {
      dispatch(communityActions.setProviderCommunityApiUrl(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "history") {
      dispatch(communityActions.setTestCommunityApiUrl(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "devices") {
      dispatch(communityActions.setDevicesApiURL(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    }
  };

  const onClickTableHeading = async (e) => {
    const { id } = e.target;
    setOtherLoader(true);
    setSearch(false);
    setHeadingName(id);

    let urlParamString = urlParams.toString();
    //let prefix = "";
    if (type === "Community") {
      if (dataType && dataType === "patient") {
        setPrefix("tab_key=patient&");
        //prefix = "table=schedule_test&";
      } else if (dataType && dataType === "user") {
        setPrefix("tab_key=user&");
        //prefix = "table=upcoming_test&";
      } else if (dataType && dataType === "iphone") {
        setPrefix("tab_key=iphone&");
        //prefix = "table=upcoming_test&";
      } else if (dataType && dataType === "billee") {
        setPrefix("tab_key=billee&");
        //prefix = "table=upcoming_test&";
      } else if (dataType && dataType === "provider") {
        setPrefix("tab_key=provider&");
        //prefix = "table=upcoming_test&";
      } else if (dataType && dataType === "history") {
        setPrefix("tab_key=group_test&");
        //prefix = "table=upcoming_test&";
      } else if (dataType && dataType === "devices") {
        setPrefix("tab_key=devices&");
        //prefix = "table=upcoming_test&";
      }
    }

    let name = "";
    let obj;
    // for (let i = 0; i < tableHeadings.length; i++) {
    //   if (id === tableHeadings[i]) {
    //   }
    // }
    if (type === "Community") {
      if (dataType === "patient") {
        switch (id) {
          case "First Name":
            name = "first_name";

            obj = setOrderAndIcon(rowOrderStateCommunityPatient[name]);
            dispatch(
              rowOrderActions.setCommunityPatientOrder({
                ...rowOrderStateCommunityPatient,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                dob: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: false },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Last Name":
            name = "last_name";

            obj = setOrderAndIcon(rowOrderStateCommunityPatient[name]);
            dispatch(
              rowOrderActions.setCommunityPatientOrder({
                ...rowOrderStateCommunityPatient,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                first_name: { state: 1, order: "", icon: "" },
                dob: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: false },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Date of Birth":
            name = "dob";

            obj = setOrderAndIcon(rowOrderStateCommunityPatient[name]);
            dispatch(
              rowOrderActions.setCommunityPatientOrder({
                ...rowOrderStateCommunityPatient,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: false },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Email":
            name = "email";

            obj = setOrderAndIcon(rowOrderStateCommunityPatient[name]);
            dispatch(
              rowOrderActions.setCommunityPatientOrder({
                ...rowOrderStateCommunityPatient,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                dob: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: false },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Telephone":
            name = "telephone_number";

            obj = setOrderAndIcon(rowOrderStateCommunityPatient[name]);
            dispatch(
              rowOrderActions.setCommunityPatientOrder({
                ...rowOrderStateCommunityPatient,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                dob: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: false },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Status":
            name = "status";

            obj = setOrderAndIcon(rowOrderStateCommunityPatient[name]);
            dispatch(
              rowOrderActions.setCommunityPatientOrder({
                ...rowOrderStateCommunityPatient,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                dob: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          default:
            return;
        }
      } else if (dataType === "user") {
        switch (id) {
          case "First Name":
            name = "first_name";

            obj = setOrderAndIcon(rowOrderStateCommunityUser[name]);
            dispatch(
              rowOrderActions.setCommunityUserOrder({
                ...rowOrderStateCommunityUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
                role: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Last Name":
            name = "last_name";

            obj = setOrderAndIcon(rowOrderStateCommunityUser[name]);
            dispatch(
              rowOrderActions.setCommunityUserOrder({
                ...rowOrderStateCommunityUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                first_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
                role: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Email":
            name = "email";

            obj = setOrderAndIcon(rowOrderStateCommunityUser[name]);
            dispatch(
              rowOrderActions.setCommunityUserOrder({
                ...rowOrderStateCommunityUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
                role: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Telephone":
            name = "telephone_number";

            obj = setOrderAndIcon(rowOrderStateCommunityUser[name]);
            dispatch(
              rowOrderActions.setCommunityUserOrder({
                ...rowOrderStateCommunityUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                role: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Role":
            name = "role";

            obj = setOrderAndIcon(rowOrderStateCommunityUser[name]);
            dispatch(
              rowOrderActions.setCommunityUserOrder({
                ...rowOrderStateCommunityUser,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          default:
            return;
        }
      } else if (dataType === "iphone") {
        switch (id) {
          case "iPhone Number":
            name = "iphone_number";

            obj = setOrderAndIcon(rowOrderStateCommunityIphone[name]);
            dispatch(
              rowOrderActions.setCommunityIphoneOrder({
                ...rowOrderStateCommunityIphone,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                iphone_description: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Description":
            name = "iphone_description";

            obj = setOrderAndIcon(rowOrderStateCommunityIphone[name]);
            dispatch(
              rowOrderActions.setCommunityIphoneOrder({
                ...rowOrderStateCommunityIphone,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                iphone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          default:
            return;
        }
      } else if (dataType === "billee") {
        switch (id) {
          case "Company":
            name = "company";

            obj = setOrderAndIcon(rowOrderStateCommunityBillee[name]);
            dispatch(
              rowOrderActions.setCommunityBilleeOrder({
                ...rowOrderStateCommunityBillee,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                first_name: { state: 1, order: "", icon: "" },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "First Name":
            name = "first_name";

            obj = setOrderAndIcon(rowOrderStateCommunityBillee[name]);
            dispatch(
              rowOrderActions.setCommunityBilleeOrder({
                ...rowOrderStateCommunityBillee,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                company: { state: 1, order: "", icon: "" },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Last Name":
            name = "last_name";

            obj = setOrderAndIcon(rowOrderStateCommunityBillee[name]);
            dispatch(
              rowOrderActions.setCommunityBilleeOrder({
                ...rowOrderStateCommunityBillee,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                first_name: { state: 1, order: "", icon: "" },
                company: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Email":
            name = "email";

            obj = setOrderAndIcon(rowOrderStateCommunityBillee[name]);
            dispatch(
              rowOrderActions.setCommunityBilleeOrder({
                ...rowOrderStateCommunityBillee,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                first_name: { state: 1, order: "", icon: "" },
                last_name: { state: 1, order: "", icon: "" },
                company: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Telephone":
            name = "telephone_number";

            obj = setOrderAndIcon(rowOrderStateCommunityBillee[name]);
            dispatch(
              rowOrderActions.setCommunityBilleeOrder({
                ...rowOrderStateCommunityBillee,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                first_name: { state: 1, order: "", icon: "" },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                company: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          default:
            return;
        }
      } else if (dataType === "provider") {
        switch (id) {
          case "First Name":
            name = "first_name";

            obj = setOrderAndIcon(rowOrderStateCommunityProvider[name]);
            dispatch(
              rowOrderActions.setCommunityProviderOrder({
                ...rowOrderStateCommunityProvider,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Last Name":
            name = "last_name";

            obj = setOrderAndIcon(rowOrderStateCommunityProvider[name]);
            dispatch(
              rowOrderActions.setCommunityProviderOrder({
                ...rowOrderStateCommunityProvider,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                first_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Email":
            name = "email";

            obj = setOrderAndIcon(rowOrderStateCommunityProvider[name]);
            dispatch(
              rowOrderActions.setCommunityProviderOrder({
                ...rowOrderStateCommunityProvider,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
                telephone_number: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Telephone":
            name = "telephone_number";

            obj = setOrderAndIcon(rowOrderStateCommunityProvider[name]);
            dispatch(
              rowOrderActions.setCommunityProviderOrder({
                ...rowOrderStateCommunityProvider,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                last_name: { state: 1, order: "", icon: "" },
                email: { state: 1, order: "", icon: "" },
                first_name: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          default:
            return;
        }
      } else if (dataType === "history") {
        switch (id) {
          case "Name":
            name = "name";

            obj = setOrderAndIcon(rowOrderStateCommunityTest[name]);
            dispatch(
              rowOrderActions.setCommunityTestOrder({
                ...rowOrderStateCommunityTest,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                created_date: { state: 1, order: "", icon: "" },
                expiry_date: { state: 1, order: "", icon: "" },
                location: { state: 1, order: "", icon: "" },
                tests: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Created Date":
            name = "created_date";

            obj = setOrderAndIcon(rowOrderStateCommunityTest[name]);
            dispatch(
              rowOrderActions.setCommunityTestOrder({
                ...rowOrderStateCommunityTest,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                name: { state: 1, order: "", icon: "" },
                expiry_date: { state: 1, order: "", icon: "" },
                location: { state: 1, order: "", icon: "" },
                tests: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Expiry Date":
            name = "expiry_date";

            obj = setOrderAndIcon(rowOrderStateCommunityTest[name]);
            dispatch(
              rowOrderActions.setCommunityTestOrder({
                ...rowOrderStateCommunityTest,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                created_date: { state: 1, order: "", icon: "" },
                name: { state: 1, order: "", icon: "" },
                location: { state: 1, order: "", icon: "" },
                tests: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "No. of Tests":
            name = "tests";

            obj = setOrderAndIcon(rowOrderStateCommunityTest[name]);
            dispatch(
              rowOrderActions.setCommunityTestOrder({
                ...rowOrderStateCommunityTest,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                created_date: { state: 1, order: "", icon: "" },
                expiry_date: { state: 1, order: "", icon: "" },
                location: { state: 1, order: "", icon: "" },
                name: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Test Location":
            name = "location";

            obj = setOrderAndIcon(rowOrderStateCommunityTest[name]);
            dispatch(
              rowOrderActions.setCommunityTestOrder({
                ...rowOrderStateCommunityTest,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                created_date: { state: 1, order: "", icon: "" },
                expiry_date: { state: 1, order: "", icon: "" },
                name: { state: 1, order: "", icon: "" },
                tests: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Status":
            name = "status";

            obj = setOrderAndIcon(rowOrderStateCommunityTest[name]);
            dispatch(
              rowOrderActions.setCommunityTestOrder({
                ...rowOrderStateCommunityTest,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                created_date: { state: 1, order: "", icon: "" },
                expiry_date: { state: 1, order: "", icon: "" },
                location: { state: 1, order: "", icon: "" },
                tests: { state: 1, order: "", icon: "" },
                name: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;

          default:
            return;
        }
      } else if (dataType === "devices") {
        switch (id) {
          case "Name":
            name = "name";
            obj = setOrderAndIcon(rowOrderCommunityDevicesList[name]);
            dispatch(
              rowOrderActions.setRowOrderDevicesList({
                ...rowOrderCommunityDevicesList,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                price: { state: 1, order: "", icon: "" },
                description: { state: 1, order: "", icon: "" },
                bllee_name: { state: 1, order: "", icon: "" },
                equipment_id: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Price":
            name = "price";
            obj = setOrderAndIcon(rowOrderCommunityDevicesList[name]);
            dispatch(
              rowOrderActions.setRowOrderDevicesList({
                ...rowOrderCommunityDevicesList,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                name: { state: 1, order: "", icon: "" },
                description: { state: 1, order: "", icon: "" },
                bllee_name: { state: 1, order: "", icon: "" },
                equipment_id: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Description":
            name = "description";
            obj = setOrderAndIcon(rowOrderCommunityDevicesList[name]);
            dispatch(
              rowOrderActions.setRowOrderDevicesList({
                ...rowOrderCommunityDevicesList,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                name: { state: 1, order: "", icon: "" },
                price: { state: 1, order: "", icon: "" },
                bllee_name: { state: 1, order: "", icon: "" },
                equipment_id: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Billee":
            name = "bllee_name";
            obj = setOrderAndIcon(rowOrderCommunityDevicesList[name]);
            dispatch(
              rowOrderActions.setRowOrderDevicesList({
                ...rowOrderCommunityDevicesList,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                name: { state: 1, order: "", icon: "" },
                price: { state: 1, order: "", icon: "" },
                description: { state: 1, order: "", icon: "" },
                equipment_id: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Equipment Id":
            name = "equipment_id";
            obj = setOrderAndIcon(rowOrderCommunityDevicesList[name]);
            dispatch(
              rowOrderActions.setRowOrderDevicesList({
                ...rowOrderCommunityDevicesList,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                name: { state: 1, order: "", icon: "" },
                price: { state: 1, order: "", icon: "" },
                description: { state: 1, order: "", icon: "" },
                equipmbllee_nameent_id: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;

          default:
            return;
        }
      }
    }
    return;
  };

  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      setSearch(true);
      setOtherLoader(true);
      if (type === "Community") {
        if (dataType && dataType === "patient") {
          setPrefix("tab_key=patient&");
        } else if (dataType && dataType === "user") {
          setPrefix("tab_key=user&");
        } else if (dataType && dataType === "iphone") {
          setPrefix("tab_key=iphone&");
        } else if (dataType && dataType === "billee") {
          setPrefix("tab_key=billee&");
        } else if (dataType && dataType === "provider") {
          setPrefix("tab_key=provider&");
        } else if (dataType && dataType === "history") {
          setPrefix("tab_key=group_test&");
        }
      }
      setOrderState({
        ...orderState,
      });
    }
  };
  const onClearSorting = () => {
    setOtherLoader(true);
    if (dataType && dataType === "patient") {
      dispatch(
        rowOrderActions.setCommunityPatientOrder({
          ...rowOrderStateCommunityPatient,
          first_name: { state: 1, order: "", icon: "" },
          last_name: { state: 1, order: "", icon: "" },
          dob: { state: 1, order: "", icon: "" },
          email: { state: 1, order: "", icon: "" },
          telephone_number: { state: 1, order: "", icon: "" },
        })
      );
      dispatch(communityActions.setPatientCommunityOrderQuery(""));
      history.replace({
        pathname: urlMapping[dataType],
        search: "?page=1",
      });
      dispatch(communityActions.setPatientCommunityApiUrl(""));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "user") {
      dispatch(
        rowOrderActions.setCommunityUserOrder({
          ...rowOrderStateCommunityUser,
          first_name: { state: 1, order: "", icon: "" },
          last_name: { state: 1, order: "", icon: "" },
          email: { state: 1, order: "", icon: "" },
          telephone_number: { state: 1, order: "", icon: "" },
          role: { state: 1, order: "", icon: "" },
        })
      );
      dispatch(communityActions.setUserCommunityOrderQuery(""));
      history.replace({
        pathname: urlMapping[dataType],
        search: "?page=1",
      });
      dispatch(communityActions.setUserCommunityApiUrl(""));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "iphone") {
      dispatch(
        rowOrderActions.setCommunityIphoneOrder({
          ...rowOrderStateCommunityIphone,
          iphone_number: { state: 1, order: "", icon: "" },
          iphone_description: { state: 1, order: "", icon: "" },
        })
      );
      dispatch(communityActions.setIphoneCommunityOrderQuery(""));
      history.replace({
        pathname: urlMapping[dataType],
        search: "?page=1",
      });
      dispatch(communityActions.setIphoneCommunityApiUrl(""));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    }
    // else if (dataType && dataType === "devices") {
    //   dispatch(
    //     rowOrderActions.setCommunityIphoneOrder({
    //       ...rowOrderStateCommunityIphone,
    //       iphone_number: { state: 1, order: "", icon: "" },
    //       iphone_description: { state: 1, order: "", icon: "" },
    //     })
    //   );
    //   dispatch(communityActions.setIphoneCommunityOrderQuery(""));
    //   history.replace({
    //     pathname: urlMapping[dataType],
    //     search: "?page=1",
    //   });
    //   dispatch(communityActions.setIphoneCommunityApiUrl(""));
    //   setShowToast(true);
    //   setDataFlag((prevState) => !prevState);
    // }
    else if (dataType && dataType === "billee") {
      dispatch(
        rowOrderActions.setCommunityBilleeOrder({
          ...rowOrderStateCommunityBillee,
          company: { state: 1, order: "", icon: "" },
          first_name: { state: 1, order: "", icon: "" },
          last_name: { state: 1, order: "", icon: "" },
          email: { state: 1, order: "", icon: "" },
          telephone_number: { state: 1, order: "", icon: "" },
        })
      );
      dispatch(communityActions.setBilleeCommunityOrderQuery(""));
      history.replace({
        pathname: urlMapping[dataType],
        search: "?page=1",
      });
      dispatch(communityActions.setBilleeCommunityApiUrl(""));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "history") {
      dispatch(
        rowOrderActions.setCommunityTestOrder({
          ...rowOrderStateCommunityTest,
          name: { state: 1, order: "", icon: "" },
          created_date: { state: 1, order: "", icon: "" },
          expiry_date: { state: 1, order: "", icon: "" },
          location: { state: 1, order: "", icon: "" },
          tests: { state: 1, order: "", icon: "" },
          status: { state: 1, order: "", icon: "" },
        })
      );
      dispatch(communityActions.setTestCommunityOrderQuery(""));
      history.replace({
        pathname: urlMapping[dataType],
        search: "?page=1",
      });
      dispatch(communityActions.setTestCommunityApiUrl(""));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    }
  };

  const onClearSearch = () => {
    setOtherLoader(true);
    if (type === "Community" && dataType === "patient") {
      dispatch(filterActions.setSearchCommunityPatientText(""));
    } else if (type === "Community" && dataType === "user") {
      dispatch(filterActions.setSearchCommunityUserText(""));
    } else if (type === "Community" && dataType === "iphone") {
      dispatch(filterActions.setSearchCommunityIphoneText(""));
    } else if (type === "Community" && dataType === "devices") {
      dispatch(filterActions.setSearchCommunityIphoneText(""));
    } else if (type === "Community" && dataType === "billee") {
      dispatch(filterActions.setSearchCommunityBilleeText(""));
    } else if (type === "Community" && dataType === "provider") {
      dispatch(filterActions.setSearchCommunityProviderText(""));
    } else if (type === "Community" && dataType === "history") {
      dispatch(filterActions.setSearchCommunityTestText(""));
    }
    setSearch(true);
    setOrderState({
      ...orderState,
    });
  };
  const removeDOBFilter = () => {
    var modifiedString = "";

    dispatch(
      communityActions.setPatientCommunityApiUrl(
        "?tab_key=patient&" + modifiedString
      )
    );
    dispatch(communityActions.setCommunityPatientDobFrom([]));
    dispatch(communityActions.setCommunityPatientDobTo([]));
    setDateRange([null, null]);
    history.replace({
      pathname: urlMapping[dataType],
      search: "",
    });

    setOrderState({
      ...orderState,
    });
  };

  const removeRoleFilter = (role) => {
    var modifiedString = "";

    dispatch(
      communityActions.setPatientCommunityApiUrl(
        "?tab_key=user&" + modifiedString
      )
    );
    dispatch(communityActions.setCommunityUserFilteredRole([]));
    history.replace({
      pathname: urlMapping[dataType],
      search: "",
    });
    setOrderState({
      ...orderState,
    });
  };

  const removeCreatedFilter = () => {
    // if (
    //   groupHistoryExpiryDateFrom.length !== 0 &&
    //   groupHistoryExpiryDateTo.length !== 0
    // ) {
    // }
    var modifiedString = "";

    dispatch(
      communityActions.setPatientCommunityApiUrl(
        "?tab_key=group_test&" + modifiedString
      )
    );
    dispatch(communityActions.setGroupHistoryCreatedDateFrom([]));
    dispatch(communityActions.setGroupHistoryCreatedDateTo([]));
    setDateRange([null, null]);
    history.replace({
      pathname: urlMapping[dataType],
      search: "",
    });

    setOrderState({
      ...orderState,
    });
  };
  const removeOrderedFilter = () => {
    // if (
    //   groupHistoryCreatedDateFrom.length === 0 &&
    //   groupHistoryCreatedDateTo.length === 0
    // ) {
    // }
    var modifiedString = "";

    dispatch(
      communityActions.setPatientCommunityApiUrl(
        "?tab_key=group_test&" + modifiedString
      )
    );
    history.replace({
      pathname: urlMapping[dataType],
      search: "",
    });
    dispatch(communityActions.setGroupHistoryExpiryDateFrom([]));
    dispatch(communityActions.setGroupHistoryExpiryDateTo([]));
    setTestDateRange([null, null]);
    setOrderState({
      ...orderState,
    });
  };
  const removeStatusFilter = (status) => {
    //const updatedStatus = groupHistoryStatus.filter((item) => item !== status);
    dispatch(communityActions.setGroupHistoryStatus([]));
    dispatch(
      communityActions.setPatientCommunityApiUrl("?tab_key=group_test&")
    );
    history.replace({
      pathname: urlMapping[dataType],
      search: "",
    });
    setOrderState({
      ...orderState,
    });
  };

  const clearFilters = () => {
    var modifiedString = "";
    if (type === "Community" && dataType === "patient") {
      dispatch(
        communityActions.setPatientCommunityApiUrl(
          "?tab_key=patient&" + modifiedString
        )
      );
      dispatch(communityActions.setCommunityPatientDobFrom([]));
      dispatch(communityActions.setCommunityPatientDobTo([]));
      setDateRange([null, null]);
    } else if (type === "Community" && dataType === "user") {
      dispatch(
        communityActions.setUserCommunityApiUrl(
          "?tab_key=user&" + modifiedString
        )
      );
      dispatch(communityActions.setCommunityUserFilteredRole([]));
    } else if (type === "Community" && dataType === "history") {
      dispatch(communityActions.setGroupHistoryCreatedDateFrom([]));
      dispatch(communityActions.setGroupHistoryCreatedDateTo([]));
      dispatch(communityActions.setGroupHistoryExpiryDateFrom([]));
      dispatch(communityActions.setGroupHistoryExpiryDateTo([]));
      dispatch(communityActions.setGroupHistoryStatus([]));
      setDateRange([null, null]);
      setTestDateRange([null, null]);

      dispatch(communityActions.setTestCommunityApiUrl("?" + modifiedString));
    }

    setOrderState({
      ...orderState,
    });
  };
  const paramType = dataType;

  return (
    <Fragment>
      {/* For Audit Log */}
      {auditLogModalOpen && (
        <AuditLogsModal
          isShowModal={auditLogModalOpen}
          hideModal={hideAuditLogModal}
          LogTitle={"Audit Log"}
          type={type}
          dataType={dataType}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          refId={communityId}
        />
      )}

      {addTestModalOpen && (
        <AddIphoneModal
          type={type}
          isShowModal={addTestModalOpen}
          hideModal={hideAddTestModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      {billeeModalOpen && (
        <AddBilleeModalCommunity
          isShowModal={billeeModalOpen}
          hideModal={hideAddBilleeModal}
          type={type}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      {providerModalOpen && (
        <AddProvider
          isShowModal={providerModalOpen}
          hideModal={hideAddProviderModal}
          type={type}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          communityId={communityId}
        />
      )}

      {addUserModalOpen && (
        <AddUserModalCommunity
          isShowModal={addUserModalOpen}
          hideModal={hideAddUserModal}
          type={type}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}

      {importCorpModalOpen && (
        <ImportCorporateModal
          isShowModal={importCorpModalOpen}
          hideModal={hideImportCorpModal}
          type={type}
          dataType={dataType}
          communityId={communityId}
          modalFormData={importFormData()}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      {filterModalOpen && dataType === "patient" && (
        <CommunityPatientFilter
          isShowModal={filterModalOpen}
          hideModal={hideFilterModal}
          setShowToast={setShowToast}
          setDataFlag={setDataFlag}
          orderBy={patientCommunityOrderQuery}
          dateRange={dateRange}
          setDateRange={setDateRange}
          setOtherLoader={setOtherLoader}
        />
      )}
      {filterModalOpen && dataType === "user" && (
        <CommunityUserFilter
          isShowModal={filterModalOpen}
          hideModal={hideFilterModal}
          setShowToast={setShowToast}
          setDataFlag={setDataFlag}
          orderBy={userCommunityOrderQuery}
          setOtherLoader={setOtherLoader}
        />
      )}
      {filterModalOpen && dataType === "history" && (
        <GroupTestHistoryFilter
          isShowModal={filterModalOpen}
          hideModal={hideFilterModal}
          setShowToast={setShowToast}
          setDataFlag={setDataFlag}
          orderBy={userCommunityOrderQuery}
          testDateRange={testDateRange}
          setTestDateRange={setTestDateRange}
          dateRange={dateRange}
          setDateRange={setDateRange}
          setOtherLoader={setOtherLoader}
        />
      )}

      {addPatientModalOpen && (
        <AddPatient
          isShowModal={addPatientModalOpen}
          hideModal={hideAddPatientModal}
          type={dataType}
          communityId={communityId}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}

      {deviceModalOpen && (
        <AddDevice
          isShowModal={deviceModalOpen}
          hideModal={hideAddDeviceModal}
          type={dataType}
          communityId={communityId}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          billeesList={billeesList}
        />
      )}

      {otherLoader ? (
        <Loader />
      ) : (
        <div className="corpoarte-table">
          <div className="table-header-filter">
            <div className="cor-header-change">
              <h1 className="page-name-heading">{heading}</h1>
              <div className="right-cor-header">
                <ul className="rc-header-listing">
                  {/* <li className="single-rc">
                  <button onClick={onClearSorting}>Clear Sorting</button>
                </li> */}
                  {/* <li className="single-rc">
                  <button onClick={onClearSearch}>Clear Search</button>
                </li> */}
                  <li className="single-rc">
                    {type === "Community" && dataType === "patient" && (
                      <SearchInput
                        value={searchCommunityPatientText}
                        handleChange={handleSearchChange}
                        onKeyEnter={onEnterPress}
                        onClearSearch={onClearSearch}
                      />
                    )}
                    {type === "Community" && dataType === "user" && (
                      <SearchInput
                        value={searchCommunityUserText}
                        handleChange={handleSearchChange}
                        onKeyEnter={onEnterPress}
                      />
                    )}
                    {type === "Community" && dataType === "iphone" && (
                      <SearchInput
                        value={searchCommunityIphoneText}
                        handleChange={handleSearchChange}
                        onKeyEnter={onEnterPress}
                      />
                    )}
                    {type === "Community" && dataType === "billee" && (
                      <SearchInput
                        value={searchCommunityBilleeText}
                        handleChange={handleSearchChange}
                        onKeyEnter={onEnterPress}
                      />
                    )}
                    {type === "Community" && dataType === "provider" && (
                      <SearchInput
                        value={searchCommunityProviderText}
                        handleChange={handleSearchChange}
                        onKeyEnter={onEnterPress}
                      />
                    )}
                    {type === "Community" && dataType === "history" && (
                      <SearchInput
                        value={searchCommunityTestText}
                        handleChange={handleSearchChange}
                        onKeyEnter={onEnterPress}
                      />
                    )}
                  </li>
                  {type === "Community" &&
                    (dataType === "patient" ||
                      dataType === "user" ||
                      dataType === "history") && (
                      <li className="single-rc">
                        <FilterButton handleClick={showFilterModal} />
                      </li>
                    )}

                  {/* {!isCatchUAdmin &&
                (userRole.includes("Super Admin") ||
                  userRole.includes("Corporate HQ Administrator") ||
                  userRole.includes("Community Admin") ||
                  userRole.includes("Individual Practice Admin")) ? (
                  <li className="single-rc">
                    <button
                      className="btn btn-primary-outline"
                      onClick={showAuditLogModal}
                    >
                      View Audit Logs
                    </button>
                  </li>
                ) : (
                  <>
                    {isCatchUAdmin && (
                      <li className="single-rc">
                        <button
                          className="btn btn-primary-outline"
                          onClick={showAuditLogModal}
                        >
                          View Audit Logs
                        </button>
                      </li>
                    )}
                  </>
                )} */}

                  {dataType !== "devices" && (
                    <li className="single-rc">
                      <button
                        className="btn btn-primary-outline"
                        onClick={showAuditLogModal}
                      >
                        View Audit Log
                      </button>
                    </li>
                  )}

                  {dataType !== "history" && (
                    <>
                      <li className="single-rc">
                        {/* <input
                            type="search"
                            name=""
                            id=""
                            placeholder="Search"
                            className="custom-search"
                          /> */}
                        {dataType !== "devices" && (
                          <button
                            className="btn btn-primary-outline"
                            onClick={showImportCorpModal}
                          >
                            Import
                          </button>
                        )}
                      </li>
                      <li className="single-rc">
                        <button
                          className="btn btn-primary-fill btn-primary-16"
                          onClick={showAddModalAccToType}
                          disabled={
                            dataType == "devices" && !billeesList?.length
                          }
                        >
                          Add
                        </button>
                      </li>
                    </>
                  )}

                  {/* {type === "billing" && dataType === "testToBill" && (
                  <li className="cdh-inner-single">
                    <div>
                      <Select
                        name="patients"
                        id="patients"
                        // className="custom-select-modal"
                        onChange={handleSelectPatients}
                        isMulti
                        options={
                          allPatients &&
                          allPatients &&
                          allPatients.map((patient) => ({
                            value: patient.patient_id,
                            label: patient.patient_name,
                          }))
                        }
                      />
                    </div>
                  </li>
                )} */}
                </ul>
              </div>
            </div>
            {type === "Community" && dataType === "patient" && (
              <>
                {communityPatientDobFrom.length !== 0 &&
                  communityPatientDobTo.length !== 0 && (
                    <div className="filter-header">
                      <h2 className="apply-filter-name">Applied Filters:</h2>
                      <ul className="apply-filter-list">
                        <li className="apply-filter-single">
                          <h3 className="filter-name-type">
                            Date of Birth:{" "}
                            <strong>
                              {communityPatientDobFrom} -{" "}
                              {communityPatientDobTo}
                            </strong>{" "}
                          </h3>
                          <img
                            src={FilterClose}
                            alt=""
                            onClick={removeDOBFilter}
                          />
                        </li>
                      </ul>
                      <Link
                        to={urlMapping[dataType]}
                        className="clear-all-filter"
                        onClick={clearFilters}
                      >
                        Clear all filters
                      </Link>
                    </div>
                  )}
              </>
            )}

            {type === "Community" && dataType === "user" && (
              <>
                {communityUserFilteredRole.length !== 0 && (
                  <div className="filter-header">
                    <h2 className="apply-filter-name">Applied Filters:</h2>
                    <ul className="apply-filter-list">
                      {communityUserFilteredRole.map((role) => (
                        <li className="apply-filter-single">
                          <h3 className="filter-name-type">
                            Role: <strong>{role}</strong>{" "}
                          </h3>
                          <img
                            src={FilterClose}
                            alt=""
                            onClick={() => removeRoleFilter(role)}
                          />
                        </li>
                      ))}
                    </ul>
                    <Link
                      to={urlMapping[dataType]}
                      className="clear-all-filter"
                      onClick={clearFilters}
                    >
                      Clear all filters
                    </Link>
                  </div>
                )}
              </>
            )}
            {type === "Community" && dataType === "history" && (
              <>
                {((groupHistoryCreatedDateFrom.length !== 0 &&
                  groupHistoryCreatedDateTo.length !== 0) ||
                  (groupHistoryExpiryDateFrom.length !== 0 &&
                    groupHistoryExpiryDateTo.length !== 0) ||
                  groupHistoryStatus.length !== 0) && (
                  <div className="filter-header">
                    <h2 className="apply-filter-name">Applied Filters:</h2>
                    <ul className="apply-filter-list">
                      {groupHistoryCreatedDateFrom.length !== 0 &&
                        groupHistoryCreatedDateTo.length !== 0 && (
                          <li className="apply-filter-single">
                            <h3 className="filter-name-type">
                              Test Date:{" "}
                              <strong>
                                {groupHistoryCreatedDateFrom} -{" "}
                                {groupHistoryCreatedDateTo}
                              </strong>{" "}
                            </h3>
                            <img
                              src={FilterClose}
                              alt=""
                              onClick={removeCreatedFilter}
                            />
                          </li>
                        )}
                      {groupHistoryExpiryDateFrom.length !== 0 &&
                        groupHistoryExpiryDateTo.length !== 0 && (
                          <li className="apply-filter-single">
                            <h3 className="filter-name-type">
                              Ordered:{" "}
                              <strong>
                                {groupHistoryExpiryDateFrom} -{" "}
                                {groupHistoryExpiryDateTo}
                              </strong>{" "}
                            </h3>
                            <img
                              src={FilterClose}
                              alt=""
                              onClick={removeOrderedFilter}
                            />
                          </li>
                        )}
                      {groupHistoryStatus &&
                        groupHistoryStatus.map((status) => (
                          <li className="apply-filter-single">
                            <h3 className="filter-name-type">
                              Status: <strong>{status}</strong>{" "}
                            </h3>
                            <img
                              src={FilterClose}
                              alt=""
                              onClick={() => removeStatusFilter(status)}
                            />
                          </li>
                        ))}
                    </ul>
                    <Link
                      to={urlMapping[dataType]}
                      className="clear-all-filter"
                      onClick={clearFilters}
                    >
                      Clear all filters
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="table-responsive">
            <table className="table" width="en-width">
              <thead>
                <tr>
                  {tableHeadings &&
                    tableHeadings.map((tableHead) => (
                      <th
                        id={tableHead}
                        name={tableHead}
                        key={tableHead}
                        onClick={dataType === "patient" && tableHead === "Status" ? undefined : onClickTableHeading }

                      >
                        {tableHead}
                        {tableHead !== "Actions" && (
                          <>
                            {rowOrderMapping[paramType][
                              orderMapping[paramType][tableHead]
                            ]?.icon !== false && (
                              <span className="icons-th">
                                {rowOrderMapping[paramType][
                                  orderMapping[paramType][tableHead]
                                ]?.icon === "" ? (
                                  <>
                                    <span></span>
                                    <span className="order-4"></span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      className={
                                        rowOrderMapping[paramType][
                                          orderMapping[paramType][tableHead]
                                        ]?.icon === "asc"
                                          ? "caret-4-asc-icon"
                                          : "caret-4-desc-icon"
                                      }
                                    ></span>
                                    <span className="order-4"></span>
                                  </>
                                )}
                              </span>
                            )}
                          </>
                        )}
                      </th>
                    ))}
                </tr>
              </thead>
              <TableEditBody
                type={type}
                data={data}
                dataType={dataType}
                deleteCorporate={props.deleteCorporate}
                communityName={communityName}
                setDataFlag={setDataFlag}
                setShowToast={setShowToast}
              />

              {/* <tbody>
                    {data.map(record => (
                    <tr>
                        <td>{record.name}</td>
                        <td>{record.address}</td>
                        <td>{record.address2}</td>
                        <td>{record.city}</td>
                        <td>{record.state}</td>
                        <td>
                        <span className="tag zip-tag">{record.zip}</span>
                        </td>
                        <td>
                        <span className="td-action">
                            <img src={Edit} alt="" />
                        </span>
                        <span className="td-action">
                            <img src={Delete_Icon} alt="" />
                        </span>{" "}
                        <span className="td-action">
                            <img src={Save} alt="" />
                        </span>
                        </td>
                    </tr>
                    ))}
                </tbody> */}
            </table>
          </div>
          {dataType === "iphone" && type === "Community" && (
            <Pagination
              dataType={dataType}
              type={type}
              data={data}
              totalRecords={totalRecords}
              parm={parm.communityID}
              setDataFlag={setDataFlag}
              prefix={"tab_key=iphone&"}
              setOtherLoader={setOtherLoader}
            />
          )}

          {dataType === "devices" && type === "Community" && (
            <Pagination
              dataType={dataType}
              type={type}
              data={data}
              totalRecords={totalRecords}
              parm={parm.communityID}
              setDataFlag={setDataFlag}
              prefix={"tab_key=devices&"}
              setOtherLoader={setOtherLoader}
            />
          )}
          {dataType === "user" && type === "Community" && (
            <Pagination
              dataType={dataType}
              type={type}
              data={data}
              totalRecords={totalRecords}
              parm={parm.communityID}
              setDataFlag={setDataFlag}
              prefix={"tab_key=user&"}
              setOtherLoader={setOtherLoader}
            />
          )}
          {dataType === "billee" && type === "Community" && (
            <Pagination
              dataType={dataType}
              type={type}
              data={data}
              totalRecords={totalRecords}
              parm={parm.communityID}
              setDataFlag={setDataFlag}
              prefix={"tab_key=billee&"}
              setOtherLoader={setOtherLoader}
            />
          )}
          {dataType === "patient" && type === "Community" && (
            <Pagination
              dataType={dataType}
              type={type}
              data={data}
              totalRecords={totalRecords}
              parm={parm.communityID}
              setDataFlag={setDataFlag}
              prefix={"tab_key=patient&"}
              setOtherLoader={setOtherLoader}
            />
          )}
          {dataType === "history" && type === "Community" && (
            <Pagination
              dataType={dataType}
              type={type}
              data={data}
              totalRecords={totalRecords}
              parm={parm.communityID}
              setDataFlag={setDataFlag}
              prefix={"tab_key=group_test&"}
              setOtherLoader={setOtherLoader}
            />
          )}
        </div>
      )}
      {dataType === TabKey && (
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      )}
    </Fragment>
  );
};

export default TableDataEdit;
