import { createSlice } from "@reduxjs/toolkit";

const initialSidebarState = {
  selectedNav: "",
  recordName: "",
  crumbs: [],
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: initialSidebarState,
  reducers: {
    setSelectedNav(state, action) {
      state.selectedNav = action.payload;
    },

    // setCrumbs(state, action) {
    //   state.crumbs.push(action.payload);
    // },
    setNewCrumbs(state, action) {
      state.crumbs = action.payload;
    },
    // removeCrumbs(state, action) {
    //   const index = action.payload;
    //   state.crumbs.length = index + 1;
    // },
    // resetCrumbs(state, action) {
    //   state.crumbs = action.payload;
    // },
    // resetPageCrumbs(state, action) {
    //   state.crumbs = [];
    // },
  },
});

export const sidebarActions = sidebarSlice.actions;

export default sidebarSlice.reducer;
