import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Loader2 from "../../../UI/loader2/loader2";
import "./AddCorporateModal.scss";
import { getWithExpiry } from "../../../utils/helper";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import images
import CloseModal from "../../../../assets/close-outline.svg";

function AddCorporateModal(props) {
  const { isShowModal, hideModal, type, setDataFlag, setShowToast } = props;

  //Start Creating State Object for Form
  const [corporate, setCorporate] = useState({
    corporate_name: "",
    address_1: "",
    address_2: "",
    country: "United States",
    city: "",
    state: "",
    zip: "",
    first_name: "",
    last_name: "",
    email: "",
    telephone_number: "",
    role: "Corporate HQ Administrator",
  });

  const handleChange = (event, val) => {
    const { name, value } = event.target;
    if (name === "zip") {
      const regex = /^\d+$/;
      if (value === "") {
        setCorporate({ ...corporate, [name]: value });
        return;
      }

      if (regex.test(value) === false) return;
    }
    setCorporate({ ...corporate, [name]: value });
  };

  //End Creating State Object for Form

  const [nameError, setNameError] = useState("");
  const [address1Error, setAddress1Error] = useState("");
  const [cityError, setCityError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [zipError, setZipError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [addBtnDisabled, setAddBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneValue, setPhoneValue] = useState("");
  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const token = "Bearer " + getWithExpiry("expiry_token");

  const addCorporateSubmissionHandler = (event) => {
    event.preventDefault();

    setLoading(true);

    setAddress1Error("");
    setCityError("");
    setCountryError("");
    setStateError("");
    setZipError("");
    setNameError("");
    setPhoneError("");
    setEmailError("");
    setFirstNameError("");
    setLastNameError("");

    setAddBtnDisabled(true);

    //const form = document.querySelector(".add-corporate-form");
    //const fd = new FormData(form);

    const fd = new FormData();
    fd.append("corporate_name", corporate.corporate_name);
    fd.append("address_1", corporate.address_1);
    fd.append("address_2", corporate.address_2);
    fd.append("country", corporate.country);
    fd.append("city", corporate.city);
    fd.append("state", corporate.state);
    fd.append("zip", corporate.zip);
    fd.append("first_name", corporate.first_name);
    fd.append("last_name", corporate.last_name);
    fd.append("email", corporate.email);
    //fd.append("telephone_number", corporate.telephone_number);
    fd.append("role", corporate.role);

    //let phoneVal = corporate.telephone_number.replace(/\s+/g, "");
    if (phoneValue) {
      fd.append("telephone_number", "+" + phoneValue);
    }

    let url = "";
    if (type === "Corporate") {
      url = `${process.env.REACT_APP_URL_PATH}/corporate/add_corporate/`;
    } else {
      url = `${process.env.REACT_APP_URL_PATH}/community/add_community/`;
    }

    // make http call
    axios({
      method: "post",
      url: url,
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      })
      .catch((error) => {
        setLoading(false);
        setAddBtnDisabled(false);
        if (error.response.data.message === "Field Errors") {
          if (
            error.response.data.data["address_form"] &&
            "address_1" in error.response.data.data["address_form"]
          ) {
            setAddress1Error(
              error.response.data.data["address_form"]["address_1"][0]
            );
          }
          if (
            error.response.data.data["address_form"] &&
            "city" in error.response.data.data["address_form"]
          ) {
            setCityError(error.response.data.data["address_form"]["city"][0]);
          }
          if (
            error.response.data.data["address_form"] &&
            "country" in error.response.data.data["address_form"]
          ) {
            setCountryError(
              error.response.data.data["address_form"]["country"][0]
            );
          }
          if (
            error.response.data.data["address_form"] &&
            "state" in error.response.data.data["address_form"]
          ) {
            setStateError(error.response.data.data["address_form"]["state"][0]);
          }
          if (
            error.response.data.data["address_form"] &&
            "zip" in error.response.data.data["address_form"]
          ) {
            setZipError(error.response.data.data["address_form"]["zip"][0]);
          }
          if (
            error.response.data.data["corporate_form"] &&
            "corporate_name" in error.response.data.data["corporate_form"]
          ) {
        
            setNameError(
              error.response.data.data["corporate_form"]["corporate_name"][0]
            );
          }
          if (
            error.response.data.data["phone_form"] &&
            "telephone_number" in error.response.data.data["phone_form"]
          ) {
            setPhoneError(
              error.response.data.data["phone_form"]["telephone_number"][0]
            );
          }
          if (
            error.response.data.data["user_form"] &&
            "email" in error.response.data.data["user_form"]
          ) {
            setEmailError(error.response.data.data["user_form"]["email"][0]);
          }
          if (
            error.response.data.data["user_form"] &&
            "first_name" in error.response.data.data["user_form"]
          ) {
            setFirstNameError(
              error.response.data.data["user_form"]["first_name"][0]
            );
          }
          if (
            error.response.data.data["user_form"] &&
            "last_name" in error.response.data.data["user_form"]
          ) {
            setLastNameError(
              error.response.data.data["user_form"]["last_name"][0]
            );
          }
        } else {
          notifyError(error.response.data.message);
        }
      });
  };

  return (
    <div className="action-modal-wrapper">
      <Modal
        className="comunitie-modal modal-loader"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header className="comubitie-modal-header" id="rm-padding">
          <h1 className="modal-header-title">Add Corporate</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="comunitie-modal-body">
          <form
         
            method="post"
            className="add-corporate-form"
            onSubmit={addCorporateSubmissionHandler}
          >
            <div className="comunties-fields">
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="corporate_name" className="label">
                      Name *
                    </label>
                    <input
                      id="corporate_name"
                      name="corporate_name"
                      type="text"
                      placeholder="Name"
                      className="custom-input"
                      value={corporate.corporate_name}
                      onChange={handleChange}
                      required
                    />
                    {nameError && <p className="error-text">{nameError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="address_1" className="label">
                      Address 01 *
                    </label>
                    <input
                      id="address_1"
                      name="address_1"
                      type="text"
                      placeholder="Address 01"
                      className="custom-input"
                      value={corporate.address_1}
                      onChange={handleChange}
                      required
                    />
                    {address1Error && (
                      <p className="error-text">{address1Error}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="country" className="label">
                      Address 02
                    </label>
                    <input
                      id="address_2"
                      name="address_2"
                      type="text"
                      className="custom-input"
                      placeholder="Address 02"
                      value={corporate.address_2}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="zip" className="label">
                      City *
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="custom-input"
                      placeholder="City"
                      value={corporate.city}
                      onChange={handleChange}
                      required
                    />
                    {cityError && <p className="error-text">{cityError}</p>}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="state" className="label">
                      State *
                    </label>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      className="custom-input"
                      placeholder="State"
                      value={corporate.state}
                      onChange={handleChange}
                      required
                    />
                    {stateError && <p className="error-text">{stateError}</p>}
                  </div>
                </div>

                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="zip" className="label">
                      Zip *
                    </label>
                    <input
                      type="text"
                      name="zip"
                      id="zip"
                      className="custom-input"
                      placeholder="Zip"
                      value={corporate.zip}
                      onChange={handleChange}
                      required
                    />
                    {zipError && <p className="error-text">{zipError}</p>}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="country" className="label">
                      Country *
                    </label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      className="custom-input"
                      placeholder="Country"
                      value={corporate.country}
                      onChange={handleChange}
                      required
                    />
                    {countryError && (
                      <p className="error-text">{countryError}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="addminstraion-fields">
              <div className="addminstraion-header">
                <h2 className="adsd-heading">Administrator Information</h2>
                <p className="ad-des">Default User</p>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="first_name" className="label">
                      First Name *
                    </label>
                    <input
                      id="first_name"
                      name="first_name"
                      type="text"
                      className="custom-input"
                      placeholder="First Name"
                      value={corporate.first_name}
                      onChange={handleChange}
                      required
                    />
                    {firstNameError && (
                      <p className="error-text">{firstNameError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="last_name" className="label">
                      Last Name *
                    </label>
                    <input
                      id="last_name"
                      name="last_name"
                      type="text"
                      className="custom-input"
                      placeholder="Last Name"
                      value={corporate.last_name}
                      onChange={handleChange}
                      required
                    />
                    {lastNameError && (
                      <p className="error-text">{lastNameError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="email" className="label">
                      Email *
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className="custom-input"
                      placeholder="Email"
                      value={corporate.email}
                      onChange={handleChange}
                      required
                    />
                    {emailError && <p className="error-text">{emailError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="telephone_number" className="label">
                      Telephone *
                    </label>
                    {/* <input
                      type="text"
                      id="telephone_number"
                      name="telephone_number"
                      className="custom-input"
                      placeholder="Telephone"
                      value={corporate.telephone_number}
                      //pattern="[0-9]+"
                      onChange={(e) => {
                        const { name, value } = e.target;

                        const regex = /^([\s+0-9])+$/i;
                        if (value === "") {
                          // case to remove whole highlighted text
                          setCorporate({ ...corporate, [name]: value });
                          return;
                        }
                        if (regex.test(value) === false) return;

                        if (value.length === 1 && value === "+") {
                          // case to remove + sign when last number is removed
                          setCorporate({ ...corporate, [name]: "" });
                        } else if (value.length >= 1 && value[0] !== "+") {
                          // case to append + sign with first number
                          setCorporate({ ...corporate, [name]: "+" + value });
                        } else {
                          // case to append other numbers
                          setCorporate({ ...corporate, [name]: value });
                        }
                      }}
                      required
                    /> */}
                    <PhoneInput
                      country={"us"}
                      value={phoneValue}
                      onChange={setPhoneValue}
                      inputProps={{
                        required: true,
                      }}
                    />
                    {phoneError && <p className="error-text">{phoneError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <label htmlFor="role" className="label">
                    Role *
                  </label>
                  <select name="role" id="role" className="custom-select-modal">
                    <option value="Corporate HQ Administrator">
                      Corporate HQ Administrator
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            id="close-btn"
            className="btn btn-secondary-fill btn-primary-16"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            // type="submit"
            onClick={addCorporateSubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={addBtnDisabled}
          >
            Add
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

export default AddCorporateModal;
