import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";

import CloseModal from "../../../../assets/close-outline.svg";
import data from "./Data";
import "./ShowTestsModal.scss";

const ShowTestsModal = (props) => {
  const headers = ["Attempt Date", "Status"];
  const { isShowModal, hideModal } = props;

  return (
    <Modal
      show={isShowModal}
      onHide={hideModal}
      backdrop="static"
      keyboard={false}
      centered
      scrollable
    >
      <Modal.Header className="cor-header">
        <span className="close-icon-modal " onClick={hideModal}>
          <img src={CloseModal} alt="" />
        </span>
      </Modal.Header>
      <Modal.Body>
        <h1 className="page-name-heading">Tests</h1>
        <li className="single-rc">
          <input
            type="search"
            name=""
            id=""
            placeholder="Search"
            className="custom-search"
          />
        </li>
        {/* <div className="right-cor-header">
                    <ul className="rc-header-listing">
                        <li className="single-rc">
                            <input
                                type="search"
                                name=""
                                id=""
                                placeholder="Search"
                                className="custom-search"
                            />
                        </li>
                    </ul>
                </div> */}
        <div className="table-responsive">
          <table className="table" id="en-width">
            <thead>
              <tr>
                {headers.map((heading) => (
                  <th key={heading}>
                    {heading}
                    {heading !== "Actions" && (
                      <span className="icons-th">
                        <span className="caret-4-desc caret-4-asc"></span>
                        <span className="order-4"></span>
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((record) => (
                <tr>
                  <td>{record.date}</td>
                  <td>
                    <span className="text-tag">
                      <span className={`tag ${record.status}-tag`}>
                        {record.status}
                      </span>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShowTestsModal;
