import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { defaultRouteMapping } from "./utils/helper";
import Loader from "./UI/loader/loader";

const LoginRoute = ({
  component: Component,
  ...rest
}) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);

  function goToDefaultRoute() {
    if (isCatchUAdmin === false) {
      for (let key in defaultRouteMapping) {
        const roleArray = defaultRouteMapping[key].roles;
        const route = defaultRouteMapping[key].route;
        for (let i = 0; i < roleArray.length; i++) {
          if (userRole.includes(roleArray[i])) {
            return route;
          }
        }
      }
    } else if (isCatchUAdmin === true) {
      for (let key in defaultRouteMapping) {
        const roleArray = defaultRouteMapping[key].roles;
        const permission = defaultRouteMapping[key].permission;
        const route = defaultRouteMapping[key].route;
        for (let i = 0; i < roleArray.length; i++) {
          if (userRole.includes(roleArray[i])) {
            return route;
          }
        }
        if (permissions.includes(permission)) {
          return route;
        }
      }
    }
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn && isCatchUAdmin === null) {
          return <Loader />;
        }

        if (!isLoggedIn) {
          return <Component {...rest} {...props} />;
        } else {
          const pathname = goToDefaultRoute();
          return (
            <Redirect
              to={{
                pathname,
              }}
            />
          );
        }
      }}
    />
  );
};


export default LoginRoute;
