import React from 'react';
import { useEffect, useState } from 'react';
import TableComponent from '../../components/UI/Table/TableData';
import { prepaidPatientsActions } from '../../store/prepaidPatients';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getWithExpiry } from '../utils/helper';
import { useParams } from "react-router-dom";
import { sidebarActions } from '../../store/sidebar';
import Loader from '../UI/loader/loader';
import useQueryString from '../../hooks/useQueryString';

const PrepaidPatients = () => {
    const prepaidPatientsHeading = [
        "First Name",
        "Last Name",
        "Provider Status Code",
        "Status"
    ];
    const [loading1, setLoading1] = useState(false);
    const { providerId } = useParams();
    const [otherLoader, setOtherLoader] = useState(false);
    const dispatch = useDispatch();
    const [dataFlag, setDataFlag] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const token = "Bearer " + getWithExpiry("expiry_token");
    const urlParams = useQueryString();

    let pageNumber = 1;
    const prepaidPatientsCurrentPage = useSelector(
        (state) => state.prepaidPatients.prepaidPatientsCurrentPage
    );
    const prepaidPatientsList = useSelector(
        (state) => state.prepaidPatients.prepaidPatientsList
    );
    const prepaidPatientsTotalRecords = useSelector(
        (state) => state.prepaidPatients.prepaidPatientsTotalRecords
    );
    const prepaidPatientsApiUrl = useSelector(
        (state) => state.prepaidPatients.prepaidPatientsApiUrl
    );

    useEffect(() => {
        let urlKeys = [...urlParams.keys()];
        let urlValues = [...urlParams.values()];
        let query = "";
        
        if (!prepaidPatientsApiUrl && urlKeys.length > 0) {
            query = "?";
            if (urlValues.includes("prepaid_patients_list")) {
                for (let i = 0; i < urlKeys.length; i++) {
                    if (urlKeys[i] !== "page") {
                        query += urlKeys[i] + "=" + urlValues[i] + "&";
                    }
                }
                if (query.endsWith("&")) {
                    query = query.slice(0, -1);
                }
            }
        } else {
            query = prepaidPatientsApiUrl ? prepaidPatientsApiUrl + "&" : "?";
        }
                
        let pageNumber = 1;
        if (urlValues.includes("prepaid_patients_list")) {
            pageNumber = urlParams.has("page")
                ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
                    ? urlParams.get("page")
                    : prepaidPatientsCurrentPage
                : prepaidPatientsCurrentPage;
        }
                
        query = query.replace("&&", "&");
        
        if (!showToast) {
            setLoading1(true);
            dispatch(sidebarActions.setSelectedNav("External Providers"));
        }
        axios({
            url: `${process.env.REACT_APP_URL_PATH}/user/prepaid_patients_list/${providerId}/${query}page=${pageNumber}`,
            method: "GET",
            headers: {
                "Content-Type": "Application/json",
                Authorization: token,
            },
        })
            .then((response) => {
                const resData = response.data.data.patients;
                dispatch(prepaidPatientsActions.setPrepaidPatientsList(resData));
                dispatch(
                    prepaidPatientsActions.setPrepaidPatientsPageNumbers(response.data.data.provider_pages)
                );
                dispatch(
                    prepaidPatientsActions.setPrepaidPatientsTotalRecords(
                        response.data.data.total_records
                    )
                );
                if (prepaidPatientsCurrentPage > response.data.data.provider_pages) {
                    dispatch(
                        prepaidPatientsActions.setPrepaidPatientsCurrentPage(
                            response.data.data.provider_pages
                        )
                    );
                }
                setLoading1(false);
                setOtherLoader(false)
            })
            .catch((error) => {
                setLoading1(false);
                setOtherLoader(false)

            });
    }, [dataFlag]);
    return (
        <div>
            {loading1 ? (
                <Loader />
            ) : (
                <TableComponent
                    tableHeadings={prepaidPatientsHeading}
                    data={prepaidPatientsList}
                    totalRecords={prepaidPatientsTotalRecords}
                    type="Prepaid Patients"
                    setDataFlag={setDataFlag}
                    setShowToast={setShowToast}
                    heading="Prepaid Patients"
                    otherLoader={otherLoader}
                    setOtherLoader={setOtherLoader}
                />

            )}
        </div>
    );
};

export default PrepaidPatients;