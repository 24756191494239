import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../store/auth";
import { ReactDOM, render } from "react-dom";
import BreadCrumb from "../Breadcrumb/Breadcrumb";
import Avatar_Image from "../../../assets/avatar-person.svg";
import SupportIcon from "../../../assets/support_call_icon.svg";

import Bell from "../../../assets/email.svg";

import Logout_Icon from "../../../assets/logout.svg";
import Account_Icon from "../../../assets/account.svg";
import "./Header.scss";
import { getWithExpiry } from "../../utils/helper";
import { userActions } from "../../../store/user";

import axios from "axios";
import { sidebarActions } from "../../../store/sidebar";
import AddAdminModel from "../Modals/Header/AddAdminModel";
import { toast } from "react-toastify";

function Header() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const selectedNav = useSelector((state) => state.sidebar.selectedNav);
  const token = "Bearer " + getWithExpiry("expiry_token");

  const logoutHandler = () => {
    localStorage.removeItem("expiry_token");
    sessionStorage.clear();
    dispatch(authActions.setIsLoggedIn(false));
    //dispatch(authActions.setRememberMe(false));
    window.location.reload();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    switch (selectedNav) {
      case "Corporates":
        break;
      case "Communities":
        break;
    }
  }, []);

  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/user/logged_user_details`,
      method: "get",
      headers: {
        "Content-Type": "Application/json",
        Authorization: token,
      },
    })
      .then((response) => {
        dispatch(userActions.setUsersdisplay(response?.data?.data));
      })
      .catch((error) => {
        if (error?.response?.data?.code === "token_not_valid") {
          toast.error("Your login session expired please Login again",{toastId: 'token_not_valid'});
          setTimeout(() => {
            logoutHandler()
          }, 3000)
        }
      });
  }, []);
  const selectedNavxx = useSelector((state) => state.sidebar.selectedNav);
  const displayUser = useSelector((state) => state.user.userdisplay);
  const userInfo = useSelector((state) => state.user.userInfo);
  const name = displayUser.name;
  const image = displayUser.profile_image;
  const is_catchu_admin = displayUser.is_catchu_admin;
  const userRoles = useSelector((state) => state.user.role).toString();
  const isSuperAdmin = JSON.parse(localStorage.getItem("an"));

  return (
    <div className="header-wrapper">
      {selectedNavxx !== "header" ? (
        <BreadCrumb />
      ) : (
        <div className="left-header"></div>
      )}
      {/* <div className="left-header"></div> */}

      <div className="right-header">
        {/* <a href="mailto:support@catchu.net">
          <img src={SupportIcon} alt="" />
          <span className="noti-circle"></span>
        </a> */}

        <Link to='/contact-us'><img src={SupportIcon} alt="" /></Link>

        <div className="dropdown header-d">
          <button
            className="custom-dropdown dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div className="icon">
              <span className="dropdown-avatar">
                <img
                  className="dav-image"
                  src={
                    userInfo?.profile_image
                      ? userInfo?.profile_image
                      : image
                      ? image
                      : Avatar_Image
                  }
                  alt=""
                />
              </span>
            </div>
            <div className="content-dropdown">
              <h1 className="cd-heading">
                {userInfo
                  ? userInfo?.first_name + " " + userInfo?.last_name
                  : name}
              </h1>
              <p className="cd-des" title={userRoles}>
                {userRoles}
              </p>
            </div>
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <Link
                to="/edit/profile/"
                className="dropdown-item second-item"
                //onClick={getUserProfile}
              >
                {/* <img className="dropdown-item-icon" src={Account_Icon} alt="" /> */}
                My Profile
              </Link>
            </li>
            {(Boolean(isSuperAdmin) && ["saqib.mehmood@tkxel.io", "link2mehar@gmail.com", "yair@catchu.net", "jeannette@catchu.net", "yair.catchu@yopmail.com", "yair.catchu911@yopmail.com", "yair.catchu007@yopmail.com", "yair.catchu111@yopmail.com"].includes(displayUser?.email)) &&  (
                <li>
                  <AddAdminModel
                    show={show}
                    handleClose={handleClose}
                    handleShow={handleShow}
                  />
                </li>
              )}
            {Boolean(isSuperAdmin) && (
              <li>
                <Link
                  to="/catchu-admin-signup"
                  className="dropdown-item second-item"
                  //onClick={getUserProfile}
                >
                  {/* <img className="dropdown-item-icon" src={Account_Icon} alt="" /> */}
                  Add CatchU Admin
                </Link>
              </li>
            )}
            <li>
              <Link
                to="/login"
                className="dropdown-item second-item"
                onClick={logoutHandler}
              >
                {/* <img className="dropdown-item-icon" src={Logout_Icon} alt="" /> */}
                Log Out
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
