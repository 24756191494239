import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { testActions } from "../../../../store/test";
import { removeEmptyParams } from "../../../../services/helpers/sorting.hepler";
import DatePicker from "react-datepicker";
import Moment from "moment";
import "./FiltersModal.scss";

import CloseModal from "../../../../assets/close-outline.svg";
import useQueryString from "../../../../hooks/useQueryString";

function FilterModal(props) {
  const {
    hideModal,
    orderBy,
    setDataFlag,
    setShowToast,
    setOtherLoader,
    testDateRange,
    setTestDateRange,
    dateRange,
    setDateRange,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const urlParams = useQueryString();
  // const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  // const [testDateRange, setTestDateRange] = useState([null, null]);
  const [testStartDate, testEndDate] = testDateRange;

  const searchFilteredText = useSelector(
    (state) => state.filter.searchScheduleText
  );

  const filterSubmissionHandler = () => {

    let dob_from = "";
    let dob_to = "";

    let test_from = "";
    let test_to = "";
    if (startDate && endDate) {
      setOtherLoader(true);
      dob_from = Moment(startDate.toLocaleDateString()).format("MM-DD-YYYY");
      dob_to = Moment(endDate.toLocaleDateString()).format("MM-DD-YYYY");
    }
    if (testStartDate && testEndDate) {
      test_from = Moment(testStartDate.toLocaleDateString()).format(
        "MM-DD-YYYY"
      );
      test_to = Moment(testEndDate.toLocaleDateString()).format("MM-DD-YYYY");
    }

    let customQuery = `search_query=${searchFilteredText}&${orderBy}&dob_from:eq=${dob_from}&dob_to:eq=${dob_to}&last_test_date_from:eq=${test_from}&last_test_date_to:eq=${test_to}`;

    var modifiedString = removeEmptyParams(customQuery);

    let searchParam = "";
    let prefix = "";
    if (modifiedString || urlParams.toString().includes("page")) {
      prefix = "table=schedule_test&";
    }
    if (
      !modifiedString.includes("page") &&
      urlParams.toString().includes("page")
    ) {
      searchParam = "?" + prefix + modifiedString + `&page=${1}`;
    } else {
      searchParam = "?" + prefix + modifiedString;
    }

    searchParam = searchParam.replace("&&", "&");
    history.replace({
      pathname: "/tests",
      search: searchParam,
    });
    dispatch(testActions.setScheduleFilteredDobFrom(dob_from));
    dispatch(testActions.setScheduleFilteredDobTo(dob_to));
    dispatch(testActions.setScheduleFilteredLastDataFrom(test_from));
    dispatch(testActions.setScheduleFilteredLastDataTo(test_to));

    dispatch(testActions.setScheduleTestApiUrl("?" + modifiedString));
    setShowToast(true);
    setDataFlag((prevState) => !prevState);
    hideModal();
  };

  const clearFilters = () => {
    var modifiedString = "";

    let searchParam = "";
    let prefix = "";
    if (modifiedString || urlParams.toString().includes("page")) {
      prefix = "table=schedule_test&";
    }
    if (
      !modifiedString.includes("page") &&
      urlParams.toString().includes("page")
    ) {
      searchParam = "?" + prefix + modifiedString + `&page=${1}`;
    } else {
      searchParam = "?" + prefix + modifiedString;
    }

    searchParam = searchParam.replace("&&", "&");
    history.replace({
      pathname: "/tests",
      search: searchParam,
    });
    dispatch(testActions.setScheduleFilteredDobFrom([]));
    dispatch(testActions.setScheduleFilteredDobTo([]));
    dispatch(testActions.setScheduleFilteredLastDataFrom([]));
    dispatch(testActions.setScheduleFilteredLastDataTo([]));
    setDateRange([null, null]);
    setTestDateRange([null, null]);
    dispatch(testActions.setScheduleTestApiUrl("?" + modifiedString));
    setShowToast(true);
    setDataFlag((prevState) => !prevState);
    hideModal();
  };
  return (
    <div className="filter-modal-wrapper">
      <Modal
        className="filter-modal initiate-test-patient-modal modal-loader"
        show={true}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
      >
        <Modal.Header className="filter-modal-header">
          <h1 className="modal-header-title">Filters</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="close-modal" />
          </span>
        </Modal.Header>
        <Modal.Body className="filter-modal-body show-select">
          <form
           
            method="post"
            className="add-corporate-form"
            // onSubmit={initiateTestSubmissionHandler}
          >
            <div className="field-wrapper">
              <label htmlFor="dob" className="label">
                Date of Birth
              </label>
              <div className="date-time-wrapper">
                <DatePicker
                  className="custom-dateTime-input"
                  placeholderText="Select your Date Range"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  dateFormat="MM/dd/yyyy"
                  maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  // isClearable={true}
                />
                <span className="dateTime-divider"></span>
              </div>
            </div>
            <div className="field-wrapper">
              <label htmlFor="last_test_date" className="label">
                Last Test Date
              </label>
              <div className="date-time-wrapper">
                <DatePicker
                  className="custom-dateTime-input"
                  placeholderText="Select your Test Date"
                  selectsRange={true}
                  startDate={testStartDate}
                  endDate={testEndDate}
                  onChange={(update) => {
                    setTestDateRange(update);
                  }}
                  dateFormat="MM/dd/yyyy"
                  //maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  // isClearable={true}
                />
                <span className="dateTime-divider"></span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16"
            onClick={clearFilters}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary-fill btn-primary-16"
            onClick={filterSubmissionHandler}
          >
            Apply Filter
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FilterModal;
