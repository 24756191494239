import React, { useState, Fragment } from "react";
import SelectComponent from "../../SelectComponent/SelectComponent";
import axios from "axios";
import useNotify from "../../../hooks/use-notify";
import EditCorporateModal from "../EditCorporateModal/EditCorporateModal";
import { useSelector } from "react-redux";
import Select from "react-select";
import Location from "../../../assets/location-icon.svg";
import { getWithExpiry } from "../../utils/helper";
import EnableModal from "../../Corporate/Modals/EnableModal/EnableModal";
import DisableModal from "../../Corporate/Modals/DisableModal/DisableModal";

const CorporateActions = (props) => {
  const { corporateInfo, corporateId, setDataFlag, setShowToast } = props;
  const [selectedVal, setSelectedVal] = useState("Corporate Action");

  const token = "Bearer " + getWithExpiry("expiry_token");
  const [editCorporateModalShow, setEditCorporateModalShow] = useState(false);
  const showEditModalHandler = () => setEditCorporateModalShow(true);
  const hideEditModalHandler = () => setEditCorporateModalShow(false);
  const { notifySuccess, notifyError } = useNotify();
  const corporateData = useSelector((state) => state.corporate.corporates);
  const corporateState = useSelector((state) => state.corporate.isEable);

  const options = [{ value: "edit", label: "Edit" }];
  let enable = { value: "enable", label: "Enable" };
  let disable = { value: "disable", label: "Disable" };
  corporateState ? options.push(disable) : options.push(enable);

 
  const [enabledId, setEnableId] = useState(0);
  const [disableId, setDisableId] = useState(0);
  const [disableModalOpen, setDisableModalOpen] = useState(false);
  const [enableModalOpen, setEnableModalOpen] = useState(false);

  const showEnableModal = () => {
    setEnableId(corporateId);
    setEnableModalOpen(true);
  };

  const hideEnableModal = () => setEnableModalOpen(false);

  const showDisableModal = () => {
    setEnableId(corporateId);
    setDisableModalOpen(true);
  };

  const handleEnableDisableModal = (event) => {
    const { value } = event.target;
    if (value === "enable") {
      setEnableId(corporateId);
      showEnableModal(true);
    }

    if (value === "disable") {
      setDisableId(corporateId);
      showDisableModal(true);
    }
  };

  const hideDisableModal = () => setDisableModalOpen(false);

  const selectCorporateAction = (selectedOption) => {
    setSelectedVal({ selectedOption });
    let value = selectedOption.value;

    if (value === "enable") {
      setEnableId(corporateId);
      showEnableModal(true);
    }

    if (value === "disable") {
      setDisableId(corporateId);
      showDisableModal(true);
    }

    if (value === "edit") {
      showEditModalHandler();
    }

    setSelectedVal({ label: "Corporate Action", value: "" });
  
  };

  const disableCorporate = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/corporate/disable_corporate/${corporateId}`,
      data: corporateId,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      })
      .catch((error) => {
        notifyError("Corporate Could Not Be Disabled");
      });
  };

  const enableCorporate = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/corporate/enable_corporate/${corporateId}`,
      data: corporateId,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      })
      .catch((error) => {
        notifyError("Corporate Could Not Be Enabled");
      });
  };



  return (
    <Fragment>
      {enableModalOpen && (
        <EnableModal
          type={"Corporate"}
          isShowModal={enableModalOpen}
          hideModal={hideEnableModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          enabledId={enabledId}
        />
      )}

      {disableModalOpen && (
        <DisableModal
          type={"Corporate"}
          isShowModal={disableModalOpen}
          hideModal={hideDisableModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          disabledId={disableId}
        />
      )}

      {editCorporateModalShow && (
        <EditCorporateModal
          isShowModal={showEditModalHandler}
          hideModal={hideEditModalHandler}
          type="Corporate"
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      <div className="edit-corporate-div">
        <div className="ecd-left">
          <h1 className="ecd-heading">{corporateInfo.corporateName}</h1>
          <span className="ecd-des-image">
            <img src={Location} alt="" />
            <p className="ecd-des">
              {`${corporateInfo.address1} ${corporateInfo.city}  ${corporateInfo.state} ${corporateInfo.zip}, ${corporateInfo.country} `}
            </p>
          </span>
        </div>
        <div className="ecd-right">
          {/* <div className="select-edit-corp">
            <Select
              isSearchable={false}
              value={selectedVal}
              onChange={selectCorporateAction}
              options={options}
              placeholder="Corporate Action"
            /> */}

          {/* <select
              className="custom-select"
              onChange={selectCorporateAction}
              value={selectedVal}
            >
              <option disabled selected value="Corporate Action">
                Corporate Action
              </option>

              {corporateState ? (
                <option value="disable">Disable</option>
              ) : (
                <option value="enable">Enable</option>
              )}
              <option value="edit">Edit</option>
            </select> */}
          {/* <SelectComponent
                            onChange={selectCorporateAction}
                            options={[{
                                label: 'Corporate Action',
                                value: 'corporate action'
                            }, {
                                label: 'Disable',
                                value: 'disable'
                            }, {
                                label: 'Edit',
                                value: 'edit'
                            }]} 
                        /> */}
          {/* </div> */}
          <ul className="cdh-right edit-communities-actions-top">
            <li className="cdh-inner-single">
              <button
                className="btn btn-primary-outline"
                onClick={showEditModalHandler}
              >
                Edit
              </button>
              {/* <button className="btn btn-primary-outline">Edit</button> */}
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default CorporateActions;
