import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { patientActions } from "../../../../store/patient";
import { removeEmptyParams } from "../../../../services/helpers/sorting.hepler";
import DatePicker from "react-datepicker";
import Moment from "moment";
import "./FiltersModal.scss";

import CloseModal from "../../../../assets/close-outline.svg";
import useQueryString from "../../../../hooks/useQueryString";
import CustomSelect from "../../../General/filters/CustomSelect.Component";

function FilterModal(props) {
  const { hideModal, orderBy, setDataFlag, setShowToast, setOtherLoader } =
    props;

  const history = useHistory();
  const dispatch = useDispatch();
  const { patientID } = useParams();
  const urlParams = useQueryString();
  const [filterData, setFilterData] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [testDateRange, setTestDateRange] = useState([null, null]);
  const [testStartDate, testEndDate] = testDateRange;

  const searchFilteredText = useSelector(
    (state) => state.filter.searchPatientHistoryText
  );
  const patientHistoryStatus = useSelector(
    (state) => state.patient.patientHistoryStatus
  );
  const returnSelectedArray = async (object) => {
    if (!object || object.length <= 0) return [];
    var _selected = [];
    object.map((dataKey) => {
      _selected.push(dataKey.value.trim());
    });
    // setSelectedFilter(_selected);
    return _selected;
  };

  const handleSelectStatus = async (e) => {
    var _selected = await returnSelectedArray(e);
    dispatch(patientActions.setPatientHistoryStatus(_selected));
    setFilterData(_selected);
  };
  const filterSubmissionHandler = () => {

    let dob_from = "";
    let dob_to = "";

    let test_from = "";
    let test_to = "";
    if (startDate && endDate) {
      setOtherLoader(true);
      dob_from =
        Moment(startDate.toLocaleDateString()).format("MM-DD-YYYY") +
        " 00:00:00";
      dob_to =
        Moment(endDate.toLocaleDateString()).format("MM-DD-YYYY") + " 00:00:00";
    }
    if (testStartDate && testEndDate) {
      test_from =
        Moment(testStartDate.toLocaleDateString()).format("MM-DD-YYYY") +
        " 00:00:00";
      test_to =
        Moment(testEndDate.toLocaleDateString()).format("MM-DD-YYYY") +
        " 00:00:00";
    }

    let customQuery = `search_query=${searchFilteredText}&${orderBy}&test_date_from:eq=${dob_from}&test_date_to:eq=${dob_to}&ordered_from:eq=${test_from}&ordered_to:eq=${test_to}&status:eq=${patientHistoryStatus}`;

    var modifiedString = removeEmptyParams(customQuery);

    let searchParam = "";
    if (
      !modifiedString.includes("page") &&
      urlParams.toString().includes("page")
    ) {
      searchParam = "?" + modifiedString + `&page=${1}`;
    } else {
      searchParam = "?" + modifiedString;
    }

    searchParam = searchParam.replace("&&", "&");
    history.replace({
      pathname: `/edit/patients/${patientID}`,
      search: searchParam,
    });

    dispatch(patientActions.setPatientHistoryTestDateFrom(dob_from));
    dispatch(patientActions.setPatientHistoryTestDateTo(dob_to));
    dispatch(patientActions.setPatientHistoryOrderedFrom(test_from));
    dispatch(patientActions.setPatientHistoryOrderedTo(test_to));

    dispatch(patientActions.setPatientHistoryApiUrl("?" + modifiedString));
    setShowToast(true);
    setDataFlag((prevState) => !prevState);
    hideModal();
  };
  const clearFilters = () => {
    var modifiedString = "";

    let searchParam = "";
    if (
      !modifiedString.includes("page") &&
      urlParams.toString().includes("page")
    ) {
      searchParam = "?" + modifiedString + `&page=${1}`;
    } else {
      searchParam = "?" + modifiedString;
    }

    searchParam = searchParam.replace("&&", "&");
    history.replace({
      pathname: `/edit/patients/${patientID}`,
      search: searchParam,
    });

    dispatch(patientActions.setPatientHistoryTestDateFrom([]));
    dispatch(patientActions.setPatientHistoryTestDateTo([]));
    dispatch(patientActions.setPatientHistoryOrderedFrom([]));
    dispatch(patientActions.setPatientHistoryOrderedTo([]));
    dispatch(patientActions.setPatientHistoryStatus(""));

    dispatch(patientActions.setPatientHistoryApiUrl("?" + modifiedString));
    setShowToast(true);
    setDataFlag((prevState) => !prevState);
    hideModal();
  };
  const statusData = ["Success", "Initiated", "Expired"];
  return (
    <div className="filter-modal-wrapper">
      <Modal
        className="filter-modal initiate-test-patient-modal modal-loader"
        show={true}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
      >
        <Modal.Header className="filter-modal-header">
          <h1 className="modal-header-title">Filters</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="close-modal" />
          </span>
        </Modal.Header>
        <Modal.Body className="filter-modal-body show-select">
          <form
           
            method="post"
            className="add-corporate-form"
            // onSubmit={initiateTestSubmissionHandler}
          >
            <div className="field-wrapper">
              <label htmlFor="dob" className="label">
                Test Date
              </label>
              <div className="date-time-wrapper">
                <DatePicker
                  className="custom-dateTime-input"
                  placeholderText="Select your Date Range"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  //timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy"
                  //showTimeInput
                  // maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  // isClearable={true}
                />
                <span className="dateTime-divider"></span>
              </div>
            </div>

            <div className="field-wrapper">
              <label htmlFor="last_test_date" className="label">
                Ordered
              </label>
              <div className="date-time-wrapper">
                <DatePicker
                  className="custom-dateTime-input"
                  placeholderText="Select your Schedule Date"
                  selectsRange={true}
                  startDate={testStartDate}
                  endDate={testEndDate}
                  onChange={(update) => {
                    setTestDateRange(update);
                  }}
                  //timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy"
                  //showTimeInput
                  //maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  // isClearable={true}
                />
                <span className="dateTime-divider"></span>
              </div>
            </div>

            <div className="field-wrapper">
              <CustomSelect
                selectedValues={patientHistoryStatus}
                labelText="Status"
                name="status"
                id="status"
                isMulti={true}
                data={statusData}
                handleSelectChange={handleSelectStatus}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16"
            onClick={clearFilters}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary-fill btn-primary-16"
            onClick={filterSubmissionHandler}
          >
            Apply Filter
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FilterModal;
