import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";

import axios from "axios";

// import images
import Filter_Icon from "../../../assets/filter-icon.svg";
import Edit from "../../../assets/edit.svg";
import Delete_Icon from "../../../assets/delete-btn.png";
import Save from "../../../assets/save.svg";
import Eye from "../../../assets/eye-icon.svg";
import Preview from "../../../assets/preview.svg";
import FilterClose from "../../../assets/filter-close.svg";
import { ToastContainer, toast } from "react-toastify";

import { filterActions } from "../../../store/filter";
import "react-toastify/dist/ReactToastify.css";

import AddCorporateModal from "../../Corporate/Modals/AddCorporateModal/AddCorporateModal";
import ImportCorporateModal from "../../Corporate/Modals/ImportCorporateModal/ImportCorporateModal";
// import FiltersModal from "../../Corporate/Modals/FiltersModal/FiltersModal";
import FiltersModal from "../Modals/FiltersModal/CorporateFilter";

import TableBody from "./TableBody";
import { corporateActions } from "../../../store/corporate";
import { rowOrderActions } from "../../../store/orders";

import Pagination from "../../UI/Pagination/Pagination";

import AuditLogsModal from "../../../components/General/AuditLogModal/AuditLogsModal.Component";
import AuditLogButton from "../../General/AuditLogModal/AuditLogButton.Component";
import SearchInput from "../../General/filters/SearchInput.Component";
import FilterButton from "../../General/filters/FilterButton.Component";
import useQueryString from "../../../hooks/useQueryString";
import Loader from "../../UI/loader/loader";
//Ordering Helpers
import {
  removeEmptyParams,
  setOrderAndIcon,
} from "../../../services/helpers/sorting.hepler";

// import "./Table.scss";

const AddImport = ({ showImportCorpModal, showModal, showAuditLogModal }) => {
  return (
    <>
      <li className="single-rc">
        <button
          className="btn btn-primary-outline"
          onClick={showImportCorpModal}
        >
          Import
        </button>
      </li>
      <li className="single-rc">
        <button
          className="btn btn-primary-fill btn-primary-16"
          onClick={showModal}
        >
          Add
        </button>
      </li>
    </>
  );
};

const TableData = (props) => {
  const dispatch = useDispatch();
  const {
    tableHeadings,
    type,
    deleteCorporate,
    setDataFlag,
    setShowToast,
    otherLoader,
    setOtherLoader,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const [addCorpModalOpen, setAddCorpModalOpen] = useState(false);
  const [importCorpModalOpen, setImportCorpModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [corporateURL, setCorporateURL] = useState("/corporates?");

  const showModal = () => setAddCorpModalOpen(true);
  const hideModal = () => setAddCorpModalOpen(false);
  const showImportCorpModal = () => setImportCorpModalOpen(true);
  const hideImportCorpModal = () => setImportCorpModalOpen(false);

  let data = useSelector((state) => state.corporate.corporates);

  const totalRecords = useSelector(
    (state) => state.corporate.corporateTotalRecords
  );
  const corporates = useSelector((state) => state.corporate.corporates);
  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);

  const [auditLogModalOpen, setAuditLogModalOpen] = useState(false);
  const [headingName, setHeadingName] = useState("");
  const showAuditLogModal = () => setAuditLogModalOpen(true);
  const hideAuditLogModal = () => setAuditLogModalOpen(false);

  const urlParams = useQueryString();
  const parm = useParams();
  const [search, setSearch] = useState(false);

  const searchFilteredText = useSelector((state) => state.filter.filterText);
  const filteredCountry = useSelector((state) => state.filter.filteredCountry);
  const filteredCity = useSelector((state) => state.filter.filteredCity);
  const filteredState = useSelector((state) => state.filter.filteredState);
  const filteredZip = useSelector((state) => state.filter.filteredZip);

  const handleSearchChange = (e) => {
    const { value, name } = e.target;
    dispatch(filterActions.setFilterText(value));
  };

  const corporateFormData = {
    name: "",
    address_01: "",
    address_02: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    first_name: "",
    last_name: "",
    email: "",
    telephone: "",
    role: "",
  };

  const orderMapping = {
    Name: "name",
    "Address 01": "address_1",
    "Address 02": "address_2",
    State: "state",
    City: "city",
    Zip: "zip",
  };

  const rowOrderState = useSelector(
    (state) => state.rowOrder.rowOrderCorporate
  );
  const orderQuery = useSelector((state) => state.rowOrder.orderQuery);
  const [orderState, setOrderState] = useState(rowOrderState);

  useEffect(() => {
    updateUrl();
  }, [orderState]);

  const buildRowOrderQuery = () => {
    let newOrderQuery = orderQuery.slice();
    if (orderMapping[headingName]) {
      let prevOrder =
        rowOrderState[orderMapping[headingName]].order === "asc"
          ? "desc"
          : "asc";
      let prevInstance = `&${orderMapping[headingName]}:orderby=${prevOrder}`;
      if (newOrderQuery.includes(prevInstance)) {
        newOrderQuery = newOrderQuery.replace(prevInstance, "");
      }
      if (!search) {
        newOrderQuery = `&${orderMapping[headingName]}:orderby=${
          rowOrderState[orderMapping[headingName]].order
        }`;
      }
    }
    dispatch(rowOrderActions.setOrderQuery(newOrderQuery));

    return newOrderQuery;
  };

  const buildFilterQuery = () => {
    let filterQuery = "";

    filterQuery =
      `country:eq=${filteredCountry}` +
      `&city:eq=${filteredCity}` +
      `&state:eq=${filteredState}` +
      `&zip:eq=${filteredZip}`;
    return filterQuery;
  };

  const buildSearchTextQuery = () => {
    let searchQuery = "";

    searchQuery =
      searchFilteredText !== "" ? `search_query=${searchFilteredText}` : "";
    return searchQuery;
  };
  const updateUrl = async () => {
    let searchQuery = buildSearchTextQuery();
    let newOrderQuery = buildRowOrderQuery();
    let filterQuery = buildFilterQuery();
    let customQuery = "";
    customQuery = `${searchQuery}${newOrderQuery}&${filterQuery}`;
    var modifiedString = removeEmptyParams(customQuery);
    let searchParam = "";
    if (!modifiedString) {
      searchParam =
        "?" +
        `${
          search === true
            ? modifiedString + `page=${search ? 1 : urlParams.get("page")}`
            : urlParams.toString()
        }`;
    } else {
      if (
        !modifiedString.includes("page") &&
        urlParams.toString().includes("page")
      ) {
        searchParam =
          "?" + modifiedString + `&page=${search ? 1 : urlParams.get("page")}`;
      } else {
        searchParam = "?" + modifiedString;
      }
    }

    searchParam = searchParam.replace("&&", "&");
    history.replace({
      pathname: "/corporates",
      search: searchParam,
    });

    var str =
      modifiedString && modifiedString !== "" ? "?" + modifiedString : "";

    dispatch(corporateActions.setCorporateApiUrl(str));
    setShowToast(true);

    setDataFlag((prevState) => !prevState);
    //setLoading(false);
  };

  const onClickTableHeading = async (e) => {
    setOtherLoader(true);
    const { id } = e.target;
    setSearch(false);
    setHeadingName(id);

    var name = "";
    switch (id) {
      case "Name":
        name = "name";

        var obj = setOrderAndIcon(rowOrderState[name]);
        dispatch(
          rowOrderActions.setCorporateOrder({
            ...rowOrderState,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            address_1: { state: 1, order: "", icon: "" },
            address_2: { state: 1, order: "", icon: "" },
            city: { state: 1, order: "", icon: "" },
            state: { state: 1, order: "", icon: "" },
            zip: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState({
          ...orderState,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;
      case "Address 01":
        name = "address_1";
        var obj = setOrderAndIcon(rowOrderState[name]);
        dispatch(
          rowOrderActions.setCorporateOrder({
            ...rowOrderState,
            name: { state: 1, order: "", icon: "" },
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            address_2: { state: 1, order: "", icon: "" },
            city: { state: 1, order: "", icon: "" },
            state: { state: 1, order: "", icon: "" },
            zip: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState({
          ...orderState,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;
      case "Address 02":
        name = "address_2";
        var obj = setOrderAndIcon(rowOrderState[name]);
        dispatch(
          rowOrderActions.setCorporateOrder({
            ...rowOrderState,
            name: { state: 1, order: "", icon: "" },
            address_1: { state: 1, order: "", icon: "" },
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            city: { state: 1, order: "", icon: "" },
            state: { state: 1, order: "", icon: "" },
            zip: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState({
          ...orderState,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;
      case "State":
        name = "state";
        var obj = setOrderAndIcon(rowOrderState[name]);
        dispatch(
          rowOrderActions.setCorporateOrder({
            ...rowOrderState,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            address_1: { state: 1, order: "", icon: "" },
            address_2: { state: 1, order: "", icon: "" },
            city: { state: 1, order: "", icon: "" },
            name: { state: 1, order: "", icon: "" },
            zip: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState({
          ...orderState,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;
      case "City":
        name = "city";
        var obj = setOrderAndIcon(rowOrderState[name]);
        dispatch(
          rowOrderActions.setCorporateOrder({
            ...rowOrderState,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            address_1: { state: 1, order: "", icon: "" },
            address_2: { state: 1, order: "", icon: "" },
            name: { state: 1, order: "", icon: "" },
            state: { state: 1, order: "", icon: "" },
            zip: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState({
          ...orderState,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;
      case "Zip":
        name = "zip";
        var obj = setOrderAndIcon(rowOrderState[name]);
        dispatch(
          rowOrderActions.setCorporateOrder({
            ...rowOrderState,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            address_1: { state: 1, order: "", icon: "" },
            address_2: { state: 1, order: "", icon: "" },
            city: { state: 1, order: "", icon: "" },
            state: { state: 1, order: "", icon: "" },
            name: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState({
          ...orderState,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;
      default:
        return;
    }
  };

  const onClearSorting = () => {
    setOtherLoader(true);
    dispatch(
      rowOrderActions.setCorporateOrder({
        ...rowOrderState,
        name: { state: 1, order: "", icon: "" },
        address_1: { state: 1, order: "", icon: "" },
        address_2: { state: 1, order: "", icon: "" },
        city: { state: 1, order: "", icon: "" },
        state: { state: 1, order: "", icon: "" },
        zip: { state: 1, order: "", icon: "" },
      })
    );
    dispatch(rowOrderActions.setOrderQuery(""));
    setHeadingName("");
    history.replace({
      pathname: "/corporates",
      search: "?page=1",
    });

    dispatch(corporateActions.setCorporateApiUrl(""));
    setShowToast(true);

    setDataFlag((prevState) => !prevState);
  };
  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      setOtherLoader(true);
      setSearch(true);
      setOrderState({
        ...orderState,
      });
    }
  };

  const onClearSearch = () => {
    setOtherLoader(true);
    dispatch(filterActions.setFilterText(""));
    setSearch(true);
    setOrderState({
      ...orderState,
    });
  };

  const showFilterModal = () => {
    setFilterModalOpen(true);
  };
  const hideFilterModal = () => setFilterModalOpen(false);

  const removeCityFilter = (city) => {
    if (
      filteredState.length === 0 &&
      filteredZip.length === 0 &&
      filteredCity.length === 1
    ) {
      dispatch(filterActions.setFilteredCity([]));
      history.replace({
        pathname: "/corporates",
        search: "",
      });
    } else {
      const updatedCity = filteredCity.filter((item) => item !== city);
      dispatch(filterActions.setFilteredCity(updatedCity));
    }
    setOrderState({
      ...orderState,
    });
  };
  const removeStateFilter = (state) => {
    if (
      filteredCity.length === 0 &&
      filteredZip.length === 0 &&
      filteredState.length === 1
    ) {
      dispatch(filterActions.setFilteredsState([]));
      history.replace({
        pathname: "/corporates",
        search: "",
      });
    } else {
      const updatedState = filteredState.filter((item) => item !== state);
      dispatch(filterActions.setFilteredsState(updatedState));
    }
    setOrderState({
      ...orderState,
    });
  };
  const removeZipFilter = (zip) => {
    if (
      filteredCity.length === 0 &&
      filteredState.length === 0 &&
      filteredZip.length === 1
    ) {
      dispatch(filterActions.setFilteredZip([]));
      history.replace({
        pathname: "/corporates",
        search: "",
      });
    } else {
      const updatedZip = filteredZip.filter((item) => item !== zip);
      dispatch(filterActions.setFilteredZip(updatedZip));
    }
    setOrderState({
      ...orderState,
    });
  };

  const clearFilters = () => {
    var modifiedString = "";
    dispatch(corporateActions.setCorporateApiUrl("?" + modifiedString));
    dispatch(filterActions.setFilteredCity([]));
    dispatch(filterActions.setFilteredsState([]));
    dispatch(filterActions.setFilteredZip([]));
    setOrderState({
      ...orderState,
    });
  };
  return (
    <Fragment>
      {/* For Audit Log */}
      {auditLogModalOpen && (
        <AuditLogsModal
          isShowModal={auditLogModalOpen}
          hideModal={hideAuditLogModal}
          LogTitle={"Audit Log"}
          type={type}
          dataType={""}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          refId={0}
        />
      )}
      {addCorpModalOpen && (
        <AddCorporateModal
          type={type}
          isShowModal={addCorpModalOpen}
          hideModal={hideModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      {importCorpModalOpen && (
        <ImportCorporateModal
          isShowModal={importCorpModalOpen}
          hideModal={hideImportCorpModal}
          dataType={type}
          modalFormData={corporateFormData}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      {filterModalOpen && (
        <FiltersModal
          isShowModal={filterModalOpen}
          hideModal={hideFilterModal}
          setShowToast={setShowToast}
          setDataFlag={setDataFlag}
          orderBy={orderQuery}
          setOtherLoader={setOtherLoader}
        />
      )}
      {otherLoader ? (
        <Loader />
      ) : (
        <div className="corpoarte-table">
          <div className="table-header-filter">
            <div className="cor-header-change">
              <h1 className="page-name-heading">Corporates</h1>
              <div className="right-cor-header">
                <ul className="rc-header-listing">
                  {/* <li className="single-rc">
                  <button onClick={onClearSorting}>Clear Sorting</button>
                </li> */}
                  {/* <li className="single-rc">
                  <button onClick={onClearSearch}>Clear Search</button>
                </li> */}
                  <li className="single-rc">
                    <SearchInput
                      value={searchFilteredText}
                      handleChange={handleSearchChange}
                      onKeyEnter={onEnterPress}
                      onClearSearch={onClearSearch}
                    />
                  </li>
                  <li className="single-rc">
                    <FilterButton handleClick={showFilterModal} />
                  </li>
                  <li className="single-rc">
                    <AuditLogButton handleClick={showAuditLogModal} />
                  </li>

                  {!isCatchUAdmin && userRole.includes("Super Admin") ? (
                    <AddImport
                      showImportCorpModal={showImportCorpModal}
                      showModal={showModal}
                      showAuditLogModal={showAuditLogModal}
                    />
                  ) : (
                    <>
                      {isCatchUAdmin &&
                        (userRole.includes("Super Admin") ||
                          permissions.includes("Corporate")) && (
                          <AddImport
                            showImportCorpModal={showImportCorpModal}
                            showModal={showModal}
                            showAuditLogModal={showAuditLogModal}
                          />
                        )}
                    </>
                  )}
                </ul>
              </div>
            </div>
            {(filteredCity.length !== 0 ||
              filteredState.length !== 0 ||
              filteredZip.length !== 0) && (
              <div className="filter-header">
                <h2 className="apply-filter-name">Applied Filters:</h2>
                <ul className="apply-filter-list">
                  {filteredCity.map((city) => (
                    <li className="apply-filter-single">
                      <h3 className="filter-name-type">
                        City: <strong>{city}</strong>{" "}
                      </h3>
                      <img
                        src={FilterClose}
                        alt=""
                        onClick={() => removeCityFilter(city)}
                      />
                    </li>
                  ))}
                  {filteredState.map((state) => (
                    <li className="apply-filter-single">
                      <h3 className="filter-name-type">
                        State: <strong>{state}</strong>{" "}
                      </h3>
                      <img
                        src={FilterClose}
                        alt=""
                        onClick={() => removeStateFilter(state)}
                      />
                    </li>
                  ))}
                  {filteredZip.map((zip) => (
                    <li className="apply-filter-single">
                      <h3 className="filter-name-type">
                        Zip: <strong>{zip}</strong>{" "}
                      </h3>
                      <img
                        src={FilterClose}
                        alt=""
                        onClick={() => removeZipFilter(zip)}
                      />
                    </li>
                  ))}
                </ul>
                <Link
                  to="/corporates"
                  className="clear-all-filter"
                  onClick={clearFilters}
                >
                  Clear all filters
                </Link>
              </div>
            )}
          </div>

          <div className="table-responsive">
            <table className="table" id="en-width">
              <thead>
                <tr>
                  {tableHeadings &&
                    tableHeadings.map((tableHead) => (
                      <th
                        id={tableHead}
                        name={tableHead}
                        key={tableHead}
                        onClick={onClickTableHeading}
                      >
                        {tableHead}
                        {tableHead !== "Actions" && (
                          <>
                            {rowOrderState[orderMapping[tableHead]].icon ===
                            "" ? (
                              <span className="icons-th">
                                <span></span>
                                <span className="order-4"></span>
                              </span>
                            ) : (
                              <span className="icons-th">
                                <span
                                  className={
                                    rowOrderState[orderMapping[tableHead]]
                                      .icon === "asc"
                                      ? "caret-4-asc-icon"
                                      : "caret-4-desc-icon"
                                  }
                                ></span>
                                <span className="order-4"></span>
                              </span>
                            )}
                          </>
                        )}
                      </th>
                    ))}
                  <th>Actions</th>
                </tr>
              </thead>

              <TableBody
                type={type}
                data={data}
                deleteCorporate={(corpId) => deleteCorporate(corpId)}
                setDataFlag={setDataFlag}
                setShowToast={setShowToast}
              />
            </table>
          </div>
          <Pagination
            data={data}
            totalRecords={totalRecords}
            type="corporate"
            setOtherLoader={setOtherLoader}
          />
        </div>
      )}

      {/* <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
    </Fragment>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     rowOrderState: state.rowOrder.rowOrderCorporate,
//   };
// };

export default TableData;
