import React, { useEffect, useState } from "react";
import useInput from "../../../hooks/use-input";
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import BrandLogo from "../../../assets/logo.png";
import ArrowRight from "../../../assets/arrow-right.svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { authActions } from "../../../store/auth";

const isNotEmpty = (value) => value.trim() !== "";
const isPasswordValid = (value) => {
  const passwordRegex = /^[a-zA-Z0-9]+$/;
  return value.trim().length >= 8 && passwordRegex.test(value);
};

const ChangePassword = () => {
  const history = useHistory();
  const email = useSelector((state) => state.auth.email);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const dispatch = useDispatch();
  const [disabledBtn, setDisabledBtn] = useState(false);

  const {
    value: newPasswordVal,
    isValid: newPasswordIsValid,
    hasError: newPasswordHasError,
    inputChangeHandler: newPasswordChangeHandler,
    inputBlurHandler: newPasswordBlurHandler,
    reset: newPasswordResetHandler,
  } = useInput(isPasswordValid);

  const {
    value: confirmNewPasswordVal,
    isValid: confirmNewPasswordIsValid,
    hasError: confirmNewPasswordHasError,
    inputChangeHandler: confirmNewPasswordChangeHandler,
    inputBlurHandler: confirmNewPasswordBlurHandler,
    reset: confirmNewPasswordResetHandler,
  } = useInput(isPasswordValid);

  const hasOtpVerification = useSelector((state) => state.auth.otpVerification);
  const successMessage = useSelector((state) => state.auth.successMessage);

  useEffect(() => {
    if (hasOtpVerification) {
      notifySuccess(successMessage);
      dispatch(authActions.resetVerifyOtpStatus(false));
    }
  }, []);

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  let formIsValid = false;


  const changePasswordSubmissionHandler = (event) => {
    event.preventDefault();
    setPasswordError("");
    setConfirmPasswordError("");
    setDisabledBtn(true);

    const fd = new FormData();
    fd.append("email", email);
    fd.append("new_password", newPasswordVal);
    fd.append("confirm_password", confirmNewPasswordVal);

    const confirmPassword = fd.get("confirm_password");

    // make an HTTP call to change password
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/user/forget_password_reset/`,
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        const successMessage = response.data.message;
        dispatch(authActions.setChangePasswordSuccessMsg(successMessage));
        history.replace("/login");
      })
      .catch((error) => {
        setDisabledBtn(false);
        if (error.response) {
          if (error.response.data.message === "Field Errors") {
            if ("new_password" in error.response.data.data) {
              setPasswordError(error.response.data.data["new_password"][0]);
            } else if ("confirm_password" in error.response.data.data) {
              setConfirmPasswordError(
                error.response.data.data["confirm_password"][0]
              );
            } else {
              setConfirmPasswordError(error.response.data.message);
            }
          } else {
            notifyError(error.response.data.message);
          }
        }
      });
  };

  const disabledBtnClass = disabledBtn ? "disabled" : "";

  return (
    <div className="login-screen-wrapper">
      <div className="login-box">
        <div className="login-logo">
          <img src={BrandLogo} alt="" />
        </div>
        <div className="login-header">
          <h1 className="login-heading">Reset Password</h1>
          {/* <p className="login-des">Please enter your email</p> */}
        </div>
        <form
          method="post"
          className="change-password-form"
          onSubmit={changePasswordSubmissionHandler}
        >
          <div className="login-field">
            <label htmlFor="new_password" className="login-label">
              New Password *
            </label>
            <input
              id="new_password"
              name="new_password"
              type="password"
              className="custom-input"
              placeholder="New Password"
              value={newPasswordVal}
              onChange={newPasswordChangeHandler}
              onBlur={newPasswordBlurHandler}
              required
            />
            {passwordError && <p className="error-text">{passwordError}</p>}
            {/* {newPasswordHasError && <p className="error-text">New password is not valid</p>} */}
          </div>
          <div className="login-field">
            <label htmlFor="confirm_password" className="login-label">
              Confirm Password *
            </label>
            <input
              id="confirm_password"
              name="confirm_password"
              type="password"
              className="custom-input"
              placeholder="Confirm Password"
              value={confirmNewPasswordVal}
              onChange={confirmNewPasswordChangeHandler}
              onBlur={confirmNewPasswordBlurHandler}
              required
            />
            {confirmPasswordError && (
              <p className="error-text">{confirmPasswordError}</p>
            )}
           
          </div>

          <ul className="check-forget-password back-login">
            <li className="password-forget">
              <Link to="/login">
                <p>Back to Login</p>
              </Link>
            </li>
          </ul>
          <div className="login-action">
            <button
              className={`btn btn-primary-fill btn-14-24 ${disabledBtnClass}`}
              disabled={disabledBtn}
            >
              Submit
              <img className="arrow-right" src={ArrowRight} alt="right-arrow" />
            </button>
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
