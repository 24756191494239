import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableComponent from "./Table/TableData";
import data from "./Data";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "./Corporate.scss";

import Users from "./Users/Users";
import Loader from "../UI/loader/loader";
import { authActions } from "../../store/auth";
import { corporateActions } from "../../store/corporate";
import { sidebarActions } from "../../store/sidebar";
import { userActions } from "../../store/user";
import { getWithExpiry } from "../utils/helper";
import useQueryString from "../../hooks/useQueryString";

const Corporate = () => {
  const tableHeadings = [
    "Name",
    "Address 01",
    "Address 02",
    "City",
    "State",
    "Zip",
  ];

  const urlParams = useQueryString();

  const [corporatesError, setCorporatesError] = useState("");
  const [loading, setLoading] = useState(true);
  const [otherLoader, setOtherLoader] = useState(false);
  const [dataFlag, setDataFlag] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);
  const corporates = useSelector((state) => state.corporate.corporates);
  const totalRecords = useSelector(
    (state) => state.corporate.corporateTotalRecords
  );

  const CorporatePageNumber = useSelector(
    (state) => state.corporate.corporateCurrentPage
  );

  const crumbs = useSelector((state) => state.sidebar.crumbs);
  const token = "Bearer " + getWithExpiry("expiry_token");
  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const deleteCorporate = (corpId) => {};

  const hasPasswordChanged = useSelector(
    (state) => state.auth.hasPasswordChanged
  );
  const successMessage = useSelector((state) => state.auth.successMessage);

  const otpVerification = useSelector((state) => state.auth.otpVerification);

  useEffect(() => {
    let timer;
    if (otpVerification) {
      timer = setTimeout(() => {
        // notifySuccess(`You are logged in as ${userRole.join(", ")}`);
        dispatch(authActions.resetVerifyOtpStatus());
      }, 1000);
    }
      return () => clearTimeout(timer);
  }, [otpVerification]); 

  useEffect(() => {
    if (hasPasswordChanged) {
      notifySuccess(successMessage);
      dispatch(authActions.resetPasswordChangeStatus(false));
    }
  }, []);

  const corporateApiUrl = useSelector(
    (state) => state.corporate.corporateApiURL
  );
  // Corporate
  useEffect(() => {
    if (!showToast) {
            //setLoading(true);
      //setShowToast(true);
      dispatch(sidebarActions.setSelectedNav("Corporate"));
    }
    let urlKeys = [...urlParams.keys()];
    let urlValues = [...urlParams.values()];

    let query;
    if (!corporateApiUrl && urlKeys.length > 0) {
      //setOtherLoader(true);
      query = "?";
      for (let i = 0; i < urlKeys.length; i++) {
        if (urlKeys[i] !== "page") {
          query = query + urlKeys[i] + "=" + urlValues[i] + "&";
        }
      }
    } else {
      query = corporateApiUrl ? corporateApiUrl + "&" : "?";
    }

    let pageNumber = urlParams.has("page")
      ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
        ? urlParams.get("page")
        : CorporatePageNumber
      : CorporatePageNumber;
    const Url = `${process.env.REACT_APP_URL_PATH}/corporate/corporates_list/${query}page=${pageNumber}`;

    query = query.replace("&&", "&");
    query = query.replace("#", "");
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/corporate/corporates_list/${query}page=${pageNumber}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        const corporates = response.data.data.corporates;
        const corporatesPage = response.data.data.corporate_pages;
        const { total_records } = response.data.data;

        dispatch(corporateActions.addCorporates(corporates));
        dispatch(corporateActions.addCorporatePage(corporatesPage));
        dispatch(corporateActions.addCorporateTotalRecords(total_records));
        if (CorporatePageNumber > response.data.data.corporate_pages) {
          dispatch(
            corporateActions.setCorporatePageNumber(
              response.data.data.corporate_pages
            )
          );
        }
        setLoading(false);
        setOtherLoader(false);
      })
      .catch((error) => {
        setLoading(false); // Add some clear instructions after this
        setOtherLoader(false);
      });
  }, [dataFlag]);

  return (
    <div className="corporate-wrapper">
      {/* {error && <p className="error-text" style={{fontSize: "14px"}}>{`Corporates ${error}`}</p>} */}
      {/* {corporatesError && (
        <p
          className="error-text"
          style={{ fontSize: "14px" }}
        >{`Corporates ${corporatesError}`}</p>
      )} */}
      {loading ? (
        <Loader />
      ) : (
        <>
          <TableComponent
            tableHeadings={tableHeadings}
            data={corporates} // corporates
            totalRecords={totalRecords}
            type="Corporate"
            deleteCorporate={deleteCorporate}
            setDataFlag={setDataFlag}
            setShowToast={setShowToast}
            otherLoader={otherLoader}
            setOtherLoader={setOtherLoader}
          />
<ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />     
              </>
      )}

      {/* <ToastContainer position="bottom-right" /> */}
    </div>
  );
};

export default Corporate;
