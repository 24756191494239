import React from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

import "./Homepage.scss";

function Homepage(props) {
  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content-wrapper">{props.children}</div>
    </div>
  );
}

export default Homepage;
