import { createSlice } from "@reduxjs/toolkit";

const initialExternalProvidersState = {
  PageNumber: 1,
  externalProvidersList: [],
  externalProvidersPageNumbers: 1,
  externalProvidersCurrentPage: 1,
  externalProvidersTotalRecords: 0,
  externalProvidersApiUrl: "",
};

const externalProvidersSlice = createSlice({
  name: "externalProviders",
  initialState: initialExternalProvidersState,
  reducers: {

    setExternalProvidersPageNumbers(state, action) {
      state.externalProvidersPageNumbers = action.payload;
    },
    setExternalProvidersCurrentPage(state, action) {
      state.externalProvidersCurrentPage = action.payload;
    },
    setExternalProvidersTotalRecords(state, action) {
      state.externalProvidersTotalRecords = action.payload;
    },
    setExternalProvidersApiUrl(state, action) {
      state.externalProvidersApiUrl = action.payload;
    },
   
    setExternalProvidersList(state, action) {
      state.externalProvidersList = action.payload;
    },

  },
});

export const externalProvidersActions = externalProvidersSlice.actions;

export default externalProvidersSlice.reducer;
