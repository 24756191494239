import React, { Fragment } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import Edit from "../../../assets/edit.svg";
import DisabledEdit from "../../../assets/disabled-edit.svg";
import Delete_Icon from "../../../assets/delete-btn.png";
import Delete_Btn from "../../../assets/delete-btn.png";
import Save from "../../../assets/save.svg";
import Eye from "../../../assets/eye-icon.svg";
import Preview from "../../../assets/preview.svg";
import { getWithExpiry } from "../../utils/helper";
import axios from "axios";
import "./Table.scss";
import DisableModal from "../../Corporate/Modals/DisableModal/DisableModal";
import { useDispatch, useSelector } from "react-redux";
import { corporateActions } from "../../../store/corporate";
import { communityActions } from "../../../store/community";
import { RegUserActions } from "../../../store/regUser";
import { sidebarActions } from "../../../store/sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EnableIcon from "../../../assets/checkicon.svg";
import ResendIcon from "../../../assets/resend-icon.svg";
import DisabledResendIcon from "../../../assets/disabled-resend.svg";
const RowActions = (props) => {
  const {
    showEditModalHandler,
    showDeleteModal,
    showDisableModal,
    showEnableModal,
    type,
    recordId,
    setId,
    isEnable,
    isDefault,
    dataType,
    showEditModal,
    delete_id,
    record,
    name,
    page,
    data,
    showResendModal,
  } = props;
  const token = "Bearer " + getWithExpiry("expiry_token");

  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);

  const history = useHistory();

  const params = useParams();

  const notifySuccesss = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const dispatch = useDispatch();
  const setBreadcrumb = (name, path) => {
    const record = {
      name: name,
      path: path,
    };
  };

  const deleteActionHandler = () => {
    props.deleteCorporate();
    showDeleteModal();
  };
  const dispatchActionTypeDelete = () => {
    if (!isDefault) {
      showDeleteModal();
      dispatch(communityActions.setActionType("delete"));
      setId(recordId, "delete");
    } else {
      notifyError("Default User Cannot be Deleted");
    }
  };
  const disabledClassName = isEnable ? "" : "disabled-icon";
  const defaultClassName = isDefault ? "disabled-icon" : "";

  const dispatchActionTypeDisable = () => {
    if (!isDefault) {
      showDisableModal();
      dispatch(corporateActions.setActionType("disable"));
      setId(recordId, "disable");
    } else {
      notifyError("Default User Cannot be Disabled");
    }
  };

  const dispatchActionTypeEnable = () => {
    showEnableModal();
    dispatch(corporateActions.setActionType("enable"));
    setId(recordId, "enable");
  };
  const dispatchActionTypeEdit = () => {
    showEditModal();
    dispatch(corporateActions.setIphoneEditId(recordId));
    dispatch(corporateActions.setIphoneEditnumber(data.number));
    dispatch(corporateActions.setIphoneEditDesc(data.description));
    setId(recordId, "edit");
  };

  const handleEditAction = () => {
    if (type === "Community" && dataType !== "iphone" && dataType !== "user") {
      showEditModalHandler();
      setId(recordId, "edit");
    }

    dispatch(RegUserActions.setIdToBeEdited(delete_id)); // I don't know why this is here

    if (page === "sub-page") {
      if (dataType === "user" && record.is_logged_in_user) {
        notifyError("Edit your details from the profile section");
      } else {
        axios({
          url: `${process.env.REACT_APP_URL_PATH}/community/update_community_user/${delete_id}`,
          method: "GET",
          headers: {
            "Content-Type": "Application/json",
            Authorization: token,
          },
        })
          .then((response) => {
            dispatch(RegUserActions.setRegUserInfo(response.data.data.user));
          })
          .catch((error) => {});
        showEditModal();
      }
    }
  };

  const handleDigAction = () => {
    if (type === "Community" && dataType !== "iphone" && dataType !== "user") {
      history.push(`/edit/communities/${recordId}`);
    }
  };

  const resendEmail = () => {
    if (record.is_logged_in_user) {
      notifyError("You cannot send email to yourself");
    } else {
      showResendModal();
      setId(record?.id, "resend");
    }
  };
  return (
    <Fragment>
      <td>
        <div>
          {type === "Corporate" && (
            <Link to="/edit/corporates" style={{ display: "inline" }}>
              <span title={"Edit"} className="td-action">
                <img src={Edit} alt="" />
              </span>
            </Link>
          )}
          {type === "Community" &&
            dataType !== "iphone" &&
            dataType !== "user" && (
              // <Link
              //   to={
              //     page === "main"
              //       ? `/edit/communities/${recordId}`
              //       : `/edit/communities/${idToBeEdited}`
              //   }
              //   style={{ display: "inline" }}
              // >
              //   <span
              //     className="td-action"
              //     title={"Edit"}
              //     onClick={handleEditAction}
              //   >
              //     <img src={Edit} alt="" />
              //   </span>
              // </Link>

              <>
                <span
                  className="td-action"
                  title={"Details"}
                  onClick={handleDigAction}
                >
                  <img src={Eye} alt="" />
                </span>
                {!isCatchUAdmin &&
                (userRole.includes("Super Admin") ||
                  userRole.includes("Community Admin") ||
                  userRole.includes("Individual Practice Admin") ||
                  userRole.includes("Corporate HQ Administrator")) ? (
                  <>
                    <span
                      className="td-action"
                      title={"Edit"}
                      onClick={handleEditAction}
                    >
                      <img src={Edit} alt="" />
                    </span>
                  </>
                ) : (
                  <>
                    {isCatchUAdmin &&
                      (userRole.includes("Super Admin") ||
                        userRole.includes("Community Admin") ||
                        userRole.includes("Individual Practice Admin") ||
                        userRole.includes("Corporate HQ Administrator") ||
                        permissions.includes("Community")) && (
                        <>
                          <span
                            className="td-action"
                            title={"Edit"}
                            onClick={handleEditAction}
                          >
                            <img src={Edit} alt="" />
                          </span>
                        </>
                      )}
                  </>
                )}
              </>
            )}
            
          {type === "Community" && dataType === "user" && (
            <>
              <span
                className="td-action"
                title={"Edit"}
                onClick={handleEditAction}
              >
                <img
                  src={record.is_logged_in_user ? DisabledEdit : Edit}
                  alt=""
                />
              </span>
              <span
                title="Resend Email"
                className="td-action"
                // className={`td-action ${disabledClassName}${defaultClassName}`}
                onClick={resendEmail}
              >
                <img
                  src={
                    record.is_logged_in_user ? DisabledResendIcon : ResendIcon
                  }
                  alt="edit-icon"
                />
              </span>
            </>
          )}
          {type === "Community" && dataType === "iphone" && (
            <span
              className="td-action"
              title={"Edit"}
              onClick={dispatchActionTypeEdit}
            >
              
              <img src={Edit} alt="" />
            </span>
            
          )}

          {/* uncomment the following to get the delete functionality */}
          {type === "Community" &&
          dataType !== "iphone" &&
          dataType !== "user" ?
          
          (
            <>
              {!isCatchUAdmin &&
              (userRole.includes("Super Admin") ||
                userRole.includes("Corporate HQ Administrator")) ? (
                <span
                  className="td-action"
                  title={"Delete"}
                  onClick={dispatchActionTypeDelete}
                >
                  <img
                    src={Delete_Btn}
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    alt="delete-icon"
                  />
                </span>
              ) : (
                <>
                  {isCatchUAdmin &&
                    (userRole.includes("Super Admin") ||
                      userRole.includes("Corporate HQ Administrator") ||
                      permissions.includes("Community")) && (
                      <span
                        className="td-action"
                        title={"Delete"}
                        onClick={dispatchActionTypeDelete}
                      >
                        <img
                          src={Delete_Btn}
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          alt="delete-icon"
                        />
                      </span>
                    )}
                </>
              )}
            </>
          ) : (
            <span
              className="td-action"
              title={"Delete"}
              onClick={dispatchActionTypeDelete}
            >
              <img
                src={Delete_Btn}
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                alt="delete-icon"
              />
            </span>
          )}

          {/* uncomment the following to get the enable/disable functionality */}
          {/* {isEnable && (
            <span
              // className={`td-action ${disabledClassName}${defaultClassName}`}
              className="td-action"
              title={"Disable"}
              onClick={dispatchActionTypeDisable}
            >
              <img src={Save} alt="disable-icon" />
            </span>
          )}
          {!isEnable && (
            <span
              className="td-action"
              title={"Enable"}
              onClick={dispatchActionTypeEnable}
            >
              <img src={EnableIcon} alt="enable-icon" />
            </span>
          )} */}
        </div>
      </td>
      {/* {actionType === 1 && <td>
                <span className="td-action" onClick={showTestsModal} >
                    <img src={Eye} alt="" />
                </span>
            </td>} */}
      {/* {actionType === 2 && <td>
                <span className="td-action">
                    <img src={Preview} alt="" />
                </span>
            </td>} */}
    </Fragment>
  );
};

export default RowActions;
