import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import Homepage from "./UI/Homepage/Homepage";
import NotFound from "./NotFound/NotFound";
import { useDispatch, useSelector } from "react-redux";
import { sidebarActions } from "../store/sidebar";
// import { rolesMapping } from "./utils/helper";
import routes from "../routes";

const rolesMapping = {
  Corporate: ["Super Admin", "Corporate HQ Administrator"],
  Community: [
    "Super Admin",
    "Corporate HQ Administrator",
    "Community Admin",
    "Individual Practice Admin",
    "Support Staff",
    "Provider",
  ],
  Patients: [
    "Super Admin",
    "Corporate HQ Administrator",
    "Support Staff",
    "Community Admin",
    "Individual Practice Admin",
    "Provider",
  ],
  Tests: [
    "Super Admin",
    "Community Admin",
    "Support Staff",
    "Provider",
    "Community Admin",
    "Individual Practice Admin",
  ],
  Providers: [
    "Super Admin",
    "Community Admin",
    "Individual Practice Admin",
    "Corporate HQ Administrator",
    "Support Staff",
  ],
  Users: [
    "Super Admin",
    "Corporate HQ Administrator",
    "Community Admin",
    "Individual Practice Admin",
  ],
  Billing: [
    "Super Admin",
    "Community Admin",
    "Individual Practice Admin",
    "Billee",
  ],
  Equipments: [
    "Super Admin",
    "Community Admin",
    "Individual Practice Admin",
    "Corporate HQ Administrator",
    "Support Staff",
    "CatchU Admin"
  ],
  ContactUs: [
    "Super Admin",
    "Community Admin",
    "Individual Practice Admin",
    "Corporate HQ Administrator",
    "Support Staff",
    "CatchU Admin",
    "Billee",
    "Provider"
  ],
};
const ProtectedRoute = ({
  type,
  path,
  name,
  dynamic,
  isLoggedIn,
  newTabOpen,
  userRole,
  permissions,
  isCatchUAdmin,
  component: Component,
  redirect: pathname,
  ...rest
}) => {
  const dispatch = useDispatch();
  const corpName = useSelector((state) => state.crumbs.CorporateName);
  const commName = useSelector((state) => state.crumbs.CommunityName);
  const patName = useSelector((state) => state.crumbs.PatientName);
  const prepaidPatName = useSelector((state) => state.crumbs.PrepaidPatName);

  // Users that are not catchu admin
  function checkPermission(userType) {
    if (userRole.includes("Super Admin")) {
      return true;
    } else {
      const roles = rolesMapping[userType];
      for (let i = 0; i < roles?.length; i++) {
        if (userRole.includes(roles[i])) {
          return true;
        }
      }
      return false;
    }
  }

  // catchu admin
  function checkAdminPermission(userType) {
    const userRolePermission = checkPermission(userType);
    if (!userRolePermission) {
      if (!permissions.includes(userType)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  function getNameID(match) {
    const myArr = [];
    if (match.params.corporateID) {
      myArr.push(corpName[match.params.corporateID]);
    }
    if (match.params.communityID) {
      myArr.push(commName[match.params.communityID]);
    }
    if (match.params.patientID) {
      myArr.push(patName[match.params.patientID]);
    }
    if (match.params.providerId) {
      myArr.push(prepaidPatName[match.params.providerId]);
    }

    return myArr;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        const routeIDs = getNameID(props.match);

        const crumbs = routes
          // Get all routes that contain the current one.
          .filter(({ path }) => props.match.path.includes(path))
          .map(({ name, path, dynamic, ...rest }, index) => ({
            name: dynamic === "yes" ? routeIDs[index - 1] : name,
            path: Object.keys(props.match.params).length
              ? Object.keys(props.match.params).reduce(
                (path, param) =>
                  path.replace(`:${param}`, props.match.params[param]),
                path
              )
              : path,
            dynamic,
            ...rest,
          }));

        dispatch(sidebarActions.setNewCrumbs(crumbs));

        if (!newTabOpen || isCatchUAdmin === null) {

          if (isLoggedIn === false) {

            return (
              <Redirect
                to={{
                  pathname,
                }}
              />
            );
          } else {
            return <div></div>;
          }
        }

        if (isLoggedIn && isCatchUAdmin === false) {
          if (checkPermission(type)) {

            return (
              <Homepage>
                <Component {...rest} {...props} />
              </Homepage>
            );
          } else {
            return (
              <Homepage>
                <NotFound {...rest} {...props} />
              </Homepage>
            );
          }
        } else if (isLoggedIn && isCatchUAdmin) {

          if (checkAdminPermission(type)) {
            return (
              <Homepage>
                <Component {...rest} {...props} />
              </Homepage>
            );
          } else {
            return (
              <Homepage>
                <NotFound {...rest} {...props} />
              </Homepage>
            );
          }
        } else {
          return (
            <Redirect
              to={{
                pathname,
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
