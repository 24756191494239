import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";

import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getWithExpiry } from "../../utils/helper";
import axios from "axios";
import Select, { components } from "react-select";
import "./EditProvider.scss";
import Loader2 from "../../UI/loader2/loader2";
// import images
import CloseModal from "../../../assets/close-outline.svg";
import AddSectionIcon from "../../../assets/add-billing-icon.svg";
import DeleteIcon from "../../../assets/delete-btn.png";

const billeeForm = {
  billee_id: "none",
  company: "",
  billee_first_name: "",
  billee_last_name: "",
  billee_email: "",
  billee_telephone_number: "",
  billee_address_1: "",
  billee_address_2: "",
  billee_country: "",
  billee_city: "",
  billee_state: "",
  billee_zip: "",
  is_delete: false,
};
const EditProvider = (props) => {
  const { isShowModal, hideModal, data, setDataFlag, setShowToast } = props;

  const token = "Bearer " + getWithExpiry("expiry_token");

  const [loading, setLoading] = useState(false);
  const [addBtnDisabled, setAddBtnDisabled] = useState(false);

  const editProviderInfo = useSelector(
    (state) => state.provider.editProviderInfo
  );

  /* Provider fields */
  const [titleVal, setTitleVal] = useState("");
  const [suffixVal, setSuffixVal] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  // Billee Fields
  const [company, setCompany] = useState("");

  const [billeeFirstName, setBilleeFirstName] = useState("");
  const [billeeLastName, setBilleeLastName] = useState("");
  const [billeeEmail, setBilleeEmail] = useState("");
  const [billeePhone, setBilleePhone] = useState("");

  const [communityList, setCommunityList] = useState([]);
  const [billCommunityList, setBillCommunityList] = useState([]);
  const [provider, setProvider] = useState(null);
  const [billees, setBillees] = useState([]);

  /* Provider field errors */
  const [titleError, setTitleError] = useState("");
  const [suffixError, setSuffixError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  /* Billee field errors */
  const [companyError, setCompanyError] = useState([""]);
  const [billeeFirstNameError, setBilleeFirstNameError] = useState([""]);
  const [billeeLastNameError, setBilleeLastNameError] = useState([""]);
  const [billeeEmailError, setBilleeEmailError] = useState([""]);
  const [billeePhoneError, setBilleePhoneError] = useState([""]);

  const [billeeAddress1Error, setBilleeAddress1Error] = useState([""]);
  const [billeeAddress2Error, setBilleeAddress2Error] = useState([""]);
  const [billeeCountryError, setBilleeCountryError] = useState([""]);
  const [billeeCityError, setBilleeCityError] = useState([""]);
  const [billeeStateError, setBilleeStateError] = useState([""]);
  const [billeeZipError, setBilleeZipError] = useState([""]);

  const [billeeCount, setBilleeCount] = useState(null);
  const [phoneValue, setPhoneValue] = useState("");

  const [showAddBtn, setShowAddBtn] = useState(0);

  const [scrollVar, setScrollVar] = useState(false);
  const myRef = useRef(null);
  const myArr = [];
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  useEffect(() => {
    setLoading(true);
    if (
      Object.entries(editProviderInfo) &&
      Object.entries(editProviderInfo).length !== 0
    ) {
      setProvider(editProviderInfo?.provider);
      setPhoneValue(editProviderInfo?.provider.telephone_number);

      if (editProviderInfo?.billee_data?.length === 0) {
        setBillees([billeeForm]);
       
        myArr.push(1);
      } else {
        setBillees(editProviderInfo?.billee_data);
        editProviderInfo?.billee_data.map((value, index) => {
          myArr.push(index + 1);
        });
      }
      setBilleeCount(myArr);

      const communityArray = editProviderInfo?.provider?.communities?.map(
        (data) => ({
          value: data.community_id,
          label: data.community_name,
        })
      );
      const billCommunityArray =
        editProviderInfo?.provider?.bill_communities?.map((data) => ({
          value: data.community_id,
          label: data.community_name,
        }));

      setCommunityList(communityArray);
      setBillCommunityList(billCommunityArray);
    }
    setLoading(false);
  }, [editProviderInfo]);

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });


  const providerChangeHandler = (e) => {
    if (provider) {
      const { name, value } = e.target;
      setProvider({ ...provider, [name]: value });
    }
  };

  const billeeChangeHandler = (e, index) => {
    if (billees) {
      const { name, value } = e.target;
      let items = [...billees];
      let item = items[index];
      item = { ...item, [name]: value };
      items[index] = item;

      setBillees(items);

    }
  };

  const ProviderDataCommunities = useSelector(
    (state) => state.provider.ProviderCommunites
  );

  const communityProviderList = ProviderDataCommunities.map((data) => ({
    value: data.id,
    label: data.community_name,
  }));

  const removeBilleeFormHandler = (e, index) => {
    e.preventDefault();
  
    let items = [...billees];
    let item = items[index];
    item = { ...item, is_delete: true };
    items[index] = item;

    setBillees(items);
    const updatedBilleeCount = billeeCount.map((value, position) => {
      if (position >= index) {
        return value - 1;
      }
      return value;
    });
    setBilleeCount(updatedBilleeCount);
  };
  const addBilleeFormHandler = (event) => {
    event.preventDefault();
    //

    setBillees([...billees, billeeForm]); // add a new form
    let indVal = billeeCount[billeeCount.length - 1];
    setBilleeCount([...billeeCount, indVal + 1]);

    setCompanyError([...companyError, ""]);
    setBilleeFirstNameError([...billeeFirstNameError, ""]);
    setBilleeLastNameError([...billeeLastNameError, ""]);
    setBilleeEmailError([...billeeEmailError, ""]);
    setBilleePhoneError([...billeePhoneError, ""]);
    setBilleeAddress1Error([...billeeAddress1Error, ""]);
    setBilleeCountryError([...billeeCountryError, ""]);
    setBilleeCityError([...billeeCityError, ""]);
    setBilleeStateError([...billeeStateError, ""]);
    setBilleeZipError([...billeeZipError, ""]);

    setCompanyError([""]);
    setBilleeFirstNameError([""]);
    setBilleeLastNameError([""]);
    setBilleeEmailError([""]);
    setBilleePhoneError([""]);
    setBilleeAddress1Error([""]);
    setBilleeAddress2Error([""]);
    setBilleeCountryError([""]);
    setBilleeCityError([""]);
    setBilleeStateError([""]);
    setBilleeZipError([""]);

    setScrollVar((prevState) => !prevState);
  };

  useEffect(() => {
    if (billees.length > 1) {
      executeScroll();
    }
  }, [scrollVar]);

  function checkDeletedEmptyForm(form) {
    if (form.billee_id === "none" && form.is_delete === true) {
      return true;
    } else {
      return false;
    }
  }

  function checkEmptyForms(forms) {
    let check = true;
    for (var i = 0; i < forms.length; i++) {
      if (!forms[i].is_delete) {
        if (
          forms[i].billee_address_1 !== "" ||
          forms[i].billee_address_2 !== "" ||
          forms[i].billee_city !== "" ||
          forms[i].billee_country !== "" ||
          forms[i].billee_email !== "" ||
          forms[i].billee_first_name !== "" ||
          forms[i].billee_last_name !== "" ||
          forms[i].billee_state !== "" ||
          forms[i].billee_telephone_number !== "" ||
          forms[i].billee_zip !== "" ||
          forms[i].company !== ""
        ) {
          check = false;
          break;
        }
      }
    }

    return check;
  }
  const checkIsDelete = (forms) => {
    let check = false;
    for (var i = 0; i < forms.length; i++) {
      if (forms[i].is_delete) {
        check = true;
        break;
      }
    }

    return check;
  };
  const editProviderSubmissionHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    setAddBtnDisabled(true);

    const checkForm = checkEmptyForms(billees);
    const isDelete = checkIsDelete(billees);

    /* Reset Provider fields */
    setTitleError("");
    setSuffixError("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");

    /* Reset Billee fields */
    setCompanyError([""]);
    setBilleeFirstNameError([""]);
    setBilleeLastNameError([""]);
    setBilleeEmailError([""]);
    setBilleePhoneError([""]);
    setBilleeAddress1Error([""]);
    setBilleeAddress2Error([""]);
    setBilleeCountryError([""]);
    setBilleeCityError([""]);
    setBilleeStateError([""]);
    setBilleeZipError([""]);

    //setCommunityList([]);
    // create form data

    const form = document.querySelector(".edit-provider-form");
    const fd = new FormData(form);

    //let phoneVal = fd.get("telephone_number").replace(/\s+/g, "");
    if (phoneValue && !phoneValue.includes("+", 0)) {
      fd.append("telephone_number", "+" + phoneValue);
    } else if (phoneValue) {
      fd.append("telephone_number", phoneValue);
    }

    let billeeObj = {};
    if (!checkForm) {
      billees.map((billee, index) => {
        let key = "billee_" + (index + 1).toString();
        if (!checkDeletedEmptyForm(billee)) {
          billeeObj[key] = billee;
        }
      });
    } else if (isDelete) {
      billees.map((billee, index) => {
        let key = "billee_" + (index + 1).toString();
        if (!checkDeletedEmptyForm(billee) && billee.is_delete) {
          billeeObj[key] = billee;
        }
      });
    }

    fd.append("billee_data", JSON.stringify(billeeObj));
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/community/update_provider/${provider?.id}`,
      method: "post",
      data: fd,
      headers: {
        "Content-Type": "Application/json",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.message === "Field Errors") {
          if (
            error.response.data.data["provider_form"] &&
            "first_name" in error.response.data.data["provider_form"]
          ) {
            setFirstNameError(
              error.response.data.data["provider_form"]["first_name"]
            );
          }
          if (
            error.response.data.data["provider_form"] &&
            "last_name" in error.response.data.data["provider_form"]
          ) {
            setLastNameError(
              error.response.data.data["provider_form"]["last_name"]
            );
          }
          if (
            error.response.data.data["provider_form"] &&
            "email" in error.response.data.data["provider_form"]
          ) {
            setEmailError(error.response.data.data["provider_form"]["email"]);
          }
          if (
            error.response.data.data["phone_form"] &&
            "telephone_number" in error.response.data.data["phone_form"]
          ) {
            setPhoneError(
              error.response.data.data["phone_form"]["telephone_number"]
            );
          }

          // Billee Errors
          if (error.response.data.data["billee_form"]) {
            const billeeIndex = error.response.data.data["billee_form"];
            const index = parseInt(Object.keys(billeeIndex)[0].split("_")[1]);
            const errorKey = Object.keys(billeeIndex)[0];
            if (
              error.response.data.data["billee_form"][errorKey] &&
              "billee_address_1" in
                error.response.data.data["billee_form"][errorKey]
            ) {
              let items = [...billeeAddress1Error];
              let item = items[index - 1];
              item =
                error.response.data.data["billee_form"][errorKey][
                  "billee_address_1"
                ];
              items[index - 1] = item;
              setBilleeAddress1Error(items);
            }
            if (
              error.response.data.data["billee_form"][errorKey] &&
              "billee_city" in error.response.data.data["billee_form"][errorKey]
            ) {
              let items = [...billeeAddress1Error];
              let item = items[index - 1];
              item =
                error.response.data.data["billee_form"][errorKey][
                  "billee_city"
                ];
              items[index - 1] = item;
              setBilleeCityError(items);
            }
            if (
              error.response.data.data["billee_form"][errorKey] &&
              "billee_country" in
                error.response.data.data["billee_form"][errorKey]
            ) {
              let items = [...billeeAddress1Error];
              let item = items[index - 1];
              item =
                error.response.data.data["billee_form"][errorKey][
                  "billee_country"
                ];
              items[index - 1] = item;
              setBilleeCountryError(items);
            }
            if (
              error.response.data.data["billee_form"][errorKey] &&
              "billee_email" in
                error.response.data.data["billee_form"][errorKey]
            ) {
              let items = [...billeeAddress1Error];
              let item = items[index - 1];
              item =
                error.response.data.data["billee_form"][errorKey][
                  "billee_email"
                ];
              items[index - 1] = item;
              setBilleeEmailError(items);
            }
            if (
              error.response.data.data["billee_form"][errorKey] &&
              "billee_first_name" in
                error.response.data.data["billee_form"][errorKey]
            ) {
              let items = [...billeeAddress1Error];
              let item = items[index - 1];
              item =
                error.response.data.data["billee_form"][errorKey][
                  "billee_first_name"
                ];
              items[index - 1] = item;
              setBilleeFirstNameError(items);
            }
            if (
              error.response.data.data["billee_form"][errorKey] &&
              "billee_last_name" in
                error.response.data.data["billee_form"][errorKey]
            ) {
              let items = [...billeeAddress1Error];
              let item = items[index - 1];
              item =
                error.response.data.data["billee_form"][errorKey][
                  "billee_last_name"
                ];
              items[index - 1] = item;
              setBilleeLastNameError(items);
            }
            if (
              error.response.data.data["billee_form"][errorKey] &&
              "billee_state" in
                error.response.data.data["billee_form"][errorKey]
            ) {
              let items = [...billeeAddress1Error];
              let item = items[index - 1];
              item =
                error.response.data.data["billee_form"][errorKey][
                  "billee_state"
                ];
              items[index - 1] = item;
              setBilleeStateError(items);
            }
            if (
              error.response.data.data["billee_form"][errorKey] &&
              "billee_telephone_number" in
                error.response.data.data["billee_form"][errorKey]
            ) {
              let items = [...billeeAddress1Error];
              let item = items[index - 1];
              item =
                error.response.data.data["billee_form"][errorKey][
                  "billee_telephone_number"
                ];
              items[index - 1] = item;
              setBilleePhoneError(items);
            }
            if (
              error.response.data.data["billee_form"][errorKey] &&
              "billee_zip" in error.response.data.data["billee_form"][errorKey]
            ) {
              let items = [...billeeAddress1Error];
              let item = items[index - 1];
              item =
                error.response.data.data["billee_form"][errorKey]["billee_zip"];
              items[index - 1] = item;
              setBilleeZipError(items);
            }
            if (
              error.response.data.data["billee_form"][errorKey] &&
              "company" in error.response.data.data["billee_form"][errorKey]
            ) {
              let items = [...billeeAddress1Error];
              let item = items[index - 1];
              item =
                error.response.data.data["billee_form"][errorKey]["company"];
              items[index - 1] = item;
              setCompanyError(items);
            }
           
          }
        } else {
          notifyError(error.response.data.message);
        }
        setAddBtnDisabled(false);
      });
  };

  const selectTitle = (e) => {
    setTitleVal(e.target.value);
  };
  const options = [
    communityList.map((data) => ({
      value: data.value,
      label: data.label,
    })),
  ];

  const handleSelectChange = (arr) => {
    setCommunityList(arr);
  };
  const returnSelectedArray = async (object) => {
    if (!object || object.length <= 0) return [];
    var _selected = [];
    object.map((dataKey) => {
      _selected.push({
        id: dataKey.value,
        value: dataKey.value,
        label: dataKey.label,
      });
    });
    return _selected;
  };
  const [selectedCommunity, setSelectedCommuntiy] = useState([]);

  const handleSelectedCommunity = async (e) => {
    var _selected = await returnSelectedArray(e);
    setCommunityList(_selected);
  };
  const hideModalHandler = () => {
    hideModal();
  };

  if (!editProviderInfo) {
    return null;
  }
  return (
    <div className="action-modal-wrapper">
      <Modal
        className="comunitie-modal modal-loader"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header
          className="comubitie-modal-header modal-header"
          id="rm-padding"
        >
          <h1 className="modal-header-title">Edit Provider</h1>
          <span className="close-icon-modal " onClick={hideModalHandler}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="comunitie-modal-body">
          <form
            
            method="post"
            className="edit-provider-form"
            onSubmit={editProviderSubmissionHandler}
          >
            <div className="comunties-fields">
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="title" className="label">
                      Title *
                    </label>
                    <select
                      id="title"
                      name="title"
                      className="custom-select-modal"
                      onChange={providerChangeHandler}
                      value={provider ? provider.title : ""}
                    >
                      <option value="MD">MD</option>
                      <option value="PA">PA</option>
                      <option value="NP">NP</option>
                      <option value="DO">DO</option>
                    </select>
                    {titleError && <p className="error-text">{titleError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="suffix" className="label">
                      Suffix
                    </label>
                    <input
                      id="suffix"
                      name="suffix"
                      type="text"
                      placeholder="Suffix"
                      className="custom-input"
                      value={provider ? provider.suffix : ""}
                      onChange={providerChangeHandler}
                      // onBlur={nameBlurHandler}
                      required
                    />
                    {suffixError && <p className="error-text">{suffixError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="first_name" className="label">
                      First Name *
                    </label>
                    <input
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="First Name"
                      className="custom-input"
                      value={provider ? provider.first_name : ""}
                      onChange={providerChangeHandler}
                      // onBlur={nameBlurHandler}
                      required
                    />
                    {firstNameError && (
                      <p className="error-text">{firstNameError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="last_name" className="label">
                      Last Name *
                    </label>
                    <input
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Last Name"
                      className="custom-input"
                      value={provider ? provider.last_name : ""}
                      onChange={providerChangeHandler}
                      // onBlur={nameBlurHandler}
                      required
                    />
                    {lastNameError && (
                      <p className="error-text">{lastNameError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="email" className="label">
                      Email *
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      className="custom-input"
                      value={provider ? provider.email : ""}
                      onChange={providerChangeHandler}
                      // onBlur={nameBlurHandler}
                      required
                    />
                    {emailError && <p className="error-text">{emailError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="telephone_number" className="label">
                      Telephone *
                    </label>
                    {/* <input
                      id="telephone_number"
                      name="telephone_number"
                      type="text"
                      placeholder="Telephone"
                      className="custom-input"
                      onChange={(e) => {
                        const { name, value } = e.target;

                        const regex = /^([\s+0-9])+$/i;
                        if (value === "") {
                          // case to remove whole highlighted text
                          setProvider({ ...provider, [name]: value });
                          return;
                        }
                        if (regex.test(value) === false) return;

                        if (value.length === 1 && value === "+") {
                          // case to remove + sign when last number is removed
                          setProvider({ ...provider, [name]: "" });
                        } else if (value.length >= 1 && value[0] !== "+") {
                          // case to append + sign with first number
                          setProvider({ ...provider, [name]: "+" + value });
                        } else {
                          // case to append other numbers
                          setProvider({ ...provider, [name]: value });
                        }
                      }}
                      value={provider ? provider.telephone_number : ""}
                      required
                    /> */}
                    <PhoneInput
                      country={"us"}
                      value={phoneValue}
                      onChange={setPhoneValue}
                      inputProps={{
                        required: true,
                      }}
                    />
                    {phoneError && <p className="error-text">{phoneError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="community" className="label">
                      Associated Communities *
                    </label>
                    <Select
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isMulti
                      options={communityProviderList}
                      value={communityList}
                      id="community_id"
                      name="community_id"
                      onChange={handleSelectedCommunity}
                      closeMenuOnSelect={false}
                      components={{ MultiValue }}
                    />
                  </div>
                </div>

                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="bill_community" className="label">
                      Communities to be Billed
                    </label>
                    <Select
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isMulti
                      options={communityList}
                      value={billCommunityList}
                      id="bill_community_id"
                      name="bill_community_id"
                      closeMenuOnSelect={false}
                      components={{ MultiValue }}
                      onChange={async (e) => {
                        setBillCommunityList(e);
                      }}
                      //  value={communitydropdown}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* multiple billees */}
            <div className="addminstraion-fields">
              <div className="addminstraion-header add-providers-top-header">
                <h2 className="adsd-heading billie-modal-title">Billees</h2>
                <img
                  className="add-section-icon"
                  src={AddSectionIcon}
                  alt=""
                  onClick={addBilleeFormHandler}
                />
              </div>
              {billees.map((billee, index) => {
                if (!billee.is_delete) {
                  return (
                    <>
                      <div
                        key={index}
                        className="addminstraion-header add-providers-inner-header"
                      >
                        <h2 className="adsd-heading inner-billie-heading">
                          {"Billee-"}
                          {billeeCount[index]}
                        </h2>
                        {/* uncomment the following to get the delete functionality */}
                        {/* <img
                          className="add-section-icon"
                          src={DeleteIcon}
                          alt=""
                          onClick={(e) => removeBilleeFormHandler(e, index)}
                        /> */}
                      </div>
                      <div className="row">
                        <div className="col-lg-6 left-col">
                          <div className="field-wrapper">
                            <label htmlFor="company" className="label">
                              Company
                            </label>
                            <input
                              id="company"
                              name="company"
                              type="text"
                              className="custom-input"
                              placeholder="Company"
                              onChange={(e) => billeeChangeHandler(e, index)}
                              value={billee ? billee.company : ""}
                              required
                            />
                            {companyError[index] && (
                              <p className="error-text">
                                {companyError[index]}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 left-col">
                          <div className="field-wrapper">
                            <label
                              htmlFor="billee_first_name"
                              className="label"
                            >
                              First Name
                            </label>
                            <input
                              id="billee_first_name"
                              name="billee_first_name"
                              type="text"
                              className="custom-input"
                              placeholder="First Name"
                              onChange={(e) => billeeChangeHandler(e, index)}
                              value={billee ? billee.billee_first_name : ""}
                              required
                            />
                            {billeeFirstNameError[index] && (
                              <p className="error-text">
                                {billeeFirstNameError[index]}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 left-col">
                          <div className="field-wrapper">
                            <label htmlFor="billee_last_name" className="label">
                              Last Name
                            </label>
                            <input
                              id="billee_last_name"
                              name="billee_last_name"
                              type="text"
                              className="custom-input"
                              placeholder="Last Name"
                              onChange={(e) => billeeChangeHandler(e, index)}
                              value={billee ? billee.billee_last_name : ""}
                              required
                            />
                            {billeeLastNameError[index] && (
                              <p className="error-text">
                                {billeeLastNameError[index]}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 left-col">
                          <div className="field-wrapper">
                            <label htmlFor="billee_email" className="label">
                              Email
                            </label>
                            <input
                              id="billee_email"
                              name="billee_email"
                              type="email"
                              className="custom-input"
                              placeholder="Email"
                              onChange={(e) => billeeChangeHandler(e, index)}
                              value={billee ? billee.billee_email : ""}
                              required
                            />
                            {billeeEmailError[index] && (
                              <p className="error-text">
                                {billeeEmailError[index]}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 left-col">
                          <div className="field-wrapper">
                            <label
                              htmlFor="billee_telephone_number"
                              className="label"
                            >
                              Telephone
                            </label>
                            {/* <input
                              type="text"
                              id="billee_telephone_number"
                              name="billee_telephone_number"
                              className="custom-input"
                              placeholder="Telephone"
                              onChange={(e) => {
                                const { name, value } = e.target;

                                let items = [...billees];
                                let item = items[index];

                                const regex = /^([\s+0-9])+$/i;
                                if (value === "") {
                                  // case to remove whole highlighted text
                                  item = { ...item, [name]: value };
                                  items[index] = item;
                                  setBillees(items);
                                  return;
                                }
                                if (regex.test(value) === false) return;

                                if (value.length === 1 && value === "+") {
                                  // case to remove + sign when last number is removed
                                  item = { ...item, [name]: "" };
                                } else if (
                                  value.length >= 1 &&
                                  value[0] !== "+"
                                ) {
                                  // case to append + sign with first number
                                  item = { ...item, [name]: "+" + value };
                                } else {
                                  // case to append other numbers
                                  item = { ...item, [name]: value };
                                }

                                items[index] = item;
                                setBillees(items);

                                // if (value === "") {
                                //   //setBilleePhoneVal(value);
                                //   return;
                                // }else{
                                //   //if (/^[+()\d]+$/.test(value)) {
                                //   //setBilleePhoneVal(value);
                                //   item = { ...item, [name]: value };
                                //   items[index] = item;

                                //   setBillees(items);
                                // }
                              }}
                              //value={billeePhoneVal}
                              value={
                                billee ? billee.billee_telephone_number : ""
                              }
                              required
                            /> */}
                            <PhoneInput
                              country={"us"}
                              value={
                                billee ? billee.billee_telephone_number : ""
                              }
                              onChange={(val) => {
                                let items = [...billees];
                                let item = items[index];
                                item = {
                                  ...item,
                                  billee_telephone_number: val,
                                };
                                items[index] = item;
                                setBillees(items);
                              }}
                              inputProps={{
                                required: true,
                              }}
                            />
                            {billeePhoneError[index] && (
                              <p className="error-text">
                                {billeePhoneError[index]}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 right-col">
                          <div className="field-wrapper">
                            <label htmlFor="country" className="label">
                              Address 01
                            </label>
                            <input
                              id="billee_address_1"
                              name="billee_address_1"
                              type="text"
                              className="custom-input"
                              // value={address2}
                              // onChange={setAddress2Handler}
                              placeholder="Address 01"
                              value={billee ? billee.billee_address_1 : ""}
                              onChange={(e) => billeeChangeHandler(e, index)}
                            />
                            {billeeAddress1Error[index] && (
                              <p className="error-text">
                                {billeeAddress1Error[index]}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 right-col">
                          <div className="field-wrapper">
                            <label htmlFor="country" className="label">
                              Address 02
                            </label>
                            <input
                              id="billee_address_2"
                              name="billee_address_2"
                              type="text"
                              className="custom-input"
                              placeholder="Address 02"
                              value={billee ? billee.billee_address_2 : ""}
                              onChange={(e) => billeeChangeHandler(e, index)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 left-col">
                          <div className="field-wrapper">
                            <label htmlFor="zip" className="label">
                              City
                            </label>
                            <input
                              type="text"
                              name="billee_city"
                              id="billee_city"
                              className="custom-input"
                              placeholder="City"
                              value={billee ? billee.billee_city : ""}
                              onChange={(e) => billeeChangeHandler(e, index)}
                              required
                            />
                            {billeeCityError[index] && (
                              <p className="error-text">
                                {billeeCityError[index]}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 right-col">
                          <div className="field-wrapper">
                            <label htmlFor="state" className="label">
                              State
                            </label>
                            <input
                              type="text"
                              id="billee_state"
                              name="billee_state"
                              className="custom-input"
                              placeholder="State"
                              value={billee ? billee.billee_state : ""}
                              onChange={(e) => billeeChangeHandler(e, index)}
                              required
                            />
                            {billeeStateError[index] && (
                              <p className="error-text">
                                {billeeStateError[index]}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 right-col">
                          <div className="field-wrapper">
                            <label htmlFor="zip" className="label">
                              Zip
                            </label>
                            <input
                              type="number"
                              name="billee_zip"
                              id="billee_zip"
                              className="custom-input"
                              placeholder="Zip"
                              value={billee ? billee.billee_zip : ""}
                              onChange={(e) => billeeChangeHandler(e, index)}
                              required
                            />
                            {billeeZipError[index] && (
                              <p className="error-text">
                                {billeeZipError[index]}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 right-col">
                          <div className="field-wrapper">
                            <label htmlFor="billee_country" className="label">
                              Country
                            </label>
                            <input
                              type="text"
                              name="billee_country"
                              id="billee_country"
                              className="custom-input"
                              placeholder="Country"
                              value={billee ? billee.billee_country : ""}
                              onChange={(e) => billeeChangeHandler(e, index)}
                              required
                            />
                            {billeeCountryError[index] && (
                              <p className="error-text">
                                {billeeCountryError[index]}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
                return <></>;
              })}
              <div ref={myRef}></div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            id="close-btn"
            className="btn btn-secondary-fill btn-primary-16"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            // type="submit"
            onClick={editProviderSubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={addBtnDisabled}
          >
            Update
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
};

export default EditProvider;
const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    borderRadius: "4px",
    fontFamily: "Open Sans",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 3;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};
