import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import CustomSelect from "../../../General/filters/CustomSelect.Component";
import { filterActions } from "../../../../store/filter";
import { removeEmptyParams } from "../../../../services/helpers/sorting.hepler";
import { corporateActions } from "../../../../store/corporate";
import { getWithExpiry } from "../../../utils/helper";
import axios from "axios";
import useQueryString from "../../../../hooks/useQueryString";
import "./FiltersModal.scss";
// imports image

import CloseModal from "../../../../assets/close-outline.svg";
import DisabledIcon from "../../../../assets/disabled-row.svg";

function FilterModal(props) {
  const {
    isShowModal,
    hideModal,
    orderBy,
    setDataFlag,
    setShowToast,
    setOtherLoader,
  } = props;

  const history = useHistory();
  const { corporateID } = useParams();
  const dispatch = useDispatch();
  const urlParams = useQueryString();
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(true);

  const searchCorporateCommunityText = useSelector(
    (state) => state.filter.searchCorporateCommunityText
  );
  const communityCorporateFilteredCountry = useSelector(
    (state) => state.corporate.communityCorporateFilteredCountry
  );
  const communityCorporateFilteredCity = useSelector(
    (state) => state.corporate.communityCorporateFilteredCity
  );
  const communityCorporateFilteredState = useSelector(
    (state) => state.corporate.communityCorporateFilteredState
  );
  const communityCorporateFilteredZip = useSelector(
    (state) => state.corporate.communityCorporateFilteredZip
  );

  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedZip, setSelectedZip] = useState([]);
  const [selectedfilter, setSelectedFilter] = useState("");

  const token = getWithExpiry("expiry_token");

  const fetchData = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_URL_PATH}/community/community_filters/${corporateID}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + token, //the token holds the user token
        },
      });
      var resData = await response.data.data;
      return resData;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };

  const returnSelectedArray = async (object) => {
    if (!object || object.length <= 0) return [];
    var _selected = [];
    object.map((dataKey) => {
      _selected.push(dataKey.value.trim());
    });
    setSelectedFilter(_selected);
    return _selected;
  };

  const handleSelectCountry = async (e) => {
    var _selected = await returnSelectedArray(e);
    //dispatch(corporateActions.setCommunityCorporateFilteredCountry(_selected));
    setSelectedCountry(_selected);
  };

  const handleSelectCity = async (e) => {
    var _selected = await returnSelectedArray(e);
    //dispatch(corporateActions.setCommunityCorporateFilteredCity(_selected));
    setSelectedCity(_selected);
  };

  const handleSelectState = async (e) => {
    var _selected = await returnSelectedArray(e);
    //dispatch(corporateActions.setCommunityCorporateFilteredState(_selected));
    setSelectedState(_selected);
  };

  const handleSelectZip = async (e) => {
    var _selected = await returnSelectedArray(e);
    //dispatch(corporateActions.setCommunityCorporateFilteredZip(_selected));
    setSelectedZip(_selected);
  };

  useEffect(() => {
    (async () => {
      var resData = await fetchData();
      await setFilterData(resData);
      setSelectedCity(communityCorporateFilteredCity);
      setSelectedState(communityCorporateFilteredState);
      setSelectedZip(communityCorporateFilteredZip);
    })();
  }, []);

  const filterSubmissionHandler = () => {
    setOtherLoader(true);
    let customQuery = `search_query=${searchCorporateCommunityText}&${orderBy}&country:eq=${selectedCountry}&city:eq=${selectedCity}&state:eq=${selectedState}&zip:eq=${selectedZip}`;

    var modifiedString = removeEmptyParams(customQuery);
    let prefix = "";
    let searchParam = "";
    if (modifiedString || urlParams.toString().includes("page")) {
      prefix = "tab_key=community&";
    }
    if (
      !modifiedString.includes("page") &&
      urlParams.toString().includes("page")
    ) {
      searchParam = "?" + prefix + modifiedString + `&page=${1}`;
    } else {
      searchParam = "?" + prefix + modifiedString;
    }
    searchParam = searchParam.replace("&&", "&");
    history.replace({
      pathname: `/edit/corporates/${corporateID}`,
      search: searchParam,
    });

    dispatch(
      corporateActions.setCommunityCorporateApiUrl(
        "?tab_key=community&" + modifiedString
      )
    );

    dispatch(corporateActions.setCommunityCorporateFilteredCity(selectedCity));
    dispatch(
      corporateActions.setCommunityCorporateFilteredState(selectedState)
    );
    dispatch(corporateActions.setCommunityCorporateFilteredZip(selectedZip));
    setShowToast(true);
    setDataFlag((prevState) => !prevState);
    hideModal();
  };

  const clearFilters = () => {
    setOtherLoader(true);
    var modifiedString = "";
    let prefix = "";
    let searchParam = "";
    if (modifiedString || urlParams.toString().includes("page")) {
      prefix = "tab_key=community&";
    }
    if (
      !modifiedString.includes("page") &&
      urlParams.toString().includes("page")
    ) {
      searchParam = "?" + prefix + modifiedString + `&page=${1}`;
    } else {
      searchParam = "?" + prefix + modifiedString;
    }
    searchParam = searchParam.replace("&&", "&");
    history.replace({
      pathname: `/edit/corporates/${corporateID}`,
      search: searchParam,
    });

    dispatch(
      corporateActions.setCommunityCorporateApiUrl(
        "?tab_key=community&" + modifiedString
      )
    );

    dispatch(corporateActions.setCommunityCorporateFilteredCity([]));
    dispatch(corporateActions.setCommunityCorporateFilteredState([]));
    dispatch(corporateActions.setCommunityCorporateFilteredZip([]));
    setShowToast(true);
    setDataFlag((prevState) => !prevState);
    hideModal();
  };

  return (
    <div className="filter-modal-wrapper">
      <Modal
        className="filter-modal"
        show={isShowModal}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
      >
        <Modal.Header className="filter-modal-header">
          <h1 className="modal-header-title">Filters</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>

        <Modal.Body className="filter-modal-body show-select">
          {filterData && filterData.filters && (
            <Fragment>
              {/* <CustomSelect
                selectedValues={communityCorporateFilteredCountry}
                labelText="Country"
                name="country"
                id="country"
                isMulti={true}
                data={filterData.filters.country}
                handleSelectChange={handleSelectCountry}
              /> */}

              <CustomSelect
                selectedValues={selectedCity}
                labelText="City"
                name="city"
                id="city"
                isMulti={true}
                data={filterData.filters.city}
                handleSelectChange={handleSelectCity}
              />

              <CustomSelect
                selectedValues={selectedState}
                labelText="State"
                name="status"
                id="status"
                isMulti={true}
                data={filterData.filters.state}
                handleSelectChange={handleSelectState}
              />

              <CustomSelect
                selectedValues={selectedZip}
                labelText="Zip"
                name="zip"
                id="zip"
                isMulti={true}
                data={filterData.filters.zip}
                handleSelectChange={handleSelectZip}
              />
            </Fragment>
          )}
        </Modal.Body>
        <Modal.Footer className="filter-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16"
            onClick={clearFilters}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary-fill btn-primary-16"
            onClick={filterSubmissionHandler}
          >
            Apply Filters
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FilterModal;
