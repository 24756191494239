import React from "react";
import { useSelector } from "react-redux";
import "./GroupInvoiceDetail.scss";

const InvoiceDetail = () =>{
const invoiceDetail = useSelector(state=>state.billing.pastInvoiceDetail)
    return (
        <>
              <div class="group_invoice_detail_model">
                  <div class="invoice_model_header">
                    <div class="billee_address_contact">
                      <div class="billee_address_fields">
                        <div class="community_name"> Jet Worldwide Enterprise Inc</div>
                        <div class="street_address"> 9 Margarita Drive</div>
                        <div class="street_address"> Stony Point, NY 10980</div>
                        <div class="phone_no"> 845-712-4820</div>
                      </div>
                    </div>
                    <div class="catchu_logo">
                      <img src="/static/media/logo.ed827b6d.svg" alt="" />
                    </div>
                  </div>
                  <div class="invoice_details">
                    <div class="invoice_number_div">
                      <span class="invoice_number_title">Invoice #:</span>
                      <span class="invoice_number">{invoiceDetail.invoice_id}</span>
                    </div>
                    <div class="invoice_date_div">
                      <span class="invoice_date_title">Invoice Date:</span>
                      <span class="invoice_date">{invoiceDetail.invoice_date}</span>
                    </div>
                    <div class="amount_due_div_top">
                      <span class="amount_due_title">Amount Due:</span>
                      <span class="amount_due">${invoiceDetail.invoice_amount}</span>
                    </div>
                  </div>
                  <div class="billing_to">
                    <div class="bill_to_div">
                      <span class="bill_to_title">Bill To:</span>
                    </div>
                    {/* <div class="bill_name_div">
                      <span class="bill_title">Jet</span>
                      <span class="bill_text"></span>
                    </div> */}
                    <div class="billing_address_div">
                      <span class="billing_address">{invoiceDetail.billee_address}</span>
                    </div>
                    {/* <div class="billing_country_div">
                      <span class="billing_address">United States</span>
                    </div> */}
                  </div>
                  <table class="invoice_date_table">
                    <thead>
                      <tr>
                        <th className="invoice_table_subheading">Due Date</th>
                        <th className="invoice_table_subheading">Terms</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="invoice_table_data">05/06/222</td>
                        <td className="invoice_table_data">Net 30</td>
                      </tr>
                    </tbody>
                  </table>
                  {/* <table class="test_detail_table">
                    <thead>
                      <tr>
                        <th className="invoice_table_subheading">Test ID</th>
                        <th className="invoice_table_subheading">Description</th>
                        <th className="invoice_table_subheading">Quantity</th>
                        <th className="invoice_table_subheading">Price</th>
                        <th className="invoice_table_subheading">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="invoice_table_data">05/06/222</td>
                        <td className="invoice_table_data">Net 30</td>
                        <td className="invoice_table_data">Net 30</td>
                        <td className="invoice_table_data">Net 30</td>
                        <td className="invoice_table_data">Net 30</td>
                      </tr>
                      <tr>
                        <td className="invoice_table_data">05/06/222</td>
                        <td className="invoice_table_data">Net 30</td>
                        <td className="invoice_table_data">Net 30</td>
                        <td className="invoice_table_data">Net 30</td>
                        <td className="invoice_table_data">Net 30</td>
                      </tr>
                    </tbody>
                  </table> */}


                  <div class="amount_detail">
                    <div class="sub_total_div">
                      <span class="sub_total_amount">Subtotal :</span>
                      <span class="subtotal">$58</span>
                    </div>
                    <div class="sales_tax_div">
                      <span class="sales_tax_titlte">Sales Tax:</span>
                      <span class="sale_tax">$0.00</span>
                    </div>
                    <div class="total_div">
                      <span class="total_title">Total:</span>
                      <span class="total">$58.00</span>
                    </div>
                    <div class="payments_div">
                      <span class="payments_title">Payments:</span>
                      <span class="payment">$0.00</span>
                    </div>
                    <div class="amount_due_div">
                      <span class="amount_due_title">Amount Due:</span>
                      <span class="amount_due">${invoiceDetail.invoice_amount}</span>
                    </div>
                  </div>
                  <div class="link_to_billing">
                    To pay online, go to https://app-sandbox.bill.com/p/jetworldwideenterprise
                  </div>
                </div>
        </>
    )
}

export default InvoiceDetail