import React from "react";

const TestPage = () => {
  return (
    <div>
      <h1>Hello World!</h1>
    </div>
  );
};

export default TestPage;
