import React from "react";

const verifyOtp = (id) => {
    const otpNumsWrapper = document.querySelector(id);
    const inputElements = otpNumsWrapper && otpNumsWrapper.querySelectorAll('input');
    for (const inputElement of inputElements) {
        inputElement.addEventListener('input', function() {
            let cursor = inputElement.selectionStart;
            let regex = /[^a-z0-9]/gi;
            let value = inputElement.value;

            if (regex.test(value)) {
                inputElement.value = value.replace(regex, '');
            }
            // inputElement.setSelectionRange(cursor, cursor);
        });
        inputElement.addEventListener('keyup', (e) => {
            let regex = /[^a-z0-9]/gi;
            let value = inputElement.value;

            if (e.keyCode === 8 || e.keyCode === 37) {
                let previous = otpNumsWrapper.querySelector('input.' + inputElement.dataset.previous);
                if (previous) {
                    previous.select();
                }
            } else if ((e.keyCode >= 48 && e.keyCode <= 57) ||  
            (e.keyCode >= 96 && e.keyCode <= 105) || 
            (e.keyCode === 39)) {
                let next = otpNumsWrapper.querySelector('input.' + inputElement.dataset.next);
                
                if (!regex.test(value)) {
                    if (next) {
                        next.select();
                    }
                }
                
            }
        });
    }
};

export default verifyOtp;