import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import "./EditCommunity.scss";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFound from "../NotFound/NotFound";

import axios from "axios";
import Loader from "../UI/loader/loader";
import { getWithExpiry } from "../utils/helper";
import EditCommunityModal from "./EditCommunityModal/EditCommunityModal";
import TableDataEdit from "../Communities/Table/TableDataEdit";

import { communityActions } from "../../store/community";
import { crumbActions } from "../../store/breadcrumb";

import { Address } from "../General/Address.Component";
import useQueryString from "../../hooks/useQueryString";
import EditIcon from "../../assets/edit.svg";
import Miuns from "../../assets/minus.svg";
import AddGroupTestModal from "../Communities/Modals/AddGroupTestModal/AddGroupTestModal";

import EnableModal from "../Corporate/Modals/EnableModal/EnableModal";
import DisableModal from "../Corporate/Modals/DisableModal/DisableModal";
import DeleteModal from "../Corporate/Modals/DeleteModal/DeleteModal";

// import { handleInputChange } from "react-select/dist/declarations/src/utils";

function EditComunities() {
  const userTableHeadings = [
    "First Name",
    "Last Name",
    "Email",
    "Telephone",
    "Role",
    "Actions",
  ];
  const iphoneTableHeadings = ["iPhone Number", "Description", "Actions"];

  const billeeTableHeadings = [
    "Company",
    "First Name",
    "Last Name",
    "Email",
    "Telephone",
    "Actions",
  ];

  const patientTableHeadings = [
    "First Name",
    "Last Name",
    "Date of Birth",
    "Email",
    "Telephone",
    "Status",
    "Actions",
  ];
  const providerTableHeadings = [
    "First Name",
    "Last Name",
    "Email",
    "Telephone",
    "Actions",
  ];

  const groupTestHistoryTableHeadings = [
    "Name",
    "Created Date",
    "Expiry Date",
    "No. of Tests",
    "Test Location",
    "Status",
    "Actions",
  ];

  const devicesTableHeadings = [
    "Name",
    "Price",
    "Description",
    "Billee",
    "Equipment Id",
  ];

  const params = useParams();
  const { communityID: communityId } = params;

  const dispatch = useDispatch();
  const urlParams = useQueryString();
  const [communityName, setCommunityName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [otherLoader, setOtherLoader] = useState(false);
  const [dataFlag, setDataFlag] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [pageExist, setPageExist] = useState(true);
  const [billeesList, setBilleesList] = useState([]);

  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);
  const [selectedTest, setSelectedTest] = useState(null);

  const communityInfo = useSelector((state) => state.community.communityInfo);
  const [enabledId, setEnableId] = useState(0);
  const [disableId, setDisableId] = useState(0);
  const [groupTestDeleteID, setGroupTestDeleteID] = useState(null);

  const usersList = useSelector((state) => state.community.usersList);
  const iphonesList = useSelector((state) => state.community.iphonesList);
  const billeeList = useSelector((state) => state.community.billeeList);
  const patientList = useSelector((state) => state.community.patientList);
  const [isBillAble, setIsBillAble] = useState(false)
  const [reportDelay, setReportDelay] = useState(false);

  const groupTestHistoryList = useSelector(
    (state) => state.community.groupTestHistory
  );

  const { devicesTotalPages,
    devicesTotalRecord,
    devicesList, devicesApiURL
  } = useSelector(
    (state) => state.community
  );


  const patientTotalRecords = useSelector(
    (state) => state.community.patientTotalRecords
  );
  const userTotalRecords = useSelector(
    (state) => state.community.userTotalRecords
  );
  const iPhoneTotalRecords = useSelector(
    (state) => state.community.iPhoneTotalRecords
  );
  const billeeTotalRecords = useSelector(
    (state) => state.community.billeeTotalRecords
  );

  const patientCommunityCurrentPage = useSelector(
    (state) => state.community.patientCommunityCurrentPage
  );
  const userCommunityCurrentPage = useSelector(
    (state) => state.community.userCommunityCurrentPage
  );
  const billeeCommunityCurrentPage = useSelector(
    (state) => state.community.billeeCommunityCurrentPage
  );
  const iphoneCommunityCurrentPage = useSelector(
    (state) => state.community.iphoneCommunityCurrentPage
  );

  const historyCommunityCurrentPage = useSelector(
    (state) => state.community.historyCommunityCurrentPage
  );

  const groupTestHistoryTotalRecords = useSelector(
    (state) => state.community.groupTestHistoryTotalRecords
  );

  const { isAddSuccess } = useSelector(state => state.community)

  const [editCommunityModalShow, setEditCommunityModalShow] = useState(false);
  const showEditModalHandler = () => setEditCommunityModalShow(true);
  const hideEditModalHandler = () => setEditCommunityModalShow(false);

  //Newly Added Code & States
  const [addGroupTestModalShow, setAddGroupTestModalShow] = useState(false);
  const hideGroupTestModalHandler = () => setAddGroupTestModalShow(false);

  const [activeTests, setActiveTests] = useState([]);
  const [activeTestsHistory, setActiveTestsHistory] = useState([]);
  const [groupIsEditMode, setGroupIsEditMode] = useState(false);

  const showGroupTestModalHandler = async () => {
    //create group test
    if (communityInfo.is_enabled) {
      await setSelectedTest(null);
      await setGroupIsEditMode(false);
      await setAddGroupTestModalShow(true);
    } else {
      notifyError("Cannot Create Group Test for Disabled Community");
    }
  };

  const options = [];
  let enable = { value: "enable", label: "Enable" };
  let disable = { value: "disable", label: "Disable" };
  communityInfo.is_enabled ? options.push(disable) : options.push(enable);

  const handleEditClick = async (data) => {
    // edit group test
    setSelectedTest(data);
    setGroupIsEditMode(true);
    setAddGroupTestModalShow(true);
  };

  const handleDeleteClick = async (data) => {
    setGroupTestDeleteID(data?.group_id);
    showGroupTestDeleteModal();

  };

  //====================================================
  const [disableModalOpen, setDisableModalOpen] = useState(false);
  const [enableModalOpen, setEnableModalOpen] = useState(false);

  const [groupTestDeleteModalOpen, setGroupTestDeleteModalOpen] =
    useState(false);
  const showGroupTestDeleteModal = () => setGroupTestDeleteModalOpen(true);
  const hideGroupTestDeleteModal = () => setGroupTestDeleteModalOpen(false);

  const showEnableModal = () => {
    setEnableId(communityId);
    setEnableModalOpen(true);
    //dispatch(providerActions.setEditProviderInfo(record));
  };

  const hideEnableModal = () => setEnableModalOpen(false);

  const showDisableModal = () => {
    setEnableId(communityId);
    setDisableModalOpen(true);
    // dispatch(providerActions.setEditProviderInfo(record));
  };

  const handleEnableDisableModal = (event) => {
    const { value } = event.target;
    if (value === "enable") {
      setEnableId(communityId);
      showEnableModal(true);
    }

    if (value === "disable") {
      setDisableId(communityId);
      showDisableModal(true);
    }

    setSelectedVal("Community Action");
    event.target.value = "Community Action";
  };

  const hideDisableModal = () => setDisableModalOpen(false);

  //====================================================

  const token = "Bearer " + getWithExpiry("expiry_token");

  const [selectedVal, setSelectedVal] = useState("Community Action");
  const history = useHistory();
  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    if (params.corporateID) {
      axios({
        url: `${process.env.REACT_APP_URL_PATH}/corporate/get_corporate_name/${params.corporateID}/`,
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((response) => {
          const resData = response.data.data;
          dispatch(
            crumbActions.setCorporateName({
              [params.corporateID]: resData.corporate_name,
            })
          );
        })
        .catch((error) => {
        });
    }
  }, []);

  // New Api

  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/community/equipment_list/${communityId}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        dispatch(communityActions.setAddEquipmentSucess(false))
        dispatch(
          communityActions.setDeviceData(
            response.data.data,
          )
        );
      })
      .catch((error) => {
      });

  }, [isAddSuccess]);

  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/user/get_community_billee_list/${communityId}/`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        setBilleesList(response?.data?.data)
      })
      .catch((error) => {
      });
  }, []);

  //enabling the report delay
  const toggleReportDelay = async (event) => {
    try {
      const flag = event.target.checked;
      const response = await axios.post(`${process.env.REACT_APP_URL_PATH}/community/send_report_after_three_days/`, {
        community_id: communityId,
        send_report_after_three_days: flag
      }, {
        headers: {
          Authorization: `${token}`,
        }
      });
      notifySuccess(response.data.message);
      setShowToast(true);
      setReportDelay(flag);
    } catch (error) {
      notifyError(error.response.data.message);
    };
  };
 
  // enable billing

  // Allow Billing

  const allowBillingChange = (e) => {
    let checked = e.target.checked;
    if (checked) {
      enableBilling();
    } else {
      disableBilling();
    }
  
  }

  const enableBilling = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/community/enable_billable_community/${communityId}`,
      data: communityId,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
        // setTimeout(() => {
        //   //window.location.reload();
        // }, 3000);
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };

  // enable billing

  const disableBilling = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/community/disable_billable_community/${communityId}`,
      data: communityId,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
        // setTimeout(() => {
        //   //window.location.reload();
        // }, 3000);
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };

  const patientCommunityApiUrl = useSelector(
    (state) => state.community.patientCommunityApiUrl
  );
  const userCommunityApiUrl = useSelector(
    (state) => state.community.userCommunityApiUrl
  );
  const iphoneCommunityApiUrl = useSelector(
    (state) => state.community.iphoneCommunityApiUrl
  );

  const billeeCommunityApiUrl = useSelector(
    (state) => state.community.billeeCommunityApiUrl
  );
  const testCommunityApiUrl = useSelector(
    (state) => state.community.testCommunityApiUrl
  );

  const providerList = useSelector((state) => state.community.providerList);
  const providerTotalRecords = useSelector(
    (state) => state.community.providerTotalRecords
  );
  const providerCommunityCurrentPage = useSelector(
    (state) => state.community.providerCommunityCurrentPage
  );
  const providerCommunityApiUrl = useSelector(
    (state) => state.community.providerCommunityApiUrl
  );

  useEffect(() => {
    if (!showToast) {
      setLoading(true);
    }
    let urlKeys = [...urlParams.keys()];
    let urlValues = [...urlParams.values()];

    let keyCheck = checkPermission();
    let Url = "";
    let query = "";
    let pageNumber = 1;
    if (urlValues.includes("patient")) {
      if (!patientCommunityApiUrl && urlKeys.length > 0) {
        query = "?";
        if (keyCheck === true) {
          setKey1("patient");
        } else {
          setKey2("patient");
        }
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = patientCommunityApiUrl ? patientCommunityApiUrl + "&" : "?";

      }
      pageNumber = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? urlParams.get("page")
          : patientCommunityCurrentPage
        : patientCommunityCurrentPage;

      query = query.replace("&&", "&");
      Url = `${process.env.REACT_APP_URL_PATH}/community/view_community/${communityId}${query}patient_page=${pageNumber}&user_page=${userCommunityCurrentPage}&iphone_page=${iphoneCommunityCurrentPage}&billee_page=${billeeCommunityCurrentPage}&provider_page=${providerCommunityCurrentPage}`;
    } else if (urlValues.includes("user")) {
      if (!userCommunityApiUrl && urlKeys.length > 0) {
        query = "?";
        if (keyCheck === true) {
          setKey1("user");
        } else {
          setKey2("user");
        }
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = userCommunityApiUrl ? userCommunityApiUrl + "&" : "?";
      }

      pageNumber = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? urlParams.get("page")
          : userCommunityCurrentPage
        : userCommunityCurrentPage;

      query = query.replace("&&", "&");
      Url = `${process.env.REACT_APP_URL_PATH}/community/view_community/${communityId}${query}patient_page=${patientCommunityCurrentPage}&user_page=${pageNumber}&iphone_page=${iphoneCommunityCurrentPage}&billee_page=${billeeCommunityCurrentPage}&provider_page=${providerCommunityCurrentPage}`;
    }
    else if (urlValues.includes("iphone")) {
      if (!iphoneCommunityApiUrl && urlKeys.length > 0) {
        query = "?";
        if (keyCheck === true) {
          setKey1("iphone");
        } else {
          setKey2("iphone");
        }
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = iphoneCommunityApiUrl ? iphoneCommunityApiUrl + "&" : "?";
      }

      pageNumber = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? urlParams.get("page")
          : iphoneCommunityCurrentPage
        : iphoneCommunityCurrentPage;

      query = query.replace("&&", "&");
      Url = `${process.env.REACT_APP_URL_PATH}/community/view_community/${communityId}${query}patient_page=${patientCommunityCurrentPage}&user_page=${userCommunityCurrentPage}&iphone_page=${pageNumber}&billee_page=${billeeCommunityCurrentPage}&provider_page=${providerCommunityCurrentPage}`;
    }
    else if (urlValues.includes("devices")) {

      if (!devicesApiURL && urlKeys.length > 0) {
        query = "?";
        if (keyCheck === true) {
          setKey1("devices");
        } else {
          setKey2("devices");
        }
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = devicesApiURL ? devicesApiURL + "&" : "?";
      }

      pageNumber = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? urlParams.get("page")
          : devicesTotalPages
        : devicesTotalPages;

      query = query.replace("&&", "&");

      Url = `${process.env.REACT_APP_URL_PATH}/community/view_community/${communityId}${query}patient_page=${patientCommunityCurrentPage}&user_page=${userCommunityCurrentPage}&iphone_page=${pageNumber}&billee_page=${billeeCommunityCurrentPage}&provider_page=${providerCommunityCurrentPage}`;
    }
   
    else if (urlValues.includes("billee")) {
      if (!billeeCommunityApiUrl && urlKeys.length > 0) {
        query = "?";
        if (keyCheck === true) {
          setKey1("billee");
        } else {
          setKey2("billee");
        }
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = billeeCommunityApiUrl ? billeeCommunityApiUrl + "&" : "?";
      }

      pageNumber = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? urlParams.get("page")
          : billeeCommunityCurrentPage
        : billeeCommunityCurrentPage;

      query = query.replace("&&", "&");
      Url = `${process.env.REACT_APP_URL_PATH}/community/view_community/${communityId}${query}patient_page=${patientCommunityCurrentPage}&user_page=${userCommunityCurrentPage}&iphone_page=${iphoneCommunityCurrentPage}&billee_page=${pageNumber}&provider_page=${providerCommunityCurrentPage}`;
    } else if (urlValues.includes("provider")) {
      if (!providerCommunityApiUrl && urlKeys.length > 0) {
        query = "?";
        if (keyCheck === true) {
          setKey1("provider");
        } else {
          setKey2("provider");
        }
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = providerCommunityApiUrl ? providerCommunityApiUrl + "&" : "?";
      }

      pageNumber = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? urlParams.get("page")
          : providerCommunityCurrentPage
        : providerCommunityCurrentPage;

      query = query.replace("&&", "&");
      Url = `${process.env.REACT_APP_URL_PATH}/community/view_community/${communityId}${query}patient_page=${patientCommunityCurrentPage}&user_page=${userCommunityCurrentPage}&iphone_page=${iphoneCommunityCurrentPage}&billee_page=${billeeCommunityCurrentPage}&provider_page=${pageNumber}`;
    } else {
      Url = `${process.env.REACT_APP_URL_PATH}/community/view_community/${communityId}?patient_page=${patientCommunityCurrentPage}&user_page=${userCommunityCurrentPage}&iphone_page=${iphoneCommunityCurrentPage}&billee_page=${billeeCommunityCurrentPage}&provider_page=${providerCommunityCurrentPage}`;
    }

    axios({
      url: Url,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        setIsBillAble(response.data.data.community.is_billable)
        const resData = response.data.data;
        dispatch(
          crumbActions.setCommunityName({
            [resData.community.id]: resData.community.community_name,
          })
        );
        dispatch(
          communityActions.addCommunityUserRoles(
            response.data.data.add_user_roles
          )
        );


        dispatch(communityActions.setCommunities(response.data.message));
        dispatch(communityActions.setUsersList(resData.user_list));
        dispatch(communityActions.setIphonesList(resData.iphone_list));
        dispatch(communityActions.setCommunityInfo(resData.community));

        dispatch(communityActions.setBilleeList(resData.billee_list));
        dispatch(communityActions.setPatientList(resData.patient_list));

        dispatch(communityActions.setProviderList(resData.provider_list));
        dispatch(communityActions.setProviderPage(resData.provider_pages));
        dispatch(
          communityActions.setProviderTotalRecords(
            response.data.data.provider_total_records
          )
        );

        dispatch(communityActions.setIphonesPage(resData.iphone_pages));
        dispatch(communityActions.setUsersPage(response.data.data.user_pages));
        dispatch(
          communityActions.setBilleePage(response.data.data.billee_pages)
        );
        dispatch(
          communityActions.setPatientPage(response.data.data.patient_pages)
        );
        dispatch(
          communityActions.setPatientTotalRecords(
            response.data.data.patient_total_records
          )
        );
        dispatch(
          communityActions.setUserTotalRecords(
            response.data.data.user_total_records
          )
        );
        dispatch(
          communityActions.setiPhoneTotalRecords(
            response.data.data.iphone_total_records
          )
        );
        dispatch(
          communityActions.setBilleeTotalRecords(
            response.data.data.billee_total_records
          )
        );

        dispatch(
          communityActions.setCommunityAdmins(
            response.data.data.community_admins
          )
        );

        if (patientCommunityCurrentPage > resData.patient_pages) {
          dispatch(
            communityActions.setPatientCommunityCurrentPage(
              resData.patient_pages
            )
          );
        }
        if (userCommunityCurrentPage > resData.user_pages) {
          dispatch(
            communityActions.setUserCommunityCurrentPage(resData.user_pages)
          );
        }
        if (billeeCommunityCurrentPage > resData.billee_pages) {
          dispatch(
            communityActions.setBilleeCommunityCurrentPage(resData.billee_pages)
          );
        }
        if (iphoneCommunityCurrentPage > resData.iphone_pages) {
          dispatch(
            communityActions.setIphoneCommunityCurrentPage(resData.iphone_pages)
          );
        }
        if (providerCommunityCurrentPage > resData.provider_pages) {
          dispatch(
            communityActions.setProviderCommunityCurrentPage(
              resData.provider_pages
            )
          );
        }

        setCommunityName(resData.community.community_name);
        setReportDelay(resData.community.send_report_after_three_days);
        //setPageExist(true);
        setLoading(false);
        setOtherLoader(false);
      })
      .catch((error) => {
        if (
          error.response.data.message === "Please select valid Community" ||
          error.response.data.message === "404 Not Found!"
        ) {
          setPageExist(false);
        }
        setLoading(false);
        setOtherLoader(false);
      });
  }, [dataFlag]);


  useEffect(() => {
    if (!showToast) {
      setLoading(true);
    }

    axios({
      url: `${process.env.REACT_APP_URL_PATH}/community/active_group_test_list/${communityId}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        const resData = response.data.data;
        setActiveTests(resData.active_group_tests);

        dispatch(
          communityActions.addCummunityActiveGroupTests(
            response.data.data.active_group_tests
          )
        );

        setLoading(false);
      })
      .catch((error) => {
        if (error.response.data.message === "Please select valid Community") {
          setPageExist(false);
        }
        setLoading(false);
      });
  }, [dataFlag]);


  useEffect(() => {
    if (!showToast) {
      setLoading(true);
    }

    let urlKeys = [...urlParams.keys()];
    let urlValues = [...urlParams.values()];

    let keyCheck = checkPermission();
    let Url = "";
    let query = "";
    let pageNumber = 1;
    if (urlValues.includes("group_test")) {
      if (!testCommunityApiUrl && urlKeys.length > 0) {
        query = "?";
        if (keyCheck === true) {
          setKey1("history");
        } else {
          setKey2("history");
        }
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = testCommunityApiUrl ? testCommunityApiUrl + "&" : "?";
      }

      pageNumber = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? urlParams.get("page")
          : historyCommunityCurrentPage
        : historyCommunityCurrentPage;

      Url = `${process.env.REACT_APP_URL_PATH}/community/group_test_history/${communityId}${query}page=${pageNumber}`;
    } else {
      Url = `${process.env.REACT_APP_URL_PATH}/community/group_test_history/${communityId}?page=${historyCommunityCurrentPage}`;
    }

    axios({
      url: Url,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        const resData = response.data.data;
        setActiveTestsHistory(resData.group_test_history);

        dispatch(
          communityActions.addCummunityGroupTestHistory(
            resData.group_test_history
          )
        );

        dispatch(
          communityActions.addCummunityGroupTestHistoryPage(
            resData.group_test_pages
          )
        );

        dispatch(
          communityActions.addCummunityGroupTestHistoryTotalRecords(
            resData.total_records
          )
        );

        setLoading(false);
      })
      .catch((error) => {
        if (error.response.data.message === "Please select valid Community") {
          setPageExist(false);
        }
        setLoading(false);
      });
  }, [dataFlag]);

  // END Code Created for to Get Active Group Tests

  const disableCommunity = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/community/disable_community/${communityId}`,
      data: communityId,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
        // setTimeout(() => {
        //   //window.location.reload();
        // }, 3000);
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };
  const enableCommunity = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/community/enable_community/${communityId}`,
      data: communityId,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };

  const [key1, setKey1] = useState("patient");
  const [key2, setKey2] = useState("patient");

  const selectCommunityAction = (e) => {
    if (e.target.value === "disable") {
      disableCommunity();
    } else if (e.target.value === "enable") {
      enableCommunity();
    } else if (e.target.value === "edit") {
      showEditModalHandler();
      showEditModalHandler();
    }
    setSelectedVal("Community Action");
    e.target.value = "Community Action";
  };

  const checkPermission = () => {
    if (
      !isCatchUAdmin &&
      (userRole.includes("Super Admin") ||
        userRole.includes("Corporate HQ Administrator") ||
        userRole.includes("Community Admin") ||
        userRole.includes("Individual Practice Admin"))
    ) {
      return true;
    } else if (
      isCatchUAdmin &&
      (userRole.includes("Super Admin") ||
        userRole.includes("Corporate HQ Administrator") ||
        userRole.includes("Community Admin") ||
        userRole.includes("Individual Practice Admin") ||
        permissions.includes("Users"))
    ) {
      return true;
    } else {
      return false;
    }
  };

  if (!loading && !pageExist) {
    return <NotFound />;
  }
  return (
    <Fragment>
      {groupTestDeleteModalOpen && (
        <DisableModal
          type={"history"}
          isShowModal={showGroupTestDeleteModal}
          hideModal={hideGroupTestDeleteModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          disabledId={groupTestDeleteID}
        />
        // <DeleteModal
        //   //data={data}
        //   type={"Group Test"}
        //   isShowModal={showGroupTestDeleteModal}
        //   hideModal={hideGroupTestDeleteModal}
        //   setDataFlag={setDataFlag}
        //   setShowToast={setShowToast}
        //   deletedId={groupTestDeleteID}
        // />
      )}

      {enableModalOpen && (
        <EnableModal
          type={"Community"}
          isShowModal={enableModalOpen}
          hideModal={hideEnableModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          enabledId={enabledId}
        />
      )}

      {disableModalOpen && (
        <DisableModal
          type={"Community"}
          isShowModal={disableModalOpen}
          hideModal={hideDisableModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          disabledId={disableId}
        />
      )}

      {editCommunityModalShow && (
        <EditCommunityModal
          isShowModal={showEditModalHandler}
          hideModal={hideEditModalHandler}
          type="Community"
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}

      {addGroupTestModalShow && (
        <AddGroupTestModal
          isShowModal={showGroupTestModalHandler}
          hideModal={hideGroupTestModalHandler}
          type="Community"
          communityInfo={communityInfo}
          selectedTest={selectedTest}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          isEdit={groupIsEditMode}
          communityId={communityId}
        />
      )}

      {loading ? (
        <Loader />
      ) : (
        <div className="edit-corp-wrapper">
          <div className="edit-communities-div">
            <div className="wrapper-top-content">
              <Address
                address={communityInfo.address}
                title={communityInfo.community_name}
              />

              {/* <div className="ecd-left">
                    <h1 className="ecd-heading">{communityInfo.community_name}</h1>
                    <span className="ecd-des-image">
                      <img src={Location} alt="" />
                      <p className="ecd-des">
                            {
                             `${communityInfo.address.address_1}, 
                              ${communityInfo.address.address_2}, 
                              ${communityInfo.address.city},
                              ${communityInfo.address.state}, 
                              ${communityInfo.address.zip}, 
                              ${communityInfo.address.country}`
                            }
                      </p>
                    </span>
                  </div> */}

              <div className="ecd-right">
                {/* <div className="select-edit-corp"> */}
                {/* <select className="custom-select">
                    <option selected disabled>
                      Community Action
                    </option>
                    <option>Disable</option>
                    <option>Merge</option>
                  </select> */}
                {/* <select
                    className="custom-select-modal"
                    //onClick={selectCommunityAction}
                    onClick={handleEnableDisableModal}
                  >
                    <option value="Community Action">Community Action</option>
                    <option
                      value={communityInfo.is_enabled ? "disable" : "enable"}
                    >
                      {communityInfo.is_enabled ? "Disable" : "Enable"}
                    </option>
                  </select>
                </div> */}
                <ul className="cdh-right edit-communities-actions-top">
                  {!isCatchUAdmin &&
                  (userRole.includes("Super Admin") ||
                    userRole.includes("Community Admin") ||
                    userRole.includes("Individual Practice Admin") ||
                    userRole.includes("Corporate HQ Administrator")) ? (
                    <li className="cdh-inner-single">
                      <button
                        className="btn btn-primary-outline"
                        onClick={showEditModalHandler}
                      >
                        Edit
                      </button>
                    </li>
                  ) : (
                    <>
                      {isCatchUAdmin &&
                        (userRole.includes("Super Admin") ||
                          userRole.includes("Community Admin") ||
                          userRole.includes("Individual Practice Admin") ||
                          userRole.includes("Corporate HQ Administrator") ||
                          permissions.includes("Community")) && (<>
                            <li className="cdh-inner-single">
                              <div className="form-check form-switch d-flex align-items-center">
                                <input className="form-check-input" onChange={toggleReportDelay} type="checkbox" style={{ marginRight: '.2rem', cursor: 'pointer' }} role="switch" id="reportDelayToggle" checked={reportDelay}/>
                                <label className="form-check-label" for="reportDelayToggle">Report Delay</label>
                              </div>
                            </li>
                            <li className="cdh-inner-single">
                              <div className="form-check form-switch d-flex align-items-center">
                                <input className="form-check-input" onChange={allowBillingChange} type="checkbox" style={{ marginRight: '.2rem', cursor: 'pointer' }} role="switch" id="flexSwitchCheckChecked" checked={isBillAble}/>
                                <label className="form-check-label" for="flexSwitchCheckChecked">Billing</label>
                              </div>
                            </li>
                            <li className="cdh-inner-single">
                              <button
                                className="btn btn-primary-outline"
                                onClick={showEditModalHandler}
                              >
                                Edit
                              </button>
                            </li>
                          </>
                        )}
                    </>
                  )}

                  <li className="cdh-inner-single">
                    <button
                      className="btn btn-primary-fill btn-primary-16"
                      onClick={showGroupTestModalHandler}
                    >
                      Create Group Test
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="second-cdh-content ">
              <div className="cdh-first-top-row">
                <div className="cdh-left">
                  <h1 className="cdh-heading heading-tag">
                    Active Group Tests
                  </h1>
                  <span className="tag zip-tag">Pending</span>
                </div>
              </div>

              {activeTests &&
                activeTests.map((test, index) => {
                  return (
                    <div
                      key={`GT${index}`}
                      className="communitie-edit-view second-row-edit-communitie"
                    >
                      <ul className="second-inner-row-content">
                        <li className="inner-s-single">
                          <h3 className="iss-heading">Group ID</h3>
                          <span className="iss-des">{test.batch_test_id}</span>
                        </li>
                        <li className="inner-s-single">
                          <h3 className="iss-heading">Group Name</h3>
                          <span className="iss-des">{test.batch_name}</span>
                        </li>
                        <li className="inner-s-single">
                          <h3 className="iss-heading">Provider</h3>
                          <span className="iss-des">
                            {test.provider.provider_name}
                          </span>
                        </li>
                        <li className="inner-s-single">
                          <h3 className="iss-heading">Created Date</h3>
                          <span className="iss-des iss-not-defined">
                            {test.created_at}
                          </span>
                        </li>
                        <li className="inner-s-single">
                          <h3 className="iss-heading">No. of Tests</h3>
                          <span className="iss-des iss-not-defined">
                            {test.no_of_tests === 0
                              ? // <img src="https://www.infinitysymbol.net/infinity.png" />
                                "∞"
                              : test.total_no_of_tests}
                          </span>
                        </li>
                        <li className="inner-s-single">
                          <h3 className="iss-heading ">Expiry Date</h3>
                          <span className="iss-des expiry-date">
                            {test.expiration_date_am_pm}
                          </span>
                        </li>
                        <li className="inner-s-single">
                          <h3 className="iss-heading">Test Location</h3>
                          <span
                            className="iss-des"
                            title={test.batch_test_location}
                          >
                            {test.batch_test_location}
                          </span>
                        </li>
                        {/* <li className="inner-s-single">
                                        <span className="td-action">
                                            <img src={EditIcon} alt="" onClick={()=>{alert("Edit Test")}}/>
                                        </span>
                                        <span className="td-action">
                                            <img src={Miuns} alt="" onClick={()=>{alert("Delete Test")}} />
                                        </span>
                                      </li> */}
                      </ul>
                      <ul className="cdh-right">
                        <li className="cdh-inner-single">
                          <span title={"Edit"}>
                            <img
                              src={EditIcon}
                              alt=""
                              onClick={() => {
                                handleEditClick(test);
                              }}
                            />
                          </span>
                        </li>

                        <li className="cdh-inner-single">
                          <span title={"Terminate"}>
                            <img
                              src={Miuns}
                              alt=""
                              onClick={() => {
                                handleDeleteClick(test);
                              }}
                            />
                          </span>
                        </li>
                      </ul>
                    </div>
                  );
                })}
            </div>
          </div>

          {checkPermission() === true ? (
            <Tabs
              id="controlled-tab-example"
              activeKey={key1}
              onSelect={(k) => setKey1(k)}
              className="mb-3"
            >
              <Tab eventKey="patient" title="Patients">
                <TableDataEdit
                  tableHeadings={patientTableHeadings}
                  data={patientList}
                  totalRecords={patientTotalRecords}
                  type="Community"
                  dataType="patient"
                  heading={"Patients"}
                  communityId={communityId}
                  communityName={communityName}
                  setDataFlag={setDataFlag}
                  setShowToast={setShowToast}
                  otherLoader={otherLoader}
                  setOtherLoader={setOtherLoader}
                  TabKey={key1}
                />
              </Tab>

              <Tab eventKey="user" title="Users">
                <TableDataEdit
                  tableHeadings={userTableHeadings}
                  data={usersList}
                  totalRecords={userTotalRecords}
                  type="Community"
                  dataType="user"
                  heading={"Users"}
                  communityId={communityId}
                  setDataFlag={setDataFlag}
                  setShowToast={setShowToast}
                  otherLoader={otherLoader}
                  setOtherLoader={setOtherLoader}
                  TabKey={key1}
                />
              </Tab>

              <Tab eventKey="iphone" title="iPhones List">
                <TableDataEdit
                  tableHeadings={iphoneTableHeadings}
                  data={iphonesList}
                  totalRecords={iPhoneTotalRecords}
                  type="Community"
                  dataType="iphone"
                  heading={"iPhones List"}
                  communityId={communityId}
                  setDataFlag={setDataFlag}
                  setShowToast={setShowToast}
                  otherLoader={otherLoader}
                  setOtherLoader={setOtherLoader}
                  TabKey={key1}
                />
              </Tab>
              <Tab eventKey="billee" title="Billee">
                <TableDataEdit
                  tableHeadings={billeeTableHeadings}
                  data={billeeList}
                  totalRecords={billeeTotalRecords}
                  type="Community"
                  dataType="billee"
                  heading={"Billee"}
                  communityId={communityId}
                  setDataFlag={setDataFlag}
                  setShowToast={setShowToast}
                  otherLoader={otherLoader}
                  setOtherLoader={setOtherLoader}
                  TabKey={key1}
                />
              </Tab>
              <Tab eventKey="provider" title="Providers">
                <TableDataEdit
                  tableHeadings={providerTableHeadings}
                  data={providerList}
                  totalRecords={providerTotalRecords}
                  type="Community"
                  dataType="provider"
                  heading={"Providers"}
                  communityId={communityId}
                  setDataFlag={setDataFlag}
                  setShowToast={setShowToast}
                  otherLoader={otherLoader}
                  setOtherLoader={setOtherLoader}
                  TabKey={key1}
                />
              </Tab>
              <Tab eventKey="history" title="Group Tests History">
                <TableDataEdit
                  tableHeadings={groupTestHistoryTableHeadings}
                  data={groupTestHistoryList}
                  totalRecords={groupTestHistoryTotalRecords}
                  type="Community"
                  dataType="history"
                  heading={"History"}
                  communityId={communityId}
                  setDataFlag={setDataFlag}
                  setShowToast={setShowToast}
                  otherLoader={otherLoader}
                  setOtherLoader={setOtherLoader}
                  TabKey={key1}
                />
              </Tab>
              <Tab eventKey="devices" title="Equipments">
                <TableDataEdit
                  tableHeadings={devicesTableHeadings}
                  data={devicesList}
                  totalRecords={devicesTotalRecord}
                  type="Community"
                  dataType="devices"
                  heading={"Equipments"}
                  billeesList={billeesList}
                  communityId={communityId}
                  setDataFlag={setDataFlag}
                  setShowToast={setShowToast}
                  otherLoader={otherLoader}
                  setOtherLoader={setOtherLoader}
                  TabKey={key2}
                />
              </Tab>
            </Tabs>
          ) : (
            <Tabs
              id="controlled-tab-example"
              activeKey={key2}
              onSelect={(k) => setKey2(k)}
              className="mb-3"
            >
              <Tab eventKey="patient" title="Patients">
                <TableDataEdit
                  tableHeadings={patientTableHeadings}
                  data={patientList}
                  totalRecords={patientTotalRecords}
                  type="Community"
                  dataType="patient"
                  heading={"Patients"}
                  communityId={communityId}
                  communityName={communityName}
                  setDataFlag={setDataFlag}
                  setShowToast={setShowToast}
                  otherLoader={otherLoader}
                  setOtherLoader={setOtherLoader}
                  TabKey={key1}
                />
              </Tab>

              {/* <Tab eventKey="user" title="Users">
                <TableDataEdit
                  tableHeadings={userTableHeadings}
                  data={usersList}
                  totalRecords={userTotalRecords}
                  type="Community"
                  dataType="user"
                  heading={"Users"}
                  communityId={communityId}
                  setDataFlag={setDataFlag}
                  setShowToast={setShowToast}
                  otherLoader={otherLoader}
                  setOtherLoader={setOtherLoader}
                  TabKey={key2}
                />
              </Tab> */}

              <Tab eventKey="iphone" title="iPhones List">
                <TableDataEdit
                  tableHeadings={iphoneTableHeadings}
                  data={iphonesList}
                  totalRecords={iPhoneTotalRecords}
                  type="Community"
                  dataType="iphone"
                  heading={"iPhones List"}
                  communityId={communityId}
                  setDataFlag={setDataFlag}
                  setShowToast={setShowToast}
                  otherLoader={otherLoader}
                  setOtherLoader={setOtherLoader}
                  TabKey={key2}
                />
              </Tab>
              <Tab eventKey="billee" title="Billee">
                <TableDataEdit
                  tableHeadings={billeeTableHeadings}
                  data={billeeList}
                  totalRecords={billeeTotalRecords}
                  type="Community"
                  dataType="billee"
                  heading={"Billee"}
                  communityId={communityId}
                  setDataFlag={setDataFlag}
                  setShowToast={setShowToast}
                  otherLoader={otherLoader}
                  setOtherLoader={setOtherLoader}
                  TabKey={key2}
                />
              </Tab>
              <Tab eventKey="provider" title="Providers">
                <TableDataEdit
                  tableHeadings={providerTableHeadings}
                  data={providerList}
                  totalRecords={providerTotalRecords}
                  type="Community"
                  dataType="provider"
                  heading={"Providers"}
                  communityId={communityId}
                  setDataFlag={setDataFlag}
                  setShowToast={setShowToast}
                  otherLoader={otherLoader}
                  setOtherLoader={setOtherLoader}
                  TabKey={key1}
                />
              </Tab>
              <Tab eventKey="history" title="Group Tests History">
                <TableDataEdit
                  tableHeadings={groupTestHistoryTableHeadings}
                  data={groupTestHistoryList}
                  totalRecords={groupTestHistoryTotalRecords}
                  type="Community"
                  dataType="history"
                  heading={"History"}
                  communityId={communityId}
                  setDataFlag={setDataFlag}
                  setShowToast={setShowToast}
                  otherLoader={otherLoader}
                  setOtherLoader={setOtherLoader}
                  TabKey={key2}
                />
              </Tab>

            </Tabs>
          )}
        </div>
      )}
    </Fragment>
  );
}

export default EditComunities;
