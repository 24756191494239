import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./EditProfile.scss";

import ProfileImage from "../../assets/profile-image.jpg";
import editProfileIcon from "../../assets/edit-profile.svg";
import Avatar_Image from "../../assets/avatar-person.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { sidebarActions } from "../../store/sidebar";
import { userActions } from "../../store/user";
import Input from "react-phone-number-input/input";
import Loader from "../UI/loader/loader";
import Loader2 from "../UI/loader2/loader2";
import { getWithExpiry } from "../utils/helper";
import { stripSrciptRegex } from "../../constants";

function EditProfile() {
  const dispatch = useDispatch();
  const token = "Bearer " + getWithExpiry("expiry_token");
  const userInfo = useSelector((state) => state.user.userInfo);

  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [dataFlag, setDataFlag] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const [user, setUser] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    telephone_number: "",
    address_1: "",
    address_2: "",
    country: "",
    city: "",
    state: "",
    zip: "",
    profile_image: "",
  });
  const nameChangeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "zip") {
      const regex = /^\d+$/;
      if (value === "") {
        setUser({ ...user, [name]: value });
        return;
      }

      if (regex.test(value) === false) return;
    }

    setUser({ ...user, [name]: value });
  };
  /* set field states */
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [phoneVal, setPhoneVal] = useState("");

  // New States
  const [addBtnDisabled, setAddBtnDisabled] = useState(false);

  const [image_files, setImage_files] = useState(null);
  const [profileImage, setProfileImage] = useState(
    userInfo?.profile_image ? userInfo?.profile_image : ""
  );
  //End new states

  /* fields change handler */
  const firstNameHandler = (e) => setFirstName(e.target.value);
  const lastNameHandler = (e) => setLastName(e.target.value);
  const emailHandler = (e) => setEmail(e.target.value);
  const phoneHandler = (e) => setPhone(e.target.value);
  const address1Handler = (e) => setAddress1(e.target.value);
  const address2handler = (e) => setAddress2(e.target.value);
  const cityHandler = (e) => setCity(e.target.value);
  const countryHandler = (e) => setCountry(e.target.value);
  const stateHandler = (e) => setState(e.target.value);
  const zipHandler = (e) => setZip(e.target.value);

  /* set error states */
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [address1Error, setAddress1Error] = useState("");
  const [address2Error, setAddress2Error] = useState("");
  const [cityError, setCityError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [zipError, setZipError] = useState("");

  /* password field error states */
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");

  useEffect(() => {
    if (!showToast) {
      setLoading(true);
    }
    dispatch(sidebarActions.setSelectedNav("header"));
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/user/update_profile/`,
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: token,
      },
    })
      .then((response) => {
        const resData = response.data.data.user;
        dispatch(userActions.setUserInfo(response.data.data.user));
        setUser(resData);
        setProfileImage(response.data.data.user.profile_image);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });

  }, [dataFlag]);

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const updatePassword = (e) => {
    e.preventDefault();

    setAddBtnDisabled(true);

    /* reset password fields */
    setOldPasswordError("");
    setNewPasswordError("");

    const form = document.querySelector(".change-password-form");
    const fd = new FormData(form);

    axios({
      url: `${process.env.REACT_APP_URL_PATH}/user/reset_password/`,
      method: "POST",
      data: fd,
      headers: {
        "Content-Type": "Application/json",
        Authorization: token,
      },
    })
      .then((response) => {
        notifySuccess(response.data.message);

        //setDataFlag((prevState) => !prevState);
        setTimeout(() => {
          //window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        setAddBtnDisabled(false);
        if (error?.response?.data?.message === "Field Errors") {
          if (error.response.data.data["new_password"]) {
            setNewPasswordError(error.response.data.data["new_password"]);
          }
          if (error.response.data.data["old_password"]) {
            setOldPasswordError(error.response.data.data["old_password"]);
          }
        } else {
          notifyError(error?.response?.data?.message);
        }
      });
  };

  const updateProfile = (e) => {
    e.preventDefault();
    setLoading2(true);

    setAddBtnDisabled(true);
    // reset field errors
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");
    setAddress1Error("");
    setCityError("");
    setCountryError("");
    setStateError("");
    setZipError("");

    const form = document.querySelector(".main-form");
    const fd = new FormData(form);

    if (image_files) {
      fd.append("profile_image", image_files, image_files.name); //error when empty, to be resolved later.
    }


    let phoneVal = fd.get("telephone_number").replace(/\s+/g, "");
    fd.append("telephone_number", phoneVal);

    axios({
      url: `${process.env.REACT_APP_URL_PATH}/user/update_profile/`,
      method: "POST",
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading2(false);
        notifySuccess(response.data.message);

        setShowToast(true);
        setDataFlag((prevState) => !prevState);

        setAddBtnDisabled(false);
      })
      .catch((error) => {
        setLoading2(false);
        setAddBtnDisabled(false);
        console.log(error, error?.response)
        if (error?.response?.data?.message === "Field Errors") {
          /* set address field error */
          if (
            error.response.data.data["user_form"] &&
            "profile_image" in error.response.data.data["user_form"]
          ) {
            notifyError(
              error.response.data.data["user_form"]["profile_image"][0]
            );
          }
          if (
            error.response.data.data["address_form"] &&
            "address_1" in error.response.data.data["address_form"]
          ) {
            setAddress1Error(
              error.response.data.data["address_form"]["address_1"]
            );
          }
          if (
            error.response.data.data["address_form"] &&
            "address_2" in error.response.data.data["address_form"]
          ) {
            setAddress2Error(
              error.response.data.data["address_form"]["address_2"]
            );
          }
          if (
            error.response.data.data["address_form"] &&
            "city" in error.response.data.data["address_form"]
          ) {
            setCityError(error.response.data.data["address_form"]["city"]);
          }
          if (
            error.response.data.data["address_form"] &&
            "country" in error.response.data.data["address_form"]
          ) {
            setCountryError(
              error.response.data.data["address_form"]["country"]
            );
          }
          if (
            error.response.data.data["address_form"] &&
            "state" in error.response.data.data["address_form"]
          ) {
            setStateError(error.response.data.data["address_form"]["state"]);
          }
          if (
            error.response.data.data["address_form"] &&
            "zip" in error.response.data.data["address_form"]
          ) {
            setZipError(error.response.data.data["address_form"]["zip"]);
          }
          /* set phone field error */
          if (
            error.response.data.data["phone_form"] &&
            "telephone_number" in error.response.data.data["phone_form"]
          ) {
            setPhoneError(
              error.response.data.data["phone_form"]["telephone_number"]
            );
          }
          /* set user form errors */
          if (
            error.response.data.data["user_form"] &&
            "first_name" in error.response.data.data["user_form"]
          ) {
            setFirstNameError(
              error.response.data.data["user_form"]["first_name"]
            );
          }
          if (
            error.response.data.data["user_form"] &&
            "last_name" in error.response.data.data["user_form"]
          ) {
            setLastNameError(
              error.response.data.data["user_form"]["last_name"]
            );
          }
          /* set email field error */
          if (
            error.response.data.data["user_form"] &&
            "email" in error.response.data.data["user_form"]
          ) {
            setEmailError(error.response.data.data["user_form"]["email"]);
          }
        } else {
          notifyError(error?.response?.data?.message);
        }
      });
  };

  const handleProfilePicture = (event) => {
    let image_file_path = URL.createObjectURL(event.target.files[0]); //Base 64 Format
    let image_as_files = event.target.files[0];

    if (event.target.files && event.target.files[0]) {
      setProfileImage(image_file_path);
      setImage_files(image_as_files);
    }
  };

  if (loading) return <div></div>;
  return (
    <div className="profile-wrapper">
      <h1 className="heading-profile">Edit Profile</h1>

      <form className="main-form">
        <div className="upper-form">
          <div className="inner-form-upper">
            <div className="edit-field">
              <div className="image-wrapper">

                <div className="edit-image-wrapper">
                  <img
                    className="pro-image"
                    id={"profile_image"}
                    name={"profile_image"}
                    src={profileImage ? profileImage : Avatar_Image}
                    alt={Avatar_Image}
                  />
                  <span className="edit-image">
                    <input
                      type="file"
                      id="profilefile"
                      name="profileFile"
                      className="edit-file-upload"
                      accept="image/*"
                      onChange={handleProfilePicture}
                      disabled={addBtnDisabled}
                      required
                    />
                    <img src={editProfileIcon} alt="" />
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 left-col-p">
                <div className="edit-field">
                  <label htmlFor="first_name" className="label">
                    First Name *
                  </label>
                  <input
                    id="first_name"
                    name="first_name"
                    type="text"
                    placeholder="First Name"
                    className="custom-input"
                    value={user?.first_name.replace(stripSrciptRegex, "")}
                    onChange={nameChangeHandler}
                  />
                  {firstNameError && (
                    <p className="error-text">{firstNameError}</p>
                  )}
                </div>
              </div>

              <div className="col-6 right-col-p">
                <div className="edit-field ">
                  <label htmlFor="last_name" className="label">
                    Last Name *
                  </label>
                  <input
                    id="last_name"
                    name="last_name"
                    type="text"
                    placeholder="Last Name"
                    className="custom-input"
                    value={user?.last_name.replace(stripSrciptRegex, "")}
                    onChange={nameChangeHandler}
                  />
                  {lastNameError && (
                    <p className="error-text">{lastNameError}</p>
                  )}
                </div>
              </div>
              <div className="col-6 left-col-p">
                <div className="edit-field">
                  <label htmlFor="email" className="label">
                    Email *
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    className="custom-input"
                    value={user?.email}
                    onChange={nameChangeHandler}
                  />
                  {emailError && <p className="error-text">{emailError}</p>}
                </div>
              </div>

              <div className="col-6 right-col-p">
                <div className="edit-field ">
                  <label htmlFor="telephone_number" className="label">
                    Telephone *
                  </label>
                  <input
                    id="telephone_number"
                    name="telephone_number"
                    type="text"
                    placeholder="Telephone"
                    className="custom-input"
                    value={user?.telephone_number}
                    onChange={(e) => {
                      const { name, value } = e.target;

                      const regex = /^([\s+0-9])+$/i;
                      if (value === "") {
                        // case to remove whole highlighted text
                        setUser({ ...user, [name]: value });
                        return;
                      }
                      if (regex.test(value) === false) return;

                      if (value.length === 1 && value === "+") {
                        // case to remove + sign when last number is removed
                        setUser({ ...user, [name]: "" });
                      } else if (value.length >= 1 && value[0] !== "+") {
                        // case to append + sign with first number
                        setUser({ ...user, [name]: "+" + value });
                      } else {
                        // case to append other numbers
                        setUser({ ...user, [name]: value });
                      }
                    }}
                    required
                  />
                  {phoneError && <p className="error-text">{phoneError}</p>}
                </div>
              </div>
              <div className="col-6 left-col-p">
                <div className="edit-field">
                  <label htmlFor="address_1" className="label">
                    Address 01
                  </label>
                  <input
                    id="address_1"
                    name="address_1"
                    type="text"
                    placeholder="Address 01"
                    className="custom-input"
                    value={user?.address_1}
                    onChange={nameChangeHandler}
                  />
                  {address1Error && (
                    <p className="error-text">{address1Error}</p>
                  )}
                </div>
              </div>

              <div className="col-6 right-col-p">
                <div className="edit-field ">
                  <label htmlFor="address_2" className="label">
                    Address 02
                  </label>
                  <input
                    id="address_2"
                    name="address_2"
                    type="text"
                    placeholder="Address 02"
                    className="custom-input"
                    value={user?.address_2}
                    onChange={nameChangeHandler}
                  />
                  {address2Error && (
                    <p className="error-text">{address2Error}</p>
                  )}
                </div>
              </div>
              <div className="col-6 left-col-p">
                <div className="edit-field">
                  <label htmlFor="city" className="label">
                    City
                  </label>
                  <input
                    id="city"
                    name="city"
                    type="text"
                    placeholder="City"
                    className="custom-input"
                    value={user?.city?.replace(stripSrciptRegex, "")}
                    onChange={nameChangeHandler}
                  />
                  {cityError && <p className="error-text">{cityError}</p>}
                </div>
              </div>

              <div className="col-6 left-col-p">
                <div className="edit-field">
                  <label htmlFor="state" className="label">
                    State
                  </label>
                  <input
                    id="state"
                    name="state"
                    type="text"
                    placeholder="State"
                    className="custom-input"
                    value={user?.state?.replace(stripSrciptRegex, "")}
                    onChange={nameChangeHandler}
                  />
                  {stateError && <p className="error-text">{stateError}</p>}
                </div>
              </div>
              <div className="col-6 right-col-p">
                <div className="edit-field ">
                  <label htmlFor="zip" className="label">
                    Zip Code
                  </label>
                  <input
                    id="zip"
                    name="zip"
                    type="text"
                    placeholder="Zip"
                    className="custom-input"
                    value={user?.zip}
                    onChange={nameChangeHandler}
                  />
                  {zipError && <p className="error-text">{zipError}</p>}
                </div>
              </div>
              <div className="col-6 right-col-p">
                <div className="edit-field ">
                  <label htmlFor="country" className="label">
                    Country
                  </label>
                  <input
                    id="country"
                    name="country"
                    type="text"
                    placeholder="Country"
                    className="custom-input"
                    value={user?.country?.replace(stripSrciptRegex, "")}
                    onChange={nameChangeHandler}
                  />
                  {countryError && <p className="error-text">{countryError}</p>}
                </div>
              </div>

              <div className="col-12">
                <div className="edit-field action-profile-update">
                  <button
                    className="btn btn-primary-24 btn-primary-fill"
                    onClick={updateProfile}
                    disabled={addBtnDisabled}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* {!loading2 && <Loader2 />} */}
      </form>
      <form className="change-password-form">
        <h1 className="heading-profile change-heading">Change Password</h1>
        <div className="bottom-form">
          <div className="inner-form-upper">
            <div className="row">
              <div className="col-6 left-col-p">
                <div className="edit-field">
                  <label htmlFor="old_password" className="label">
                    Current Password
                  </label>
                  <input
                    id="old_password"
                    name="old_password"
                    type="password"
                    placeholder="Current Password"
                    className="custom-input"
                  />
                  {oldPasswordError && (
                    <p className="error-text">{oldPasswordError}</p>
                  )}
                </div>
              </div>
              <div className="col-6 right-col-p">
                <div className="edit-field">
                  <label htmlFor="new_password" className="label">
                    New Password
                  </label>
                  <input
                    id="new_password"
                    name="new_password"
                    type="password"
                    placeholder="New Password"
                    className="custom-input"
                  />
                  {newPasswordError && (
                    <p className="error-text">{newPasswordError}</p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="edit-field action-profile-update">
                  <button
                    className="btn btn-primary-24 btn-primary-fill"
                    onClick={updatePassword}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditProfile;
