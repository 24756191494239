import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Moment from "moment";
import DateTime from "../../../../components/DateTimePicker/DateTimePicker";
import "./AddGroupTestModal.scss";
import Loader2 from "../../../UI/loader2/loader2";
// import images
import CloseModal from "../../../../assets/close-outline.svg";
import { useHistory, useParams } from "react-router-dom";
import { dateParse, getWithExpiry } from "../../../utils/helper";
import Loader from "../../../UI/loader/loader";

const isNotEmpty = (value) => value.trim() !== "";
const isEmailValid = (value) => value.includes("@");

function AddGroupTestModal(props) {
  const {
    isShowModal,
    hideModal,
    type,
    setDataFlag,
    setShowToast,
    selectedTest,
    isEdit,
    communityInfo,
    communityId,
  } = props;

  const providerList = useSelector((state) => state.patient.providerList);

  // const newDateTime = isEdit
  //   ? Moment(new Date(selectedTest.expiration_date)).format("YYYY-MM-DD") +
  //     " " +
  //     new Intl.DateTimeFormat("en-GB", {
  //       hour: "2-digit",
  //       minute: "2-digit",
  //       second: "2-digit",
  //     }).format(new Date(selectedTest.expiration_date))
  //   : "";
  const [AddBilleeList, setAddBilleeList] = useState([]);
  const [AddProviderList, setAddProviderList] = useState([]);

  const [startDate, setStartDate] = useState(
    isEdit ? dateParse(selectedTest.expiration_date) : ""
  );

  const [groupNameError, setGroupNameError] = useState("");
  const [providerError, setProviderError] = useState("");
  const [billeeError, setBilleeError] = useState("");

  const formTitle = isEdit ? "Edit Group Test" : "Add Group Test";


  const group_test_id = selectedTest ? selectedTest.batch_test_id : "";
  const group_id = selectedTest ? selectedTest.group_id : "";

  const token = "Bearer " + getWithExpiry("expiry_token");
  const editGroupTestApiUrl = `${process.env.REACT_APP_URL_PATH}/community/edit_group_test/${group_id}/`;
  const addGroupTestApiUrl = `${process.env.REACT_APP_URL_PATH}/community/create_group_test/`;
  const addGroupTestApiUrlEdit = `${process.env.REACT_APP_URL_PATH}/community/create_group_test/${communityId}`;
  let apiPath = isEdit ? editGroupTestApiUrl : addGroupTestApiUrl;
  let apiPath2 = isEdit ? editGroupTestApiUrl : addGroupTestApiUrlEdit;
  const [billeeList, setBilleeList] = useState(null);

  const [disabledBtn, setDisabledBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [groupTest, setGroupTest] = useState({
    id: isEdit ? selectedTest.group_id : "",
    community_id: isEdit ? communityInfo.id : communityId,
    batch_code: isEdit ? selectedTest.batch_code : "",
    group_name: isEdit ? selectedTest.batch_name : "",
    no_of_tests: isEdit
      ? selectedTest.no_of_tests === 0
        ? ""
        : selectedTest.no_of_tests
      : "",
    provider_id: isEdit ? selectedTest.provider.provider_id : "",
    billee_id: isEdit ? selectedTest.billee.billee_id : "",
    expiry_date: isEdit ? selectedTest.expiration_date : "",
  });


  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "no_of_tests") {
      if (value === "") {
        setGroupTest({ ...groupTest, [name]: value });
        return;
      }
      if (value > 0 && value < 100000) {
        setGroupTest({ ...groupTest, [name]: value });
      }
    } else if (name === "group_name") {
      if (value.length <= 100) {
        setGroupTest({ ...groupTest, [name]: value });
      }
    } else {
      setGroupTest({ ...groupTest, [name]: value });
    }
  };

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    axios({
      // url: `${process.env.REACT_APP_URL_PATH}/community/create_group_test/${communityId}`,
      url: apiPath2,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        const data = response.data.data;
        if (isEdit) {
          setAddBilleeList(data.group_test.billee_list);
          setAddProviderList(data.group_test.provider_list);
        } else {
          setAddBilleeList(data.billee_list);
          setAddProviderList(data.providers_list);
        }
        if (!isEdit) {
          setGroupTest({ ...groupTest, batch_code: data.batch_code });
        }
      })
      .catch((error) => {
      });
  }, []);

  const handleProviderChange = (e) => {
    const { name, value } = e.target;
    //setBilleeList(null);
    // setProviderVal(value);
    setGroupTest({ ...groupTest, [name]: value });

    axios({
      url: `${process.env.REACT_APP_URL_PATH}/test/billee_list_group_test/${value}/`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        setAddBilleeList(response.data.data.billees_list);
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };
  function ISOtoLongDate(isoString, locale = "en-US") {
    const options = { month: "numeric", day: "numeric", year: "numeric" };
    const date = new Date(isoString);
    const longDate = new Intl.DateTimeFormat(locale, options).format(date);
    return longDate;
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    // // create form data here

    setLoading(true);
    setDisabledBtn(true);

    setGroupNameError("");
    setProviderError("");
    setBilleeError("");

    let dateTime;
    if (startDate) {
      let newDate = ISOtoLongDate(startDate.toISOString(), "en-GB");
      dateTime =
        Moment(newDate, "DD/MM/YYYY").format("YYYY-MM-DD") +
        " " +
        new Intl.DateTimeFormat("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(startDate);

    } else {
      dateTime = "";
    }

    const fd = new FormData();

    if (!isEdit) {
      fd.append("timezone", Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
    fd.append("community_id", groupTest.community_id);
    fd.append("billee_id", groupTest.billee_id);
    fd.append("provider_id", groupTest.provider_id);
    fd.append("batch_code", groupTest.batch_code);
    fd.append("expiry_date", dateTime);

    fd.append("group_name", groupTest.group_name);
    fd.append("no_of_tests", groupTest.no_of_tests);

    axios({
      method: "post",
      url: apiPath,
      data: fd,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      })
      .catch((error) => {
        setLoading(false);
        setDisabledBtn(false);
        const resData = error.response.data;
        if (error.response.data.message === "Field Errors") {
          if ("billee_id" in error.response.data.data) {
            setBilleeError(error.response.data.data["billee_id"][0]);
          }
          if ("provider_id" in error.response.data.data) {
            setProviderError(error.response.data.data["provider_id"][0]);
          }
          if ("group_name" in error.response.data.data) {
            setGroupNameError(error.response.data.data["group_name"][0]);
          }
        } else {
          notifyError(error.response.data.message);
        }
      });
  };

  return (
    <div className="action-modal-wrapper">
      <Modal
        className="groupTest-modal modal-loader"
        show={isShowModal}
        size="md"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        // aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header
          className="comubitie-modal-header"
          style={{ padding: "1rem 0rem" }}
        >
          <div className="cdh-left">
            <h1 className="cdh-heading heading-tag">
              {isEdit ? "Edit Group Test" : "Create Group Test"}
            </h1>
            <span className="tag tag-modal">{groupTest?.batch_code}</span>
          </div>
          {/* <h1 className="modal-header-title">
            {formTitle}
            <span className="tag zip-tag">12345</span>
          </h1> */}
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="comunitie-modal-body">
          <form className="add-corporate-form" onSubmit={handleSubmit}>
            <div>
              <div className="row">
                <div className="col-lg-12 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="batch_name" className="label">
                      Group Name *
                    </label>
                    <input
                      id="group_name"
                      name="group_name"
                      type="text"
                      placeholder="Group Name"
                      className="custom-input"
                      value={groupTest.group_name}
                      onChange={handleChange}
                    />
                    {groupNameError && (
                      <p className="error-text">{groupNameError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-12 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="no_of_tests" className="label">
                      No. of Tests
                    </label>
                    <p className="inner-label-text">
                      will be infinite if left empty
                    </p>
                    <input
                      id="no_of_tests"
                      name="no_of_tests"
                      type="number"
                      placeholder="No. of Tests"
                      className="custom-input"
                      value={groupTest.no_of_tests}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="provider_id" className="label">
                      Provider *
                    </label>
                    <select
                      // type="text"
                      name="provider_id"
                      id="provider_id"
                      className="custom-select-modal"
                      placeholder="Select Provider"
                      value={groupTest.provider_id}
                      onChange={handleProviderChange}
                    >
                      {groupTest.provider_id === "" && (
                        <option value="">Select a Provider</option>
                      )}
                      {AddProviderList &&
                        AddProviderList.map((value) => (
                          <option key={value.id} value={value.id}>
                            {value.name}
                          </option>
                        ))}
                    </select>
                    {providerError && (
                      <p className="error-text">{providerError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-12 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="billee_id" className="label">
                      Billee *
                    </label>
                    <select
                      //type="text"
                      name="billee_id"
                      id="billee_id"
                      className="custom-select-modal"
                      placeholder="billee"
                      value={groupTest.billee_id}
                      onChange={handleChange}
                    >
                      {groupTest.billee_id === "" && (
                        <option value="">Select a Billee</option>
                      )}
                      {AddBilleeList &&
                        AddBilleeList.map((value) => (
                          <option key={value.billee_id || value.id} value={value.billee_id || value.id}>
                            {value.billee_name || value.name}
                          </option>
                        ))}
                    </select>
                    {billeeError && <p className="error-text">{billeeError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="expiry_date" className="label">
                      Expiry Date
                    </label>
                    <DateTime
                      startDate={startDate}
                      setStartDate={setStartDate}
                      type={"group"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            id="modal-close-btn"
            className="btn btn-secondary-fill btn-primary-16"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            className="btn btn-primary-fill btn-primary-16"
            disabled={disabledBtn}
            onClick={handleSubmit}
          >
            {isEdit ? "Update" : "Create"}
          </button>
          {/* <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          /> */}
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

export default AddGroupTestModal;
