import React, { Fragment, useState, useEffect } from "react";
import RowActions from "./RowActions";
import CreateTestModal from "../../UI/Modals/CreateTestModal";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { getWithExpiry } from "../../utils/helper";
const TestsToScheduleBody = (props) => {
  const { data, type, subType, setId, setDataFlag, setShowToast } = props;
  const [createTestModalOpen, setCreateTestModalOpen] = useState(false);
  const [testInfo, setTestInfo] = useState([]);
  const [location, setLocation] = useState("");

  const [providerList, setProviderList] = useState(null);
  const [billeeList, setBilleeList] = useState(null);
  const [iPhoneList, setIPhoneList] = useState(null);

  const showCreateTestModal = (data) => {

    if (!data.is_enabled) {
      notifyError("Cannot Create Test for Disabled Patient");
      return;
    }

    axios({
      url: `${process.env.REACT_APP_URL_PATH}/test/create_test/?patient_id=${data?.id}`,
      method: "GET",

      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        setCreateTestModalOpen(true);
        setProviderList(response.data.data.provider_list);
        setIPhoneList(response.data.data.iphones);
        setTestInfo(data);
        setLocation(data.location);
      })
      .catch((error) => {
      });
  };

  const hideCreateTestModal = () => setCreateTestModalOpen(false);
  const token = "Bearer " + getWithExpiry("expiry_token");

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <Fragment>
      {createTestModalOpen && (
        <CreateTestModal
          isShowModal={createTestModalOpen}
          hideModal={hideCreateTestModal}
          type="Test"
          setDataFlag={setDataFlag}
          providerList={providerList}
          testInfo={testInfo}
          iphones={iPhoneList}
          setShowToast={setShowToast}
          location={location}
        />
      )}
      <tbody>
        {(data?.schedule_test_list?.length === 0 || data?.length === 0) && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        {data &&
          data.schedule_test_list &&
          data.schedule_test_list.map((record) => (
            <tr key={Math.random()}>
              <td>{record.first_name}</td>
              <td>{record.last_name}</td>
              <td>{record.dob}</td>
              <td>{record.last_test_date ? record.last_test_date : "N/A"}</td>
              <RowActions
                testInfo={{
                  id: record.id,
                  firstName: record.first_name,
                  lastName: record.last_name,
                  dateOfBirth: record.dob,
                  lastTestDate: record.last_test_date,
                  location: record.location,
                  is_enabled: record.is_enabled,
                  email: record.email,
                  telephone: record.telephone,
                }}
                setId={setId}
                recordId={record.id}
                showCreateTestModal={showCreateTestModal}
                location={record.location}
                type={type}
                subType={subType}
                handleClick={() => showCreateTestModal(record)}
              />
            </tr>
          ))}
      </tbody>

      {/* <tbody>
        {data && data.schedule_test_list ? (
          data.schedule_test_list.map((record) => (
            <tr key={Math.random()}>
              <td>{record.first_name}</td>
              <td>{record.last_name}</td>
              <td>{record.dob}</td>
              <td>{record.last_test_date ? record.last_test_date : "N/A"}</td>
              <RowActions
                testInfo={{
                  id: record.id,
                  firstName: record.first_name,
                  lastName: record.last_name,
                  dateOfBirth: record.dob,
                  lastTestDate: record.last_test_date,
                  location: record.location,
                }}
                setId={setId}
                recordId={record.id}
                showCreateTestModal={showCreateTestModal}
                type={type}
                subType={subType}
                handleClick={() => showCreateTestModal(record)}
              />
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
      </tbody> */}

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Fragment>
  );
};

export default TestsToScheduleBody;
