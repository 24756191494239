import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import "./AddPatient.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import { dateParse } from "../../utils/helper";
import axios from "axios";
import DatePicker from "react-datepicker";
// import images
import CloseModal from "../../../assets/close-outline.svg";
import { getWithExpiry } from "../../utils/helper";
import Loader2 from "../../UI/loader2/loader2";
import { stripSrciptRegex } from "../../../constants";

const AddPatient = (props) => {
  const {
    isShowModal,
    hideModal,
    type,
    record,
    communityId,
    setDataFlag,
    setShowToast,
    outerEdit,
    patientEditId,
    isEdit,
  } = props;
  const token = "Bearer " + getWithExpiry("expiry_token");
  const { patientID: patientId } = useParams();

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [dobError, setDobError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [addBtnDisabled, setAddBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    dob: "",
    telephone_number: "",
  });
  const [phoneValue, setPhoneValue] = useState("");
  const communityList = useSelector((state) => state.patient.communityList);
  const [startDate, setStartDate] = useState("");
  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });


  function dateParse(dateTime) {
    let parts = dateTime.split(" ");
    let date = parts[0].split("-");
    return new Date(date[2], date[0] - 1, date[1]);
  }
  useEffect(() => {
    if (outerEdit) {
      axios({
        url: `${process.env.REACT_APP_URL_PATH}/community/update_patient/${patientEditId}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((response) => {
          const resData = response.data.data.patient;
          const newDate = dateParse(resData.dob);
          setStartDate(newDate);
          setUser({
            ...user,
            first_name: resData.first_name,
            last_name: resData.last_name,
            email: resData.email,
            dob: newDate,
            telephone_number: resData.telephone_number,
          });
          setPhoneValue(
            resData.telephone_number ? resData.telephone_number : ""
          );
        })
        .catch((error) => {
        });
    } else if (record) {
      const newDate = dateParse(record.dob);
      setStartDate(newDate);
      setUser({
        ...user,
        first_name: record.first_name,
        last_name: record.last_name,
        email: record.email,
        dob: newDate,
        telephone_number: record.telephone_number,
      });
      setPhoneValue(record.telephone_number ? record.telephone_number : "");
    }
  }, []);

  function ISOtoLongDate(isoString, locale = "en-US") {
    const options = { month: "numeric", day: "numeric", year: "numeric" };
    const date = new Date(isoString);
    const longDate = new Intl.DateTimeFormat(locale, options).format(date);
    return longDate;
  }
  const addPatientSubmissionHandler = (event) => {
    event.preventDefault();

    setLoading(true);

    setFirstNameError("");
    setLastNameError("");
    setDobError("");
    setEmailError("");
    setTelephoneError("");

    setAddBtnDisabled(true);
    const fd = new FormData();
    fd.append("first_name", user.first_name);
    fd.append("last_name", user.last_name);

    let dateTime = "";
    if (startDate) {
      let newDate = ISOtoLongDate(startDate.toISOString(), "en-GB");

      dateTime = Moment(newDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    }
    fd.append("dob", dateTime);

    if (user.email) {
      fd.append("email", user.email);
    }
    fd.append("role", role);

    if (phoneValue && !phoneValue.includes("+", 0)) {
      fd.append("telephone_number", "+" + phoneValue);
    } else if (phoneValue) {
      fd.append("telephone_number", phoneValue);
    }

    if (communityId) {
      fd.append("community_id", communityId);
    } else if (!isEdit) {
      if (communityList.length === 1) {
        fd.append("community_id", communityList[0].id);
      } else {
        fd.append("community_id", role);
      }
    }

    const urlMapping = {
      Patient: "/community/add_patient/",
      patient: "/community/add_patient/", // coming from community
      PatientDetails: `/community/update_patient/${
        patientEditId ? patientEditId : record?.id
      }`,
    };

    // make http call
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}${urlMapping[type]}`,
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);

        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      
      })
      .catch((error) => {
        console.error("ADD PATIENT ERROR", error.response);
        setLoading(false);
        setAddBtnDisabled(false);
        const errorData = error.response.data;
        if (error.response && errorData.message === "Field Errors") {
          if (errorData.message === "Field Errors") {
            if (
              errorData.data["patient_form"] &&
              "first_name" in errorData.data["patient_form"]
            ) {
             
              setFirstNameError(
                errorData.data["patient_form"]["first_name"][0]
              );
            }
            if (
              errorData.data["patient_form"] &&
              "last_name" in errorData.data["patient_form"]
            ) {
              
              setLastNameError(errorData.data["patient_form"]["last_name"][0]);
            }
            if (
              errorData.data["patient_form"] &&
              "dob" in errorData.data["patient_form"]
            ) {
             
              setDobError(errorData.data["patient_form"]["dob"][0]);
            }
            if (
              errorData.data["patient_form"] &&
              "email" in errorData.data["patient_form"]
            ) {
              
              setEmailError(errorData.data["patient_form"]["email"][0]);
            }

            if (
              errorData.data["phone_form"] &&
              "telephone_number" in errorData.data["phone_form"]
            ) {
              
              setTelephoneError(
                errorData.data["phone_form"]["telephone_number"][0]
              );
            }
          } else {
            notifyError(errorData.message);
          }
        } else {
          notifyError(errorData.message);
        }
      });
  };

  const roleChangeHandler = (e) => {
    setRole(e.target.value);
  };

  const nameChangeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "telephone_number") {
      if (value === "") {
        setUser({ ...user, [name]: value });
        return;
      }
      if (/^[+()\d]+$/.test(value)) {
        setUser({ ...user, [name]: value });
      }
    } else {
      setUser({ ...user, [name]: value });
    }
  };

  return (
    <div className="action-modal-wrapper">
      <Modal
        className="comunitie-modal initiate-test-patient-modal modal-loader"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header
          className="comubitie-modal-header"
          style={{ padding: "1rem 0rem" }}
        >
          <h1 className="modal-header-title">
            {type === "Patient" || type === "patient"
              ? "Add Patient"
              : "Edit Patient"}
          </h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="comunitie-modal-body">
          <form
            
            method="post"
            className="add-patient-form"
            onSubmit={addPatientSubmissionHandler}
          >
            <div className="communities-fields">
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="first_name" className="label">
                      First Name *
                    </label>
                    <input
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="First Name"
                      className="custom-input"
                      value={user ? user.first_name : ""}
                      onChange={nameChangeHandler}
                      required
                    />
                    {firstNameError && (
                      <p className="error-text">{firstNameError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="last_name" className="label">
                      Last Name *
                    </label>
                    <input
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Last Name"
                      className="custom-input"
                      value={user ? user.last_name : ""}
                      onChange={nameChangeHandler}
                      required
                    />
                    {lastNameError && (
                      <p className="error-text">{lastNameError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="dob" className="label">
                      DOB *
                    </label>
                    <div className="date-time-wrapper">
                      <DatePicker
                        className="custom-dateTime-input"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        // onChangeRaw={(e) => {
                        //   e.preventDefault();
                        // }}
                        dateFormat="MM/dd/yyyy"
                        maxDate={new Date()}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      <span className="dateTime-divider"></span>
                    </div>
                    {/* <input
                      ref={dobInput}
                      id="dob"
                      name="dob"
                      type="date"
                      className="custom-input"
                      value={user.dob}
                      //defaultValue={user.dob}
                      onChange={nameChangeHandler}
                      onFocus={() => {
                        dobInput.current.max = new Date()
                          .toISOString()
                          .split("T")[0];
                      }}
                      onKeyDown={(e) => e.preventDefault()}
                    /> */}
                    {dobError && <p className="error-text">{dobError}</p>}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="email" className="label">
                      Email
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      className="custom-input"
                      value={user ? user.email : ""}
                      onChange={nameChangeHandler}
                      required
                    />
                    {emailError && <p className="error-text">{emailError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="telephone_number" className="label">
                      Telephone
                    </label>
                    {/* <input
                      type="text"
                      id="telephone_number"
                      name="telephone_number"
                      className="custom-input"
                      placeholder="Telephone"
                      value={
                        user?.telephone_number ? user.telephone_number : ""
                      }
                      onChange={(e) => {
                        const { value, name } = e.target;

                        const regex = /^([\s+0-9])+$/i;
                        if (value === "") {
                          // case to remove whole highlighted text
                          setUser({ ...user, [name]: value });
                          return;
                        }
                        if (regex.test(value) === false) return;

                        if (value.length === 1 && value === "+") {
                          // case to remove + sign when last number is removed
                          setUser({ ...user, [name]: "" });
                        } else if (value.length >= 1 && value[0] !== "+") {
                          // case to append + sign with first number
                          setUser({ ...user, [name]: "+" + value });
                        } else {
                          // case to append other numbers
                          setUser({ ...user, [name]: value });
                        }
                      }}
                      required
                    /> */}
                    <PhoneInput
                      country={"us"}
                      value={phoneValue}
                      onChange={setPhoneValue}
                      inputProps={{
                        required: true,
                      }}
                    />
                    {telephoneError && (
                      <p className="error-text">{telephoneError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  {type === "Patient" && (
                    <div className="field-wrapper">
                      <label htmlFor="role" className="label">
                        Community *
                      </label>
                      <select
                        name="role"
                        id="role"
                        className="custom-select-modal"
                        onChange={roleChangeHandler}
                        value={role}
                      >
                        {communityList.length > 1 && role === "" && (
                          <option value="">{"Select a Community"}</option>
                        )}
                        {communityList &&
                          communityList.map((community) => (
                            <option
                              key={community.id}
                              value={`${community.id}`}
                            >
                              {community.community_name.replace(stripSrciptRegex, "")}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="user-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16 text-capitalize"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            onClick={addPatientSubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={addBtnDisabled}
          >
            {type === "Patient" || type === "patient" ? "Add" : "Update"}
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
};

export default AddPatient;
