import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getWithExpiry } from "../../../utils/helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader2 from "../../../UI/loader2/loader2";
import "./ResendModal.scss";

import axios from "axios";

// import images
import CloseModal from "../../../../assets/close-outline.svg";
import { useHistory, useParams } from "react-router-dom";
import DeleteRowIcon from "../../../../assets/delete-row.svg";
import ResendRowIcon from "../../../../assets/resend.svg";

function ResendModal(props) {
  const {
    isShowModal,
    hideModal,
    type,
    data,
    setDataFlag,
    setShowToast,
    resendID,
  } = props;
  const token = "Bearer " + getWithExpiry("expiry_token");
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [loading, setLoading] = useState(false);

  const notifySuccesss = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const resendEmail = () => {
    setLoading(true);
    setDisabledBtn(true);


    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/user/resend_password_email/`,
      data: { user_id: resendID },
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        hideModal();

        notifySuccesss(response.data.message);

        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      })
      .catch((error) => {
        setLoading(false);
        setDisabledBtn(false);
        if (error.response && error.response.data.message !== "Field Errors") {
          notifyError(error.response.data.message);
        }
      });
  };

  return (
    <div className="action-modal-wrapper">
      <Modal
        className="action-modal modal-loader"
        show={isShowModal}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="action-modal-header" id="disable-modal">
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="action-modal-body">
          <img src={ResendRowIcon} alt="" />
          <h2 className="action-heading">
            Are you sure you want to resend email?
          </h2>
        </Modal.Body>
        <Modal.Footer className="action-footer-modal" id="disable-modal-footer">
          <button
            className="btn btn-primary-fill btn-14-24"
            onClick={resendEmail}
            disabled={disabledBtn}
          >
            Yes, I’m Sure.
          </button>
          <button
            className="btn btn-primary-outline btn-14-24"
            onClick={hideModal}
          >
            Cancel
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

export default ResendModal;
