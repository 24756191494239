import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import CustomSelect from "../../../General/filters/CustomSelect.Component";
import { filterActions } from "../../../../store/filter";
import { removeEmptyParams } from "../../../../services/helpers/sorting.hepler";
import { communityActions } from "../../../../store/community";
import { getWithExpiry } from "../../../utils/helper";
import axios from "axios";
import useQueryString from "../../../../hooks/useQueryString";
import "./FiltersModal.scss";
// imports image

import CloseModal from "../../../../assets/close-outline.svg";
import DisabledIcon from "../../../../assets/disabled-row.svg";

function FilterModal(props) {
  const { isShowModal, hideModal, orderBy, setDataFlag, setShowToast } = props;

  const history = useHistory();
  const { communityID } = useParams();
  const dispatch = useDispatch();
  const urlParams = useQueryString();
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(true);

  const searchCommunityUserText = useSelector(
    (state) => state.filter.searchCommunityUserText
  );
  const communityUserFilteredRole = useSelector(
    (state) => state.community.communityUserFilteredRole
  );

  const communityUserRoles = useSelector(
    (state) => state.community.communityUserRoles
  );

  const returnSelectedArray = async (object) => {
    if (!object || object.length <= 0) return [];
    var _selected = [];
    object.map((dataKey) => {
      _selected.push(dataKey.value.trim());
    });
    // setSelectedFilter(_selected);
    return _selected;
  };

  const handleSelectRole = async (e) => {
    var _selected = await returnSelectedArray(e);
    // dispatch(communityActions.setCommunityUserFilteredRole(_selected));
    setFilterData(_selected);
  };
  useEffect(() => {
    setFilterData(communityUserFilteredRole);
  }, []);
  const filterSubmissionHandler = () => {
    let customQuery = `search_query=${searchCommunityUserText}&${orderBy}&role:eq=${filterData}`;

    var modifiedString = removeEmptyParams(customQuery);
    let prefix = "";
    let searchParam = "";
    if (modifiedString || urlParams.toString().includes("page")) {
      prefix = "tab_key=user&";
    }
    if (
      !modifiedString.includes("page") &&
      urlParams.toString().includes("page")
    ) {
      searchParam = "?" + prefix + modifiedString + `&page=${1}`;
    } else {
      searchParam = "?" + prefix + modifiedString;
    }

    searchParam = searchParam.replace("&&", "&");
    history.replace({
      pathname: `/edit/communities/${communityID}`,
      search: searchParam,
    });

    dispatch(
      communityActions.setUserCommunityApiUrl("?tab_key=user&" + modifiedString)
    );
    dispatch(communityActions.setCommunityUserFilteredRole(filterData));
    setShowToast(true);
    setDataFlag((prevState) => !prevState);
    hideModal();
  };

  const clearFilters = () => {
    var modifiedString = "";
    let prefix = "";
    let searchParam = "";
    if (modifiedString || urlParams.toString().includes("page")) {
      prefix = "tab_key=user&";
    }
    if (
      !modifiedString.includes("page") &&
      urlParams.toString().includes("page")
    ) {
      searchParam = "?" + prefix + modifiedString + `&page=${1}`;
    } else {
      searchParam = "?" + prefix + modifiedString;
    }

    searchParam = searchParam.replace("&&", "&");
    history.replace({
      pathname: `/edit/communities/${communityID}`,
      search: searchParam,
    });

    dispatch(
      communityActions.setUserCommunityApiUrl("?tab_key=user&" + modifiedString)
    );
    dispatch(communityActions.setCommunityUserFilteredRole([]));
    setFilterData([]);
    setShowToast(true);
    setDataFlag((prevState) => !prevState);
    hideModal();
  };
  return (
    <div className="filter-modal-wrapper">
      <Modal
        className="filter-modal"
        show={isShowModal}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
      >
        <Modal.Header className="filter-modal-header">
          <h1 className="modal-header-title">Filters</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>

        <Modal.Body className="filter-modal-body show-select">
          <Fragment>
            <CustomSelect
              selectedValues={filterData}
              labelText="Role"
              name="role"
              id="role"
              isMulti={true}
              data={communityUserRoles}
              handleSelectChange={handleSelectRole}
            />
          </Fragment>
        </Modal.Body>
        <Modal.Footer className="filter-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16"
            onClick={clearFilters}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary-fill btn-primary-16"
            onClick={filterSubmissionHandler}
          >
            Apply Filters
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FilterModal;
