import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import auth, { authActions } from "../../../store/auth";
import axios from "axios";
import useInput from "../../../hooks/use-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ForgotPassword.scss";

// import images
import BrandLogo from "../../../assets/logo.png";
import ArrowRight from "../../../assets/arrow-right.svg";

const isEmailValid = (value) => value.includes("@");

function ForgotPassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [disabledBtn, setDisabledBtn] = useState(false);

  const [emailSent, setEmailSent] = useState(false);

  const {
    value: emailVal,
    isValid: emailIsValid,
    hasError: hasEmailError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput(isEmailValid);

  let formIsValid = false;

  if (emailIsValid) {
    formIsValid = true;
  }

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const resetPasswordHandler = async (event) => {
    event.preventDefault();
    setDisabledBtn(true);

    if (!emailIsValid) {
      formIsValid = false;
      return;
    }

    // create form data
    const form = document.querySelector(".forgot-password-form");
    const fd = new FormData(form);

    // make an HTTP call here to get the email OTP
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/user/forget_password_otp/`, //http:127.0.0.1/user/forget_password_otp/
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        setDisabledBtn(false);
        setEmailSent(true);
        dispatch(authActions.verifyForgotPasswordOtp(emailVal));
        dispatch(
          authActions.setForgotPasswordSuccessMsg(response.data.message)
        );
        dispatch(authActions.setPathCheck(true));
        // history.replace("/verify-forgot-password-otp");
        
      })
      .catch((error) => {
        if (error.response) {
          setDisabledBtn(false);

          notifyError(error.response.data.message);
          // error.response.data.message
        }
      });
  };

  const disabledBtnClass = disabledBtn ? "disabled" : "";

  return (
    <div className="login-screen-wrapper">
      <div className="login-box">
        <div className="login-logo">
          <img src={BrandLogo} alt="" />
        </div>
        <div className="login-header">
          <h1 className="login-heading">Forgot Password</h1>
          {!emailSent && <p className="login-des">Please enter your email</p>}
          {/* <p className="login-des">Please enter your email</p> */}
        </div>
        {!emailSent ? (
          <form
           
            method=""
            className="forgot-password-form"
            onSubmit={resetPasswordHandler}
          >
            <div className="login-field">
              <label htmlFor="email" className="login-label">
                Email Address *
              </label>
              <input
                id="email"
                name="email"
                type="email"
                className="custom-input"
                placeholder="Email Address"
                value={emailVal}
                onChange={emailChangeHandler}
                onBlur={emailBlurHandler}
                required
              />
              {/* {hasEmailError && <p className="error-text">Email address is not valid</p>} */}
            </div>
            <ul className="check-forget-password back-login text-right">
              <li className="password-forget w-100">
                <Link to="/login">
                  <p>Back to Login</p>
                </Link>
              </li>
            </ul>
            <div className="login-action">
              <button
                className={`btn btn-primary-fill btn-14-24 ${disabledBtnClass}`}
                disabled={disabledBtn}
                // onClick={showMessage}
              >
                REQUEST RESET PASSWORD
                <img className="arrow-right" src={ArrowRight} alt="" />
              </button>
            </div>
          </form>
        ) : (
          <>
            <div className="forgot-password-text-wrapper">
            <p className="m-auto p-2">
                We've emailed you instructions for resetting your password, if an
                account exists with the email you just provided, you should receive
                them shortly.
              </p>
              <p className="m-auto p-2">
                If you don't receive an email, please make sure you've entered
                the address you registered with, and check your spam folder as
                well.
              </p>
            </div>
          </>
        )}
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ForgotPassword;
