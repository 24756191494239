import React, { Fragment } from "react";
import Edit from "../../../assets/edit.svg";
import DisabledEdit from "../../../assets/disabled-edit.svg";
import Delete_Icon from "../../../assets/delete-btn.png";
import Save from "../../../assets/save.svg";
import Eye from "../../../assets/eye-icon.svg";
import Preview from "../../../assets/preview.svg";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "./Table.scss";
import DisableModal from "../../Corporate/Modals/DisableModal/DisableModal";
import { useDispatch } from "react-redux";
import { corporateActions } from "../../../store/corporate";
import { communityActions } from "../../../store/community";

import EnableIcon from "../../../assets/checkicon.svg";
import ResendIcon from "../../../assets/resend-icon.svg";
import DisbaledResendIcon from "../../../assets/disabled-resend.svg";
const BilleeRowActions = (props) => {
  const {
    showDeleteModal,
    showDisableModal,
    showEnableModal,
    showEditModal,
    showCreateTestModal,
    recordId,
    showTestsModal,
    actionType,
    setId,
    isEnable,
    isDefault,
    type,
    testInfo,
    name,
    subType,
    record,
    showResendModal,
  } = props;

  const notifySuccesss = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const dispatch = useDispatch();

  const dispatchActionTypeEdit = () => {
    if (record.is_logged_in_user) {
      notifyError("Edit your details from the profile section");
    } else {
      showEditModal();
      dispatch(communityActions.setActionType("edit"));

      setId(recordId, "edit");
    }
  };
  const dispatchActionTypeDelete = () => {
    showDeleteModal();
    dispatch(communityActions.setActionType("delete"));

    setId(recordId, "delete");
  };

  const dispatchActionTypeDisable = () => {
    showDisableModal();
    dispatch(communityActions.setActionType("disable"));

    setId(recordId, "disable");
  };

  const dispatchActionTypeEnable = () => {
    showEnableModal();
    dispatch(communityActions.setActionType("delete"));

    setId(recordId, "enable");
  };
  const resendEmail = () => {
    if (record.is_logged_in_user) {
      notifyError("You cannot send email to yourself");
    } else {
      showResendModal();
      setId(record?.user_id, "resend");
    }
  };
  return (
    <Fragment>
      <td>
        {/* <Link
          title="Edit"
          // to={`/edit/patients/${recordId}`}
          style={{ display: "inline" }}
          // className={`${disabledClassName}${defaultClassName}`}
          // onClick={(e) => setBreadcrumb(e, name)}
        >
          <span className="td-action">
            <img src={Edit} alt="" />
          </span>
        </Link> */}
        <span
          title="Edit"
          className="td-action"
          // className={`td-action ${disabledClassName}${defaultClassName}`}
          onClick={dispatchActionTypeEdit}
        >
          <img
            src={record.is_logged_in_user ? DisabledEdit : Edit}
            alt="Edit-icon"
          />
        </span>
        <span
          title="Resend Email"
          className="td-action"
          // className={`td-action ${disabledClassName}${defaultClassName}`}
          onClick={resendEmail}
        >
          <img
            src={record.is_logged_in_user ? DisbaledResendIcon : ResendIcon}
            alt="edit-icon"
          />
        </span>
        {/* uncomment the following to get the delete functionality */}
        <span
          title="Delete"
          className="td-action"
          // className={`td-action ${disabledClassName}${defaultClassName}`}
          onClick={dispatchActionTypeDelete}
        >
          <img
            src={Delete_Icon}
            alt="delete-icon"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
            }}
          />
        </span>{" "}
        {/* uncomment the following to get the enable/disable functionality */}
        {/* {isEnable && (
          <span
            title="Disable"
            className="td-action"
            // className={`td-action ${disabledClassName}${defaultClassName}`}
            onClick={dispatchActionTypeDisable}
          >
            <img src={Save} alt="disable-icon" />
          </span>
        )}
        {!isEnable && (
          <span
            title="Enable"
            className="td-action"
            onClick={dispatchActionTypeEnable}
          >
            <img src={EnableIcon} alt="enable-icon" />
          </span>
        )} */}
      </td>
    </Fragment>
  );
};

export default BilleeRowActions;
