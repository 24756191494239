import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Loader2 from "../../../UI/loader2/loader2";
// import images
import CloseModal from "../../../../assets/close-outline.svg";
import { getWithExpiry } from "../../../utils/helper";
import FileImage from "../../../../assets/file.svg";
import DownloadFileImage from "../../../../assets/download.svg";
import { saveAs } from "file-saver";
// Components imports
import {
  corporateModalFields,
  corporateCommunityModalFields,
  communityModalFields,
  iphoneModalFields,
  userModalFieldsCorporate,
  userModalFieldsCommunity,
  regularUserModalFields,
  adminUserModalFields,
  patientModalFields,
  billeeModalFields,
  patientCommunityModalFields,
  providerModalFields,
  providerCommunityModalFields
} from "./utils";
import {
  iphoneOptionalFields,
  corporateOptionalFields,
  communityOptionalFields,
  userOptionalFieldsCorporate,
  userOptionalFieldsCommunity,
  regularUserOptionalFields,
  adminUserOptionalFields,
  patientOptionalFields,
  billeeOptionalFields,
  patientCommunityOptionalFields,
  providerOptionalFields,
  providerCommunityOptionalFields
} from "./optionalFields";
import UploadFile from "./UploadFile";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./ImportCorporateModal.scss";
import { corporateActions } from "../../../../store/corporate";
import { communityActions } from "../../../../store/community";

const ImportModal = (props) => {
  const dispatch = useDispatch();
  const {
    isShowModal,
    hideModal,
    type,
    dataType,
    modalFormData,
    communityId,
    corporateId,
    setDataFlag,
    setShowToast,
  } = props;
  const token = getWithExpiry("expiry_token");
  const headers = useSelector((state) => state.corporate.fileUploadData); // file headers
  const filteredData = useSelector((state) => state.corporate.filteredFileData);
  const corporateFile = useSelector((state) => state.corporate.corporateFile); // file data
  const [formData, setFormData] = useState();
  const [addBtnDisabled, setAddBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorTxt, setErrorTxt] = useState([]);
  const [initialState, setInitialState] = useState([]);

  useEffect(() => {
    setFormData(modalFormData)
    if(modalFormData){
      setErrorTxt(new Array(Object.keys(modalFormData).length).fill(""));
      setInitialState(new Array(Object.keys(modalFormData).length).fill(""))
    }
  },[modalFormData])

  const [dropdownState, setDropdownState] = useState([]);
  // const [initialState, setInitialState] = useState([""]);

  useEffect(() => {
    dispatch(corporateActions.saveUploadFile([]));
    dispatch(corporateActions.setFileUploadData([]));
  }, []);

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const sampleFiles = {
    iphone:
      "https://common-bucket-catchu.s3.amazonaws.com/sample_import_files/IPHONE+IMPORT+(3).csv",
    Corporate:
      "https://common-bucket-catchu.s3.amazonaws.com/sample_import_files/COROPRATE+IMPORT+UPDATEDd.csv",
    Community:
      "https://common-bucket-catchu.s3.amazonaws.com/sample_import_files/IMPORT+COMMUNITY+SIDE+BAR+Up.csv",
    community:
      "https://common-bucket-catchu.s3.amazonaws.com/sample_import_files/IMPORT+COMMUNITY+UNDER+CORPORATE.csv", // for corporate sub branch, to be optimised later
    user:
      type === "Corporate"
        ? 
        "https://common-bucket-catchu.s3.amazonaws.com/sample_import_files/USER+IMPORT+UNDER+CORPORATE.csv"
        : "https://common-bucket-catchu.s3.amazonaws.com/sample_import_files/USER+IMPORT+UNDER+COMMUNITY.csv",
    AdminUser:
      "https://catchu-bucket.s3.amazonaws.com/sample_import_files/CATCHU+ADMIN+IMPORT.csv",
    RegUser:
      "https://common-bucket-catchu.s3.amazonaws.com/sample_import_files/REGULAR+USER+IMPORT+UPDATED.csv",
    patient:
      "https://common-bucket-catchu.s3.amazonaws.com/sample_import_files/PATIENTS+IMPORT+UNDER+COMMUNITIESs+.csv",
    Patient:
      " https://common-bucket-catchu.s3.amazonaws.com/sample_import_files/PATIENTS+IMPORT+UPDATEDd.csv",
    billee:
      "https://common-bucket-catchu.s3.amazonaws.com/sample_import_files/BILLEE+IMPORT+UNDER+COMMUNITY.csv",
    Provider:"https://common-bucket-catchu.s3.amazonaws.com/sample_import_files/PROVIDERS+IMPORT++UPDATED++.csv",
  };
  const dictTypes = {
    iphone: iphoneModalFields,
    Corporate: corporateModalFields,
    Community: communityModalFields,
    community: corporateCommunityModalFields,
    user:
      type === "Corporate"
        ? userModalFieldsCorporate
        : userModalFieldsCommunity,
    AdminUser: adminUserModalFields,
    RegUser: regularUserModalFields,
    Patient: patientModalFields,
    patient: patientCommunityModalFields,
    billee: billeeModalFields,
    Provider: providerModalFields,
  };
  const optionalFields = {
    iphone: iphoneOptionalFields,
    Corporate: corporateOptionalFields,
    Community: communityOptionalFields,
    community: communityOptionalFields,
    user:
      type === "Corporate"
        ? userOptionalFieldsCorporate
        : userOptionalFieldsCommunity,
    AdminUser: adminUserOptionalFields,
    RegUser: regularUserOptionalFields,
    Patient: patientOptionalFields,
    patient: patientCommunityOptionalFields,
    billee: billeeOptionalFields,
    Provider: providerOptionalFields,
  };
  const urlMapping = {
    iphone: "/test/import_iphone/",
    Corporate: "/corporate/import_corporates/",
    Community: "/community/import_communities/",
    community: "/community/import_communities/", // for corporate sub branch, to be optimised later
    user:
      type === "Corporate"
        ? "/user/import_user_under_corporate/"
        : "/user/import_user/",
    AdminUser: "/user/import_catchu_admin_user/",
    RegUser: "/user/import_user_regular/",
    Patient: "/user/import_patients/",
    patient: "/user/import_community_patients/",
    billee: "/user/import_billees/",
    Provider: "/user/import_providers/",
  };
  const csvNameMapping = {
    iphone: "iphone_csv_file",
    Corporate: "corporate_csv_file",
    Community: "community_csv_file",
    community: "community_csv_file", // for corporate sub branch, to be optimised later
    user: "userCsvFile",
    AdminUser: "userCsvFile",
    RegUser: "userCsvFile",
    Patient: "patientCSVFile",
    patient: "patientCSVFile",
    billee: "billeeCSVFile",
    Provider: "providersCsvFile",
  };

 
  const saveFile = () => {
    let datatypecheck = !dataType ? type : dataType;
    let param = datatypecheck === "provider"? "Provider": datatypecheck
    saveAs(sampleFiles[param], "sample-file.csv");
  };
  const onHandleChange = (position, e) => {
    const { name, value, selectedIndex } = e.target;

    let mySelectedIndex = selectedIndex;
    if (initialState[position] === "") {
      mySelectedIndex = selectedIndex - 1;
    }

    const updatedInitialState = initialState.map((item, index) => {
      return index === position ? value : item;
    });

    setInitialState(updatedInitialState);

    let prevState = -1; // for unselect
    let currState = -1; // for select

    if (formData && formData[name] && formData[name] !== "") {
      let formVal = formData[name];
      let prevPpositionIndex = headers.indexOf(formVal);
      dropdownState.map((item, index) => {
        if (index === prevPpositionIndex) {
          prevState = index;
        }
      });
    }

    dropdownState.map((item, index) => {
      if (index === mySelectedIndex) {
        currState = index;
      }
    });

    let items = [...dropdownState];

    if (prevState !== -1) {
      let item1 = items[prevState];
      item1 = false;
      items[prevState] = item1;
    }
    if (currState !== -1) {
      let item2 = items[currState];
      item2 = true;
      items[currState] = item2;
    }

    setDropdownState(items);

    setFormData({ ...formData, [name]: value });
  };

  const corporateModalSubmit = () => {
    setLoading(true);
    const resetErrorState = errorTxt.map(() => {
      return "";
    });
    let items = [...resetErrorState];
    setAddBtnDisabled(true);

    const fileData = new FormData();
    if (corporateFile.length !== 0) {
      fileData.append(csvNameMapping[dataType == 'provider' ? 'Provider' : dataType], corporateFile[0]);
    }

    if (
      type === "Community" &&
      (dataType === "iphone" ||
        dataType === "user" ||
        dataType === "billee" ||
        dataType === "patient")
    ) {
      fileData.append("community_id", communityId);
    }
    // For corporate
    if (
      type === "Corporate" &&
      (dataType === "user" || dataType === "community")
    ) {
      fileData.append("corporate_id", corporateId);
    }

    for (var key in formData) {
      // remove optional fields from here if they are empty
      fileData.append(key, formData[key]);
    }

    axios
      .post(
        `${process.env.REACT_APP_URL_PATH}${urlMapping[dataType == 'provider' ? 'Provider' : dataType]}`,
        fileData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        dispatch(corporateActions.saveUploadFile([]));
        dispatch(corporateActions.setFileUploadData([]));
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
      
      })
      .catch((error) => {
        setLoading(false);
        setAddBtnDisabled(false);
        const resData = error.response.data;
        if (error.response.data.message === "Field Errors") {
          let count = 0;
          for (var key in formData) {
            if (key in error.response.data.data) {
        
              let item = items[count];
              item = "Mapping cannot be empty";
              items[count] = item;
            }
            count = count + 1;
          }

          setErrorTxt(items);
        } else {
          notifyError(error.response.data.message);
        }
      });
  };

  return (
    <div className="filter-modal-wrapper ">
      <Modal
        className="import-modal modal-loader"
        show={true}
        onHide={() => {
          dispatch(corporateActions.saveUploadFile([]));
          dispatch(corporateActions.setFileUploadData([]));
          hideModal();
        }}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
      >
        <Modal.Header className="filter-modal-header">
          <h1 className="modal-header-title">Import</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="filter-modal-body">
          <div className="simple-file-download" onClick={saveFile}>
            <div className="left-side-file">
              <span className="icon-file">
                <img src={FileImage} alt="" />
              </span>
              <div className="content-file">
                <h3 className="file-name">Sample File.csv</h3>
                <p className="file-des">download the sample file</p>
              </div>
            </div>
            <div className="icon-download-file">
              <img src={DownloadFileImage} alt="" />
            </div>
          </div>
          <span className="m-5">
            <UploadFile
              setInitialState={setInitialState}
              setDropdownState={setDropdownState}
              setErrorTxt={setErrorTxt}
            />
          </span>
          <h2 className="import-heading-mapping">Mapping</h2>

          {dictTypes[dataType === 'provider' ? 'Provider' : dataType]?.map(({ name, label }, index) => {
            if (optionalFields[dataType === 'provider' ? 'Provider' : dataType].includes(name)) {
              return (
                <div key={Math.random()} className="field-wrapper">
                  <label htmlFor={label} className="label">
                    {label}
                  </label>
                  <select
                    name={name}
                    id={name}
                    className="custom-select-modal"
                    onChange={(e) => onHandleChange(index, e)}
                    value={initialState[index]}
                  >
                    {initialState[index] === "" && (
                      <option value="">Select your Mapping</option>
                    )}
                    {headers.map((name, index) => {
                      if (name) {
                        return (
                          <option
                            key={Math.random()}
                            id={index}
                            name={name}
                            value={name}
                            disabled={dropdownState[index]}
                          >
                            {name}
                          </option>
                        );
                      }
                    })}
                  </select>
                  {errorTxt[index] && (
                    <p className="error-text">{errorTxt[index]}</p>
                  )}
                </div>
              );
            } else {
              return (
                <div key={Math.random()} className="field-wrapper">
                  <label htmlFor={label} className="label">
                    {label} *
                  </label>
                  <select
                    name={name}
                    id={name}
                    className="custom-select-modal"
                    onChange={(e) => onHandleChange(index, e)}
                    value={initialState[index]}
                  >
                    {initialState[index] === "" && (
                      <option value="">Select your Mapping</option>
                    )}
                    {headers.map((name, index) => {
                      if (name) {
                        return (
                          <option
                            key={Math.random()}
                            id={index}
                            name={name}
                            value={name}
                            disabled={dropdownState[index]}
                          >
                            {name}
                          </option>
                        );
                      }
                    })}
                  </select>
                  {errorTxt[index] && (
                    <p className="error-text">{errorTxt[index]}</p>
                  )}
                </div>
              );
            }
          })}
        </Modal.Body>
        <Modal.Footer className="user-footer-modal">
          <button
            id="modal-close-btn"
            className="btn btn-secondary-fill btn-primary-16"
            onClick={() => {
              dispatch(corporateActions.saveUploadFile([]));
              dispatch(corporateActions.setFileUploadData([]));
              hideModal();
            }}
          >
            Close
          </button>
          <button
            className="btn btn-primary-fill btn-primary-16"
            onClick={corporateModalSubmit}
            disabled={addBtnDisabled}
          >
            Add
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
};

export default ImportModal;
