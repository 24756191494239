import React, { useState, useEffect, Fragment } from "react";
import { Modal } from "react-bootstrap";
import "./GroupTestHistoryModal.scss";
import TableComponent from "../../Table/TableData";
import CloseModal from "../../../../assets/close-outline.svg";
import { Link } from "react-router-dom";
import Filter_Icon from "../../../../assets/filter-icon.svg";
import axios from "axios";
import { getWithExpiry } from "../../../utils/helper";
import Loader2 from "../../../UI/loader2/loader2";
import { filterActions } from "../../../../store/filter";
import {
  removeEmptyParams,
  setOrderAndIcon,
} from "../../../../services/helpers/sorting.hepler";
import SearchInput from "../../../General/filters/SearchInput.Component";
import { useSelector, useDispatch } from "react-redux";
import ApplyTestCredit from "./ApplyTestCredit";
import { rowOrderActions } from "../../../../store/orders";
import { billingActions } from "../../../../store/billing";
import InvoiceDetailPagination from "../../../UI/Pagination/InvoiceDetailPagination";
import ApplyTestIcon from "../../../../assets/Apply.svg";
import { formatCurrency } from "../../../../utils/helper";
import InvoiceDetail from "./GroupInvoiceDetail";
function GroupTestHistoryModalBilling(props) {
  const {
    isShowModal,
    hideModal,
    type,
    setDataFlag,
    setShowToast,
    selectedTest,
    isEdit,
    communityInfo,
    groupTestId,
  } = props;

  const [testID, setTestID] = useState("");
  const [creditType, setCreditType] = useState("");
  const [isShowInvoice, setShowInvoice] = useState("")
  const [applyCreditModalOpen, setApplyCreditModalOpen] = useState(false);
  const showApplyCreditModal = (testId) => {
    setTestID(testId);
    setCreditType("test");
    setApplyCreditModalOpen(true);
  };
  const hideApplyCreditModal = () => setApplyCreditModalOpen(false);
  const tableHeadings = [
    "Test ID",
    "Patient Name",
    "Date of Birth",
    "Test Date",
    // "Test Location",
    // "Remote",
    "Credit Amount",
    "Credit Reason",
    "Actions",
  ];

  // apiPath = `community/group_test_history/community_id?page=PAGE_NO`

  const [groupTestHistoryDetail, setGroupTestHistoryDetail] = useState([]);
  const [invoiceTests, setInvoiceTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [otherLoader, setOtherLoader] = useState(false);
  const dispatch = useDispatch();

  const pastInvoiceHistoryPageNumber = useSelector(
    (state) => state.billing.pastInvoiceHistoryPageNumber
  );
  const pastInvoiceHistoryCurrentPage = useSelector(
    (state) => state.billing.pastInvoiceHistoryCurrentPage
  );
  const pastInvoiceHistorytotalRecords = useSelector(
    (state) => state.billing.pastInvoiceHistorytotalRecords
  );

  const [orderState, setOrderState] = useState(null);
  const [orderState2, setOrderState2] = useState(null);

  // const [showToast, setShowToast] = useState(false);

  const token = "Bearer " + getWithExpiry("expiry_token");

  const [orderQuery, setOrderQuery] = useState("");
  const [headingName, setHeadingName] = useState("");
  const [search, setSearch] = useState(false);
  const [apiUrl, setApiUrl] = useState("");
  const handleSearchChange = (e) => {
    const { value, name } = e.target;
    dispatch(filterActions.setSearchPastInvoicesTestHistoryText(value));
  };

  useEffect(() => {
    // if (!showToast) {
    //    setLoading(true);
    // }
    let query = apiUrl ? apiUrl + "&" : "?";
    const newUrl = `${process.env.REACT_APP_URL_PATH}/billing/get_invoice_details/${groupTestId}/${query}page=${pastInvoiceHistoryCurrentPage}`;
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/billing/get_invoice_details/${groupTestId}${query}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        setLoading(false);
        const resData = response.data.data;
        setInvoiceTests(resData.tests);


        dispatch(
          billingActions.setPastInvoiceHistoryPageNumber(
            resData.invoice_detail_pages
          )
        );
        dispatch(
          billingActions.setBillingPastInvoiceDetail(resData)
        )
        dispatch(
          billingActions.setPastInvoiceHistorytotalRecords(
            resData.invoice_detail_total_records
          )
        );

        if (pastInvoiceHistoryCurrentPage > resData.invoice_detail_pages) {
          dispatch(
            billingActions.setPastInvoiceHistoryCurrentPage(
              resData.invoice_detail_pages
            )
          );
        }

        setOtherLoader(false);
      })
      .catch((error) => {
        if (error.response.data.message === "Please select valid Community") {
          // setPageExist(false);
        }
        setLoading(false);
        setOtherLoader(false);
      });
  }, [orderState]);

  const pastInvoicesTestHistoryOrderMapping = {
    "Test ID": "test_id",
    "Patient Name": "patient_name",
    "Test Date": "test_date",
    "Date of Birth": "date_of_birth",
    "Test Location": "location",
    "Remote": "remote",
    "Credit Amount": "credit_amount",
    "Credit Reason": "amount_reason",
  };

  const searchPastInvoicesTestHistoryText = useSelector(
    (state) => state.filter.searchPastInvoicesTestHistoryText
  );

  const rowOrderStatePastInvoicesHistory = useSelector(
    (state) => state.rowOrder.rowOrderPastInvoicesHistory
  );

  useEffect(() => {
    updateUrl();
  }, [orderState2]);

  const buildRowOrderQuery = () => {
    let newOrderQuery = orderQuery.slice();

    let mappingHeaderName = pastInvoicesTestHistoryOrderMapping[headingName];
    let rowOrderHeaderName =
      rowOrderStatePastInvoicesHistory[
      pastInvoicesTestHistoryOrderMapping[headingName]
      ];

    if (mappingHeaderName) {
      let prevOrder = rowOrderHeaderName.order === "asc" ? "desc" : "asc";
      let prevInstance = `&${mappingHeaderName}:orderby=${prevOrder}`;
      if (newOrderQuery.includes(prevInstance)) {
        newOrderQuery = newOrderQuery.replace(prevInstance, "");
      }
      if (!search) {
        newOrderQuery = `&${mappingHeaderName}:orderby=${rowOrderHeaderName.order}`;
      }
    }
    setOrderQuery(newOrderQuery);
    return newOrderQuery;
  };

  const buildFilterQuery = () => {
    let filterQuery = "";

    return filterQuery;
  };

  const buildSearchTextQuery = () => {
    let searchQuery = "";
    searchQuery =
      searchPastInvoicesTestHistoryText !== ""
        ? `search_query=${searchPastInvoicesTestHistoryText}`
        : "";
    return searchQuery;
  };

  const updateUrl = () => {
    const searchQuery = buildSearchTextQuery();
    const newOrderQuery = buildRowOrderQuery();
    const filterQuery = buildFilterQuery();

    let customQuery = "";

    customQuery = `${searchQuery}${newOrderQuery}&${filterQuery}`;

    let modifiedString = removeEmptyParams(customQuery);

    let str =
      modifiedString && modifiedString !== "" ? "?" + modifiedString : "";
    setApiUrl(str);
    setOrderState({
      ...orderState,
    });
  };

  const onClickTableHeading = async (e) => {
    const { id } = e.target;
    if (id === "Actions") return;
    setSearch(false);
    setOtherLoader(true);
    setHeadingName(id);
    let name = "";
    let obj;
    switch (id) {
      case "Test ID":
        name = "test_id";
        obj = setOrderAndIcon(rowOrderStatePastInvoicesHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderPastInvoicesHistory({
            ...rowOrderStatePastInvoicesHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            patient_name: { state: 1, order: "", icon: "" },
            test_date: { state: 1, order: "", icon: "" },
            date_of_birth: { state: 1, order: "", icon: "" },
            location: { state: 1, order: "", icon: "" },
            remote: { state: 1, order: "", icon: "" },
            credit_amount: { state: 1, order: "", icon: "" },
            amount_reason: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      case "Patient Name":
        name = "patient_name";
        obj = setOrderAndIcon(rowOrderStatePastInvoicesHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderPastInvoicesHistory({
            ...rowOrderStatePastInvoicesHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            test_id: { state: 1, order: "", icon: "" },
            test_date: { state: 1, order: "", icon: "" },
            date_of_birth: { state: 1, order: "", icon: "" },
            location: { state: 1, order: "", icon: "" },
            remote: { state: 1, order: "", icon: "" },
            credit_amount: { state: 1, order: "", icon: "" },
            amount_reason: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      case "Test Date":
        name = "test_date";
        obj = setOrderAndIcon(rowOrderStatePastInvoicesHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderPastInvoicesHistory({
            ...rowOrderStatePastInvoicesHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            patient_name: { state: 1, order: "", icon: "" },
            test_id: { state: 1, order: "", icon: "" },
            date_of_birth: { state: 1, order: "", icon: "" },
            location: { state: 1, order: "", icon: "" },
            remote: { state: 1, order: "", icon: "" },
            credit_amount: { state: 1, order: "", icon: "" },
            amount_reason: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      case "Date of Birth":
        name = "date_of_birth";
        obj = setOrderAndIcon(rowOrderStatePastInvoicesHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderPastInvoicesHistory({
            ...rowOrderStatePastInvoicesHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            patient_name: { state: 1, order: "", icon: "" },
            test_date: { state: 1, order: "", icon: "" },
            test_id: { state: 1, order: "", icon: "" },
            location: { state: 1, order: "", icon: "" },
            remote: { state: 1, order: "", icon: "" },
            credit_amount: { state: 1, order: "", icon: "" },
            amount_reason: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      case "Test Location":
        name = "location";
        obj = setOrderAndIcon(rowOrderStatePastInvoicesHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderPastInvoicesHistory({
            ...rowOrderStatePastInvoicesHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            patient_name: { state: 1, order: "", icon: "" },
            test_date: { state: 1, order: "", icon: "" },
            date_of_birth: { state: 1, order: "", icon: "" },
            test_id: { state: 1, order: "", icon: "" },
            remote: { state: 1, order: "", icon: "" },
            credit_amount: { state: 1, order: "", icon: "" },
            amount_reason: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      case "Remote":
        name = "remote";
        obj = setOrderAndIcon(rowOrderStatePastInvoicesHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderPastInvoicesHistory({
            ...rowOrderStatePastInvoicesHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            patient_name: { state: 1, order: "", icon: "" },
            test_date: { state: 1, order: "", icon: "" },
            date_of_birth: { state: 1, order: "", icon: "" },
            location: { state: 1, order: "", icon: "" },
            test_id: { state: 1, order: "", icon: "" },
            credit_amount: { state: 1, order: "", icon: "" },
            amount_reason: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      case "Credit Amount":
        name = "credit_amount";
        obj = setOrderAndIcon(rowOrderStatePastInvoicesHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderPastInvoicesHistory({
            ...rowOrderStatePastInvoicesHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            patient_name: { state: 1, order: "", icon: "" },
            test_date: { state: 1, order: "", icon: "" },
            date_of_birth: { state: 1, order: "", icon: "" },
            location: { state: 1, order: "", icon: "" },
            remote: { state: 1, order: "", icon: "" },
            test_id: { state: 1, order: "", icon: "" },
            amount_reason: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      case "Credit Reason":
        name = "amount_reason";
        obj = setOrderAndIcon(rowOrderStatePastInvoicesHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderPastInvoicesHistory({
            ...rowOrderStatePastInvoicesHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            patient_name: { state: 1, order: "", icon: "" },
            test_date: { state: 1, order: "", icon: "" },
            date_of_birth: { state: 1, order: "", icon: "" },
            location: { state: 1, order: "", icon: "" },
            remote: { state: 1, order: "", icon: "" },
            credit_amount: { state: 1, order: "", icon: "" },
            test_id: { state: 1, order: "", icon: "" },
          })
        );
        setOrderState2({
          ...orderState2,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });

        return;

      default:
        return;
    }
  };
  const onClearSorting = () => {
    setOtherLoader(true);
    dispatch(
      rowOrderActions.setRowOrderPastInvoicesHistory({
        ...rowOrderStatePastInvoicesHistory,
        test_id: { state: 1, order: "", icon: "" },
        patient_name: { state: 1, order: "", icon: "" },
        test_date: { state: 1, order: "", icon: "" },
        date_of_birth: { state: 1, order: "", icon: "" },
        location: { state: 1, order: "", icon: "" },
        remote: { state: 1, order: "", icon: "" },
        credit_amount: { state: 1, order: "", icon: "" },
        amount_reason: { state: 1, order: "", icon: "" },
      })
    );
    setOrderQuery("");
    setHeadingName("");
    setOrderState2({
      ...orderState2,
    });
  };
  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      setSearch(true);
      setOtherLoader(true);

      updateUrl();
    }
  };
  const onClearSearch = () => {
    setOtherLoader(true);
    dispatch(filterActions.setSearchPastInvoicesTestHistoryText(""));
    setSearch(true);
    setOrderState2({
      ...orderState2,
    });
  };

  const applyAllCredit = () => {
    setTestID(groupTestId);
    setCreditType("invoice");
    setApplyCreditModalOpen(true);
  };

  const showInvoice = () => {
    setShowInvoice(!isShowInvoice)
  }
  return (
    <Fragment>
      {applyCreditModalOpen && (
        <ApplyTestCredit
          isShowModal={applyCreditModalOpen}
          hideModal={hideApplyCreditModal}
          testID={testID}
          type={creditType}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      <div className="action-modal-wrapper">
        <Modal
          className="groupTest-modal audit-modal"
          show={isShowModal}
          size="lg"
          onHide={hideModal}
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
          scrollable
        >
          <Modal.Header className="audit-modal-header">
            <h1 className="modal-header-title"></h1>
            <span
              className="close-icon-modal "
              onClick={() => {
                dispatch(
                  rowOrderActions.setRowOrderPastInvoicesHistory({
                    ...rowOrderStatePastInvoicesHistory,
                    test_id: { state: 1, order: "", icon: "" },
                    patient_name: { state: 1, order: "", icon: "" },
                    test_date: { state: 1, order: "", icon: "" },
                    date_of_birth: { state: 1, order: "", icon: "" },
                    location: { state: 1, order: "", icon: "" },
                    remote: { state: 1, order: "", icon: "" },
                    credit_amount: { state: 1, order: "", icon: "" },
                    amount_reason: { state: 1, order: "", icon: "" },
                  })
                );
                hideModal();
              }}
            >
              <img src={CloseModal} alt="" />
            </span>
          </Modal.Header>

          <Modal.Body className="filter-modal-body">
            <div className="cor-header">
              <h1 className="page-name-heading">Invoice Details</h1>

              <div className="right-cor-header">
                <ul className="rc-header-listing">
                  {/* <li className="single-rc">
                  <button onClick={onClearSorting}>Clear Sorting</button>
                </li> */}
                  {/* <li className="single-rc">
                  <button onClick={onClearSearch}>Clear Search</button>
                </li> */}



                  <li className="single-rc">

                    <button
                      onClick={applyAllCredit}
                      className="btn btn-primary-fill btn-primary-16"
                    >
                      Apply Credit
                    </button>
                  </li>

                  <li className="single-rc">
                    <SearchInput
                      value={searchPastInvoicesTestHistoryText}
                      handleChange={handleSearchChange}
                      onKeyEnter={onEnterPress}
                      onClearSearch={onClearSearch}
                    />
                  </li>

                </ul>
              </div>
            </div>
            {!isShowInvoice ? <>
              <div className="table-responsive">
                <table className="table" id="en-width">
                  <thead>
                    <tr>
                      {tableHeadings &&
                        tableHeadings.map((tableHead) => (
                          <th
                            id={tableHead}
                            name={tableHead}
                            key={tableHead}
                            onClick={onClickTableHeading}
                          >
                            {tableHead}
                            {tableHead !== "Actions" && (
                              <>
                                {rowOrderStatePastInvoicesHistory[
                                  pastInvoicesTestHistoryOrderMapping[tableHead]
                                ].icon === "" ? (
                                  <span className="icons-th">
                                    <span></span>
                                    <span className="order-4"></span>
                                  </span>
                                ) : (
                                  <span className="icons-th">
                                    <span
                                      className={
                                        rowOrderStatePastInvoicesHistory[
                                          pastInvoicesTestHistoryOrderMapping[
                                          tableHead
                                          ]
                                        ].icon === "asc"
                                          ? "caret-4-asc-icon"
                                          : "caret-4-desc-icon"
                                      }
                                    ></span>
                                    <span className="order-4"></span>
                                  </span>
                                )}
                              </>
                            )}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceTests && invoiceTests.length === 0 && (
                      <tr>
                        <td colSpan="10" className="text-center">
                          No data available in table
                        </td>
                      </tr>
                    )}
                    {invoiceTests &&
                      invoiceTests.map((record) => (
                        <tr key={record.id}>
                          <td><Link to={`/edit/patients/${record?.patient_user_role_id}`}> <u>{record.test_id ? record.test_id : "N/A"} </u> </Link></td>
                          <td>{record.patient_name}</td>
                          <td>{record.date_of_birth}</td>
                          <td>{record.test_date}</td>
                          {/* <td>{record.location ? record.location : "N/A"}</td>
                        <td>
                          <span className="tag zip-tag">
                            {record.remote ? "Yes" : "No"}
                          </span>
                        </td> */}

                          <td> {record.credit_amount === "N/A" || !record.credit_amount || typeof (parseInt(record.credit_amount)) !== "number" ? "N/A" : `$${formatCurrency(record.credit_amount)}`}</td>

                          <td>
                            {record.amount_reason ? record.amount_reason : "N/A"}
                          </td>
                          <td>
                            {/* <span className="text-tag">
                          </span> */}
                            {/* <span
                            className={"resend-tag"}
                            onClick={() => showApplyCreditModal(record.id)}
                          >
                            Apply Test Credit
                          </span> */}
                            <span
                              title="Apply Test Credit"
                              className="td-action"
                              onClick={() => showApplyCreditModal(record.id)}
                            >
                              <img src={ApplyTestIcon} alt="" />
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <InvoiceDetailPagination
                data={invoiceTests}
                totalRecords={pastInvoiceHistorytotalRecords}
                type={type}
                groupTestId={groupTestId}
                setInvoiceTests={setInvoiceTests}
                LogsApiUrl={apiUrl}
                setOtherLoader={setOtherLoader}
              />
            </>
              : <InvoiceDetail />}
          </Modal.Body>
          {(loading || otherLoader) && <Loader2 />}
        </Modal>
      </div>
    </Fragment>
  );
}

export default GroupTestHistoryModalBilling;
