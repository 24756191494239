import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { patientActions } from "../../store/patient";
import { sidebarActions } from "../../store/sidebar";
import TableComponent from "../UI/Table/TableData";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getWithExpiry } from "../utils/helper";
import "./Patient.scss";
import Loader from "../UI/loader/loader";
import useQueryString from "../../hooks/useQueryString";
function Patients() {
  const tableHeadings = [
    "First Name",
    "Last Name",
    "Community",
    "Date of Birth",
    "Email",
    "Telephone",
    "Status",
  ];

  const dispatch = useDispatch();
  const urlParams = useQueryString();
  const token = "Bearer " + getWithExpiry("expiry_token");
  const patients = useSelector((state) => state.patient.patients);
  const totalRecords = useSelector(
    (state) => state.patient.patientTotalRecords
  );
  const PatientPageNumber = useSelector(
    (state) => state.patient.patientCurrentPage
  );
  const crumbs = useSelector((state) => state.sidebar.crumbs);

  const [loading, setLoading] = useState(true);
  const [otherLoader, setOtherLoader] = useState(false);
  const [dataFlag, setDataFlag] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const patientApiUrl = useSelector((state) => state.patient.patientApiUrl);
  useEffect(() => {
    if (!showToast) {
      setLoading(true);
      dispatch(sidebarActions.setSelectedNav("Patients"));
    }
    let urlKeys = [...urlParams.keys()];
    let urlValues = [...urlParams.values()];
    let query;
    if (!patientApiUrl && urlKeys.length > 0) {
      query = "?";
      for (let i = 0; i < urlKeys.length; i++) {
        if (urlKeys[i] !== "page") {
          query = query + urlKeys[i] + "=" + urlValues[i] + "&";
        }
      }
    } else {
      query = patientApiUrl ? patientApiUrl + "&" : "?";
    }

    let pageNumber = urlParams.has("page")
      ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
        ? urlParams.get("page")
        : PatientPageNumber
      : PatientPageNumber;

    const Url = `${process.env.REACT_APP_URL_PATH}/community/patient_list/${query}page=${pageNumber}`;

    query = query.replace("&&", "&");
    axios({
      url: `${process.env.REACT_APP_URL_PATH}/community/patient_list/${query}page=${pageNumber}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        const Patients = response.data.data["patient_list"];
        const communities = response.data.data["communities"];

        dispatch(patientActions.addPatients(Patients));
        dispatch(
          patientActions.setPatientsPageNumber(response.data.data.patient_pages)
        );
        dispatch(
          patientActions.setPatientTotalRecords(
            response.data.data.total_records
          )
        );
        dispatch(patientActions.setCommunityList(communities));
        if (PatientPageNumber > response.data.data.patient_pages) {
          dispatch(
            patientActions.setPatientCurrentPage(
              response.data.data.patient_pages
            )
          );
        }
        setLoading(false);
        setOtherLoader(false);
      })
      .catch((error) => {
        setLoading(false);
        setOtherLoader(false);
      });
  }, [dataFlag]);

  return (
    <Fragment>
      <div className="corporate-wrapper">
        {loading ? (
          <Loader />
        ) : (
          <>
            <TableComponent
              tableHeadings={tableHeadings}
              data={patients}
              totalRecords={totalRecords}
              heading="Patients"
              type="Patient"
              isAdd={true}
              isImport={true}
              setDataFlag={setDataFlag}
              setShowToast={setShowToast}
              otherLoader={otherLoader}
              setOtherLoader={setOtherLoader}
            />
            {/* <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            /> */}
          </>
        )}
      </div>
    </Fragment>
  );
}

export default Patients;
