import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CloseModal from "../../assets/close-outline.svg";
import "./tos.scss";
function Tos() {
  const docs = [
    // { uri: require("./tos.docx") },
    { uri: require("./tos.docx") },
  ];
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="TOS-wrapper">
      {/* <button className="btn btn-primary-fill btn-14-24" onClick={handleShow}>
        AuditLog Modal
      </button> */}
      <iframe
        className="iframe-doc"
        style={{ width: "100%", height: "100vh" }}
        src="https://docs.google.com/document/d/e/2PACX-1vS9FKKjoc8eSm1Ijp55iSxsBaAG-ItNGFGmcXmPIlzBO9zFbjP7p9sfxzdrvZuWo_EK2qODbisl3oG3/pub?embedded=true"
      ></iframe>
      {/* <iframe
        style={{ width: "100%", height: "100vh" }}
        src="https://docs.google.com/document/d/12TY7t6VCezaQ7-SyXgt97CZ6m3NLULq6/edit?usp=sharing&ouid=117799917260915135421&rtpof=true&sd=true"
      ></iframe> */}
      {/* <iframe src={TOSFile}></iframe> */}
      {/* <DocViewer
        width="100%"
        height="600px"
        pluginRenderers={DocViewerRenderers}
        documents={docs}
      /> */}

      {/* <Modal
        className="test-modal"
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header className="audit-modal-header">
          <h1 className="modal-header-title">Terms and Conditions</h1>
          <span className="close-icon-modal " onClick={handleClose}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="filter-modal-body">
          <iframe
            style={{ width: "100%", height: "100vh" }}
            src="https://docs.google.com/document/d/e/2PACX-1vS9FKKjoc8eSm1Ijp55iSxsBaAG-ItNGFGmcXmPIlzBO9zFbjP7p9sfxzdrvZuWo_EK2qODbisl3oG3/pub?embedded=true"
          ></iframe>
        </Modal.Body>
      </Modal> */}

      {/* <h1>Terms of Services</h1>
      <p>
        SOFTWARE SERVICES AGREEMENT PLEASE READ THIS AGREEMENT CAREFULLY BEFORE
        USING THIS SERVICE. BY USING THE SERVICE, CLICKING AGREE OR OTHER FORM
        OF ASSENT, YOU ARE AGREEING TO BE BOUND BY THIS AGREEMENT. IF YOU ARE
        AGREEING TO THIS AGREEMENT ON BEHALF OF OR FOR THE BENEFIT OF YOUR
        EMPLOYER OR A THIRD PARTY, THEN YOU REPRESENT AND WARRANT THAT YOU HAVE
        THE NECESSARY AUTHORITY TO AGREE TO THIS AGREEMENT ON THEIR BEHALF.
        REVIEW SECTION 9, AS IT REQUIRES MANDATORY ARBITRATION AND CONTAINS A
        CLASS ACTION WAIVER. This agreement is between JET Worldwide Enterprises
        Inc., a Delaware corporation (“JET”), and the User agreeing to these
        terms (“Customer”). 1. SOFTWARE SERVICE. This agreement provides
        Customer access to and usage of CatchU, an Internet-based software
        service designed to administer and calculate multisensory integration
        effects (“Tests”) in order to objectively assess fall risk in adults who
        have a patient or client relationship with Customer (“Users”). CatchU
        includes a mobile software/app on which the Test is taken by a User and
        also generates Test results and billing assistance for Tests
        (“Service”). 2. USE OF SERVICE. Customer Owned Data. All data uploaded
        by Customer or User invited by Customer to the Service remains the
        property of Customer, as between JET and Customer (“Customer Data”).
        Customer grants JET the right to use the Customer Data solely for
        purposes of performing under this agreement and as permitted in this
        agreement. During the term of this agreement, Customer may export its
        Customer Data to the extent permitted by functionality within the
        Service. Further Customer grants JET the option of using deidentified
        data for the purposes of product enhancement, research and/or
        publications. Access and Usage. Customer may allow its contractors to
        access the Service in compliance with the terms of this agreement, which
        access must be for the sole benefit of Customer. Customer is responsible
        for the compliance with this agreement by its employees and contractors.
        General Customer Responsibilities. Customer: (i) must keep its passwords
        secure and confidential; (ii) is solely responsible for Customer and
        User Data and all activity in its account in the Service; (iii) must use
        reasonable efforts to prevent unauthorized access to its account and
        notify JET promptly of any such unauthorized access; and (iv) may use
        the Service only in accordance with the Service's online knowledge base
        and applicable law. Customer Responsibilities with Regard to Users.
        Customer understands the following and agrees that it is Customer’s
        responsibility to make Users (its patients and/or clients) aware of the
        following: CatchU is a screening test for a User’s risk of falls but a
        low probability score does not guarantee that a User will not fall.
        CatchU is NOT an Emergency Service and in the event of an emergency,
        patients and clients must use a phone to call 911 or Customer or another
        healthcare provider. Though Users may be in direct, virtual contact with
        JET through the Service, neither JET nor the Service provides any
        medical or healthcare services or advice including, but not limited to,
        emergency or urgent medical services. Users are responsible for the
        security of their communications that are within their control,
        including, without limitation secure internet connection, creating
        strong passwords, and safeguarding such passwords. Technical Support.
        JET must provide Customer support for the Service under the terms of
        JET's Customer Support Policy (Support), which is located at
        support@catchu.net. Third Party Services. The Service interoperates with
        one or more third party services and depends on the continuing
        availability and access to such third-party service and any data or
        information interfaces (“Third Party Services”). If for any reason JET
        cannot access or use the applicable Third Party Service (including
        without limitation, change in terms or increase in fees charged by a
        third-party service provider), JET may not be able to provide all of the
        functions of its Service. No refund or credit will be provided for
        unavailability of any Third Party Services. Customer will only be
        charged for reports generated as a result of a User participating in a
        Test. 3. WARRANTY. Availability. JET will make commercially reasonable
        efforts to maintain the availability of the Service, excluding any
        scheduled downtime, events beyond the reasonable control of JET and
        Customer or User technical issues. The features and functions of the
        Service may change but will not materially degrade during a paid term of
        an order. Mutual Compliance with Laws. Each party represents and
        warrants to the other party that it will comply with all applicable laws
        regarding its performance under this agreement. Accuracy of Information
        Provided. Customer represents and warrants to JET that all Customer Data
        and other material provided under Customer’s account, by Customer or on
        its behalf by a contractor or User, is true, correct and accurate. If
        Customer learns that any Customer or User Data provided to JET as part
        of the Service is not true, correct or accurate, Customer must
        immediately notify JET of this fact, and provide the true, correct and
        accurate information. JET relies on Customer and User representations
        regarding the truth, accuracy and compliance with laws of Customer Data.
        No Medical Advice Provided By JET. The Service is solely providing a
        technology platform and a wellness device to calculate an objective fall
        risk assessment and to provide suggestions to the referring medical
        practitioner for counseling of the User. The Service does not provide
        medical advice, provide medical or diagnostic services, obtain User’s
        informed consent or authorization to use the Service, or prescribe
        medication. Use of the Service is not a substitute for the professional
        judgment of health care providers in diagnosing and treating
        patients/Users or determining safety precautions. Customer agrees that
        it is solely responsible for verifying the accuracy of User information,
        obtaining User’s informed consent to use the Service and for all of its
        decisions or actions with respect to the medical care, treatment, fall
        risk categorization and well-being of Users, including without
        limitation, all of Customer’s acts or omissions. Any use or reliance by
        Customer upon the Service will not diminish that responsibility.
        Customer assumes all risks associated with Customer’s clinical use of
        the Service for the treatment of patients/Users, development of care
        plans or recommendation of activity levels or safety precautions for
        Users. JET does not assume any liability or responsibility for damage or
        injury (including death) to Customer, a User (patient or client of
        Customer) other persons, or tangible property arising from any use of
        the Service. Indemnification. Customer indemnifies and holds JET and its
        affiliates, directors, officers, employees, agents, representatives,
        successors and assigns, from and against any loss, cost, damage or
        expense, including reasonable attorneys’ fees and court costs, arising
        out of or in connection with its or its employees, contractors or Users’
        use of the Service. Customer’s Compliance With Medical Retention Laws,
        Patient Records Access, Privacy Laws, and Third Party Payor Laws.
        Customer is responsible for understanding and complying with all state
        and federal laws related to retention of medical records, User access to
        information, and User authorization to release data. Customer agrees
        that it is solely responsible for informing patients/Users of the risks
        of using the Service and obtaining any consent or authorization from
        patient/User deemed necessary under state or federal privacy laws prior
        to using the Service or giving patient/User information to JET. Customer
        further understands that use of the Service does not guarantee payment
        from any third-party payor and agrees that Customer is solely
        responsible for compliance with any laws, policies, rules or regulations
        required by any such third-party payors. OTHER THAN THE ABOVE WARRANTY,
        THE SERVICE IS PROVIDED ‘AS IS' WITHOUT WARRANTY. JET DISCLAIMS ALL
        WARRANTIES, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, TITLE, AND FITNESS FOR A PARTICULAR PURPOSE. WHILE JET
        TAKES REASONABLE PHYSICAL, TECHNICAL, AND ADMINISTRATIVE MEASURES TO
        SECURE THE SERVICE, JET DOES NOT GUARANTEE THAT THE SERVICE CANNOT BE
        COMPROMISED. CUSTOMER UNDERSTANDS THAT THE SERVICE MAY NOT BE ERROR
        FREE, AND USE MAY BE INTERRUPTED. 4. PAYMENT. General. Customer must pay
        all fees as specified on an order for the Service. Customer is
        responsible for the payment of all sales, use, withholding, VAT, and
        other similar taxes. Invoices will be delivered to Customer through the
        Service or email provided by Customer and will be due sixty (60) days
        from the date the Invoice was issued by Customer. If Customer does not
        make payment by the sixty (60) day deadline, Customer will be
        responsible for a 1.5% per month late fee. Payments are to be made by
        ACH or wire transfer, complying with information presented on each
        invoice. CUSTOMER IS RESPONSIBLE FOR THE TIMELY PAYMENT OF ALL FEES. For
        questions on billing or payments, please access www.catchu.net/FAQ. JET
        reports provided to Customer will be identified by Customer and will
        contain PII including health data and counseling suggestions specific to
        an individual customer. That report will be retained in JET databases.
        Any use made by JET for product enhancement and/or research will utilize
        deidentified data. 5. MUTUAL CONFIDENTIALITY. Definition of Confidential
        Information. Confidential Information means all non-public information
        disclosed by a party (“Discloser”) to the other party (“Recipient”),
        whether orally, visually, or in writing, that is designated as
        confidential or that reasonably should be understood to be confidential
        given the nature of the information and the circumstances of disclosure
        (“Confidential Information”). JET's Confidential Information includes,
        without limitation, the Service, its user interface design and layout,
        its pricing information, and its Software and Documentation (defined
        below). Customer's Confidential Information includes, without
        limitation, the Customer Data. Protection of Confidential Information.
        The Recipient must use the same degree of care that it uses to protect
        the confidentiality of its own confidential information (but in no event
        less than reasonable care or standards required by applicable laws), and
        it may not disclose or use any Confidential Information of the Discloser
        for any purpose outside the scope of this agreement. The Recipient must
        make commercially reasonable efforts to limit access to Confidential
        Information of Discloser to those of its employees and contractors who
        need such access for purposes consistent with this agreement and who
        have signed confidentiality agreements with Recipient no less
        restrictive than the confidentiality terms of this agreement.
        Exclusions. Confidential Information excludes information that: (i) is
        or becomes generally known to the public without breach of any
        obligation owed to Discloser; (ii) was known to the Recipient on a
        non-confidential basis prior to its disclosure by the Discloser without
        breach of any obligation owed to the Discloser; (iii) is received from a
        third party on a non-confidential basis without breach of any obligation
        owed to Discloser; or (iv) was independently developed by the Recipient
        without use or access to the Confidential Information. The Recipient may
        disclose Confidential Information to the extent required by law or court
        order, but will provide Discloser with advance notice to seek a
        protective order. 6. DATA SECURITY MEASURES Security Measures. In order
        to protect Customer's Confidential Information, JET: (i) implements and
        maintains reasonable security measures appropriate to the nature of the
        Confidential Information including, without limitation, technical,
        physical, administrative, and organizational controls, and will maintain
        the confidentiality, security, and integrity of such Confidential
        Information; (ii) implements and maintains industry standard systems and
        procedures for detecting, preventing, and responding to attacks,
        intrusions, or other systems failures and regularly tests, or otherwise
        monitors the effectiveness of the safeguards’ key controls, systems, and
        procedures; (iii) designates an employee or employees to coordinate
        implementation and maintenance of its Security Measures (as defined
        below); and (iv) identifies reasonably foreseeable internal and external
        risks to the security, confidentiality, and integrity of Customer's
        Confidential Information that could result in the unauthorized
        disclosure, misuse, alteration, destruction, or other compromise of such
        information, and assesses the sufficiency of any safeguards in place to
        control these risks (collectively, “Security Measures”). Notice of Data
        Breach. If JET knows that Customer Confidential Information has been
        accessed, disclosed, or acquired without proper authorization and
        contrary to the terms of this agreement, JET will promptly alert
        Customer of any such data breach, and immediately take such actions as
        may be necessary to preserve forensic evidence and eliminate the cause
        of the data breach. JET will give highest priority to immediately
        correcting any data breach, and will devote such resources as may be
        required to accomplish that goal. JET will provide Customer with all
        available information reasonably necessary to enable Customer to fully
        understand the nature and scope of the data breach. To the extent that
        Customer, in its sole reasonable discretion, deems warranted, Customer
        may provide notice to any or all parties affected by any data breach.
        JET will provide Customer with information about what JET has done or
        plans to do to minimize any harmful effect of the unauthorized use or
        disclosure of, or access to, Confidential Information. Business
        Associate Agreement. If Customer is a Covered Entity as that term is
        defined in the Health Insurance Portability and Accountability Act
        (“HIPAA”) and its implementing regulations, then Customer and JET agree
        to be bound by the terms and provisions of JET’s Business Associate
        Agreement from time to time in effect, which can be found at
        www.catchu.net/BAA and which is incorporated by reference herein. JET
        will provide 30 days’ prior notice to the Customer of any amendment to
        the Business Associate Agreement. Such amendments shall be effective
        when posted to the website. 7. PROPERTY. Reservation of Rights. The
        Service and the Software and Documentation (defined below) are the
        proprietary property of JET and its licensors, and all right, title, and
        interest in and to the Service and the Software and Documentation,
        including all associated intellectual property rights, remain only with
        JET. Customer may not remove or modify any proprietary marking or
        restrictive legends in the Service or Software and Documentation. JET
        reserves all rights unless expressly granted in this agreement.
        Restrictions. Customer may not: (i) sell, resell, rent, or lease the
        Service or use it in a service-provider capacity; (ii) use the Service
        to store or transmit unsolicited marketing emails, libelous, or
        otherwise objectionable, unlawful, or tortious material, or to store or
        transmit infringing material in violation of third-party rights; (iii)
        interfere with or disrupt the integrity or performance of the Service;
        (iv) attempt to gain unauthorized access to the Service or its related
        systems or networks; (v) reverse engineer the Service or the Software
        and Documentation; or (vi) access the Service or use the Software and
        Documentation to build a competitive service or product, or copy any
        feature, function, or graphic for competitive purposes. Software and
        Documentation. All software (including mobile as well as data management
        and report generation )provided by JET as part of the Service
        (“Software”), and the Service documentation, sample data, marketing
        materials, training materials, and other materials provided through the
        Service or by JET (“Documentation”) are licensed to Customer as follows:
        JET grants Customer a non-exclusive, non-transferable license during the
        term of this agreement, to use such Software in accordance with the
        Documentation, solely in connection with the Service. Aggregation
        Services and De-identified Data. JET will use protected health
        information to provide data aggregation services (as that term is
        defined by HIPAA) to Customer and to create de-identified data in
        accordance with 45 CFR 164.514(a)-(c) for Customer. JET and its
        affiliates may use and disclose, during and after this agreement, all
        aggregate, anonymized information and de-identified data for purposes of
        enhancing the Service, technical support and other business purposes,
        including research in compliance with the HIPAA Privacy Standards. 8.
        TERM AND TERMINATION. Term. This agreement continues until all orders
        have expired or are terminated for material breach as provided below.
        Mutual Termination for Material Breach. If either party is in material
        breach of this agreement, the other party may terminate this agreement
        at the end of a written 30-day notice/cure period, if the breach has not
        been cured. Return of Customer Data. Within 60 days after termination,
        upon request JET will make the Service available for Customer to export
        Customer Data as described at www.catchu.net/Data_Export After such
        60-day period, JET has no obligation to maintain the Customer Data and
        may destroy it. Return JET Property Upon Termination. Upon termination
        of this agreement for any reason, Customer must pay JET for any unpaid
        amounts and upon request of JET. destroy or return any property or
        Confidential Information of Jet. Upon Jet's request, Customer will
        confirm in writing its compliance with this destruction or return
        requirement. Suspension. JET may temporarily suspend the Service and/or
        remove the applicable Customer Data if it in good faith believes that,
        as part of using the Service, Customer has violated a law or made any
        misrepresentation as part of using the Service. JET will attempt to
        contact Customer in advance. Suspension for Non-Payment. JET may in its
        sole discretion temporarily suspend the Service if Customer is more than
        30 days late on any payment due pursuant to an order. 9. LIABILITY
        LIMIT. Exclusion of Indirect Damages. To the maximum extent allowed by
        law, JET is not liable for any indirect, special, incidental, or
        consequential damages arising out of or related to this agreement
        (including, without limitation, costs of delay; loss of or unauthorized
        access to data or information; and lost profits, revenue, or anticipated
        cost savings, or injury or death to Users), even if it knows of the
        possibility or foreseeability of such damage or loss. If Customer is a
        California citizen, Customer specifically assumes the above mentioned
        risks and agrees that the limitations of liability in this agreement
        shall apply to all unknown or unanticipated results of the use of the
        Service, as well as those known and anticipated, Customer does hereby
        knowingly waive any and all rights and protections under California
        Civil Code Section 1542, which Customer agrees that he or she
        understands and which reads as follows: “A general release does not
        extend to claims which the creditor does not know or suspect to exist in
        his favor at the time of executing the release, which if known by him
        must have materially affected his settlement with the debtor.” Total
        Limit on Liability. To the maximum extent allowed by law, JET's total
        liability arising out of or related to this agreement (whether in
        contract, tort, or otherwise) does not exceed the amount paid by
        Customer on behalf of the User(s) involved in the liability claim within
        the 12-month period prior to the event that gave rise to the liability.
        10. GOVERNING LAW AND DISPUTES. Governing Law. This agreement is
        governed by the laws of the State of New York (without regard to
        conflicts of law principles) for any dispute between the parties or
        relating in any way to the subject matter of this agreement. General
        Arbitration Process. Any dispute or claim that may arise between the
        parties relating in any way to or arising out of this agreement,
        Customer’s use of or access to the Services (a “Claim”), must be
        resolved exclusively through final and binding arbitration (rather than
        in court) before a single arbitrator with at least ten years’ experience
        in dealing with business disputes under the then current commercial or
        international rules of the American Arbitration Association, and the
        proceeding will be held in the city of New York, New York. Any judgment
        on the award rendered by the arbitrator is final and may be entered in
        any court of competent jurisdiction. Nothing in this agreement prevents
        either party from seeking injunctive or equitable relief in any court of
        competent jurisdiction. The prevailing party in any arbitration or
        litigation is entitled to recover its attorneys’ fees and costs from the
        other party. PROHIBITION OF CLASS AND REPRESENTATIVE ACTIONS. EACH PARTY
        MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL PARTY BASIS,
        AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
        REPRESENTATIVE ACTION OR PROCEEDING. THE ARBITRATOR MAY NOT CONSOLIDATE
        OR JOIN MORE THAN ONE PARTY'S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER
        ANY FORM OF A CONSOLIDATED, CLASS OR REPRESENTATIVE PROCEEDING. 11.
        OTHER TERMS. Entire Agreement and Changes. This agreement and the order
        constitute the entire agreement between the parties and supersede any
        prior or contemporaneous negotiations or agreements, whether oral or
        written, related to this subject matter. Customer is not relying on any
        representation concerning this subject matter, oral or written, not
        included in this agreement. No representation, promise, or inducement
        not included in this agreement is binding. No modification or waiver of
        any term of this agreement is effective unless both parties sign it,
        however this agreement may be modified through an online process
        provided by JET. Consent to Electronic Notice, Communications and
        Transactions. For purposes of messages and notices about the Service
        (including, without limitation, collections and payments issues), JET
        may send email notices to the email address associated with Customer's
        account or provide in service notifications. For certain notices (e.g.,
        notices regarding termination or material breaches), JET may send
        notices to the postal address provided by Customer. JET has no liability
        associated with Customer's failure to maintain accurate contact
        information within the Service or its failure to review any emails or
        in-service notices. CUSTOMER AGREES THAT ITS ELECTRONIC SUBMISSIONS VIA
        THE SERVICES IN CONNECTION WITH SUCH ACTIVITIES CONSTITUTE ITS AGREEMENT
        TO BE BOUND BY SUCH AGREEMENTS AND TRANSACTIONS AND APPLIES TO ALL
        RECORDS RELATING TO SUCH TRANSACTIONS. Customer represents and warrants
        that it has the authority to take such actions. No Assignment. Neither
        party may assign or transfer this agreement to a third party, nor
        delegate any duty, except that the agreement and all orders may be
        assigned, without the consent of the other party, as part of a merger or
        sale of all or substantially all the businesses or assets of a party.
        Independent Contractors. The parties are independent contractors with
        respect to each other. Enforceability and Force Majeure. If any term of
        this agreement is invalid or unenforceable, the other terms remain in
        effect. Except for the payment of monies, neither party is liable for
        events beyond its reasonable control, including, without limitation,
        force majeure events. Money Damages Insufficient. Any breach by a party
        of this agreement or violation of the other party's intellectual
        property rights could cause irreparable injury or harm to the other
        party. The other party may seek a court order to stop any breach or
        avoid any future breach of this agreement. Survival of Terms. Any terms,
        that by their nature survive termination of this agreement for a party
        to assert its rights and receive the protections of this agreement, will
        survive (including, without limitation, the confidentiality terms).
        Mobile Software. JET may make available software to access the Software
        to Users, via a mobile device and to Customers via a web portal. To take
        the Test, User must have access to a compatible mobile phone. To
        administer the service, order Tests and review billing, Customer must
        access a secured website using established credentials. JET does not
        warrant that the Software will be compatible with User’s mobile device.
        Customer or a User may use mobile data in connection with the Software
        and may incur additional charges from Customer's or a User’s wireless
        provider for these services. Customer agrees that it or its Users are
        solely responsible for any such charges. Customer acknowledges that JET
        may, from time to time, issue upgraded versions of the Software, and may
        automatically electronically upgrade the version of the Software that
        Customer is using on its mobile device. Customer consents to such
        automatic upgrading on its mobile device, and agrees that the terms and
        conditions of this agreement will apply to all such upgrades. If the
        mobile device software is acquired from an Apple platform (App Store
        Software), the additional terms set forth on Exhibit A apply. Feedback.
        If Customer provides feedback or suggestions about the Service, then JET
        (and those it allows to use its technology) may use such information
        without obligation to Customer. Last Revised 30/11/2021 EXHIBIT A -
        MOBILE SOFTWARE FROM APPLE APP STORE The following applies to any JET
        Mobile Software Customer acquires from the Apple App Store (App Store
        Software): Acknowledgment. This agreement is between JET and Customer
        only, and not with Apple, and JET, not Apple, is solely responsible for
        the App Store Software and the content thereof. The agreement does not
        provide for usage rules for App Store Software that are in conflict with
        the App Store Terms of Service as of the effective date of the agreement
        (which Customer acknowledges it has had the opportunity to review).
        Scope of License. The license granted to Customer for the App Store
        Software is limited to a non-transferable license on any Apple-branded
        Products that the Customer owns or controls and as permitted by the
        Usage Rules set forth in the App Store Terms of Service, except that
        such App Store Software may be accessed and used by other accounts
        associated with the purchaser via Family Sharing or volume purchasing.
        Maintenance and Support. JET is solely responsible for providing any
        maintenance and support services with respect to the App Store Software,
        as specified in the agreement, or as required under applicable law. JET
        and Customer acknowledge that Apple has no obligation whatsoever to
        furnish any maintenance and support services with respect to the App
        Store Software. Warranty. JET is solely responsible for any App Store
        Software warranties, whether express or implied by law, to the extent
        not effectively disclaimed. In the event of any failure of the App Store
        Software to conform to any applicable warranty, Customer may notify
        Apple, and Apple may refund the purchase price for the App Store
        Software (if that purchase price was paid to Apple on behalf of JetJET
        to Customer; and that, to the maximum extent permitted by applicable
        law, Apple has no other warranty obligation whatsoever with respect to
        the App Store Software, and any other claims, losses, liabilities,
        damages, costs, or expenses attributable to any failure to conform to
        any warranty is JET’s sole responsibility. Product Claims. JET and
        Customer acknowledge that JET, not Apple, is responsible for addressing
        any claims of Customer or any third party relating to the App Store
        Software or Customer’s possession and/or use of that App Store Software,
        including, but not limited to: (i) product liability claims; (ii) any
        claim that the App Store Software fails to conform to any applicable
        legal or regulatory requirement; and (iii) claims arising under consumer
        protection, privacy, or similar legislation, including in connection
        with the App Store Software’s use of the HealthKit and HomeKit
        frameworks. This agreement does not limit JET's liability to Customer
        beyond what is permitted by applicable law. Intellectual Property
        Rights. JET and Customer acknowledge that, in the event of any
        third-party claim that the App Store Software or Customer’s possession
        and use of that App Store Software infringes that third party’s
        intellectual property rights, Customer, not Apple, will be solely
        responsible for the investigation, defense, settlement, and discharge of
        any such intellectual property infringement claim. Legal Compliance.
        Customer represents and warrants that: (i) he/she/it is not located in a
        country that is subject to a U.S. Government embargo, or that has been
        designated by the U.S. Government as a “terrorist supporting” country;
        and (ii) he/she/it is not listed on any U.S. Government list of
        prohibited or restricted parties. Developer Name and Address. Company's
        name is JET Worldwide Enterprises Inc. and address is 9 Margarita Drive,
        Stony Point, NY 10980, and the contact information (phone number; email
        address) to which any Customer questions, complaints, or claims with
        respect to the App Store Software should be directed as follows:
        support@catchu.net Third-Party Terms of Agreement. Customer must comply
        with applicable third-party terms of use when using the App Store
        Software (e.g., the App Store Software is a VoIP application, then
        Customer must not be in violation of its wireless data service agreement
        when using the App Store Software). Third-Party Beneficiary. JET and
        Customer acknowledge and agree that Apple, and Apple’s subsidiaries, are
        third-party beneficiaries of this agreement, and that, upon Customer’s
        acceptance of the agreement, Apple will have the right (and will be
        deemed to have accepted the right) to enforce the agreement against
        Customer as a third-party beneficiary thereof.
      </p> */}
    </div>
  );
}

export default Tos;
