import { createSlice } from "@reduxjs/toolkit";

const initialAdminUserState = {
  admins: [],
  idToBeDeleted: "",
  idToBeDisabled: "",
  idToBeEnabled: "",
  idToBeEdited: "",
  successMessage: "",
  actionType: "",
  pageNumber: 1,
  currentPage: 1,

  fileUploadData: [],
  totalRecordsAdmins: 0,

  adminUserCurrentPage: 1,
  adminUserApiUrl: "",
  adminUserOrderQuery: "",
};

const AdminUserSlice = createSlice({
  name: "adminuser",
  initialState: initialAdminUserState,
  reducers: {
    setAdminUserCurrentPage(state, action) {
      state.adminUserCurrentPage = action.payload;
    },

    setAdminUserApiUrl(state, action) {
      state.adminUserApiUrl = action.payload;
    },
    setAdminUserOrderQuery(state, action) {
      state.adminUserOrderQuery = action.payload;
    },

    setAdminUsersPageNumber(state, action) {
      state.pageNumber = action.payload;
    },
    setAdminUserPage(state, action) {
      state.currentPage = action.payload;
    },
    addAdminUser(state, action) {
      state.admins.push(action.payload);
    },
    addAdminUsers(state, action) {
      state.admins = action.payload;
    },
    editAdminUser(state, payload) {
      const admin = state.admins.find((admin) => admin.id === payload);
    },
    deleteAdminUser(state, payload) {},
    disableAdminUser(state, payload) {},
    importAdminUsers(state, payload) {
      state = payload;
    },
    addAdminUserSuccessMessage(state, action) {
      state.successMessage = action.payload;
    },
    setIdToBeDeleted(state, action) {
      state.idToBeDeleted = action.payload;
    },
    setIdToBeDisabled(state, action) {
      state.idToBeDisabled = action.payload;
    },
    setIdToBeEnabled(state, action) {
      state.idToBeEnabled = action.payload;
    },
    setIdToBeEdited(state, action) {
      state.idToBeEdited = action.payload;
    },
    setActionType(state, action) {
      state.actionType = action.payload;
    },
    // setCommunityList(state, action) {
    //   state.communityList = action.payload;
    // },
    // setUserList(state, action) {
    //   state.userList = action.payload;
    // },
    // setCorporateInfo(state, action) {
    //   state.corporateInfo = action.payload;
    // },
    // setCorporateAdmins(state, action) {
    //   state.corporateAdmins = action.payload;
    // },
    setFileUploadData(state, { payload }) {
      state.fileUploadData = payload;
    },
    setAdminsTotalRecords(state, action) {
      state.totalRecordsAdmins = action.payload;
    },
    // saveUploadFile(state, { payload }) {
    //   state.corporateFile = payload;
    // },
  },
});

export const AdminUserActions = AdminUserSlice.actions;

export default AdminUserSlice.reducer;
