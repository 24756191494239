import React from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getWithExpiry } from "../../../utils/helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UploadFileIcon from "../../../../assets/upload-file.svg";

import { corporateActions } from "../../../../store/corporate";

const notifySuccess = (successMsg) =>
  toast.success(successMsg, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const notifyError = (error) =>
  toast.error(error, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
const UploadFile = ({ setInitialState, setDropdownState, setErrorTxt }) => {
  const dispatch = useDispatch();

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: ".csv",
      onDrop: (acceptedFiles) => {
        sendFile(acceptedFiles);
      },
    });

  const token = getWithExpiry("expiry_token");
  const sendFile = (file) => {
    const formDataFile = new FormData();
    formDataFile.append("csv_file", file[0]);
    dispatch(corporateActions.saveUploadFile(file));
    axios
      .post(
        `${process.env.REACT_APP_URL_PATH}/community/csvfile_headers/`,
        formDataFile,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(({ data }) => {
        const fileData = data.data;
        dispatch(corporateActions.setFileUploadData(fileData));

        setErrorTxt((prevState) =>
          prevState.map(() => {
            return "";
          })
        );

        setInitialState((prevState) =>
          prevState.map(() => {
            return "";
          })
        );
        setDropdownState((prevState) =>
          fileData.map(() => {
            return false;
          })
        );
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <div className="">
      <div {...getRootProps({ className: "fileDropBox" })}>
        <input
          type="file"
          className="file-upload"
          {...getInputProps()}
          multiple={false}
        />
        <div className="upload-content">
          <img src={UploadFileIcon} alt="" />
          <h2 className="upload-heading">
            {fileRejectionItems.length === 0
              ? files.length === 0
                ? "Drag 'n' drop some file here, or click to select file"
                : files
              : fileRejectionItems}
            {/* Drag and Drop files here or
                <Link className="choose-file">choose file</Link> */}
          </h2>
          <p className="file-size">Maximum size: 5MB</p>
        </div>
        <aside>
          <ul>{fileRejectionItems}</ul>
        </aside>
      </div>

      {/* <div {...getRootProps({ className: "fileDropBox" })}>
        <input {...getInputProps()} />
        <p>
          {fileRejectionItems.length === 0
            ? files.length === 0
              ? "Drag 'n' drop some files here, or click to select files"
              : files
            : fileRejectionItems}
        </p>
      </div>
      <aside>
        <ul>{fileRejectionItems}</ul>
      </aside> */}
    </div>
  );
};

export default UploadFile;
