const corporateOptionalFields = ["address_02"];
const communityOptionalFields = ["address_02"];
const iphoneOptionalFields = ["description"];
const userOptionalFieldsCorporate = ["community"];
const userOptionalFieldsCommunity = [];
const regularUserOptionalFields = ["corporate", "community"];
const adminUserOptionalFields = [];
const patientOptionalFields = ["email", "telephone"];
const patientCommunityOptionalFields = ["email", "telephone"];
const billeeOptionalFields = ["address_02"];
const providerOptionalFields = [
  "suffix",
  "billeeCompany",
  "billeeFirstName",
  "billeeLastName",
  "billeeEmail",
  "billeeTelephone",
  "address_01",
  "address_02",
  "country",
  "city",
  "state",
  "zip",
];

export {
  iphoneOptionalFields,
  corporateOptionalFields,
  communityOptionalFields,
  userOptionalFieldsCorporate,
  userOptionalFieldsCommunity,
  regularUserOptionalFields,
  adminUserOptionalFields,
  patientOptionalFields,
  billeeOptionalFields,
  patientCommunityOptionalFields,
  providerOptionalFields,
};
