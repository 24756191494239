import React, { Fragment, useState } from "react";

import DeleteModal from "../../Corporate/Modals/DeleteModal/DeleteModal";
import DisableModal from "../../Corporate/Modals/DisableModal/DisableModal";
import EnableModal from "../../Corporate/Modals/EnableModal/EnableModal";
import ShowTestsModal from "../../Corporate/Modals/ShowTestsModal/ShowTestsModal";
import ResendModal from "../Modals/ResendModal/ResendModal";
import { useDispatch, useSelector } from "react-redux";
import { sidebarActions } from "../../../store/sidebar";
import { communityActions } from "../../../store/community";
import { useHistory, useParams } from "react-router-dom";
import "./Table.scss";
import RowActions from "./RowActions";
import axios from "axios";
import EditCommunityModal from "../../EditCommunity/EditCommunityModal/EditCommunityModal";
import EditUserModal from "../Modals/EditUserModal/EditUserModal";

const TableBody = (props) => {
  const { type, data, dataType, setDataFlag, setShowToast } = props;
  const history = useHistory();
  const params = useParams();
  const { corporateID: corporateId } = params;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disableModalOpen, setDisableModalOpen] = useState(false);
  const [enableModalOpen, setEnableModalOpen] = useState(false);
  const [showTests, setShowTests] = useState(false);
  const [corpId, setCorpId] = useState();

  const [editCommunityModalShow, setEditCommunityModalShow] = useState(false);
  const showEditModalHandler = () => setEditCommunityModalShow(true);
  const hideEditModalHandler = () => setEditCommunityModalShow(false);
  const showDeleteModal = () => setDeleteModalOpen(true);
  const hideDeleteModal = () => setDeleteModalOpen(false);
  const showDisableModal = () => setDisableModalOpen(true);
  const hideDisableModal = () => setDisableModalOpen(false);
  const showEnableModal = () => setEnableModalOpen(true);
  const hideEnableModal = () => setEnableModalOpen(false);
  const showTestsModal = () => setShowTests(true);
  const hideTestsModal = () => setShowTests(false);

  const [editUserModalOpen, setEditModalOpen] = useState(false);
  const showEditModal = () => setEditModalOpen(true);
  const hideEditModal = () => setEditModalOpen(false);

  const [communityEditId, setCommunityEditId] = useState(null);
  const [deletedId, setDeletedId] = useState(null);
  const [enabledId, setEnabledId] = useState(null);
  const [disabledId, setDisabledId] = useState(null);

  const [resendID, setResendID] = useState(null);
  const [resendModalOpen, setResendModalOpen] = useState(false);
  const showResendModal = () => setResendModalOpen(true);
  const hideResendModal = () => setResendModalOpen(false);

  const dispatch = useDispatch();

  const communityList = useSelector((state) => state.corporate.communityList);
  const userList = useSelector((state) => state.corporate.userList);


  const deleteModalActions = (corpId) => {
    axios
      .post({
        url: `${process.env.REACT_APP_URL_PATH}/corporate/delete_corporate/${corpId}`,
      })
      .then((response) => {
      })
      .catch((error) => {
      });
    hideDeleteModal();
  };

  const disbleModalActions = (corpId) => {
    axios
      .post({
        url: `${process.env.REACT_APP_URL_PATH}/corporate/disable_corporate/${corpId}`,
      })
      .then((response) => {
      })
      .catch((error) => {
      });
    hideDisableModal();
  };

  const handleActionType = (corpId) => {
    if (actionType === "delete") {
      deleteModalActions(corpId);
    } else if (actionType === "disable") {
      disbleModalActions(corpId);
    }
  };

  const actionType = useSelector((state) => state.corporate.actionType);

  const setId = (id, type) => {
    if (type === "delete") {
      setDeletedId(id);
    } else if (type === "disable") {
      setDisabledId(id);
    } else if (type === "enable") {
      setEnabledId(id);
    } else if (type === "edit") {
      dispatch(communityActions.setIdToBeEdited(id)); // useless
      setCommunityEditId(id);
    } else if (type === "resend") {
      setResendID(id);
    }
  };

  const setBreadcrumb = (name, path) => {
    const record = {
      name: name,
      path: path,
    };
    // dispatch(sidebarActions.setCrumbs(record));
  };
  const handleEditAction = (id, name) => {
    history.push(`/edit/corporates/${corporateId}/community/${id}`);
    //setBreadcrumb(name, `/edit/corporates/${corporateId}/community/${id}`);
  };


  return (
    <Fragment>
      {editCommunityModalShow && (
        <EditCommunityModal
          isShowModal={showEditModalHandler}
          hideModal={hideEditModalHandler}
          type="Community"
          //data={communityInfo}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          outerEdit={true}
          communityEditId={communityEditId}
        />
      )}
      {resendModalOpen && (
        <ResendModal
          isShowModal={resendModalOpen}
          hideModal={hideResendModal}
          type={type}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          resendID={resendID}
        />
      )}

      {deleteModalOpen && (
        <DeleteModal
          type={dataType}
          dataType = {type}
          isShowModal={deleteModalOpen}
          hideModal={hideDeleteModal}
          data={data}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          deletedId={deletedId}
        />
      )}
      {disableModalOpen && (
        <DisableModal
          type={dataType}
          isShowModal={disableModalOpen}
          hideModal={hideDisableModal}
          data={data}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          disabledId={disabledId}
        />
      )}
      {enableModalOpen && (
        <EnableModal
          type={dataType}
          isShowModal={enableModalOpen}
          hideModal={hideEnableModal}
          data={data}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          enabledId={enabledId}
        />
      )}
      {showTests && (
        <ShowTestsModal isShowModal={showTests} hideModal={hideTestsModal} />
      )}
      {editUserModalOpen && (
        <EditUserModal
          isShowModal={editUserModalOpen}
          hideModal={hideEditModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      <tbody>
        {dataType === "community" && data.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        {dataType === "user" && data.length === 0 && (
          <tr>
            <td colSpan="6" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        {type === "Corporate" &&
          dataType === "user" &&
          data.map((record) => (
            <tr key={Math.random()}>
              <td>
                {record.data.first_name}
                {record.is_default && (
                  <div style={{ color: "green" }}>Default User</div>
                )}
              </td>
              <td>
                {record.data.last_name}
                {record.is_default && (
                  <div style={{ color: "green" }}>Default User</div>
                )}
              </td>
              <td>{record.data.email}</td>
              <td>{record.data.telephone_number}</td>
              <td>{record.role}</td>

              <RowActions
                setId={setId}
                recordId={record.data.id}
                isEnable={record.is_enabled}
                isDefault={record.is_default}
                showDeleteModal={showDeleteModal}
                showDisableModal={showDisableModal}
                showEnableModal={showEnableModal}
                delete_id={record.delete_id}
                showEditUserModal={showEditModal}
                page="sub-page"
                dataType={dataType}
                record={record}
                type={type}
                showResendModal={showResendModal}
              />
              {/* {record.is_default ? (
                <RowActions
                  setId={setId}
                  recordId={record.data.id}
                  isEnable={record.is_enabled}
                  isDefault={record.is_default}
                  showDeleteModal={hideDeleteModal}
                  showDisableModal={hideDisableModal}
                  showEnableModal={hideEnableModal}
                  delete_id={record.delete_id}
                  showEditUserModal={showEditModal}
                  page="sub-page"
                  dataType={dataType}
                  record={record}
                  type={type}
                />
              ) : (
                <RowActions
                  setId={setId}
                  recordId={record.data.id}
                  isEnable={record.is_enabled}
                  isDefault={record.is_default}
                  showDeleteModal={showDeleteModal}
                  showDisableModal={showDisableModal}
                  showEnableModal={showEnableModal}
                  delete_id={record.delete_id}
                  showEditUserModal={showEditModal}
                  page="sub-page"
                  dataType={dataType}
                  record={record}
                  type={type}
                />
              )} */}
            </tr>
          ))}
      </tbody>

      {type === "Community" && dataType === "user" && (
        <tbody>
          {userList.map((record) => (
            <tr key={Math.random()}>
              <td>{record.communityId}</td>
              <td>{record.name}</td>
              <td>{record.corporate}</td>
              <td>{record.address1}</td>
              <td>{record.address2}</td>
              <td>{record.state}</td>
              <td>{record.city}</td>
              <td>
                {/* <span className="tag zip-tag">{record.zip}</span> */}
                {record.zip}
              </td>
              <RowActions
                setId={setId}
                recordId={record.id}
                isEnable={record.is_enabled}
                isDefault={record.is_default}
                showDeleteModal={showDeleteModal}
                showDisableModal={showDisableModal}
                dataType={dataType}
                page="sub-page"
                type={type}
              />
            </tr>
          ))}
        </tbody>
      )}

      {type === "Corporate" && dataType === "community" && (
        <tbody>
          {communityList.map((record) => (
            <tr key={Math.random()}>
              <td>{record.community_id}</td>
              <td
                className="first-td-link"
                onClick={() =>
                  handleEditAction(record.id, record.community_name)
                }
              >
                {record.community_name}
              </td>
              {/* <td>{record.community_name}</td> */}
              <td>{record.address.address_1}</td>
              <td>
                {record.address.address_2 ? record.address.address_2 : "N/A"}
              </td>
              <td>{record.address.city}</td>
              <td>{record.address.state}</td>
              <td>
                {/* <span className="tag zip-tag">{record.address.zip}</span> */}
                {record.address.zip}
              </td>
              <RowActions
                setId={setId}
                recordId={record.id}
                isEnable={record.is_enabled}
                showEditModalHandler={showEditModalHandler}
                showDeleteModal={showDeleteModal}
                showDisableModal={showDisableModal}
                showEnableModal={showEnableModal}
                type={type}
                name={record.community_name}
                dataType={dataType}
                corporateId={corporateId}
              />
            </tr>
          ))}
        </tbody>
      )}
    </Fragment>
  );
};

export default TableBody;
