import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import useInput from "../../../hooks/use-input";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader2 from "../../UI/loader2/loader2";
import SelectComponent from "../../SelectComponent/SelectComponent";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getWithExpiry } from "../../utils/helper";
import axios from "axios";
import "./EditCommunityModal.scss";

// import images
import CloseModal from "../../../assets/close-outline.svg";
import DisabledIcon from "../../../assets/disabled-row.svg";

const isNotEmpty = (value) => value.trim() !== "";
const isEmailValid = (value) => value.includes("@");

function EditCommunityModal(props) {
  const {
    isShowModal,
    hideModal,
    type,
    setDataFlag,
    setShowToast,
    outerEdit,
    communityEditId,
  } = props;
  const [communityName, setCommunityName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  //Start Creating State Object for Form
  const [community, setCommunity] = useState({
    community_name: "",
    address_1: "",
    address_2: "",
    country: "",
    city: "",
    state: "",
    zip: "",
    default_admin: 0,
  });

  const handleChange = (event, val) => {
    const { name, value } = event.target;
    if (name === "zip") {
      const regex = /^\d+$/;
      if (value === "") {
        setCommunity({ ...community, [name]: value });
        return;
      }

      if (regex.test(value) === false) return;
    }
    setCommunity({
      ...community,
      [name]: value,
    });
  };

  //End Creating State Object for Form

  const setAddress2Handler = (e) => setAddress2(e.target.value);
  const setStateHandler = (e) => setState(e.target.value);
  const setZipHandler = (e) => setZip(e.target.value);

  const [communityNameError, setCommunityNameError] = useState("");
  const [nameError, setNameError] = useState("");
  const [address1Error, setAddress1Error] = useState("");
  const [cityError, setCityError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [zipError, setZipError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [roleError, setRoleError] = useState("");

  const params = useParams();
  const { communityID: communityId } = params;
  const token = "Bearer " + getWithExpiry("expiry_token");

  const [disableBtn, setDisableBtn] = useState(false);
  const [loading, setLoading] = useState(false);

  const [defaultAdmin, setDefaultAdmin] = useState("");

  const communityInfo = useSelector((state) => state.community.communityInfo);

  const communityUserAdmin = useSelector(
    (state) => state.community.community_admins
  );
  const [defaultAdminUsers, setDefaultAdminUsers] = useState("");
  const defaultUser =
    communityUserAdmin && communityUserAdmin.find((admin) => admin.is_default);
  useEffect(() => {
    if (outerEdit) {
      axios({
        url: `${process.env.REACT_APP_URL_PATH}/community/update_community/${communityEditId}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((response) => {

          const resData = response.data.data;

          setDefaultAdminUsers(resData.community_admins);
          const defaultAdmin =
            resData.community_admins &&
            resData.community_admins?.find((admin) => admin.is_default);
          setCommunity({
            ...community,
            community_name: resData.community.community_name,
            address_1: resData.community.address.address_1,
            address_2: resData.community.address.address_2,
            country: resData.community.address.country,
            city: resData.community.address.city,
            state: resData.community.address.state,
            zip: resData.community.address.zip,
            default_admin: defaultAdmin ? defaultAdmin?.id : "",
          });
        })
        .catch((error) => {
        });
    } else {
      if (communityInfo) {
        setCommunity({
          ...community,
          community_name: communityInfo.community_name,
          address_1: communityInfo.address.address_1,
          address_2: communityInfo.address.address_2,
          country: communityInfo.address.country,
          city: communityInfo.address.city,
          state: communityInfo.address.state,
          zip: communityInfo.address.zip,
          default_admin: defaultUser ? defaultUser?.id : "",
        });
      }
    }
  }, []);

  const communityNameChangeHandler = (e) => setCommunityName(e.target.value);
  const address1ChangeHandler = (e) => setAddress1(e.target.value);
  const address2ChangeHandler = (e) => setAddress2(e.target.value);
  const cityChangeHandler = (e) => setCity(e.target.value);
  const countryChangeHandler = (e) => setCountry(e.target.value);
  const zipChangeHandler = (e) => setZip(e.target.value);
  const stateChangeHandler = (e) => setState(e.target.value);

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  let formIsValid = false;

  const editCommunitySubmissionHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    setDisableBtn(true);
    setCommunityNameError("");
    setNameError("");
    setAddress1Error("");
    setCountryError("");
    setStateError("");
    setCityError("");
    setZipError("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");
    const fd = new FormData();
    fd.append("community_name", community.community_name);
    fd.append("address_1", community.address_1);
    fd.append("address_2", community.address_2);
    fd.append("country", community.country);
    fd.append("city", community.city);
    fd.append("state", community.state);
    fd.append("zip", community.zip);
    fd.append("default_admin", community.default_admin);

    // make http call
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}/community/update_community/${
        communityEditId ? communityEditId : communityId
      }`,
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        // handleSuccessResponse(response)
        setLoading(false);
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
        // setTimeout(() => {
        //   //window.location.reload();
        // }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        setDisableBtn(false);
        if (error.response.data.message === "Field Errors") {
          if (
            error.response.data.data["community_form"] &&
            "community_name" in error.response.data.data["community_form"]
          ) {
            setCommunityNameError(
              error.response.data.data["community_form"]["community_name"][0]
            );
          }
          if (
            error.response.data.data["address_form"] &&
            "address_1" in error.response.data.data["address_form"]
          ) {
            setAddress1Error(
              error.response.data.data["address_form"]["address_1"][0]
            );
          }
          if (
            error.response.data.data["address_form"] &&
            "city" in error.response.data.data["address_form"]
          ) {
            setCityError(error.response.data.data["address_form"]["city"][0]);
          }
          if (
            error.response.data.data["address_form"] &&
            "country" in error.response.data.data["address_form"]
          ) {
            setCountryError(
              error.response.data.data["address_form"]["country"][0]
            );
          }
          if (
            error.response.data.data["address_form"] &&
            "state" in error.response.data.data["address_form"]
          ) {
            setStateError(error.response.data.data["address_form"]["state"][0]);
          }
          if (
            error.response.data.data["address_form"] &&
            "zip" in error.response.data.data["address_form"]
          ) {
            setZipError(error.response.data.data["address_form"]["zip"][0]);
          }
          if (
            error.response.data.data["corporate_form"] &&
            "corporate_name" in error.response.data.data["corporate_form"]
          ) {
            setNameError(
              error.response.data.data["corporate_form"]["corporate_name"][0]
            );
          }
          if (
            error.response.data.data["phone_form"] &&
            "telephone_number" in error.response.data.data["phone_form"]
          ) {
            setPhoneError(
              error.response.data.data["phone_form"]["telephone_number"][0]
            );
          }
          if (
            error.response.data.data["user_form"] &&
            "email" in error.response.data.data["user_form"]
          ) {
            setEmailError(error.response.data.data["user_form"]["email"][0]);
          }
          if (
            error.response.data.data["user_form"] &&
            "first_name" in error.response.data.data["user_form"]
          ) {
            setFirstNameError(
              error.response.data.data["user_form"]["first_name"][0]
            );
          }
          if (
            error.response.data.data["user_form"] &&
            "last_name" in error.response.data.data["user_form"]
          ) {
            setLastNameError(
              error.response.data.data["user_form"]["last_name"][0]
            );
          }
        } else {
          notifyError(error.response.data.message);
        }
      });
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setDefaultAdmin(value);
  };

  return (
    <div className="action-modal-wrapper">
      <Modal
        className="comunitie-modal modal-loader"
        show={isShowModal}
        size="lg"
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header className="comubitie-modal-header" id="rm-padding">
          <h1 className="modal-header-title">Edit Community</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="comunitie-modal-body">
          <form
           
            method="post"
            className="edit-community-form"
            onSubmit={editCommunitySubmissionHandler}
          >
            <div className="edit-corporate-fields">
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="community_name" className="label">
                      Name *
                    </label>
                    <input
                      id="community_name"
                      name="community_name"
                      type="text"
                      placeholder="Name"
                      className="custom-input"
                      // value={communityName}
                      // onChange={communityNameChangeHandler}
                      value={community.community_name}
                      onChange={handleChange}
                      required
                    />
                    {communityNameError && (
                      <p className="error-text">{communityNameError}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="address_1" className="label">
                      Address 01 *
                    </label>
                    <input
                      id="address_1"
                      name="address_1"
                      type="text"
                      placeholder="Address 01"
                      className="custom-input"
                      // value={address1}
                      // onChange={address1ChangeHandler}
                      value={community.address_1}
                      onChange={handleChange}
                      required
                    />
                    {address1Error && (
                      <p className="error-text">{address1Error}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="address_2" className="label">
                      Address 02
                    </label>
                    <input
                      type="text"
                      name="address_2"
                      id="address_2"
                      className="custom-input"
                      placeholder="Address 02"
                      // value={address2}
                      // onChange={address2ChangeHandler}
                      value={community.address_2}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="city" className="label">
                      City *
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="custom-input"
                      placeholder="City"
                      // value={city}
                      // onChange={cityChangeHandler}
                      value={community.city}
                      onChange={handleChange}
                      required
                    />
                    {cityError && <p className="error-text">{cityError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-col">
                  <div className="field-wrapper">
                    <label htmlFor="state" className="label">
                      State *
                    </label>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      className="custom-input"
                      placeholder="State"
                      // value={state}
                      // onChange={stateChangeHandler}
                      value={community.state}
                      onChange={handleChange}
                      required
                    />
                    {stateError && <p className="error-text">{stateError}</p>}
                  </div>
                </div>

                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="zip" className="label">
                      Zip *
                    </label>
                    <input
                      type="text"
                      name="zip"
                      id="zip"
                      className="custom-input"
                      placeholder="Zip"
                      // value={zip}
                      // onChange={zipChangeHandler}
                      value={community.zip}
                      onChange={handleChange}
                      required
                    />
                    {zipError && <p className="error-text">{zipError}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 right-col">
                  <div className="field-wrapper">
                    <label htmlFor="country" className="label">
                      Country *
                    </label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      className="custom-input"
                      placeholder="Country"
                      // value={country}
                      // onChange={countryChangeHandler}
                      value={community.country}
                      onChange={handleChange}
                      required
                    />
                    {countryError && (
                      <p className="error-text">{countryError}</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 left-col">
                  <label htmlFor="default_admin" className="label">
                    Default Admin
                  </label>
                  <select
                    id="default_admin"
                    name="default_admin"
                    className="custom-select-modal"
                    // onChange={changeHandler}
                    // value={defaultAdmin}
                    onChange={(e) => {
                      setCommunity({
                        ...community,
                        default_admin: e.target.value,
                      });
                    }}
                    value={community.default_admin}
                  >
                    {outerEdit ? (
                      <>
                        {community?.default_admin === "" && (
                          <option>Select Default Admin User</option>
                        )}
                        {defaultAdminUsers &&
                          defaultAdminUsers.map((name) => (
                            <option key={name.id} value={name.id}>
                              {name.first_name} {name.last_name}
                            </option>
                          ))}
                      </>
                    ) : (
                      <>
                        {community?.default_admin === "" && (
                          <option>Select Default Admin User</option>
                        )}
                        {communityUserAdmin &&
                          communityUserAdmin.map((name) => (
                            <option key={name.id} value={name.id}>
                              {name.first_name} {name.last_name}
                            </option>
                          ))}
                      </>
                    )}
                    {/* {communityUserAdmin &&
                      communityUserAdmin.map((user) => (
                        <option value={user.id}>
                          {user.first_name} {user.last_name}
                        </option>
                      ))} */}
                  </select>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16 text-capitalize"
            onClick={hideModal}
          >
            Close
          </button>
          <button
            // type="submit"
            onClick={editCommunitySubmissionHandler}
            className="btn btn-primary-fill btn-primary-16"
            disabled={disableBtn}
          >
            Update
          </button>
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

export default EditCommunityModal;
