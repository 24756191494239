import Location from "../../assets/location-icon.svg";

export const Address = (props) => {
  const { address, title } = props;

  return (
    <div className="ecd-left">
      <h1 className="ecd-heading">{title}</h1>
      <span className="ecd-des-image">
        <img src={Location} alt="" />
        <p className="ecd-des">
          {`${address?.address_1}, 
                       ${address?.city}
                       ${address?.state} 
                       ${address?.zip}, ${address?.country}`}
        </p>
      </span>
    </div>
  );
};
