import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getWithExpiry } from "../../../utils/helper";
import axios from "axios";
import "./DisableModal.scss";
import Loader2 from "../../../UI/loader2/loader2";
// import images
import CloseModal from "../../../../assets/close-outline.svg";
import DisabledIcon from "../../../../assets/disabled-row.svg";
import { useHistory } from "react-router-dom";

function DisableModal(props) {
  const history = useHistory();
  const {
    isShowModal,
    hideModal,
    id,
    type,
    data,
    setDataFlag,
    setShowToast,
    disabledId,
  } = props;
  const token = "Bearer " + getWithExpiry("expiry_token");
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [loading, setLoading] = useState(false);

  const idToBeDisabled1 = useSelector(
    (state) => state.corporate.idToBeDisabled
  );
  const idToBeDisabled2 = useSelector(
    (state) => state.adminuser.idToBeDisabled
  );
  const idToBeDisabled3 = useSelector((state) => state.reguser.idToBeDisabled);
  const idToBeDisabled4 = useSelector((state) => state.patient.idToBeDisabled);

  const idToBeDisabled5 = useSelector(
    (state) => state.community.idToBeDisabled
  );

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  let model = "",
    delID;
  if (type === "community" || type === "Community") {
    model = "/community/disable_community/";
    // delID = idToBeDisabled1;
    delID = disabledId;
  } else if (type === "Corporate") {
    model = "/corporate/disable_corporate/";
    // delID = idToBeDisabled1;
    delID = disabledId;
  } else if (type === "RegUser") {
    model = "/user/disable_regular_user/";
    // delID = idToBeDisabled3;
    delID = disabledId;
  } else if (type === "AdminUser") {
    model = "/user/disable_user/";
    // delID = idToBeDisabled2;
    delID = disabledId;
  } else if (type === "iphone") {
    model = "/test/disable_iphone/";
    // delID = idToBeDisabled1;
    delID = disabledId;
  } else if (type === "user") {
    model = "/user/disable_user/";
    // delID = idToBeDisabled1;
    delID = disabledId;
  } else if (type === "Patient") {
    model = "/user/disable_user/";
    // delID = idToBeDisabled4;
    delID = disabledId;
  } else if (type === "patient") {
    model = "/user/disable_user/";
    // delID = idToBeDisabled5;
    delID = disabledId;
  } else if (type === "PatientDetails") {
    model = "/user/disable_user/";
    delID = disabledId;
  } else if (type === "billee") {
    model = "/user/disable_user/";
    // delID = idToBeDisabled5;
    delID = disabledId;
  } else if (type === "Provider") {
    model = "/user/disable_provider/";
    delID = disabledId;
  } else if (type === "history") {
    model = "/community/terminate_group_test/";
    delID = disabledId;
  }

  const disableCorporate = () => {
    setLoading(true);
    setDisabledBtn(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}${model}${disabledId}`,
      data: disabledId,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then(async (response) => {
        setLoading(false);
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
        // setTimeout(() => {
        //   //window.location.reload();
        // }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        setDisabledBtn(false);
        if (error.response && error.response.data.message !== "Field Errors") {
          notifyError(error.message);
        }
      });
  };

  return (
    <div className="action-modal-wrapper">
      <Modal
        className="action-modal modal-loader"
        show={isShowModal}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="action-modal-header" id="disable-modal">
          {/* <h1 className="modal-header-title"></h1> */}
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="action-modal-body">
          <img src={DisabledIcon} alt="" />

          {type === "history" ? (
            <h2 className="action-heading">
              {"Are you sure you want to terminate?"}
            </h2>
          ) : (
            <h2 className="action-heading">
              {"Are you sure do you want to disable?"}
            </h2>
          )}
        </Modal.Body>
        <Modal.Footer className="action-footer-modal" id="disable-modal-footer">
          <button
            className="btn btn-primary-fill btn-14-24"
            onClick={disableCorporate}
            disabled={disabledBtn}
          >
            Yes, I’m Sure.
          </button>
          <button
            className="btn btn-primary-outline btn-14-24"
            onClick={hideModal}
          >
            {type === "history" ? "Cancel Terminate" : "Cancel Disable"}
          </button>
        </Modal.Footer>
        {loading && <Loader2 />}
      </Modal>
    </div>
  );
}

export default DisableModal;
