import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AdminUserActions } from "../../../store/adminUser";
import { RegUserActions } from "../../../store/regUser";
import { sidebarActions } from "../../../store/sidebar";
import { useHistory } from "react-router-dom";

import DeleteModal from "../../Corporate/Modals/DeleteModal/DeleteModal";
import DisableModal from "../../Corporate/Modals/DisableModal/DisableModal";
import ShowTestsModal from "../../Corporate/Modals/ShowTestsModal/ShowTestsModal";
import ResendModal from "../../Corporate/Modals/ResendModal/ResendModal";
import DeleteProviderModal from "../../Corporate/Modals/DeleteModal/DeleteProviderModal";

import TestsToScheduleBody from "./TestsToScheduleBody";
import UpcomingTestsBody from "./UpcomingTestsBody";
import CreateTestModal from "../Modals/CreateTestModal";
import EditProvider from "../../Provider/Modals/EditProvider";
import AddPatient from "../../Patient/Modals/AddPatient";
import "./Table.scss";
import RowActions from "./RowActions";
import EnableModal from "../../Corporate/Modals/EnableModal/EnableModal";
import EditAdminUserModal from "../../Users/EditAdminUser/EditAdminUser";
import EditRegUserModal from "../../Users/EditRegUser/EditRegUser";
import { providerActions } from "../../../store/provider";
import ProviderModal from "../../PatientDetail/Modals/ProviderModal";
import ResendButton from "../../../assets/resend-button.svg";
import { formatCurrency } from "../../../utils/helper";
// import UpcomingTestsData from ""
import { crumbActions } from "../../../store/breadcrumb";

const TableBody = (props) => {
  const {
    type,
    data,
    subType,
    regUserRoles,
    setDataFlag,
    setShowToast,
    setDataFlag1,
    setDataFlag2,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [resendID, setResendID] = useState(null);
  const [resendModalOpen, setResendModalOpen] = useState(false);
  const showResendModal = () => setResendModalOpen(true);
  const hideResendModal = () => setResendModalOpen(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteProviderModalOpen, setDeleteProviderModalOpen] = useState(false);
  const [disableModalOpen, setDisableModalOpen] = useState(false);
  const [showTests, setShowTests] = useState(false);
  const [enableModalOpen, setEnableModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [showEditProvider, setShowEditProvider] = useState(false);
  const [itemId, setItemId] = useState();
  const [createTestModalOpen, setCreateTestModalOpen] = useState(false);

  const [filterTestID, setFilterTestID] = useState(null);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const showFilterModal = (testID) => {
    setFilterTestID(testID);
    setFilterModalOpen(true);
  };
  const hideFilterModal = () => setFilterModalOpen(false);
  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);
  const showDeleteModal = (record) => {
    setDeleteModalOpen(true);
    //dispatch(providerActions.setEditProviderInfo(record));
  };
  const showDeleteProviderModal = (record) => {
    if (record) {
      setDeleteProviderModalOpen(true);
      dispatch(providerActions.setEditProviderInfo(record));
    }
  };
  const hideDeleteModal = () => setDeleteModalOpen(false);
  const hideDeleteProviderModal = () => setDeleteProviderModalOpen(false);

  const showDisableModal = (record) => {
    setDisableModalOpen(true);
    //dispatch(providerActions.setEditProviderInfo(record));
  };
  const hideDisableModal = () => setDisableModalOpen(false);

  const showTestsModal = () => setShowTests(true);
  const hideTestsModal = () => setShowTests(false);

  const showEnableModal = (record) => {
    setEnableModalOpen(true);
    //dispatch(providerActions.setEditProviderInfo(record));
  };
  const hideEnableModal = () => setEnableModalOpen(false);

  const showCreateTestModal = () => setCreateTestModalOpen(true);
  const hideCreateTestModal = () => setCreateTestModalOpen(false);
  const showAdminEditModal = () => setEditModalOpen(true);
  const hideAdminEditModal = () => setEditModalOpen(false);
  const showRegUserEditModal = () => setEditModalOpen(true);
  const hideRegUserEditModal = () => setEditModalOpen(false);

  const [addPatientModalOpen, setAddPatientModalOpen] = useState(false);
  const showAddPatientModalOpen = () => setAddPatientModalOpen(true);
  const hideAddPatientModalOpen = () => setAddPatientModalOpen(false);

  const showEditModal = (record) => {
    if (record) {
      setShowEditProvider(true);
      dispatch(providerActions.setEditProviderInfo(record));
    }
  };

  const hideEditModal = () => setShowEditProvider(false);
  const actionTypeRegUser = useSelector((state) => state.reguser.actionType);
  const actionTypeAdminUser = useSelector(
    (state) => state.adminuser.actionType
  );

  const [patientEditId, setPatientEditId] = useState(null);
  const [deletedId, setDeletedId] = useState(null);
  const [enabledId, setEnabledId] = useState(null);
  const [disabledId, setDisabledId] = useState(null);

  const setId = (id, operation) => {
    if (type === "RegUser") {
      if (operation === "delete") {
        setDeletedId(id);
      } else if (operation === "disable") {
        setDisabledId(id);
      } else if (operation === "enable") {
        setEnabledId(id);
      } else if (operation === "edit") {
        dispatch(RegUserActions.setIdToBeEdited(id));
      } else if (operation === "resend") {
        setResendID(id);
      }
    } else if (type === "AdminUser") {
      if (operation === "delete") {
        setDeletedId(id);
      } else if (operation === "disable") {
        setDisabledId(id);
      } else if (operation === "enable") {
        setEnabledId(id);
      } else if (operation === "edit") {
        dispatch(AdminUserActions.setIdToBeEdited(id));
      } else if (operation === "resend") {
        setResendID(id);
      }
    } else if (type === "Patient") {
      if (operation === "delete") {
        setDeletedId(id);
      } else if (operation === "disable") {
        setDisabledId(id);
      } else if (operation === "enable") {
        setEnabledId(id);
      } else if (operation === "edit") {
        setPatientEditId(id);
      }
    } else if (type === "Provider") {
      if (operation === "delete") {
        setDeletedId(id);
      } else if (operation === "disable") {
        setDisabledId(id);
      } else if (operation === "enable") {
        setEnabledId(id);
      } else if (operation === "resend") {
        setResendID(id);
      }
    } else if (type === "Test") {
      if (operation === "delete") {
        setDeletedId(id);
      } else if (operation === "disable") {
        setDisabledId(id);
      } else if (operation === "enable") {
        setEnabledId(id);
      } else if (operation === "resend") {
        setResendID(id);
      }
    }
  };

  const setBreadcrumb = (name, path) => {
    const record = {
      name: name,
      path: path,
    };
  };
  const handleEditAction = (id, name) => {
    history.push(`/edit/patients/${id}`);
  };

  const handleEditActionProvider = (id,first_name,last_name) => {
    dispatch(
      crumbActions.setPrepaidPatientName({
        [id]: `${first_name}  ${last_name}`,
      })
    );
    history.push(`/edit/external-providers/${id}`);
  };


  return (
    <Fragment>
      {type === "AdminUser" && editModalOpen && (
        <EditAdminUserModal
          isShowModal={editModalOpen}
          hideModal={hideAdminEditModal}
          type={type}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}

      {type === "RegUser" && editModalOpen && (
        <EditRegUserModal
          isShowModal={editModalOpen}
          hideModal={hideRegUserEditModal}
          type={type}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}

      {resendModalOpen && (
        <ResendModal
          isShowModal={resendModalOpen}
          hideModal={hideResendModal}
          type={type}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          resendID={resendID}
        />
      )}

      {deleteModalOpen && (
        <DeleteModal
          isShowModal={deleteModalOpen}
          hideModal={hideDeleteModal}
          type={type}
          dataType={"Provider"}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          deletedId={deletedId}
        />
      )}
      {disableModalOpen && (
        <DisableModal
          isShowModal={disableModalOpen}
          hideModal={hideDisableModal}
          type={type}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          disabledId={disabledId}
        />
      )}
      {enableModalOpen && (
        <EnableModal
          type={type}
          isShowModal={enableModalOpen}
          hideModal={hideEnableModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          enabledId={enabledId}
        />
      )}
      {showTests && (
        <ShowTestsModal isShowModal={showTests} hideModal={hideTestsModal} />
      )}
      {createTestModalOpen && (
        <CreateTestModal
          isShowModal={createTestModalOpen}
          hideModal={hideCreateTestModal}
          setId={setId}
          showDeleteModal={showDeleteModal}
          type="Test"
          setDataFlag={setDataFlag1}
          setShowToast={setShowToast}
        />
      )}
      {showEditProvider && (
        <EditProvider
          isShowModal={showEditProvider}
          hideModal={hideEditModal}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
        />
      )}
      {deleteProviderModalOpen && (
        <DeleteProviderModal
          isShowModal={deleteProviderModalOpen}
          hideModal={hideDeleteProviderModal}
          type={type}
          subType={subType}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          deletedId={deletedId}
        />
      )}

      {addPatientModalOpen && (
        <AddPatient
          isShowModal={addPatientModalOpen}
          hideModal={hideAddPatientModalOpen}
          //record={user}
          type={"PatientDetails"}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          outerEdit={true}
          patientEditId={patientEditId}
          isEdit={true}
        />
      )}
      {filterModalOpen && (
        <ProviderModal
          isShowModal={filterModalOpen}
          hideModal={hideFilterModal}
          setShowToast={setShowToast}
          setDataFlag={setDataFlag}
          testID={filterTestID}
        />
      )}

      <tbody>
        {type === "AdminUser" && data.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}

        {type === "RegUser" && data.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        {type === "Community" && data.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        {type === "Patient" &&
          !subType &&
          subType !== "Patient History" &&
          data.length === 0 && (
            <tr>
              <td colSpan="10" className="text-center">
                No data available in table
              </td>
            </tr>
          )}
        {type === "Patient" &&
          subType === "Patient History" &&
          data.length === 0 && (
            <tr>
              <td colSpan="10" className="text-center">
                No data available in table
              </td>
            </tr>
          )}
        {type === "Provider" && data.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
        {type === "External Providers" && data.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
         {type === "Prepaid Patients" && data.length === 0 && (
          <tr>
            <td colSpan="10" className="text-center">
              No data available in table
            </td>
          </tr>
        )}
      </tbody>

      {type === "AdminUser" && (
        <tbody>
          {data.map((record) => (
            <tr key={record.id}>
              <td>{record.first_name}</td>
              <td>{record.last_name}</td>
              <td>{record.email}</td>
              <td>{record.telephone_number}</td>
              <RowActions
                isEnable={record.is_enabled}
                isDefault={record.is_default}
                setId={setId}
                recordId={record.id}
                showDeleteModal={showDeleteModal}
                showDisableModal={showDisableModal}
                showEnableModal={showEnableModal}
                showTestsModal={showTestsModal}
                showAdminEditModal={showAdminEditModal}
                showResendModal={showResendModal}
                record={record}
                type={type}
              />
            </tr>
          ))}
        </tbody>
      )}

      {type === "RegUser" && (
        <tbody>
          {data.map((record) => (
            <tr key={Math.random()}>
              <td>{record.first_name}</td>
              <td>{record.last_name}</td>
              <td>{record.email}</td>
              <td>{record.telephone_number}</td>

              <RowActions
                isEnable={record.is_enabled}
                setId={setId}
                recordId={record.id}
                record={record}
                showDeleteModal={showDeleteModal}
                showDisableModal={showDisableModal}
                showEnableModal={showEnableModal}
                showTestsModal={showTestsModal}
                showRegUserEditModal={showRegUserEditModal}
                showResendModal={showResendModal}
                type={type}
              />
            </tr>
          ))}
        </tbody>
      )}

      {type === "Test" && subType === "schedule" && (
        <TestsToScheduleBody
          setId={setId}
          data={data}
          showCreateTestModal={showCreateTestModal}
          type={type}
          subType={subType}
          setShowToast={setShowToast}
          setDataFlag={setDataFlag1}
        />
      )}

      {type === "Test" && subType === "upcoming" && (
        <UpcomingTestsBody
          data={data}
          type={type}
          subType={subType}
          setShowToast={setShowToast}
          setDataFlag={setDataFlag2}
          setId={setId}
          showDeleteModal={showDeleteModal}
        />
      )}

      {type === "Community" && (
        <tbody>
          {data.map((record) => (
            <tr key={Math.random()}>
              <td>{record.communityId}</td>
              <td>{record.name}</td>
              <td>{record.corporate}</td>
              <td>{record.address1}</td>
              <td>{record.address2}</td>
              <td>{record.state}</td>
              <td>{record.city}</td>
              <td>
                {/* <span className="tag zip-tag">{record.zip}</span> */}
                {record.zip}
              </td>
              <RowActions
                showDeleteModal={showDeleteModal}
                showDisableModal={showDisableModal}
              />
            </tr>
          ))}
        </tbody>
      )}

      {type === "Patient" && !subType && (
        <tbody>
          {data.map((record) => (
            <tr key={Math.random()}>
              <td
                className="first-td-link"
                onClick={() =>
                  handleEditAction(
                    record.id,
                    record.first_name + " " + record.last_name
                  )
                }
              >
                {record.first_name}
              </td>
              <td
                className="first-td-link"
                onClick={() =>
                  handleEditAction(
                    record.id,
                    record.first_name + " " + record.last_name
                  )
                }
              >
                {record.last_name}
              </td>
              {/* <td>{record.first_name}</td>
              <td>{record.last_name}</td> */}
              <td>{record.community_name}</td>
              <td>{record.dob}</td>
              <td>{record.email ? record.email : "N/A"}</td>
              <td>
                {record.telephone_number ? record.telephone_number : "N/A"}
              </td>
              <td>
                <span className="text-tag">
                  <span
                    className={`tag ${(record.status === "Pending" && "complete-tag") ||
                      (record.status === "Success" && "resend-tag") ||
                      (record.status === "Failed" && "failed-tag") ||
                      (record.status === "Expired" && "failed-tag") ||
                      (record.status === "Initiated" && "pending-tag")
                      }`}
                  >
                    {record.status ? record.status : "N/A"}
                  </span>
                </span>
              </td>

              <RowActions
                isEnable={record.is_enabled}
                setId={setId}
                recordId={record.id}
                showAddPatientModalOpen={showAddPatientModalOpen}
                showDeleteModal={showDeleteModal}
                showDisableModal={showDisableModal}
                showEnableModal={showEnableModal}
                showTestsModal={showTestsModal}
                type={type}
                name={record.first_name + " " + record.last_name}
                record={record}
              />
            </tr>
          ))}
        </tbody>
      )}

      {type === "Patient" && subType === "Patient History" && (
        <tbody>
          {data.map((record) => (
            <tr key={Math.random()}>
              <td>
                <span className="text-tag">
                  <p className="before-tag-text">{record.provider}</p>
                </span>
              </td>

              <td>{record.ordered}</td>
              <td>
                <span className="text-tag">
                  <span
                    className={`tag ${(record.status === "Pending" && "complete-tag") ||
                      (record.status === "Success" && "resend-tag") ||
                      (record.status === "Failed" && "failed-tag") ||
                      (record.status === "Expired" && "failed-tag") ||
                      (record.status === "Initiated" && "pending-tag")
                      }`}
                  >
                    {record.status ? record.status : "N/A"}
                  </span>
                </span>
              </td>
              <td>{record.test_date}</td>
              <td>{record.location ? record.location : "N/A"}</td>
              <td>
                <span className="tag zip-tag">
                  {record.remote ? "Yes" : "No"}
                </span>
              </td>
              <td>
                {" "}
                {record.credit_amount === "N/A" ||
                  !record.credit_amount ||
                  typeof parseInt(record.credit_amount) !== "number"
                  ? "N/A"
                  : `$${formatCurrency(record.credit_amount)}`}
              </td>
              <td>{record.amount_reason ? record.amount_reason : "N/A"}</td>
              <td>
                <span
                  title="Resend Report"
                  className="td-action"
                  onClick={() => showFilterModal(record.id)}
                >
                  {record.status === "Success" && (
                    <img src={ResendButton} alt="Resend Report" />
                  )}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      )}

      {type === "External Providers" && (
        <tbody>
          {data &&
            data.map((record) => (
              <tr key={Math.random()}>
                <td className="first-td-link" onClick={() => handleEditActionProvider(record.id,record.first_name,record.last_name)
                }>{record.provider_status_code}</td>
                <td>{record.first_name}</td>
                <td>{record.last_name}</td>
                <td>{record.email}</td>
              </tr>
            ))}
        </tbody>
      )}


      {type === "Prepaid Patients" && (
        <tbody>
          {data &&
            data.map((record) => (
              <tr key={Math.random()}>
                <td>{record.first_name}</td>
                <td>{record.last_name}</td>
                <td >{record.provider_status_code}</td>
                {/* <td>{record.test_status}</td> */}
                <td>
                  <span>
                <span
                    className={`tag ${(record.test_status === "Pending" && "complete-tag") ||
                      (record.test_status === "Success" && "resend-tag") ||
                      (record.test_status === "Failed" && "failed-tag") ||
                      (record.test_status === "Expired" && "failed-tag") ||
                      (record.test_status === "Initiated" && "pending-tag")
                      }`}
                  >
                    {record.test_status ? record.test_status : "N/A"}
                  </span>
                </span>
                </td>
              </tr>
            ))}
        </tbody>
      )}

      {type === "Provider" && (
        <tbody>
          {data &&
            data.map((record) => (
              <tr key={Math.random()}>
                <td>{record.first_name}</td>
                <td>{record.last_name}</td>
                <td>
                  <ul className="providers-comunities-list">
                    {record &&
                      record.communities.map((com, index) => (
                        <li key={index} className="pc-single">
                          {com}
                        </li>
                      ))}
                  </ul>
                </td>
                <td>{record.email}</td>
                <td>{record.telephone_number}</td>
                {!isCatchUAdmin &&
                  (userRole.includes("Super Admin") ||
                    userRole.includes("Community Admin") ||
                    userRole.includes("Individual Practice Admin") ||
                    userRole.includes("Support Staff")) ? (
                  <>
                    <RowActions
                      type={type}
                      subType={subType}
                      dataType={"Provider"}
                      setId={setId}
                      isEnable={record.is_enabled}
                      recordId={record.id}
                      record={record}
                      showDeleteModal={showDeleteModal}
                      showDisableModal={showDisableModal}
                      showEnableModal={showEnableModal}
                      showEditModal={showEditModal}
                      showDeleteProviderModal={showDeleteProviderModal}
                      showResendModal={showResendModal}

                    // subType="Patient History"
                    />
                  </>
                ) : (
                  <>
                    {isCatchUAdmin &&
                      (userRole.includes("Super Admin") ||
                        permissions.includes("Providers") ||
                        userRole.includes("Support Staff")) && (
                        <>
                          <RowActions
                            type={type}
                            setId={setId}
                            dataType={"Provider"}
                            isEnable={record.is_enabled}
                            recordId={record.id}
                            record={record}
                            showDeleteModal={showDeleteModal}
                            showDisableModal={showDisableModal}
                            showEnableModal={showEnableModal}
                            showEditModal={showEditModal}
                            showDeleteProviderModal={showDeleteProviderModal}
                            showResendModal={showResendModal}

                          // subType="Patient History"
                          />
                        </>
                      )}
                  </>
                )}
              </tr>
            ))}
        </tbody>
      )}
    </Fragment>
  );
};

export default TableBody;
