import Corporate from "./components/Corporate/Corporate";
import Communities from "./components/Communities/Communities";
import Patients from "./components/Patient/Patient";
import Test from "./components/Test/Test";
import Provider from "./components/Provider/Provider";
import Users from "./components/Users/Users";
import Billing from "./components/Billing/Billing";
import NotFound from "./components/NotFound/NotFound";
import EditCorporate from "./components/EditCorporate/EditCorporate";
import CorpDetail from "./components/CorporateDetail/CorporateDetail"; // removing this causes weird behaviour
import EditCommunities from "./components/EditCommunity/EditCommunity";
import PatientsDetail from "./components/PatientDetail/PatientDetail";
import Equipments from "./components/Equipments/Equipments";
import ExternalProviders from "./components/ExternalProviders/ExternalProviders";
import ContactUs from "./components/Contact/ContactUs";
import PrepaidPatients from "./components/PrepaidPatients/PrepaidPatients";

const paths = [
  // Corporate paths
  {
    path: "/corporates",
    name: "Corporate",
    dynamic: "no",
    type: "Corporate",
    Component: Corporate,
  },
  {
    path: "/edit/corporates/:corporateID",
    name: "Edit Corporate",
    dynamic: "yes",
    type: "Corporate",
    Component: EditCorporate,
  },
  {
    path: "/edit/corporates/:corporateID/community/:communityID",
    name: "Edit Corporate Community",
    dynamic: "yes",
    type: "Community",
    Component: EditCommunities,
  },
  {
    path: "/edit/corporates/:corporateID/community/:communityID/patient/:patientID",
    name: "Edit Corporate Patients",
    dynamic: "yes",
    type: "Patients",
    Component: PatientsDetail,
  },

  // Community paths
  {
    path: "/communities",
    name: "Community",
    dynamic: "no",
    type: "Community",
    Component: Communities,
  },
  {
    path: "/edit/communities/:communityID",
    name: "Edit Community",
    dynamic: "yes",
    type: "Community",
    Component: EditCommunities,
  },
  {
    path: "/edit/communities/:communityID/edit/patient/:patientID",
    name: "Edit Community Patients",
    dynamic: "yes",
    type: "Patients",
    Component: PatientsDetail,
  },

  // Patient paths
  {
    path: "/patients",
    name: "Patients",
    dynamic: "no",
    type: "Patients",
    Component: Patients,
  },
  {
    path: "/edit/patients/:patientID",
    name: "Edit Patients",
    dynamic: "yes",
    type: "Patients",
    Component: PatientsDetail,
  },

  // Other paths
  {
    path: "/tests",
    name: "Tests",
    dynamic: "no",
    type: "Tests",
    Component: Test,
  },
  {
    path: "/tests/:testID/edit/patient/:patientID",
    name: "Edit Test Patients",
    dynamic: "yes",
    type: "Patients",
    Component: PatientsDetail,
  },

  {
    path: "/providers",
    name: "Internal Providers",
    dynamic: "no",
    type: "Providers",
    Component: Provider,
  },

  {
    path: "/users",
    name: "Users",
    dynamic: "no",
    type: "Users",
    Component: Users,
  },

  {
    path: "/billing",
    name: "Billing",
    dynamic: "no",
    type: "Billing",
    Component: Billing,
  },
  {
    path: "/equipments",
    name: "Equipments",
    dynamic: "no",
    type: "Equipments",
    Component: Equipments,
  },
  {
    path: "/external-providers",
    name: "External Providers",
    dynamic: "no",
    type: "External Providers",
    Component: ExternalProviders,
  },
  {
    path: "/edit/external-providers/:providerId",
    name: "External Providers",
    dynamic: "yes",
    type: "External Providers",
    Component: PrepaidPatients,
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    dynamic: "no",
    type: "ContactUs",
    Component: ContactUs,
  },
];
export default paths;
