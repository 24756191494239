import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterClose from "../../assets/filter-close.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BilleeInvoicesModal from "../Corporate/Modals/FiltersModal/BilleeInvoicesModal";
import { useParams, useHistory } from "react-router-dom";
import TableEditBody from "../Communities/Table/TableEditBody";

import { rowOrderActions } from "../../store/orders";
import {
  removeEmptyParams,
  setOrderAndIcon,
} from "../../services/helpers/sorting.hepler";
import Loader from "../UI/loader/loader";
import SearchInput from "../General/filters/SearchInput.Component";
import FilterButton from "../General/filters/FilterButton.Component";
import useQueryString from "../../hooks/useQueryString";
import Pagination from "../UI/Pagination/Pagination";
import Select, { components } from "react-select";
import { getWithExpiry } from "../utils/helper";
import { filterActions } from "../../store/filter";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { equipmentsActions } from "../../store/equipments";

const TableDataEdit = (props) => {
  const {
    tableHeadings,
    data,
    type,
    dataType,
    communityName,
    heading,
    totalRecords,
    setDataFlag,
    setShowToast,
    otherLoader,
    setOtherLoader,
    billingData,
  } = props;
  const [importCorpModalOpen, setImportCorpModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const token = "Bearer " + getWithExpiry("expiry_token");

  const showImportCorpModal = () => setImportCorpModalOpen(true);
  const hideImportCorpModal = () => setImportCorpModalOpen(false);

  const showFilterModal = () => setFilterModalOpen(true);
  const hideFilterModal = () => setFilterModalOpen(false);

  const parm = useParams();
  const history = useHistory();
  const urlParams = useQueryString();

  const dispatch = useDispatch();

  // move to separate file later

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [loading, setLoading] = useState(false);
  const [headingName, setHeadingName] = useState("");
  const [search, setSearch] = useState(false);
  const handleSearchChange = (e) => {
    const { value, name } = e.target;

    if (type === "Equipments" && dataType === "equipastInvoices") {
      dispatch(filterActions.setSearchInvoicesBillingText(value));
    } else if (type === "Equipments" && dataType === "Equipments") {
      dispatch(filterActions.setSearchEquipmentsText(value));
    }
  };

  const invoicesEquipmentsOrderMapping = {
    "Invoice #": "invoice_id",
    "Invoice Code": "invoice_code",
    "Invoice Due Date": "due_date",
    "Community Name": "community_name",
    "Billee Name": "billee_name",
    "Billee Company": "billee_company",
    "Invoice Date": "invoice_date",
    "Invoice Status": "status",
    "Amount Due": "total",
    // "Community Name": "community_id",
    "Billee Address": "billee_address",
  };

  const equipmentsOrderMapping = {
    "Community ID": "community_id",
    "Community Name": "community_name",
    "Billee Name": "billee_name",
    "Billee Company": "billee_company",
    "Equipment ID": "equipment_id",
    "Equipment Price": "equipment_price",
  };

  const searchInvoicesBillingText = useSelector(
    (state) => state.filter.searchInvoicesBillingText
  );

  const searchEquipmentsText = useSelector(
    (state) => state.filter.searchEquipmentsText
  );

  const rowOrderStateEquipmentsPastInvoices = useSelector(
    (state) => state.rowOrder.rowOrderEquipmentsPastInvoices
  );
  const rowOrderEquipments = useSelector(
    (state) => state.rowOrder.rowOrderEquipments
  );
  const invoicesEquipmentsOrderQuery = useSelector(
    (state) => state.equipments.invoicesEquipmentsOrderQuery
  );

  const pastInvoicesCommunities = useSelector(
    (state) => state.filter.pastInvoicesCommunities
  );
  const filterBillingStatus = useSelector(
    (state) => state.filter.filterBillingStatus
  );

  const [orderState, setOrderState] = useState(null);
  const [prefix, setPrefix] = useState("");

  const orderMapping = {
    equipastInvoices: invoicesEquipmentsOrderMapping,
    Equipments: equipmentsOrderMapping,
  };
  const rowOrderMapping = {
    equipastInvoices: rowOrderStateEquipmentsPastInvoices,
    Equipments: rowOrderEquipments,
  };
  useEffect(() => {
    updateUrl();
  }, [orderState]);

  const buildRowOrderQuery = () => {
    let newOrderQuery = "";
    if (dataType && dataType === "equipastInvoices") {
      newOrderQuery = invoicesEquipmentsOrderQuery.slice();
    } else if (dataType && dataType === "Equipments") {
      // newOrderQuery = creditHistoryOrderQuery.slice();
    }

    let mappingHeaderName, rowOrderHeaderName;
    if (type === "Equipments") {
      if (dataType === "equipastInvoices") {
        mappingHeaderName = invoicesEquipmentsOrderMapping[headingName];
        rowOrderHeaderName =
          rowOrderStateEquipmentsPastInvoices[
            invoicesEquipmentsOrderMapping[headingName]
          ];
      } else if (type === "Equipments" && dataType === "Equipments") {
        mappingHeaderName = equipmentsOrderMapping[headingName];
        rowOrderHeaderName =
          rowOrderEquipments[equipmentsOrderMapping[headingName]];
      }
    }

    if (mappingHeaderName) {
      let prevOrder = rowOrderHeaderName.order === "asc" ? "desc" : "asc";
      let prevInstance = `&${mappingHeaderName}:orderby=${prevOrder}`;
      if (newOrderQuery.includes(prevInstance)) {
        newOrderQuery = newOrderQuery.replace(prevInstance, "");
      }
      if (!search) {
        newOrderQuery = `&${mappingHeaderName}:orderby=${rowOrderHeaderName.order}`;
      }
    }
    if (dataType && dataType === "equipastInvoices") {
      dispatch(
        equipmentsActions.setInvoicesEquipmentsOrderQuery(newOrderQuery)
      );
    } else if (type === "Equipments" && dataType && dataType === "Equipments") {
      dispatch(equipmentsActions.setEquipmentOrderQuery(newOrderQuery));
    }

    return newOrderQuery;
  };

  const buildFilterQuery = () => {
    let filterQuery = "";

    return filterQuery;
  };

  const buildSearchTextQuery = () => {
    let searchQuery = "";
    if (type === "Equipments" && dataType === "equipastInvoices") {
      searchQuery =
        searchInvoicesBillingText !== ""
          ? `search_query=${searchInvoicesBillingText}`
          : "";
    } else if (type === "Equipments" && dataType === "Equipments") {
      searchQuery =
        searchEquipmentsText !== ""
          ? `search_query=${searchEquipmentsText}`
          : "";
    }
    return searchQuery;
  };
  const getPrefix = () => {
    let newPrefix = prefix;

    return newPrefix;
  };

  const updateUrl = () => {
    const searchQuery = buildSearchTextQuery();
    const newOrderQuery = buildRowOrderQuery();
    const filterQuery = buildFilterQuery();
    let customQuery = "";
    customQuery = `${searchQuery}${newOrderQuery}&${filterQuery}`;

    let modifiedString = removeEmptyParams(customQuery);

    let urlParamString = urlParams.toString();

    const newPrefix = getPrefix();
    let searchParam = "";

    if (!modifiedString) {
      searchParam =
        "?" +
        `${
          search === true
            ? newPrefix +
              modifiedString +
              `page=${search ? 1 : urlParams.get("page")}`
            : newPrefix + urlParamString
        }`;
    } else {
      if (
        !modifiedString.includes("page") &&
        urlParams.toString().includes("page")
      ) {
        searchParam =
          "?" +
          newPrefix +
          modifiedString +
          `&page=${search ? 1 : urlParams.get("page")}`;
      } else {
        searchParam = "?" + newPrefix + modifiedString;
      }
    }

    searchParam = searchParam.replace("&&", "&");
    history.replace({
      // pathname: urlMapping[dataType],
      search: searchParam,
    });

    let str =
      modifiedString && modifiedString !== ""
        ? "?" +
          `${
            dataType === "Equipments"
              ? "tab_key=equipments&"
              : "tab_key=invoice&"
          }` +
          modifiedString
        : "";

    if (dataType && dataType === "equipastInvoices") {
      dispatch(equipmentsActions.setInvoicesEquipmentsOrderQuery(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    } else if (dataType && dataType === "Equipments") {
      dispatch(equipmentsActions.setEquipmentOrderQuery(str));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    }
  };

  const onClickTableHeading = async (e) => {
    const { id } = e.target;

    if (id === "Actions" || id === "View") return;
    setOtherLoader(true);
    setSearch(false);
    setHeadingName(id);

    let prefix = "";
    if (type === "Equipments") {
      if (dataType && dataType === "equipastInvoices") {
        setPrefix("tab_key=invoice&");
        prefix = "table=upcoming_test&";
      } else if (dataType && dataType === "Equipments") {
        setPrefix("tab_key=equipments&");
        // prefix = "table=upcoming_test&";
      }
    }

    let name = "";
    let obj;
    if (type === "Equipments") {
      if (dataType === "Equipments") {
        switch (id) {
          case "Community Name":
            name = "community_name";

            obj = setOrderAndIcon(rowOrderEquipments[name]);
            dispatch(
              rowOrderActions.setRowOrderEquipments({
                ...rowOrderEquipments,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                equipment_price: { state: 1, order: "", icon: "" },
                equipment_id: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;

          case "Billee Name":
            name = "billee_name";
            obj = setOrderAndIcon(rowOrderEquipments[name]);
            dispatch(
              rowOrderActions.setRowOrderEquipments({
                ...rowOrderEquipments,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                billee_company: { state: 1, order: "", icon: "" },
                equipment_price: { state: 1, order: "", icon: "" },
                equipment_id: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Billee Company":
            name = "billee_company";
            obj = setOrderAndIcon(rowOrderEquipments[name]);
            dispatch(
              rowOrderActions.setRowOrderEquipments({
                ...rowOrderEquipments,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                billee_name: { state: 1, order: "", icon: "" },
                equipment_price: { state: 1, order: "", icon: "" },
                equipment_id: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;

          case "Equipment Price":
            name = "equipment_price";

            obj = setOrderAndIcon(rowOrderEquipments[name]);
            dispatch(
              rowOrderActions.setRowOrderEquipments({
                ...rowOrderEquipments,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                equipment_id: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Equipment ID":
            name = "equipment_id";

            obj = setOrderAndIcon(rowOrderEquipments[name]);
            dispatch(
              rowOrderActions.setRowOrderEquipments({
                ...rowOrderEquipments,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                equipment_price: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
        }
      } else if (dataType === "equipastInvoices") {
        switch (id) {
          case "Community Name":
            name = "community_name";
            obj = setOrderAndIcon(rowOrderStateEquipmentsPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderEquipmentsPastInvoicesOrder({
                ...rowOrderStateEquipmentsPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Invoice #":
            name = "invoice_id";
            obj = setOrderAndIcon(rowOrderStateEquipmentsPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderEquipmentsPastInvoicesOrder({
                ...rowOrderStateEquipmentsPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Invoice Code":
            name = "invoice_code";
            obj = setOrderAndIcon(rowOrderStateEquipmentsPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderEquipmentsPastInvoicesOrder({
                ...rowOrderStateEquipmentsPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                community_name: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Billee Name":
            name = "billee_name";
            obj = setOrderAndIcon(rowOrderStateEquipmentsPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderEquipmentsPastInvoicesOrder({
                ...rowOrderStateEquipmentsPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Billee Company":
            name = "billee_company";
            obj = setOrderAndIcon(rowOrderStateEquipmentsPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderEquipmentsPastInvoicesOrder({
                ...rowOrderStateEquipmentsPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Invoice Date":
            name = "invoice_date";
            obj = setOrderAndIcon(rowOrderStateEquipmentsPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderEquipmentsPastInvoicesOrder({
                ...rowOrderStateEquipmentsPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Invoice Due Date":
            name = "due_date";
            obj = setOrderAndIcon(rowOrderStateEquipmentsPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderEquipmentsPastInvoicesOrder({
                ...rowOrderStateEquipmentsPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Amount Due":
            name = "total";
            obj = setOrderAndIcon(rowOrderStateEquipmentsPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderEquipmentsPastInvoicesOrder({
                ...rowOrderStateEquipmentsPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                status: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Invoice Status":
            name = "status";
            obj = setOrderAndIcon(rowOrderStateEquipmentsPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderEquipmentsPastInvoicesOrder({
                ...rowOrderStateEquipmentsPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                community_name: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });
            return;
          case "Community Name":
            name = "community_id";
            obj = setOrderAndIcon(rowOrderStateEquipmentsPastInvoices[name]);
            dispatch(
              rowOrderActions.setRowOrderEquipmentsPastInvoicesOrder({
                ...rowOrderStateEquipmentsPastInvoices,
                [name]: { state: obj.state, order: obj.order, icon: obj.icon },
                invoice_code: { state: 1, order: "", icon: "" },
                billee_name: { state: 1, order: "", icon: "" },
                billee_company: { state: 1, order: "", icon: "" },
                invoice_date: { state: 1, order: "", icon: "" },
                due_date: { state: 1, order: "", icon: "" },
                invoice_id: { state: 1, order: "", icon: "" },
                total: { state: 1, order: "", icon: "" },
                billee_address: { state: 1, order: "", icon: "" },
              })
            );
            setOrderState({
              ...orderState,
              [name]: { state: obj.state, order: obj.order, icon: obj.icon },
            });

            return;
          default:
            return;
        }
      }
    }
    return;
  };

  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      setSearch(true);
      setOtherLoader(true);
      if (type === "Equipments") {
        if (dataType && dataType === "equipastInvoices") {
          setPrefix("tab_key=invoice&");
        } else if (dataType && dataType === "Equipments") {
          setPrefix("tab_key=equipments&");
          setDataFlag((prevState) => !prevState);
        }
      }
      setOrderState({
        ...orderState,
      });
    }
  };
  const onClearSorting = () => {
    setOtherLoader(true);
    if (dataType && dataType === "equipastInvoices") {
      dispatch(
        rowOrderActions.setRowOrderEquipmentsPastInvoicesOrder({
          ...rowOrderStateEquipmentsPastInvoices,
          invoice_id: { state: 1, order: "", icon: "" },
          invoice_date: { state: 1, order: "", icon: "" },
          due_date: { state: 1, order: "", icon: "" },
          status: { state: 1, order: "", icon: "" },
          total: { state: 1, order: "", icon: "" },
        })
      );
      dispatch(equipmentsActions.setInvoicesEquipmentsOrderQuery(""));
      history.replace({
        pathname: "/billing",
        search: "?page=1",
      });
      dispatch(equipmentsActions.setInvoicesBillingApiUrl(""));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
    }
  };
  const onClearSearch = () => {
    setOtherLoader(true);
    if (type === "Equipments" && dataType === "equipastInvoices") {
      dispatch(filterActions.setSearchInvoicesBillingText(""));
      dispatch(equipmentsActions.setInvoicesEquipmentsOrderQuery(""));
    } else if (type === "Equipments" && dataType === "Equipments") {
      dispatch(filterActions.setSearchEquipmentsText(""));
    }
    setSearch(true);
    setOrderState({
      ...orderState,
    });
  };

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const billeeObject = () => {
    if (dataType === "equipastInvoices") {
      const newEvent = billingData?.all_communities?.reduce(
        (results, event) => {
          if (pastInvoicesCommunities.includes(event.community_id)) {
            results.push({
              value: event.community_id,
              label: event.community_name,
            });
          }
          return results;
        },
        []
      );
      return newEvent;
    } else {
      const newEvent = billingData.all_communities.reduce((results, event) => {
        return results;
      }, []);
      return newEvent;
    }
  };
  const removeSelectedCommunity = (community) => {
    if (dataType === "equipastInvoices") {
      if (pastInvoicesCommunities.length === 1) {
        dispatch(filterActions.setFilterBillingCommunities("ALL"));
        dispatch(filterActions.setPastInvoicesCommunities("ALL"));
        history.replace({
          pathname: "/billing",
          search: "",
        });
      } else {
        const updatedList = pastInvoicesCommunities.filter(
          (item) => item !== community.value
        );
        dispatch(filterActions.setFilterBillingCommunities(updatedList));
        dispatch(filterActions.setPastInvoicesCommunities(updatedList));
      }
    }

    setOrderState({
      ...orderState,
    });
  };
  const removeSelectedStatus = (status) => {
    if (filterBillingStatus.length === 1) {
      dispatch(filterActions.setFilterBillingStatus([]));
      history.replace({
        pathname: "/billing",
        search: "",
      });
    } else {
      const updatedList = filterBillingStatus.filter((item) => item !== status);
      dispatch(filterActions.setFilterBillingStatus(updatedList));
    }
    setOrderState({
      ...orderState,
    });
  };
  const clearFilters = () => {
    if (dataType === "equipastInvoices") {
      dispatch(filterActions.setFilterBillingCommunities("ALL"));
      dispatch(filterActions.setPastInvoicesCommunities("ALL"));
      dispatch(filterActions.setFilterBillingStatus("ALL"));
    } else {
      dispatch(filterActions.setFilterBillingCommunities("ALL"));
      dispatch(filterActions.setCreditHistoryCommunities("ALL"));
    }

    setOrderState({
      ...orderState,
    });
  };
  const paramType = !dataType ? type : dataType;
  return (
    <Fragment>
      {filterModalOpen &&
        type === "Equipments" &&
        dataType === "equipastInvoices" && (
          <BilleeInvoicesModal
            isShowModal={filterModalOpen}
            hideModal={hideFilterModal}
            billingData={billingData}
            setShowToast={setShowToast}
            setDataFlag={setDataFlag}
            orderBy={invoicesEquipmentsOrderQuery}
            setOtherLoader={setOtherLoader}
            searchText={searchInvoicesBillingText}
          />
        )}

      {otherLoader || loading ? (
        <Loader />
      ) : (
        <div className="corpoarte-table">
          <div className="table-header-filter">
            <div className="cor-header-change">
              <h1 className="page-name-heading">{heading}</h1>
              {dataType !== "history" && (
                <div className="right-cor-header">
                  <ul className="rc-header-listing">
                    {/* {dataType === "equipastInvoices" && (
                      <>
                        <li className="single-rc">
                          <div className="date-time-wrapper">
                            <DatePicker
                              className="custom-dateTime-input"
                              placeholderText="Select your Date Range"
                              isClearable
                              selectsRange={true}
                              startDate={startDate}
                              endDate={endDate}
                              onChange={(update) => {
                                setDateRange(update);
                              }}
                              dateFormat="MM/dd/yyyy"
                              maxDate={new Date()}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              disabledKeyboardNavigation

                              // isClearable={true}
                            />
                            <span className="dateTime-divider"></span>
                          </div>
                        </li>
                        <li className="single-rc">
                          <button
                            className="btn btn-primary-fill btn-primary-16"
                            // onClick={downloadReport}
                          >
                            Send Invoice Report
                          </button>
                        </li>
                      </>
                    )} */}
                    {/* <li className="single-rc">
                    <button onClick={onClearSorting}>Clear Sorting</button>
                  </li> */}
                    {/* <li className="single-rc">
                    <button onClick={onClearSearch}>Clear Search</button>
                  </li> */}
                    <li className="single-rc">
                      {type === "Equipments" &&
                        dataType === "equipastInvoices" && (
                          <SearchInput
                            value={searchInvoicesBillingText}
                            handleChange={handleSearchChange}
                            onKeyEnter={onEnterPress}
                            onClearSearch={onClearSearch}
                          />
                        )}

                      {type === "Equipments" && dataType === "Equipments" && (
                        <SearchInput
                          value={searchEquipmentsText}
                          handleChange={handleSearchChange}
                          onKeyEnter={onEnterPress}
                          onClearSearch={onClearSearch}
                        />
                      )}
                    </li>
                    {/* {type === "Equipments" && dataType != "Equipments" && (
                      <li className="single-rc">
                        <FilterButton handleClick={showFilterModal} />
                      </li>
                    )} */}
                  </ul>
                </div>
              )}
            </div>

            {type === "Equipments" && dataType === "equipastInvoices" && (
              <>
                {((pastInvoicesCommunities.length !== 0 &&
                  pastInvoicesCommunities !== "ALL") ||
                  (filterBillingStatus?.length !== 0 &&
                    filterBillingStatus !== [])) && (
                  <div className="filter-header">
                    <h2 className="apply-filter-name">Applied Filters:</h2>
                    <ul className="apply-filter-list">
                      {billeeObject()?.map((community) => (
                        <li className="apply-filter-single">
                          <h3 className="filter-name-type">
                            Community: <strong>{community.label}</strong>{" "}
                          </h3>
                          <img
                            src={FilterClose}
                            alt=""
                            onClick={() => removeSelectedCommunity(community)}
                          />
                        </li>
                      ))}
                      {filterBillingStatus?.map((status) => (
                        <li className="apply-filter-single">
                          <h3 className="filter-name-type">
                            Invoice: <strong>{status}</strong>{" "}
                          </h3>
                          <img
                            src={FilterClose}
                            alt=""
                            onClick={() => removeSelectedStatus(status)}
                          />
                        </li>
                      ))}
                    </ul>
                    <Link
                      to={"/billing"}
                      className="clear-all-filter"
                      onClick={clearFilters}
                    >
                      Clear all filters
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="table-responsive">
            <table className="table" width="en-width">
              <thead>
                <tr>
                  {tableHeadings &&
                    tableHeadings?.map((tableHead) => (
                      <th
                        id={tableHead}
                        name={tableHead}
                        key={tableHead}
                        onClick={onClickTableHeading}
                      >
                        {tableHead}
                        {tableHead !== "Actions" && tableHead !== "View" && (
                          <>
                            {rowOrderMapping[paramType][
                              orderMapping[paramType][tableHead]
                            ]?.icon === "" ? (
                              <span className="icons-th">
                                <span></span>
                                <span className="order-4"></span>
                              </span>
                            ) : (
                              <span className="icons-th">
                                <span
                                  className={
                                    rowOrderMapping[paramType][
                                      orderMapping[paramType][tableHead]
                                    ]?.icon === "asc"
                                      ? "caret-4-asc-icon"
                                      : "caret-4-desc-icon"
                                  }
                                ></span>
                                <span className="order-4"></span>
                              </span>
                            )}
                          </>
                        )}
                      </th>
                    ))}
                </tr>
              </thead>
              <TableEditBody
                type={type}
                data={data}
                dataType={dataType}
                deleteCorporate={props.deleteCorporate}
                communityName={communityName}
                setDataFlag={setDataFlag}
                setShowToast={setShowToast}
              />
            </table>
          </div>

          {type === "Equipments" && dataType === "equipastInvoices" && (
            <Pagination
              dataType={dataType}
              type={type}
              data={data}
              totalRecords={totalRecords}
              parm={parm.id}
              prefix={"tab_key=invoice&"}
              setOtherLoader={setOtherLoader}
            />
          )}

          {type === "Equipments" && dataType === "Equipments" && (
            <Pagination
              dataType={dataType}
              type={type}
              data={data}
              totalRecords={totalRecords}
              parm={parm.id}
              prefix={"tab_key=credit_history&"}
              setOtherLoader={setOtherLoader}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};

export default TableDataEdit;

const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    borderRadius: "4px",
    fontFamily: "Open Sans",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 3;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};
