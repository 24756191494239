import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import CustomSelect from "../../../General/filters/CustomSelect.Component";
import { filterActions } from "../../../../store/filter";
import { removeEmptyParams } from "../../../../services/helpers/sorting.hepler";
import { patientActions } from "../../../../store/patient";
import { getWithExpiry } from "../../../utils/helper";
import DatePicker from "react-datepicker";
import axios from "axios";
import Moment from "moment";
import useQueryString from "../../../../hooks/useQueryString";
import "./FiltersModal.scss";
// imports image

import CloseModal from "../../../../assets/close-outline.svg";
import DisabledIcon from "../../../../assets/disabled-row.svg";

function FilterModal(props) {
  const {
    isShowModal,
    hideModal,
    orderBy,
    setDataFlag,
    setShowToast,
    setOtherLoader,
    dateRange,
    setDateRange,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const urlParams = useQueryString();
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(true);

  // const [dateRange, setDateRange] = useState([null, null]);
  //const [startDate, endDate] = dateRange;
  const [startDate, setStartDate] = useState("");

  const searchFilteredText = useSelector((state) => state.filter.filterText);
  const filteredDobFrom = useSelector((state) => state.filter.filteredDobFrom);
  const filteredDobTo = useSelector((state) => state.filter.filteredDobTo);

  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedZip, setSelectedZip] = useState([]);
  const [selectedfilter, setSelectedFilter] = useState("");

  const token = getWithExpiry("expiry_token");

  function ISOtoLongDate(isoString, locale = "en-US") {
    const options = { month: "numeric", day: "numeric", year: "numeric" };
    const date = new Date(isoString);
    const longDate = new Intl.DateTimeFormat(locale, options).format(date);
    return longDate;
  }
  const filterSubmissionHandler = () => {
    if (startDate) {
      setOtherLoader(true);
      let newDate1 = ISOtoLongDate(startDate.toISOString(), "en-GB");
      //let newDate2 = ISOtoLongDate(endDate.toISOString(), "en-GB");
      let dob_from = Moment(newDate1, "DD/MM/YYYY").format("MM-DD-YYYY");
      //let dob_to = Moment(newDate2, "DD/MM/YYYY").format("MM-DD-YYYY");
      let customQuery = `search_query=${searchFilteredText}&${orderBy}&dob_from:eq=${dob_from}`;
      let searchParam = "";
      var modifiedString = removeEmptyParams(customQuery);
      if (
        !modifiedString.includes("page") &&
        urlParams.toString().includes("page")
      ) {
        searchParam = "?" + modifiedString + `&page=${1}`;
      } else {
        searchParam = "?" + modifiedString;
      }
      searchParam = searchParam.replace("&&", "&");
      history.replace({
        pathname: "/patients",
        search: searchParam,
      });

      dispatch(filterActions.setFilteredDobFrom(dob_from));
      //dispatch(filterActions.setFilteredDobTo(dob_to));
      dispatch(patientActions.setPatientApiUrl("?" + modifiedString));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
      hideModal();
    }
  };
  const clearFilters = () => {
    let searchParam = "";
    var modifiedString = "";
    if (
      !modifiedString.includes("page") &&
      urlParams.toString().includes("page")
    ) {
      searchParam = "?" + modifiedString + `&page=${1}`;
    } else {
      searchParam = "?" + modifiedString;
    }
    searchParam = searchParam.replace("&&", "&");
    history.replace({
      pathname: "/patients",
      search: searchParam,
    });

    dispatch(filterActions.setFilteredDobFrom([]));
    dispatch(filterActions.setFilteredDobTo([]));
    setDateRange([null, null]);
    dispatch(patientActions.setPatientApiUrl("?" + modifiedString));
    setShowToast(true);
    setDataFlag((prevState) => !prevState);
    hideModal();
  };

  return (
    <div className="filter-modal-wrapper">
      <Modal
        className="filter-modal initiate-test-patient-modal modal-loader"
        show={true}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
      >
        <Modal.Header className="filter-modal-header">
          <h1 className="modal-header-title">Filters</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="close-modal" />
          </span>
        </Modal.Header>
        <Modal.Body className="filter-modal-body show-select">
          <form
          
            method="post"
            className="add-corporate-form"
            // onSubmit={initiateTestSubmissionHandler}
          >
            <div className="field-wrapper">
              <label htmlFor="scheduled" className="label">
                Date of Birth
              </label>
              <div className="date-time-wrapper">
                <DatePicker
                  className="custom-dateTime-input"
                  placeholderText="Select date of birth"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  // onChangeRaw={(e) => {
                  //   e.preventDefault();
                  // }}
                  dateFormat="MM/dd/yyyy"
                  maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                {/* <DatePicker
                  className="custom-dateTime-input"
                  placeholderText="Select your Date Range"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  dateFormat="MM/dd/yyyy"
                  maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  // isClearable={true}
                /> */}
                <span className="dateTime-divider"></span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16"
            onClick={clearFilters}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary-fill btn-primary-16"
            onClick={filterSubmissionHandler}
          >
            Apply Filter
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FilterModal;
