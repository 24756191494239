import { createSlice } from "@reduxjs/toolkit";

const initialTestState = {
  testInfo: "",
  testList: [],
  PageNumber: 1,
  upcomingTestList: [],
  scheduleTestList: [],

  upcomingTestPageNumbers: 1,
  upcomingTestCurrentPage: 1,
  upcomingTestTotalRecords: 0,
  scheduleTestPageNumbers: 1,
  scheduleTestCurrentPage: 1,
  scheduleTestTotalRecords: 0,

  testTotalRecords: 0,
  testCurrentPage: 1,

  testsToScheduleApiUrl: "",
  upcomingTestsApiUrl: "",
  scheduleOrderQuery: "",
  upcomingOrderQuery: "",

  scheduleFilteredDobFrom: "",
  scheduleFilteredDobTo: "",
  scheduleFilteredLastDataFrom: "",
  scheduleFilteredLastDataTo: "",

  upcomingFilteredDobFrom: "",
  upcomingFilteredDobTo: "",
  upcomingFilteredScheduledDateFrom: "",
  upcomingFilteredScheduledDateTo: "",
};

const testSlice = createSlice({
  name: "test",
  initialState: initialTestState,
  reducers: {
    setScheduleFilteredDobFrom(state, action) {
      state.scheduleFilteredDobFrom = action.payload;
    },
    setScheduleFilteredDobTo(state, action) {
      state.scheduleFilteredDobTo = action.payload;
    },
    setScheduleFilteredLastDataFrom(state, action) {
      state.scheduleFilteredLastDataFrom = action.payload;
    },
    setScheduleFilteredLastDataTo(state, action) {
      state.scheduleFilteredLastDataTo = action.payload;
    },

    setUpcomingFilteredDobFrom(state, action) {
      state.upcomingFilteredDobFrom = action.payload;
    },
    setUpcomingFilteredDobTo(state, action) {
      state.upcomingFilteredDobTo = action.payload;
    },
    setUpcomingFilteredScheduledDateFrom(state, action) {
      state.upcomingFilteredScheduledDateFrom = action.payload;
    },
    setUpcomingFilteredScheduledDateTo(state, action) {
      state.upcomingFilteredScheduledDateTo = action.payload;
    },

    setUpcomingOrderQuery(state, action) {
      state.upcomingOrderQuery = action.payload;
    },
    setScheduleOrderQuery(state, action) {
      state.scheduleOrderQuery = action.payload;
    },

    setUpcomingTestPageNumbers(state, action) {
      state.upcomingTestPageNumbers = action.payload;
    },
    setUpcomingTestCurrentPage(state, action) {
      state.upcomingTestCurrentPage = action.payload;
    },
    setUpcomingTestTotalRecords(state, action) {
      state.upcomingTestTotalRecords = action.payload;
    },
    setUpcomingTestApiUrl(state, action) {
      state.upcomingTestsApiUrl = action.payload;
    },

    setScheduleTestPageNumbers(state, action) {
      state.scheduleTestPageNumbers = action.payload;
    },
    setScheduleTestCurrentPage(state, action) {
      state.scheduleTestCurrentPage = action.payload;
    },
    setScheduleTestTotalRecords(state, action) {
      state.scheduleTestTotalRecords = action.payload;
    },
    setScheduleTestApiUrl(state, action) {
      state.testsToScheduleApiUrl = action.payload;
    },

    setTestCurrentPage(state, action) {
      state.testCurrentPage = action.payload;
    },

    setCreateTestInfo(state, action) {
      state.testInfo = action.payload;
    },
    setTestList(state, action) {
      state.testList = action.payload;
    },
    setUpcomingTestList(state, action) {
      state.upcomingTestList = action.payload;
    },
    setScheduleTestList(state, action) {
      state.scheduleTestList = action.payload;
    },
    setTestTotalRecords(state, action) {
      state.testTotalRecords = action.payload;
    },
  },
});

export const testActions = testSlice.actions;

export default testSlice.reducer;
