import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import CustomSelect from "../../../General/filters/CustomSelect.Component";
import { filterActions } from "../../../../store/filter";
import { communityActions } from "../../../../store/community";
import { removeEmptyParams } from "../../../../services/helpers/sorting.hepler";
import { patientActions } from "../../../../store/patient";
import { getWithExpiry } from "../../../utils/helper";
import DatePicker from "react-datepicker";
import axios from "axios";
import Moment from "moment";
import useQueryString from "../../../../hooks/useQueryString";
import "./FiltersModal.scss";
// imports image

import CloseModal from "../../../../assets/close-outline.svg";
import DisabledIcon from "../../../../assets/disabled-row.svg";

function FilterModal(props) {
  const {
    isShowModal,
    hideModal,
    orderBy,
    setDataFlag,
    setShowToast,
    dateRange,
    setDateRange,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const urlParams = useQueryString();
  const { communityID } = useParams();
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [startDate, endDate] = dateRange;

  const searchFilteredText = useSelector(
    (state) => state.filter.searchCommunityPatientText
  );
  const filteredDobFrom = useSelector(
    (state) => state.community.communityPatientDobFrom
  );
  const filteredDobTo = useSelector(
    (state) => state.community.communityPatientDobFrom
  );


  const filterSubmissionHandler = () => {
    if (startDate && endDate) {
      let dob_from = Moment(startDate.toLocaleDateString()).format(
        "MM-DD-YYYY"
      );
      let dob_to = Moment(endDate.toLocaleDateString()).format("MM-DD-YYYY");
      let customQuery = `search_query=${searchFilteredText}&${orderBy}&dob_from:eq=${dob_from}&dob_to:eq=${dob_to}`;

      var modifiedString = removeEmptyParams(customQuery);
      let prefix = "";
      let searchParam = "";
      if (modifiedString || urlParams.toString().includes("page")) {
        prefix = "tab_key=patient&";
      }
      if (
        !modifiedString.includes("page") &&
        urlParams.toString().includes("page")
      ) {
        searchParam = "?" + prefix + modifiedString + `&page=${1}`;
      } else {
        searchParam = "?" + prefix + modifiedString;
      }
      searchParam = searchParam.replace("&&", "&");
      history.replace({
        pathname: `/edit/communities/${communityID}`,
        search: searchParam,
      });

      dispatch(
        communityActions.setPatientCommunityApiUrl(
          "?tab_key=patient&" + modifiedString
        )
      );
      dispatch(communityActions.setCommunityPatientDobFrom(dob_from));
      dispatch(communityActions.setCommunityPatientDobTo(dob_to));
      setShowToast(true);
      setDataFlag((prevState) => !prevState);
      hideModal();
    }
  };

  const clearFilters = () => {
    var modifiedString = "";
    let prefix = "";
    let searchParam = "";
    if (modifiedString || urlParams.toString().includes("page")) {
      prefix = "tab_key=patient&";
    }
    if (
      !modifiedString.includes("page") &&
      urlParams.toString().includes("page")
    ) {
      searchParam = "?" + prefix + modifiedString + `&page=${1}`;
    } else {
      searchParam = "?" + prefix + modifiedString;
    }
    searchParam = searchParam.replace("&&", "&");
    history.replace({
      pathname: `/edit/communities/${communityID}`,
      search: searchParam,
    });

    dispatch(
      communityActions.setPatientCommunityApiUrl(
        "?tab_key=patient&" + modifiedString
      )
    );
    dispatch(communityActions.setCommunityPatientDobFrom([]));
    dispatch(communityActions.setCommunityPatientDobTo([]));
    setDateRange([null, null]);
    setShowToast(true);
    setDataFlag((prevState) => !prevState);
    hideModal();
  };

  return (
    <div className="filter-modal-wrapper">
      <Modal
        className="filter-modal initiate-test-patient-modal modal-loader"
        show={true}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
      >
        <Modal.Header className="filter-modal-header">
          <h1 className="modal-header-title">Filters</h1>
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="close-modal" />
          </span>
        </Modal.Header>
        <Modal.Body className="filter-modal-body show-select">
          <form
          
            method="post"
            className="add-corporate-form"
            // onSubmit={initiateTestSubmissionHandler}
          >
            <div className="field-wrapper">
              <label htmlFor="scheduled" className="label">
                Date of Birth
              </label>
              <div className="date-time-wrapper">
                <DatePicker
                  className="custom-dateTime-input"
                  placeholderText="Select your Date Range"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  dateFormat="MM/dd/yyyy"
                  maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  // isClearable={true}
                />
                <span className="dateTime-divider"></span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="addmisnis-footer-modal">
          <button
            className="btn btn-secondary-fill btn-primary-16"
            onClick={clearFilters}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary-fill btn-primary-16"
            onClick={filterSubmissionHandler}
          >
            Apply Filter
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FilterModal;
