import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// import images
import Filter_Icon from "../../../assets/filter-icon.svg";

import AddCorporateModal from "../../Corporate/Modals/AddCorporateModal/AddCorporateModal";
import AddUserModal from "../../Corporate/Modals/AddUserModal/AddUserModal";
import ImportUserModal from "../../Users/ImportUserModal";
import ImportCorporateModal from "../../Corporate/Modals/ImportCorporateModal/ImportCorporateModal";
import PatientHistoryFiltersModal from "../../Corporate/Modals/FiltersModal/PatientHistoryFilter";
import ScheduleFiltersModal from "../../Corporate/Modals/FiltersModal/ScheduleTestFilter";
import UpcomingFiltersModal from "../../Corporate/Modals/FiltersModal/UpcomingTestFilter";
import FilterClose from "../../../assets/filter-close.svg";
import AddPatient from "../../Patient/Modals/AddPatient";
import AddProvider from "../../Provider/Modals/AddProvider";
import { useParams, useHistory, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getWithExpiry } from "../../utils/helper";
import TableBody from "./TableBody";
import "./Table.scss";
import {
  adminUserModalFields,
  regularUserModalFields,
} from "../../Corporate/Modals/ImportCorporateModal/utils";
import { providerActions } from "../../../store/provider";
import Pagination from "../Pagination/Pagination";
import { filterActions } from "../../../store/filter";
import { rowOrderActions } from "../../../store/orders";
import { patientActions } from "../../../store/patient";
import { testActions } from "../../../store/test";
import { RegUserActions } from "../../../store/regUser";
import { AdminUserActions } from "../../../store/adminUser";
import AuditLogsModal from "../../../components/General/AuditLogModal/AuditLogsModal.Component";
import AuditLogButton from "../../General/AuditLogModal/AuditLogButton.Component";
import useQueryString from "../../../hooks/useQueryString";
import {
  removeEmptyParams,
  setOrderAndIcon,
} from "../../../services/helpers/sorting.hepler";
import SearchInput from "../../General/filters/SearchInput.Component";
import FilterButton from "../../General/filters/FilterButton.Component";
import Loader from "../loader/loader";
const TableData = (props) => {
  const {
    tableHeadings,
    data,
    type,
    subType,
    heading,
    isImport,
    isAdd,
    totalRecords,
    setDataFlag,
    setShowToast,
    TabKey,
    otherLoader,
    setOtherLoader,
  } = props;

  const urlParams = useQueryString();
  const history = useHistory();

  const [addCorpModalOpen, setAddCorpModalOpen] = useState(false);
  const [importCorpModalOpen, setImportCorpModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const showModal = () => setAddCorpModalOpen(true);
  const hideModal = () => setAddCorpModalOpen(false);
  const showImportCorpModal = () => setImportCorpModalOpen(true);
  const hideImportCorpModal = () => setImportCorpModalOpen(false);
  const showFilterModal = () => setFilterModalOpen(true);
  const hideFilterModal = () => setFilterModalOpen(false);

  const [auditLogModalOpen, setAuditLogModalOpen] = useState(false);
  const showAuditLogModal = () => setAuditLogModalOpen(true);
  const hideAuditLogModal = () => setAuditLogModalOpen(false);

  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);
  const params = useParams();

  const dispatch = useDispatch();

  const [headingName, setHeadingName] = useState("");
  const [search, setSearch] = useState(false);
  const handleSearchChange = (e) => {
    const { value, name } = e.target;

    dispatch(filterActions.setSearchPatientHistoryText(value));
  };
  const patientHistoryOrderMapping = {
    Provider: "provider",
    Ordered: "ordered",
    Status: "status",
    "Test Date": "test_date",
    Location: "location",
    Remote: "remote",
    "Credit Amount": "credit_amount",
    "Credit Reason": "amount_reason",
  };

  const urlMapping = {
    "Patient History": params.corporateID
      ? `/edit/corporates/${params.corporateID}/community/${params.communityID}/patient/${params.patientID}`
      : params.communityID
      ? `/edit/communities/${params.communityID}/edit/patient/${params.patientID}`
      : params.testID
      ? `/tests/${params.testID}/edit/patient/${params.patientID}`
      : `/edit/patients/${params.patientID}`,
  };

  const patientHistoryStatus = useSelector(
    (state) => state.patient.patientHistoryStatus
  );
  const searchFilteredText = useSelector(
    (state) => state.filter.searchPatientHistoryText
  );

  const patientHistoryTestDateFrom = useSelector(
    (state) => state.patient.patientHistoryTestDateFrom
  );
  const patientHistoryTestDateTo = useSelector(
    (state) => state.patient.patientHistoryTestDateTo
  );
  const patientHistoryOrderedFrom = useSelector(
    (state) => state.patient.patientHistoryOrderedFrom
  );
  const patientHistoryOrderedTo = useSelector(
    (state) => state.patient.patientHistoryOrderedTo
  );

  const rowOrderStatePatientHistory = useSelector(
    (state) => state.rowOrder.rowOrderPatientHistory
  );
  const patientHistoryOrderQuery = useSelector(
    (state) => state.patient.patientHistoryOrderQuery
  );

  const [orderState, setOrderState] = useState(null);
  //const [prefix, setPrefix] = useState("");

  useEffect(() => {
    updateUrl();
  }, [orderState]);

  const buildRowOrderQuery = () => {
    let newOrderQuery = patientHistoryOrderQuery.slice();

    let mappingHeaderName = patientHistoryOrderMapping[headingName];
    let rowOrderHeaderName =
      rowOrderStatePatientHistory[patientHistoryOrderMapping[headingName]];

    if (mappingHeaderName) {
      let prevOrder = rowOrderHeaderName.order === "asc" ? "desc" : "asc";
      let prevInstance = `&${mappingHeaderName}:orderby=${prevOrder}`;
      if (newOrderQuery.includes(prevInstance)) {
        newOrderQuery = newOrderQuery.replace(prevInstance, "");
      }
      if (!search) {
        newOrderQuery = `&${mappingHeaderName}:orderby=${rowOrderHeaderName.order}`;
      }
    }
    dispatch(patientActions.setPatientHistoryOrderQuery(newOrderQuery));

    return newOrderQuery;
  };

  const buildFilterQuery = () => {
    let filterQuery = "";
    filterQuery =
      `test_date_from:eq=${patientHistoryTestDateFrom}` +
      `&test_date_to:eq=${patientHistoryTestDateTo}` +
      `&ordered_from:eq=${patientHistoryOrderedFrom}` +
      `&ordered_to:eq=${patientHistoryOrderedTo}&status:eq=${patientHistoryStatus}`;

    return filterQuery;
  };

  const buildSearchTextQuery = () => {
    let searchQuery = "";
    searchQuery =
      searchFilteredText !== "" ? `search_query=${searchFilteredText}` : "";

    return searchQuery;
  };


  const updateUrl = () => {
    const searchQuery = buildSearchTextQuery();
    const newOrderQuery = buildRowOrderQuery();
    const filterQuery = buildFilterQuery();

    let customQuery = "";

    customQuery = `${searchQuery}${newOrderQuery}&${filterQuery}`;

    let modifiedString = removeEmptyParams(customQuery);

    let urlParamString = urlParams.toString();

    let searchParam = "";
    if (!modifiedString) {
      searchParam =
        "?" + `${search === true ? modifiedString : urlParamString}`;
    } else {
      if (
        !modifiedString.includes("page") &&
        urlParams.toString().includes("page")
      ) {
        searchParam =
          "?" + modifiedString + `&page=${search ? 1 : urlParams.get("page")}`;
      } else {
        searchParam = "?" + modifiedString;
      }
    }

    searchParam = searchParam.replace("&&", "&");
    history.replace({
      pathname: urlMapping[subType],
      search: searchParam,
    });

    let str =
      modifiedString && modifiedString !== "" ? "?" + modifiedString : "";


    dispatch(patientActions.setPatientHistoryApiUrl(str));
    setShowToast(true);
    setDataFlag((prevState) => !prevState);
  };

  const onClickTableHeading = async (e) => {
    const { id } = e.target;
    if (id === "Actions") return;
    setSearch(false);
    setOtherLoader(true);
    setHeadingName(id);

    let name = "";
    let obj;
    for (let i = 0; i < tableHeadings.length; i++) {
      if (id === tableHeadings[i]) {
        name = patientHistoryOrderMapping[id];
        obj = setOrderAndIcon(rowOrderStatePatientHistory[name]);
        dispatch(
          rowOrderActions.setRowOrderPatientHistory({
            ...rowOrderStatePatientHistory,
            [name]: { state: obj.state, order: obj.order, icon: obj.icon },
          })
        );
        setOrderState({
          ...orderState,
          [name]: { state: obj.state, order: obj.order, icon: obj.icon },
        });
        break;
      }
    }
    return;
  };

  const onClearSorting = () => {
    setOtherLoader(true);
    dispatch(
      rowOrderActions.setRowOrderPatientHistory({
        ...rowOrderStatePatientHistory,
        provider: { state: 1, order: "", icon: "" },
        remote: { state: 1, order: "", icon: "" },
        ordered: { state: 1, order: "", icon: "" },
        test_date: { state: 1, order: "", icon: "" },
        status: { state: 1, order: "", icon: "" },
        location: { state: 1, order: "", icon: "" },
        credit_amount: { state: 1, order: "", icon: "" },
        amount_reason: { state: 1, order: "", icon: "" },
      })
    );
    dispatch(patientActions.setPatientHistoryOrderQuery(""));
    history.replace({
      pathname: urlMapping[subType],
      search: "?page=1",
    });

    dispatch(patientActions.setPatientHistoryApiUrl(""));
    setShowToast(true);
    setDataFlag((prevState) => !prevState);
  };
  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      setSearch(true);
      setOtherLoader(true);
      setOrderState({
        ...orderState,
      });
    }
  };

  const onClearSearch = () => {
    setOtherLoader(true);
    dispatch(filterActions.setSearchPatientHistoryText(""));
    setSearch(true);
    setOrderState({
      ...orderState,
    });
  };

  const removeCreatedFilter = () => {
    dispatch(patientActions.setPatientHistoryTestDateFrom([]));
    dispatch(patientActions.setPatientHistoryTestDateTo([]));
    history.replace({
      pathname: urlMapping[subType],
      search: "",
    });

    setOrderState({
      ...orderState,
    });
  };
  const removeOrderedFilter = () => {
    history.replace({
      pathname: urlMapping[subType],
      search: "",
    });
    dispatch(patientActions.setPatientHistoryOrderedFrom([]));
    dispatch(patientActions.setPatientHistoryOrderedTo([]));
    setOrderState({
      ...orderState,
    });
  };
  const removeStatusFilter = (status) => {
    dispatch(patientActions.setPatientHistoryStatus(""));
    history.replace({
      pathname: urlMapping[subType],
      search: "",
    });
    setOrderState({
      ...orderState,
    });
  };

  const clearFilters = () => {
    dispatch(patientActions.setPatientHistoryTestDateFrom([]));
    dispatch(patientActions.setPatientHistoryTestDateTo([]));
    dispatch(patientActions.setPatientHistoryOrderedFrom([]));
    dispatch(patientActions.setPatientHistoryOrderedTo([]));
    dispatch(patientActions.setPatientHistoryStatus(""));

    dispatch(patientActions.setPatientHistoryApiUrl(""));

    setOrderState({
      ...orderState,
    });
  };
  return (
    <Fragment>
      {/* For Audit Log */}
      {auditLogModalOpen && (
        <AuditLogsModal
          isShowModal={auditLogModalOpen}
          hideModal={hideAuditLogModal}
          LogTitle={"Audit Log"}
          type={type}
          dataType={subType === "Patient History" ? subType : ""}
          setDataFlag={setDataFlag}
          setShowToast={setShowToast}
          refId={subType === "Patient History" ? params.patientID : 0}
        />
      )}

      {filterModalOpen && subType === "Patient History" && (
        <PatientHistoryFiltersModal
          isShowModal={filterModalOpen}
          hideModal={hideFilterModal}
          setShowToast={setShowToast}
          setDataFlag={setDataFlag}
          orderBy={patientHistoryOrderQuery}
          setOtherLoader={setOtherLoader}
        />
      )}
      {props.type === "Test" && <br />}
      {otherLoader ? (
        <Loader />
      ) : (
        <div className="corpoarte-table">
          <div className="table-header-filter">
            <div className="cor-header-change">
              <h1 className="page-name-heading">{heading}</h1>
              <div className="right-cor-header">
                <ul className="rc-header-listing">
                
                  <li className="single-rc">
                    <SearchInput
                      value={searchFilteredText}
                      handleChange={handleSearchChange}
                      onKeyEnter={onEnterPress}
                      onClearSearch={onClearSearch}
                    />
                  </li>

                  <li className="single-rc">
                    <FilterButton handleClick={showFilterModal} />
                  </li>
                  <li className="single-rc">
                    <AuditLogButton handleClick={showAuditLogModal} />
                  </li>

                  {type === "Provider" ? (
                    <>
                      {!isCatchUAdmin &&
                      (userRole.includes("Super Admin") ||
                        userRole.includes("Community Admin") ||
                        userRole.includes("Individual Practice Admin")) ? (
                        <>
                          {isImport && (
                            <li className="single-rc">
                              <button
                                className="btn btn-primary-outline"
                                onClick={showImportCorpModal}
                              >
                                Import
                              </button>
                            </li>
                          )}
                          {isAdd && (
                            <li className="single-rc">
                              <button
                                className="btn btn-primary-fill btn-primary-16"
                              >
                                Add
                              </button>
                            </li>
                          )}
                        </>
                      ) : (
                        <>
                          {isCatchUAdmin &&
                            (userRole.includes("Super Admin") ||
                              permissions.includes("Providers")) && (
                              <>
                                {isImport && (
                                  <li className="single-rc">
                                    <button
                                      className="btn btn-primary-outline"
                                      onClick={showImportCorpModal}
                                    >
                                      Import
                                    </button>
                                  </li>
                                )}
                                {isAdd && (
                                  <li className="single-rc">
                                    <button
                                      className="btn btn-primary-fill btn-primary-16"
                                    >
                                      Add
                                    </button>
                                  </li>
                                )}
                              </>
                            )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {type === "Patient" || type === "patient" ? (
                        <>
                          {!isCatchUAdmin &&
                          (userRole.includes("Super Admin") ||
                            userRole.includes("Community Admin") ||
                            userRole.includes("Individual Practice Admin") ||
                            userRole.includes("Support Staff") ||
                            userRole.includes("Provider")) ? (
                            <>
                              {isImport && (
                                <li className="single-rc">
                                  <button
                                    className="btn btn-primary-outline"
                                    onClick={showImportCorpModal}
                                  >
                                    Import
                                  </button>
                                </li>
                              )}
                              {isAdd && (
                                <li className="single-rc">
                                  <button
                                    className="btn btn-primary-fill btn-primary-16"
                                  >
                                    Add
                                  </button>
                                </li>
                              )}
                            </>
                          ) : (
                            <>
                              {isCatchUAdmin &&
                                (userRole.includes("Super Admin") ||
                                  userRole.includes("Community Admin") ||
                                  userRole.includes(
                                    "Individual Practice Admin"
                                  ) ||
                                  userRole.includes("Support Staff") ||
                                  userRole.includes("Provider") ||
                                  permissions.includes("Tests")) && (
                                  <>
                                    {isImport && (
                                      <li className="single-rc">
                                        <button
                                          className="btn btn-primary-outline"
                                          onClick={showImportCorpModal}
                                        >
                                          Import
                                        </button>
                                      </li>
                                    )}
                                    {isAdd && (
                                      <li className="single-rc">
                                        <button
                                          className="btn btn-primary-fill btn-primary-16"
                                        >
                                          Add
                                        </button>
                                      </li>
                                    )}
                                  </>
                                )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {isImport && (
                            <li className="single-rc">
                              <button
                                className="btn btn-primary-outline"
                                onClick={showImportCorpModal}
                              >
                                Import
                              </button>
                            </li>
                          )}
                          {isAdd && (
                            <li className="single-rc">
                              <button
                                className="btn btn-primary-fill btn-primary-16"
                              >
                                Add
                              </button>
                            </li>
                          )}
                        </>
                      )}
                    </>
                  )}
                </ul>
              </div>
            </div>
           
            {((patientHistoryTestDateFrom.length !== 0 &&
              patientHistoryTestDateTo.length !== 0) ||
              (patientHistoryOrderedFrom.length !== 0 &&
                patientHistoryOrderedTo.length !== 0) ||
              patientHistoryStatus.length !== 0) && (
              <div className="filter-header">
                <h2 className="apply-filter-name">Applied Filters:</h2>
                <ul className="apply-filter-list">
                  {patientHistoryTestDateFrom.length !== 0 &&
                    patientHistoryTestDateTo.length !== 0 && (
                      <li className="apply-filter-single">
                        <h3 className="filter-name-type">
                          Test Date:{" "}
                          <strong>
                            {patientHistoryTestDateFrom} -{" "}
                            {patientHistoryTestDateTo}
                          </strong>{" "}
                        </h3>
                        <img
                          src={FilterClose}
                          alt=""
                          onClick={removeCreatedFilter}
                        />
                      </li>
                    )}
                  {patientHistoryOrderedFrom.length !== 0 &&
                    patientHistoryOrderedTo.length !== 0 && (
                      <li className="apply-filter-single">
                        <h3 className="filter-name-type">
                          Ordered:{" "}
                          <strong>
                            {patientHistoryOrderedFrom} -{" "}
                            {patientHistoryOrderedTo}
                          </strong>{" "}
                        </h3>
                        <img
                          src={FilterClose}
                          alt=""
                          onClick={removeOrderedFilter}
                        />
                      </li>
                    )}
                  {patientHistoryStatus &&
                    patientHistoryStatus.map((status) => (
                      <li className="apply-filter-single">
                        <h3 className="filter-name-type">
                          Status: <strong>{status}</strong>{" "}
                        </h3>
                        <img
                          src={FilterClose}
                          alt=""
                          onClick={() => removeStatusFilter(status)}
                        />
                      </li>
                    ))}
                </ul>
                <Link
                  to={urlMapping[subType]}
                  className="clear-all-filter"
                  onClick={clearFilters}
                >
                  Clear all filters
                </Link>
              </div>
            )}
          </div>
          <div className="table-responsive">
            <table className="table" id="en-width">
              <thead>
                <tr>
                  {tableHeadings &&
                    tableHeadings.map((tableHead) => (
                      <th
                        id={tableHead}
                        name={tableHead}
                        key={tableHead}
                        onClick={onClickTableHeading}
                      >
                        {tableHead}
                        {tableHead !== "Actions" && (
                          <>
                            {rowOrderStatePatientHistory[
                              patientHistoryOrderMapping[tableHead]
                            ].icon === "" ? (
                              <span className="icons-th">
                                <span></span>
                                <span className="order-4"></span>
                              </span>
                            ) : (
                              <span className="icons-th">
                                <span
                                  className={
                                    rowOrderStatePatientHistory[
                                      patientHistoryOrderMapping[tableHead]
                                    ].icon === "asc"
                                      ? "caret-4-asc-icon"
                                      : "caret-4-desc-icon"
                                  }
                                ></span>
                                <span className="order-4"></span>
                              </span>
                            )}
                          </>
                        )}
                      </th>
                    ))}
                </tr>
              </thead>

              <TableBody
                subType={subType}
                type={type}
                data={data}
                setDataFlag={setDataFlag}
                setShowToast={setShowToast}
              />
            </table>
          </div>

          <Pagination
            type={type}
            dataType={subType}
            data={data}
            params={params.patientID}
            totalRecords={totalRecords}
            setOtherLoader={setOtherLoader}
          />
        </div>
      )}
      {TabKey === type && (
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      )}

      {type !== "RegUser" && type !== "AdminUser" && (
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      )}
    </Fragment>
  );
};

export default TableData;
