import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Table, Tabs } from "react-bootstrap";
// import TableComponent from "../UI/Table/TableData";
import data from "./Data";
import TableDataEdit from "../Corporate/Table/TableDataEdit";
import "./EditCorporate.scss";
import { getWithExpiry } from "../utils/helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../UI/loader/loader";
import NotFound from "../NotFound/NotFound";

// imports image
import Location from "../../assets/location-icon.svg";

import EditCorporateModal from "./EditCorporateModal/EditCorporateModal";
import axios from "axios";
import { corporateActions } from "../../store/corporate";
import { sidebarActions } from "../../store/sidebar";
import { crumbActions } from "../../store/breadcrumb";
import CommunitiesTable from "../Corporate/Table/CommunitiesTable/CommunitiesTable";
import SelectComponent from "../SelectComponent/SelectComponent";

import CorporateActions from "./CorporateActions/CorporateActions";
import useQueryString from "../../hooks/useQueryString";
function EditCorporate(props) {
  const communityTableHeadings = [
    "ID",
    "Name",
    "Address 01",
    "Address 02",
    "City",
    "State",
    "Zip",
  ];
  const userTableHeadings = [
    "First Name",
    "Last Name",
    "Email",
    "Telephone",
    "Role",
  ];
  const [key, setKey] = useState("community");
  const [loading, setLoading] = useState(true);
  const [otherLoader, setOtherLoader] = useState(false);
  const [dataFlag, setDataFlag] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const [pageExist, setPageExist] = useState(true);
  const params = useParams();
  const { corporateID: corporateId } = params;

  const dispatch = useDispatch();
  const urlParams = useQueryString();
  const userRole = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state.user.permissions);
  const isCatchUAdmin = useSelector((state) => state.user.isCatchuAdmin);

  const corporateInfo = useSelector((state) => state.corporate.corporateInfo);
  const communityList = useSelector((state) => state.corporate.communityList);
  const crumbs = useSelector((state) => state.sidebar.crumbs);
  const totalRecords = useSelector(
    (state) => state.corporate.communityTotalRecords
  );
  const userList = useSelector((state) => state.corporate.userList);
  const userTotalRecords = useSelector(
    (state) => state.corporate.userTotalRecords
  );

  const corporateAdmins = useSelector(
    (state) => state.corporate.corporateAdmins
  );

  const CorporateEntitiesPageNumber = useSelector(
    (state) => state.corporate.corporateEntitiesCurrentPage
  );
  const communityCorporateCurrentPage = useSelector(
    (state) => state.corporate.communityCorporateCurrentPage
  );
  const userCorporateCurrentPage = useSelector(
    (state) => state.corporate.userCorporateCurrentPage
  );

  const [editCorporateModalShow, setEditCorporateModalShow] = useState(false);
  const showEditModalHandler = () => setEditCorporateModalShow(true);
  const hideEditModalHandler = () => setEditCorporateModalShow(false);

  const token = "Bearer " + getWithExpiry("expiry_token");

  const [disableState, setDisableState] = useState(false);
  const [selectedVal, setSelectedVal] = useState("");

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const communityCorporateApiUrl = useSelector(
    (state) => state.corporate.communityCorporateApiUrl
  );
  const userCorporateApiUrl = useSelector(
    (state) => state.corporate.userCorporateApiUrl
  );
  useEffect(() => {
    if (!showToast) {
      setLoading(true);
    }
    // dispatch(sidebarActions.setSelectedNav("Corporates"));

    let urlKeys = [...urlParams.keys()];
    let urlValues = [...urlParams.values()];

    let Url = "";
    let query = "";
    if (urlValues.includes("community")) {
      if (!communityCorporateApiUrl && urlKeys.length > 0) {
        query = "?";
        setKey("community");
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = communityCorporateApiUrl ? communityCorporateApiUrl + "&" : "?";

      }
    } else if (urlValues.includes("users")) {
      if (!userCorporateApiUrl && urlKeys.length > 0) {
        query = "?";
        setKey("user");
        for (let i = 0; i < urlKeys.length; i++) {
          if (urlKeys[i] !== "page") {
            query = query + urlKeys[i] + "=" + urlValues[i] + "&";
          }
        }
      } else {
        query = userCorporateApiUrl ? userCorporateApiUrl + "&" : "?";
      }
    }

    query = query.replace("&&", "&");

    let pageNumber = 1;
    if (urlValues.includes("community")) {
      pageNumber = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? urlParams.get("page")
          : communityCorporateCurrentPage
        : communityCorporateCurrentPage;

      Url = `${process.env.REACT_APP_URL_PATH}/corporate/view_corporate/${corporateId}${query}community_page=${pageNumber}&user_page=${userCorporateCurrentPage}`;
    } else if (urlValues.includes("users")) {
      pageNumber = urlParams.has("page")
        ? !isNaN(parseInt(urlParams.get("page"))) && urlParams.get("page") > 0
          ? urlParams.get("page")
          : userCorporateCurrentPage
        : userCorporateCurrentPage;

      Url = `${process.env.REACT_APP_URL_PATH}/corporate/view_corporate/${corporateId}${query}community_page=${communityCorporateCurrentPage}&user_page=${pageNumber}`;
    } else {
      Url = `${process.env.REACT_APP_URL_PATH}/corporate/view_corporate/${corporateId}?community_page=${communityCorporateCurrentPage}&user_page=${userCorporateCurrentPage}`;
    }

    axios({
      url: Url,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {

        dispatch(
          crumbActions.setCorporateName({
            [response.data.data.corporate.id]:
              response.data.data.corporate.corporate_name,
          })
        );

        dispatch(
          corporateActions.addcommunityPage(response.data.data.community_pages)
        );
        dispatch(corporateActions.addUserpages(response.data.data.user_pages));
        dispatch(corporateActions.addCorporates(response.data.message));
        dispatch(
          corporateActions.setUserTotalRecords(
            response.data.data.user_total_records
          )
        );
        dispatch(
          corporateActions.setCommunityList(response.data.data.community_list)
        );
        dispatch(
          corporateActions.setCommunityTotalRecords(
            response.data.data.community_total_records
          )
        );
        dispatch(corporateActions.setUserList(response.data.data.user_list));
        dispatch(
          corporateActions.setCorporateInfo({
            corporateName: response.data.data.corporate.corporate_name,
            address1: response.data.data.corporate.address["address_1"],
            address2: response.data.data.corporate.address["address_2"],
            city: response.data.data.corporate.address["city"],
            country: response.data.data.corporate.address["country"],
            state: response.data.data.corporate.address["state"],
            zip: response.data.data.corporate.address["zip"],
          })
        );
        dispatch(
          corporateActions.setCorporateAdmins(
            response.data.data.corporate_admins
          )
        );
        dispatch(
          corporateActions.setIsEnable(response.data.data.corporate.is_enabled)
        );

        if (
          communityCorporateCurrentPage > response.data.data.community_pages
        ) {
          dispatch(
            corporateActions.setCommunityCorporateCurrentPage(
              response.data.data.community_pages
            )
          );
        }

        if (userCorporateCurrentPage > response.data.data.user_pages) {
          dispatch(
            corporateActions.setUserCorporateCurrentPage(
              response.data.data.user_pages
            )
          );
        }

        setLoading(false);
        setOtherLoader(false);
      })
      .catch((error) => {
        if (
          error.response.data.message === "Some unknown error occurred!" ||
          error.response.data.message === "404 Not Found!"
        ) {
          setPageExist(false);
        }
        setLoading(false);
        setOtherLoader(false);
      });
  }, [dataFlag]);


  const checkPermission = () => {
    if (
      !isCatchUAdmin &&
      (userRole.includes("Super Admin") ||
        userRole.includes("Corporate HQ Administrator"))
    ) {
      return true;
    } else if (
      isCatchUAdmin &&
      (userRole.includes("Super Admin") ||
        userRole.includes("Corporate HQ Administrator") ||
        permissions.includes("Community"))
    ) {
      return true;
    } else {
      return false;
    }
  };
  if (!loading && !pageExist) {
    //return <Redirect to="*" />;
    return <NotFound />;
  }
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="edit-corp-wrapper">
          <CorporateActions
            corporateInfo={corporateInfo}
            corporateId={corporateId}
            setDataFlag={setDataFlag}
            setShowToast={setShowToast}
          />
          {checkPermission() === true ? (
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="community" title="Communities">
                <TableDataEdit
                  tableHeadings={communityTableHeadings}
                  data={communityList}
                  totalRecords={totalRecords}
                  dataType="community"
                  type="Corporate"
                  heading={"Communities"}
                  corporateId={corporateId}
                  setDataFlag={setDataFlag}
                  setShowToast={setShowToast}
                  TabKey={key}
                  otherLoader={otherLoader}
                  setOtherLoader={setOtherLoader}
                />
              </Tab>
              <Tab eventKey="user" title="Users">
                <TableDataEdit
                  tableHeadings={userTableHeadings}
                  data={userList}
                  totalRecords={userTotalRecords}
                  dataType="user"
                  type="Corporate"
                  heading={"Users"}
                  corporateId={corporateId}
                  setDataFlag={setDataFlag}
                  setShowToast={setShowToast}
                  TabKey={key}
                  otherLoader={otherLoader}
                  setOtherLoader={setOtherLoader}
                />
              </Tab>
            </Tabs>
          ) : (
            <TableDataEdit
              tableHeadings={userTableHeadings}
              data={userList}
              totalRecords={userTotalRecords}
              dataType="user"
              type="Corporate"
              heading={"Users"}
              corporateId={corporateId}
              setDataFlag={setDataFlag}
              setShowToast={setShowToast}
              TabKey={key}
              otherLoader={otherLoader}
              setOtherLoader={setOtherLoader}
            />
          )}
          {/* tabs */}
        </div>
      )}
      {/* <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
    </Fragment>
  );
}

export default EditCorporate;
