import { configureStore, combineReducers } from "@reduxjs/toolkit";
// import storage from "redux-persist/lib/storage";
import storage from "redux-persist/lib/storage/session";
//import { combineReducers } from 'redux';
import { persistReducer } from "redux-persist";

import authReducer from "./auth";
import corporateReducer from "./corporate";
import communityReducer from "./community";
import adminUserReducer from "./adminUser";
import regUserReducer from "./regUser";
import sidebarReducer from "./sidebar";
import userReducer from "./user";
import testReducer from "./test";
import patientReducer from "./patient";
import providerReducer from "./provider";
import filterReducer from "./filter";
import crumbReducer from "./breadcrumb";
import billingReducer from "./billing";
import rowOrderReducer from "./orders";
import equipmentsReducer from "./equipments";
import externalProviders from "./externalProviders";
import prepaidPatients from "./prepaidPatients";

const reducers = combineReducers({
  auth: authReducer,
  corporate: corporateReducer,
  community: communityReducer,
  adminuser: adminUserReducer,
  reguser: regUserReducer,
  sidebar: sidebarReducer,
  user: userReducer,
  test: testReducer,
  patient: patientReducer,
  provider: providerReducer,
  filter: filterReducer,
  crumbs: crumbReducer,
  billing: billingReducer,
  equipments: equipmentsReducer,
  rowOrder: rowOrderReducer,
  externalProviders:externalProviders,
  prepaidPatients: prepaidPatients,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "sidebar", "crumbs"],
};
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
}); // applymiddleware here if needed

export default store;
