import { createSlice } from "@reduxjs/toolkit";

const initialPrepaidPatientsState = {
  PageNumber: 1,
  prepaidPatientsList: [],
  prepaidPatientsPageNumbers: 1,
  prepaidPatientsCurrentPage: 1,
  prepaidPatientsTotalRecords: 0,
  prepaidPatientsApiUrl: "",
};

const prepaidPatientsSlice = createSlice({
  name: "prepaidPatients",
  initialState: initialPrepaidPatientsState,
  reducers: {

    setPrepaidPatientsPageNumbers(state, action) {
      state.prepaidPatientsPageNumbers = action.payload;
    },
    setPrepaidPatientsCurrentPage(state, action) {
      state.prepaidPatientsCurrentPage = action.payload;
    },
    setPrepaidPatientsTotalRecords(state, action) {
      state.prepaidPatientsTotalRecords = action.payload;
    },
    setPrepaidPatientsApiUrl(state, action) {
      state.prepaidPatientsApiUrl = action.payload;
    },
   
    setPrepaidPatientsList(state, action) {
      state.prepaidPatientsList = action.payload;
    },

  },
});

export const prepaidPatientsActions = prepaidPatientsSlice.actions;

export default prepaidPatientsSlice.reducer;
