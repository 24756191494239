import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EnableIcon from "../../../../assets/enable_icon.svg";
import axios from "axios";
import "./EnableModal.scss";
import { getWithExpiry } from "../../../utils/helper";
// import images
import CloseModal from "../../../../assets/close-outline.svg";

import PreviewIcon from "../../../../assets/preview.svg";

function EnableModal(props) {
  const {
    isShowModal,
    hideModal,
    id,
    type,
    data,
    setDataFlag,
    setShowToast,
    enabledId,
  } = props;
  const token = "Bearer " + getWithExpiry("expiry_token");
  const [disabledBtn, setDisabledBtn] = useState(false);
  const idToBeEnabled1 = useSelector((state) => state.corporate.idToBeEnabled);
  const idToBeEnabled2 = useSelector((state) => state.adminuser.idToBeEnabled);
  const idToBeEnabled3 = useSelector((state) => state.reguser.idToBeEnabled);
  const idToBeEnabled4 = useSelector((state) => state.patient.idToBeEnabled);
  const idToBeEnabled5 = useSelector((state) => state.community.idToBeEnabled);

  const notifySuccess = (successMsg) =>
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  let model = "",
    delID;
  if (type === "community" || type === "Community") {
    model = "/community/enable_community/";
    //delID = idToBeEnabled1;
    delID = enabledId;
  } else if (type === "Corporate") {
    model = "/corporate/enable_corporate/";
    // delID = idToBeEnabled1;
    delID = enabledId;
  } else if (type === "RegUser") {
    model = "/user/enable_regular_user/";
    // delID = idToBeEnabled3;
    delID = enabledId;
  } else if (type === "AdminUser") {
    model = "/user/enable_user/";
    // delID = idToBeEnabled2;
    delID = enabledId;
  } else if (type === "user") {
    model = "/user/enable_user/";
    // delID = idToBeEnabled1;
    delID = enabledId;
  } else if (type === "Patient") {
    model = "/user/enable_user/";
    // delID = idToBeEnabled4;
    delID = enabledId;
  } else if (type === "patient") {
    model = "/user/enable_user/";
    // delID = idToBeEnabled5;
    delID = enabledId;
  } else if (type === "PatientDetails") {
    model = "/user/enable_user/";
    delID = enabledId;
  } else if (type === "billee") {
    model = "/user/enable_user/";
    // delID = idToBeEnabled5;
    delID = enabledId;
  } else if (type === "Provider") {
    model = "/user/enable_provider/";
    delID = enabledId;
  } else if (type === "iphone") {
    model = "/test/enable_iphone/";
    // delID = idToBeEnabled1;
    delID = enabledId;
  }

  const enableCorporate = () => {
    setDisabledBtn(true);
    
    axios({
      method: "post",
      url: `${process.env.REACT_APP_URL_PATH}${model}${enabledId}`,
      data: enabledId,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((response) => {
        hideModal();
        notifySuccess(response.data.message);
        setShowToast(true);
        setDataFlag((prevState) => !prevState);
        // setTimeout(() => {
        //   //window.location.reload();
        // }, 3000);
      })
      .catch((error) => {
        setDisabledBtn(false);
        if (error.response.data.message !== "Field Errors") {
          notifyError(error.message);
        }
      });
  };

  return (
    <div className="action-modal-wrapper">
      <Modal
        className="action-modal"
        show={isShowModal}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="action-modal-header" id="enable-modal">
          {/* <h1 className="modal-header-title"></h1> */}
          <span className="close-icon-modal " onClick={hideModal}>
            <img src={CloseModal} alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="action-modal-body">
          <img src={EnableIcon} alt="" />
          <h2 className="action-heading">Are you sure you want to enable?</h2>
        </Modal.Body>
        <Modal.Footer className="action-footer-modal" id="modal-footer">
          <button
            className="btn btn-primary-fill btn-14-24"
            onClick={enableCorporate}
            disabled={disabledBtn}
          >
            Yes, I’m Sure.
          </button>
          <button
            className="btn btn-primary-outline btn-14-24"
            onClick={hideModal}
          >
            Cancel Enable
          </button>
        </Modal.Footer>
        {/* <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}
      </Modal>
    </div>
  );
}

export default EnableModal;
