import { createSlice } from "@reduxjs/toolkit";

const initialProviderState = {
  editProviderInfo: {},
  providerData: [],
  ProviderCommunites: [],
  ProviderPageNumber: 1,
  actionType: "",
  providerTotalRecords: 0,

  providerCurrentPage: 1,
  providerApiUrl: "",
};

const providerSlice = createSlice({
  name: "provider",
  initialState: initialProviderState,
  reducers: {
    setProviderApiUrl(state, action) {
      state.providerApiUrl = action.payload;
    },
    setProviderCurrentPage(state, action) {
      state.providerCurrentPage = action.payload;
    },

    setActionType(state, action) {
      state.actionType = action.payload;
    },
    setEditProviderInfo(state, action) {
      state.editProviderInfo = action.payload;
    },
    setProviderData(state, action) {
      state.providerData = action.payload;
    },
    setProviderCommunites(state, action) {
      state.ProviderCommunites = action.payload;
    },
    setProviderPageNumber(state, action) {
      state.ProviderPageNumber = action.payload;
    },
    setProviderTotalRecords(state, action) {
      state.providerTotalRecords = action.payload;
    },
  },
});

export const providerActions = providerSlice.actions;

export default providerSlice.reducer;
