import React from "react";
import "./loader.scss";
import LoaderImage from "../../../assets/loader.gif";
function Loader() {
  return (
    <div className="loader-wrapper">
      <img className="loader-image" src={LoaderImage} alt="loader_image" />
    </div>
  );
}

export default Loader;
